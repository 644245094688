import React, { ReactElement } from "react";

import { styled, SxProps, Tab, Tabs as MuiTabs, Theme } from "@mui/material";

import Txt from "./Text";

export interface TabsProps {
    tabs: TabData[];
    tabBackgroundColor?: string;
}

export function Tabs(props: TabsProps): ReactElement {
    const { tabs, tabBackgroundColor } = props;
    const [selectedTab, setSelectedTab] = React.useState<
        (typeof tabs)[number]["id"]
    >(tabs[0].id);
    const onChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
        setSelectedTab(newValue);
    };
    return (
        <>
            <StyledTabs
                variant="scrollable"
                scrollButtons={false}
                selectionFollowsFocus
                value={selectedTab}
                onChange={onChange}>
                {props.tabs.map((tab, i) => (
                    <Tab
                        key={i}
                        id={`tab-${i}`}
                        value={tab.id}
                        label={<TabLabel>{tab.name}</TabLabel>}
                        onClick={tab?.onClick}
                        sx={tabSxProp(tabBackgroundColor)}
                    />
                ))}
            </StyledTabs>
        </>
    );
}

export type TabData = {
    /** Name of the content used in the Tab button */
    name: string;
    /**
     * Override for the path extension for the tab. By default will convert the
     * name. E.g. Name="Advanced controls" path will be "advanced-controls"
     */
    id: string;
    /**
     * Content to render in the main body of the page below the page header
     */
    content: ReactElement;
    /**
     * Event emitter when tab is clicked
     */
    onClick?: () => void;
};

export const StyledTabs = styled(MuiTabs)(({ theme }) => ({
    "marginTop": theme.spacing(4),
    "& .MuiTabs-indicator": {
        display: "none",
    },
}));

export const tabSxProp: (
    tabBackgroundColor: string | undefined,
) => SxProps<Theme> = tabBackgroundColor => theme => ({
    "textTransform": "none",
    "color": theme.colours.neutral[600],
    "width": "auto",
    "minWidth": 0,
    "padding": `${theme.spacing(2)} ${theme.spacing(4)}`,
    "border": "1px solid transparent",
    [theme.breakpoints.up("md")]: {
        padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    },
    "&.Mui-selected": {
        color: theme.colours.brand.blueDark,
        backgroundColor: tabBackgroundColor ?? theme.colours.neutral.white,
        borderRadius: "5px 5px 0px 0px",
        border: "1px solid " + theme.colours.neutral[300],
        borderBottom: "none",
    },
});

export const TabLabel = (props: { children: string }) => (
    <Txt font="secondary" level={8} emphasis>
        {props.children}
    </Txt>
);
