import { ReactElement } from "react";

import { useQuery } from "@apollo/client";
import { styled } from "@mui/material/styles";

import { gql } from "__generated__/apollo";
import { DeviceUserRole } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import { removeNullables } from "services/utils";

import AddDeviceUserDialog from "./AddDeviceUserDialog";
import { DeviceUserCard } from "./DeviceUserCard";

interface DeviceUsersProps {
    deviceId: string;
}

export const DEVICE_USERS_QUERY = gql(`
    query DeviceUsers($deviceId: String!) {
        device(id: $deviceId) {
            id
            users {
                edges {
                    id
                    role
                    user {
                        id
                        email
                        status
                    }
                    createdAt
                    lastChangedBy {
                        email
                        id
                    }
                    lastChangedAt
                }
            }
        }
    }
`);

export function DeviceUsers(props: DeviceUsersProps): ReactElement {
    const { deviceId } = props;
    const { data, loading, error } = useQuery(DEVICE_USERS_QUERY, {
        variables: { deviceId },
    });

    if (data) {
        const deviceUserEdges = removeNullables(
            data.device?.users?.edges ?? [],
        );
        return (
            <div>
                {error && (
                    <Callout variant="error">
                        There was an issue finding users for this device. Some
                        information may be missing.
                    </Callout>
                )}
                <div>
                    <AddDeviceUserDialog deviceId={deviceId} />
                </div>
                <UserCardContainer>
                    {deviceUserEdges
                        ?.sort((a, b) => {
                            // first sort alphabetically by domain
                            const domainA = a.user.email.split("@")[1];
                            const domainB = b.user.email.split("@")[1];
                            if (domainA !== domainB) {
                                if (domainA > domainB) return 1;
                                else return -1;
                            }

                            // then alphabetically by email
                            if (a.user.email > b.user.email) return 1;
                            else return -1;
                        })
                        .map(edge => {
                            if (!edge) return null;
                            const email: string = edge.user.email;
                            const userId: string = edge.user.id;
                            const role: DeviceUserRole = edge.role;
                            const createdAt: string =
                                edge.createdAt ?? "Unknown";
                            const lastChangedAt: string =
                                edge.lastChangedAt ?? "Never";
                            const lastChangedByEmail: string =
                                edge.lastChangedBy?.email ?? "No one";
                            return (
                                <DeviceUserCard
                                    key={edge.id}
                                    edgeId={edge.id}
                                    deviceId={deviceId}
                                    email={email}
                                    userId={userId}
                                    userDeactivated={
                                        edge.user.status === "DEACTIVATED"
                                    }
                                    role={role}
                                    createdAt={createdAt}
                                    lastChangedAt={lastChangedAt}
                                    lastChangedByEmail={lastChangedByEmail}
                                />
                            );
                        })}
                </UserCardContainer>
            </div>
        );
    } else if (loading) {
        return <Skeleton variant="rectangular" height={100} />;
    }
    return <Callout variant="error">Unable to find Device Users</Callout>;
}

const UserCardContainer = styled("div")(({ theme }) => {
    const border = "1px solid " + theme.colours.neutral[300];
    return {
        "marginTop": 16,
        "border": border,
        "borderRadius": 4,
        "& > *:not(:last-child)": {
            borderBottom: border,
        },
    };
});
