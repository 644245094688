import React, { ReactElement, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material/styles";

import Txt from "./Text";

/**
 * Properties for the {@link Switch} component.
 */
export interface SwitchProps {
    /**
     * Unique identifier for the switch. Important for accessibility.
     */
    id: string;
    /**
     * Checked value (state) of the switch.
     */
    checked?: boolean;
    /**
     * Callback function on change event for the switch.
     */
    onChange?: (value: boolean) => void;
    /**
     * Disable the button in ts current checked state. User cannot interact with
     * the switch.
     */
    disabled?: boolean;
    /**
     * Size (drives height) of the switch.
     */
    size?: "default" | "sm";
    /**
     * Text label to accompany the switch.
     */
    label?: string;
    /**
     * Position of the label relative to the interative switch.
     */
    labelPosition?: "left" | "right" | "top";
    /**
     * Specify the behaviour of the width of the Switch.
     *
     * If set to `false` the Switch will only take as much space as its
     * content. If set to `true` it will expand to take the full width of the
     * container.`
     *
     * Defaults to `true`.
     */
    fillWidth?: boolean;
    // intermediate?: boolean
}

// Based on https://web.dev/building-a-switch-component/
/**
 * Standard Switch (toggle) component. It handles two states which usually
 * denote "on" or "off".
 *
 * Use this for features that are enabling/disabling. Note that **a change in
 * Switch state should cause an immediate update**. This is unlike forms, which
 * require separate user input (i.e. 'save' button) to confirm a change in
 * state.
 *
 * Do not use this component for opting-in or selecting an item (i.e. in forms).
 * In those scenarios, you should use `Checkbox`.
 *
 * @param props - {@link SwitchProps}
 */
export function Switch({
    id,
    checked = false,
    onChange,
    disabled = false,
    size = "default",
    label,
    labelPosition = "left",
    fillWidth = true,
}: SwitchProps): ReactElement {
    const [value, setValue] = useState(checked);
    const theme = useTheme();

    useEffect(() => {
        setValue(checked);
    }, [checked]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        const newValue = e.target.checked;
        setValue(newValue);
        onChange?.(newValue);
    };

    const thumbSize = size === "sm" ? 12 : 18;
    const trackPadding = size === "sm" ? 2 : 3;
    const labelPositionTopCss = css`
        flex-direction: column;
        gap: 0.5ch;
        align-items: flex-start;
    `;

    const labelComponent = label && (
        <Txt font="secondary" level={8}>
            {label}
        </Txt>
    );

    return (
        <label
            htmlFor={id}
            css={[
                switchCss({ theme, thumbSize, trackPadding, fillWidth }),
                labelPosition === "top" && labelPositionTopCss,
            ]}
            className="vertical">
            {["left", "top"].includes(labelPosition) && labelComponent}
            <input
                type="checkbox"
                role="switch"
                id={id}
                checked={value}
                onChange={handleChange}
                disabled={disabled}
            />
            {labelPosition === "right" && labelComponent}
        </label>
    );
}

/**
 * Style generator function for the {@link Switch} component.
 *
 * @param theme - Material UI Theme object
 * @param thumbSize - Pixel size of the movable thumb affordance
 * @param trackPadding - Pixel padding of the switch track, containg the thumb
 * @returns CSS
 */
const switchCss = (props: {
    theme: Theme;
    thumbSize: number;
    trackPadding: number;
    fillWidth: boolean;
}) => css`
    --thumb-size: ${props.thumbSize}px;
    --thumb: hsl(0 0% 100%);
    --thumb-highlight: hsl(0 0% 0% / 10%);

    --track-size: calc(var(--thumb-size) * 2);
    --track-padding: ${props.trackPadding}px;
    --track-inactive: ${props.theme.colours.neutral[300]};
    --track-active: ${props.theme.colours.brand.green};

    --thumb-color: var(--thumb);
    --thumb-color-highlight: var(--thumb-highlight);
    --track-color-inactive: var(--track-inactive);
    --track-color-active: var(--track-active);

    /* Left To Right text direction */
    --isLTR: 1;

    display: flex;
    align-items: center;
    gap: 2ch;
    justify-content: ${props.fillWidth ? "space-between" : "flex-start"};

    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    overflow: visible;

    &:dir(rtl) {
        --isLTR: -1;
    }

    &.-vertical {
        min-block-size: calc(
            var(--track-size) + calc(var(--track-padding) * 2)
        );

        & > input {
            transform: rotate(calc(90deg * var(--isLTR) * -1));
            touch-action: pan-x;
        }
    }

    & > input {
        --thumb-position: 0%;
        --thumb-transition-duration: 0.25s;

        padding: var(--track-padding);
        background: var(--track-color-inactive);
        inline-size: var(--track-size);
        block-size: var(--thumb-size);
        border-radius: var(--track-size);

        appearance: none;
        cursor: pointer;
        pointer-events: auto;
        touch-action: pan-y;
        border: none;
        outline-offset: 5px;
        box-sizing: content-box;

        flex-shrink: 0;
        display: grid;
        align-items: center;
        grid: [track] 1fr / [track] 1fr;

        transition: background-color 0.25s ease;

        &::before {
            --highlight-size: 0;

            content: "";
            cursor: pointer;
            pointer-events: auto;
            grid-area: track;
            inline-size: var(--thumb-size);
            block-size: var(--thumb-size);
            background: var(--thumb-color);
            box-shadow:
                0 0 0 var(--highlight-size) var(--thumb-color-highlight),
                /* Shadow/Shadow 2 */ 0px 4px 6px rgba(0, 0, 0, 0.05);
            border-radius: 50%;
            transform: translateX(var(--thumb-position));
            transition:
                transform var(--thumb-transition-duration) ease,
                box-shadow 0.1s ease;
        }

        &:not(:disabled):hover::before {
            --highlight-size: 0.5rem;
        }

        &:checked {
            background: var(--track-color-active);
            --thumb-position: calc((var(--track-size) - 100%) * var(--isLTR));
        }

        &:indeterminate {
            --thumb-position: calc(
                calc(calc(var(--track-size) / 2) - calc(var(--thumb-size) / 2)) *
                    var(--isLTR)
            );
        }

        &:disabled {
            cursor: not-allowed;
            --thumb-color: transparent;
            &:checked {
                opacity: 40%;
            }

            &::before {
                cursor: not-allowed;
                box-shadow: inset 0 0 0 2px hsl(0 100% 100% / 60%);
            }
        }
    }
`;
