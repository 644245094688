import { ReactElement } from "react";

import { css } from "@emotion/react";

import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import { useCultureStepInvocations } from "services/hooks/useCultureStepInvocations";

import { InvocationItem } from "../Invocations/InvocationItem";
import { log as parentLog } from "../log";

export const log = parentLog.extend("StepInvocations");

export function StepInvocations(props: {
    deviceId: string;
    stepId: string;
    isActive: boolean;
    numInvocations: number;
}): ReactElement {
    // If the Step is active (i.e. its running or is up next) then we poll
    const shouldPoll = props.isActive;
    const { data, loading, error } = useCultureStepInvocations(
        props.stepId,
        shouldPoll ? 5_000 : 0,
    );
    const invocations = data?.step?.invocations?.nodes;

    if (error) log.error("Issue when loading invocations for step", error);

    let invocationItems: ReactElement | ReactElement[] = (
        <Callout message="This step does not have any operations" />
    );

    if (!invocations && loading) {
        invocationItems = Array(props.numInvocations)
            .fill(0)
            .map((_, i) => (
                <div key={i}>
                    {/** Wrap in a div here because Skeleton won't accept a margin-top override */}
                    <Skeleton height={66} />
                </div>
            ));
    }

    if (invocations && invocations.length > 0) {
        invocationItems = invocations.map(invocation => (
            <InvocationItem
                key={invocation.id}
                invocation={invocation}
                deviceId={props.deviceId}
            />
        ));
    }

    return (
        <div
            onClick={e => e.stopPropagation()}
            css={css`
                margin-top: 12px;
                & > * {
                    margin-top: 4px;
                }
            `}>
            {invocationItems}
        </div>
    );
}
