import { ReactElement } from "react";

import { css } from "@emotion/react";
import ButtonBase from "@mui/material/ButtonBase";

import { log as parentLog } from "components";
import LargeFlaskOutline from "icons/flask/flaskmap-desktop-outline.svg?react";
import LargePositionActive from "icons/flask/flaskmap-desktop-position-active.svg?react";
import LargePositionDisabled from "icons/flask/flaskmap-desktop-position-disabled.svg?react";
import LargePositionInactive from "icons/flask/flaskmap-desktop-position-inactive.svg?react";
import SmallFlaskOutline from "icons/flask/flaskmap-mobile-outline.svg?react";
import SmallPositionActive from "icons/flask/flaskmap-mobile-position-active.svg?react";

const log = parentLog.extend("FlaskMap");

export interface FlaskMapProps {
    /**
     * Size variant of the flask map.
     */
    size?: "s" | "l";
    /**
     * Number (index) for the active position that should be displayed in the
     * flask. Starts from 0, which is top left, and enumerates left-to-right
     * like a book, before wrapping.
     */
    activePosition: number;
    /**
     * Callback to change the state of the active index. It is called when the
     * flask is interacted with directly (only applies to large size).
     */
    setActivePosition: (n: number) => void;
    /**
     * An array of indexes that should be disabled (i.e. not clickable). This
     * does not affect the active position index and how that visibly renders.
     */
    disabledPositions?: number[];
    /**
     * Override default colour (only applies to size="s")
     */
    colour?: string;
}

/**
 * The flask map is a top-down visualisation of the flask marking regions as
 * spots. There are 9 regions in the flask, spaced equally in a grid. One of
 * these regions can be active at a time, determined by the passed props.
 *
 * Any of the regions can be marked as disabled. This also means they cannot be
 * directly clicked in order to change the active index.
 *
 * Index is noted from 0 in the top left of the flask, and then enumerated
 * left-to-right before wrapping to a new line.
 *
 * @param FlaskMapProps
 */
export default function FlaskMap({
    activePosition,
    setActivePosition,
    disabledPositions,
    size = "l",
    colour,
}: FlaskMapProps): ReactElement {
    return (
        <div style={{ display: "inline-block" }}>
            {size === "l" ? (
                <LargeFlaskMap
                    activePosition={activePosition}
                    setActivePosition={setActivePosition}
                    disabledPositions={disabledPositions}
                />
            ) : (
                <SmallFlaskMap
                    activePosition={activePosition}
                    colour={colour}
                />
            )}
        </div>
    );
}

function SmallFlaskMap({
    activePosition,
    colour,
}: {
    activePosition: number;
    colour?: string;
}) {
    const definedColour = colour ?? "white";
    const col = activePosition % 3;
    const row = Math.floor(activePosition / 3);
    const baseXMarginPx = 6;
    const baseYMarginPx = 22;
    const xPxOffset = 9 * col;
    const yPxOffset = 9 * row;

    return (
        <div>
            <div
                style={{ height: "53px", width: "36px", position: "relative" }}>
                {/* outline */}
                <div style={{ position: "absolute" }}>
                    <SmallFlaskOutline
                        css={css`
                            & > path {
                                stroke: ${definedColour};
                            }
                        `}
                    />
                </div>
                {/* grid of indicators */}
                <div
                    style={{
                        position: "absolute",
                        marginLeft: baseXMarginPx + xPxOffset + "px",
                        marginTop: baseYMarginPx + yPxOffset + "px",
                        transition: "0.2s",
                    }}>
                    <SmallPositionActive
                        css={css({
                            "display": "block",
                            "& path": {
                                fill: definedColour,
                            },
                        })}
                    />
                </div>
            </div>
        </div>
    );
}

function LargeFlaskMap({
    activePosition,
    setActivePosition,
    disabledPositions,
}: {
    activePosition: number;
    setActivePosition: (n: number) => void;
    disabledPositions?: number[];
}) {
    const positions: PositionState[] = [];
    const numPositions = 9;
    for (let i = 0; i < numPositions; i++) {
        positions.push("inactive");
    }

    if (disabledPositions) {
        for (const index of disabledPositions) {
            if (index < numPositions) {
                positions[index] = "disabled";
            }
        }
    }

    if (activePosition >= numPositions) {
        log.error(
            `The active position ${activePosition} is too large. There are only ${numPositions} positions in the grid.`,
        );
    } else {
        positions[activePosition] = "active";
    }

    return (
        <div style={{ height: "124px", width: "88px", position: "relative" }}>
            {/* outline */}
            <div style={{ position: "absolute" }}>
                <LargeFlaskOutline />
            </div>
            {/* grid of indicators */}
            <div
                style={{
                    position: "absolute",
                    marginLeft: "12px",
                    marginTop: "48px",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "8px",
                }}>
                {positions.map((state, i) => (
                    <ButtonBase
                        key={i}
                        disabled={state === "disabled"}
                        disableRipple
                        onClick={() => {
                            setActivePosition(i);
                        }}>
                        <LargePositionIndicator state={state} />
                    </ButtonBase>
                ))}
            </div>
        </div>
    );
}

type PositionState = "active" | "inactive" | "disabled";

function LargePositionIndicator({ state }: { state: PositionState }) {
    if (state === "active") {
        return <LargePositionActive />;
    } else if (state === "inactive") {
        return <LargePositionInactive />;
    } else {
        return <LargePositionDisabled />;
    }
}
