import { ReactElement } from "react";

import { Grid, useTheme } from "@mui/material";
import { partition } from "lodash";

import Txt from "components/common/Text";
import { dayjs } from "services/date";

import DeviceCard from "./DeviceCard";
import { Device } from "./DevicesContainer";
import { log as parentLog } from "./log";

const log = parentLog.extend("DevicesList");

export interface DevicesListProps {
    devices: Device[];
}

export function DevicesList({ devices }: DevicesListProps): ReactElement {
    const theme = useTheme();
    const partitionedDevices = partition(devices, shouldBeHidden);
    const hiddenDevices = partitionedDevices[0];
    const visibleDevices = partitionedDevices[1];
    log.debug({ devices, hiddenDevices, visibleDevices });

    return (
        <>
            <Grid container spacing={1}>
                {visibleDevices.map(device => {
                    return (
                        <Grid item xs={12} key={device.id}>
                            <DeviceCard device={device} />
                        </Grid>
                    );
                })}
            </Grid>
            {hiddenDevices.length > 0 && (
                <>
                    <Txt
                        level={8}
                        style={{
                            marginTop: 24,
                            marginBottom: 12,
                            color: theme.colours.neutral[800],
                        }}>
                        These Devices have been offline for a while...
                    </Txt>
                    <Grid container spacing={1}>
                        {hiddenDevices.map(device => {
                            return (
                                <Grid item xs={12} key={device.id}>
                                    <DeviceCard device={device} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </>
    );
}

function shouldBeHidden(device: Device): boolean {
    const isOffline = !device.balena?.isOnline;
    const lastConnected = device.balena?.lastConnected;
    const monthsOffline = dayjs().diff(lastConnected, "months", true);
    return !lastConnected || (isOffline && monthsOffline > 2);
}

/**
 * Logs mock data to the console based on data received by the DevicesList
 * component. To use, temporarily run this function pre-render in the
 * DevicesList component.
 */
// function logMockData(devices: Device[]) {
//     const mock = devices.map(device => {
//         if (device.balena?.deviceJson) device.balena.deviceJson = null;
//         return device;
//     });
//     // eslint-disable-next-line no-console
//     console.log(mock);
// }
