import { ReactElement } from "react";

import { Box, useTheme } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

import Icon from "components/common/Icon";
import { IconButton } from "components/common/IconButton";
import Txt from "components/common/Text";
import { formatDatetime } from "services/date";

import { StoredFileData, useRecentFiles } from "./useRecentFiles";

export function RecentFiles({
    onSelectFile,
}: {
    onSelectFile: (file: StoredFileData) => void;
}): ReactElement {
    const { recentFiles } = useRecentFiles();
    return (
        <Box>
            <Box
                sx={{
                    marginTop: 4,
                    marginBottom: 2,
                    color: theme => theme.colours.neutral[600],
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                }}>
                <Txt font="secondary" level={8} emphasis>
                    Recent files
                </Txt>
                <Icon name="recent" />
            </Box>
            <Box
                sx={{
                    "border": "1px solid",
                    "borderColor": theme => theme.colours.neutral[300],
                    "borderRadius": 1,
                    "& > :not(:last-child)": {
                        // backgroundColor: "green",
                        borderBottom: "1px solid",
                        borderColor: theme => theme.colours.neutral[300],
                    },
                }}>
                <AnimatePresence initial={false}>
                    {recentFiles.length === 0 && (
                        <Txt
                            font="secondary"
                            level={8}
                            italic
                            align="center"
                            sx={{ color: theme => theme.colours.neutral[500] }}>
                            No recent files
                        </Txt>
                    )}
                    {recentFiles.map(file => (
                        <RecentFileRow
                            key={file.hash}
                            file={file}
                            onSelectFile={() => onSelectFile(file)}
                        />
                    ))}
                </AnimatePresence>
            </Box>
        </Box>
    );
}

function RecentFileRow(props: {
    file: StoredFileData;
    onSelectFile: () => void;
}) {
    const { file, onSelectFile } = props;
    const { pushFile, removeFile } = useRecentFiles();
    const theme = useTheme();
    return (
        <motion.div
            transition={{ duration: 0.1 }}
            style={{ overflow: "hidden" }}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}>
            <Box
                sx={{
                    "padding": 3,
                    "paddingRight": 1,
                    "userSelect": "none",
                    "cursor": "pointer",
                    ":hover": {
                        backgroundColor: theme => theme.colours.neutral[100],
                    },
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "space-between",
                }}
                onClick={() => {
                    onSelectFile();
                    pushFile(file.filedata);
                }}>
                <Box>
                    <Txt font="secondary" level={8}>
                        {file.filedata.filename}
                    </Txt>
                    <Txt
                        font="secondary"
                        level={10}
                        italic
                        sx={{ color: theme => theme.colours.neutral[700] }}>
                        Imported {formatDatetime(file.imported)}
                    </Txt>
                </Box>
                <IconButton
                    icon="x-square"
                    size="lg"
                    colour={theme.colours.neutral[400]}
                    onClick={e => {
                        e.stopPropagation();
                        removeFile(file.hash);
                    }}
                />
            </Box>
        </motion.div>
    );
}
