import { DevicePlatform } from "__generated__/apollo/graphql";
import { getHowToGuideLink, HowToGuideStage } from "services/support-links";

export type DrySetupGuideData = {
    sectionTitle: string;
    howToGuide?: string;
    steps: string[];
}[];

export function getDrySetupGuideData(
    platform: DevicePlatform,
): DrySetupGuideData {
    if (platform === DevicePlatform.Triple) {
        return [
            {
                sectionTitle:
                    "Dry Setup documentation does not exist for this platform",
                steps: [],
            },
        ];
    }

    return [
        {
            sectionTitle: "Consumable Checks",
            howToGuide: getHowToGuideLink(
                HowToGuideStage.ConsumableChecks,
                platform,
            ),
            steps: [
                "Perform consumable quality checks",
                "Share serial number of consumable set",
            ],
        },
        {
            sectionTitle: {
                [DevicePlatform.Echo]: "Incubator & fridge checks",
                [DevicePlatform.Foxtrot]: "Incubator checks",
                [DevicePlatform.Mock]: "Incubator checks",
            }[platform],
            howToGuide: getHowToGuideLink(
                HowToGuideStage.PreSetupChecks,
                platform,
            ),
            steps: {
                [DevicePlatform.Echo]: [
                    "Check incubator temperature, water level and CO2",
                    "Check fridge settings and platform",
                ],
                [DevicePlatform.Foxtrot]: [
                    "Check incubator temperature, water level and CO2",
                ],
                [DevicePlatform.Mock]: [
                    "Check incubator temperature, water level and CO2",
                ],
            }[platform],
        },

        {
            sectionTitle: "Incubator setup",
            howToGuide: getHowToGuideLink(
                HowToGuideStage.IncubatorSetup,
                platform,
            ),
            steps: [
                "Slide the flask tray flat into place and engage the toggle clamps",
                "Position the incubator plate and engage the latch",
                "Insert the incubator plate tubing into the pinch valves",
                "Insert the liquid trap vessel",
                "Connect all aseptic connectors, matching tubing markers",
            ],
        },
        {
            sectionTitle: "Sidecar setup",
            howToGuide: getHowToGuideLink(
                HowToGuideStage.SidecarSetup,
                platform,
            ),
            steps: [
                "Position the sidecar plate and engage the latch",
                {
                    [DevicePlatform.Echo]: "Attach both pump heads",
                    [DevicePlatform.Foxtrot]:
                        "Attach both pump heads and insert tubing into the bubble sensors",
                    [DevicePlatform.Mock]:
                        "Attach both pump heads and insert tubing into the bubble sensors",
                }[platform],
                "Connect the aseptic connectors between the sidecar plate and incubator plate",
            ],
        },
    ];
}
