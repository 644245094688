import { useTheme } from "@mui/material/styles";
import useMuiMediaQuery from "@mui/material/useMediaQuery";

/**
 * A wrapper for the Material UI useMediaQuery hook. This wrapper introduces a
 * fix for client side rendering where false would be returned on the first
 * call.
 * @param query CSS media query
 */
export function useMediaQuery(
    query: Parameters<typeof useMuiMediaQuery>[0],
): boolean {
    return useMuiMediaQuery(query, {
        noSsr: true, // https://github.com/ReactTraining/react-router/issues/4170
    });
}

/**
 * Uses a media query on screen width to determine if the view is in mobile
 * format or not.
 */
export function useIsMobile(): boolean {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return isMobile;
}
