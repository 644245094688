import { useSearchParams } from "react-router-dom";

/**
 * A around `useSearchParams` (from react-router-dom) that provides a `useState` API for storing state in the query string
 */
export function useSearchParamsState({ name }: { name: string }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = searchParams.get(name);

    const setValue = (newValue?: string) => {
        setSearchParams(
            prev => {
                if (newValue) {
                    prev.set(name, newValue);
                } else {
                    prev.delete(name);
                }
                return prev;
            },
            { replace: true },
        );
    };

    return [value, setValue] as const;
}
