import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";

import { gql } from "__generated__/apollo";
import { StatusCheck, StatusCheckState } from "__generated__/apollo/graphql";
import defaultLog from "services/logger";

const logger = defaultLog.extend("useAutomatedChecks");

const DEVICE_AUTOMATED_CHECK_QUERY = gql(`
    query DeviceAutomatedCheck($deviceId: String!) {
        device(id: $deviceId) {
            id
            statusChecks {
                name
                type
                state
                reason
            }

        }
    }
`);

/**
 * Retrieves information regarding whether a device is ready to run a culture
 *
 * @param deviceId - ID of Device to check
 */
export function useAutomatedChecks(deviceId: string): {
    checks: Array<StatusCheck>;
    allPass: boolean;
    loading: boolean;
} {
    const { data, loading } = useQuery(DEVICE_AUTOMATED_CHECK_QUERY, {
        variables: { deviceId },
        fetchPolicy: "cache-and-network",
    });

    const checks = data?.device?.statusChecks || [];

    if (isEmpty(checks)) {
        logger.warn({ device: data?.device }, "No checks found for device");
    }

    const allPass = Object.values(checks).every(
        check => check.state !== StatusCheckState.Error,
    );

    return { checks, allPass, loading };
}
