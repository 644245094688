import { useCallback } from "react";

import { atom, useAtom } from "jotai";

import { today } from "services/date";

import { log as parentLog } from "../../log";
import { cultureStartDateAtom } from "../create-culture-state";

import {
    CultureSetupPhase,
    CultureSetupState,
    CultureUploadType,
    getCultureSetupPhase,
    getSetupEntryStep,
    SetupStep,
} from "./culture-setup-state";

export const log = parentLog.extend("CultureSetupState:hooks");

const setupStepAtom = atom(SetupStep.Inactive);

const cultureSetupStateAtom = atom<CultureSetupState>({
    cultureUploadType: CultureUploadType.ServerOwned,
    culture: undefined,
});

export type UseSetupState = {
    /**
     * The current step of the culture setup flow
     */
    currentStep: SetupStep;
    /**
     * The current server + local state surrounding the culture setup flow
     */
    setupState: CultureSetupState;
    setSetupState: (
        updater: (oldState: CultureSetupState) => CultureSetupState,
    ) => void;
    resetSetupState: () => void;
    /**
     * The current phase of the culture setup flow, derived from the setupState.
     */
    currentSetupPhase: CultureSetupPhase;

    /**
     * Perform a state transition
     */
    doStateTransition: (
        targetStep: SetupStep,
        state?: CultureSetupState,
    ) => void;
};

/**
 * Hook to read and update the current step in the culture setup flow.
 */
export function useCultureSetupState(): UseSetupState {
    const [currentStep, setStep] = useAtom(setupStepAtom);

    const [setupState, setSetupState] = useAtom(cultureSetupStateAtom);
    const currentSetupPhase = getCultureSetupPhase(setupState);

    return {
        currentStep,
        setupState,
        setSetupState,
        resetSetupState: useCallback(() => {
            setSetupState({
                cultureUploadType: CultureUploadType.ServerOwned,
            });
            setStep(SetupStep.Inactive);
        }, [setSetupState, setStep]),
        currentSetupPhase,
        doStateTransition: setStep,
    };
}

export function useCultureSetupModalState() {
    const {
        currentStep,
        currentSetupPhase,
        setSetupState,
        setupState,
        doStateTransition,
    } = useCultureSetupState();

    const [, setStartDate] = useAtom(cultureStartDateAtom);

    function setModalOpen(
        newValue: boolean,
        newCultureUploadType: CultureUploadType = CultureUploadType.ServerOwned,
    ) {
        if (currentStep === SetupStep.Inactive && newValue === true) {
            // Automatically set the culture start date to today
            setStartDate(today());

            setSetupState(state => ({
                ...state,
                cultureUploadType: newCultureUploadType,
            }));

            // Re-compute entry state based on possibly new culture upload type
            const entryStep = getSetupEntryStep(
                currentSetupPhase,
                newCultureUploadType,
            );

            // Enter modal at particular step
            doStateTransition(entryStep);
        } else if (currentStep !== SetupStep.Inactive && newValue === false) {
            doStateTransition(SetupStep.Inactive);
        }
    }

    return {
        setModalOpen: useCallback(setModalOpen, [
            currentStep,
            currentSetupPhase,
            setSetupState,
            doStateTransition,
            setStartDate,
        ]),
        cultureUploadType: setupState.cultureUploadType,
        modalOpen: currentStep !== SetupStep.Inactive,
        currentSetupPhase,
    };
}
