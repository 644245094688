import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";

/** One hour in milliseconds */
const HOUR = 1000 * 60 * 60;

export const reactQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 24 * HOUR,
        },
    },
});

const localStoragePersistor = createSyncStoragePersister({
    storage: window.localStorage,
    // time in ms to throttle saving the cache to disk
    throttleTime: 2_000,
});

void persistQueryClient({
    queryClient: reactQueryClient,
    persister: localStoragePersistor,
});
