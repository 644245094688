import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { DeviceOperationsQuery } from "__generated__/apollo/graphql";

export const DEVICE_OPERATIONS_QUERY = gql(`
    query DeviceOperations($deviceId: String!) {
        device(id: $deviceId) {
            id
            operations {
                operationId
                name
                description
                parameters {
                    ... on NumberParameter {
                        name
                        parameterId
                        required
                        description
                        defaultNumber: default
                        units
                        min
                        max
                        step
                    }
                    ... on StringParameter {
                        parameterId
                        required
                        name
                        description
                        defaultString: default
                        options
                    }
                    ... on StringArrayParameter {
                        parameterId
                        required
                        name
                        description
                        defaultStringArray: default
                        options
                    }
                    ... on BooleanParameter {
                        parameterId
                        required
                        name
                        description
                        defaultBoolean: default
                    }
                }
            }
        }
    }
`);

type DeviceOperationsDevice = NonNullable<DeviceOperationsQuery["device"]>;
export type DeviceOperation = NonNullable<
    NonNullable<DeviceOperationsDevice["operations"]>[number]
>;
export type DeviceOperationParameter = NonNullable<
    NonNullable<DeviceOperation["parameters"]>[number]
>;

export const useDeviceOperationsQuery = (deviceId: string) =>
    useQuery(DEVICE_OPERATIONS_QUERY, {
        variables: { deviceId },
    });
