import { useState } from "react";

import styled from "@emotion/styled";
import { Collapse, Tooltip } from "@mui/material";

import { StepState } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";
import Txt from "components/common/Text";
import { useFeature } from "services/feature-flags";
import { Step } from "services/hooks/useCultureProcedureSteps";
import { useSimulationErrorDetails } from "services/hooks/useCultureSchedule";
import { useRenderTimer } from "services/hooks/useRenderTimer";

import {
    renderStepTimingInfo,
    stepHasLiveReferenceRuntime,
} from "./step-render-utils";
import { StepControls } from "./StepControls";
import { StepInvocations } from "./StepInvocations";
import { StepProps } from "./StepItem";
import { StepStateIcon } from "./StepStateIcon";

export function StepCard({
    step,
    deviceId,
    cultureId,
    procedureState,
}: StepProps) {
    const isActive = Boolean(
        step.state && [StepState.Running].includes(step.state),
    );
    const [collapsed, setCollapsed] = useState(true);

    const simulationErrorDetails = useSimulationErrorDetails();

    const errorBadgesEnabled = useFeature(
        "procedure_and_step_error_badges",
    ).enabled;

    const hasSimulationError =
        simulationErrorDetails?.problemStep?.id === step?.id;

    const hasFailedInvocations = Boolean(
        step?.invocationSummary?.numberOfFailedInvocations,
    );

    const showFailedInvocationsBadge =
        errorBadgesEnabled && hasFailedInvocations;

    return (
        <BaseStepCard
            key={step.id}
            hasSimulationError={hasSimulationError}
            onClick={e => {
                e.stopPropagation();
                setCollapsed(!collapsed);
            }}>
            <StepInfo>
                <StepContents>
                    <StepIconContainer>
                        <StepStateIcon
                            state={step.state}
                            needsConfirmation={
                                !step.confirmed && step.confirmationRequired
                            }
                            hasSimulationError={hasSimulationError}
                        />
                    </StepIconContainer>
                    <div>
                        <FlexSpaceBetween>
                            <Txt font="secondary" level={8}>
                                {step.name}
                            </Txt>
                            {showFailedInvocationsBadge && (
                                <Tooltip
                                    title={
                                        "An invocation in this step was unsuccessful"
                                    }
                                    placement="right">
                                    <Flex
                                    // A non-React wrapper component is needed for MUI Tooltip to work
                                    >
                                        <ErrorIcon name="alert-circle" />
                                    </Flex>
                                </Tooltip>
                            )}
                        </FlexSpaceBetween>
                        <StepEstimationDetails step={step} />
                    </div>
                </StepContents>
                <StepControls
                    deviceId={deviceId}
                    cultureId={cultureId}
                    step={step}
                    procedureState={procedureState}
                />
            </StepInfo>
            <Collapse
                in={!collapsed}
                style={{ width: "100%" }}
                mountOnEnter
                unmountOnExit>
                <StepInvocations
                    deviceId={deviceId}
                    stepId={step.id}
                    isActive={isActive}
                    numInvocations={step.invocations?.totalCount ?? 1}
                />
            </Collapse>
        </BaseStepCard>
    );
}

function StepEstimationDetails({ step }: { step: Step }) {
    // If we are waiting for a run time reference we want to update the rendered estimate every second (as it counts down)
    const liveReferenceRuntime = stepHasLiveReferenceRuntime(step);

    useRenderTimer({
        seconds: 1,
        enabled: liveReferenceRuntime || step.state === StepState.Running,
    });

    return (
        <Txt
            font="secondary"
            level={9}
            sx={{
                color: "text.secondary",
            }}>
            {renderStepTimingInfo(step)}
        </Txt>
    );
}

const StepInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
`;

const StepContents = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 12px;
`;

const StepIconContainer = styled.div`
    width: 20px;
`;

const FlexSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ErrorIcon = styled(Icon)`
    color: ${({ theme }) => theme.colours.red[500]};
`;

const BaseStepCard = styled.div<{ hasSimulationError: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 12px 12px;

    border-radius: 4px;
    border: 1px solid
        ${({ theme, hasSimulationError }) =>
            hasSimulationError
                ? theme.colours.red[500]
                : theme.colours.neutral[300]};
    background: ${({ theme }) => theme.colours.neutral[200]};
`;
