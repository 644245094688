import { ReactElement } from "react";

import Papa from "papaparse";

import { MenuOptions } from "components/common/Menu";
import { MoreButton } from "components/common/MoreButton";
import { saveTextAsCSV } from "services/file-utils";
import { isDefined } from "services/utils";

import { log as parentLog } from "../log";

import { ConfluenceGraphProps } from "./GraphCard";
import { ResultNodes, useFlaskConfluence } from "./useFlaskConfluence";

export const log = parentLog.extend("GraphCard");

type CsvRow = {
    cultureId: string;
    resultId: string;
    resultTimestamp: string | null;
    flaskNumber: number | null;
    imageId: string;
    imageIndex: number;
    confluenceValue: number | null;
    relativePosX: number | null;
    relativePosY: number | null;
    relativePosZ: number | null;
};

function mapToCsvRow(input: {
    cultureId: string;
    results: ResultNodes;
}): CsvRow[] {
    const rows: CsvRow[] = [];

    for (const result of input.results) {
        if (
            !result ||
            !result.timestampISO ||
            result?.data?.__typename !== "FlaskImages" ||
            !result.data.images ||
            !result.data.images.length
        ) {
            continue;
        }

        const { timestampISO, id: resultId, data } = result;
        const { images, flask } = data;
        if (!images) continue;

        // We only export results with 100 images as this is what we display on
        // the graph. Anything less is considered not enough data to determine
        // an average confluence.
        const nonNullImages = images.filter(isDefined);
        if (nonNullImages.length !== 100) continue;

        for (const [imageIndex, image] of nonNullImages.entries()) {
            const { id: imageId, confluence } = image;
            const newRow: CsvRow = {
                cultureId: input.cultureId,
                resultId,
                resultTimestamp: timestampISO,
                flaskNumber: flask?.number ?? null,
                imageId,
                imageIndex,
                confluenceValue: confluence,
                relativePosX:
                    image.position?.relativePosition?.relativeCoords?.x ?? null,
                relativePosY:
                    image.position?.relativePosition?.relativeCoords?.y ?? null,
                relativePosZ:
                    image.position?.relativePosition?.relativeCoords?.z ?? null,
            };
            rows.push(newRow);
        }
    }

    return rows;
}

export function DownloadFlaskConfluenceButton({
    flaskId,
    cultureId,
    flaskName,
}: ConfluenceGraphProps & { flaskName: string | null }): ReactElement {
    const { results } = useFlaskConfluence(cultureId, flaskId);
    const disabled = results === null || results?.length === 0 || !flaskId;

    const options: MenuOptions = [];
    if (flaskName) {
        options.push({
            icon: "download",
            label: `Download ${flaskName} data`,
            disabled,
            onClick: () => {
                if (disabled) return;

                const rows = mapToCsvRow({
                    cultureId,
                    results: results,
                });
                const csvText = Papa.unparse(rows);

                let filename = cultureId;
                if (!cultureId.startsWith("culture")) {
                    filename = "culture_" + filename;
                }
                filename += `_flask_${flaskId}_confluence_export_${new Date().toISOString()}.csv`;

                saveTextAsCSV(csvText, filename);
            },
        });
    }

    return <MoreButton vertical options={options} />;
}
