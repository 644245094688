import { useState } from "react";

import { log as parentLog } from "./log";

export const log = parentLog.extend("usePersistentState");

const LOCAL_STORAGE_NAMESPACE = "appState";

/**
 * Returns the app state object, as stored in local storage.
 */
function getAppState(): Record<string, string | undefined> {
    const appStateString = window.localStorage.getItem(LOCAL_STORAGE_NAMESPACE);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return appStateString ? (JSON.parse(appStateString) as any) : {}; // TODO remove 'any' type cast
}

/**
 * Set a key in the app state with a stringified value.
 * @param key - Property key in app state object
 * @param value - Stringified value of the property
 */
function setAppStateKey(key: string, value: string): void {
    const appState = getAppState();
    appState[key] = value;
    const appStateString = JSON.stringify(appState);
    window.localStorage.setItem(LOCAL_STORAGE_NAMESPACE, appStateString);
}

/**
 * Provides hook-based access to local storage.
 * @param key - The unique key for the desired data in local storage
 * @param initialValue - An initial value for the key if its not known
 * @returns [storedValue, setValue]
 */
export function useLocalStorage<V, SetterParam = V | ((v: V) => V)>(
    key: string,
    initialValue: V,
): [V, (v: SetterParam) => void] {
    const [storedValue, setStoredValue] = useState<V>(() => {
        try {
            const appState = getAppState();
            const item = appState[key];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return item ? (JSON.parse(item) as any) : initialValue; // TODO remove 'any' type cast
        } catch (error) {
            log.error("Error caught in initialising local storage state", {
                error,
            });
            return initialValue;
        }
    });
    const setValue = (value: SetterParam) => {
        try {
            const valueToStore: V =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            const valueAsString = JSON.stringify(valueToStore);
            setAppStateKey(key, valueAsString);
        } catch (error) {
            log.error("Error caught in setting local storage state", {
                error,
            });
        }
    };
    return [storedValue, setValue];
}

/**
 * Provides hook-based access to persistent storage, used for basic data storage
 * of primitives. Data is stored in the local storage provided by the runtime.
 *
 * **In future, this data will be synchronised (by default) with the server to
 * ensure state is shared across multiple instances of the app on client
 * devices. This can be overrided.**
 *
 * This hook is recommended for all persistent storage of app state.
 *
 * @param key - The unique key for the desired data in local storage
 * @param initialValue - An initial value for the key if its not known
 * @returns [storedValue, setValue]
 */
export function usePersistentState<V, SetterParam = V | ((v: V) => V)>(
    key: string,
    initialValue: V,
): [V, (v: SetterParam) => void] {
    return useLocalStorage(key, initialValue);
}
