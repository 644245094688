import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { CultureControl } from "__generated__/apollo/graphql";
import {
    getToastifyMessages,
    useToasts,
} from "components/common/toasts/useToasts";

const cultureControlMutation = gql(`
    mutation CultureControl($input: ControlCultureInput!) {
        controlCulture(input: $input) {
            ok
            message
            culture {
                id
                state
                isActive
                errorMessage
                mintedByServer
            }
        }
    }
`);

const CONTROL_LANGUAGE = {
    [CultureControl.Start]: {
        past: "started",
        gerund: "starting",
    },
    [CultureControl.Pause]: {
        past: "paused",
        gerund: "pausing",
    },
    [CultureControl.Resume]: {
        past: "resumed",
        gerund: "resuming",
    },
    [CultureControl.Clear]: {
        past: "cleared",
        gerund: "clearing",
    },
    [CultureControl.Restart]: {
        past: "restarted",
        gerund: "restarting",
    },
} as const satisfies Partial<
    Record<CultureControl, { past: string; gerund: string }>
>;

export default function useCultureControl({
    cultureId,
    onSuccess,
}: {
    cultureId: string;
    onSuccess?: () => void;
}) {
    const [cultureControl, mutationDetails] = useMutation(
        cultureControlMutation,
    );

    const { toastifyAction } = useToasts();

    const handleCultureControl = async ({
        control,
        entity = "culture",
    }: {
        control: keyof typeof CONTROL_LANGUAGE;
        entity?: string;
    }) => {
        const { gerund, past } = CONTROL_LANGUAGE[control];

        return toastifyAction(
            getToastifyMessages({
                entity,
                verb: {
                    past,
                    gerund,
                },
            }),
            async () => {
                const output = await cultureControl({
                    variables: {
                        input: {
                            cultureId,
                            control,
                        },
                    },
                });

                if (output.data?.controlCulture.ok) {
                    onSuccess?.();
                    return output;
                } else {
                    throw new Error(output.data?.controlCulture.message);
                }
            },
        );
    };

    return [handleCultureControl, mutationDetails] as const;
}
