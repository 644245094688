import { CultureControl } from "__generated__/apollo/graphql";
import Txt from "components/common/Text";
import useCultureControl from "services/hooks/server-minted-cultures/useCultureControl";

import { useDeviceWetTests } from "./useDeviceWetTests";
import { WetTestsActivityCard } from "./WetTestsActivityCard";

interface WetTestsViewProps {
    cultureId: string;
}

export function WetTestsView({ cultureId }: WetTestsViewProps) {
    const { wetTestState, loading, errorMessage } = useDeviceWetTests({
        cultureId,
    });

    const [handleCultureControl] = useCultureControl({
        cultureId,
    });

    const handleStartCulture = async () => {
        await handleCultureControl({
            control: CultureControl.Start,
            entity: "Wet Tests",
        });
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                    paddingBottom: "16px",
                }}>
                <Txt font="primary" level={5} emphasis>
                    Wet Tests
                </Txt>
                <Txt font="secondary" level={8}>
                    Run the Wet Tests to be confident your consumable is
                    installed correctly and fluid pumping is working as
                    expected.
                </Txt>
            </div>
            <WetTestsActivityCard
                loading={loading}
                errorMessage={errorMessage}
                wetTestState={wetTestState}
                startCallback={handleStartCulture}
            />
        </div>
    );
}
