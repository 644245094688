import { isNumber, max } from "lodash";

import { StepState } from "__generated__/apollo/graphql";
import {
    dayjs,
    differenceInSeconds,
    formatSecondsDuration,
} from "services/date";
import { Step } from "services/hooks/useCultureProcedureSteps";

interface StepTimingInfoOptions {
    /**
     * If true, will display target runtime reference information on past steps
     */
    displayPastTargetRuntimeReference?: boolean;
    /**
     * If true, will display the achieved runtime information on past steps
     */
    displayPastAchievedRuntimeReference?: boolean;
}

/**
 * Generates the secondary text that should be displayed on a Step based on its
 * current state.
 *
 * @param stepDetails The step to generate the timing information for
 * @param options Options for how the timing information should be displayed
 */
export function renderStepTimingInfo(
    stepDetails: Step,
    {
        displayPastTargetRuntimeReference = false,
        displayPastAchievedRuntimeReference = false,
    }: StepTimingInfoOptions = {},
): null | string {
    const { state, timeStarted, timeFinished, timePlanned, runtimeReference } =
        stepDetails;

    const _estimationDetails = renderEstimationDetails(stepDetails);
    const estimationDetails = _estimationDetails
        ? ` ${_estimationDetails}`
        : "";

    if (state === StepState.Running) {
        return `Running${estimationDetails}`;
    }
    if (state === StepState.Removed) {
        return "Will be skipped";
    }
    if (state === StepState.Ignored) {
        return "Ignored and was not run";
    }

    if (state === StepState.Planned) {
        if (!timePlanned) {
            return `At some point${estimationDetails}`;
        }

        return `Planned${estimationDetails}`;
    }

    const stepDuration =
        timeStarted && timeFinished
            ? differenceInSeconds(timeStarted, timeFinished)
            : null;

    const runtimeNotes: string[] = [];

    if (isNumber(stepDuration)) {
        const formattedRuntime = formatSecondsDuration(stepDuration);
        runtimeNotes.push(`after ${formattedRuntime}`);
    }

    // If the step has a runtime reference, it can be useful for a user to know
    // that the steps runtime was controlled by the timing of another step.
    // Otherwise the scheduled is not clear, especially around critical timings
    // such as dissociation.

    if (
        runtimeReference &&
        runtimeReference.referenceStep &&
        runtimeReference.runtimeInSeconds
    ) {
        const { referenceStep, runtimeInSeconds } = runtimeReference;

        // We can display the original target runtime of a step in relation to
        // the finish time of a past step. This is perhaps less useful than the
        // achieved runtime, but can be useful for debugging and understanding
        // the runtime of a step. Ideally this would be accessible as a tooltip
        // behind a suitable icon.

        if (displayPastTargetRuntimeReference) {
            const formattedTargetRuntime =
                formatSecondsDuration(runtimeInSeconds);
            runtimeNotes.push(
                `(was planned to run for ${formattedTargetRuntime} following completion of ${referenceStep.name})`,
            );
        }

        // We can display the achieved runtime of a step in relation to the
        // completion time of a past step. This is useful for debugging and
        // understanding the runtime of a step. However, this information is not
        // relevant to the majority of end uses. Ideally this would be
        // accessible as a tooltip behind a suitable icon.

        if (
            displayPastAchievedRuntimeReference &&
            referenceStep.timeFinished &&
            timeFinished
        ) {
            const secondsSinceReferenceStep = differenceInSeconds(
                referenceStep.timeFinished,
                timeFinished,
            );
            const formattedTargetRuntime = formatSecondsDuration(
                secondsSinceReferenceStep,
            );
            runtimeNotes.push(
                `(ran for ${formattedTargetRuntime} following completion of ${referenceStep.name})`,
            );
        }
    }
    const runtime = runtimeNotes.length ? ` ${runtimeNotes.join(" ")}` : "";

    if (state === StepState.Complete) {
        return `Completed${runtime}`;
    }

    if (state === StepState.Cancelled) {
        return `Cancelled${runtime}`;
    }

    if (state === StepState.Failed) {
        return `Failed${runtime}`;
    }

    return null;
}

export function stepHasLiveReferenceRuntime({
    runtimeReference,
    state,
}: Step): boolean {
    const liveReferenceRuntime =
        runtimeReference?.runtimeInSeconds &&
        runtimeReference?.referenceStep?.timeFinished &&
        state === StepState.Running;

    return !!liveReferenceRuntime;
}

function renderEstimationDetails(step: Step) {
    const { runtimeReference, estimatedDuration, state, timeStarted } = step;

    const { runtimeInSeconds } = runtimeReference ?? {};

    const { name: referenceStepName, timeFinished: refStepTimeFinished } =
        runtimeReference?.referenceStep || {};

    if (runtimeInSeconds && stepHasLiveReferenceRuntime(step)) {
        const stopTime = dayjs(refStepTimeFinished).add(
            runtimeInSeconds,
            "seconds",
        );
        const diffSeconds = stopTime.diff(dayjs(), "seconds");
        const remainingSeconds = max([diffSeconds, 0]);
        return `(${remainingSeconds} seconds remaining)`;
    }

    if (referenceStepName && runtimeInSeconds) {
        const formattedRuntime = formatSecondsDuration(runtimeInSeconds);
        return `(will run for ${formattedRuntime} following completion of ${referenceStepName})`;
    }

    if (state === StepState.Running && timeStarted && estimatedDuration) {
        const stepDuration = differenceInSeconds(
            timeStarted,
            new Date().toISOString(),
        );

        const remainingSeconds = estimatedDuration / 1000 - stepDuration;

        if (remainingSeconds < 0) {
            return "";
        }

        const formattedRemaining = formatSecondsDuration(remainingSeconds);
        return `(about ${formattedRemaining} remaining)`;
    }

    if (estimatedDuration) {
        const estimatedDurationString = dayjs
            .duration(estimatedDuration, "milliseconds")
            .humanize();
        return `(takes about ${estimatedDurationString})`;
    }

    return "";
}
