import { ReactElement, useState } from "react";

import { useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import { clamp } from "lodash";

import Txt from "./Text";

export type JobButtonState =
    | "idle"
    | "loading"
    | "running"
    | "success"
    | "fail";

export interface JobButtonProps {
    /**
     * The state of the job that the button controls
     */
    state: JobButtonState;
    /**
     * The progress of the job as value between 0 and 1
     */
    progress?: number;
    /**
     * Function called when the button is clicked
     */
    onClick: (currentState: JobButtonState) => void;
}

export function JobButton({
    state,
    progress = 0,
    onClick = () => undefined,
}: JobButtonProps): ReactElement {
    const theme = useTheme();
    const [hovered, setHovered] = useState(false);

    const isIdle = state === "idle";
    const isLoading = state === "loading";
    const isRunning = state === "running";
    const isActive = isLoading || isRunning;
    const isSuccess = state === "success";
    const isFail = state === "fail";
    const isDone = isSuccess || isFail;

    const clamped = clamp(progress ?? 0, 0, 1);
    const pathProgress = isDone ? 1 : clamped;

    const lightGrey = theme.colours.neutral[300];
    const darkGrey = theme.colours.neutral[500];
    const runningBlue = theme.colours.blue[500];
    const successGreen = theme.colours.green[500];
    const errorRed = theme.colours.red[500];

    const strokeWidth = 2;
    let primaryColour = darkGrey;
    switch (state) {
        case "loading":
            primaryColour = darkGrey;
            break;
        case "running":
            primaryColour = runningBlue;
            break;
        case "success":
            primaryColour = successGreen;
            break;
        case "fail":
            primaryColour = errorRed;
            break;
    }

    return (
        <motion.div
            onClick={e => {
                e.stopPropagation();
                onClick(state);
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            initial={false}
            animate={isIdle ? "idle" : "default"}
            whileHover={[state, "hover"]}
            whileTap={[state, "tap"]}
            variants={{
                default: {
                    backgroundColor: "transparent",
                    width: isIdle ? 50 : 30,
                },
                idle: {
                    backgroundColor: lightGrey,
                    width: 50,
                },
                hover: {
                    scale: 1.05,
                },
                tap: {
                    scale: 0.9,
                },
            }}
            style={{
                // Using box shadow to simulate border to avoid layout shift
                boxShadow: `inset 0px 0px 0px ${strokeWidth}px ${lightGrey}`,
                borderRadius: 15,
                height: 30,
                position: "relative",
                cursor: "pointer",
                userSelect: "none", // text can't be highlighted
            }}>
            <motion.svg viewBox="0 0 30 30" height="100%" width="100%">
                {/* The Fill Donut when active */}
                <motion.circle
                    initial={{ opacity: 0 }}
                    animate={
                        isIdle ? "idle" : isLoading ? "loading" : "running"
                    }
                    variants={{
                        idle: {
                            opacity: 0,
                            pathLength: 0,
                            stroke: primaryColour,
                            rotate: 270,
                        },
                        loading: {
                            pathLength: 0.33,
                            opacity: 1,
                            stroke: primaryColour,
                            rotate: [-90, 270],
                            transition: {
                                opacity: { duration: 0.01 },
                                rotate: {
                                    duration: 1.5,
                                    repeat: Infinity,
                                    ease: "linear",
                                },
                            },
                        },
                        running: {
                            rotate: 270,
                            pathLength: pathProgress,
                            opacity: 1,
                            stroke: primaryColour,
                            transition: {
                                pathLength: {
                                    type: "tween",
                                    duration: 0.5,
                                    bounce: 0,
                                },
                                opacity: { duration: 0.01 },
                            },
                        },
                    }}
                    cx="15"
                    cy="15"
                    r="14"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    strokeLinecap="round"
                />

                {/* The Stop Square when active */}
                <motion.rect
                    initial={false}
                    animate={isActive ? "active" : "idle"}
                    variants={{
                        idle: { opacity: 0, fill: primaryColour },
                        active: {
                            opacity: 1,
                            fill: primaryColour,
                            transition: { delay: 0.1 },
                        },
                    }}
                    x="11"
                    y="11"
                    width="8"
                    height="8"
                    rx="2"
                    ry="2"
                />
            </motion.svg>

            {/* The Success Check symbol */}
            <motion.svg
                initial="default"
                animate={isSuccess && !hovered ? "success" : "default"}
                variants={{
                    default: {
                        opacity: 0,
                    },
                    success: {
                        opacity: 1,
                    },
                }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    position: "absolute",
                    top: 3,
                    left: 3,
                    transform: "scale(0.667)",
                }}>
                <path
                    d="M20 6L9 17L4 12"
                    stroke={successGreen}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </motion.svg>

            {/* The Failure symbol */}
            <motion.svg
                initial="default"
                animate={isFail && !hovered ? "fail" : "default"}
                variants={{
                    default: {
                        opacity: 0,
                    },
                    fail: {
                        opacity: 1,
                    },
                }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{
                    position: "absolute",
                    top: 3,
                    left: 3,
                    transform: "scale(0.667)",
                }}
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18 6L6 18"
                    stroke={errorRed}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 6L18 18"
                    stroke={errorRed}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </motion.svg>

            {/* The Play symbol to restart */}
            <motion.svg
                id="play-symbol"
                initial="default"
                animate={isDone && hovered ? "done" : "default"}
                variants={{
                    default: {
                        opacity: 0,
                    },
                    done: {
                        opacity: 1,
                        transition: { delay: 0.1 },
                    },
                }}
                style={{
                    position: "absolute",
                    top: 3,
                    left: 3,
                    transform: "scale(0.5)",
                }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6 3L20 12L6 21V3Z"
                    stroke={primaryColour}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </motion.svg>

            {/* Idle "Run" Text */}
            <motion.span
                initial={false}
                whileHover={{
                    color: theme.colours.blue[700],
                }}
                animate={{
                    opacity: isIdle ? 1 : 0,
                }}
                transition={{
                    opacity: { duration: 0.1 },
                }}
                style={{
                    color: runningBlue,
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Txt font="secondary" level={9} emphasis>
                    Run
                </Txt>
            </motion.span>
        </motion.div>
    );
}
