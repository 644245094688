import React from "react";

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { useNavigate } from "react-router";

import { Avatar } from "components/common/Avatar";
import Icon from "components/common/Icon";
import Txt from "components/common/Text";
import { config } from "services/config";
import { useFeature } from "services/feature-flags";
import { useUser } from "services/hooks/useUser";

import { toTitleCase } from "../../services/string-utils";

import { log as parentLog } from "./log";

const log = parentLog.extend("UserButton");

interface UserButtonProps {
    compact: boolean;
    className?: string;
}

export function UserButton(props: UserButtonProps): JSX.Element {
    const { user, displayName, fullName, logout, isMytosUser } = useUser();

    const theme = useTheme();
    const navigate = useNavigate();
    log.debug("Display name", displayName);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const serialButtonEnabled =
        useFeature("serial_page").enabled || config.isLocal;

    const handleToggle = (): void => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (
        event: React.MouseEvent<EventTarget> | MouseEvent | TouchEvent,
    ): void => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent): void {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const environment = config.evaluateEnvironment();
    let environmentToSwitchTo: typeof environment;

    switch (environment) {
        case "production":
            environmentToSwitchTo = "staging";
            break;
        default:
            environmentToSwitchTo = "production";
            break;
    }

    const environmentSwitchUrl = new URL(window.location.href);
    // Remove port in case we are coming from localhost
    environmentSwitchUrl.port = "";
    environmentSwitchUrl.hostname = config.appHostnameForEnvironment(
        environmentToSwitchTo,
    );

    return (
        <div style={{ display: "inline-block" }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                <Avatar
                    border={props.compact ? true : false}
                    // TODO can we get this link from a LinkedIn profile URL?
                    // src="https://media-exp1.licdn.com/dms/image/C4D03AQGq3-toPXGOXg/profile-displayphoto-shrink_400_400/0?e=1598486400&v=beta&t=kV5S8Qf2SuoyB2sURS3BF5eQ4wt2VeykoaoUczZ6qAc"
                    // src="https://d33wubrfki0l68.cloudfront.net/c1dd96ed78e6ab30e205b084b6af77dad6f43ce5/3b9d6/assets/images/ben-profile.jfif"
                    // src="https://d33wubrfki0l68.cloudfront.net/d0f8b5cb38170647b22a034d3892a61d3e7ab561/aad89/assets/images/xian-profile.jpg"
                    // src="https://d33wubrfki0l68.cloudfront.net/e0ca8cab3010c95f11d31e7f52d47ef2e95cf342/9181b/assets/images/jordina-profile.png"
                    // src="https://d33wubrfki0l68.cloudfront.net/78135c21831993c477c676f75d0187194c55663a/0c476/assets/images/sina-profile.jpg"
                />
                {props.compact ? null : (
                    <React.Fragment>
                        <Txt
                            font="primary"
                            level={8}
                            emphasis
                            style={{
                                textTransform: "none",
                                color: theme.colours.neutral[700],
                                fontSize: 14,
                                marginLeft: 12,
                                marginRight: 8,
                            }}>
                            {displayName}
                        </Txt>
                        <Icon
                            name="chevron-down"
                            size="md"
                            colourOverride={theme.colours.neutral[700]}
                        />
                    </React.Fragment>
                )}
            </Button>

            <Popper
                // open={true}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="bottom-end"
                disablePortal={false}
                sx={{
                    zIndex: 9999, // very high to make it unlikely to be painted over
                }}
                modifiers={[
                    { name: "flip", enabled: true },
                    {
                        name: "preventOverflow",
                        enabled: true,
                        options: {
                            altBoundary: true,
                        },
                    },
                ]}>
                {({ TransitionProps }): JSX.Element => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: "right top",
                        }}>
                        <Paper variant="outlined" sx={{ marginTop: 2 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    disablePadding
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}>
                                    <Txt
                                        font="secondary"
                                        level={7}
                                        emphasis
                                        style={{
                                            color: theme.colours.neutral[700],
                                            margin: "0px 12px",
                                            marginTop: theme.spacing(2),
                                        }}>
                                        {fullName}
                                    </Txt>
                                    <Txt
                                        font="secondary"
                                        level={8}
                                        style={{
                                            color: theme.colours.neutral[700],
                                            margin: "0px 12px",
                                            marginBottom: "8px",
                                        }}>
                                        {user?.email}
                                    </Txt>
                                    <Divider />
                                    {isMytosUser && (
                                        <a
                                            href={environmentSwitchUrl.toString()}>
                                            <StyledMenuItem
                                                onClick={handleClose}>
                                                <StyledListItemIcon>
                                                    <Icon name="switcharoo" />
                                                </StyledListItemIcon>
                                                Switch to{" "}
                                                {toTitleCase(
                                                    environmentToSwitchTo,
                                                )}
                                            </StyledMenuItem>
                                        </a>
                                    )}
                                    <StyledMenuItem
                                        onClick={e => {
                                            navigate("/settings");
                                            handleClose(e);
                                        }}>
                                        <StyledListItemIcon>
                                            <Icon name="settings-toggle-horizontal" />
                                        </StyledListItemIcon>
                                        Settings
                                    </StyledMenuItem>
                                    {serialButtonEnabled && (
                                        <StyledMenuItem
                                            onClick={e => {
                                                navigate("/serial");
                                                handleClose(e);
                                            }}>
                                            <StyledListItemIcon>
                                                <Icon name="cable" />
                                            </StyledListItemIcon>
                                            Serial
                                        </StyledMenuItem>
                                    )}
                                    <a href="mailto:support@mytos.bio">
                                        <StyledMenuItem onClick={handleClose}>
                                            <StyledListItemIcon>
                                                <Icon name="question-help-message" />
                                            </StyledListItemIcon>
                                            Support
                                        </StyledMenuItem>
                                    </a>
                                    <StyledMenuItem onClick={logout}>
                                        <StyledListItemIcon>
                                            <Icon name="logout" />
                                        </StyledListItemIcon>
                                        Log out
                                    </StyledMenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    opacity: 1,
    colour: theme.colours.neutral[700],
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    "padding": "12px 12px",
    "& .MuiListItemIcon-root": {
        minWidth: 0,
        paddingRight: 20,
    },
    "fontSize": 16,
    "color": theme.colours.neutral[700],
}));
