import React, { useState } from "react";

import styled from "@emotion/styled";

import { EditBottleInput } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";
import Modal from "components/common/Modal";
import { NumberInput } from "components/common/NumberInput";
import { TextLabel } from "components/common/TextLabel";
import { useUpdateBottle } from "services/hooks/useUpdateBottle";
import { useUpdateFlask } from "services/hooks/useUpdateFlask";

import { ContainerTile, ContainerTileProps } from "./ContainerTile";

interface EditContainerModalProps extends ContainerTileProps {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
}

export const EditContainerModal: React.FC<EditContainerModalProps> = ({
    modalOpen,
    setModalOpen,
    id,
    name,
    totalVolumeMl,
    liquidVolumeMl,
    isFlask,
}) => {
    const [newTotalVolume, setNewTotalVolume] = useState<number | null>(
        totalVolumeMl,
    );
    const [newLiquidVolume, setNewLiquidVolume] = useState<number | null>(
        liquidVolumeMl,
    );

    const updateFlask = useUpdateFlask();
    const updateBottle = useUpdateBottle();

    /**
     * Returns true if container successfully updated
     */
    async function editContainer(
        editContainerInput: Omit<EditBottleInput, "bottleId"> & { id: string },
    ) {
        const { id, ...volumeProps } = editContainerInput;
        if (isFlask) {
            return await updateFlask({
                ...volumeProps,
                flaskId: id,
            });
        } else {
            return await updateBottle({
                ...volumeProps,
                bottleId: id,
            });
        }
    }

    const close = () => {
        setModalOpen(false);
        setNewLiquidVolume(liquidVolumeMl);
        setNewTotalVolume(totalVolumeMl);
    };

    return (
        <Modal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={close}
            title={`Edit container`}
            maxWidth="xs"
            buttonLeft={{
                label: "Back",
                onClick: close,
            }}
            buttonRight={{
                label: "Confirm",
                onClick: async () => {
                    const success = await editContainer({
                        id: id,
                        liquidVolume: newLiquidVolume,
                        totalVolume: newTotalVolume,
                    });
                    if (success) setModalOpen(false);
                },
            }}>
            <div
                style={{
                    width: "100%",
                    position: "relative",
                }}>
                <FlexColumnContainer>
                    {/* For readability purposes, label chosen is Fill to represent LiquidVolume */}
                    <TextLabel label="Fill">
                        <NumberInput
                            fullWidth={true}
                            required
                            step={10}
                            min={0}
                            max={newTotalVolume ?? undefined}
                            units="mL"
                            value={newLiquidVolume}
                            onChange={setNewLiquidVolume}
                            helperText=""
                        />
                    </TextLabel>
                    {/* For readability purposes, label chosen is Capacity to represent TotalVolume */}
                    <TextLabel label="Capacity">
                        <NumberInput
                            fullWidth={true}
                            required
                            step={10}
                            min={0}
                            units="mL"
                            value={newTotalVolume}
                            onChange={setNewTotalVolume}
                            helperText=""
                        />
                    </TextLabel>
                </FlexColumnContainer>
                <FlexRowContainer>
                    <ContainerTile
                        key={`${id}-old`}
                        id={id}
                        name={name}
                        liquidVolumeMl={liquidVolumeMl}
                        totalVolumeMl={totalVolumeMl}
                        isFlask={isFlask}
                        isVolumeModifiable={false}
                        fixedWidth={false}
                    />
                    <Icon name="arrow-right" size="md" />
                    <ContainerTile
                        key={`${id}-new`}
                        id={id}
                        name={name}
                        liquidVolumeMl={newLiquidVolume}
                        totalVolumeMl={newTotalVolume}
                        isFlask={isFlask}
                        isVolumeModifiable={false}
                        fixedWidth={false}
                    />
                </FlexRowContainer>
            </div>
        </Modal>
    );
};

const FlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const FlexRowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
