import { ReactElement } from "react";

import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import copy from "copy-to-clipboard";
import { isString } from "lodash";

import { DeviceJobState, DeviceJobType } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import { JobButton, JobButtonState } from "components/common/JobButton";
import Txt, { TxtProps } from "components/common/Text";
import { Dayjs, formatDatetime } from "services/date";
import { exhaustiveMatchingGuard } from "services/utils";

import { useCancelDryTest, useStartDryTest } from "./useTriggerDryTest";
import { DryTest, estimateCheckCompletionTime, isTestStale } from "./utils";

const DryTestNameMapping: Record<DeviceJobType, string> = {
    [DeviceJobType.Tilter]: "Tilter",
    [DeviceJobType.Shaker]: "Shaker",
    [DeviceJobType.PinchValveBedding]: "Pinch Valves",
};

export function DryTestRow({
    dryTest,
    deviceId,
    currentDateTime,
}: {
    dryTest: DryTest;
    deviceId: string;
    currentDateTime?: Dayjs;
}) {
    const { type } = dryTest;

    return (
        <div
            style={{
                padding: "12px",
                paddingLeft: "16px",
                paddingRight: "16px",
                userSelect: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: "62px",
            }}>
            <div>
                <Txt font="secondary" level={8}>
                    {DryTestNameMapping[type]}
                </Txt>
                <DryTestCardMetaData dryTest={dryTest} />
            </div>
            <DryTestEndIcon
                dryTest={dryTest}
                deviceId={deviceId}
                currentDateTime={currentDateTime}
            />
        </div>
    );
}

function DryTestEndIcon({
    dryTest,
    deviceId,
    currentDateTime,
}: {
    dryTest: DryTest;
    deviceId: string;
    currentDateTime?: Dayjs;
}): ReactElement {
    const { id, state, type, progress } = dryTest;
    const { triggerDryTest, loading: startLoading } = useStartDryTest();
    const { cancelDryTest, loading: cancelLoading } = useCancelDryTest();

    const onClick = async () => {
        switch (state) {
            case DeviceJobState.Failed:
            case DeviceJobState.Complete:
            case DeviceJobState.Cancelled:
            case null:
                await triggerDryTest({ deviceId, types: [type] });
                break;
            case DeviceJobState.InProgress:
            case DeviceJobState.Triggered:
            case DeviceJobState.Pending:
                await cancelDryTest({ id });
                break;
            case DeviceJobState.Cancelling:
                break;
            default:
                exhaustiveMatchingGuard(state);
        }
    };

    const getState = (): JobButtonState => {
        if (startLoading || cancelLoading) {
            return "loading";
        }
        switch (state) {
            case DeviceJobState.Complete:
                return isTestStale(dryTest, currentDateTime)
                    ? "idle"
                    : "success";
            case DeviceJobState.Failed:
                return "fail";
            case DeviceJobState.InProgress:
            case DeviceJobState.Cancelling:
                return "running";
            case DeviceJobState.Triggered:
            case DeviceJobState.Pending:
                return "loading";
            case DeviceJobState.Cancelled:
            case null:
                return "idle";
            default:
                exhaustiveMatchingGuard(state);
                return "idle";
        }
    };

    return (
        <JobButton
            state={getState()}
            onClick={onClick}
            progress={progress ?? undefined}
        />
    );
}

function DryTestCardMetaData({ dryTest }: { dryTest: DryTest }): ReactElement {
    const { state, updatedAt, requestedAt, errorMessage } = dryTest;

    const hasUpdatedAt = isString(updatedAt);
    const hasRequestedAt = isString(requestedAt);

    if (state === DeviceJobState.Failed) {
        return (
            <ErrorMessageReport
                id={dryTest.id}
                errorMessage={errorMessage ?? undefined}
            />
        );
    }

    const getText = (): string => {
        switch (state) {
            case DeviceJobState.Complete:
                return hasUpdatedAt
                    ? `Completed at ${formatDatetime(updatedAt)}`
                    : `Dry test complete`;
            case DeviceJobState.InProgress: {
                if (!hasRequestedAt && !hasUpdatedAt) {
                    return "Dry test in progress";
                }
                const estimatedCompletion = estimateCheckCompletionTime({
                    type: dryTest.type,
                    progress: dryTest.progress ?? undefined,
                    updatedAt: dryTest.updatedAt ?? undefined,
                });
                return `Estimated completion ${formatDatetime(
                    estimatedCompletion,
                )}`;
            }
            case DeviceJobState.Pending:
            case DeviceJobState.Triggered:
                return hasRequestedAt
                    ? `Pending start, requested at ${formatDatetime(
                          requestedAt,
                      )}`
                    : `Pending start`;
            case DeviceJobState.Cancelled:
                return hasUpdatedAt
                    ? `Cancelled at ${formatDatetime(updatedAt)}`
                    : `Dry test cancelled`;
            case DeviceJobState.Cancelling:
                return "Cancelling dry test";
            case null:
                return "";
        }
    };

    return <MetaDataTxt>{getText()}</MetaDataTxt>;
}

function ErrorMessageReport({
    id,
    errorMessage,
}: {
    id: string;
    errorMessage?: string;
}) {
    const theme = useTheme();
    const report = `Dry test ${id} failed with error: ${errorMessage}`;
    const handleCopy = () => {
        copy(report, { format: "text/plain" });
    };

    return (
        <ErrorMessageContainer>
            <MetaDataTxt font="secondary" level={10} italic>
                Please share report with Mytos support.
            </MetaDataTxt>
            <ErrorMessageButton
                colour={theme.colours.red[600]}
                hoverColour={theme.colours.red[800]}
                variant="tertiary"
                size="s"
                iconRight={"clipboard"}
                onClick={handleCopy}
                tooltip={"Copy to clipboard"}>
                Copy report
            </ErrorMessageButton>
        </ErrorMessageContainer>
    );
}

const ErrorMessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: ${({ theme }) => theme.colours.red[600]};
`;

const ErrorMessageButton = styled(Button)`
    padding: 0;
    font-style: italic;
    font-size: 10px;
`;

const StyledTxtForMetaData = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

const MetaDataTxt = (props: TxtProps) => (
    <StyledTxtForMetaData font="secondary" level={10} italic {...props} />
);
