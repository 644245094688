import { ReactElement } from "react";

import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

import { StepState } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";

import { log as parentLog } from "../Procedures/log";
import { ConfirmationRequiredIcon } from "../StateIcons";

export const log = parentLog.extend("StepItemStatus");

export type StepStateIconProps = {
    state: StepState | null;
    needsConfirmation: boolean | null;
    hasSimulationError?: boolean;
};

// TODO (MYT-5359) - Use StatusIcon

const IconFrame = styled.div`
    box-sizing: border-box;
    border-radius: 20px;

    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CompleteState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.brandGreen[500]};
    color: ${({ theme }) => theme.colours.neutral.white};
`;

const FailedState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.neutral.white};
    color: ${({ theme }) => theme.colours.red[500]};
    border: 1px solid ${({ theme }) => theme.colours.red[500]};
`;

const RunningState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.brandBlue[600]};
    padding: 1px;
`;

const PausedState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.neutral.white};
    color: ${({ theme }) => theme.colours.orange[500]};
`;

const IgnoredState = styled(IconFrame)`
    color: ${({ theme }) => theme.colours.neutral[500]};
`;

const WhiteBuffer = styled.div`
    border-radius: 20px;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: ${({ theme }) => theme.colours.neutral.white}; */
    border: 0px;
`;

const SmallGreyCircle = styled.div`
    border-radius: 20px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${({ theme }) => theme.colours.neutral[300]};
`;

export function StepStateIcon({
    state,
    needsConfirmation,
    hasSimulationError,
}: StepStateIconProps): ReactElement {
    const theme = useTheme();

    if (state === StepState.Planned && needsConfirmation) {
        return <ConfirmationRequiredIcon />;
    }

    if (hasSimulationError) {
        return (
            <FailedState title="Simulation Error">
                <Icon name="cross" size="sm" />
            </FailedState>
        );
    }

    switch (state) {
        case StepState.Complete:
            return (
                <CompleteState title={state}>
                    <Icon
                        name="check"
                        size="sm"
                        sx={{ transform: "scale(80%)" }}
                    />
                </CompleteState>
            );

        case StepState.Failed:
            return (
                <FailedState title={state}>
                    <Icon name="cross" size="sm" />
                </FailedState>
            );

        case StepState.Pausing:
        case StepState.Cancelling:
        case StepState.Running:
            return (
                <RunningState title={state}>
                    <RotatingLines
                        width="18"
                        strokeColor={theme.colours.neutral.white}
                        strokeWidth="5"
                        animationDuration="1"
                    />
                </RunningState>
            );

        case StepState.Paused:
            return (
                <PausedState title={state}>
                    <Icon name="pause-circle" size="md" />
                </PausedState>
            );

        case StepState.Removed:
        case StepState.Ignored:
        case StepState.Cancelled:
            return (
                <IgnoredState title={state}>
                    <Icon name="disable" size="md" />
                </IgnoredState>
            );

        case StepState.Planned:
        case StepState.Waiting:
        case StepState.Unknown:
        case null:
            return (
                <IconFrame title={state ?? ""}>
                    <WhiteBuffer>
                        <SmallGreyCircle />
                    </WhiteBuffer>
                </IconFrame>
            );
    }
}
