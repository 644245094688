import { useEffect, useRef, useState } from "react";

type Props = {
    /**
     * Function to call when the last element is scrolled into the viewport.
     */
    onLoadMore?: () => void;
    /**
     * The distance in pixels from the bottom of the element to the bottom of the viewport at which the "onLoadMore" callback will be called.
     */
    loadMoreThresholdPx?: number;
};

/**
 * Utility to help with infinite scrolling. This hook will call the "onLoadMore" callback when the last element is scrolled into the viewport.
 *
 * @example
 * ```ts
 * const { boundaryRef, setLastInfiniteScrollElementRef } = useInfiniteScroll({
 *     onLoadMore: () => {
 *         // Load more data here
 *     },
 * });
 *
 * return (
 *     // The boundary ref is needed so we can load more data before the last item is in the viewport
 *     // If you don't include it then data will only be loaded when the last item enters viewport (so the user will reach the end of the list before more data has loaded)
 *     <div ref={boundaryRef}>
 *         {data.map((item, index) => {
 *             return (
 *                 <div
 *                     key={item.id}
 *                    // Only set this ref on the last element, this is the one we are watching to see when to fetch more data
 *                     ref={
 *                         index === data.length - 1
 *                             ? setLastInfiniteScrollElementRef
 *                             : undefined
 *                     }>
 *                     {item.name}
 *                 </div>
 *             );
 *         })}
 *     </div>
 * );
 * ```
 */
export default function useInfiniteScroll({
    onLoadMore,
    loadMoreThresholdPx = 500,
}: Props) {
    const boundaryRef = useRef<HTMLDivElement>(null);

    // Using state rather than ref because this allows us to reassign to a different element after the initial render
    // E.g. when more data is loaded we can just reassign "lastElement" to be the new last element
    // To make this work you still pass to the "ref" attribute e.g. <div ref={setLastElement} />
    const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                const last = entries[0];
                if (last.isIntersecting) {
                    onLoadMore?.();
                }
            },
            {
                // To make rootMargin work we need to specify a boundary element that is not hte viewport
                // See here for details: https://stackoverflow.com/a/58625634
                root: boundaryRef.current,
                rootMargin: `${loadMoreThresholdPx}px`,
            },
        );

        if (lastElement) {
            observer.observe(lastElement);
        }

        return () => {
            observer.disconnect();
        };
    }, [lastElement, onLoadMore, loadMoreThresholdPx]);

    return {
        /**
         * This is the element that is used as the boundary for the IntersectionObserver, it is needed to make the "loadMoreThresholdPx" option work
         */
        boundaryRef,
        /**
         * This is a 'useState' setter function, so it can be used to dynamically change the element that is observed
         * You still pass it as <element ref={setLastInfiniteScrollElementRef} />
         */
        setLastInfiniteScrollElementRef: setLastElement,
    };
}
