import { ReactElement } from "react";

import Button, { ButtonSize } from "../Button";

export interface DeviceNotificationsPreferenceButtonProps {
    notificationsEnabled: boolean;
    onClick: () => void;
    disabled?: boolean;
    size?: ButtonSize;
    /**
     * Controls the display of text in the button. Without text, only icon is
     * displayed. Defaults false.
     */
    hideText?: boolean;
}

/**
 * This button is used to handle the styling of the NotificationsPreferenceButtonRaws
 * two states, enabled and disabled. The logic is handled in the parent component
 * and passed down through onClick.
 */
export default function DeviceNotificationsPreferenceButton({
    notificationsEnabled,
    hideText = false,
    ...otherProps
}: DeviceNotificationsPreferenceButtonProps): ReactElement {
    const text = notificationsEnabled ? "Stop alerts" : "Alert me";
    const icon = notificationsEnabled ? "bell-crossed" : "bell-ring";
    // Minimum width of the button set to avoid resizing when child text swaps.
    return (
        <Button
            variant="secondary"
            size={otherProps.size ?? "m"}
            iconLeft={icon}
            {...otherProps}>
            {hideText ? "" : text}
        </Button>
    );
}
