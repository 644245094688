import { Fragment, ReactElement } from "react";

import { Box, Collapse, Tooltip } from "@mui/material";

import { Card } from "components/common/Card";
import { DropdownButton } from "components/common/DropdownButton";
import Txt from "components/common/Text";
import { config } from "services/config";
import { useFeature } from "services/feature-flags";
import { useDeviceRole } from "services/hooks/useDeviceRole";
import { useUser } from "services/hooks/useUser";

import {
    useCultureCreationModalOpen,
    useLegacyCultureUploadType,
} from "./create-culture-state";
import { useResetCreateCultureState } from "./CreateCultureState";
import {
    CultureUploadType,
    useCultureSetupModalState,
} from "./culture-setup-state";
import { useDeviceUploadConditions } from "./protocol-upload-hooks";

type CreateCultureProps = {
    /**
     * Device ID
     */
    deviceId: string;
};

export function CreateCulture(props: CreateCultureProps): ReactElement {
    const { deviceId } = props;
    const { deviceCanAcceptCultureUpload } =
        useDeviceUploadConditions(deviceId);

    const cardDisplayed = deviceCanAcceptCultureUpload;

    return (
        <Fragment>
            <Collapse in={cardDisplayed}>
                <CreateCultureCard deviceId={deviceId} />
            </Collapse>
        </Fragment>
    );
}

function CreateCultureCard(props: { deviceId: string }) {
    const { deviceId } = props;
    const { isMytosUser } = useUser();
    const { canOperate } = useDeviceRole(deviceId);
    const { deviceHasCulture } = useDeviceUploadConditions(deviceId);

    const wetTestsEnabled =
        useFeature("wet_tests_beta").enabled || config.isLocal;

    const [, setModalOpen] = useCultureCreationModalOpen();

    const { resetCreateCultureState } = useResetCreateCultureState();
    const { setModalOpen: setCultureSetupModalOpen } =
        useCultureSetupModalState();

    const [, setLegacyCultureUploadType] = useLegacyCultureUploadType();

    const tooltipMessage: string = !canOperate
        ? "You do not have permission to control the device. Contact an admin to change your role."
        : "";

    return (
        <Card>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                    gap: 4,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <Txt font="secondary" level={7}>
                    {deviceHasCulture
                        ? "This device has a culture loaded. Upload another protocol to run multiple cultures."
                        : "To get started, create a new culture on this device by uploading a protocol."}
                </Txt>
                <Tooltip title={tooltipMessage}>
                    <DropdownButton
                        variant="primary"
                        options={[
                            {
                                dropdownLabel: "Culture",
                                value: "new-culture",
                                buttonLabel: "New culture",
                                dropdownLabelDescription:
                                    "Upload culture safely, ensuring device is ready",
                                onClick: wetTestsEnabled
                                    ? () => {
                                          resetCreateCultureState();
                                          setCultureSetupModalOpen(
                                              true,
                                              CultureUploadType.ServerOwned,
                                          );
                                      }
                                    : () => {
                                          setLegacyCultureUploadType(
                                              CultureUploadType.ServerOwned,
                                          );
                                          resetCreateCultureState();
                                          setModalOpen(true);
                                      },
                                disabled: !canOperate,
                            },
                            isMytosUser && {
                                dropdownLabel: "Development culture",
                                value: "new-development-culture",
                                buttonLabel: "Development culture",
                                dropdownLabelDescription:
                                    "Bypass device preparation - proceed with caution",
                                onClick: wetTestsEnabled
                                    ? () => {
                                          resetCreateCultureState();
                                          setCultureSetupModalOpen(
                                              true,
                                              CultureUploadType.Development,
                                          );
                                      }
                                    : () => {
                                          setLegacyCultureUploadType(
                                              CultureUploadType.Development,
                                          );
                                          resetCreateCultureState();
                                          setModalOpen(true);
                                      },
                                disabled: !canOperate,
                            },
                        ].filter(Boolean)}
                        size="l"
                    />
                </Tooltip>
            </Box>
        </Card>
    );
}
