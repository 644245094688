import { ReactElement, useState } from "react";

import styled from "@emotion/styled";
import { partial, round } from "lodash";

import Callout from "components/common/Callout";
import { CollapsibleCard } from "components/common/CollapsibleCard";
import Txt from "components/common/Text";

import { NamedInputRows, NamedNumericRows } from "../SelectProtocol/InputRows";

import {
    useCellPreparationInfo,
    UseCellPreparationInfo,
    useProtocolCellDataQuery,
} from "./useCellPreparationData";

export type CellPreparationViewContentProps = UseCellPreparationInfo;

export function CellPreparationView({
    protocolId,
}: {
    protocolId?: string;
}): ReactElement {
    const { protocolCellData } = useProtocolCellDataQuery(protocolId);

    const props = useCellPreparationInfo(protocolCellData);

    return <CellPreparationViewContent {...props} />;
}

function flaskNamesFromNumbers(numbers: number[]) {
    const flaskRangeString = numbers.join(", ");
    return `Flasks ${flaskRangeString}`;
}

export function CellPreparationViewContent({
    coatingTimeMinutes,
    cellPreparationStaticDetails,
    cellConcentration,
    flaskCellDetails,
    seedVesselCellCount,
    setSeedVesselCellCount: onChangeSeedVesselCellCount,
    setCellConcentration: onChangeConcentration,
    setSeedDensity: onChangeSeedDensity,
}: CellPreparationViewContentProps): ReactElement {
    const [expanded, setExpanded] = useState(false);

    let CalloutSection: ReactElement;
    if (!coatingTimeMinutes) {
        CalloutSection = <></>;
    } else {
        const coatingTimeHours = round(coatingTimeMinutes / 60, 1);
        const string = `There is a ${coatingTimeHours} hour coating step. Consider setting an alarm for cell preparation.`;
        CalloutSection = <Callout message={string} variant="warn"></Callout>;
    }

    return (
        <div
            style={{
                display: "flex",
                gap: 32,
                flexDirection: "column",
                width: "100%",
                height: "fit-content",
            }}>
            <div
                style={{
                    display: "flex",
                    gap: 12,
                    flexDirection: "column",
                }}>
                <Txt font="primary" level={5} emphasis>
                    Cell Preparation
                </Txt>
                <Callout variant="help">
                    See the Setup Guide for cell preparation instructions.
                </Callout>
                <Txt font="secondary" level={8}>
                    You will need to prepare a cell suspension for seeding at
                    the start of this culture. Optionally set your target
                    density below to determine what cell count to prepare.
                </Txt>
                {CalloutSection}
            </div>

            {cellPreparationStaticDetails.flaskGroupDetails.map(
                ({ flasks }, index) => {
                    return (
                        <NamedInputRows
                            key={index}
                            sectionName={flaskNamesFromNumbers(flasks)}
                            sectionDescription="Note that there may be some cell losses within the device."
                            rows={[
                                {
                                    type: "number",
                                    name: "Target cell density",
                                    onChange: partial(
                                        onChangeSeedDensity,
                                        index,
                                    ),
                                    value: flaskCellDetails[index].cellDensity,
                                    units: "/ cm²",
                                    autoComma: true,
                                },
                            ]}
                        />
                    );
                },
            )}

            <CollapsibleCard
                expanded={expanded}
                setExpanded={setExpanded}
                header={() => (
                    <CollapsibleCardTitleTxt level={8}>
                        Show me the working...
                    </CollapsibleCardTitleTxt>
                )}
                sx={{ boxShadow: null }}
                collapsibleContent={() => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: 24,
                        }}>
                        <DescriptionText level={9}>
                            The volumes and flask sizes are fixed within this
                            protocol.
                        </DescriptionText>

                        {cellPreparationStaticDetails.flaskGroupDetails.map(
                            ({ flasks, flaskSizeCm2, doseMl }, index) => {
                                return (
                                    <NamedNumericRows
                                        key={index}
                                        sectionName={flaskNamesFromNumbers(
                                            flasks,
                                        )}
                                        rows={[
                                            {
                                                name: "Dose volume",
                                                value: doseMl,
                                                units: "ml",
                                            },
                                            {
                                                name: "Flask size",
                                                value: flaskSizeCm2,
                                                units: "cm²",
                                            },
                                            {
                                                name: "Number of viable cells",
                                                value: flaskCellDetails[index]
                                                    .cellCount,
                                            },
                                        ]}
                                    />
                                );
                            },
                        )}
                    </div>
                )}></CollapsibleCard>

            <NamedInputRows
                sectionName="Preparation requirements"
                sectionDescription="Prepare the seeding tube with the following cell suspension."
                rows={[
                    {
                        type: "number",
                        name: "Cell suspension volume",
                        onChange: () => {},
                        value: cellPreparationStaticDetails.seedVesselCellSuspensionMl,
                        units: "ml",
                        disabled: true,
                        autoComma: true,
                    },
                    {
                        type: "number",
                        name: "Cell concentration",
                        onChange: onChangeConcentration,
                        value: cellConcentration,
                        units: "/ ml",
                        autoComma: true,
                    },
                    {
                        type: "number",
                        name: "Total viable cell count",
                        onChange: onChangeSeedVesselCellCount,
                        value: seedVesselCellCount,
                        autoComma: true,
                    },
                ]}></NamedInputRows>
        </div>
    );
}

const CollapsibleCardTitleTxt = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

const DescriptionText = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[600]};
`;
