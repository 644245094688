import { ReactElement, Suspense, lazy } from "react";

import { ApolloProvider } from "@apollo/client";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider as JotaiProvider } from "jotai";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
    Route,
    Routes,
    Navigate,
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import { AppBarWrapper } from "components/AppBarWrapper";
import ToastsProvider from "components/common/toasts/ToastsProvider";
import { Culture } from "components/pages/Culture";
import { Developer } from "components/pages/Developer";
import { Devices } from "components/pages/Devices";
import PageNotFound from "components/pages/PageNotFound";
import { Signin } from "components/pages/Signin";
import { WelcomePage } from "components/pages/Welcome";
import { RequireAuthentication } from "components/RequireAuthentication";
import { UpdateAvailableChecker } from "components/UpdateAvailableChecker";
import { client } from "services/apollo/client";
import { config } from "services/config";
import { FeatureFlagsProvider, useFeature } from "services/feature-flags";
import { useUserUiPreferences } from "services/hooks/useUserUiPreferences";
import defaultLog from "services/logger";
import { PostHog } from "services/posthog";
import { reactQueryClient } from "services/react-query/client";

import { MytosThemeProvider } from "./Theme";

const log = defaultLog.extend("App");

export default function App(): ReactElement {
    log.debug("Rendering the App");
    config.dump();

    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
    // window.addEventListener("online", e => console.log(e, "you're online"));
    // window.addEventListener("offline", e => console.log(e, "you're offline"));

    // This mirrors the Content-Security-Policy header set in netlify.toml
    // If you encounter any errors due to CSP please update here and also there
    const cspMetaContent = `frame-ancestors 'none';
    frame-src 'self' https://*.balena-devices.com/ app.netlify.com;
    object-src 'none';
    default-src 'self';
    script-src-elem 'self' https://*.lr-in-prod.com https://*.logr-ingest.com https://*.i.posthog.com;
    worker-src 'self' blob:;
    connect-src 'self' blob: data: https://*.mytos.bio https://*.sentry.io https://*.lr-in-prod.com https://*.logr-ingest.com https://*.i.posthog.com https://s3.us-east-2.amazonaws.com http://localhost:4000;
    img-src 'self' blob: data: https://s3.us-east-2.amazonaws.com;
    media-src 'self' blob: https://s3.us-east-2.amazonaws.com;
    style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
    style-src-elem 'self' 'unsafe-inline' https://fonts.googleapis.com;
    font-src 'self' https://fonts.gstatic.com data:
    `;
    const cspMeta = config.isLocal ? (
        <meta httpEquiv="Content-Security-Policy" content={cspMetaContent} />
    ) : (
        <></>
    );

    return (
        <HelmetProvider>
            <Helmet>
                {cspMeta}
                <meta name="theme-color" content="#fff" />
                <title>{config.envIdentifier}Mytos App</title>
            </Helmet>
            <PostHog>
                <JotaiProvider>
                    <ApolloProvider client={client}>
                        <QueryClientProvider client={reactQueryClient}>
                            <FeatureFlagsProvider>
                                <MytosThemeProvider>
                                    <ToastsProvider>
                                        <UpdateAvailableChecker />
                                        <RouterProvider router={router} />
                                    </ToastsProvider>
                                </MytosThemeProvider>
                            </FeatureFlagsProvider>
                        </QueryClientProvider>
                    </ApolloProvider>
                </JotaiProvider>
            </PostHog>
        </HelmetProvider>
    );
}

export const router = createBrowserRouter([
    // match everything with "*"
    { path: "*", element: <AppContent /> },
]);

function AppContent() {
    // Force the user preferences to be fetched on page load. This is important
    // because all other calls to useUserUiPreferences will return a cached
    // value. We need this fetch here because we write the Apollo cache to local
    // storage and we want to make sure we're not falling back to the old cached
    // version forever.
    useUserUiPreferences({
        fetchPolicy: "cache-and-network",
    });

    return (
        <>
            <CssBaseline />
            <AppBarWrapper>
                <AppSwitchboard />
            </AppBarWrapper>
        </>
    );
}

const Settings = lazy(() => import("components/pages/Settings"));
const Cultures = lazy(() => import("components/pages/Cultures"));
const Device = lazy(() => import("components/pages/Device"));
const Dashboard = lazy(() => import("components/pages/Dashboard"));
const SerialPage = lazy(() => import("components/pages/serial/SerialPage"));
const ImageResult = lazy(() => import("components/pages/ImageResult"));

function AppSwitchboard(): ReactElement {
    return (
        <Routes>
            {/* =============== Root =============== */}

            <Route
                path="/"
                element={
                    <RequireAuthentication>
                        <Navigate replace to="/devices" />
                    </RequireAuthentication>
                }
            />

            {/* =============== Login =============== */}

            <Route path={"/signin"} element={<Signin />} />

            <Route path={"/welcome"} element={<WelcomePage />} />

            {/* =============== Settings =============== */}

            <Route
                path="/settings/*"
                element={
                    <RequireAuthentication>
                        <Helmet>
                            <title>
                                {config.envIdentifier}Settings | Mytos
                            </title>
                        </Helmet>
                        <Suspense>
                            <Settings />
                        </Suspense>
                    </RequireAuthentication>
                }
            />

            {/* =============== Dashboard =============== */}

            {config.isLocal && (
                <Route
                    path="/dashboard"
                    element={
                        <RequireAuthentication>
                            <Helmet>
                                <title>
                                    {config.envIdentifier}Dashboard | Mytos
                                </title>
                            </Helmet>
                            <Suspense>
                                <Dashboard />
                            </Suspense>
                        </RequireAuthentication>
                    }
                />
            )}

            {/* =============== Cultures =============== */}

            {useFeature("app_cultures_page").enabled && (
                <Route
                    path="/cultures"
                    element={
                        <RequireAuthentication>
                            <Helmet>
                                <title>
                                    {config.envIdentifier}Cultures | Mytos
                                </title>
                            </Helmet>
                            <Suspense>
                                <Cultures />
                            </Suspense>
                        </RequireAuthentication>
                    }
                />
            )}

            {useFeature("app_culture_page").enabled && (
                <Route
                    path="/cultures/:cultureId/*"
                    element={
                        <RequireAuthentication>
                            <Culture />
                        </RequireAuthentication>
                    }
                />
            )}

            {/* =============== Devices =============== */}

            <Route
                path="/devices"
                element={
                    <RequireAuthentication>
                        <Helmet>
                            <title>{config.envIdentifier}Devices | Mytos</title>
                        </Helmet>
                        <Devices />
                    </RequireAuthentication>
                }
            />

            <Route
                path="/devices/:deviceIdentifier/*"
                element={
                    <RequireAuthentication>
                        <Suspense>
                            <Device />
                        </Suspense>
                    </RequireAuthentication>
                }
            />

            {/* =============== Results =============== */}

            {useFeature("app_results_feature").enabled && (
                <Route
                    path="/result"
                    element={
                        <RequireAuthentication>
                            <Navigate replace to="/results" />
                        </RequireAuthentication>
                    }
                />
            )}
            {useFeature("app_results_feature").enabled && (
                <Route
                    path="/results"
                    element={
                        <RequireAuthentication>
                            <Navigate replace to="/" />
                        </RequireAuthentication>
                    }
                />
            )}
            {useFeature("app_results_feature").enabled && (
                <Route
                    path="/results/:resultId"
                    element={
                        <RequireAuthentication>
                            <Helmet>
                                <title>
                                    {config.envIdentifier}Result | Mytos
                                </title>
                            </Helmet>
                            <Suspense>
                                <ImageResult />
                            </Suspense>
                        </RequireAuthentication>
                    }
                />
            )}

            {/* =============== Misc =============== */}

            {!config.isProd && (
                <Route path="/developer" element={<Developer />} />
            )}

            {(useFeature("serial_page").enabled || config.isLocal) && (
                <Route
                    path="/serial"
                    element={
                        <Suspense>
                            <SerialPage />
                        </Suspense>
                    }
                />
            )}

            <Route
                path="*"
                element={
                    <RequireAuthentication>
                        <PageNotFound />
                    </RequireAuthentication>
                }
            />
        </Routes>
    );
}
