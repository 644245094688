import { ReactElement } from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

import Callout from "../Callout";

import DeviceNotificationsPreference from "./DeviceNotificationsPreference";
import { useDeviceNotificationPreference } from "./useDeviceNotificationPreference";

export interface DeviceNotificationsPreferenceCard {
    deviceId: string;
    promptTo: "enable" | "disable";
}

export function DeviceNotificationsPreferenceCard(
    props: DeviceNotificationsPreferenceCard,
): ReactElement {
    const { deviceId, promptTo } = props;
    const { enabled } = useDeviceNotificationPreference(deviceId);
    const displayPrompt =
        promptTo === "disable" ? !!enabled : !(enabled ?? false);

    const currentState = promptTo === "disable" ? "enabled" : "disabled";
    return (
        <Collapse in={displayPrompt} easing={{ exit: "easeOut" }} timeout={750}>
            <Callout
                title={`Notifications currently ${currentState}`}
                style={{ marginTop: 16 }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 4,
                        flexDirection: {
                            xs: "column",
                            sm: "row",
                        },
                    }}>
                    <span>
                        {`Would you like to ${promptTo} notifications just for this device?`}
                    </span>
                    <DeviceNotificationsPreference
                        deviceId={deviceId}
                        size="m"
                    />
                </Box>
            </Callout>
        </Collapse>
    );
}
