import { ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";
import copy from "copy-to-clipboard";

import { gql } from "__generated__/apollo";
import {
    CultureState,
    ProcedureState,
    StepControl,
    StepState,
} from "__generated__/apollo/graphql";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { IconName } from "components/common/Icon";
import { MoreButton, MoreButtonOptions } from "components/common/MoreButton";
import {
    getToastifyMessages,
    useToasts,
} from "components/common/toasts/useToasts";
import { AddOperationModal } from "components/pages/Device/Schedule/AddOperationModal";
import { procedureIsUpcoming, stepStatePhaseMap } from "services/culture-utils";
import useStepControl from "services/hooks/server-minted-cultures/useStepControl";
import { usePauseCulture } from "services/hooks/useCultureControlLegacy";
import { useCultureOverviewSimple } from "services/hooks/useCultureOverview";
import { Step } from "services/hooks/useCultureProcedureSteps";
import { CULTURE_SCHEDULE_QUERY } from "services/hooks/useCultureSchedule";
import { useDeviceCultureState } from "services/hooks/useCultureState";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { log as parentLog } from "../log";

import { UpdateStepRuntimeReferenceModal } from "./UpdateStepRuntimeReferenceModal";

export const log = parentLog.extend("StepControls");

enum StepControlLabels {
    Skip = "Skip",
    Restore = "Restore",
    Cancel = "Cancel",
    CopyId = "Copy ID",
    DuplicateStep = "Duplicate step",
    AddOperation = "Add operation",
    UpdateRuntimeReference = "Update runtime reference",
}

type Control = {
    control: StepControl;
    /** Button label for control */
    label: StepControlLabels;
    /** Step states where the control can apply */
    states: (StepState | null)[];
    icon?: IconName;
};

const CONTROLS: {
    [key in Exclude<
        StepControl,
        StepControl.Confirm | StepControl.Unconfirm | StepControl.Pause
    >]: Control;
} = {
    REMOVE: {
        control: StepControl.Remove,
        label: StepControlLabels.Skip,
        states: [StepState.Planned],
        icon: "skip",
    },
    RESTORE: {
        control: StepControl.Restore,
        label: StepControlLabels.Restore,
        states: [StepState.Removed],
        icon: "sync-arrows",
    },
    CANCEL: {
        control: StepControl.Cancel,
        label: StepControlLabels.Cancel,
        states: [...stepStatePhaseMap.active],
        icon: "disable",
    },
};

export interface StepControlsProps {
    deviceId: string;
    cultureId: string;
    step: Step;
    procedureState?: ProcedureState | null;
}

export function StepControls({
    deviceId,
    cultureId,
    step,
    procedureState,
}: StepControlsProps): ReactElement {
    const { toastifyAction } = useToasts();

    const { canOperate } = useDeviceRole(deviceId);

    const { data: cultureOverview } = useCultureOverviewSimple({ cultureId });
    const deviceIsOnline = cultureOverview?.culture?.device?.isOnline ?? false;
    const cultureIsActive = cultureOverview?.culture?.isActive ?? false;

    const procedureIsInThePast = !procedureIsUpcoming(procedureState);

    const [addInvocationModalOpen, setAddInvocationModalOpen] = useState(false);
    const [attemptedControl, setAttemptedControl] =
        useState<StepControlLabels | null>(null);
    const [controlStep, { loading: controlSubmitting }] = useStepControl();
    const [duplicateStep] = useMutation(DUPLICATE_STEP_MUTATION);

    const pauseCulture = usePauseCulture(deviceId);
    const { data } = useDeviceCultureState(deviceId);
    const cultureState = data?.device?.culture?.state;
    const shouldPause = cultureState === CultureState.Running;

    const handleDuplicateStep = () =>
        toastifyAction(
            getToastifyMessages({
                entity: "step",
                verb: {
                    past: "duplicated",
                    gerund: "duplicating",
                },
            }),
            async () => {
                const output = await duplicateStep({
                    variables: {
                        input: {
                            deviceId,
                            stepId: step.id,
                        },
                    },
                    refetchQueries: [CULTURE_SCHEDULE_QUERY],
                });

                if (output.data?.duplicateStep.ok) {
                    return output;
                } else {
                    throw new Error(output.data?.duplicateStep.message);
                }
            },
        );

    const controlOptions: MoreButtonOptions = [
        {
            icon: "clipboard",
            label: StepControlLabels.CopyId,
            onClick: () => copy(step.id),
        },
    ];

    const allowUpdateStepRuntimeReference =
        procedureIsUpcoming(procedureState) &&
        !!step.runtimeReference?.referenceStep;

    const [
        updateStepRuntimeReferenceModalOpen,
        setUpdateStepRuntimeReferenceModalOpen,
    ] = useState(false);

    const cultureUploadedViaOldFlow = !cultureOverview?.culture?.mintedByServer;

    const onlyAllowForServerMinted = <
        T extends { disabled?: boolean; tooltip?: string },
    >(
        value: T,
    ): T => ({
        ...value,
        disabled: Boolean(cultureUploadedViaOldFlow || value.disabled),
        tooltip: cultureUploadedViaOldFlow
            ? "This functionality is only available for cultures uploaded via the new flow. To perform this operation please go to the old Schedule view."
            : value.tooltip,
    });

    if (canOperate && cultureIsActive) {
        controlOptions.push("divider");

        const duplicateWarningTooltip = !deviceIsOnline
            ? "Steps can only be duplicated when the device is online"
            : procedureIsInThePast
              ? "Only steps for upcoming procedures can be duplicated"
              : undefined;

        controlOptions.push(
            onlyAllowForServerMinted({
                label: StepControlLabels.DuplicateStep,
                icon: "copy" as const,
                onClick: () => {
                    if (shouldPause) {
                        setAttemptedControl(StepControlLabels.DuplicateStep);
                    } else {
                        void handleDuplicateStep();
                    }
                },
                disabled: duplicateWarningTooltip !== undefined,
                tooltip: duplicateWarningTooltip,
            }),
        );

        const addOperationWarningTooltip = !deviceIsOnline
            ? "Steps can only have operations added when the device is online"
            : procedureIsInThePast
              ? "Only steps for upcoming procedures can have operations added"
              : undefined;

        controlOptions.push(
            onlyAllowForServerMinted({
                icon: "plus" as const,
                label: StepControlLabels.AddOperation,
                onClick: () => {
                    if (shouldPause) {
                        setAttemptedControl(StepControlLabels.AddOperation);
                    } else {
                        setAddInvocationModalOpen(true);
                    }
                },
                disabled: addOperationWarningTooltip !== undefined,
                tooltip: addOperationWarningTooltip,
            }),
        );

        // Only show this if necessary, just clutters the dropdown if we show it
        // disabled
        if (allowUpdateStepRuntimeReference) {
            controlOptions.push(
                onlyAllowForServerMinted({
                    icon: "edit" as const,
                    label: StepControlLabels.UpdateRuntimeReference,
                    onClick: () => setUpdateStepRuntimeReferenceModalOpen(true),
                    disabled: procedureIsInThePast,
                    tooltip: procedureIsInThePast
                        ? "Only steps for upcoming procedures can have their runtime reference updated"
                        : undefined,
                }),
            );
        }

        Object.values(CONTROLS).forEach(control => {
            if (control.states.includes(step.state)) {
                controlOptions.push("divider");
                controlOptions.push(
                    onlyAllowForServerMinted({
                        icon: control.icon,
                        label: control.label,
                        onClick: async () =>
                            controlStep({
                                control: control.control,
                                stepId: step.id,
                                deviceId,
                            }),
                        disabled: controlSubmitting,
                    }),
                );
            }
        });
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            <MoreButton size="sm" options={controlOptions} />
            <ConfirmationDialog
                variant="advised"
                delayPrimaryAction
                open={Boolean(attemptedControl)}
                onPrimaryAction={async () => {
                    const paused = await pauseCulture();
                    if (paused) {
                        switch (attemptedControl) {
                            case StepControlLabels.AddOperation:
                                setAddInvocationModalOpen(true);
                                break;
                            case StepControlLabels.DuplicateStep:
                                void handleDuplicateStep();
                                break;
                            default:
                                break;
                        }
                    }
                    setAttemptedControl(null);
                }}
                onDismiss={() => {
                    setAttemptedControl(null);
                }}
                title={"Culture is running"}
                body={`Pausing the culture will allow you to ${attemptedControl}.`}
                primaryButtonText="Pause culture"
            />
            {allowUpdateStepRuntimeReference && (
                <UpdateStepRuntimeReferenceModal
                    step={step}
                    deviceId={deviceId}
                    modalOpen={updateStepRuntimeReferenceModalOpen}
                    setModalOpen={setUpdateStepRuntimeReferenceModalOpen}
                />
            )}
            {canOperate && cultureIsActive && (
                <AddOperationModal
                    stepId={step.id}
                    deviceId={deviceId}
                    modalOpen={addInvocationModalOpen}
                    setModalOpen={setAddInvocationModalOpen}
                />
            )}
        </div>
    );
}

const DUPLICATE_STEP_MUTATION = gql(`
    mutation DuplicateStep($input: DuplicateStepInput!) {
        duplicateStep(input: $input) {
            ok
            message
        }
    }
`);
