import { config } from "services/config";

import { log as parentLog } from ".";
import { createAuthHeader } from "./auth";
import { useFeature } from "./feature-flags";
import { createId, isPureObject } from "./utils";

const log = parentLog.extend("requests");

export function constructBalenaUrl(props: {
    balenaUuid?: string;
    token?: string;
    path: string;
}): string {
    return `https://${props.balenaUuid}.balena-devices.com${props.path}?token=${props.token}`;
}

export function detectBalenaError(data: unknown): boolean {
    if (Array.isArray(data)) {
        if (data?.includes("ECONNREFUSED" as never)) {
            return true;
        }
    }
    if (isPureObject(data)) {
        if (data?.code === "ECONNREFUSED") {
            return true;
        }
    }
    return false;
}

/**
 * Takes a Response and safely parses the body into a string or JSON type.
 *
 * @param response Response object
 * @returns Destructuring object
 */
export async function parseResponseBody(
    response: Response,
): Promise<string | Record<string, unknown>> {
    const text = await response.text();
    let body: string | Record<string, unknown> = text;
    try {
        const json = JSON.parse(text);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body = json as any; // TODO remove 'any' type cast
    } catch {
        // do nothing - we've safely caught and body has fallback
    }
    return body;
}

type fetchViaServerProxyDeviceProps = {
    deviceId: string;
    path: string;
    method?: "GET" | "POST";
    body?: Record<string, unknown>;
    headers?: Record<string, string>;
};

export function fetchViaServerProxyDevice({
    deviceId,
    path,
    method = "GET",
    body,
    headers,
}: fetchViaServerProxyDeviceProps): Promise<Response> {
    const authorization = createAuthHeader();
    const appliedHeaders = {
        "Content-Type": "application/json",
        "Authorization": authorization,
        "x-request-id": createId(),
        ...headers,
    };
    const requestInit: RequestInit = {
        credentials: "include",
        method: "POST",
        headers: appliedHeaders,
        body: JSON.stringify({
            deviceId,
            method,
            path,
            body,
        }),
    };
    const url = new URL(config.serverUrl);
    url.pathname = "/proxy/device";
    /**
     * Setting search params in the URL is not needed by the server's endpoint,
     * but gives us a method to get better names when reporting on the Network
     * tab of the Chrome Dev Tools.
     */
    url.searchParams.set("path", path.replaceAll("/", "_"));
    url.searchParams.set("deviceId", deviceId);
    return fetch(url.toString(), requestInit);
}

/**
 * Makes a function that will make a request to a Device endpoint using the
 * server's device proxy. This function handled origin resolution and
 * authentication.
 *
 * @param deviceId - Named arg: Device ID
 * @param method - Named arg: HTTP request method GET/POST
 * @param path - Named arg: path of device endpoint
 * @returns fetch function to be called
 */
export function useFetchViaServerProxyDevice(): typeof fetchViaServerProxyDevice {
    const mqttTransport = useFeature(
        "fetch_server_proxy_device_transport_mqtt",
    );
    return (args: fetchViaServerProxyDeviceProps) => {
        log.debug(
            "Fetch via server proxy with MQTT",
            mqttTransport.enabled,
            mqttTransport,
        );
        const { headers, ...otherArgs } = args;
        const mergedHeaders = handleTransportHeaders(
            headers,
            mqttTransport.enabled,
        );
        return fetchViaServerProxyDevice({
            ...otherArgs,
            headers: mergedHeaders,
        });
    };
}

/**
 * Utility function to handle transport headers.
 *
 * @param headers - Original headers to be added to the request
 * @param addMqttTransportHeader - Boolean to add mqtt transport header
 * @returns Processed headers
 */
export const handleTransportHeaders = (
    headers: Record<string, string> | undefined,
    addMqttTransportHeader: boolean,
): Record<string, string> => {
    const mqttTransportHeader = { "x-transport-method": "mqtt" };
    const mergedHeaders = {
        ...(addMqttTransportHeader ? mqttTransportHeader : {}),
        ...headers,
    };
    return mergedHeaders;
};
