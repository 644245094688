import React, { ReactElement, useState } from "react";

import { CultureControl, CultureState } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Callout from "components/common/Callout";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { DeviceNotificationsPreferenceCard } from "components/common/DeviceNotificationsPreference/DeviceNotificationsPreferenceCard";
import { TextInput } from "components/common/TextInput";
import { useToasts } from "components/common/toasts/useToasts";
import { useDeviceName } from "services/hooks/useDeviceName";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import useCultureControl from "../../../../services/hooks/server-minted-cultures/useCultureControl";
import { useDeviceSoftwareVersion } from "../Settings/DeviceSoftware";

import { log as parentLog } from "./log";

export const log = parentLog.extend("ClearCultureDialog");

interface ClearCultureDialogProps {
    cultureId: string;
    deviceId: string;
    disabled: boolean;
    cultureName: string;
    cultureState: CultureState | undefined;
    onCultureCleared?: () => Promise<void>;
    tooltip?: string;
}

export default function ClearCultureDialog(
    props: ClearCultureDialogProps,
): ReactElement {
    const {
        cultureId,
        deviceId,
        disabled: propDisabled,
        onCultureCleared,
        cultureName,
    } = props;
    const [open, setOpen] = React.useState(false);
    const { toast } = useToasts();
    const { name: deviceName } = useDeviceName(deviceId);
    const [typedDeviceName, setTypedDeviceName] = useState("");
    // Authentication
    const { canOperate } = useDeviceRole(deviceId);

    const data = useDeviceSoftwareVersion({ deviceId });
    const deviceWillUpdate = data?.currentCommit !== data?.targetCommit;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [handleCultureControl, { loading: clearing }] = useCultureControl({
        cultureId,
        onSuccess: async () => {
            setOpen(false);
            await onCultureCleared?.();
        },
    });

    const cultureRunningStates = [CultureState.Running, CultureState.Pausing];
    const cultureIsRunning =
        props.cultureState && cultureRunningStates.includes(props.cultureState);
    const cultureIsPaused = props.cultureState === CultureState.Paused;

    const disabled =
        !canOperate ||
        propDisabled ||
        props.cultureState === undefined ||
        cultureIsRunning;

    const confirmDisabled =
        disabled ||
        clearing ||
        (cultureIsPaused && typedDeviceName !== deviceName);

    const overrideTooltip =
        "You do not have permission to control the device. Contact an admin to change your role.";

    const tooltipForRunning = "The culture must be paused";

    const tooltip =
        props.tooltip ||
        (!canOperate && overrideTooltip) ||
        (cultureIsRunning && tooltipForRunning) ||
        "";

    const handleClearCulture = async () => {
        if (cultureIsRunning) {
            toast.error(
                "The culture must be paused before attempting to clear",
            );
            return;
        }

        await handleCultureControl({ control: CultureControl.Clear });
    };

    return (
        <React.Fragment>
            <Button
                variant="secondary"
                disabled={disabled}
                tooltip={tooltip}
                iconLeft="bin"
                onClick={handleOpen}
                style={{ minWidth: 200 }}>
                Clear Culture
            </Button>
            <ConfirmationDialog
                variant="destructive"
                open={open}
                primaryButtonDisabled={confirmDisabled}
                onSecondaryAction={handleClose}
                onDismiss={handleClose}
                onPrimaryAction={handleClearCulture}
                title={"Clear the Culture"}
                body={
                    <>
                        By clearing the <strong>{cultureName}</strong> culture
                        from the device, all past and upcoming steps in the
                        schedule will be removed from the device.{" "}
                        <strong>This action is irreversible.</strong>
                    </>
                }
                primaryButtonText="Clear"
                secondaryButtonText="Cancel">
                {/* enforce min padding (will merge with next element) */}
                {cultureIsPaused && (
                    <div>
                        <p>
                            <strong>
                                The culture on {deviceName} is paused. Type the
                                name of the device to confirm:
                            </strong>
                        </p>
                        <TextInput onChange={v => setTypedDeviceName(v)} />
                    </div>
                )}
                <div style={{ marginTop: 20 }}></div>
                {deviceWillUpdate && (
                    <Callout
                        variant="warn"
                        message="This device will automatically update software after the culture is cleared. It will go offline for a few minutes."
                        style={{ marginTop: 8 }}
                    />
                )}
                <DeviceNotificationsPreferenceCard
                    deviceId={deviceId}
                    promptTo="disable"
                />
                {cultureIsRunning && (
                    <Callout
                        variant="error"
                        message="The culture is currently running. It must be paused before it can be cleared."
                        style={{ marginTop: 8 }}
                    />
                )}
            </ConfirmationDialog>
        </React.Fragment>
    );
}
