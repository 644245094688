import { useState, ReactElement } from "react";

import { styled } from "@mui/material";
import { DayPicker, Matcher } from "react-day-picker";

import "react-day-picker/dist/style.css";
import { fontFamilyMap } from "./Text";

export interface DatePickerProps {
    /**
     * Handler function that receives new Date object every time its updated in
     * the picker.
     */
    onChange: (date: Date) => void;
    /**
     * Initial default value for the picker, which is overrided by the internal
     * state of the picker as it's used.
     */
    defaultValue?: Date;
    /**
     * Disable days
     */
    disabled?: Matcher | Matcher[];
}

/**
 * A full, static Date Picker. Based on
 * https://react-day-picker.js.org/
 */
export function DatePicker({
    onChange,
    defaultValue,
    disabled,
}: DatePickerProps): ReactElement {
    const [selected, setSelected] = useState<Date | undefined>(defaultValue);
    return (
        <DatePickerContainer>
            <DayPicker
                mode="single"
                required
                defaultMonth={selected}
                selected={selected}
                disabled={disabled}
                onSelect={d => {
                    setSelected(d);
                    onChange(d ?? new Date());
                }}
                styles={{
                    day: {
                        borderRadius: 4,
                    },
                }}
            />
        </DatePickerContainer>
    );
}

const DatePickerContainer = styled("div")(({ theme }) => ({
    display: "inline-flex",
    border: "1px solid",
    borderColor: theme.colours.neutral[300],
    borderRadius: 4,
    fontFamily: fontFamilyMap.secondary,
}));
