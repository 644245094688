import { ReactElement } from "react";

import MuiSkeleton, {
    SkeletonProps as MuiSkeletonProps,
} from "@mui/material/Skeleton";

type SkeletonProps = MuiSkeletonProps & {
    /**
     * Decimal representation of desired aspect ratio.
     *
     * E.g. an aspect ratio of 4:3 (width:height) would be 4/3
     */
    aspectRatio?: number;
};

/**
 * A placeholder componenet for when data is still loading and has response has
 * not returned yet.
 *
 * This specific implementation is a necessary wrapper of the Material UI
 * library Skeleton component: https://material-ui.com/api/skeleton/
 *
 * @param props Standard props for Material UI Skeleton component
 */
export default function Skeleton(props: SkeletonProps): ReactElement {
    const { style, aspectRatio, ...otherProps } = props;

    const paddingTop: string | undefined = aspectRatio
        ? String((1 / aspectRatio) * 100) + "%"
        : undefined;

    const extendedStyle = Object.assign<React.CSSProperties, typeof style>(
        {
            transform: "none",
            borderRadius: props.variant === "rectangular" ? 4 : undefined,
            color: "transparent",
            paddingTop,
            ...(paddingTop && { height: 0 }),
        },
        style,
    );
    return <MuiSkeleton {...otherProps} style={extendedStyle} />;
}
