import { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import { useWindowWidth } from "@react-hook/window-size";
import { cloneDeep } from "lodash";

import FlaskMap from "components/common/FlaskMap";
import Skeleton from "components/common/Skeleton";
import Txt from "components/common/Text";
import {
    dayjs,
    cultureMorningOrAfternoon,
    dayOfCultureFactory,
} from "services/date";
import useInfiniteScroll from "services/hooks/useInfiniteScroll";
import { clamp, ordinalConversion, removeNullables } from "services/utils";

import { useGridSettings } from "./device-images-state";
import {
    findImagesClosestToFlaskPositions,
    isResultDataFlaskImages,
} from "./device-images-utils";
import {
    CultureQueryFlaskImageData,
    CultureQueryImageResult,
} from "./DeviceImages";
import { GridImage } from "./GridImage";
import { log as parentLog } from "./log";

export const log = parentLog.extend("ResultGrid");

export const FLASK_POSITIONS = [
    "top_left",
    "top_centre",
    "top_right",
    "middle_left",
    "middle_centre",
    "middle_right",
    "bottom_left",
    "bottom_centre",
    "bottom_right",
] as const;

export type FlaskPosition = (typeof FLASK_POSITIONS)[number];
type HiddenRowsState = Record<FlaskPosition, boolean>;

export type ColumnSize = "s" | "m" | "l";

const COLUMN_SIZE_PX: { [k in ColumnSize]: number } = {
    s: 250,
    m: 340, // previously 450
    l: 640,
};

export type ResultGridProps = {
    results: (CultureQueryImageResult | null)[];
    columnSize?: ColumnSize;
    startDate?: string;
    /**
     * Called when the user scrolls to the end of the grid for infinite scroll functionality.
     */
    onLoadMore?: () => void;
    loadingMore?: boolean;
    dayStartIndex: number;
};

export function ResultGrid({
    results,
    columnSize = "m",
    startDate,
    onLoadMore,
    loadingMore,
    dayStartIndex,
}: ResultGridProps): ReactElement {
    const { customGridSettings, rows: rowsSetting } = useGridSettings();
    const [hiddenRows, setHiddenRows] = useState<HiddenRowsState>(() => {
        const initialState = FLASK_POSITIONS.reduce<HiddenRowsState>(
            (prev, current) => {
                return {
                    ...prev,
                    [current]: false,
                };
            },
            {} as HiddenRowsState,
        );
        return initialState;
    });

    const toggleRowHide = (pos: FlaskPosition) => {
        setHiddenRows(prev => {
            const previousValue = prev[pos];
            return { ...prev, [pos]: !previousValue };
        });
    };

    const numResults = results.length;
    const columns = numResults + 1;
    const positionNames = cloneDeep(FLASK_POSITIONS);
    const rows = customGridSettings
        ? rowsSetting + 1
        : positionNames.length + 1;

    const labelColumn: ReactElement[] = [<div key={-1} />];
    const gridColumnsArrays: ReactElement[][] = [labelColumn];

    if (customGridSettings) {
        gridColumnsArrays.push(
            ...generateResultColumnsArrayAllImages({
                results,
                dayStartIndex,
                startDate,
            }),
        );
        labelColumn.push(
            ...Array(rowsSetting)
                .fill(0)
                .map((k, i) => {
                    return <SimpleRowLabel key={i} positionIndex={i} />;
                }),
        );
    } else {
        gridColumnsArrays.push(
            ...generateResultColumnsArrayNamedPositions({
                results,
                dayStartIndex,
                startDate,
            }),
        );
        labelColumn.push(
            ...positionNames.map((k, i) => {
                return (
                    <RowLabel
                        key={i}
                        positionName={k}
                        positionIndex={i}
                        isRowHidden={hiddenRows[k]}
                        toggleHideCallback={toggleRowHide}
                    />
                );
            }),
        );
    }

    const gridData: ReactElement[] = [];
    for (let r = 0; r < rows; r++) {
        for (let c = 0; c < columns; c++) {
            gridData.push(gridColumnsArrays[c][r]);
        }
        if (loadingMore) {
            gridData.push(
                <Skeleton key={`loading-${r}`} width="100%" height="100%" />,
            );
        }
    }
    const numberOfColumnsToRender = numResults + (loadingMore ? 1 : 0);

    const { boundaryRef, setLastInfiniteScrollElementRef } = useInfiniteScroll({
        loadMoreThresholdPx: 1000,
        onLoadMore: () => {
            // if the user has set a custom grid, we don't want to load more results otherwise we'll mess up their settings
            if (!customGridSettings) {
                onLoadMore?.();
            }
        },
    });

    // the below padding calculator provides padding on very large screens so
    // that the grid does not end at the edges of the screen
    const windowWidth = useWindowWidth();
    const gutterSize = (windowWidth - 1920) / 2;
    const validGutter = clamp(gutterSize, { min: 0 });
    const padding = 12 + validGutter;

    return (
        <div>
            <div
                ref={boundaryRef}
                css={css`
                    display: grid;
                    grid-template-columns: min-content repeat(
                            ${numberOfColumnsToRender},
                            ${COLUMN_SIZE_PX[columnSize]}px
                        );
                    gap: 4px;
                    /* overflow-y: auto; */
                    overflow-x: auto;
                    direction: rtl;
                    padding: 0px ${padding}px;
                    position: relative;
                `}>
                {gridData.map((e, i) => {
                    const key = `grid-item-${e?.key ?? i}`;
                    return (
                        <div
                            id={`grid-item-${i}`}
                            ref={
                                i === numResults
                                    ? setLastInfiniteScrollElementRef
                                    : undefined
                            }
                            key={key}
                            style={{
                                direction: "ltr",
                            }}>
                            {e}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function generateResultColumnsArrayAllImages({
    results,
    dayStartIndex = 0,
    startDate,
}: {
    results: (CultureQueryImageResult | null)[];
    dayStartIndex?: number;
    startDate?: string;
}): ReactElement[][] {
    const resultColumns = results.map((result, resultIdx) => {
        const resultData = result?.data;
        const images = removeNullables(
            isResultDataFlaskImages(resultData)
                ? (resultData.images ?? [])
                : [],
        );
        const label = `${ordinalConversion(resultIdx + 1)} Most Recent`;
        const resultId = result?.id ?? resultIdx;
        const resultArray: ReactElement[] = [
            <ResultHeader
                result={result}
                label={label}
                key={`result-${resultId}-pos-header`}
                startDate={startDate}
                dayStartIndex={dayStartIndex ?? 0}
            />,
            ...images.map((p, pIdx) => {
                const key = `result-${resultId}-pos-${pIdx}`;
                const value = p;
                return (
                    <div
                        id={`image-container-${pIdx}`}
                        key={key}
                        style={{ height: "100%" }}>
                        <GridImage value={value} />
                    </div>
                );
            }),
        ];

        return resultArray;
    });
    return resultColumns; //.reverse();
}

function generateResultColumnsArrayNamedPositions({
    results,
    dayStartIndex,
    startDate,
}: {
    results: (CultureQueryImageResult | null)[];
    dayStartIndex?: number;
    startDate?: string;
}): ReactElement[][] {
    const resultColumns = results.map((result, resultIdx) => {
        type FlaskPositionImageData = Record<
            FlaskPosition,
            CultureQueryFlaskImageData | null
        >;
        const positions = FLASK_POSITIONS.reduce<FlaskPositionImageData>(
            (prev, current) => {
                return {
                    ...prev,
                    [current]: null,
                };
            },
            {} as FlaskPositionImageData,
        );

        const resultData = result?.data;

        if (isResultDataFlaskImages(resultData)) {
            const imageData =
                findImagesClosestToFlaskPositions(resultData) ?? [];
            imageData.map((v, index) => {
                const posName = FLASK_POSITIONS[index];
                if (posName) positions[posName] = v;
            });
        }

        const label = `${ordinalConversion(resultIdx + 1)} Most Recent`;
        const resultId = result?.id ?? resultIdx;
        const resultArray: ReactElement[] = [
            <ResultHeader
                result={result}
                label={label}
                key={`result-${resultId}-pos-header`}
                startDate={startDate}
                dayStartIndex={dayStartIndex}
            />,
            ...FLASK_POSITIONS.map((p, pIdx) => {
                const key = `result-${resultId}-pos-${pIdx}`;
                const value = positions[p];
                return (
                    <div
                        id={`image-container-${pIdx}`}
                        key={key}
                        style={{ height: "100%" }}>
                        <GridImage value={value} />
                    </div>
                );
            }),
        ];

        return resultArray;
    });

    return resultColumns; //.reverse();
}

function RowLabel(props: {
    positionName: FlaskPosition;
    positionIndex: number;
    isRowHidden: boolean;
    toggleHideCallback: (pos: FlaskPosition) => void;
}): ReactElement {
    const {
        positionIndex,
        isRowHidden,
        // positionName,
        // toggleHideCallback
    } = props;
    // const tidyName = toTitleCase(positionName.replace("_", " "));
    return (
        <div
            css={css`
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 4px;
            `}>
            {!isRowHidden && (
                <span style={{ marginBottom: 8 }}>
                    <FlaskMap
                        size="s"
                        activePosition={positionIndex}
                        setActivePosition={() => undefined}
                        colour="black"
                    />
                </span>
            )}
            {/* <Txt level={9} emphasis align="center">
                {tidyName}
            </Txt>
            <Button
                variant="tertiary"
                size="s"
                iconLeft={!isRowHidden ? "eye-closed" : "eye-open"}
                onClick={() => toggleHideCallback(positionName)}
                style={{ minWidth: 52 }}>
                {isRowHidden ? "Show" : "Hide"}
            </Button> */}
        </div>
    );
}

function SimpleRowLabel(props: { positionIndex: number }): ReactElement {
    const { positionIndex } = props;

    return (
        <div
            css={css`
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 4px;
            `}>
            <Txt level={9} emphasis align="center">
                {positionIndex}
            </Txt>
        </div>
    );
}

function ResultHeader(props: {
    result: CultureQueryImageResult | null;
    label: string;
    startDate?: string;
    dayStartIndex?: number;
}): ReactElement {
    const { result, startDate, dayStartIndex } = props;
    const theme = useTheme();
    const dayOfCulture = dayOfCultureFactory(startDate);
    const morningOrAfternoon = cultureMorningOrAfternoon(startDate);

    const resultData = result?.data;
    const flaskId =
        (isResultDataFlaskImages(resultData) && resultData.flask?.flaskId) ??
        "Missing flask ID";
    log.debug("flask id", flaskId);

    const ts = result?.timestampISO ?? undefined;

    const time = ts
        ? dayjs(ts).calendar(null, {
              sameDay: "[Today at] HH:mm (z)", // Today at 02:30 (GMT)
              lastDay: "[Yesterday at] HH:mm (z)", // Yesterday at 15:30 (GMT)
              lastWeek: "[Last] dddd [at] HH:mm (z)", // Last Monday at 15:30 (GMT)
              sameElse: "HH:mm dddd D MMM (z)", // 15:30 Monday 23 Jan 2023 (GMT)
          })
        : "some time ago";

    const day = ts ? dayOfCulture(ts) : "unknown";
    const mOrA = morningOrAfternoon(ts);
    const timeOfDay =
        mOrA === null ? "" : mOrA === 0 ? " - Morning" : " - Afternoon";
    const areDayValuesNumbers =
        typeof dayStartIndex === "number" && typeof day === "number";
    const dayTitle = `Day ${areDayValuesNumbers ? day + dayStartIndex : day} ${timeOfDay}`;

    return (
        <div
            css={css`
                /* align-self: start; */
                /* position: sticky;
                top: 64px; // matches AppBar height (64px)
                z-index: 10;
                background-color: yellow; */

                display: flex;
                flex-direction: column;
                padding: 4px;
                padding-bottom: 8px;
                border-bottom: 2px solid ${theme.colours.neutral[900]};
            `}>
            <Txt emphasis level={7}>
                {dayTitle}
            </Txt>
            <Txt
                font="secondary"
                level={9}
                style={{ color: theme.colours.neutral[700] }}>
                {time}
            </Txt>
        </div>
    );
}
