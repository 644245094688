import styled from "@emotion/styled";

export const InfoPill = styled.div<{ opacity?: number }>`
    display: flex;
    align-items: center;
    height: fit-content;
    width: fit-content;
    gap: 4px;
    border: 1px solid ${({ theme }) => theme.colours.neutral[300]};
    border-radius: 20px;
    padding-left: 8px;
    padding-right: 8px;
    opacity: ${({ opacity }) => opacity ?? 1};

    & > :not(:last-child) {
        padding-right: 4px;
        border-right: 1px solid ${({ theme }) => theme.colours.neutral[300]};
    }
`;
