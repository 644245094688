import { ReactElement } from "react";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { lowerCase, upperFirst } from "lodash";

import Icon from "components/common/Icon";
import Txt from "components/common/Text";
import { useSimulationErrorDetails } from "services/hooks/useCultureSchedule";
import { Invocation } from "services/hooks/useCultureStepInvocations";

import { log as parentLog } from "../Procedures/log";

import InvocationDetailsModal, {
    useInvocationDetailsModalState,
} from "./InvocationDetailsModal";
import { InvocationStateIconContainer } from "./InvocationStateIcon";

export const log = parentLog.extend("InvocationItem");

export interface InvocationItemProps {
    deviceId: string;
    invocation: Invocation;
}

export function InvocationItem({
    invocation,
    deviceId,
}: InvocationItemProps): ReactElement {
    const detailsModalState = useInvocationDetailsModalState();

    const detailsModalOpen =
        detailsModalState.openInvocationId === invocation.id;

    const setDetailsModalOpen = (open: boolean) =>
        detailsModalState.setOpenState(invocation.id, open);

    // Operations have a description property which would ideally be used as a
    // fallback if the description on the invocation is no provided. For the
    // moment a basic string formatting of the operationId is good enough.

    const operationName = invocation.operationId
        ? upperFirst(lowerCase(invocation.operationId))
        : "Unknown";

    const invocationDescription =
        invocation.description && invocation.description.length
            ? invocation.description
            : operationName;

    const simulationErrorDetails = useSimulationErrorDetails();

    const hasSimulationError =
        simulationErrorDetails?.problemInvocation?.id === invocation?.id;

    const theme = useTheme();

    return (
        <div>
            <InvocationItemContainer
                onClick={e => {
                    e.stopPropagation();
                    setDetailsModalOpen(true);
                }}>
                <InvocationStateIconContainer
                    invocation={invocation}
                    hasSimulationError={hasSimulationError}
                />
                <Txt font="secondary" level={9}>
                    {invocationDescription}
                </Txt>
                <Icon
                    name="chevron-right"
                    sx={{
                        marginLeft: "auto",
                    }}
                />
            </InvocationItemContainer>
            {hasSimulationError && (
                <Txt
                    level={9}
                    style={{
                        color: theme.colours.red[600],
                        marginLeft: "36px",
                    }}>
                    Error seen during simulation:{" "}
                    {simulationErrorDetails.errorMessage}
                </Txt>
            )}
            {invocation.errorMessage && (
                <Txt
                    level={9}
                    style={{
                        color: theme.colours.red[600],
                        marginLeft: "36px",
                    }}>
                    {invocation.errorMessage}
                </Txt>
            )}
            <InvocationDetailsModal
                deviceId={deviceId}
                invocation={invocation}
                setModalOpen={setDetailsModalOpen}
                isOpen={detailsModalOpen}
            />
        </div>
    );
}

const InvocationItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer !important;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: ${({ theme }) => theme.colours.neutral[900]};
    }
`;
