import { ReactElement } from "react";

import styled from "@emotion/styled";

import BetaCallout from "components/common/BetaCallout";
import Callout from "components/common/Callout";
import { Card } from "components/common/Card";
import Graph from "components/common/Graph/Graph";
import { Select } from "components/common/Select";
import Skeleton from "components/common/Skeleton";
import StatusBadge from "components/common/StatusBadge";
import Txt from "components/common/Text";
import {
    menuOptionDetailsFromFlask as selectDetailsFromFlask,
    useFlaskIds,
} from "components/pages/Device/Images/SelectFlask";
import { isDefined } from "services/utils";

import { log as parentLog } from "../log";

import { DownloadFlaskConfluenceButton } from "./DownloadConfluence";
import { useFlaskConfluence } from "./useFlaskConfluence";

export const log = parentLog.extend("GraphCard");

export interface GraphCardProps {
    cultureId?: string;
}

export interface GraphCardContentProps {
    cultureId: string;
}
export interface ConfluenceGraphProps {
    cultureId: string;
    flaskId: string | undefined;
}

export default function GraphCard({ cultureId }: GraphCardProps): ReactElement {
    return (
        <ConfluenceCard>
            <FlexRow>
                <CardTitle level={4}>Confluence</CardTitle>
                <StatusBadge text="Beta" size="md" status="beta" />
            </FlexRow>
            {cultureId ? (
                <GraphCardContent cultureId={cultureId} />
            ) : (
                <CardContent>
                    <Callout variant="info" message="No culture loaded" />
                </CardContent>
            )}
        </ConfluenceCard>
    );
}

export function GraphCardContent({
    cultureId,
}: GraphCardContentProps): ReactElement {
    const {
        selectedFlaskReference,
        numberImagedFlasks,
        flasks,
        loading,
        setSelectedFlaskId,
    } = useFlaskIds({ cultureId });

    if (numberImagedFlasks > 0) {
        const selectOptions = flasks
            .map(selectDetailsFromFlask)
            .filter(isDefined);

        const selectedFlask = flasks.find(
            flask => flask.containerReference === selectedFlaskReference,
        );
        const selectedFlaskNumber = selectedFlask?.flaskNumber;
        const selectedFlaskName = selectedFlaskNumber
            ? `Flask ${selectedFlaskNumber}`
            : null;

        return (
            <>
                <FlaskContainer>
                    <Select
                        defaultValue={selectedFlaskReference}
                        options={selectOptions}
                        onChange={setSelectedFlaskId}
                        maxWidth={110}
                    />
                    <DownloadFlaskConfluenceButton
                        flaskId={selectedFlaskReference ?? undefined}
                        flaskName={selectedFlaskName}
                        cultureId={cultureId}
                    />
                </FlaskContainer>
                {selectedFlaskReference && (
                    <CardContent>
                        <BetaCallout
                            description="The confluence analysis feature is in a private
                                beta stage of development and may not be
                                accurate. Mytos does not recommend using this
                                data for any critical decisions or analysis."
                            style={{
                                marginBottom: "2rem",
                            }}
                        />
                        <ConfluenceGraph
                            flaskId={selectedFlaskReference}
                            cultureId={cultureId}
                        />
                    </CardContent>
                )}
            </>
        );
    }
    if (flasks.length > 0) {
        return (
            <CardContent>
                <Callout
                    variant="info"
                    message="No flasks have been imaged in the current culture"
                />
            </CardContent>
        );
    }

    if (loading) {
        return (
            <CardContent>
                <Skeleton height={300} />
            </CardContent>
        );
    }

    return (
        <CardContent>
            <Callout
                variant="info"
                message="Culture does not have any flasks"
            />
        </CardContent>
    );
}

export function ConfluenceGraph({
    flaskId,
    cultureId,
}: ConfluenceGraphProps): ReactElement {
    const { datapoints, startDate, loading, error, dayStartIndex } =
        useFlaskConfluence(cultureId, flaskId);
    if (error)
        log.error({ error }, "Error occurred retrieving flask confluence");
    if (datapoints.length > 0)
        return (
            <GraphWithHeight>
                <Graph
                    entries={datapoints}
                    startDate={startDate}
                    dayStartIndex={dayStartIndex}
                />
            </GraphWithHeight>
        );

    if (loading) return <Skeleton height={300} />;
    if (error)
        return (
            <Callout
                variant="error"
                message="Error occurred retrieving flask confluence"
            />
        );
    return <Callout variant="info" message="No confluence data available" />;
}

const ConfluenceCard = styled(Card)`
    display: grid;
    grid-template-columns: auto 1fr; /* Two columns */
    grid-template-rows: auto 1fr; /* Two rows */
    gap: 20px; /* Gap between grid items */
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        grid-template-columns: auto; /* Two columns */
        grid-template-rows: auto; /* Two rows */
    }
`;

const CardTitle = styled(Txt)`
    grid-column: 1 / span 1; /* Start from second column and span 1 column */
    grid-row: 1 / span 1; /* Start from first row and span 1 row */
`;

const GraphWithHeight = styled.div`
    height: 300px;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const FlaskContainer = styled.div`
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    display: flex;
    gap: 8px;
    justify-content: flex-end; /* Right-align content */
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        justify-content: start; /* Right-align content */
    }
`;

const CardContent = styled.div`
    grid-column: 1 / span 2;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        grid-column: 1 / span 1;
    }
`;
