import { Fragment, ReactElement } from "react";

import { useQuery } from "@apollo/client";
import { css } from "@emotion/react";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import { gql } from "__generated__/apollo";
import { DeviceSettingQuery } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Callout from "components/common/Callout";
import { CodeText } from "components/common/CodeText";
import Section from "components/common/Section";
import Skeleton from "components/common/Skeleton";
import StatusBadge from "components/common/StatusBadge";
import { Switch } from "components/common/Switch";
import { TabContent } from "components/common/TabContent";
import Txt from "components/common/Text";
import { TextLabel } from "components/common/TextLabel";
import {
    formatDatetime,
    formatUptime,
    relativeDatetime,
    unixToISO,
} from "services/date";
import { useFeature } from "services/feature-flags";
import { useDeviceRole } from "services/hooks/useDeviceRole";
import { useUser } from "services/hooks/useUser";

import { log as parentLog } from "../log";

import ChangeBalenaUuidDialog from "./ChangeBalenaUuidDialog";
import ChangeMaintenanceDialog from "./ChangeMaintenanceDialog";
import { DeviceSoftwareCard } from "./DeviceSoftware";
import { DeviceUsers } from "./DeviceUsers/DeviceUsers";
import { useDeviceOverrideLock } from "./useDeviceOverrideLock";

export const log = parentLog.extend("DeviceSettings");

export const DeviceSettingQueryName = "DeviceSetting";

const DEVICE_SETTINGS_QUERY = gql(`
    query DeviceSetting($deviceId: String!) {
        device(id: $deviceId) {
            id
            isOnline
            isInMaintenance
            maintenanceUntil
            envVarConfigOk
            name
            status
            url
            balena {
                deviceUuid
                deviceName
                deviceTypeName
                isOnline
                location
                ipAddresses
                macAddresses
                publicAddress
                lastConnected
                fleetName
                overrideLockEnabled
            }
            meta {
                boot {
                    startTime
                    uptime
                }
                network {
                    connectionType
                    localIp
                    networkName
                    uptime
                }
                equipment {
                    kits {
                        name
                        commitHash
                        environment
                    }
                }
            }
        }
    }
`);

type Device = NonNullable<DeviceSettingQuery["device"]>;
type Meta = NonNullable<Device["meta"]>;
type Equipment = NonNullable<Meta["equipment"]>;
type Kits = NonNullable<Equipment["kits"]>;
type Kit = NonNullable<Kits[number]>;

interface DeviceSettingsProps {
    deviceId: string;
}

export function DeviceSettings(props: DeviceSettingsProps): ReactElement {
    const { deviceId } = props;
    const { canOperate } = useDeviceRole(deviceId);
    const { data, loading, error } = useQuery(DEVICE_SETTINGS_QUERY, {
        variables: { deviceId },
    });
    const advancedInfoEnabled = useFeature(
        "app_device_settings_advanced",
    ).enabled;

    log.debug("Device Settings response", { data, error });

    if (!canOperate) {
        return (
            <TabContent>
                <Callout variant="info">
                    You are a viewer of this device. You will be unable to make
                    any edits, or view certain information. Contact an admin to
                    change your role.
                </Callout>
            </TabContent>
        );
    }

    if (error) log.error("Error with Device Settings query", { error, data });
    if (data?.device) {
        // const deviceMeta: DeviceSettingQuery_device_meta = !config.isLocal
        //     ? data.device?.meta ?? ({} as DeviceSettingQuery_device_meta)
        //     : {
        //           __typename: "DeviceMetaInfo",
        //           boot: {
        //               uptime: 7200,
        //               startTime: 1622120493,
        //               __typename: "DeviceBootInfo",
        //           },
        //           network: {
        //               __typename: "DeviceNetworkInfo",
        //               networkName: "Fake-Network-10H",
        //               connectionType: NetworkConnectionType.WIRELESS,
        //               uptime: 7200,
        //           },
        //           equipment: {
        //               __typename: "DeviceEquipmentInfo",
        //               kits: [
        //                   {
        //                       __typename: "DeviceKitInfo",
        //                       name: "MicroscopeGantry",
        //                       commitHash: "a1b2c3",
        //                       environment: "test-multi-pump",
        //                   },
        //               ],
        //           },
        //       };
        const deviceMeta: Meta = data.device?.meta ?? ({} as Meta);

        const st = deviceMeta.boot?.startTime;
        const deviceStartTime: string = st
            ? formatDatetime(unixToISO(st))
            : "Unknown";
        const deviceUptime: string = st ? relativeDatetime(unixToISO(st)) : "";
        const isInMaintenance = data.device.isInMaintenance;

        return (
            <TabContent>
                <DeviceSoftwareCard deviceId={deviceId} />
                <Section heading="General">
                    <GeneralInfo
                        deviceId={deviceId}
                        deviceName={data.device.name ?? "none"}
                        deviceStartTime={deviceStartTime}
                        deviceUptime={deviceUptime}
                        isInMaintenance={isInMaintenance}
                        maintenanceUntil={data.device.maintenanceUntil}
                        configOk={data.device.envVarConfigOk === true}
                    />
                </Section>
                <NetworkInfo meta={deviceMeta} />
                <Section heading="Users">
                    <DeviceUsers deviceId={deviceId} />
                </Section>
                {advancedInfoEnabled && (
                    <>
                        <Section heading="Equipment">
                            <TextLabel label="Kits">
                                <div
                                    css={css`
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 8px;
                                    `}>
                                    {deviceMeta.equipment?.kits.length ? (
                                        deviceMeta.equipment?.kits.map(
                                            (k, i) => (
                                                <KitChip key={i} data={k} />
                                            ),
                                        )
                                    ) : (
                                        <Txt level={6}>None</Txt>
                                    )}
                                </div>
                            </TextLabel>
                        </Section>
                        <Section heading="Advanced (Balena)">
                            <BalenaInfo device={data.device} />
                        </Section>
                        <Section heading="Legacy">
                            <LegacyLinks
                                balenaUrl={data.device.url}
                                balenaLocalIPs={
                                    data?.device?.balena?.ipAddresses ?? []
                                }
                            />
                        </Section>
                    </>
                )}
            </TabContent>
        );
    } else if (loading) {
        return (
            <TabContent>
                <Skeleton height={100} />
            </TabContent>
        );
    }
    return (
        <TabContent>
            <Callout variant="error">
                Error encountered with Device Settings. Please contact an admin.
            </Callout>
        </TabContent>
    );
}

function GeneralInfo(props: {
    deviceName: string;
    deviceId: string;
    deviceStartTime: string;
    deviceUptime: string;
    configOk: boolean;
    maintenanceUntil: string | null;
    isInMaintenance: boolean | null;
}): ReactElement {
    const { maintenanceUntil, isInMaintenance, configOk } = props;
    const mu = maintenanceUntil ? formatDatetime(maintenanceUntil) : "Unknown";
    return (
        <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Name">
                    <Txt level={6}>{props.deviceName}</Txt>
                </TextLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Device UUID">
                    <CodeText text={props.deviceId} />
                </TextLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Powered on">
                    <Txt level={6}>{props.deviceStartTime}</Txt>
                    <Txt level={8} italic>
                        {props.deviceUptime}
                    </Txt>
                </TextLabel>
            </Grid>
            {isInMaintenance !== null && (
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Maintenance status">
                        <StatusBadge
                            status={isInMaintenance ? "warn" : "disabled"}
                            text={isInMaintenance ? "Active" : "Inactive"}
                        />
                        {isInMaintenance && (
                            <Txt level={6}>{`until ${mu}`}</Txt>
                        )}
                        <ChangeMaintenanceDialog
                            deviceId={props.deviceId}
                            maintenanceUntil={maintenanceUntil ?? undefined}
                        />
                    </TextLabel>
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <TextLabel label="Configuration Check">
                    <StatusBadge
                        status={configOk ? "ok" : "warn"}
                        text={configOk ? "Success" : "Contact Support"}
                        size="sm"
                    />
                </TextLabel>
            </Grid>
        </Grid>
    );
}

function NetworkInfo({ meta }: { meta: Meta | null }): ReactElement {
    const netUp = meta?.network?.uptime;
    const networkUptime: string = netUp ? formatUptime(netUp) : "Unknown";

    const connectionType: string = meta?.network?.connectionType ?? "Unknown";
    const localIp: string = meta?.network?.localIp ?? "Unknown";
    const networkName: string = meta?.network?.networkName ?? "Unknown";

    const noDataAvailable = Object.values(meta?.network ?? {}).every(
        e => e === null || e === "DeviceNetworkInfo",
    );
    if (noDataAvailable) {
        log.debug("No network data provided. Skipping render.");
        return <Fragment />;
    }

    return (
        <Section heading="Network">
            <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Network" childrenDirection="column">
                        <Txt level={6}>{networkName}</Txt>
                        <Txt level={9} italic>
                            {connectionType}
                        </Txt>
                    </TextLabel>
                    <TextLabel label="Local IP" childrenDirection="column">
                        <CodeText text={localIp} />
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Last change">
                        <Txt level={6}>{networkUptime}</Txt>
                    </TextLabel>
                </Grid>
            </Grid>
        </Section>
    );
}

function KitChip(props: { data: Kit }): ReactElement {
    const theme = useTheme();
    const containerCss = css`
        border: 1px solid ${theme.colours.neutral[300]};
        padding: 4px 8px;
        border-radius: 4px;
        color: ${theme.colours.neutral[700]};
    `;
    return (
        <div css={containerCss}>
            <Txt font="secondary" level={8} emphasis>
                {props.data.name}
            </Txt>
            <Txt font="secondary" level={9}>
                {props.data.commitHash}
            </Txt>
            <Txt font="secondary" level={9}>
                {props.data.environment}
            </Txt>
        </div>
    );
}

function BalenaInfo({ device }: { device: Device }): ReactElement {
    const deviceId = device.id;
    const balenaDevice = device.balena;
    const balenaUuid = balenaDevice?.deviceUuid ?? "";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const balenaDashboardRef = balenaUuid
        ? `https://dashboard.balena-cloud.com/devices/${balenaUuid}/summary`
        : "#";
    const lastConnected: string = relativeDatetime(
        balenaDevice?.lastConnected ?? "",
    );
    const isBalenaOnline = device.balena?.isOnline;
    const balenaConnectionStatus =
        isBalenaOnline === null
            ? "Unknown"
            : isBalenaOnline
              ? "Online"
              : "Offline";
    return (
        <Fragment>
            <div style={{ marginBottom: 24 }}>
                <Callout>
                    Information provided from Balena is read-only. To manage
                    Balena configuration, please go to{" "}
                    <a
                        href={balenaDashboardRef}
                        target="_blank"
                        rel="noopener noreferrer">
                        the Balena Cloud Dashboard
                    </a>
                    .
                </Callout>
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={6} container>
                    <Grid item>
                        <TextLabel label="Balena UUID">
                            {balenaUuid ? (
                                <CodeText text={balenaUuid} />
                            ) : (
                                <Txt level={8}>No Balena UUID</Txt>
                            )}
                            <ChangeBalenaUuidDialog
                                deviceId={deviceId}
                                balenaUuid={balenaUuid}
                            />
                        </TextLabel>
                    </Grid>
                    <Grid item></Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Connected to Balena">
                        <Txt level={6}>{balenaConnectionStatus}</Txt>
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Balena Device name">
                        <CodeText text={balenaDevice?.deviceName ?? ""} />
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Last connected event">
                        <Txt level={6}>{lastConnected}</Txt>
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Public URL">
                        {balenaDevice?.publicAddress ? (
                            <CodeText
                                text={`https://${balenaUuid}.balena-devices.com/`}
                            />
                        ) : (
                            <Txt level={8}>Device is not web accessible</Txt>
                        )}
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Balena Fleet">
                        <Txt level={6}>{balenaDevice?.fleetName}</Txt>
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Local IP address">
                        {balenaDevice?.ipAddresses?.map(ip => (
                            <CodeText key={ip} text={ip} />
                        ))}
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Device Type">
                        <Txt level={6}>{balenaDevice?.deviceTypeName}</Txt>
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="MAC Address(es)">
                        {balenaDevice?.macAddresses?.map((addr, i) => (
                            <div key={i} style={{ marginBottom: 2 }}>
                                <CodeText text={addr} />
                            </div>
                        ))}
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Location">
                        <Txt level={6}>{balenaDevice?.location}</Txt>
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Public IP address">
                        <CodeText text={balenaDevice?.publicAddress ?? ""} />
                    </TextLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLabel label="Override update lock">
                        <BalenaOverrideLockIndicator
                            overrideLockEnabled={
                                balenaDevice?.overrideLockEnabled ?? false
                            }
                            deviceId={deviceId}
                        />
                    </TextLabel>
                </Grid>
            </Grid>
        </Fragment>
    );
}

function BalenaOverrideLockIndicator(props: {
    overrideLockEnabled: boolean;
    deviceId: string;
}): ReactElement {
    const { deviceId, overrideLockEnabled } = props;
    const { handleSetBalenaOverrideLock, loading } = useDeviceOverrideLock();
    const { isAdmin } = useDeviceRole(deviceId);
    const { isMytosUser } = useUser();

    return (
        <Tooltip
            title="Override existing update lock(s) if your app is stuck in an invalid state under an update lock."
            placement="bottom">
            <div>
                <Switch
                    id={"override-lock"}
                    checked={overrideLockEnabled}
                    labelPosition="right"
                    disabled={loading || !isAdmin || !isMytosUser}
                    onChange={async enabled =>
                        await handleSetBalenaOverrideLock({ enabled, deviceId })
                    }
                />
            </div>
        </Tooltip>
    );
}

function LegacyLinks(props: {
    balenaUrl: string | null;
    balenaLocalIPs: string[];
}): ReactElement {
    return (
        <>
            <Callout>Use the buttons below to open the legacy UI</Callout>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "16px 0",
                    gap: 16,
                }}>
                <a
                    href={props.balenaUrl ?? ""}
                    target="_blank"
                    rel="noreferrer">
                    <Button
                        variant="secondary"
                        iconRight="external-link"
                        disabled={props.balenaUrl === null}
                        style={{ width: "100%" }}>
                        Open legacy via Balena
                    </Button>
                </a>
                {props.balenaLocalIPs.map(ip => {
                    const href = new URL(props.balenaUrl ?? "");
                    href.protocol = "http";
                    href.hostname = ip;
                    return (
                        <a
                            key={ip}
                            href={href.toString()}
                            target="_blank"
                            rel="noreferrer">
                            <Button
                                variant="secondary"
                                iconRight="external-link"
                                style={{ width: "100%" }}>
                                {`Open legacy via ${ip}`}
                            </Button>
                        </a>
                    );
                })}
            </div>
        </>
    );
}
