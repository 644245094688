import React, { ReactElement } from "react";

import { AnimatePresence, motion } from "framer-motion";

import Callout from "components/common/Callout";
import { DateTimeInput } from "components/common/DateTimeInput";
import { Select } from "components/common/Select";
import Txt from "components/common/Text";
import { Dayjs, Timezone } from "services/date";

import { useProtocolState } from "./protocol-upload-hooks";

export function LegacyConfirmView({ protocolName }: { protocolName?: string }) {
    const {
        cultureStartDate,
        setCultureStartDate,
        cultureTimezone,
        setCultureTimezone,
        protocolUploadErrorMessage,
    } = useProtocolState();

    return (
        <ConfirmView
            protocolName={protocolName}
            cultureStartDate={cultureStartDate}
            setCultureStartDate={setCultureStartDate}
            cultureTimezone={cultureTimezone}
            setCultureTimezone={setCultureTimezone}
            protocolUploadErrorMessage={protocolUploadErrorMessage}
        />
    );
}

export function ConfirmView({
    cultureStartDate,
    setCultureStartDate,
    cultureTimezone,
    setCultureTimezone,
    protocolUploadErrorMessage,
    protocolName,
}: {
    cultureStartDate: Dayjs;
    setCultureStartDate: (d: Dayjs) => void;
    cultureTimezone?: Timezone;
    setCultureTimezone: (d: Timezone) => void;
    protocolUploadErrorMessage?: string;
    protocolName?: string;
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}>
            <div style={{ display: "flex", minHeight: 32 }}>
                <Txt
                    font="secondary"
                    level={8}
                    emphasis
                    sx={{
                        flexGrow: 1,
                        color: theme => theme.colours.neutral[600],
                    }}>
                    Culture
                </Txt>
                <Expand present={Boolean(protocolName)}>
                    <Txt font="secondary" level={7} style={{ minHeight: 24 }}>
                        {protocolName}
                    </Txt>
                </Expand>
            </div>
            <div style={{ display: "flex", marginTop: 8 }}>
                <Txt
                    font="secondary"
                    level={8}
                    sx={{
                        flexGrow: 1,
                        color: theme => theme.colours.neutral[600],
                    }}>
                    will begin on
                </Txt>
                <DateTimeInput
                    disableTime
                    defaultValue={cultureStartDate}
                    onChange={d => d && setCultureStartDate(d)}
                />
            </div>
            <div style={{ display: "flex", marginTop: 8 }}>
                <Txt
                    font="secondary"
                    level={8}
                    sx={{
                        flexGrow: 1,
                        color: theme => theme.colours.neutral[600],
                    }}>
                    in timezone
                </Txt>
                <Select
                    options={[
                        { value: "Europe/London", label: "London, UK" },
                        {
                            value: "America/New_York",
                            label: "New York, USA",
                        },
                        {
                            value: "America/Los_Angeles",
                            label: "Los Angeles, USA",
                        },
                    ]}
                    defaultValue={cultureTimezone}
                    onChange={newValue => {
                        setCultureTimezone(newValue);
                    }}
                    maxWidth={145}
                />
            </div>
            <Expand present={Boolean(protocolUploadErrorMessage)}>
                <Callout
                    variant="error"
                    title="Protocol upload failed"
                    message={protocolUploadErrorMessage}
                    style={{ marginTop: 16 }}
                />
            </Expand>
            {/* <DeviceNotificationsPreferenceCard // TODO restore this when Notifications are rebuilt
                        deviceId={deviceId}
                        promptTo="enable"
                    /> */}
        </div>
    );
}

function Expand(props: {
    present: boolean;
    children: React.ReactNode;
}): ReactElement {
    return (
        <AnimatePresence initial={false}>
            {props.present && (
                <motion.div
                    style={{ display: "inline-flex" }}
                    transition={{ duration: 0.1 }}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}>
                    {props.children}
                </motion.div>
            )}
        </AnimatePresence>
    );
}
