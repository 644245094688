import React, { ReactElement } from "react";

// import { Theme as EmotionTheme } from "@emotion/react/macro";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { Theme as MaterialUITheme } from "@mui/material";
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import "./styles/fonts.scss";

interface Tones {
    900: string;
    800: string;
    700: string;
    600: string;
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
}

// Extend the built in Material UI Theme interface with some custom options
declare module "@mui/material/styles" {
    interface Theme {
        colours: {
            /**
             * @deprecated use `theme.colours.brandGreen` or
             * `theme.colours.brandBlue` directly
             */
            brand: {
                /**
                 * @deprecated use `theme.colours.brandGreen[500]` instead
                 */
                green: string;
                /**
                 * @deprecated use `theme.colours.brandGreen[600]` instead
                 */
                greenDark: string;
                /**
                 * @deprecated use `theme.colours.brandBlue[500]` instead
                 */
                blue: string;
                /**
                 * @deprecated use `theme.colours.brandBlue[600]` instead
                 */
                blueDark: string;
                /**
                 * @deprecated use `theme.colours.brandBlue[100]` instead
                 */
                lightBlue: string;
                /**
                 * @deprecated use `theme.colours.brandBlue[200]` instead
                 */
                polar: string;
            };
            /**
             * @deprecated use `theme.colours.orange` or `theme.colours.red`
             * directly
             */
            accent: {
                /**
                 * @deprecated use `theme.colours.orange[500]` instead
                 */
                alertOrange: string;
                /**
                 * @deprecated use `theme.colours.red[500]` instead
                 */
                errorRed: string;
            };
            neutral: {
                900: string;
                800: string;
                700: string;
                600: string;
                500: string;
                400: string;
                300: string;
                200: string;
                100: string;
                white: string;
            };
            gradients: {
                A: string;
                B: string;
                C: string;
                D: string;
            };
            brandGreen: Tones;
            brandBlue: Tones;
            orange: Tones;
            red: Tones;
            green: Tones;
            blue: Tones;
        };
    }
    // allow configuration using `createMuiTheme`
    // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
    interface ThemeOptions extends Theme {}
}

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module "@emotion/react" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
    export interface Theme extends MaterialUITheme {}
}

const theme = createTheme({
    /**
     * This prop is a no-op that is needed to prevent complaints about it
     * missing. I have no idea why it broke when I did a deep upgrade of @babel
     * packages, but it did. This may not be necessary with future updates to
     * the Mui packages.
     */
    unstable_sx: () => ({}),
    applyStyles: () => ({}),
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960, // Keep old breakpoint. Material guideline is 900px.
            lg: 1200,
            xl: 1920, // keep old breakpoint. Material guideline is 1536px.
        },
    },
    colours: {
        brand: {
            green: "#0cdfbc",
            greenDark: "#08d0af",
            blue: "#0cdfdf",
            blueDark: "#14cdcd",
            lightBlue: "#F0FAFA",
            polar: "#E4F6F6",
        },
        accent: {
            alertOrange: "#fe6619",
            errorRed: "#D11717",
        },
        neutral: {
            900: "#171c1c",
            800: "#4b5352",
            700: "#6d7877",
            600: "#9ba7a7",
            500: "#bac5c5",
            400: "#d3dada",
            300: "#e6ebeb",
            200: "#f1f5f5",
            100: "#f8fbfb",
            white: "#ffffff",
        },
        gradients: {
            A: "linear-gradient(90deg, #0CDFBC 0%, #0CDFDF 100%)",
            B: "linear-gradient(90deg, #0CDFDF 0%, #0CDFBC 100%)",
            C: "linear-gradient(90deg, #14CDCD 0%, #08D0AF 100%)",
            D: "linear-gradient(90deg, #08D0AF 0%, #14CDCD 100%)",
        },
        brandGreen: {
            100: "#E1FAF6",
            200: "#C6F7EF",
            300: "#93F5E5",
            400: "#5EEBD3",
            500: "#0CDFBC",
            600: "#07C7A7",
            700: "#05A389",
            800: "#027A67",
            900: "#015244",
        },
        brandBlue: {
            100: "#F0FAFA",
            200: "#E4F6F6",
            300: "#B2EDED",
            400: "#74E8E8",
            500: "#0CDFDF",
            600: "#06CCCC",
            700: "#03ABAB",
            800: "#018080",
            900: "#005959",
        },
        orange: {
            100: "#FEEDE5",
            200: "#FECBB2",
            300: "#FEAA7F",
            400: "#FE884C",
            500: "#FF6619",
            600: "#E55912",
            700: "#BF470A",
            800: "#9E3703",
            900: "#802B00",
        },
        red: {
            100: "#FCE4E4",
            200: "#F6B3B3",
            300: "#ED7B7B",
            400: "#EE5151",
            500: "#D11717",
            600: "#B71414",
            700: "#9D1111",
            800: "#830E0E",
            900: "#690B0B",
        },
        green: {
            100: "#E5FFF3",
            200: "#B5F3D6",
            300: "#7BE5B3",
            400: "#46D38F",
            500: "#26C076",
            600: "#12A45E",
            700: "#0D854B",
            800: "#076739",
            900: "#03562E",
        },
        blue: {
            100: "#E5F6FF",
            200: "#C2E6FB",
            300: "#8AD0F8",
            400: "#50B3EB",
            500: "#2691CD",
            600: "#1376AD",
            700: "#095F8F",
            800: "#05486F",
            900: "#023654",
        },
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#0cdfbc",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            contrastText: "#171C1C",
        },
        secondary: {
            main: "#0cdfdf",
        },
        text: {
            primary: "#171c1c",
            secondary: "#6d7877",
            disabled: "#bac5c5",
        },
    },
    typography: {
        fontFamily: ["Campton"].join(","),
    },
});

function MytosThemeProvider(props: {
    children?: React.ReactNode;
}): ReactElement {
    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <EmotionThemeProvider theme={theme}>
                    {props.children}
                </EmotionThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
}

export { MytosThemeProvider };
