import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { DelayStepInput } from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";
import { CULTURE_SCHEDULE_QUERY } from "services/hooks/useCultureSchedule";

export function useRescheduleStep(): {
    delayStep: (props: DelayStepInput) => Promise<void>;
} {
    const { toast } = useToasts();
    const [delayStepMutation] = useMutation(DELAY_STEP_MUTATION);

    const delayStep = async (input: DelayStepInput) => {
        const toastId = toast.loading("Rescheduling Step");

        const res = await delayStepMutation({
            variables: { input },
            refetchQueries: [CULTURE_SCHEDULE_QUERY],
        });

        if (res?.data?.delayStep.ok) {
            toast.update(toastId, {
                type: "success",
                render: "Step rescheduled",
                isLoading: false,
                autoClose: 3000,
            });
        } else {
            toast.update(toastId, {
                type: "error",
                render: "Unable to reschedule step",
                isLoading: false,
                autoClose: 6000,
            });
        }
    };
    return { delayStep };
}

const DELAY_STEP_MUTATION = gql(`
    mutation DelayStep($input: DelayStepInput!) {
        delayStep(input: $input) {
            ok
            message
        }
    }
`);
