import React, { ReactElement } from "react";

import { css } from "@emotion/react";
import { Collapse } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Txt, { fontFamilyMap, textLevelFontSize } from "./Text";

export interface TextAreaProps {
    /**
     * Optionally provide an ID for the HTML element
     */
    id?: string;
    /**
     * Value controls the input. If left undefined, component will manage it's
     * own state. If defined, value must be changed when the `onChange` callback
     * is fired.
     */
    value?: string;
    /**
     * String to be displayed subtly within the text area when it's empty
     */
    placeholder?: string;
    /**
     * Every time a change is attempted (i.e. new key press) on the text area
     * this callback is fired.
     */
    onChange?: (newText: string) => void;
    /**
     * Disables direct editing of the text area by the user
     */
    readOnly?: boolean;
    /**
     * Allows the user to resize the area along either axis independently or
     * both. Defaults to no resizing available.
     */
    resize?: "none" | "horizontal" | "vertical" | "both";
    /**
     * If `false` displays the text area in a visually errored state
     */
    valid?: boolean;
    /**
     * If provided, will override the default helper text to the user to inform
     * them when `valid=false`.
     */
    invalidPrompt?: string;
    /**
     * Override styling of the element directly
     */
    style?: React.CSSProperties;
}

export function TextArea(props: TextAreaProps): ReactElement {
    const {
        id,
        value,
        placeholder,
        onChange,
        readOnly = false,
        style,
        resize = "none",
        valid = true,
        invalidPrompt = "The input provided is invalid",
    } = props;

    const theme = useTheme();

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => onChange?.(event.target.value);

    return (
        <div style={{ width: "100%" }}>
            <textarea
                id={id}
                name={id}
                readOnly={readOnly}
                value={value}
                placeholder={placeholder}
                onChange={handleTextChange}
                css={css({
                    fontFamily: fontFamilyMap.primary,
                    fontSize: textLevelFontSize[8],
                    color: theme.colours.neutral[800],
                    stroke: "1px solid",
                    borderColor: valid
                        ? theme.colours.neutral[300]
                        : theme.colours.accent.alertOrange,
                    borderRadius: 4,
                    margin: 0,
                    padding: 16,
                    width: "100%",
                    height: 100,
                    resize: resize,
                    outlineColor: valid
                        ? theme.colours.brand.green
                        : theme.colours.accent.alertOrange,
                })}
                style={style}
            />
            <Collapse in={!valid} timeout={100}>
                <Txt
                    font="secondary"
                    level={9}
                    italic
                    style={{ color: theme.colours.accent.alertOrange }}>
                    {invalidPrompt}
                </Txt>
            </Collapse>
        </div>
    );
}
