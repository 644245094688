import { Fragment, ReactElement, useState } from "react";

import { useQuery, ApolloError } from "@apollo/client";
import { orderBy, uniqBy } from "lodash";

import { gql } from "__generated__/apollo";
import {
    CultureResultsQuery,
    DeviceResultsQuery,
} from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import { TabContent } from "components/common/TabContent";
import { removeNullables } from "services/utils";

import { log as parentLog } from "../log";

import ResultsList from "./ResultsList";

export const log = parentLog.extend("DeviceResults");

const PAGE_SIZE = 20;

const DEVICE_RESULTS_QUERY = gql(`
    query DeviceResults($deviceId: String!, $after: String, $first: Int) {
        device(id: $deviceId) {
            id
            name
            results(after: $after, first: $first) {
                nodes {
                    id
                    type
                    timestampISO
                    data {
                        __typename
                        ... on FlaskImages {
                            flask {
                                flaskId
                                number
                            }
                            images {
                                id
                                timestampISO
                            }
                            videos {
                                id
                                timestampISO
                            }
                        }
                    }
                }
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
                totalCount
            }
        }
    }
`);

const CULTURE_RESULTS_QUERY = gql(`
    query CultureResults($cultureId: String!, $after: String, $first: Int) {
        culture(id: $cultureId) {
            id
            name
            results(after: $after, first: $first) {
                nodes {
                    id
                    type
                    timestampISO
                    data {
                        __typename
                        ... on FlaskImages {
                            flask {
                                flaskId
                                number
                            }
                            images {
                                id
                                timestampISO
                            }
                            videos {
                                id
                                timestampISO
                            }
                        }
                    }
                }
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
                totalCount
            }
        }
    }
`);

type DeviceResultsDevice = NonNullable<DeviceResultsQuery["device"]>;
type CultureResultsCulture = NonNullable<CultureResultsQuery["culture"]>;
type DeviceResultConnection = NonNullable<DeviceResultsDevice["results"]>;
export type DeviceResult = NonNullable<
    NonNullable<DeviceResultConnection["nodes"]>[number]
>;

export interface DeviceResultsByDeviceProps {
    deviceId: string;
}

interface DeviceResultsByCultureProps {
    cultureId: string;
}

export default function DeviceResults(
    props: DeviceResultsByDeviceProps | DeviceResultsByCultureProps,
): ReactElement {
    const { results, loading, viewMore, numberToView, error } =
        useResults(props);

    let content: ReactElement = <></>;
    if (error) {
        content = (
            <Callout
                variant="error"
                message="There was an error fetching results. Please contact support."
            />
        );
    } else if (results) {
        log.debug("results results", results);
        const resultsData: DeviceResult[] = removeNullables(
            results.nodes ?? [],
        );

        const totalCount = results.totalCount;
        if (totalCount && totalCount > 0 && resultsData) {
            content = (
                <ResultsList
                    resultsData={resultsData}
                    viewMore={viewMore}
                    numberToView={numberToView}
                    hasMore={results.pageInfo?.hasNextPage ?? true}
                />
            );
        } else {
            content = <Callout message="There are no results." />;
        }
    } else if (loading) {
        content = (
            <Fragment>
                <Skeleton height={50} style={{ marginBottom: 20 }} />
                <Skeleton height={50} style={{ marginBottom: 20 }} />
                <Skeleton height={50} style={{ marginBottom: 20 }} />
            </Fragment>
        );
    }

    return <TabContent>{content}</TabContent>;
}

function useResults(
    props: DeviceResultsByCultureProps | DeviceResultsByDeviceProps,
): {
    results?: DeviceResultConnection;
    viewMore: () => void;
    loading: boolean;
    error?: ApolloError;
    numberToView: number;
} {
    const [numberToView, setNumberToView] = useState(PAGE_SIZE);
    const isDeviceProp = "deviceId" in props;
    const {
        loading: cultureLoading,
        data: cultureData,
        error: deviceError,
        fetchMore: cultureFetchMore,
    } = useQuery(CULTURE_RESULTS_QUERY, {
        variables: {
            cultureId: !isDeviceProp ? props.cultureId : "",
            first: PAGE_SIZE,
            after: null,
        },
        skip: isDeviceProp,
    });
    const {
        loading: deviceLoading,
        data: deviceData,
        error: cultureError,
        fetchMore: deviceFetchMore,
    } = useQuery(DEVICE_RESULTS_QUERY, {
        variables: {
            deviceId: isDeviceProp ? props.deviceId : "",
            first: PAGE_SIZE,
            after: null,
        },
        skip: !isDeviceProp,
    });
    const path = isDeviceProp ? "device" : "culture";
    const updateQuery = <
        TData extends {
            device?: DeviceResultsDevice | null;
            culture?: CultureResultsCulture | null;
        },
    >(
        prev: TData,
        { fetchMoreResult }: { fetchMoreResult: TData },
    ): TData => {
        if (!fetchMoreResult) {
            return prev;
        }
        if (!prev[path]?.results) {
            return fetchMoreResult;
        }
        const prevNodes = removeNullables(prev[path].results?.nodes ?? []);
        const newNodes = removeNullables(
            fetchMoreResult[path]?.results?.nodes ?? [],
        );
        const pageInfo =
            fetchMoreResult[path]?.results?.pageInfo ??
            prev[path].results.pageInfo;
        setNumberToView(prev => prev + PAGE_SIZE);
        const concat = [...prevNodes, ...newNodes];
        const deduped = uniqBy(concat, "id");
        const mergedResults = orderBy(deduped, "timestampISO", "desc");
        return {
            [path]: {
                ...prev[path],
                results: {
                    ...prev[path].results,
                    nodes: mergedResults,
                    pageInfo: pageInfo,
                },
            },
        } as TData;
    };
    if (isDeviceProp) {
        return {
            results: deviceData?.device?.results ?? undefined,
            loading: deviceLoading,
            numberToView,
            error: deviceError,
            viewMore: () =>
                deviceFetchMore({
                    variables: {
                        deviceId: props.deviceId,
                        first: PAGE_SIZE,
                        after: deviceData?.device?.results?.pageInfo?.endCursor,
                    },
                    updateQuery,
                }),
        };
    }
    return {
        results: cultureData?.culture?.results ?? undefined,
        loading: cultureLoading,
        numberToView,
        error: cultureError,
        viewMore: () =>
            cultureFetchMore({
                variables: {
                    cultureId: props.cultureId,
                    first: PAGE_SIZE,
                    after: cultureData?.culture?.results?.pageInfo?.endCursor,
                },
                updateQuery,
            }),
    };
}
