import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { AddOperationToStepInput } from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";
import { StepInvocationsQueryName } from "services/hooks/useCultureStepInvocations";

export function useAddOperationToStep(): {
    addOperationToStep: (props: AddOperationToStepInput) => Promise<void>;
} {
    const { toast } = useToasts();
    const [addOperationToStepMutation] = useMutation(ADD_OPERATION_TO_STEP);

    const addOperationToStep = async (input: AddOperationToStepInput) => {
        const toastId = toast.loading("Adding Operation to Step");

        const res = await addOperationToStepMutation({
            variables: { input },
            refetchQueries: [StepInvocationsQueryName],
        });

        if (res?.data?.addOperationToStep.ok) {
            toast.update(toastId, {
                type: "success",
                render: "Operation added to Step",
                isLoading: false,
                autoClose: 3000,
            });
        } else {
            toast.update(toastId, {
                type: "error",
                render: "Unable to add Operation to Step",
                isLoading: false,
                autoClose: 6000,
            });
        }
    };
    return { addOperationToStep };
}

const ADD_OPERATION_TO_STEP = gql(`
    mutation AddOperationToStep($input: AddOperationToStepInput!) {
        addOperationToStep(input: $input) {
            ok
            message
        }
    }
`);
