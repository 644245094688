import { Fragment, ReactElement, ReactNode } from "react";

import "react-toastify/dist/ReactToastify.min.css";
// import "react-toastify/dist/ReactToastify.css"; // ! non-minified version; only use in development

import { css, useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

import {
    fontFamilyMap,
    textLevelFontSize,
    textLevelLineHeight,
} from "components/common/Text";

import { ToastCloseButton } from "./ToastCloseButton";

export default function ToastsProvider({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const toastContainerCss = useToastContainerCss();
    return (
        <Fragment>
            <ToastContainer
                css={toastContainerCss}
                // below we configure out defaults https://fkhadra.github.io/react-toastify/api/toast-container
                position="bottom-center"
                autoClose={5000} // Delay in ms to close
                closeButton={ToastCloseButton}
                // transition?
                hideProgressBar={false}
                pauseOnHover={true}
                pauseOnFocusLoss={true}
                closeOnClick={false}
                newestOnTop={true} // set to false if position "top", true if "bottom"
                draggable={true}
                draggablePercent={60}
                limit={3}
            />
            {children}
        </Fragment>
    );
}

function useToastContainerCss() {
    const theme = useTheme();
    return css`
        // Applies to container (.Toastify__toast-container)
        && {
            width: 98vw;
            ${theme.breakpoints.up(540)} {
                width: 540px;
            }
            ${theme.breakpoints.down(540)} {
                bottom: env(safe-area-inset-bottom);
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .Toastify__toast-body {
            display: flex;
            align-items: center;
            margin: 12px 16px;
            color: ${theme.colours.neutral[900]};

            font-family: ${fontFamilyMap.secondary};
            font-size: ${textLevelFontSize[8]};
            line-height: ${textLevelLineHeight[8]};
        }
        .Toastify__progress-bar {
            background: ${theme.colours.neutral[300] + "70"};
        }
        .Toastify__toast {
            padding: 0;
            min-height: 48px;
            border-radius: 4px;
            margin-bottom: 0.5rem;
        }
        .Toastify__toast--info {
            border: 1px solid;
            border-color: ${theme.colours.neutral[300]};
            background-color: ${theme.colours.neutral[100]};
        }
        .Toastify__toast--success {
            border: 1px solid;
            // for transparency add "1A"
            border-color: ${theme.colours.brand.green};
            background-color: #ecfbf8;
            & svg {
                color: ${theme.colours.brand.greenDark};
            }
        }
        .Toastify__progress-bar--success {
            background: ${theme.colours.brand.green + "26"};
        }
        .Toastify__toast--error {
            border: 1px solid;
            // for transparency add "1A"
            border-color: ${theme.colours.accent.alertOrange};
            background-color: #fff0e9;
            & svg {
                color: ${theme.colours.accent.alertOrange};
            }
        }
        .Toastify__progress-bar--error {
            background: ${theme.colours.accent.alertOrange + "26"};
        }
    `;
}
