import { useEffect } from "react";

import { useMutation as useApolloMutation, useQuery } from "@apollo/client";
import { atom, useAtom } from "jotai";
import { isNull, isUndefined } from "lodash";

import { gql } from "__generated__/apollo/gql";
import {
    CellFormat,
    CreateAndSyncCultureInput,
    CreateAndSyncCultureMutation,
    ProtocolUploadConditionsQuery,
    CultureState,
    DevicePlatform,
} from "__generated__/apollo/graphql";
import { Options } from "components/common/Select";
import { config } from "services/config";
import { Dayjs, Timezone } from "services/date";
import { useFeature } from "services/feature-flags";

import { CULTURE_OVERVIEW_QUERY, DEVICE_CULTURE_QUERY } from "../DeviceCulture";

import {
    cellFormatAtom,
    cellLineAtom,
    creatingCultureAtom,
    cultureStartDateAtom,
    cultureTimezoneAtom,
    passageNumberAtom,
    protocolUploadErrorMessageAtom,
    vialIDAtom,
} from "./create-culture-state";
import { log as parentLog } from "./log";

export const log = parentLog.extend("protocol-upload-hooks");

export interface UseProtocolState {
    /**
     * Set the protocol JSON string. Usually used on import of JSON file.
     */
    setProtocolJson: (s: unknown | Error | undefined) => void;
    /**
     * The desired start date as displayed by the UI and set by the user.
     * Defaults to 'today' by default.
     */
    cultureStartDate: Dayjs;
    /**
     * Sets the desired start date for the user.
     */
    setCultureStartDate: (newDate: Dayjs) => void;
    /**
     * The timezone the culture will run in. Defaults to local timezone by
     * default if recognised and supported.
     */
    cultureTimezone: Timezone | undefined;
    /**
     * Set the timezone for the culture to run in.
     */
    setCultureTimezone: (tz: Timezone) => void;
    /**
     * The exported protocol object, which is based on the imported JSON but
     * modified before sending to backend.
     *
     * If invalid JSON is imported, this will be an Error object.
     * If no object or string is present, this will be undefined.
     */
    protocolObject: unknown | Error | undefined;
    /**
     * Error message displayed if protocol upload mutation failed.
     */
    protocolUploadErrorMessage: string | undefined;
    /**
     * Sets the displayed protocol upload error message.
     */
    setProtocolUploadErrorMessage: (s: string | undefined) => void;
    /**
     * Clears the protocol state from memory.
     */
    clearProtocol: () => void;
}

/**
 * State atom that stores the imported protocol JSON string. This atom is only
 * expected to be written to entirely (overwrite), rather than precise property
 * updates.
 *
 * For the uploaded protocol, see `protocolAtom`.
 */
const protocolObjectAtom = atom<unknown | Error | undefined>(undefined);

export function useProtocolState(): UseProtocolState {
    const [protocolObject, setProtocolObject] = useAtom(protocolObjectAtom);
    const [cultureStartDate, setCultureStartDate] =
        useAtom(cultureStartDateAtom);
    const [cultureTimezone, setCultureTimezone] = useAtom(cultureTimezoneAtom);
    const [protocolUploadErrorMessage, setProtocolUploadErrorMessage] = useAtom(
        protocolUploadErrorMessageAtom,
    );

    return {
        setProtocolJson: s => {
            setProtocolObject(s);
            setProtocolUploadErrorMessage(undefined);
        },
        protocolObject,
        cultureStartDate,
        setCultureStartDate,
        cultureTimezone,
        setCultureTimezone,
        protocolUploadErrorMessage,
        setProtocolUploadErrorMessage,
        clearProtocol: () => {
            setProtocolObject(undefined);
            setProtocolUploadErrorMessage(undefined);
        },
    };
}

type Transport = "balena" | "mqtt" | "local" | "server-minted";

/**
 * Returns an array of transport options for protocol upload.
 * The transport options include different methods for uploading the protocol.
 *
 * @returns An array of transport options.
 */
export function useTransportOptions(): Options<Transport> {
    const serverUploadEnabled =
        useFeature("app_protocol_upload_to_server").enabled || config.isLocal;

    const [, setDefaultTransport] = useUploadTransport();

    // By default users should use server minted uploads if they have the
    // feature flag enabled.
    useEffect(() => {
        if (serverUploadEnabled) {
            setDefaultTransport("server-minted");
        }
    }, [serverUploadEnabled, setDefaultTransport]);

    return [
        ...(serverUploadEnabled
            ? [
                  {
                      value: "server-minted",
                      label: "Upload via server (default)",
                  } as const,
              ]
            : []),
        {
            value: "balena",
            label: `Upload via V1 ${serverUploadEnabled ? "" : "(default)"}`,
        },
        { value: "mqtt", label: "Upload via V2" },
        { value: "local", label: "Upload via local network" },
    ];
}

export const uploadTransportAtom = atom<Transport>("balena");

export const useUploadTransport = () => useAtom(uploadTransportAtom);

export interface UseDeviceUploadConditions {
    deviceIsOnline: boolean;
    deviceHasCulture: boolean;
    updateStateBlocking: boolean;
    deviceCanAcceptCultureUpload: boolean;
}

const PROTOCOL_UPLOAD_CONDITIONS_QUERY = gql(`
    query ProtocolUploadConditions($deviceId: String!, $filterBy: CultureConnectionFilters) {
        device(id: $deviceId) {
            id
            name
            isOnline
            platform
            balenaUuid
            balena {
                ipAddresses
                updateAvailable
                updating
            }
            # Wet Test cultures will only show up in this field
            culture {
                id
                isWetTestCulture
            }
            cultures(filterBy: $filterBy) {
                nodes {
                    id
                    state
                }
            }
        }
    }
`);

/**
 * Provides relevant device state information to decide if protocol upload is
 * permissible.
 *
 * Multiple culture uploads are only permitted if the user is an admin and all
 * loaded cultures are primary and ready.
 *
 * @param deviceId - The device to check upload conditions for.
 */
export function useDeviceUploadConditions(
    deviceId: string,
): UseDeviceUploadConditions {
    const { data } = useQuery<ProtocolUploadConditionsQuery>(
        PROTOCOL_UPLOAD_CONDITIONS_QUERY,
        {
            variables: {
                deviceId,
                filterBy: {
                    onDevice: true,
                },
            },
        },
    );

    const deviceIsOnline = Boolean(data?.device?.isOnline);

    const updating = data?.device?.balena?.updating;
    const updateStateBlocking = Boolean(updating);
    const deviceAvailable = deviceIsOnline && !updateStateBlocking;

    const devicePlatform = data?.device?.platform ?? null;
    const deviceCompatibleWithMultipleCultures =
        devicePlatform === null ||
        devicePlatform === DevicePlatform.Triple ||
        devicePlatform === DevicePlatform.Mock;

    const deviceHasCulture = Boolean(data?.device?.culture);

    /** Note that this excludes Wet Test culture entities */
    const deviceCultures = data?.device?.cultures?.nodes || [];

    const everyDeviceCultureIsReady = deviceCultures.every(
        culture => culture?.state === CultureState.Ready,
    );
    const noWetTestCulture = !data?.device?.culture?.isWetTestCulture;
    const everyDeviceCultureIsPrimaryAndReady =
        noWetTestCulture && everyDeviceCultureIsReady;

    const firstCultureUploadPermitted = !deviceHasCulture;
    const subsequentCultureUploadPermitted =
        deviceCompatibleWithMultipleCultures &&
        everyDeviceCultureIsPrimaryAndReady;

    return {
        deviceIsOnline,
        deviceHasCulture,
        updateStateBlocking,
        deviceCanAcceptCultureUpload:
            deviceAvailable &&
            (firstCultureUploadPermitted || subsequentCultureUploadPermitted),
    };
}

const CREATE_AND_SYNC_CULTURE_MUTATION = gql(`
    mutation CreateAndSyncCulture($input: CreateAndSyncCultureInput!) {
        createAndSyncCulture(input: $input) {
            ok
            message
            culture {
                ...CultureSetupQueryData
            }
        }
    }
`);

type CreateAndSyncCulture = Omit<CreateAndSyncCultureInput, "deviceId">;

export interface UseCreateCulture {
    createAndSyncCulture: (
        variables: CreateAndSyncCulture,
    ) => Promise<CreateAndSyncCultureMutation["createAndSyncCulture"]>;
    creatingCulture: boolean;
}

export function useCreateAndSyncCulture(deviceId: string): UseCreateCulture {
    const [creatingCulture, setCreatingCulture] = useAtom(creatingCultureAtom);

    const [createAndSyncCultureMutation] = useApolloMutation(
        CREATE_AND_SYNC_CULTURE_MUTATION,
        {
            refetchQueries: [CULTURE_OVERVIEW_QUERY, DEVICE_CULTURE_QUERY],
        },
    );

    /**
     * Creates a culture that the server will then sync with a device.
     * Requires protocolId of a previously created protocol in local state
     */
    async function createAndSyncCulture(
        params: CreateAndSyncCulture,
    ): Promise<CreateAndSyncCultureMutation["createAndSyncCulture"]> {
        setCreatingCulture(true);

        const res = await createAndSyncCultureMutation({
            variables: {
                input: {
                    deviceId,
                    ...params,
                    skipValidation: params.skipValidation || config.isLocal,
                },
            },
        });

        setCreatingCulture(false);

        if (res.errors) {
            log.error("Error creating culture", res.errors);
            const errorMessage = res.errors[0].message;
            return { ok: false, message: errorMessage, culture: null };
        }

        if (isNull(res.data) || isUndefined(res.data)) {
            return { ok: false, message: "No data returned", culture: null };
        }

        return res.data.createAndSyncCulture;
    }

    return {
        createAndSyncCulture,
        creatingCulture,
    };
}

export type CellLineMetadata = {
    vialID: string;
    cellLine: string;
    passageNumber: number | null;
    cellFormat: CellFormat;
};

/**
 * Optional metadata about the cells used, populated by the user
 */
export interface UseCellLineMetadata {
    vialID: string;
    setVialID: (newVialID: string) => void;
    cellLine: string;
    setCellLine: (newCellLine: string) => void;
    passageNumber: number | null;
    setPassageNumber: (newPassageNumber: number | null) => void;
    cellFormat: CellFormat;
    setCellFormat: (newCellFormat: CellFormat) => void;
}

export function useCellLineMetadata(): UseCellLineMetadata {
    const [cellLine, setCellLine] = useAtom<string>(cellLineAtom);
    const [vialID, setVialID] = useAtom<string>(vialIDAtom);
    const [cellFormat, setCellFormat] = useAtom<CellFormat>(cellFormatAtom);
    const [passageNumber, setPassageNumber] = useAtom<number | null>(
        passageNumberAtom,
    );
    return {
        cellLine,
        setCellLine,
        vialID,
        setVialID,
        passageNumber,
        setPassageNumber,
        cellFormat,
        setCellFormat,
    };
}
