import { ReactElement } from "react";

import styled from "@emotion/styled";
import { Box } from "@mui/material";

import { DeviceJobState, StartDeviceJobs } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import { DropdownButton } from "components/common/DropdownButton";
import Skeleton from "components/common/Skeleton";
import { Switch } from "components/common/Switch";
import Txt from "components/common/Text";
import { Dayjs, dayjs } from "services/date";
import { useFeature } from "services/feature-flags";

import { useDryTestOverride } from "../CreateCultureState";

import { DryTestRow } from "./DryTestRow";
import { useDeviceDryTests } from "./useDeviceDryTests";
import { useStartDryTest } from "./useTriggerDryTest";
import { DryTest, RequiredDryTestsForProgression } from "./utils";

export interface DryTestsViewProps {
    deviceId: string;
    currentDateTime?: Dayjs;
}

export interface DryTestsViewContentProps {
    deviceId: string;
    dryTests: DryTest[];
    currentDateTime: Dayjs;
}

export function DryTestsView({
    deviceId,
    currentDateTime = dayjs(),
}: DryTestsViewProps): ReactElement {
    const { dryTests } = useDeviceDryTests({ deviceId });
    return (
        <DryTestsViewContent
            dryTests={dryTests}
            deviceId={deviceId}
            currentDateTime={currentDateTime}
        />
    );
}

export function DryTestsViewContent({
    dryTests,
    deviceId,
    currentDateTime,
}: DryTestsViewContentProps): ReactElement {
    const { triggerDryTest } = useStartDryTest();
    const canSkipDryTest = useFeature(
        "ability_to_skip_required_create_culture_checks",
    ).enabled;

    const { dryTestOverride, setDryTestOverride } = useDryTestOverride();

    return (
        <Container>
            <div
                style={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                }}>
                <Txt font="primary" level={5} emphasis>
                    Dry Tests
                </Txt>
                <Txt font="secondary" level={8}>
                    Run the dry tests before connecting reagents to be confident
                    your consumable is installed correctly.
                </Txt>
                {canSkipDryTest && (
                    <Callout variant="info">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                            <Txt font="secondary" level={8}>
                                Override dry test completion requirement
                            </Txt>
                            <Switch
                                size="sm"
                                id={"override-dry-test"}
                                checked={dryTestOverride}
                                onChange={setDryTestOverride}
                            />
                        </div>
                    </Callout>
                )}
            </div>
            <DryTests
                currentDateTime={currentDateTime}
                deviceId={deviceId}
                dryTests={dryTests}
                triggerDryTest={triggerDryTest}
            />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
`;

export function DryTests({
    deviceId,
    dryTests,
    currentDateTime,
    triggerDryTest,
}: {
    deviceId: string;
    dryTests: DryTest[];
    currentDateTime: Dayjs;
    triggerDryTest: (input: StartDeviceJobs) => Promise<void>;
}) {
    const requiredChecks = dryTests.filter(check =>
        [DeviceJobState.Failed, DeviceJobState.Cancelled, null].includes(
            check.state,
        ),
    );
    const requiredChecksTypes = requiredChecks.map(check => check.type);
    const requiredChecksPresent = requiredChecksTypes.length > 0;

    const handleRunAll = async () => {
        await triggerDryTest({
            deviceId,
            types: RequiredDryTestsForProgression,
        });
    };

    const handleRunNecessary = async () => {
        await triggerDryTest({
            deviceId,
            types: requiredChecksTypes,
        });
    };

    return (
        <div>
            <Box
                sx={{
                    color: theme => theme.colours.neutral[700],
                    display: "flex",
                    alignItems: "end",
                    gap: 2,
                    justifyContent: "space-between",
                    paddingBottom: "8px",
                }}>
                <Txt font="secondary" level={8} emphasis>
                    Dry Tests
                </Txt>
                <div style={{ zIndex: 2 }}>
                    <DropdownButton
                        size="s"
                        options={[
                            {
                                value: "run-all",
                                buttonLabel: "Run all",
                                dropdownLabel: "Run all",
                                dropdownLabelDescription:
                                    "All available dry tests",
                                onClick: handleRunAll,
                            },
                            {
                                value: "run-necessary",
                                buttonLabel: "Run necessary",
                                dropdownLabel: "Run necessary",
                                dropdownLabelDescription:
                                    "Only cancelled or failed dry tests",
                                disabled: !requiredChecksPresent,
                                onClick: handleRunNecessary,
                            },
                        ]}
                    />
                </div>
            </Box>
            {dryTests.length ? (
                <Box
                    sx={{
                        "outline": "1px solid",
                        "outlineColor": theme => theme.colours.neutral[300],
                        "borderRadius": 1,
                        "& > :not(:last-child)": {
                            borderBottom: "1px solid",
                            borderColor: theme => theme.colours.neutral[300],
                        },
                    }}>
                    {dryTests.map(dryTest => (
                        <DryTestRow
                            key={dryTest.id}
                            dryTest={dryTest}
                            deviceId={deviceId}
                            currentDateTime={currentDateTime}
                        />
                    ))}
                </Box>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1px", // for the 1px border bottom
                    }}>
                    {Array.from({
                        length: RequiredDryTestsForProgression.length,
                    }).map((_, index) => (
                        <Skeleton
                            key={index}
                            height={52} // 52 + 5 + 5 = 62, same as card height
                            style={{ margin: "5px" }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
