import { Fragment, ReactElement } from "react";

import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from ".";

const log = parentLog.extend("DeviceStatusUpdates");

const DEVICE_ONLINE_CHANGE = gql(`
    query DeviceOnlineChange {
        devices {
            id
            name
            isOnline
        }
    }
`);

// This data only tracks changes that occurred during current page. Any refresh
// would reset the state.
const DEVICE_ONLINE_RECORD: Record<string, boolean | undefined> = {};

export function DeviceStatusUpdates(): ReactElement {
    const { toast } = useToasts();

    const { data } = useQuery(DEVICE_ONLINE_CHANGE, {
        fetchPolicy: "cache-only",
    });

    data?.devices?.forEach(device => {
        const newValue = device?.isOnline;
        if (!newValue) return;

        const storedValue = DEVICE_ONLINE_RECORD[device.id];
        if (storedValue === newValue) return;
        if (storedValue === undefined) {
            // Store a state for the first time
            DEVICE_ONLINE_RECORD[device.id] = newValue;
            return;
        }

        const message = device.isOnline
            ? `${device.name} is now online`
            : `${device.name} is now offline`;

        toast.info(message, {
            pauseOnFocusLoss: false,
            autoClose: 5_000,
        });
        log.debug(
            `Device ${device.name} online status alerted. New value: ${newValue}`,
        );

        DEVICE_ONLINE_RECORD[device.id] = newValue;
    });

    return <Fragment />;
}
