import { Fragment, ReactElement, ReactNode } from "react";

import Container from "@mui/material/Container";
import { useTheme, Theme, styled, SxProps } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link, useParams, Navigate, useLocation } from "react-router-dom";

import Txt from "components/common/Text";

import { log as componentsLog } from "..";

import StatusBadge from "./StatusBadge";

const log = componentsLog.extend("PageHeader");

export type TabData = {
    /** Name of the content used in the Tab button */
    name: string;
    /**
     * Override for the path extension for the tab. By default will convert the
     * name. E.g. Name="Advanced controls" path will be "advanced-controls"
     */
    path: string;
    /**
     * Content to render in the main body of the page below the page header
     */
    content: ReactElement;
    /**
     * If true will display an action required indicator on the tab
     */
    indicator?: boolean;
    /**
     * Display special indicators if the tab is part of an alpha testing or beta
     * release.
     */
    earlyAccess?: "alpha" | "beta";
};

export interface PageHeaderContainerProps {
    /**
     * The route param key the component should use to determine the current
     * active tab
     */
    paramKey: string;
    /**
     * Content to be inserted in the page header
     */
    children?: ReactNode;
    /**
     * Data for the tabs to be displayed.
     */
    tabs: TabData[];
}

export function PageHeaderContainer({
    tabs,
    children,
    paramKey,
}: PageHeaderContainerProps): ReactElement {
    const { pathname: path } = useLocation();
    const params = useParams();
    const tabPath = params[paramKey];
    log.debug("Page header info", { path, params });

    const currentTabIndex = tabs.findIndex(
        (td: TabData) => td.path === tabPath,
    );
    if (currentTabIndex < 0) {
        log.debug(
            "tab index could not be found with current path. redirecting.",
        );
        return <Navigate replace to={"../" + tabs[0].path} />;
    }

    return (
        <PageHeader tabs={tabs} currentIndex={currentTabIndex}>
            {children}
        </PageHeader>
    );
}

export interface PageHeaderProps {
    /**
     * Content to be inserted in the page header
     */
    children?: ReactNode;
    /**
     * Data for the tabs to be displayed.
     */
    tabs: TabData[];
    /**
     * Array index of the current tab
     */
    currentIndex: number;
}

/**
 * Page Header is a wrapper for page content that takes Tab data (which includes
 * the component to render when a tab is selected). Additionally it takes
 * components as children that will be rendered in the page header.
 */
export function PageHeader({
    currentIndex,
    tabs,
    children,
}: PageHeaderProps): ReactElement {
    const theme = useTheme();

    const currentTab = tabs[currentIndex];
    log.debug("Tab state data ", { currentIndex, currentTab });

    return (
        <Fragment>
            <div
                style={{
                    backgroundColor: theme.colours.brandBlue[100],
                    boxShadow:
                        "inset 0px -1px 0px " + theme.colours.neutral[300],
                }}>
                <Container
                    maxWidth="xl"
                    sx={theme => ({
                        padding: theme.spacing(4),
                        paddingBottom: 0,
                        [theme.breakpoints.up("md")]: {
                            paddingLeft: theme.spacing(8),
                            paddingRight: theme.spacing(8),
                        },
                    })}>
                    {children}
                    <PageHeaderTabs
                        variant="scrollable"
                        scrollButtons={false}
                        selectionFollowsFocus
                        value={currentIndex}>
                        {tabs.map((tab, i) => {
                            return (
                                <Tab
                                    key={i}
                                    id={`tab-${i}`}
                                    component={Link}
                                    label={
                                        <TabLabel
                                            indicator={tab.indicator ?? false}
                                            earlyAccess={tab.earlyAccess}>
                                            {tab.name}
                                        </TabLabel>
                                    }
                                    to={`../${tab.path}`}
                                    sx={tabSxProp}
                                />
                            );
                        })}
                    </PageHeaderTabs>
                </Container>
            </div>
            {currentTab.content}
        </Fragment>
    );
}

const PageHeaderTabs = styled(Tabs)(({ theme }) => ({
    "marginTop": theme.spacing(4),
    "& .MuiTabs-indicator": {
        display: "none",
    },
}));

const tabSxProp: SxProps<Theme> = theme => ({
    "textTransform": "none",
    "color": theme.colours.neutral[600],
    "width": "auto",
    "minWidth": 0,
    "border": "1px solid transparent",
    "padding": `${theme.spacing(2)} ${theme.spacing(4)}`,
    [theme.breakpoints.up("md")]: {
        padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    },
    "&.Mui-selected": {
        color: theme.colours.brandBlue[600],
        backgroundColor: theme.colours.neutral.white,
        borderRadius: "5px 5px 0px 0px",
        border: "1px solid " + theme.colours.neutral[300],
        borderBottom: "none",
    },
});

const TabLabel = (props: {
    indicator: boolean;
    children: string;
    earlyAccess: "alpha" | "beta" | undefined;
}) => (
    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <Txt font="secondary" level={8} emphasis display="inline">
            {props.children}
        </Txt>
        {props.indicator && <ActionIndicator />}
        {props.earlyAccess && (
            <StatusBadge text="Beta" size="sm" status="beta" />
        )}
    </div>
);

const ActionIndicator = styled("div")(({ theme }) => ({
    position: "absolute",
    top: `${theme.spacing(3)}`,
    right: `${theme.spacing(2)}`,
    [theme.breakpoints.up("md")]: {
        top: `${theme.spacing(3)}`,
        right: `${theme.spacing(4)}`,
    },
    height: 6,
    width: 6,
    borderRadius: 100,
    background: theme.colours.orange[500],
    boxShadow: "0px 1px 3px 0px   #00000030",
}));
