import { Fragment, ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Tooltip, useTheme } from "@mui/material";
import { lighten } from "polished";

import { gql } from "__generated__/apollo";
import { StepControl, StepState } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Txt from "components/common/Text";
import { CULTURE_SCHEDULE_LEGACY_QUERY } from "services/hooks/useCultureScheduleLegacy";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { log as parentLog } from "../log";

import ConfirmStepDialog from "./ConfirmStepDialog";
import { StepItemProps } from "./StepItem";

export const log = parentLog.extend("StepConfirmation");

export function StepConfirmation(props: StepItemProps): ReactElement {
    const { deviceId, step } = props;
    const theme = useTheme();
    const confirmationRequired = props.step.confirmationRequired;
    const confirmationWaiting = props.step.waitingForConfirmation;
    const confirmed = props.step.confirmed;
    const stepPlanned = props.step.state === StepState.Planned;
    const [executing, setExecuting] = useState(false); // handles any controls in an executing state
    const { canOperate } = useDeviceRole(props.deviceId);

    const [controlStep] = useMutation(CONFIRM_STEP_MUTATION, {
        refetchQueries: [CULTURE_SCHEDULE_LEGACY_QUERY],
    });
    const confirmStep = async () => {
        setExecuting(true);
        await controlStep({
            variables: {
                input: {
                    control: StepControl.Confirm,
                    deviceId,
                    stepId: step.id,
                },
            },
        });
        setExecuting(false);
    };

    const skipStep = async () => {
        setExecuting(true);
        await controlStep({
            variables: {
                input: {
                    control: StepControl.Remove,
                    deviceId,
                    stepId: step.id,
                },
            },
        });
        setExecuting(false);
    };

    const tooltipMessage: string = canOperate
        ? ""
        : "You do not have permission to control the device. Contact an admin to change your role.";

    const confirmDisabled = !canOperate || executing;

    if (confirmationRequired && stepPlanned && !confirmed) {
        return (
            <div
                css={css`
                    color: ${theme.colours.accent.alertOrange};
                    display: flex;
                    align-items: center;
                    gap: 18px;
                `}>
                <StepConfirmationIcon />
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    `}>
                    {props.step.confirmationText && (
                        <ConfirmationText level={8}>
                            {props.step.confirmationText}
                        </ConfirmationText>
                    )}
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 8px;
                        `}>
                        <Tooltip title={tooltipMessage}>
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 8px 0;
                                    padding: 0px 16px;
                                    border: 1px solid
                                        ${theme.colours.accent.alertOrange};
                                    border-radius: 4px;
                                    background-color: ${lighten(
                                        0.4,
                                        theme.colours.accent.alertOrange,
                                    )};
                                `}>
                                <ConfirmStepDialog
                                    disabled={confirmDisabled}
                                    confirmStep={confirmStep}
                                    confirmationWaiting={confirmationWaiting}
                                    stepName={step.name}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={tooltipMessage}>
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 8px 0;
                                    padding: 0px 16px;
                                    border: 1px solid
                                        ${theme.colours.neutral[600]};
                                    border-radius: 4px;
                                    background-color: ${lighten(
                                        0.4,
                                        theme.colours.neutral[600],
                                    )};
                                `}>
                                <Button
                                    disabled={!canOperate || executing}
                                    onClick={skipStep}
                                    variant="tertiary"
                                    size="s"
                                    iconRight="skip"
                                    colour={theme.colours.neutral[600]}>
                                    Skip
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    } else if (confirmed) {
        return (
            <div
                css={css`
                    color: ${theme.colours.neutral[500]};
                    display: flex;
                    align-items: center;
                    gap: 18px;
                `}>
                <StepConfirmationIcon confirmed />
                <Txt font="secondary" level={10} style={{ margin: "4px 0" }}>
                    Confirmation was given to continue
                </Txt>
            </div>
        );
    }
    return <Fragment />;
}

function StepConfirmationIcon(props: { confirmed?: boolean }): ReactElement {
    const { confirmed = false } = props;
    return (
        <div
            css={css`
                width: 20px;
                position: relative;
                display: flex;
                justify-content: center;
            `}>
            {confirmed ? (
                <ConfirmationGivenIcon />
            ) : (
                <ConfirmationPendingIcon />
            )}
        </div>
    );
}

function ConfirmationPendingIcon() {
    const theme = useTheme();
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.528809 6.05529L6.05523 0.52887L13.8708 0.52887L19.3972 6.05529V13.8708L13.8708 19.3972H6.05523L0.528809 13.8708L0.528809 6.05529Z"
                    fill={theme.colours.neutral.white}
                    stroke={theme.colours.accent.alertOrange}
                />
            </svg>
            <Icon
                name="touch-select"
                size="sm"
                css={css`
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                `}
            />
        </>
    );
}

function ConfirmationGivenIcon() {
    const theme = useTheme();
    return (
        <div
            css={css`
                border-radius: 20px;
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${theme.colours.neutral.white};
            `}>
            <div
                css={css`
                    border-radius: 20px;
                    width: 8px;
                    height: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colours.brand.green};
                    opacity: 0.4;
                `}></div>
        </div>
    );
}

const CONFIRM_STEP_MUTATION = gql(`
    mutation ConfirmStepMutationLegacy($input: ControlStepInput!) {
        controlStep(input: $input) {
            ok
            message
            step {
                id
                name
                state
                confirmationRequired
                waitingForConfirmation
                confirmed
                timeStarted
            }
        }
    }
`);

const ConfirmationText = styled(Txt)`
    padding: 6px 0 0 0;
    color: ${({ theme }) => theme.colours.orange[500]};
`;
