import React, { ReactElement } from "react";

import { SxProps, Theme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { isNumber } from "lodash";

import Skeleton from "./Skeleton";

/**
 * ! IMPLEMENTATION NOTICE
 *
 * The implementation of this component is largely due to the lack of variant
 * customisation at the theme level. When this is available, customising
 * components by building wrappers around them will become less of an issue.
 * This work will be released in Material UI v5 (MUI v5). Migration of this
 * repository's use of the existing v4 to the new MUI version is covered in
 * Clubhouse ticket CH-5154
 *
 * This <Text> component however should be used as much as reasonably possible.
 * It implements the specific design system set out in our design files, and
 * ensures that all text rendered in the app uses a common API and a common
 * implementation where fixes can be provided for a large distributed benefits.
 */

/**
 * Properties for the common <Text> component
 */
export interface TxtProps {
    children: string | React.ReactNode;
    /**
     * Is the text a major or minor aspect of the page?
     */
    font?: Font;
    /**
     * What size level is it? These are abstracted from actual sizes which are
     * handled under the hood based on the render context.
     */
    level?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | "inherit";
    /**
     * Should the text be emphasised (displayed in bold)?
     */
    emphasis?: boolean;
    /**
     * Should the text be emphasised (displayed in bold)?
     */
    italic?: boolean;
    /**
     * How should the rendering of this text behave alongside sibling components?
     */
    display?: "initial" | "block" | "inline";
    /**
     * Set the horizontal alignment of the text
     */
    align?: TypographyProps["align"];
    /**
     * Should the space where text be become represented by a skeleton. This is
     * useful when the value of the child is not deterministic at render time
     * and you want to minimise post-fetch movement, or provide better UX.
     *
     * If `true` is provided, a sensible default width for the Skeleton will be
     * set, or if a `number` is provided directly then that will be used to set
     * the Skeleton's width.
     */
    skeleton?: boolean | number;
    /**
     * If needed, a custom className can be passed down to the underlying
     * component. This should be avoided if possible.
     */
    className?: string;
    /**
     * Sx prop forwarded to the Mui component
     */
    sx?: SxProps<Theme>;
    /**
     * If needed, a custom CSS object can be passed down to the underlying
     * component. This should be avoided if possible.
     */
    style?: React.CSSProperties;
    // TODO see below
    /**
     * Should there be a `variant` property? What should the variants be? What
     * about custom colour property?
     *
     * See custom variants in CH-5154
     */
}

/**
 * Available font types e.g. 'primary'
 */
type Font = "primary" | "secondary";

/**
 * Key-value pairs of font type to font family.
 *
 * E.g. `primary` maps to `Campton`
 */
export const fontFamilyMap: {
    [F in Font]: string;
} = {
    primary: "Campton",
    secondary: "Work Sans",
};

// TODO could have function use theme.typography.pxToRem() instead of static?
// TODO could have function use polished().pxToRem() instead of static?

/**
 * The following map describes the corresponding font size for each Typography
 * style levels as specified in our design system.
 *
 * As described in https://material-ui.com/components/typography/#accessibility
 * these font sizes are specified in the `rem` unit for accessibility reasons.
 * These sizes are specified based on the assumption that `1rem = 16px`.
 */
export const textLevelFontSize: {
    [K in Required<TxtProps>["level"]]: string;
} = {
    1: "3rem", // 48px
    2: "2.5rem", // 40px
    3: "2rem", // 32px
    4: "1.5rem", // 24px
    5: "1.25rem", // 20px
    6: "1.125rem", // 18px
    7: "1rem", // 16px (standard body size)
    8: "0.875rem", // 14px
    9: "0.75rem", // 12px
    10: "0.625rem", // 10px
    inherit: "inherit",
};

/**
 * The following map describes the corresponding line height for each Typography
 * style levels as specified in our design system.
 *
 * As described in https://material-ui.com/components/typography/#accessibility
 * these line heights are specified in the `rem` unit for accessibility reasons.
 * These sizes are specified based on the assumption that `1rem = 16px`.
 */
export const textLevelLineHeight: {
    [K in Required<TxtProps>["level"]]: string;
} = {
    1: "3.5rem", // 56px
    2: "3rem", // 48px
    3: "2.5rem", // 40px
    4: "1.875rem", // 30px
    5: "1.75rem", // 28px
    6: "1.625rem", // 26px
    7: "1.5rem", // 24px (standard body line height)
    8: "1.375rem", // 22px
    9: "1.125rem", // 18px
    10: "1rem", // 16px
    inherit: "inherit",
};

/**
 * Standard text component for all string representation throughout the App. The
 * properties available in the API reflect the Design System, which makes
 * details of the exact font-family, styling, and pixel sizing abstract from any
 * presentational logic.
 */
export default function Txt({
    font = "primary",
    level = 1,
    emphasis = false,
    italic,
    display = "block",
    ...otherProps
}: TxtProps): ReactElement {
    const { children, style, className, skeleton, align, sx } = otherProps;

    let skeletonWidth: number | undefined = 150; // sensible default
    if (isNumber(skeleton)) {
        // we scale this by a pixel value we define as an arbitrary width of a
        // single character
        skeletonWidth = skeleton * 10;
    }

    const fontFamily = fontFamilyMap[font];
    const fontSize = textLevelFontSize[level];
    const fontWeight = emphasis ? 600 : 400;
    const fontStyle =
        italic === undefined ? "inherit" : italic ? "italic" : "normal";
    const lineHeight = textLevelLineHeight[level];

    return (
        <Typography
            component="span" // this allows component to nest in itself
            className={className}
            sx={{
                fontFamily,
                fontSize,
                fontWeight,
                fontStyle,
                lineHeight,
                color: "inherit",
                display,
                ...sx,
            }}
            align={align}
            style={style}>
            {skeleton ? <Skeleton width={skeletonWidth} /> : children}
        </Typography>
    );
}
