import { Fragment, ReactElement } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import {
    DeviceUserRole,
    UpdateDeviceUserMutationVariables,
} from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { log as parentLog } from "../../log";

import { SelectUserRole } from "./SelectUserRole";

export const log = parentLog.extend("ChangeUserRole");

interface ChangeUserRoleProps {
    role: DeviceUserRole;
    edgeId: string;
    deviceId: string;
}

const UPDATE_DEVICE_USER_MUTATION = gql(`
    mutation UpdateDeviceUser($input: UpdateDeviceUserInput!) {
        updateDeviceUser(input: $input) {
            ok
            message
            device {
                id
                users {
                    totalCount
                    edges {
                        id
                        role
                        user {
                            id
                            email
                        }
                        createdAt
                        lastChangedBy {
                            email
                            id
                        }
                        lastChangedAt
                    }
                }
            }
        }
    }
`);

export function ChangeUserRole(props: ChangeUserRoleProps): ReactElement {
    const { role, edgeId, deviceId } = props;
    const { toast } = useToasts();
    const { isAdmin } = useDeviceRole(deviceId);
    const [changeUser] = useMutation(UPDATE_DEVICE_USER_MUTATION);

    const handleRoleChange = async (role: DeviceUserRole) => {
        const variables: UpdateDeviceUserMutationVariables = {
            input: {
                edgeId: edgeId,
                role,
            },
        };
        const response = await changeUser({ variables });
        log.debug("Mutation response", response);

        const mutationResponse = response.data?.updateDeviceUser;
        if (mutationResponse) {
            if (mutationResponse.ok) {
                toast.success("User role has been updated");
                return;
            } else {
                toast.error(
                    `Unable to change role of User. ${mutationResponse.message}.`,
                );
                return;
            }
        }
        toast.error("Unable to change role of User. Please contact support.");
    };

    return (
        <Fragment>
            <SelectUserRole
                defaultValue={role}
                disabled={!isAdmin}
                onChange={handleRoleChange}
            />
        </Fragment>
    );
}
