import { useEffect } from "react";

/**
 * Hook that attaches a listener to the window. When Events of type `id` are
 * heard, then the hook will called the provided `handler` function.
 *
 * @param id - Event type identifier
 * @param handler - Callback function to execute
 */
export function useWindowEventListener(args: {
    id: string;
    handler: () => void;
}): void {
    const { id, handler } = args;
    useEffect(() => {
        window.addEventListener(id, handler);
        return () => {
            window.removeEventListener(id, handler);
        };
    }, [id, handler]);
}
