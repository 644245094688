/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
import type { SeverityLevel } from "@sentry/types";
import debug from "debug";
import { isString } from "lodash";
import LogRocket from "logrocket";
import posthog from "posthog-js";

export interface Dictionary<T> {
    [Key: string]: T;
}

export interface Log {
    debug(...args: any[]): void;
    info(...args: any[]): void;
    warn(...args: any[]): void;
    error(...args: any[]): void;
}

export const validLogLevels = [
    "debug",
    "info",
    "warn",
    "error",
    "silent",
] as const;
export type LogLevel = (typeof validLogLevels)[number];

export type LoggerConfig = {
    logLevel?: LogLevel;
};

export class Logger implements Log {
    private Debugger: debug.Debugger;
    private name: string;
    private _logLevel: LogLevel;

    constructor(name: string, { logLevel = "debug" }: LoggerConfig = {}) {
        this.name = name;
        this._logLevel = logLevel;
        this.Debugger = debug(this.name);
    }

    /**
     * Calls the underlying 'Debug' library to make a log
     * @param args
     */
    private callDebug(...args: any[]) {
        this.Debugger(args[0], ...args.slice(1));
    }

    /**
     * Determines whether the proposed `level` is high enough to emit the log or
     * whether it should be suppressed. Returns boolean.
     * @param level Proposed level to emit from
     */
    private shouldEmit(level: LogLevel): boolean {
        if (this._logLevel === "silent") return false;

        // get numeric level of logger currently
        const allowedLevel = validLogLevels.indexOf(level);
        // get numeric level of proposed log
        const targetLevel = validLogLevels.indexOf(level);
        // evaluate levels
        if (targetLevel >= allowedLevel) return true;
        return false;
    }

    extend(name: string): Logger {
        const newLogger = new Logger(this.name + ":" + name, {
            logLevel: this._logLevel,
        });
        return newLogger;
    }

    private posthogLog(args: any[], level?: "log" | "warn" | "error"): void {
        const stringArgs: string[] = args.map(arg => {
            if (isString(arg)) {
                return arg;
            }
            try {
                return arg.toString();
            } catch {
                // do nothing
            }
            try {
                return JSON.stringify(arg);
            } catch {
                // do nothing
            }
            return "[Unserialisable]";
        });
        const message = stringArgs.join(" ");
        posthog.sessionRecording?.log(message, level);
    }

    /**
     * Debug statements are the lowest level of logging. They are also sometimes
     * referred to as "verbose" logs.
     * @param args any number of any
     */
    debug(...args: any[]): void {
        LogRocket.debug(this.name, ...args);
        this.posthogLog(args, "log");
        // eslint-disable-next-line no-console
        this.Debugger.log = console.debug.bind(console);
        if (this.shouldEmit("debug")) this.callDebug(...args);
    }

    /**
     * Info logs are used sparingly to track key steps or flows within a complex
     * call stack. This information is always relevant to be displayed.
     * @param args any number of any
     */
    info(...args: any[]): void {
        LogRocket.info(this.name, ...args);
        this.posthogLog(args, "log");
        // eslint-disable-next-line no-console
        this.Debugger.log = console.info.bind(console);
        if (this.shouldEmit("info")) this.callDebug(...args);
    }

    /**
     * Warn logs are used to indicate a potential issue, or imminent issues.
     * These are always displayed and should be exected to be passed through to
     * error tacking systems such as Sentry.
     * @param args any number of any
     */
    warn(...args: any[]): void {
        LogRocket.warn(this.name, ...args);
        this.posthogLog(args, "warn");
        // eslint-disable-next-line no-console
        this.Debugger.log = console.warn.bind(console);
        this.sentryEvent("warning", args);
        if (this.shouldEmit("warn")) this.callDebug(...args);
    }

    /**
     * Error logs are the highest log level that can be called. They should only
     * be used with significant issues, that need to be fixed if they occur.
     * Expect these to be logged with an error tracking system such as Sentry.
     * @param args any number of any
     */
    error(...args: any[]): void {
        LogRocket.error(this.name, ...args);
        this.posthogLog(args, "error");
        // eslint-disable-next-line no-console
        this.Debugger.log = console.error.bind(console);
        this.sentryEvent("error", args);
        if (this.shouldEmit("error")) this.callDebug(...args);
    }

    /**
     * Records the log with Sentry as a breadcrumb for diagnostics.
     * @param message Error message
     */
    private addBreadcrumb(
        message: string,
        severity: SeverityLevel,
        data?: object,
    ): void {
        Sentry.addBreadcrumb({
            category: this.name,
            message: message,
            level: severity,
            data: data,
        });
    }

    private sentryEvent(level: SeverityLevel, args: any[]) {
        let msg = "<Log without message string>";
        for (const arg of args) {
            if (typeof arg === "string") {
                msg = arg;
                break;
            }
        }
        let data;
        for (const arg of args) {
            if (typeof arg === "object") {
                data = arg;
                break;
            }
        }

        let exception = new Error(msg); // default

        // If we get an exception object
        if (data instanceof Error) {
            exception = data;
        } else if (data?.err instanceof Error) {
            exception = data.err;
        } else if (data?.error instanceof Error) {
            exception = data.error;
        }

        // For certain log levels, we want to avoid capturing Sentry exceptions

        const quietLevels: SeverityLevel[] = [
            "debug",
            "info",
            "log",
            "warning",
        ];

        if (quietLevels.includes(level)) {
            this.addBreadcrumb(msg, level, data);
        } else {
            this.addBreadcrumb(msg, level, data);
            Sentry.captureException(exception);
        }
    }
}

const defaultLog = new Logger("mytos");
defaultLog.info("Default logger created.");

export default defaultLog;
