import { ReactElement } from "react";

import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Txt from "components/common/Text";

import Icon, { IconName } from "./Icon";
import Skeleton from "./Skeleton";

export interface StatusBadgeProps {
    /**
     * The status of the badge:
     * - `ok` styled with a positive green
     * - `warn` styled with an alerting orange
     * - `disabled` styled with a low contrast grey
     */
    status: "ok" | "warn" | "disabled" | "info" | "beta";
    /**
     * The text content of the badge.
     */
    text: string;
    /**
     * Fill applies a solid, rather than outline style.
     */
    fill?: boolean;
    /**
     * Size of the badge. Defaults to "md".
     */
    size?: "sm" | "md";
    /**
     * Icon to be displayed to the left of the text
     */
    icon?: IconName;
    /**
     * Renders a skeleton of the same shape and size as the badge
     */
    skeleton?: boolean;
    /**
     * A message to display when mouse hovers over badge
     */
    tooltip?: string;

    /**
     * An element to be displayed to the right of the text
     */
    endAdornment?: ReactElement;
}

export default function StatusBadge({
    status,
    text,
    fill,
    size = "md",
    icon,
    skeleton,
    tooltip,
    endAdornment,
}: StatusBadgeProps): ReactElement {
    const theme = useTheme();

    if (skeleton) {
        return (
            <Skeleton
                height={{ sm: 26, md: 32 }[size]}
                width={80}
                style={{ borderRadius: 1000 }}
            />
        );
    }

    /**
     * A special colour definition just for this component. Unclear yet whether
     * this colour should be part of the design system and used elsewhere so
     * we're keeping it scoped here.
     */
    const PURPLE = "#A72CD1";

    const nofillVariants: {
        [k in StatusBadgeProps["status"]]: {
            color: string;
        };
    } = {
        ok: {
            color: theme.colours.brand.greenDark,
        },
        warn: {
            color: theme.colours.accent.alertOrange,
        },
        disabled: {
            color: theme.colours.neutral[500],
        },
        info: {
            color: theme.colours.blue[500],
        },
        beta: {
            color: PURPLE,
        },
    };

    const fillVariants: {
        [k in StatusBadgeProps["status"]]: {
            color: string;
            backgroundColor: string | undefined;
            borderColor: string | undefined;
        };
    } = {
        ok: {
            color: theme.colours.neutral.white,
            backgroundColor: theme.colours.brand.greenDark,
            borderColor: theme.colours.brand.greenDark,
        },
        warn: {
            color: theme.colours.neutral.white,
            backgroundColor: theme.colours.accent.alertOrange,
            borderColor: theme.colours.accent.alertOrange,
        },
        disabled: {
            color: theme.colours.neutral.white,
            backgroundColor: theme.colours.neutral[500],
            borderColor: theme.colours.neutral[500],
        },
        info: {
            color: theme.colours.neutral.white,
            backgroundColor: theme.colours.blue[500],
            borderColor: theme.colours.blue[500],
        },
        beta: {
            color: theme.colours.neutral.white,
            backgroundColor: PURPLE,
            borderColor: PURPLE,
        },
    };

    const variantStyle = (fill ? fillVariants : nofillVariants)[status];

    const sizeStyle = (
        {
            sm: { textLevel: 9, height: "24px", padding: "0px 8px" },
            md: { textLevel: 8, height: "30px", padding: "0px 12px" },
        } as const
    )[size];

    return (
        <Tooltip title={tooltip ?? ""}>
            <div
                style={{
                    display: "inline-flex",
                    alignItems: "center",
                    margin: 0,
                    padding: sizeStyle.padding,
                    border: "1px solid",
                    height: sizeStyle.height,
                    borderRadius: 1000, // pill shape
                    gap: 6,
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                    userSelect: "none",
                    ...variantStyle,
                }}>
                {icon && <Icon name={icon} size={size} />}
                <Txt font="secondary" level={sizeStyle.textLevel} emphasis>
                    {text}
                </Txt>
                {endAdornment}
            </div>
        </Tooltip>
    );
}
