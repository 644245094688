/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
};

/** Input required to add a new User to Device. */
export type AddDeviceUserInput = {
    /** ID of the Device to grant access to */
    deviceId: Scalars["String"]["input"];
    /** The role of the User when accessing the Device */
    role: DeviceUserRole;
    /** Email of User getting access to the Device, if not provided then an ID must be provided. This field will be ignored if `userId` is provided. */
    userEmail?: InputMaybe<Scalars["String"]["input"]>;
    /** ID of User getting access to the Device, if not provided then an email must be provided. If it is provided, then the `userEmail` field will be ignored. */
    userId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Payload required to add an Operation to a Step */
export type AddOperationToStepInput = {
    /** A reference to the Operation that will be added */
    operationId: Scalars["String"]["input"];
    /** The parameters to be provided to the Operation at execution time */
    parameters: Scalars["JSON"]["input"];
    /** The ID of the Step to add the Operation to */
    stepId: Scalars["String"]["input"];
};

/** Response payload for adding an Operation to a Step */
export type AddOperationToStepMutationResponse = MutationResponse & {
    __typename?: "AddOperationToStepMutationResponse";
    /** Newly created invocation */
    invocation: Maybe<Invocation>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if Step mutation was successful */
    ok: Scalars["Boolean"]["output"];
};

/**
 * An API Key that has been commissioned by the User.
 * This type does not actually provide the token itself, just the metadata.
 */
export type ApiKey = {
    __typename?: "ApiKey";
    /** UTC ISO timestamp of when the API key was created */
    created: Scalars["String"]["output"];
    /** UTC ISO timestamp of when the API key will expire */
    expiry: Maybe<Scalars["String"]["output"]>;
    /** Unique ID of the API token */
    id: Scalars["String"]["output"];
    /** Friendly name of the token, set by the User */
    name: Maybe<Scalars["String"]["output"]>;
    /**
     * The raw API token payload (includes much of other keys of this object).
     * Primarily used for debugging.
     */
    payload: Maybe<Scalars["JSON"]["output"]>;
    /** The scopes that this API key has been provisioned with */
    scopes: Maybe<Array<Maybe<ApiScope>>>;
};

/** API Scopes */
export enum ApiScope {
    /** Authorised to delete resources */
    Delete = "DELETE",
    /** Authorised to send GET requests through the Device Proxy */
    ProxyReadDevice = "PROXY_READ_DEVICE",
    /** Authorised to send POST requests through the Device Proxy */
    ProxyWriteDevice = "PROXY_WRITE_DEVICE",
    /** Authorised to use GraphQL query operations */
    ReadGraphql = "READ_GRAPHQL",
    /** Authorised to use GraphQL mutation operations */
    WriteGraphql = "WRITE_GRAPHQL",
}

/** Device metadata determined by the Balena service */
export type Balena = {
    __typename?: "Balena";
    /** Releases available to the Device */
    availableReleases: Maybe<Array<Maybe<BalenaRelease>>>;
    /** Commit SHA of the current running software release */
    currentCommit: Maybe<Scalars["String"]["output"]>;
    /** Timestamp of the release of the current running software in ISO format */
    currentCommitDate: Maybe<Scalars["String"]["output"]>;
    /** Balena Device Name */
    deviceName: Maybe<Scalars["String"]["output"]>;
    /** The name of the device type */
    deviceTypeName: Maybe<Scalars["String"]["output"]>;
    /** Balena Device UUID */
    deviceUuid: Maybe<Scalars["String"]["output"]>;
    /** Name of the fleet the Device belongs to */
    fleetName: Maybe<Scalars["String"]["output"]>;
    /** Commit SHA of the release the fleet should be running */
    fleetTargetCommit: Maybe<Scalars["String"]["output"]>;
    /** IP addresses (IPv4 and IPv6) of device */
    ipAddresses: Maybe<Array<Scalars["String"]["output"]>>;
    /** Whether the Device is online as reported by Balena */
    isOnline: Maybe<Scalars["Boolean"]["output"]>;
    /** When the device was lastConnected */
    lastConnected: Maybe<Scalars["String"]["output"]>;
    /** The geolocation of the device */
    location: Maybe<Scalars["String"]["output"]>;
    /** The MAC addresses of the device */
    macAddresses: Maybe<Array<Scalars["String"]["output"]>>;
    /** Whether the override lock on the device is enabled */
    overrideLockEnabled: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * True when a Device's software version is locked to a particular release,
     * rather than tracking the software version of the fleet it belongs to
     */
    pinned: Scalars["Boolean"]["output"];
    /** The public IP address of the device */
    publicAddress: Maybe<Scalars["String"]["output"]>;
    /** Commit SHA of the latest release the Device should be running */
    targetCommit: Maybe<Scalars["String"]["output"]>;
    /** True when the Device has software update to perform */
    updateAvailable: Scalars["Boolean"]["output"];
    /** True when the Device is actively updating */
    updating: Scalars["Boolean"]["output"];
};

/** Object representing a Balena Release */
export type BalenaRelease = {
    __typename?: "BalenaRelease";
    /** Commit SHA of the release */
    commit: Maybe<Scalars["String"]["output"]>;
    /** Timestamp of the release in ISO format */
    createdAt: Maybe<Scalars["String"]["output"]>;
    /** Balena Release ID */
    id: Maybe<Scalars["String"]["output"]>;
};

/** Interface containing the fields that are shared across all types of parameters */
export type BaseParameter = {
    /** A brief description of the parameter */
    description: Maybe<Scalars["String"]["output"]>;
    /** A readable name for the parameter */
    name: Maybe<Scalars["String"]["output"]>;
    /** A unique ID for the parameter */
    parameterId: Maybe<Scalars["String"]["output"]>;
    /** Whether this parameter required to run the device operation */
    required: Maybe<Scalars["Boolean"]["output"]>;
};

/** A parameter type to characterise a valid boolean */
export type BooleanParameter = BaseParameter & {
    __typename?: "BooleanParameter";
    /** The default boolean value */
    default: Maybe<Scalars["Boolean"]["output"]>;
    /** A brief description of the parameter */
    description: Maybe<Scalars["String"]["output"]>;
    /** A readable name for the parameter */
    name: Maybe<Scalars["String"]["output"]>;
    /** A unique ID for the parameter */
    parameterId: Maybe<Scalars["String"]["output"]>;
    /** Whether this parameter required to run the device operation */
    required: Maybe<Scalars["Boolean"]["output"]>;
};

/** A Bottle on a Device */
export type Bottle = {
    __typename?: "Bottle";
    /**
     * Container reference of the bottle, as provided in the protocol designer.
     * Unique within a culture.
     */
    containerReference: Maybe<Scalars["String"]["output"]>;
    /** When the bottle was created on the device side */
    createdAt: Maybe<Scalars["String"]["output"]>;
    /**
     * Unique ID of the culture the bottle belongs to
     * @deprecated Use cultures.nodes.id instead
     */
    cultureId: Maybe<Scalars["String"]["output"]>;
    /** Cultures the bottle belongs to */
    cultures: Maybe<CultureUnpaginatedConnection>;
    /** Unique ID of the bottle (Primary Key) */
    id: Scalars["String"]["output"];
    /** Current volume of the fluid in the bottle, in millilitres */
    liquidVolumeMl: Maybe<Scalars["Float"]["output"]>;
    /** Readable name of the bottle */
    name: Maybe<Scalars["String"]["output"]>;
    /** Total volume of fluid that can be stored in the bottle, in millilitres */
    totalVolumeMl: Maybe<Scalars["Float"]["output"]>;
    /** When the bottle was updated on the device side */
    updatedAt: Maybe<Scalars["String"]["output"]>;
};

/** Connection object for the `Bottle` type */
export type BottleConnection = Connection & {
    __typename?: "BottleConnection";
    /**
     * Array of Bottle nodes. If pagination arguments are provided to
     * `BottleConnection`, this array will be modified.
     */
    nodes: Maybe<Array<Maybe<Bottle>>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** Cancel a Device Job */
export type CancelDeviceJob = {
    /** The ID of the Device Job to cancel */
    id: Scalars["String"]["input"];
};

/** All possible cell formats for a culture */
export enum CellFormat {
    /** Culture begins in the clump format */
    Clump = "CLUMP",
    /** Culture begins in the single cell format */
    SingleCell = "SINGLE_CELL",
}

/** Required interface for Connection objects */
export type Connection = {
    /** Page info for connection object */
    pageInfo: PageInfo;
    /** Total count for associated data */
    totalCount: Scalars["Int"]["output"];
};

/** Payload required to control Culture */
export type ControlCultureInput = {
    /** The control type to execute */
    control: CultureControl;
    /** The ID of the Culture to control */
    cultureId: Scalars["String"]["input"];
};

/** Response payload a culture control */
export type ControlCultureResponse = MutationResponse & {
    __typename?: "ControlCultureResponse";
    /** The culture affected */
    culture: Culture;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if successful */
    ok: Scalars["Boolean"]["output"];
};

/** Payload required to control an Invocation */
export type ControlInvocationInput = {
    /** The control type to execute */
    control: InvocationControl;
    /** The ID of the Device */
    deviceId: Scalars["String"]["input"];
    /** The ID of the Invocation to control */
    invocationId: Scalars["String"]["input"];
};

/** Payload required to control Procedure */
export type ControlProcedureInput = {
    /** The control type to execute */
    control: ProcedureControl;
    /** The ID of the Procedure to control */
    procedureId: Scalars["String"]["input"];
};

/** Response for controlling a Procedure */
export type ControlProcedureResponse = MutationResponse & {
    __typename?: "ControlProcedureResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of the edit attempt */
    ok: Scalars["Boolean"]["output"];
    /** The updated procedure */
    procedure: Maybe<Procedure>;
};

/** Payload required to control Step */
export type ControlStepInput = {
    /** The control type to execute */
    control: StepControl;
    /** The ID of the Device containing the Step */
    deviceId: Scalars["String"]["input"];
    /** The ID of the Step to control */
    stepId: Scalars["String"]["input"];
};

/** Collection of images taken on the counting chip */
export type CountingChipImages = {
    __typename?: "CountingChipImages";
    /** ID of the counting chip */
    countingChipId: Maybe<Scalars["String"]["output"]>;
    /** ID of container receiving the fluid via the counting chip */
    destinationContainerId: Maybe<Scalars["String"]["output"]>;
    /** Array of captured images of the counting chip */
    images: Maybe<Array<Maybe<ImageData>>>;
    /** ID of the flask fluid will be moved to */
    nextFlaskId: Maybe<Scalars["String"]["output"]>;
    /** Flask number that fluid will be moved to */
    nextFlaskNumber: Maybe<Scalars["Int"]["output"]>;
    /** ID of the flask fluid came from */
    previousFlaskId: Maybe<Scalars["String"]["output"]>;
    /** Flask number that fluid came from */
    previousFlaskNumber: Maybe<Scalars["Int"]["output"]>;
    /** ID of container sourcing the fluid via the counting chip */
    sourceContainerId: Maybe<Scalars["String"]["output"]>;
};

/**
 * Create a culture using a protocol JSON stored in the server database, and
 * sync the culture data with the device
 */
export type CreateAndSyncCultureInput = {
    /** Optional metadata for the culture */
    cultureMetadata?: InputMaybe<CultureMetadataInput>;
    /** The ID of the Device to create the Culture on */
    deviceId: Scalars["String"]["input"];
    /** The ID of the Protocol to create the Culture with */
    protocolId: Scalars["String"]["input"];
    /** Skip validation of the device status checks */
    skipValidation?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Date of the first day of the culture in YYYY-MM-DD format */
    startDate: Scalars["String"]["input"];
    /**
     * Timezone that the culture is operating in.
     * Formatted as a valid TZ identifier: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     */
    timezone: Scalars["String"]["input"];
};

/** Payload required to create a new API key */
export type CreateApiKeyInput = {
    /** Designated expiry time by the User (if null then no expiry is set) */
    expiry?: InputMaybe<Scalars["String"]["input"]>;
    /** Friendly name given to the API key by the User */
    name?: InputMaybe<Scalars["String"]["input"]>;
    /** Array of scopes to be added to the key */
    scopes?: InputMaybe<Array<InputMaybe<ApiScope>>>;
};

/**
 * A special type returned upon the creation of a new API Key. It is used for this
 * special circumstance because the API key itself should only ever be displayed
 * once, and so this data is only ever returned in the response to a brand new
 * created key.
 */
export type CreateApiKeyResponse = MutationResponse & {
    __typename?: "CreateApiKeyResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** The API Key metadata */
    metadata: Maybe<ApiKey>;
    /** Result of login attempt */
    ok: Scalars["Boolean"]["output"];
    /** Secret token representing the encoded API key */
    token: Maybe<Scalars["String"]["output"]>;
};

/** Response payload for creating a culture */
export type CreateCultureResponse = MutationResponse & {
    __typename?: "CreateCultureResponse";
    /** Newly created culture */
    culture: Maybe<Culture>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if successful */
    ok: Scalars["Boolean"]["output"];
};

/** Parameters available for creating a new Device */
export type CreateDeviceInput = {
    /** Balena UUID of the new Device */
    balenaUuid: Scalars["String"]["input"];
    /** Optional ID to associate the new Device with */
    deviceId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response payload for CreateDevice mutation */
export type CreateDeviceResponse = MutationResponse & {
    __typename?: "CreateDeviceResponse";
    /** Device object created if successful */
    device: Maybe<Device>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Status of create Device attempt */
    ok: Scalars["Boolean"]["output"];
};

/** Payload required to add an Operation to the schedule as an Invocation */
export type CreateInvocationInput = {
    /** The Device containing the Culture where the Operation should be added */
    deviceId: Scalars["String"]["input"];
    /** A reference to the Operation the Invocation will be execute */
    operationId: Scalars["String"]["input"];
    /** The parameters to be provided to the Operation at execution time */
    parameters: Scalars["JSON"]["input"];
};

/**
 * Required payload for creating a protocol JSON.
 * Note: This does not create a culture or sync culture data with device, it
 * only persists the protocol JSON.
 */
export type CreateProtocolInput = {
    /** JSON object containing the protocol */
    protocol: Scalars["JSON"]["input"];
};

/** Response payload for protocol JSON upload */
export type CreateProtocolResponse = MutationResponse & {
    __typename?: "CreateProtocolResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if successful */
    ok: Scalars["Boolean"]["output"];
    /** Created protocol */
    protocol: Maybe<Protocol>;
    /**
     * Created wet test protocol, if there was a wet test protocol bundled with
     * the protocol export
     */
    wetTestProtocol: Maybe<Protocol>;
};

/** A Culture running on a Device */
export type Culture = {
    __typename?: "Culture";
    /** Bottles associated with the culture */
    bottles: Maybe<BottleConnection>;
    /** CSV data download url for the culture */
    csvDownloadUrl: Maybe<Scalars["String"]["output"]>;
    /** Set the offset for the first day of the protocol. */
    dayStartIndex: Maybe<Scalars["Int"]["output"]>;
    /** Description of the purpose of the Culture */
    description: Maybe<Scalars["String"]["output"]>;
    /** The device which runs the culture */
    device: Maybe<Device>;
    /** Information about any errors that have occurred on the culture */
    errorMessage: Maybe<Scalars["String"]["output"]>;
    /** Flasks associated with the culture */
    flasks: Maybe<FlaskConnection>;
    /** Unique ID of the Culture */
    id: Scalars["ID"]["output"];
    /** Whether this culture is loaded onto a device */
    isActive: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * If the culture was created as a wet test for another culture this will be
     * true and otherwise false.
     */
    isWetTestCulture: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * If the last simulation failed this will contain details of
     * the failure. If the last simulation was successful, this will be null.
     */
    lastSimulationError: Maybe<CultureSimulationFailure>;
    /**
     * Used during the rollout of the Culture Procedures project, true if the
     * culture was created by the server (the new way), false if the culture was
     * created by the device (the old way)
     */
    mintedByServer: Maybe<Scalars["Boolean"]["output"]>;
    /** Designated short name of the Culture */
    name: Maybe<Scalars["String"]["output"]>;
    /** Scheduled duration of the Culture in seconds */
    plannedDuration: Maybe<Scalars["Float"]["output"]>;
    /** The protocol that the culture was generated from */
    protocol: Maybe<Protocol>;
    /** Results generated on within the culture */
    results: Maybe<ResultConnection>;
    /** The event schedule which designates when all the tasks will be run by the Device */
    schedule: Maybe<Schedule>;
    /** ISO formatted date of the scheduled start time */
    startDate: Maybe<Scalars["String"]["output"]>;
    /** Current state of the Culture */
    state: Maybe<CultureState>;
    /** ISO formatted date of the time the culture was loaded onto the Device */
    uploadDate: Maybe<Scalars["String"]["output"]>;
    /**
     * If this culture was generated from a wet test protocol, this is defined
     * and contains the primary protocol it is associated with.
     */
    wetTestPrimaryProtocol: Maybe<Protocol>;
};

/** A Culture running on a Device */
export type CultureResultsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    before?: InputMaybe<Scalars["String"]["input"]>;
    filterBy?: InputMaybe<ResultConnectionFilters>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Connection object for associated Cultures */
export type CultureConnection = Connection & {
    __typename?: "CultureConnection";
    /**
     * Array of `CultureEdge` edges. If pagination arguments are provided to
     * `CultureConnection`, this array will be sliced. By default the array is
     * ordered by the `CultureEdge.Culture.createdAt` field.
     */
    edges: Maybe<Array<Maybe<CultureEdge>>>;
    /**
     * Array of Culture nodes. If pagination arguments are provided to
     * `CultureConnection`, this array will be sliced.
     * By default the array is ordered by the `Culture.createdAt` field.
     */
    nodes: Maybe<Array<Maybe<Culture>>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** Filter cultures options */
export type CultureConnectionFilters = {
    /** Filter cultures based on the culture that they were produced in */
    deviceIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
    /** Whether the culture is currently on the device */
    onDevice?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Filter cultures based on their current state */
    states?: InputMaybe<Array<CultureState>>;
};

/** All controls of an Culture */
export enum CultureControl {
    /** Clear the Culture from the device. This action will remove all data */
    Clear = "CLEAR",
    /**
     * Pause a Culture that is currently running. If a Procedure is currently
     * running, the Culture will transition to the PAUSING state whilst that
     * Procedure completes, then the Culture will transition to the PAUSED state.
     * When the culture RESUME action is sent the Culture will continue from
     * the next Procedure.
     * You can pause at the Procedure and Step levels too, they might lead the the
     * device doing less work before it gets to the PAUSED state.
     */
    Pause = "PAUSE",
    /**
     * Restart a Culture that has been completed. This action will sync outstanding
     * procedures to the device and restart the culture. The culture will be
     * marked as complete again once all procedures have been completed.
     */
    Restart = "RESTART",
    /**
     * Resume a Culture that is currently paused. This action is used to restart
     * cultures that have been paused through any action (i.e. Culture pause,
     * Procedure pause or Step pause).
     */
    Resume = "RESUME",
    /** Start a Culture that has been loaded onto the device */
    Start = "START",
}

/**
 * Metadata on the relationship between the `CultureConnection` object and the
 * `Culture` data itself.
 */
export type CultureEdge = {
    __typename?: "CultureEdge";
    /** Obscured cursor for Culture pagination (cursors have no semantic meaning) */
    cursor: Maybe<Scalars["String"]["output"]>;
    /** Culture object */
    node: Maybe<Culture>;
};

/** Optional metadata associated with a culture to be provided by the user */
export type CultureMetadataInput = {
    /** Optional metadata - format of the cells in the culture. */
    cellFormat?: InputMaybe<CellFormat>;
    /**
     * Optional metadata - cell line of cells used.
     * Culture will be tagged with this information.
     */
    cellLine?: InputMaybe<Scalars["String"]["input"]>;
    /**
     * Optional metadata - concentration of cells loaded in seed vessel, in cells/mL.
     * Culture will be tagged with this information.
     */
    initialSeedCellConcentration?: InputMaybe<Scalars["Float"]["input"]>;
    /**
     * Optional metadata - current passage number of cells used.
     * Culture will be tagged with this information.
     */
    passageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * Optional metadata - vial ID of cells used.
     * Culture will be tagged with this information.
     */
    vialID?: InputMaybe<Scalars["String"]["input"]>;
};

/** Details of a simulation failure for a culture */
export type CultureSimulationFailure = {
    __typename?: "CultureSimulationFailure";
    /** The ID of the culture that failed */
    cultureId: Maybe<Scalars["String"]["output"]>;
    /** The error message */
    errorMessage: Maybe<Scalars["String"]["output"]>;
    /** The ID of the simulation failure */
    id: Scalars["ID"]["output"];
    /** The invocation that failed simulation */
    problemInvocation: Maybe<Invocation>;
    /** The procedure that failed simulation */
    problemProcedure: Maybe<Procedure>;
    /** The step that failed simulation */
    problemStep: Maybe<Step>;
};

/** Possible states of a Culture */
export enum CultureState {
    /** Culture has finished processing all items in the schedule */
    Complete = "COMPLETE",
    /** Culture is loading on Device */
    Loading = "LOADING",
    /** Culture is not progressing */
    Paused = "PAUSED",
    /**
     * A culture that is in the process of being paused but still performing
     * ongoing operations
     */
    Pausing = "PAUSING",
    /** Culture is unstarted */
    Ready = "READY",
    /** Culture is in progress */
    Running = "RUNNING",
    /** Culture is being updated */
    Updating = "UPDATING",
}

/** Connection object for associated Cultures */
export type CultureUnpaginatedConnection = Connection & {
    __typename?: "CultureUnpaginatedConnection";
    /**
     * Array of Culture nodes. If pagination arguments are provided to
     * `CultureConnection`, this array will be sliced.
     * By default the array is ordered by the `Culture.createdAt` field.
     */
    nodes: Maybe<Array<Maybe<Culture>>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** Payload required to delay a Step and any subsequent steps */
export type DelayStepInput = {
    /** The amount of milliseconds to delay the Step and subsequent Steps */
    delay: Scalars["Int"]["input"];
    /** The ID of the Device containing the Step to delay */
    deviceId: Scalars["String"]["input"];
    /** The ID of the Step to delay */
    stepId: Scalars["String"]["input"];
};

/** Device data */
export type Device = {
    __typename?: "Device";
    /** Device data managed by Balena */
    balena: Maybe<Balena>;
    /** Balena Device UUID */
    balenaUuid: Maybe<Scalars["String"]["output"]>;
    /** The culture loaded on the Device */
    culture: Maybe<Culture>;
    /** ID of Culture currently on the Device */
    cultureId: Maybe<Scalars["String"]["output"]>;
    /** Cultures run on this Device */
    cultures: Maybe<CultureConnection>;
    /** For each device job type, the most recent job that has been requested */
    deviceJobs: Maybe<Array<DeviceJob>>;
    /** Whether environment variables are in-keeping with expected configuration */
    envVarConfigOk: Maybe<Scalars["Boolean"]["output"]>;
    /** Unique ID for Device */
    id: Scalars["ID"]["output"];
    /** Maintenance status */
    isInMaintenance: Maybe<Scalars["Boolean"]["output"]>;
    /** Network availability */
    isOnline: Scalars["Boolean"]["output"];
    /** The ISO-formatted timestamp of the most recently received heartbeat */
    lastHeartbeatTimestamp: Maybe<Scalars["String"]["output"]>;
    /** Date to deactivate maintenance mode */
    maintenanceUntil: Maybe<Scalars["String"]["output"]>;
    /** Additional metadata */
    meta: Maybe<DeviceMetaInfo>;
    /** Whether the device has been migrated to a new environment */
    migrated: Maybe<Scalars["Boolean"]["output"]>;
    /** Preferred name set by a User */
    name: Maybe<Scalars["String"]["output"]>;
    /** The Operations available to the device */
    operations: Maybe<Array<Operation>>;
    /** Org that the Device belongs to */
    orgId: Maybe<Scalars["String"]["output"]>;
    /** The platform of the device, if known */
    platform: Maybe<DevicePlatform>;
    /** Results generated on the Device */
    results: Maybe<ResultConnection>;
    /** Operational status */
    status: Maybe<DeviceStatus>;
    /** Status checks for the device */
    statusChecks: Maybe<Array<StatusCheck>>;
    /** Direct access auth token */
    token: Maybe<Scalars["String"]["output"]>;
    /** Direct access URL */
    url: Maybe<Scalars["String"]["output"]>;
    /** A connection representing Users who have some form of access to the Device */
    users: Maybe<DeviceUserConnection>;
};

/** Device data */
export type DeviceCulturesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    before?: InputMaybe<Scalars["String"]["input"]>;
    filterBy?: InputMaybe<CultureConnectionFilters>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Device data */
export type DeviceResultsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    before?: InputMaybe<Scalars["String"]["input"]>;
    filterBy?: InputMaybe<ResultConnectionFilters>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Device boot information */
export type DeviceBootInfo = {
    __typename?: "DeviceBootInfo";
    /** Start up time */
    startTime: Maybe<Scalars["Int"]["output"]>;
    /** Uptime */
    uptime: Maybe<Scalars["Int"]["output"]>;
};

/** Device camera information */
export type DeviceCameraInfo = {
    __typename?: "DeviceCameraInfo";
    /** Type of camera */
    cameraType: Maybe<Scalars["String"]["output"]>;
    /** ID of camera */
    id: Maybe<Scalars["String"]["output"]>;
};

/** Device equipment information */
export type DeviceEquipmentInfo = {
    __typename?: "DeviceEquipmentInfo";
    /** Cameras */
    cameras: Array<DeviceCameraInfo>;
    /** Kits */
    kits: Array<DeviceKitInfo>;
};

/** A Job running on a Device */
export type DeviceJob = {
    __typename?: "DeviceJob";
    /** Any error message associated with the Device Job */
    errorMessage: Maybe<Scalars["String"]["output"]>;
    /** Unique ID of the Device Job */
    id: Scalars["ID"]["output"];
    /** Current progress of the Device Job (between 0-1) */
    progress: Maybe<Scalars["Float"]["output"]>;
    /** ISO formatted date of when the Device Job was requested */
    requestedAt: Maybe<Scalars["String"]["output"]>;
    /** Current state of the Device Job */
    state: Maybe<DeviceJobState>;
    /** Type of the Device Job */
    type: Maybe<DeviceJobType>;
    /** ISO formatted date of when the Device Job was last updated */
    updatedAt: Maybe<Scalars["String"]["output"]>;
};

/** All Device Job types */
export enum DeviceJobState {
    /** The device job was cancelled */
    Cancelled = "CANCELLED",
    /** The device job was is in the process of being cancelled */
    Cancelling = "CANCELLING",
    /** The device job has completed */
    Complete = "COMPLETE",
    /** The device job has failed */
    Failed = "FAILED",
    /** The device job is in progress on the device */
    InProgress = "IN_PROGRESS",
    /** The device job has been requested but has not yet started */
    Pending = "PENDING",
    /** The device job has been triggered to run on the device */
    Triggered = "TRIGGERED",
}

/** All Device Job types */
export enum DeviceJobType {
    /**
     * The pinch valve bedding device job is executed to ensure pinch valves are
     * properly sealing the tubing
     */
    PinchValveBedding = "PINCH_VALVE_BEDDING",
    /** The shaker job ensures the device shaker works properly */
    Shaker = "SHAKER",
    /** The tilter job ensures the device tilter works properly */
    Tilter = "TILTER",
}

/** Device Kit information */
export type DeviceKitInfo = {
    __typename?: "DeviceKitInfo";
    /** Version of Kit denoted by commit hash of firmware */
    commitHash: Maybe<Scalars["String"]["output"]>;
    /** Environment identifier used for firmware version */
    environment: Maybe<Scalars["String"]["output"]>;
    /** Name of Kit */
    name: Maybe<Scalars["String"]["output"]>;
};

/** Metadata object for a Device */
export type DeviceMetaInfo = {
    __typename?: "DeviceMetaInfo";
    /** Information about Device boot */
    boot: Maybe<DeviceBootInfo>;
    /** Information on what equipment a Device has installed */
    equipment: Maybe<DeviceEquipmentInfo>;
    /** Information on connected network */
    network: Maybe<DeviceNetworkInfo>;
};

/** Device network information */
export type DeviceNetworkInfo = {
    __typename?: "DeviceNetworkInfo";
    /** Network connection type */
    connectionType: Maybe<NetworkConnectionType>;
    /** The local IP of the device */
    localIp: Maybe<Scalars["String"]["output"]>;
    /** Network name */
    networkName: Maybe<Scalars["String"]["output"]>;
    /** Duration connected to network */
    uptime: Maybe<Scalars["Int"]["output"]>;
};

/** Known Device Platforms */
export enum DevicePlatform {
    /** Is an Echo device */
    Echo = "ECHO",
    /** Is a Foxtrot device */
    Foxtrot = "FOXTROT",
    /** Is a mock device */
    Mock = "MOCK",
    /** Is a Triple device */
    Triple = "TRIPLE",
}

/** Status of a Device */
export enum DeviceStatus {
    /** Device is available */
    Available = "AVAILABLE",
    /** Device is offline */
    Offline = "OFFLINE",
    /** Device is updating (may have limited functionality) */
    Updating = "UPDATING",
}

/** Connection object for a Device with multiple Users */
export type DeviceUserConnection = Connection & {
    __typename?: "DeviceUserConnection";
    /** Array of edges between Device and User resources representing the relationship between two unique resources. */
    edges: Maybe<Array<Maybe<DeviceUserEdge>>>;
    /** The edge between the device and the user requesting the connection. */
    myAccess: Maybe<DeviceUserEdge>;
    /** Sliced array of all nodes (ordered by timestamp by default) */
    nodes: Maybe<Array<Maybe<User>>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** Data representing relationship between a Device and a User */
export type DeviceUserEdge = {
    __typename?: "DeviceUserEdge";
    /** ISO UTC timestamp of when the User-Device relationship was created */
    createdAt: Maybe<Scalars["String"]["output"]>;
    /** A cursor for use in pagination */
    cursor: Scalars["String"]["output"];
    /** A unique ID for the relationship between the User and Device */
    id: Scalars["String"]["output"];
    /** ISO UTC timestamp of when the User-Device relationship was last modified */
    lastChangedAt: Maybe<Scalars["String"]["output"]>;
    /** User that last made a modification the this User-Device relationship */
    lastChangedBy: Maybe<User>;
    /** Whether notifications relating to the device are sent to the user */
    notificationsEnabled: Maybe<Scalars["Boolean"]["output"]>;
    /** The role the User has with access to the Device */
    role: DeviceUserRole;
    /** The User with some access to Device */
    user: User;
};

/** Device user role options */
export enum DeviceUserRole {
    /** An administrator has full access to all Device operations */
    Admin = "ADMIN",
    /** An operator has partial access to a Device, including operations and data, but excluding Device settings and configuration. */
    Operator = "OPERATOR",
    /** A viewer only has access to read Device data */
    Viewer = "VIEWER",
}

/** Payload required to duplicate an Invocation */
export type DuplicateInvocationInput = {
    /** The ID of the Device */
    deviceId: Scalars["String"]["input"];
    /** The ID of the Invocation to duplicate */
    invocationId: Scalars["String"]["input"];
};

/** Input for duplicating a Procedure */
export type DuplicateProcedureInput = {
    /** ID of the Procedure to duplicate */
    procedureId: Scalars["ID"]["input"];
};

/** Response for duplicating a Procedure */
export type DuplicateProcedureResponse = MutationResponse & {
    __typename?: "DuplicateProcedureResponse";
    /** The culture affected */
    culture: Maybe<Culture>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** The new procedure that was created */
    newProcedure: Maybe<Procedure>;
    /** Result of the edit attempt */
    ok: Scalars["Boolean"]["output"];
};

/** Payload required to duplicate an Step */
export type DuplicateStepInput = {
    /** The ID of the Device containing the Step */
    deviceId: Scalars["String"]["input"];
    /** The ID of the Step to duplicate */
    stepId: Scalars["String"]["input"];
};

/** Response payload for duplicating a Step */
export type DuplicateStepMutationResponse = MutationResponse & {
    __typename?: "DuplicateStepMutationResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if Step mutation was successful */
    ok: Scalars["Boolean"]["output"];
};

/** Input payload for editing a Bottle */
export type EditBottleInput = {
    /** Unique ID of the bottle */
    bottleId: Scalars["String"]["input"];
    /** Updated current volume of the liquid in the bottle in millilitres */
    liquidVolume?: InputMaybe<Scalars["Float"]["input"]>;
    /** Updated total volume of the bottle in millilitres */
    totalVolume?: InputMaybe<Scalars["Float"]["input"]>;
};

/** Input payload for editing a Flask */
export type EditFlaskInput = {
    /** Unique ID of the flask */
    flaskId: Scalars["String"]["input"];
    /** Updated current volume of the liquid in the flask in millilitres */
    liquidVolume?: InputMaybe<Scalars["Float"]["input"]>;
    /** Updated total volume of the flask in millilitres */
    totalVolume?: InputMaybe<Scalars["Float"]["input"]>;
};

/** Payload required to edit Invocation parameters */
export type EditInvocationParametersInput = {
    /** The ID of the Device */
    deviceId: Scalars["String"]["input"];
    /** The ID of the Invocation to edit */
    invocationId: Scalars["String"]["input"];
    /** The updated parameters (it does not need to be a full parameters object) */
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** Details of a new step to be created */
export type EditProcedureStepInput = {
    /** ID of the Step */
    existingStepId?: InputMaybe<Scalars["ID"]["input"]>;
    /** New name of the Step */
    newStepName?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input for editing the steps of a Procedure */
export type EditProcedureStepsInput = {
    /** Ordered list step details. The step details are either references to existing steps, or details to create a new step with no invocations. */
    orderedSteps: Array<EditProcedureStepInput>;
    /** ID of the Procedure to edit */
    procedureId: Scalars["ID"]["input"];
};

/** Response for editing the steps of a Procedure */
export type EditProcedureStepsResponse = MutationResponse & {
    __typename?: "EditProcedureStepsResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of the edit attempt */
    ok: Scalars["Boolean"]["output"];
    /** The re-ordered steps on the procedure */
    procedureSteps: Maybe<Array<Step>>;
};

/** The notification preferences for a specific event */
export type EventNotificationPreference = GenericNotificationConfigInterface & {
    __typename?: "EventNotificationPreference";
    /** A switch for notifications for this event through email */
    email: Scalars["Boolean"]["output"];
    /** A switch for notifications for this event through all channels */
    enabled: Scalars["Boolean"]["output"];
    /** The event type */
    event: NotificationEvent;
    /** A switch for notifications for this event through the app */
    inApp: Scalars["Boolean"]["output"];
    /** A switch for notifications for this event through slack */
    slack: Scalars["Boolean"]["output"];
    /** A switch for notifications for this event through sms */
    sms: Scalars["Boolean"]["output"];
};

/** A Flask on a Device */
export type Flask = {
    __typename?: "Flask";
    /**
     * Container reference of the bottle, as provided in the protocol designer.
     * Unique within a culture.
     */
    containerReference: Maybe<Scalars["String"]["output"]>;
    /** When the flask was created on the device side */
    createdAt: Maybe<Scalars["String"]["output"]>;
    /**
     * Unique ID of the culture the flask belongs to
     * @deprecated Use cultures.nodes.id instead
     */
    cultureId: Maybe<Scalars["String"]["output"]>;
    /** Cultures the bottle belongs to */
    cultures: Maybe<CultureUnpaginatedConnection>;
    /** The number of the flask in the device */
    flaskNumber: Maybe<Scalars["Int"]["output"]>;
    /**
     * Size of flask, measured commonly in surface area of bottom of flask in
     * millimitres squared.
     */
    growthAreaMm2: Maybe<Scalars["Int"]["output"]>;
    /** Unique ID of the flask (Primary Key) */
    id: Scalars["String"]["output"];
    /**
     * Whether or not the flask is able to be imaged, this is often related to the
     * stacked flask which cannot be imaged
     */
    imageable: Maybe<Scalars["Boolean"]["output"]>;
    /** Current volume of the liquid in the flask, in millilitres */
    liquidVolumeMl: Maybe<Scalars["Float"]["output"]>;
    /** Readable name of the flask */
    name: Maybe<Scalars["String"]["output"]>;
    /** Total volume of fluid that can be stored in the flask, in millilitres */
    totalVolumeMl: Maybe<Scalars["Float"]["output"]>;
    /**
     * The number of the tray that the flask is on in the device (optional). This
     * is necessary for devices that have multiple trays such as MCL (iDEM Triple)
     */
    trayNumber: Maybe<Scalars["Int"]["output"]>;
    /** When the flask was updated on the device side */
    updatedAt: Maybe<Scalars["String"]["output"]>;
};

/** Connection object for the `Flask` type */
export type FlaskConnection = Connection & {
    __typename?: "FlaskConnection";
    /**
     * Array of Flask nodes. If pagination arguments are provided to
     * `FlaskConnection`, this array will be modified.
     */
    nodes: Maybe<Array<Maybe<Flask>>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** Collection of images for a flask */
export type FlaskImages = {
    __typename?: "FlaskImages";
    /**
     * Reference to Flask being imaged.
     * NOTE: This does not reference the `Flask` schema type, but the legacy `ResultFlask` type.
     */
    flask: Maybe<ResultFlask>;
    /** Array of captured images of the flask */
    images: Maybe<Array<Maybe<ImageData>>>;
    /** Array of captured videos of the flask */
    videos: Maybe<Array<Maybe<ImageData>>>;
};

/**
 * Information about particular group of flasks with the same flask size and cell
 * suspension dose volume for seeding.
 */
export type FlaskSeedGroupData = {
    __typename?: "FlaskSeedGroupData";
    /** Volume of cell suspension that will be added to each flask in the group */
    doseMl: Scalars["Float"]["output"];
    /** The numbers of the flasks in the group */
    flaskNumbers: Array<Maybe<Scalars["Int"]["output"]>>;
    /** Size of the growth area of each flask in the group in cm^2 */
    flaskSizeCm2: Scalars["Int"]["output"];
};

/** Fleet */
export type Fleet = {
    __typename?: "Fleet";
    /** Unique ID of the fleet */
    id: Scalars["Int"]["output"];
    /** Name of the fleet */
    name: Scalars["String"]["output"];
};

/** Response payload for any generic MutationResponse */
export type GenericMutationResponse = MutationResponse & {
    __typename?: "GenericMutationResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if successful */
    ok: Scalars["Boolean"]["output"];
};

/** Generic config with simple enabled switch */
export type GenericNotificationConfig = GenericNotificationConfigInterface & {
    __typename?: "GenericNotificationConfig";
    /** Switch for enabling or disabling channel/event */
    enabled: Scalars["Boolean"]["output"];
};

/** Interface for config with simple enabled switch */
export type GenericNotificationConfigInterface = {
    /** Switch for enabling or disabling channel/event */
    enabled: Scalars["Boolean"]["output"];
};

/** Data related to a single image capture on a Device */
export type ImageData = {
    __typename?: "ImageData";
    /** Ratio of cells to empty space on the surface of the flask visible within the image */
    confluence: Maybe<Scalars["Float"]["output"]>;
    /** File format of the captured image */
    fileFormat: Maybe<Scalars["String"]["output"]>;
    /** Height in pixels of the captured image */
    height: Maybe<Scalars["Int"]["output"]>;
    /** UUID of the image */
    id: Scalars["ID"]["output"];
    /** Pre-signed URL which can be used to access the resource for a limited time */
    imageUrl: Maybe<Scalars["String"]["output"]>;
    /** Pre-signed URL which can be used to access the resource for a limited time */
    maskUrl: Maybe<Scalars["String"]["output"]>;
    /** The pixel scale is the conversion ratio between distance (i.e. metres) and a pixel in the captured image. The unit for this field is microns/pixel. */
    pixelScale: Maybe<Scalars["Float"]["output"]>;
    /** Position of the captured image */
    position: Maybe<Position>;
    /** Timestamp of when image was taken in ISO format */
    timestampISO: Maybe<Scalars["String"]["output"]>;
    /** Width in pixels of the captured image */
    width: Maybe<Scalars["Int"]["output"]>;
};

/** The smallest configurable and actionable task executed by a Device in the Culture Schedule. */
export type Invocation = {
    __typename?: "Invocation";
    /** Informational description for what will be performed */
    description: Maybe<Scalars["String"]["output"]>;
    /** The message reported to the user if there was an error at runtime */
    errorMessage: Maybe<Scalars["String"]["output"]>;
    /** The estimated runtime (in milliseconds) */
    estimatedDuration: Maybe<Scalars["Float"]["output"]>;
    /** Reports whether complete/failed/ignored */
    finished: Maybe<Scalars["Boolean"]["output"]>;
    /** Unique ID */
    id: Scalars["ID"]["output"];
    /** Identifier for the Operation that was called e.g. PumpSolution */
    operationId: Maybe<Scalars["String"]["output"]>;
    /** The output (returned) data after finishing */
    output: Maybe<Scalars["JSON"]["output"]>;
    /** The input parameters */
    parameters: Maybe<Scalars["JSON"]["output"]>;
    /** Controls which can be performed */
    permittedControls: Maybe<Array<InvocationControl>>;
    /** Current state e.g. RUNNING */
    state: Maybe<InvocationState>;
    /** ID of the Step (parent) */
    stepId: Maybe<Scalars["ID"]["output"]>;
    /** ISO formatted timestamp of when the Invocation stopped running (e.g. completed or failed) */
    timeFinished: Maybe<Scalars["String"]["output"]>;
    /** ISO formatted timestamp of intended start time */
    timePlanned: Maybe<Scalars["String"]["output"]>;
    /** ISO formatted timestamp of when the Invocation began running */
    timeStarted: Maybe<Scalars["String"]["output"]>;
};

/** Connection object for the `Invocation` type */
export type InvocationConnection = Connection & {
    __typename?: "InvocationConnection";
    /**
     * Array of Invocation objects. If pagination arguments are provided to
     * `InvocationConnection`, this array will reflect the requested arguments. By
     * default the array is in execution (running) order on the Device.
     */
    nodes: Maybe<Array<Invocation>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** All controls of an Invocation */
export enum InvocationControl {
    /**
     * Stop the current Invocation in it's tracks and move onto the next Invocation.
     * The Culture will continue running.
     * This only be used on running procedures.
     */
    Cancel = "CANCEL",
    /** Remove a planned Invocation so that the schedule skips it when executing */
    Remove = "REMOVE",
    /** Restore a REMOVED Invocation so that it will no longer be skipped by the schedule */
    Restore = "RESTORE",
}

/** Response payload for mutated Invocation */
export type InvocationMutationResponse = MutationResponse & {
    __typename?: "InvocationMutationResponse";
    /** Updated Invocation data */
    invocation: Maybe<Invocation>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if mutation was successful */
    ok: Scalars["Boolean"]["output"];
};

/** Possible states of an Invocation */
export enum InvocationState {
    /**
     * This invocation was stopped in it's tracks during execution and did not and
     * will not fully complete
     */
    Cancelled = "CANCELLED",
    /**
     * The device is the the process of stopping this invocation in it's tracks
     * then this invocation will transition to the CANCELLED state
     */
    Cancelling = "CANCELLING",
    /** Finished running successfully */
    Complete = "COMPLETE",
    /** Finished unsuccessfully with an error */
    Failed = "FAILED",
    /** Was REMOVED and the schedule has now passed point of execution, and so did not run */
    Ignored = "IGNORED",
    /** Was RUNNING but has been temporarily paused */
    Paused = "PAUSED",
    /** Not run yet but will at some future point in time */
    Planned = "PLANNED",
    /** Was PLANNED but is now marked to be skipped when the schedule is supposed to execute */
    Removed = "REMOVED",
    /** Currently in progress */
    Running = "RUNNING",
    /** State could not be determined or recognised */
    Unknown = "UNKNOWN",
    /** About to be run since the Step it belongs to has started */
    Waiting = "WAITING",
}

/** Parameters available for marking a Device as migrated */
export type MigrateDeviceInput = {
    /** ID of the Device to migrate */
    deviceId: Scalars["String"]["input"];
};

/** Response payload for migrate Device mutation */
export type MigrateDeviceResponse = MutationResponse & {
    __typename?: "MigrateDeviceResponse";
    /** Device migrated if successful */
    device: Maybe<Device>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Status of migrate Device attempt */
    ok: Scalars["Boolean"]["output"];
};

/** Standard response from any DeviceUser mutation */
export type ModifyDeviceUserResponse = MutationResponse & {
    __typename?: "ModifyDeviceUserResponse";
    /** The modified Device */
    device: Maybe<Device>;
    /** Edge may be returned if it exists after the mutation */
    edge: Maybe<DeviceUserEdge>;
    /** Human-readable response on the status of the mutation */
    message: Scalars["String"]["output"];
    /** Boolean status of whether mutation was successful */
    ok: Scalars["Boolean"]["output"];
};

/** Input for moving a Device to a new Fleet */
export type MoveDeviceInput = {
    /** Id of the Device to move */
    deviceId: Scalars["String"]["input"];
    /** Id of the Fleet to move the device to */
    targetFleetId: Scalars["Int"]["input"];
};

/** Response payload for move Device mutation */
export type MoveDeviceResponse = MutationResponse & {
    __typename?: "MoveDeviceResponse";
    /** New fleet ID of Device if successful */
    fleetId: Maybe<Scalars["Int"]["output"]>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Status of move Device attempt */
    ok: Scalars["Boolean"]["output"];
};

/** All GraphQL Mutations */
export type Mutation = {
    __typename?: "Mutation";
    /**
     * Will add a new Device-User relationship if it doesn't exist. If one already
     * exists, no action will be taken.
     */
    addDeviceUser: ModifyDeviceUserResponse;
    /** Add a new operation to the end of a step */
    addOperationToStep: AddOperationToStepMutationResponse;
    /** Mutation to cancel all Device Jobs on a device */
    cancelDeviceJob: GenericMutationResponse;
    /** Control a Culture's state */
    controlCulture: ControlCultureResponse;
    /** Control an invocations state */
    controlInvocation: InvocationMutationResponse;
    /** Control a Procedures state */
    controlProcedure: ControlProcedureResponse;
    /** Control a Step's state */
    controlStep: StepMutationResponse;
    /** Used to create a culture and sync the culture data with the device */
    createAndSyncCulture: CreateCultureResponse;
    /** Create a new API key */
    createApiKey: CreateApiKeyResponse;
    /**
     * Create a new Device. Idempotent request, if a Device already exists,
     * the mutation will refuse to create a Device.
     */
    createDevice: CreateDeviceResponse;
    /** Create an Invocation in the Schedule to run an Operation */
    createInvocation: GenericMutationResponse;
    /** Used to upload a protocol JSON object to the server (but not create a culture) */
    createProtocol: CreateProtocolResponse;
    /** Used to Delay a Step and any subsequent Steps. */
    delayStep: StepConnectionMutationResponse;
    /** Duplicate an Invocation in the schedule */
    duplicateInvocation: GenericMutationResponse;
    /** Duplicates a procedure and all the steps/invocations within it */
    duplicateProcedure: DuplicateProcedureResponse;
    /** Duplicate a Step in the schedule */
    duplicateStep: DuplicateStepMutationResponse;
    /** Mutation to edit the state of a bottle. */
    editBottle: GenericMutationResponse;
    /** Mutation to edit the state of a flask. */
    editFlask: GenericMutationResponse;
    /** Used to edit the parameters of a invocation in the schedule. */
    editInvocationParameters: InvocationMutationResponse;
    /** Allows the creation of new empty steps and the reordering of the steps within a procedure */
    editProcedureSteps: EditProcedureStepsResponse;
    /** Mark a Device as migrated */
    migrateDevice: MigrateDeviceResponse;
    /** Move a Device to a new Fleet */
    moveDevice: MoveDeviceResponse;
    /** Pin a device to a Balena release */
    pinDeviceToBalenaRelease: GenericMutationResponse;
    /** Register details of a new user and create an account */
    registerUser: RegisterUserResponse;
    /**
     * Removes a User's access to a Device. Takes object as argument where either
     * (1) the edge ID can be provided or
     * (2) the userId and deviceId can be provided
     */
    removeDeviceUser: ModifyDeviceUserResponse;
    /** Change the friendly name to describe the API key */
    renameApiKey: RenameRevokeResponse;
    /** Used to replace a wet test with a new culture */
    replaceWetTestWithCulture: CreateCultureResponse;
    /** Used to Reschedule a Procedure and optionally subsequent Procedures. */
    rescheduleProcedure: RescheduleProcedureResponse;
    /**
     * Deletes all the sessions for the current user. This causes the user to be
     * signed out on all devices.
     */
    revokeAllSessions: UpdateUserResponse;
    /** Revoke an API key from a Users account */
    revokeApiKey: RenameRevokeResponse;
    /** Set the Balena Override Lock */
    setBalenaOverrideLock: GenericMutationResponse;
    /** Mutation to start one or more Device Jobs on a device */
    startDeviceJob: StartDeviceJobResponse;
    /** Trigger a culture simulation for upcoming invocations */
    triggerCultureSimulationForUpcomingInvocations: GenericMutationResponse;
    /**
     * Sends a request to the device to bulk upload it's current state to the
     * server. This is useful for when the device and server have fallen out of
     * sync and you want the current state of the device to be sent to the server.
     */
    triggerDeviceToServerSync: GenericMutationResponse;
    /** Force the server to refresh the upcoming procedures on a device */
    triggerServerToDeviceSync: GenericMutationResponse;
    /** Unpin a device to a Balena release */
    unpinDeviceFromBalenaRelease: GenericMutationResponse;
    /** Update metadata of a Device */
    updateDevice: UpdateDeviceResponse;
    /** Update Device maintenance status */
    updateDeviceMaintenance: UpdateDeviceMaintenanceResponse;
    /** Changes the user preference for notifications of a specific device */
    updateDeviceNotificationPreference: ModifyDeviceUserResponse;
    /** Update Device Org */
    updateDeviceOrg: UpdateDeviceOrgResponse;
    /** Modifies an existing Device-User relationship */
    updateDeviceUser: ModifyDeviceUserResponse;
    /** Updates the event notification preferences for the current User (as authenticated). */
    updateEventNotificationsPreference: UpdateUserResponse;
    /** Updates the global notifications preferences for the current User (as authenticated). */
    updateGlobalNotificationsPreferences: UpdateUserResponse;
    /** Mutation to update the state of a notification */
    updateNotification: UpdateNotificationResponse;
    /** Mutation to update the state of multiple notifications */
    updateNotifications: UpdateNotificationsResponse;
    /** Update the runtime reference of a step */
    updateStepRuntimeReference: StepMutationResponse;
    /** Update the details of the logged in User */
    updateUser: UpdateUserResponse;
    /**
     * Used to change the orgId on the current user's session. All other parts of
     * the session such as expiryDate remain the same
     */
    updateUserSession: UpdateUserResponse;
    /** Update the UI preferences of the logged in User */
    updateUserUiPreferences: UpdateUserUiPreferencesResponse;
    /** Used to upload a protocol to the server */
    uploadPartialProtocol: PartialProtocolUploadMutationResponse;
};

/** All GraphQL Mutations */
export type MutationAddDeviceUserArgs = {
    input: AddDeviceUserInput;
};

/** All GraphQL Mutations */
export type MutationAddOperationToStepArgs = {
    input: AddOperationToStepInput;
};

/** All GraphQL Mutations */
export type MutationCancelDeviceJobArgs = {
    input: CancelDeviceJob;
};

/** All GraphQL Mutations */
export type MutationControlCultureArgs = {
    input: ControlCultureInput;
};

/** All GraphQL Mutations */
export type MutationControlInvocationArgs = {
    input: ControlInvocationInput;
};

/** All GraphQL Mutations */
export type MutationControlProcedureArgs = {
    input: ControlProcedureInput;
};

/** All GraphQL Mutations */
export type MutationControlStepArgs = {
    input: ControlStepInput;
};

/** All GraphQL Mutations */
export type MutationCreateAndSyncCultureArgs = {
    input: CreateAndSyncCultureInput;
};

/** All GraphQL Mutations */
export type MutationCreateApiKeyArgs = {
    createApiKeyInput: CreateApiKeyInput;
};

/** All GraphQL Mutations */
export type MutationCreateDeviceArgs = {
    createDeviceInput: CreateDeviceInput;
};

/** All GraphQL Mutations */
export type MutationCreateInvocationArgs = {
    input: CreateInvocationInput;
};

/** All GraphQL Mutations */
export type MutationCreateProtocolArgs = {
    input: CreateProtocolInput;
};

/** All GraphQL Mutations */
export type MutationDelayStepArgs = {
    input: DelayStepInput;
};

/** All GraphQL Mutations */
export type MutationDuplicateInvocationArgs = {
    input: DuplicateInvocationInput;
};

/** All GraphQL Mutations */
export type MutationDuplicateProcedureArgs = {
    input: DuplicateProcedureInput;
};

/** All GraphQL Mutations */
export type MutationDuplicateStepArgs = {
    input: DuplicateStepInput;
};

/** All GraphQL Mutations */
export type MutationEditBottleArgs = {
    input: EditBottleInput;
};

/** All GraphQL Mutations */
export type MutationEditFlaskArgs = {
    input: EditFlaskInput;
};

/** All GraphQL Mutations */
export type MutationEditInvocationParametersArgs = {
    input: EditInvocationParametersInput;
};

/** All GraphQL Mutations */
export type MutationEditProcedureStepsArgs = {
    input: EditProcedureStepsInput;
};

/** All GraphQL Mutations */
export type MutationMigrateDeviceArgs = {
    migrateDeviceInput: MigrateDeviceInput;
};

/** All GraphQL Mutations */
export type MutationMoveDeviceArgs = {
    input: MoveDeviceInput;
};

/** All GraphQL Mutations */
export type MutationPinDeviceToBalenaReleaseArgs = {
    input: PinDeviceToBalenaReleaseInput;
};

/** All GraphQL Mutations */
export type MutationRegisterUserArgs = {
    registerUserInput: RegisterUserInput;
};

/** All GraphQL Mutations */
export type MutationRemoveDeviceUserArgs = {
    input: RemoveDeviceUserInput;
};

/** All GraphQL Mutations */
export type MutationRenameApiKeyArgs = {
    keyId: Scalars["String"]["input"];
    newName: Scalars["String"]["input"];
};

/** All GraphQL Mutations */
export type MutationReplaceWetTestWithCultureArgs = {
    input: ReplaceWetTestWithCultureInput;
};

/** All GraphQL Mutations */
export type MutationRescheduleProcedureArgs = {
    input: RescheduleProcedureInput;
};

/** All GraphQL Mutations */
export type MutationRevokeApiKeyArgs = {
    keyId: Scalars["String"]["input"];
};

/** All GraphQL Mutations */
export type MutationSetBalenaOverrideLockArgs = {
    input: SetBalenaOverrideLockInput;
};

/** All GraphQL Mutations */
export type MutationStartDeviceJobArgs = {
    input: StartDeviceJobs;
};

/** All GraphQL Mutations */
export type MutationTriggerCultureSimulationForUpcomingInvocationsArgs = {
    input: TriggerCultureSimulationInput;
};

/** All GraphQL Mutations */
export type MutationTriggerDeviceToServerSyncArgs = {
    input: TriggerDeviceToServerSyncInput;
};

/** All GraphQL Mutations */
export type MutationTriggerServerToDeviceSyncArgs = {
    input: TriggerServerToDeviceSyncInput;
};

/** All GraphQL Mutations */
export type MutationUnpinDeviceFromBalenaReleaseArgs = {
    input: UnpinDeviceFromBalenaReleaseInput;
};

/** All GraphQL Mutations */
export type MutationUpdateDeviceArgs = {
    updateDeviceInput: UpdateDeviceInput;
};

/** All GraphQL Mutations */
export type MutationUpdateDeviceMaintenanceArgs = {
    updateDeviceMaintenanceInput: UpdateDeviceMaintenanceInput;
};

/** All GraphQL Mutations */
export type MutationUpdateDeviceNotificationPreferenceArgs = {
    input: UpdateDeviceNotificationPreferenceInput;
};

/** All GraphQL Mutations */
export type MutationUpdateDeviceOrgArgs = {
    updateDeviceOrgInput: UpdateDeviceOrgInput;
};

/** All GraphQL Mutations */
export type MutationUpdateDeviceUserArgs = {
    input: UpdateDeviceUserInput;
};

/** All GraphQL Mutations */
export type MutationUpdateEventNotificationsPreferenceArgs = {
    input: UpdateEventNotificationsPreferenceInput;
};

/** All GraphQL Mutations */
export type MutationUpdateGlobalNotificationsPreferencesArgs = {
    input: UpdateGlobalNotificationsPreferencesInput;
};

/** All GraphQL Mutations */
export type MutationUpdateNotificationArgs = {
    input: UpdateNotificationInput;
};

/** All GraphQL Mutations */
export type MutationUpdateNotificationsArgs = {
    input: UpdateNotificationsInput;
};

/** All GraphQL Mutations */
export type MutationUpdateStepRuntimeReferenceArgs = {
    input: UpdateStepRuntimeReferenceInput;
};

/** All GraphQL Mutations */
export type MutationUpdateUserArgs = {
    updateUserInput: UpdateUserInput;
};

/** All GraphQL Mutations */
export type MutationUpdateUserSessionArgs = {
    orgId: Scalars["String"]["input"];
};

/** All GraphQL Mutations */
export type MutationUpdateUserUiPreferencesArgs = {
    input: UpdateUserUiPreferencesInput;
};

/** All GraphQL Mutations */
export type MutationUploadPartialProtocolArgs = {
    input: PartialProtocolUploadInput;
};

/** Defines minimum required fields for any response to a mutation request */
export type MutationResponse = {
    /** Message */
    message: Scalars["String"]["output"];
    /** State */
    ok: Scalars["Boolean"]["output"];
};

/** Types of network connection */
export enum NetworkConnectionType {
    /** Wired connection (e.g. ethernet) */
    Wired = "WIRED",
    /** Wireless connection (i.e. Wi-Fi) */
    Wireless = "WIRELESS",
}

/** A user notification */
export type Notification = {
    __typename?: "Notification";
    /** Whether the notification has been archived */
    archived: Scalars["Boolean"]["output"];
    /** Date notification was archived */
    archivedDate: Maybe<Scalars["String"]["output"]>;
    /** Date notification was created */
    createdDate: Scalars["String"]["output"];
    /** Event that triggered the notification */
    event: NotificationEvent;
    /** UUID of the notification */
    id: Scalars["String"]["output"];
    /** Whether the notification has been read or not */
    read: Scalars["Boolean"]["output"];
    /** Date notification was read */
    readDate: Maybe<Scalars["String"]["output"]>;
    /** Data payload regarding the subject of notification (e.g. Result data for a Result creation notification) */
    subject: Maybe<NotificationSubject>;
    /** User that received the notification */
    user: Maybe<User>;
};

/**
 * An edge representing a notification that belongs to a user for use with arguments
 * for a Notification Connection object
 */
export type NotificationEdge = {
    __typename?: "NotificationEdge";
    /** Cursor for notifications pagination */
    cursor: Maybe<Scalars["String"]["output"]>;
    /** Notification related to edge */
    node: Maybe<Notification>;
};

/** The event which has triggered the need for notification in the first place */
export enum NotificationEvent {
    /** Flask image result created */
    FlaskImagesResultCreated = "FLASK_IMAGES_RESULT_CREATED",
}

/** The preferences for notifications belonging to a user */
export type NotificationPreferences = {
    __typename?: "NotificationPreferences";
    /** Channel configuration for email notifications */
    email: GenericNotificationConfig;
    /** A list of more granular preference notifications for specific events */
    events: Maybe<Array<EventNotificationPreference>>;
    /** A global switch for notifications through all channels */
    global: Scalars["Boolean"]["output"];
    /** Channel configuration for notifications within the app UI */
    inApp: GenericNotificationConfig;
    /** Channel configuration for Slack notifications */
    slack: SlackChannelConfig;
    /** Channel configuration for SMS notifications */
    sms: GenericNotificationConfig;
};

/** Union of possible subject data associated to a Notification */
export type NotificationSubject = Result;

/** The connection representing a user's notifications */
export type NotificationsConnection = Connection & {
    __typename?: "NotificationsConnection";
    /** Relationships to notification objects */
    edges: Array<NotificationEdge>;
    /** Related notification objects */
    nodes: Array<Notification>;
    /** Information related to currently queried page */
    pageInfo: PageInfo;
    /** Total count of related notifications */
    totalCount: Scalars["Int"]["output"];
};

/** Filtering options for retrieving notifications */
export type NotificationsFilter = {
    /** Filter by archived */
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Filter by device UUID */
    deviceId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by what event triggered the notification */
    events?: InputMaybe<Array<NotificationEvent>>;
    /** Filter by read */
    read?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Input options for a notifications query */
export type NotificationsInput = {
    /** Cursor to paginate from when using `first` parameter */
    after?: InputMaybe<Scalars["String"]["input"]>;
    /** Cursor to paginate from when using `last` parameter */
    before?: InputMaybe<Scalars["String"]["input"]>;
    /** Rules to filter returned data */
    filterBy?: InputMaybe<NotificationsFilter>;
    /** Number of notifications to return (ordered by most recent) */
    first?: InputMaybe<Scalars["Int"]["input"]>;
    /** Number of notification to return (from end of data array) */
    last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A parameter type to characterise a valid number */
export type NumberParameter = BaseParameter & {
    __typename?: "NumberParameter";
    /** The default number for the parameter */
    default: Maybe<Scalars["Float"]["output"]>;
    /** A brief description of the parameter */
    description: Maybe<Scalars["String"]["output"]>;
    /** Maximum value for the parameter */
    max: Maybe<Scalars["Float"]["output"]>;
    /** Minimum value for the parameter */
    min: Maybe<Scalars["Float"]["output"]>;
    /** A readable name for the parameter */
    name: Maybe<Scalars["String"]["output"]>;
    /** A unique ID for the parameter */
    parameterId: Maybe<Scalars["String"]["output"]>;
    /** Whether this parameter required to run the device operation */
    required: Maybe<Scalars["Boolean"]["output"]>;
    /** Step size for the parameter */
    step: Maybe<Scalars["Float"]["output"]>;
    /** Units to be used when referencing this parameter */
    units: Maybe<Scalars["String"]["output"]>;
};

/** An Operation is the executable task a Device runs based on a scheduled Invocation */
export type Operation = {
    __typename?: "Operation";
    /** A brief description */
    description: Maybe<Scalars["String"]["output"]>;
    /** A readable name */
    name: Maybe<Scalars["String"]["output"]>;
    /** Unique ID */
    operationId: Maybe<Scalars["String"]["output"]>;
    /** The schema of parameters needed in order to run this Operation */
    parameters: Maybe<Array<Parameter>>;
};

/** Org */
export type Org = {
    __typename?: "Org";
    /** Unique Identifier for the org */
    id: Scalars["ID"]["output"];
    /** Name of the org */
    name: Maybe<Scalars["String"]["output"]>;
};

/** Organisation */
export type Organisation = {
    __typename?: "Organisation";
    /** Unique Identifier for the organisation */
    id: Scalars["ID"]["output"];
    /** Name of the organisation */
    name: Maybe<Scalars["String"]["output"]>;
};

/** Pagination object metadata */
export type PageInfo = {
    __typename?: "PageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars["String"]["output"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"]["output"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"]["output"];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars["String"]["output"]>;
};

/** A union of all possible parameter types */
export type Parameter =
    | BooleanParameter
    | NumberParameter
    | StringArrayParameter
    | StringParameter;

/**
 * Required payload for applying a partial protocol to a culture
 * TODO(MYT-4926): Make start date & timezone required
 */
export type PartialProtocolUploadInput = {
    /** Current to replace the new future described in the applied protocol field */
    cultureId: Scalars["String"]["input"];
    /** A protocol describing the new intended future of the culture */
    protocol: Scalars["JSON"]["input"];
    /** Date of the first day of the original culture in YYYY-MM-DD format */
    startDate: Scalars["String"]["input"];
    /**
     * Timezone that the culture is operating in.
     * Formatted as a valid TZ identifier: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     */
    timezone: Scalars["String"]["input"];
};

/** Response payload for partial protocol upload */
export type PartialProtocolUploadMutationResponse = MutationResponse & {
    __typename?: "PartialProtocolUploadMutationResponse";
    /** Updated culture */
    culture: Maybe<Culture>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if successful */
    ok: Scalars["Boolean"]["output"];
};

/** Input for moving a Device to a new Fleet */
export type PinDeviceToBalenaReleaseInput = {
    /** Id of the Device to move */
    deviceId: Scalars["String"]["input"];
    /** Id of the Fleet */
    targetReleaseId: Scalars["String"]["input"];
};

/**
 * The position of a subject relating to a physical location on the Device. This is
 * either denoted by the absolute coordinates (x, y, z) based off the datum, and/or
 * the relative position to some other known item on the Device.
 */
export type Position = {
    __typename?: "Position";
    /** X,Y,Z coordinates relative to the Device itself. */
    absolutePosition: Maybe<Xyz>;
    /**
     * A special RelativePosition data object to describe the position of the
     * subject relative to some other item on the Device.
     */
    relativePosition: Maybe<RelativePosition>;
};

/** Type for the highest abstraction of a task that will occur. Encapsulates a number of Steps (i.e. operations that the device will perform) */
export type Procedure = {
    __typename?: "Procedure";
    /** Reports whether this Procedure requires confirmation to start running */
    confirmationRequired: Maybe<Scalars["Boolean"]["output"]>;
    /** Text associated with Procedure confirmation */
    confirmationText: Maybe<Scalars["String"]["output"]>;
    /** Reports whether the Procedure has been confirmed and may start */
    confirmed: Maybe<Scalars["Boolean"]["output"]>;
    /** The User who last confirmed the Procedure. If the Procedure is subsequently unconfirmed, this field will be null */
    confirmedBy: Maybe<User>;
    /** Estimated runtime duration of the Procedure in milliseconds */
    estimatedDuration: Maybe<Scalars["Float"]["output"]>;
    /** Unique ID of the Procedure */
    id: Scalars["ID"]["output"];
    /**
     * ISO formatted timestamp of the last time the device was sent the Procedure.
     * If this is null the device has never been sent the procedure yet.
     */
    lastSentToDeviceAt: Maybe<Scalars["String"]["output"]>;
    /** Friendly readable name of the Procedure */
    name: Maybe<Scalars["String"]["output"]>;
    /** Current state of the Procedure i.e. running */
    state: Maybe<ProcedureState>;
    /** List of Steps in the order they will be executed */
    steps: Maybe<StepConnection>;
    /** Aggregate information about the steps in this procedure */
    stepsSummary: Maybe<ProcedureStepsSummary>;
    /** ISO formatted timestamp of when the Procedure stopped running (e.g. completed or failed) */
    timeFinished: Maybe<Scalars["String"]["output"]>;
    /** ISO formatted timestamp of intended start time of the Procedure */
    timePlanned: Maybe<Scalars["String"]["output"]>;
    /** ISO formatted timestamp of when the Procedure began running */
    timeStarted: Maybe<Scalars["String"]["output"]>;
    /** Reports whether the Procedure is currently waiting for confirmation to start */
    waitingForConfirmation: Maybe<Scalars["Boolean"]["output"]>;
};

/** Connection object for the `Procedure` type */
export type ProcedureConnection = Connection & {
    __typename?: "ProcedureConnection";
    /**
     * Array of Procedure nodes. If pagination arguments are provided to
     * `ProcedureConnection`, this array will be modified.
     * By default the array is in order of the Procedures being run on the Device.
     */
    nodes: Maybe<Array<Maybe<Procedure>>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** All controls of an Procedure */
export enum ProcedureControl {
    /**
     * Stop the current Invocation in it's tracks and move onto the next Procedure
     * without completing any outstanding Invocations. The Culture will continue
     * running.
     * This only be used on running procedures.
     */
    Cancel = "CANCEL",
    /** Mark a Procedure as confirmed by user, some procedures require this to be done before they can be executed. */
    Confirm = "CONFIRM",
    /**
     * Pause a Procedure that is currently running. If a Step is currently
     * running, the Procedure will transition to the PAUSING state whilst that
     * Step completes, then the Procedure will transition to the PAUSED state.
     * When the culture RESUME action is sent the Procedure will continue from
     * the next Step.
     * You can also pause at the Culture and Step levels too, they might lead the the
     * device doing more or less work before it gets to the PAUSED state.
     */
    Pause = "PAUSE",
    /** Remove a planned Procedure to transition its state to removed (the schedule will skip it rather than running it) */
    Remove = "REMOVE",
    /** Restore an removed Procedure to transition its state to planned or waiting */
    Restore = "RESTORE",
    /** Mark a Procedure as unconfirmed by user. */
    Unconfirm = "UNCONFIRM",
}

/** Possible states for a Procedure */
export enum ProcedureState {
    /**
     * The Procedure was stopped in it's tracks during execution and did not and
     * will not fully complete
     */
    Cancelled = "CANCELLED",
    /**
     * The device is the the process of stopping this Procedure in it's tracks
     * then this Procedure will transition to the CANCELLED state
     */
    Cancelling = "CANCELLING",
    /** The Procedure finished running successfully */
    Complete = "COMPLETE",
    /** This Procedure failed to complete successfully */
    Failed = "FAILED",
    /** The Procedure was skipped when it was due to run and the schedule has now moved past it */
    Ignored = "IGNORED",
    /** The Procedure is currently paused and will continue again when prompted to */
    Paused = "PAUSED",
    /** The Procedure will finish it's current step then transition to the PAUSED state */
    Pausing = "PAUSING",
    /** The Procedure is upcoming in the schedule and will be run */
    Planned = "PLANNED",
    /** The Procedure will be skipped when it is due to run */
    Removed = "REMOVED",
    /** The Procedure is currently in progress */
    Running = "RUNNING",
    /**
     * The Procedure is not currently making progress towards finishing
     * @deprecated Use `FAILED` instead
     */
    Stalled = "STALLED",
    /** The Procedure state is currently unknown */
    Unknown = "UNKNOWN",
}

/** Aggregate information about the steps for a given procedure */
export type ProcedureStepsSummary = {
    __typename?: "ProcedureStepsSummary";
    /**
     * Whether the procedure has any steps that are in a failed state or have child
     * invocations in a failed state.
     */
    hasStepsWithErrors: Maybe<Scalars["Boolean"]["output"]>;
    /** The id of the Procedure this summary is for */
    id: Scalars["ID"]["output"];
    /** The number of steps that have outstanding confirmations for this procedure */
    numberOfStepsWithOutstandingConfirmation: Maybe<Scalars["Int"]["output"]>;
};

/** Information about a protocol JSON */
export type Protocol = {
    __typename?: "Protocol";
    /** Unique ID of the protocol */
    id: Scalars["ID"]["output"];
    /** Time in minutes for the coating step, or null if there is no coating step */
    initialCoatingTimeMinutes: Maybe<Scalars["Int"]["output"]>;
    /**
     * The metadata associated with the first seed procedure in the protocol, or
     * null if there is no seed procedure
     */
    initialSeedProcedureData: Maybe<SeedProcedureMetadata>;
    /** The name of the protocol */
    name: Maybe<Scalars["String"]["output"]>;
    /**
     * Basic information about all bottles from a protocol JSON start state.
     * Does not reference any Bottle entities.
     * Temporary type for compatibility with the basic upload flow Wet Setup page.
     */
    startingBottles: Maybe<Array<Maybe<ProtocolStartingBottleData>>>;
};

/**
 * Basic information about a bottle, from a protocol JSON start state.
 * Does not reference any Bottle entities.
 */
export type ProtocolStartingBottleData = {
    __typename?: "ProtocolStartingBottleData";
    /** Volume of liquid in the bottle at the start of the protocol */
    liquidVolume: Scalars["Float"]["output"];
    /** Name to display for the reagent in the bottle */
    reagentName: Scalars["String"]["output"];
    /** Total volume of the bottle */
    totalVolume: Scalars["Float"]["output"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type Query = {
    __typename?: "Query";
    /** Get bottle by ID */
    bottle: Maybe<Bottle>;
    /** Get culture by ID */
    culture: Maybe<Culture>;
    /** Cultures generated on the Device */
    cultures: Maybe<CultureConnection>;
    /** Return the device of provided ID given the user has access */
    device: Maybe<Device>;
    /** Get Device Job by ID */
    deviceJob: Maybe<DeviceJob>;
    /** Devices that the user has access to */
    devices: Maybe<Array<Maybe<Device>>>;
    /** Get flask by ID */
    flask: Maybe<Flask>;
    /** All fleets that exist */
    fleets: Maybe<Array<Maybe<Fleet>>>;
    /** Get invocation by ID */
    invocation: Maybe<Invocation>;
    /** Get a notification by ID */
    notification: Maybe<Notification>;
    /** Get notifications for the user */
    notifications: NotificationsConnection;
    /** Return org by ID */
    org: Maybe<Org>;
    /** Orgs that the user has access to */
    orgs: Maybe<Array<Maybe<Org>>>;
    /** Get procedure by ID */
    procedure: Maybe<Procedure>;
    /** Get protocol JSON information by ID */
    protocol: Maybe<Protocol>;
    /** Get data of a particular result */
    result: Maybe<Result>;
    /** Simple status check to see if server is running ok */
    status: ServerStatus;
    /** Get step by ID */
    step: Maybe<Step>;
    /** Return a User - by default the currently authorised user */
    user: Maybe<User>;
    /** Return a Users UI preferences */
    userUiPreferences: Maybe<UserUiPreferences>;
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryBottleArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryCultureArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryCulturesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    before?: InputMaybe<Scalars["String"]["input"]>;
    filterBy?: InputMaybe<CultureConnectionFilters>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryDeviceArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryDeviceJobArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryFlaskArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryInvocationArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryNotificationArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryNotificationsArgs = {
    input: NotificationsInput;
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryOrgArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryProcedureArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryProtocolArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryResultArgs = {
    resultInput: ResultInput;
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryStepArgs = {
    id: Scalars["String"]["input"];
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryUserArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** All GraphQL queries (the root GraphQL operation) */
export type QueryUserUiPreferencesArgs = {
    userId: Scalars["String"]["input"];
};

/** Payload required to create a new user account */
export type RegisterUserInput = {
    /** Primary email for the new account */
    email: Scalars["String"]["input"];
    /** First name of user */
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    /** Last name of user */
    lastName?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response payload to a register user request */
export type RegisterUserResponse = MutationResponse & {
    __typename?: "RegisterUserResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of login attempt */
    ok: Scalars["Boolean"]["output"];
    /** User object just created by mutation */
    user: Maybe<User>;
};

/**
 * Defines the position of a subject relative to some item on the Device. The item
 * on the Device will have its own absolute position. Commonly used, for example,
 * to provide a relative position within a flask.
 */
export type RelativePosition = {
    __typename?: "RelativePosition";
    /** ID of the item on the Device to measure relative to */
    itemId: Maybe<Scalars["String"]["output"]>;
    /** Friendly name to describe the position of the subject relative to the item */
    positionName: Maybe<Scalars["String"]["output"]>;
    /** Coordinates of subject with item (refer to itemId) as the origin */
    relativeCoords: Maybe<Xyz>;
};

/** Input required to remove a User from a Device. */
export type RemoveDeviceUserInput = {
    /** ID of the Device to remove. If not provided, edgeId must be provided. */
    deviceId?: InputMaybe<Scalars["String"]["input"]>;
    /** ID of the Device-User relationship to remove. If not provided, userId and deviceId must be provided. */
    edgeId?: InputMaybe<Scalars["String"]["input"]>;
    /** ID of the User to remove. If not provided, edgeId must be provided. */
    userId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response data for renaming or revoking (mutating) an API key */
export type RenameRevokeResponse = MutationResponse & {
    __typename?: "RenameRevokeResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of login attempt */
    ok: Scalars["Boolean"]["output"];
    /** Updated User object as a result of the API key change */
    user: Maybe<User>;
};

/**
 * Clear Wet Test culture, replace it with the primary culture it is intended for,
 * and sync the culture data with the device
 */
export type ReplaceWetTestWithCultureInput = {
    /** The ID of the Wet Test culture to replace */
    cultureId: Scalars["String"]["input"];
    /** Optional metadata for the culture */
    cultureMetadata?: InputMaybe<CultureMetadataInput>;
    /** The ID of the protocol to use for generating the replacement culture */
    protocolId: Scalars["String"]["input"];
    /** Skip validation of the device status checks */
    skipValidation?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Date of the first day of the culture in YYYY-MM-DD format */
    startDate: Scalars["String"]["input"];
    /**
     * Timezone that the culture is operating in.
     * Formatted as a valid TZ identifier: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     */
    timezone: Scalars["String"]["input"];
};

/** Payload required to delay a procedure and optionally cascade the delay to subsequent procedures */
export type RescheduleProcedureInput = {
    /** Whether subsequent procedures should be delayed by the same amount, to keep relative timings */
    cascade: Scalars["Boolean"]["input"];
    /** The ID of the Step to delay */
    procedureId: Scalars["String"]["input"];
    /** The desired new ISO timePlanned attribute of the procedure */
    targetISOTimestamp: Scalars["String"]["input"];
};

/** Response for rescheduling a Procedure */
export type RescheduleProcedureResponse = MutationResponse & {
    __typename?: "RescheduleProcedureResponse";
    /** The culture affected */
    culture: Maybe<Culture>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of the edit attempt */
    ok: Scalars["Boolean"]["output"];
};

/** The date result of some sort of completed function on a Device */
export type Result = {
    __typename?: "Result";
    /** The Culture that the Result is associated with */
    culture: Maybe<Culture>;
    /** The ID of the Culture the Result is associated with */
    cultureId: Scalars["String"]["output"];
    /** The type-specific data of the result payload */
    data: Maybe<ResultData>;
    /** The device that Result was created on */
    device: Device;
    /** Unique identifier for Result */
    id: Scalars["ID"]["output"];
    /** The timestamp the Result was created (in ISO UTC format) */
    timestampISO: Maybe<Scalars["String"]["output"]>;
    /** The data type of the Result that was created (e.g. imaging or pumping) */
    type: Maybe<ResultType>;
};

/** Connection object for associated Results */
export type ResultConnection = Connection & {
    __typename?: "ResultConnection";
    /**
     * Array of `ResultEdge` edges. If pagination arguments are provided to
     * `ResultConnection`, this array will be sliced. By default the array is
     * ordered by the `ResultEdge.Result.timestampISO` field.
     */
    edges: Maybe<Array<Maybe<ResultEdge>>>;
    /** Flask IDs that are referenced in the Results for this device */
    flaskIds: Maybe<Array<Scalars["String"]["output"]>>;
    /**
     * Array of Result nodes. If pagination arguments are provided to
     * `ResultConnection`, this array will be sliced.
     * By default the array is ordered by the `Result.timestampISO` field.
     */
    nodes: Maybe<Array<Maybe<Result>>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** Filter results options */
export type ResultConnectionFilters = {
    /** Filter results which were created on or after this timestamp (inclusively) */
    afterTimestamp?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter results which were created before this timestamp (exclusive) */
    beforeTimestamp?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter results based on the culture that they were produced in */
    cultureId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter results to flasks with IDs contained in this array */
    flaskIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/**
 * A Result can have multiple types resulting in a different payload.
 * This union represents the possible data shapes of the payload.
 */
export type ResultData = CountingChipImages | FlaskImages;

/**
 * Metadata on the relationship between the `ResultConnection` object and the
 * `Result` data itself.
 */
export type ResultEdge = {
    __typename?: "ResultEdge";
    /** Obscured cursor for Result pagination (cursors have no semantic meaning) */
    cursor: Maybe<Scalars["String"]["output"]>;
    /** Result object */
    node: Maybe<Result>;
};

/** Metadata for flasks on a consumable within a culture */
export type ResultFlask = {
    __typename?: "ResultFlask";
    /**
     * Container reference of the flask being images.
     * NOTE: Not a flask UUID.
     */
    flaskId: Maybe<Scalars["String"]["output"]>;
    /** Number of the flask in the culture */
    number: Maybe<Scalars["Int"]["output"]>;
    /**
     * Size of flask measured commonly in surface area of bottom of flask.
     * NOTE: Always null
     */
    size: Maybe<Scalars["Int"]["output"]>;
};

/** Required payload to query a result */
export type ResultInput = {
    /** UUID of Result */
    resultId: Scalars["ID"]["input"];
};

/** Type of Result */
export enum ResultType {
    /** Images taken of a counting chip */
    CountingChipImages = "COUNTING_CHIP_IMAGES",
    /** Images taken of a flask */
    FlaskImages = "FLASK_IMAGES",
}

/** The type for the schedule of all tasks the Device will perform throughout the Culture */
export type Schedule = {
    __typename?: "Schedule";
    /** The current running in the Schedule */
    currentProcedure: Maybe<Procedure>;
    /**
     * All Invocations within the schedule
     * @deprecated Query invocations from Step objects
     */
    invocations: Maybe<InvocationConnection>;
    /** Is the culture waiting on a user to confirm a procedure or a step */
    isWaitingOnConfirmation: Maybe<Scalars["Boolean"]["output"]>;
    /** The current running or next planned Invocation in the Schedule */
    nextInvocation: Maybe<Invocation>;
    /**
     * The next planned Procedure in the Schedule, if there is a procedure
     * currently running, this will be the one after that.
     */
    nextProcedure: Maybe<Procedure>;
    /** The current running or next planned Step in the Schedule */
    nextStep: Maybe<Step>;
    /**
     * The previous Invocation in the Schedule
     * The most recently completed invocation which belongs to the current running
     * step. Previous invocation will only be populated if the invocation within
     * the current step.
     */
    previousInvocation: Maybe<Invocation>;
    /**
     * The previous Procedure in the Schedule
     * This will be the most recently completed procedure.
     */
    previousProcedure: Maybe<Procedure>;
    /**
     * The previous Step in the Schedule
     * The most recently completed step which belongs to the current running
     * procedure. Previous step will only be populated if it was completed within
     * the current procedure.
     */
    previousStep: Maybe<Step>;
    /**
     * Procedures belonging to the culture schedule
     * This connection will include all culture procedures ordered by the planned
     * time.
     */
    procedures: Maybe<ProcedureConnection>;
    /** Steps belonging to the Schedule */
    steps: Maybe<StepConnection>;
};

/** Metadata about a seed procedure */
export type SeedProcedureMetadata = {
    __typename?: "SeedProcedureMetadata";
    /** Unique groups of flasks that will end up with the same cell seeding density */
    flaskSeedGroups: Array<FlaskSeedGroupData>;
    /**
     * Volume of cell suspension that is loaded into the seed vessel for the
     * seed procedure
     */
    seedVesselCellSuspensionMl: Scalars["Float"]["output"];
};

/** Healthcheck for server application instance */
export type ServerStatus = {
    __typename?: "ServerStatus";
    /** Optional additional message provided by server on its status */
    message: Maybe<Scalars["String"]["output"]>;
    /** Boolean determining whether server is ok to receive requests */
    ok: Scalars["Boolean"]["output"];
};

/** Input for setting the override lock on a Device */
export type SetBalenaOverrideLockInput = {
    /** Id of the Device to set the override lock on */
    deviceId: Scalars["String"]["input"];
    /** Whether the override lock should be enabled */
    enabled: Scalars["Boolean"]["input"];
};

/** An extended channel config for slack to present extra metadata */
export type SlackChannelConfig = GenericNotificationConfigInterface & {
    __typename?: "SlackChannelConfig";
    /** A switch for notifications over this channel */
    enabled: Scalars["Boolean"]["output"];
    /**
     * An indication of whether the user has set up their workspace with our
     * Slack bot
     */
    isPaired: Scalars["Boolean"]["output"];
    /** The id of the specific slack app the user has installed to their workspace */
    slackAppClientId: Scalars["String"]["output"];
    /** A comma separated string of scopes for our slack app */
    slackAppScope: Scalars["String"]["output"];
};

/** Response payload to starting a device job */
export type StartDeviceJobResponse = MutationResponse & {
    __typename?: "StartDeviceJobResponse";
    /** The device job(s) that were created */
    createdDeviceJobs: Maybe<Array<DeviceJob>>;
    /** Message explaining the state */
    message: Scalars["String"]["output"];
    /** State */
    ok: Scalars["Boolean"]["output"];
};

/** Start specified device job(s), either one or all */
export type StartDeviceJobs = {
    /** The ID of the Device to request a device job on */
    deviceId: Scalars["String"]["input"];
    /**
     * Which device job types to start - order will not necessarily be respected,
     * and duplicates will be ignored.
     */
    types: Array<DeviceJobType>;
};

/** Status check information */
export type StatusCheck = {
    __typename?: "StatusCheck";
    /** Name of the status check */
    name: Scalars["String"]["output"];
    /** Reason for the status check state */
    reason: Scalars["String"]["output"];
    /** State of the status check */
    state: StatusCheckState;
    /** State of the status check */
    type: StatusCheckType;
};

/** Possible states for a status check */
export enum StatusCheckState {
    /** Status check has an error */
    Error = "ERROR",
    /** Status check is successful */
    Success = "SUCCESS",
    /** Status check has a warning */
    Warning = "WARNING",
}

/** Possible types for a status check */
export enum StatusCheckType {
    /** Status check for device configuration */
    DeviceConfiguration = "DEVICE_CONFIGURATION",
    /** Status check for device online */
    DeviceOnline = "DEVICE_ONLINE",
    /** Status check to see if device is reading mass balances */
    MassBalancesConnected = "MASS_BALANCES_CONNECTED",
}

/** Type for the smallest recognisable instruction to a biologist. Encapsulates a number of Invocations (i.e. operations that the device will perform). */
export type Step = {
    __typename?: "Step";
    /** Reports whether this Step requires confirmation to start running */
    confirmationRequired: Maybe<Scalars["Boolean"]["output"]>;
    /** Text associated with Step confirmation */
    confirmationText: Maybe<Scalars["String"]["output"]>;
    /** Reports whether the Step has been confirmed and may start */
    confirmed: Maybe<Scalars["Boolean"]["output"]>;
    /** The User who last confirmed the Step. If the Step is subsequently unconfirmed, this field will be null */
    confirmedBy: Maybe<User>;
    /** The message reported to the user if there was an error at runtime */
    errorMessage: Maybe<Scalars["String"]["output"]>;
    /** Estimated runtime duration of the Step in milliseconds */
    estimatedDuration: Maybe<Scalars["Float"]["output"]>;
    /** Unique ID of the Step */
    id: Scalars["ID"]["output"];
    /** Aggregate information about the invocations in this step */
    invocationSummary: Maybe<StepInvocationsSummary>;
    /** List of Invocations in the order they will be executed */
    invocations: Maybe<InvocationConnection>;
    /** Friendly readable name of the Step */
    name: Maybe<Scalars["String"]["output"]>;
    /** Runtime reference to another step */
    runtimeReference: Maybe<StepRuntimeReference>;
    /** Used to logically subgroup Steps within a procedure together */
    stageName: Maybe<Scalars["String"]["output"]>;
    /** Current state of the Step i.e. running */
    state: Maybe<StepState>;
    /** ISO formatted timestamp of when the Step stopped running (e.g. completed or failed) */
    timeFinished: Maybe<Scalars["String"]["output"]>;
    /** ISO formatted timestamp of intended start time of the Step */
    timePlanned: Maybe<Scalars["String"]["output"]>;
    /** ISO formatted timestamp of when the Step began running */
    timeStarted: Maybe<Scalars["String"]["output"]>;
    /** Reports whether the Step is currently waiting for confirmation to start */
    waitingForConfirmation: Maybe<Scalars["Boolean"]["output"]>;
};

/** Connection object for the `Step` type */
export type StepConnection = Connection & {
    __typename?: "StepConnection";
    /**
     * Array of Step nodes. If pagination arguments are provided to
     * `StepConnection`, this array will be modified.
     * By default the array is in order of the Steps being run on the Device.
     */
    nodes: Maybe<Array<Step>>;
    /** Details on the current page of the connection */
    pageInfo: PageInfo;
    /** Total number of nodes in this connection */
    totalCount: Scalars["Int"]["output"];
};

/** Response payload for mutated Step */
export type StepConnectionMutationResponse = MutationResponse & {
    __typename?: "StepConnectionMutationResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if Step mutation was successful */
    ok: Scalars["Boolean"]["output"];
    /** Up-to-date Step data */
    stepConnection: Maybe<StepConnection>;
};

/** All controls of an Step */
export enum StepControl {
    /**
     * Stop the current Invocation in it's tracks and move onto the next Step
     * without completing any outstanding Invocations. The Culture will continue
     * running.
     * This only be used on running Steps.
     */
    Cancel = "CANCEL",
    /** Mark a step as confirmed by user, some steps require this to be done before they can be executed. */
    Confirm = "CONFIRM",
    /**
     * Pause a Step that is currently running. If a Invocation is currently
     * running, the Step will transition to the PAUSING state whilst that
     * Invocation completes, then the Step will transition to the PAUSED state.
     * When the culture RESUME action is sent the Step will continue from
     * the next Invocation.
     * You can also pause at the Culture and Procedure levels too, they might lead the the
     * device doing more work before it gets to the PAUSED state.
     */
    Pause = "PAUSE",
    /** Remove a planned Step to transition its state to removed (the schedule will skip it rather than running it) */
    Remove = "REMOVE",
    /** Restore an removed Step to transition its state to planned or waiting */
    Restore = "RESTORE",
    /** Mark a step as unconfirmed by user. */
    Unconfirm = "UNCONFIRM",
}

/** Aggregate information about the invocations for a given step */
export type StepInvocationsSummary = {
    __typename?: "StepInvocationsSummary";
    /** The id of the Step this summary is for */
    id: Scalars["ID"]["output"];
    /** The number of child invocations that are in a failed state */
    numberOfFailedInvocations: Maybe<Scalars["Int"]["output"]>;
};

/** Response payload for mutated Step */
export type StepMutationResponse = MutationResponse & {
    __typename?: "StepMutationResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Returns true if Step mutation was successful */
    ok: Scalars["Boolean"]["output"];
    /** Up-to-date Step data */
    step: Maybe<Step>;
};

/** The step target runtime from the completion time of a reference step */
export type StepRuntimeReference = {
    __typename?: "StepRuntimeReference";
    /** A earlier Step in the culture whose completion time is used as a reference */
    referenceStep: Maybe<Step>;
    /** The target step runtime as measured from the completion time of the reference step */
    runtimeInSeconds: Maybe<Scalars["Float"]["output"]>;
};

/** Possible states for a Step */
export enum StepState {
    /** The Step has been cancelled and will stop running */
    Cancelled = "CANCELLED",
    /**
     * The Step is in the process of stopping the current invocation in it's
     * tracks, then it will transition to the CANCELLED state
     */
    Cancelling = "CANCELLING",
    /** The Step finished running successfully */
    Complete = "COMPLETE",
    /** The Step finished unsuccessfully with an error */
    Failed = "FAILED",
    /** The Step was skipped when it was due to run and the schedule has now moved past it */
    Ignored = "IGNORED",
    /** The Step is paused mid execution */
    Paused = "PAUSED",
    /**
     * The Step is finishing it's current invocation, then it will transition to
     * the PAUSED state
     */
    Pausing = "PAUSING",
    /** The Step is upcoming in the schedule and will be run */
    Planned = "PLANNED",
    /** The Step will be skipped when it is due to run */
    Removed = "REMOVED",
    /** The Step is currently in progress */
    Running = "RUNNING",
    /** The Step state is currently unknown */
    Unknown = "UNKNOWN",
    /** The Step is waiting for confirmation */
    Waiting = "WAITING",
}

/** A parameter type to characterise an array of string values */
export type StringArrayParameter = BaseParameter & {
    __typename?: "StringArrayParameter";
    /** The default array of strings for the parameter */
    default: Maybe<Array<Scalars["String"]["output"]>>;
    /** A brief description of the parameter */
    description: Maybe<Scalars["String"]["output"]>;
    /** A readable name for the parameter */
    name: Maybe<Scalars["String"]["output"]>;
    /** The available string options for the parameter */
    options: Maybe<Array<Scalars["String"]["output"]>>;
    /** A unique ID for the parameter */
    parameterId: Maybe<Scalars["String"]["output"]>;
    /** Whether this parameter required to run the device operation */
    required: Maybe<Scalars["Boolean"]["output"]>;
};

/** A parameter type to characterise a valid string */
export type StringParameter = BaseParameter & {
    __typename?: "StringParameter";
    /** The default string for the parameter */
    default: Maybe<Scalars["String"]["output"]>;
    /** A brief description of the parameter */
    description: Maybe<Scalars["String"]["output"]>;
    /** A readable name for the parameter */
    name: Maybe<Scalars["String"]["output"]>;
    /** The available string options for the parameter */
    options: Maybe<Array<Scalars["String"]["output"]>>;
    /** A unique ID for the parameter */
    parameterId: Maybe<Scalars["String"]["output"]>;
    /** Whether this parameter required to run the device operation */
    required: Maybe<Scalars["Boolean"]["output"]>;
};

/** Payload to trigger a culture simulation for all upcoming invocations */
export type TriggerCultureSimulationInput = {
    /** The ID of the Culture to simulate for */
    cultureId: Scalars["String"]["input"];
};

/** Input for triggering a bulk upload of data from a Device */
export type TriggerDeviceToServerSyncInput = {
    /** Id of the Device to trigger bulk upload */
    deviceId: Scalars["String"]["input"];
};

/** Input for triggering a force sync of a Device */
export type TriggerServerToDeviceSyncInput = {
    /** Id of the Device to trigger sync */
    deviceId: Scalars["String"]["input"];
};

/** Input for unpinning a Device from a Balena Release */
export type UnpinDeviceFromBalenaReleaseInput = {
    /** Id of the Device to remove the balena release pin from */
    deviceId: Scalars["String"]["input"];
};

/** Parameters available for updating metadata of a Device */
export type UpdateDeviceInput = {
    /** New Balena UUID that should be associated with the Device */
    balenaUuid?: InputMaybe<Scalars["String"]["input"]>;
    /** ID of the Device to update */
    deviceId: Scalars["String"]["input"];
};

/** Parameters available for updating maintenance status of a Device */
export type UpdateDeviceMaintenanceInput = {
    /** ID of the Device to update */
    deviceId: Scalars["String"]["input"];
    /** Date the the device will go in maintenance until */
    maintenanceUntil?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response payload for update Device mutation */
export type UpdateDeviceMaintenanceResponse = MutationResponse & {
    __typename?: "UpdateDeviceMaintenanceResponse";
    /** Device object updated if successful */
    device: Maybe<Device>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Status of create Device attempt */
    ok: Scalars["Boolean"]["output"];
};

/** Input required to modify an existing Device-User relationship. */
export type UpdateDeviceNotificationPreferenceInput = {
    /** ID of the Device for which the preferences will apply */
    deviceId: Scalars["String"]["input"];
    /** The role of the User when accessing the Device */
    notificationsEnabled: Scalars["Boolean"]["input"];
};

/** Parameters available for updating orgId of a Device */
export type UpdateDeviceOrgInput = {
    /** ID of the Device to update */
    deviceId: Scalars["String"]["input"];
    /** New orgId that should be associated with the Device */
    orgId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response payload for update Device Org mutation */
export type UpdateDeviceOrgResponse = MutationResponse & {
    __typename?: "UpdateDeviceOrgResponse";
    /** Device object updated if successful */
    device: Maybe<Device>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Status of update Device Org attempt */
    ok: Scalars["Boolean"]["output"];
};

/** Response payload for update Device mutation */
export type UpdateDeviceResponse = MutationResponse & {
    __typename?: "UpdateDeviceResponse";
    /** Device object updated if successful */
    device: Maybe<Device>;
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Status of update Device attempt */
    ok: Scalars["Boolean"]["output"];
};

/** Input required to modify an existing Device-User relationship. */
export type UpdateDeviceUserInput = {
    /** ID of the Device-User relationship to update */
    edgeId: Scalars["String"]["input"];
    /** The role of the User when accessing the Device */
    role: DeviceUserRole;
};

/** Input object for updating a users notification preferences for a single event */
export type UpdateEventNotificationsPreferenceInput = {
    /** A switch for notifications for this event through email */
    email?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** A switch for notifications for this event through all channels */
    enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The event type */
    event: NotificationEvent;
    /** A switch for notifications for this event through the app */
    inApp?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** A global switch for notifications through slack */
    slack?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** A switch for notifications for this event through sms */
    sms?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Input object for updating a users notification preferences */
export type UpdateGlobalNotificationsPreferencesInput = {
    /** A global switch for notifications through email */
    email?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** A global switch for notifications through all channels */
    global?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** A global switch for notifications through the app */
    inApp?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** A global switch for notifications through slack */
    slack?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** A global switch for notifications through sms */
    sms?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The payload for mutation to update the of a notification. */
export type UpdateNotificationInput = {
    /** Optionally specify if the notification should be marked as archived */
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** ID of notification to modify */
    notificationId: Scalars["String"]["input"];
    /** Optionally specify if the notification should be marked as read */
    read?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Response payload to update Notification data */
export type UpdateNotificationResponse = MutationResponse & {
    __typename?: "UpdateNotificationResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Notification object just updated by mutation */
    notification: Maybe<Notification>;
    /** Result of notification update */
    ok: Scalars["Boolean"]["output"];
};

/** Parameters to update multiple attributes of multiple notifications. */
export type UpdateNotificationsInput = {
    /** Optionally specify if the notification(s) should be marked as archived */
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** IDs of notifications to modify */
    notificationIds: Array<Scalars["ID"]["input"]>;
    /** Optionally specify if the notification(s) should be marked as read */
    read?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Response payload to updating multiple notifications */
export type UpdateNotificationsResponse = MutationResponse & {
    __typename?: "UpdateNotificationsResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of notifications update */
    ok: Scalars["Boolean"]["output"];
};

/** Payload required to update a step runtime reference */
export type UpdateStepRuntimeReferenceInput = {
    /** The ID of the Device containing the Step */
    deviceId: Scalars["String"]["input"];
    /** The new target runtime in seconds */
    runtimeInSeconds: Scalars["Float"]["input"];
    /** The ID of the Step to control */
    stepId: Scalars["String"]["input"];
};

/** Input object for updating User profile data */
export type UpdateUserInput = {
    /** Updated email address */
    email?: InputMaybe<Scalars["String"]["input"]>;
    /** Local timezone of user when receiving email notifications */
    emailTimezone?: InputMaybe<Scalars["String"]["input"]>;
    /** Updated first name */
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    /** Updated last name */
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    /** Updated phone number */
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response payload to update User data */
export type UpdateUserResponse = MutationResponse & {
    __typename?: "UpdateUserResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of login attempt */
    ok: Scalars["Boolean"]["output"];
    /** User object just created by mutation */
    user: Maybe<User>;
};

/** Input object for updating User UI preferences for the logged in user */
export type UpdateUserUiPreferencesInput = {
    /** The preference values */
    values: Scalars["JSON"]["input"];
};

/** Response payload to update User UI preferences */
export type UpdateUserUiPreferencesResponse = MutationResponse & {
    __typename?: "UpdateUserUiPreferencesResponse";
    /** Explanation for `ok` boolean */
    message: Scalars["String"]["output"];
    /** Result of login attempt */
    ok: Scalars["Boolean"]["output"];
    /** The updated User UI preferences */
    userUiPreferences: Maybe<UserUiPreferences>;
};

/** A user of the frontend App */
export type User = {
    __typename?: "User";
    /** List of API Keys commissioned by the User */
    apiKeys: Maybe<Array<Maybe<ApiKey>>>;
    /** Primary email associated with the user account */
    email: Scalars["String"]["output"];
    /** Preferred first name of the user */
    firstName: Maybe<Scalars["String"]["output"]>;
    /** Unique ID of User */
    id: Scalars["ID"]["output"];
    /** Preferred last name of the user */
    lastName: Maybe<Scalars["String"]["output"]>;
    /** Organisation the user belongs to */
    organisation: Maybe<Organisation>;
    /** Mobile number in E.164 format */
    phoneNumber: Maybe<Scalars["String"]["output"]>;
    /** The User's sessions */
    sessions: Maybe<Array<Maybe<UserSession>>>;
    /** Various user-configured settings */
    settings: Maybe<UserSettings>;
    /** Status of the user account */
    status: Maybe<UserStatus>;
};

/** An authentication session for a User created from the sign in flow */
export type UserSession = {
    __typename?: "UserSession";
    /** Timestamp the session was created */
    createdAt: Maybe<Scalars["String"]["output"]>;
    /** Timestamp the session will expire */
    expiresAt: Maybe<Scalars["String"]["output"]>;
    /** Unique ID of the session */
    id: Scalars["ID"]["output"];
    /** The user agent that followed the sign in flow */
    userAgent: Maybe<Scalars["String"]["output"]>;
};

/** The configurable settings belonging to a user */
export type UserSettings = {
    __typename?: "UserSettings";
    /** The preferences for notifications */
    notifications: NotificationPreferences;
    /** The timezone for a user */
    timezone: Maybe<Scalars["String"]["output"]>;
};

/** User statuses */
export enum UserStatus {
    /** User account is active */
    Active = "ACTIVE",
    /** User account is deactivated */
    Deactivated = "DEACTIVATED",
}

/** User preferences for the our web application */
export type UserUiPreferences = {
    __typename?: "UserUiPreferences";
    /** Unique ID of User UI preferences */
    id: Scalars["ID"]["output"];
    /** The ID of the User the preferences are associated with */
    userId: Scalars["ID"]["output"];
    /** The preference values */
    values: Maybe<Scalars["JSON"]["output"]>;
};

/** Cartesian coordinates */
export type Xyz = {
    __typename?: "XYZ";
    /** X coordinate */
    x: Maybe<Scalars["Float"]["output"]>;
    /** Y coordinate */
    y: Maybe<Scalars["Float"]["output"]>;
    /** Z coordinate */
    z: Maybe<Scalars["Float"]["output"]>;
};

export type NotificationsCountQueryVariables = Exact<{
    read?: InputMaybe<Scalars["Boolean"]["input"]>;
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type NotificationsCountQuery = {
    __typename?: "Query";
    notifications: {
        __typename?: "NotificationsConnection";
        totalCount: number;
    };
};

export type NotificationsQueryVariables = Exact<{
    before?: InputMaybe<Scalars["String"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type NotificationsQuery = {
    __typename?: "Query";
    notifications: {
        __typename?: "NotificationsConnection";
        edges: Array<{
            __typename?: "NotificationEdge";
            node: {
                __typename?: "Notification";
                id: string;
                event: NotificationEvent;
                read: boolean;
                readDate: string | null;
                archived: boolean;
                archivedDate: string | null;
                createdDate: string;
                subject: {
                    __typename?: "Result";
                    id: string;
                    device: {
                        __typename?: "Device";
                        id: string;
                        name: string | null;
                    };
                } | null;
            } | null;
        }>;
        pageInfo: {
            __typename?: "PageInfo";
            startCursor: string | null;
            hasPreviousPage: boolean;
        };
    };
};

export type NotificationsIdQueryVariables = Exact<{
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type NotificationsIdQuery = {
    __typename?: "Query";
    notifications: {
        __typename?: "NotificationsConnection";
        edges: Array<{
            __typename?: "NotificationEdge";
            node: { __typename?: "Notification"; id: string } | null;
        }>;
    };
};

export type UpdateNotificationMutationVariables = Exact<{
    notificationId: Scalars["String"]["input"];
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
    read?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateNotificationMutation = {
    __typename?: "Mutation";
    updateNotification: {
        __typename?: "UpdateNotificationResponse";
        ok: boolean;
        message: string;
        notification: {
            __typename?: "Notification";
            id: string;
            event: NotificationEvent;
            read: boolean;
            readDate: string | null;
            archived: boolean;
            archivedDate: string | null;
            createdDate: string;
            subject: {
                __typename?: "Result";
                id: string;
                device: {
                    __typename?: "Device";
                    id: string;
                    name: string | null;
                };
            } | null;
        } | null;
    };
};

export type UpdateNotificationsMutationVariables = Exact<{
    notificationIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
    archived?: InputMaybe<Scalars["Boolean"]["input"]>;
    read?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateNotificationsMutation = {
    __typename?: "Mutation";
    updateNotifications: {
        __typename?: "UpdateNotificationsResponse";
        ok: boolean;
        message: string;
    };
};

export type PinnedDevicesQueryVariables = Exact<{ [key: string]: never }>;

export type PinnedDevicesQuery = {
    __typename?: "Query";
    devices: Array<{
        __typename?: "Device";
        id: string;
        isOnline: boolean;
        name: string | null;
    } | null> | null;
};

export type ServerStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ServerStatusQuery = {
    __typename?: "Query";
    status: {
        __typename?: "ServerStatus";
        ok: boolean;
        message: string | null;
    };
};

export type DeviceOnlineChangeQueryVariables = Exact<{ [key: string]: never }>;

export type DeviceOnlineChangeQuery = {
    __typename?: "Query";
    devices: Array<{
        __typename?: "Device";
        id: string;
        name: string | null;
        isOnline: boolean;
    } | null> | null;
};

export type UpdateDeviceNotificationPreferenceMutationVariables = Exact<{
    input: UpdateDeviceNotificationPreferenceInput;
}>;

export type UpdateDeviceNotificationPreferenceMutation = {
    __typename?: "Mutation";
    updateDeviceNotificationPreference: {
        __typename?: "ModifyDeviceUserResponse";
        ok: boolean;
        message: string;
        edge: {
            __typename?: "DeviceUserEdge";
            id: string;
            notificationsEnabled: boolean | null;
        } | null;
    };
};

export type DeviceNotificationsEnabledQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceNotificationsEnabledQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        users: {
            __typename?: "DeviceUserConnection";
            myAccess: {
                __typename?: "DeviceUserEdge";
                id: string;
                notificationsEnabled: boolean | null;
            } | null;
        } | null;
    } | null;
};

export type CulturePageQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CulturePageQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        name: string | null;
        state: CultureState | null;
        isActive: boolean | null;
        isWetTestCulture: boolean | null;
        mintedByServer: boolean | null;
        errorMessage: string | null;
        device: { __typename?: "Device"; id: string } | null;
        schedule: {
            __typename?: "Schedule";
            isWaitingOnConfirmation: boolean | null;
            nextStep: { __typename?: "Step"; id: string } | null;
            nextProcedure: { __typename?: "Procedure"; id: string } | null;
        } | null;
    } | null;
};

export type ConfirmProcedureMutationVariables = Exact<{
    input: ControlProcedureInput;
}>;

export type ConfirmProcedureMutation = {
    __typename?: "Mutation";
    controlProcedure: {
        __typename?: "ControlProcedureResponse";
        ok: boolean;
        message: string;
        procedure: {
            __typename?: "Procedure";
            id: string;
            name: string | null;
            state: ProcedureState | null;
            confirmationRequired: boolean | null;
            confirmed: boolean | null;
            confirmedBy: {
                __typename?: "User";
                id: string;
                firstName: string | null;
                lastName: string | null;
            } | null;
        } | null;
    };
};

export type ProcedureStepsLightQueryVariables = Exact<{
    id: Scalars["String"]["input"];
}>;

export type ProcedureStepsLightQuery = {
    __typename?: "Query";
    procedure: {
        __typename?: "Procedure";
        id: string;
        steps: {
            __typename?: "StepConnection";
            totalCount: number;
            nodes: Array<{
                __typename?: "Step";
                id: string;
                name: string | null;
            }> | null;
        } | null;
    } | null;
};

export type EditProcedureStepsMutationVariables = Exact<{
    procedureId: Scalars["ID"]["input"];
    steps: Array<EditProcedureStepInput> | EditProcedureStepInput;
}>;

export type EditProcedureStepsMutation = {
    __typename?: "Mutation";
    editProcedureSteps: {
        __typename?: "EditProcedureStepsResponse";
        ok: boolean;
        message: string;
        procedureSteps: Array<{
            __typename?: "Step";
            id: string;
            name: string | null;
        }> | null;
    };
};

export type DuplicateProcedureMutationVariables = Exact<{
    input: DuplicateProcedureInput;
}>;

export type DuplicateProcedureMutation = {
    __typename?: "Mutation";
    duplicateProcedure: {
        __typename?: "DuplicateProcedureResponse";
        ok: boolean;
        message: string;
        culture: {
            __typename?: "Culture";
            id: string;
            schedule: {
                __typename?: "Schedule";
                isWaitingOnConfirmation: boolean | null;
                currentProcedure: {
                    __typename?: "Procedure";
                    id: string;
                } | null;
                nextProcedure: { __typename?: "Procedure"; id: string } | null;
                nextStep: { __typename?: "Step"; id: string } | null;
                nextInvocation: {
                    __typename?: "Invocation";
                    id: string;
                } | null;
                procedures: {
                    __typename?: "ProcedureConnection";
                    totalCount: number;
                    nodes: Array<{
                        __typename?: "Procedure";
                        id: string;
                        name: string | null;
                        state: ProcedureState | null;
                        estimatedDuration: number | null;
                        timePlanned: string | null;
                        timeStarted: string | null;
                        timeFinished: string | null;
                        confirmationRequired: boolean | null;
                        confirmationText: string | null;
                        waitingForConfirmation: boolean | null;
                        confirmed: boolean | null;
                        lastSentToDeviceAt: string | null;
                        confirmedBy: {
                            __typename?: "User";
                            id: string;
                            firstName: string | null;
                            lastName: string | null;
                        } | null;
                        steps: {
                            __typename?: "StepConnection";
                            totalCount: number;
                        } | null;
                        stepsSummary: {
                            __typename?: "ProcedureStepsSummary";
                            id: string;
                            numberOfStepsWithOutstandingConfirmation:
                                | number
                                | null;
                            hasStepsWithErrors: boolean | null;
                        } | null;
                    } | null> | null;
                } | null;
            } | null;
        } | null;
        newProcedure: { __typename?: "Procedure"; id: string } | null;
    };
};

export type RescheduleProcedureMutationVariables = Exact<{
    input: RescheduleProcedureInput;
}>;

export type RescheduleProcedureMutation = {
    __typename?: "Mutation";
    rescheduleProcedure: {
        __typename?: "RescheduleProcedureResponse";
        ok: boolean;
        message: string;
        culture: {
            __typename?: "Culture";
            id: string;
            schedule: {
                __typename?: "Schedule";
                isWaitingOnConfirmation: boolean | null;
                currentProcedure: {
                    __typename?: "Procedure";
                    id: string;
                } | null;
                nextProcedure: { __typename?: "Procedure"; id: string } | null;
                nextStep: { __typename?: "Step"; id: string } | null;
                nextInvocation: {
                    __typename?: "Invocation";
                    id: string;
                } | null;
                procedures: {
                    __typename?: "ProcedureConnection";
                    totalCount: number;
                    nodes: Array<{
                        __typename?: "Procedure";
                        id: string;
                        name: string | null;
                        state: ProcedureState | null;
                        estimatedDuration: number | null;
                        timePlanned: string | null;
                        timeStarted: string | null;
                        timeFinished: string | null;
                        confirmationRequired: boolean | null;
                        confirmationText: string | null;
                        waitingForConfirmation: boolean | null;
                        confirmed: boolean | null;
                        lastSentToDeviceAt: string | null;
                        confirmedBy: {
                            __typename?: "User";
                            id: string;
                            firstName: string | null;
                            lastName: string | null;
                        } | null;
                        steps: {
                            __typename?: "StepConnection";
                            totalCount: number;
                        } | null;
                        stepsSummary: {
                            __typename?: "ProcedureStepsSummary";
                            id: string;
                            numberOfStepsWithOutstandingConfirmation:
                                | number
                                | null;
                            hasStepsWithErrors: boolean | null;
                        } | null;
                    } | null> | null;
                } | null;
            } | null;
        } | null;
    };
};

export type ConfirmStepMutationVariables = Exact<{
    input: ControlStepInput;
}>;

export type ConfirmStepMutation = {
    __typename?: "Mutation";
    controlStep: {
        __typename?: "StepMutationResponse";
        ok: boolean;
        message: string;
        step: {
            __typename?: "Step";
            id: string;
            name: string | null;
            state: StepState | null;
            confirmationRequired: boolean | null;
            waitingForConfirmation: boolean | null;
            confirmed: boolean | null;
            timeStarted: string | null;
        } | null;
    };
};

export type DuplicateStepMutationVariables = Exact<{
    input: DuplicateStepInput;
}>;

export type DuplicateStepMutation = {
    __typename?: "Mutation";
    duplicateStep: {
        __typename?: "DuplicateStepMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type UpdateStepRuntimeReferenceMutationVariables = Exact<{
    input: UpdateStepRuntimeReferenceInput;
}>;

export type UpdateStepRuntimeReferenceMutation = {
    __typename?: "Mutation";
    updateStepRuntimeReference: {
        __typename?: "StepMutationResponse";
        ok: boolean;
        message: string;
        step: {
            __typename: "Step";
            id: string;
            name: string | null;
            runtimeReference: {
                __typename: "StepRuntimeReference";
                runtimeInSeconds: number | null;
            } | null;
        } | null;
    };
};

export type DevicesFilterQueryVariables = Exact<{ [key: string]: never }>;

export type DevicesFilterQuery = {
    __typename?: "Query";
    devices: Array<{
        __typename?: "Device";
        id: string;
        name: string | null;
    } | null> | null;
};

export type CulturesQueryVariables = Exact<{
    last?: InputMaybe<Scalars["Int"]["input"]>;
    before?: InputMaybe<Scalars["String"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    after?: InputMaybe<Scalars["String"]["input"]>;
    filterBy?: InputMaybe<CultureConnectionFilters>;
}>;

export type CulturesQuery = {
    __typename?: "Query";
    cultures: {
        __typename?: "CultureConnection";
        totalCount: number;
        nodes: Array<{
            __typename?: "Culture";
            id: string;
            name: string | null;
            state: CultureState | null;
            uploadDate: string | null;
            isActive: boolean | null;
            errorMessage: string | null;
            device: {
                __typename?: "Device";
                id: string;
                name: string | null;
            } | null;
            schedule: {
                __typename?: "Schedule";
                isWaitingOnConfirmation: boolean | null;
            } | null;
        } | null> | null;
        pageInfo: {
            __typename?: "PageInfo";
            endCursor: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string | null;
        };
    } | null;
};

export type DeviceOrgQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceOrgQuery = {
    __typename?: "Query";
    device: { __typename?: "Device"; id: string; orgId: string | null } | null;
};

export type UpdateDeviceOrgMutationVariables = Exact<{
    updateDeviceUpdateDeviceOrgInput: UpdateDeviceOrgInput;
}>;

export type UpdateDeviceOrgMutation = {
    __typename?: "Mutation";
    updateDeviceOrg: {
        __typename?: "UpdateDeviceOrgResponse";
        ok: boolean;
        message: string;
        device: {
            __typename?: "Device";
            id: string;
            orgId: string | null;
        } | null;
    };
};

export type TriggerServerToDeviceSyncMutationVariables = Exact<{
    input: TriggerServerToDeviceSyncInput;
}>;

export type TriggerServerToDeviceSyncMutation = {
    __typename?: "Mutation";
    triggerServerToDeviceSync: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type TriggerDeviceToServerSyncMutationVariables = Exact<{
    input: TriggerDeviceToServerSyncInput;
}>;

export type TriggerDeviceToServerSyncMutation = {
    __typename?: "Mutation";
    triggerDeviceToServerSync: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type TriggerCultureSimulationForUpcomingInvocationsMutationVariables =
    Exact<{
        input: TriggerCultureSimulationInput;
    }>;

export type TriggerCultureSimulationForUpcomingInvocationsMutation = {
    __typename?: "Mutation";
    triggerCultureSimulationForUpcomingInvocations: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type DeviceSyncPageQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceSyncPageQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        cultureId: string | null;
    } | null;
};

export type UseCultureFlaskConfluenceQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
    flaskId: Scalars["String"]["input"];
}>;

export type UseCultureFlaskConfluenceQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        startDate: string | null;
        dayStartIndex: number | null;
        results: {
            __typename?: "ResultConnection";
            nodes: Array<{
                __typename?: "Result";
                id: string;
                timestampISO: string | null;
                data:
                    | { __typename: "CountingChipImages" }
                    | {
                          __typename: "FlaskImages";
                          flask: {
                              __typename?: "ResultFlask";
                              number: number | null;
                          } | null;
                          images: Array<{
                              __typename?: "ImageData";
                              id: string;
                              confluence: number | null;
                              position: {
                                  __typename?: "Position";
                                  relativePosition: {
                                      __typename?: "RelativePosition";
                                      relativeCoords: {
                                          __typename?: "XYZ";
                                          x: number | null;
                                          y: number | null;
                                          z: number | null;
                                      } | null;
                                  } | null;
                              } | null;
                          } | null> | null;
                      }
                    | null;
            } | null> | null;
        } | null;
    } | null;
};

export type DeviceAutomatedCheckQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceAutomatedCheckQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        statusChecks: Array<{
            __typename?: "StatusCheck";
            name: string;
            type: StatusCheckType;
            state: StatusCheckState;
            reason: string;
        }> | null;
    } | null;
};

export type ProtocolCellDataQueryVariables = Exact<{
    protocolId: Scalars["String"]["input"];
}>;

export type ProtocolCellDataQuery = {
    __typename?: "Query";
    protocol: {
        __typename?: "Protocol";
        id: string;
        name: string | null;
        initialCoatingTimeMinutes: number | null;
        initialSeedProcedureData: {
            __typename?: "SeedProcedureMetadata";
            seedVesselCellSuspensionMl: number;
            flaskSeedGroups: Array<{
                __typename?: "FlaskSeedGroupData";
                doseMl: number;
                flaskNumbers: Array<number | null>;
                flaskSizeCm2: number;
            }>;
        } | null;
    } | null;
};

export type CreateProtocolV2MutationVariables = Exact<{
    input: CreateProtocolInput;
}>;

export type CreateProtocolV2Mutation = {
    __typename?: "Mutation";
    createProtocol: {
        __typename?: "CreateProtocolResponse";
        ok: boolean;
        message: string;
        protocol: {
            __typename?: "Protocol";
            id: string;
            name: string | null;
            initialCoatingTimeMinutes: number | null;
            initialSeedProcedureData: {
                __typename?: "SeedProcedureMetadata";
                seedVesselCellSuspensionMl: number;
                flaskSeedGroups: Array<{
                    __typename?: "FlaskSeedGroupData";
                    doseMl: number;
                    flaskNumbers: Array<number | null>;
                    flaskSizeCm2: number;
                }>;
            } | null;
            startingBottles: Array<{
                __typename?: "ProtocolStartingBottleData";
                liquidVolume: number;
                reagentName: string;
                totalVolume: number;
            } | null> | null;
        } | null;
        wetTestProtocol: {
            __typename?: "Protocol";
            id: string;
            name: string | null;
            initialCoatingTimeMinutes: number | null;
            initialSeedProcedureData: {
                __typename?: "SeedProcedureMetadata";
                seedVesselCellSuspensionMl: number;
                flaskSeedGroups: Array<{
                    __typename?: "FlaskSeedGroupData";
                    doseMl: number;
                    flaskNumbers: Array<number | null>;
                    flaskSizeCm2: number;
                }>;
            } | null;
            startingBottles: Array<{
                __typename?: "ProtocolStartingBottleData";
                liquidVolume: number;
                reagentName: string;
                totalVolume: number;
            } | null> | null;
        } | null;
    };
};

export type ReplaceWetTestWithCultureMutationVariables = Exact<{
    input: ReplaceWetTestWithCultureInput;
}>;

export type ReplaceWetTestWithCultureMutation = {
    __typename?: "Mutation";
    replaceWetTestWithCulture: {
        __typename?: "CreateCultureResponse";
        ok: boolean;
        message: string;
    };
};

export type CreateAndSyncCultureV2MutationVariables = Exact<{
    input: CreateAndSyncCultureInput;
}>;

export type CreateAndSyncCultureV2Mutation = {
    __typename?: "Mutation";
    createAndSyncCulture: {
        __typename?: "CreateCultureResponse";
        ok: boolean;
        message: string;
    };
};

export type DevicePlatformQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DevicePlatformQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        platform: DevicePlatform | null;
    } | null;
};

export type DeviceJobsQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceJobsQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        deviceJobs: Array<{
            __typename?: "DeviceJob";
            id: string;
            type: DeviceJobType | null;
            state: DeviceJobState | null;
            errorMessage: string | null;
            progress: number | null;
            updatedAt: string | null;
            requestedAt: string | null;
        }> | null;
    } | null;
};

export type StartDeviceJobMutationVariables = Exact<{
    input: StartDeviceJobs;
}>;

export type StartDeviceJobMutation = {
    __typename?: "Mutation";
    startDeviceJob: {
        __typename?: "StartDeviceJobResponse";
        ok: boolean;
        message: string;
    };
};

export type CancelDeviceJobMutationVariables = Exact<{
    input: CancelDeviceJob;
}>;

export type CancelDeviceJobMutation = {
    __typename?: "Mutation";
    cancelDeviceJob: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type ProtocolStartingBottlesQueryVariables = Exact<{
    protocolId: Scalars["String"]["input"];
}>;

export type ProtocolStartingBottlesQuery = {
    __typename?: "Query";
    protocol: {
        __typename?: "Protocol";
        id: string;
        startingBottles: Array<{
            __typename?: "ProtocolStartingBottleData";
            liquidVolume: number;
            reagentName: string;
            totalVolume: number;
        } | null> | null;
    } | null;
};

export type WetTestsQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type WetTestsQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        description: string | null;
        isWetTestCulture: boolean | null;
        state: CultureState | null;
        errorMessage: string | null;
        schedule: {
            __typename?: "Schedule";
            procedures: {
                __typename?: "ProcedureConnection";
                nodes: Array<{
                    __typename?: "Procedure";
                    id: string;
                    estimatedDuration: number | null;
                    timeStarted: string | null;
                    timeFinished: string | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type CultureSetupQueryDataFragment = {
    __typename?: "Culture";
    id: string;
    name: string | null;
    state: CultureState | null;
    isWetTestCulture: boolean | null;
    wetTestPrimaryProtocol: {
        __typename?: "Protocol";
        id: string;
        name: string | null;
        initialSeedProcedureData: {
            __typename?: "SeedProcedureMetadata";
            seedVesselCellSuspensionMl: number;
            flaskSeedGroups: Array<{
                __typename?: "FlaskSeedGroupData";
                doseMl: number;
                flaskNumbers: Array<number | null>;
                flaskSizeCm2: number;
            }>;
        } | null;
    } | null;
    protocol: { __typename?: "Protocol"; id: string } | null;
};

export type ProtocolUploadConditionsQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
    filterBy?: InputMaybe<CultureConnectionFilters>;
}>;

export type ProtocolUploadConditionsQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        name: string | null;
        isOnline: boolean;
        platform: DevicePlatform | null;
        balenaUuid: string | null;
        balena: {
            __typename?: "Balena";
            ipAddresses: Array<string> | null;
            updateAvailable: boolean;
            updating: boolean;
        } | null;
        culture: {
            __typename?: "Culture";
            id: string;
            isWetTestCulture: boolean | null;
        } | null;
        cultures: {
            __typename?: "CultureConnection";
            nodes: Array<{
                __typename?: "Culture";
                id: string;
                state: CultureState | null;
            } | null> | null;
        } | null;
    } | null;
};

export type CreateAndSyncCultureMutationVariables = Exact<{
    input: CreateAndSyncCultureInput;
}>;

export type CreateAndSyncCultureMutation = {
    __typename?: "Mutation";
    createAndSyncCulture: {
        __typename?: "CreateCultureResponse";
        ok: boolean;
        message: string;
        culture: {
            __typename?: "Culture";
            id: string;
            name: string | null;
            state: CultureState | null;
            isWetTestCulture: boolean | null;
            wetTestPrimaryProtocol: {
                __typename?: "Protocol";
                id: string;
                name: string | null;
                initialSeedProcedureData: {
                    __typename?: "SeedProcedureMetadata";
                    seedVesselCellSuspensionMl: number;
                    flaskSeedGroups: Array<{
                        __typename?: "FlaskSeedGroupData";
                        doseMl: number;
                        flaskNumbers: Array<number | null>;
                        flaskSizeCm2: number;
                    }>;
                } | null;
            } | null;
            protocol: { __typename?: "Protocol"; id: string } | null;
        } | null;
    };
};

export type CreateProtocolMutationVariables = Exact<{
    input: CreateProtocolInput;
}>;

export type CreateProtocolMutation = {
    __typename?: "Mutation";
    createProtocol: {
        __typename?: "CreateProtocolResponse";
        ok: boolean;
        message: string;
        protocol: {
            __typename?: "Protocol";
            id: string;
            name: string | null;
            initialCoatingTimeMinutes: number | null;
            initialSeedProcedureData: {
                __typename?: "SeedProcedureMetadata";
                seedVesselCellSuspensionMl: number;
                flaskSeedGroups: Array<{
                    __typename?: "FlaskSeedGroupData";
                    doseMl: number;
                    flaskNumbers: Array<number | null>;
                    flaskSizeCm2: number;
                }>;
            } | null;
            startingBottles: Array<{
                __typename?: "ProtocolStartingBottleData";
                liquidVolume: number;
                reagentName: string;
                totalVolume: number;
            } | null> | null;
        } | null;
        wetTestProtocol: {
            __typename?: "Protocol";
            id: string;
            name: string | null;
            initialCoatingTimeMinutes: number | null;
            initialSeedProcedureData: {
                __typename?: "SeedProcedureMetadata";
                seedVesselCellSuspensionMl: number;
                flaskSeedGroups: Array<{
                    __typename?: "FlaskSeedGroupData";
                    doseMl: number;
                    flaskNumbers: Array<number | null>;
                    flaskSizeCm2: number;
                }>;
            } | null;
            startingBottles: Array<{
                __typename?: "ProtocolStartingBottleData";
                liquidVolume: number;
                reagentName: string;
                totalVolume: number;
            } | null> | null;
        } | null;
    };
};

export type DeviceCultureQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
    filterBy?: InputMaybe<CultureConnectionFilters>;
}>;

export type DeviceCultureQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        platform: DevicePlatform | null;
        culture: {
            __typename?: "Culture";
            name: string | null;
            description: string | null;
            errorMessage: string | null;
            startDate: string | null;
            uploadDate: string | null;
            state: CultureState | null;
            id: string;
            mintedByServer: boolean | null;
            plannedDuration: number | null;
            isWetTestCulture: boolean | null;
            protocol: { __typename?: "Protocol"; id: string } | null;
            schedule: {
                __typename?: "Schedule";
                nextProcedure: { __typename?: "Procedure"; id: string } | null;
            } | null;
            lastSimulationError: {
                __typename?: "CultureSimulationFailure";
                id: string;
                errorMessage: string | null;
            } | null;
            wetTestPrimaryProtocol: {
                __typename?: "Protocol";
                id: string;
                name: string | null;
                initialSeedProcedureData: {
                    __typename?: "SeedProcedureMetadata";
                    seedVesselCellSuspensionMl: number;
                    flaskSeedGroups: Array<{
                        __typename?: "FlaskSeedGroupData";
                        doseMl: number;
                        flaskNumbers: Array<number | null>;
                        flaskSizeCm2: number;
                    }>;
                } | null;
            } | null;
        } | null;
        cultures: {
            __typename?: "CultureConnection";
            nodes: Array<{
                __typename?: "Culture";
                id: string;
                state: CultureState | null;
            } | null> | null;
        } | null;
    } | null;
};

export type CultureOverviewQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CultureOverviewQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        name: string | null;
        description: string | null;
        startDate: string | null;
        uploadDate: string | null;
        state: CultureState | null;
        id: string;
        plannedDuration: number | null;
        errorMessage: string | null;
        mintedByServer: boolean | null;
        isWetTestCulture: boolean | null;
        device: {
            __typename?: "Device";
            id: string;
            platform: DevicePlatform | null;
        } | null;
        schedule: {
            __typename?: "Schedule";
            nextProcedure: { __typename?: "Procedure"; id: string } | null;
        } | null;
        lastSimulationError: {
            __typename?: "CultureSimulationFailure";
            id: string;
            errorMessage: string | null;
        } | null;
        wetTestPrimaryProtocol: {
            __typename?: "Protocol";
            id: string;
            name: string | null;
            initialSeedProcedureData: {
                __typename?: "SeedProcedureMetadata";
                seedVesselCellSuspensionMl: number;
                flaskSeedGroups: Array<{
                    __typename?: "FlaskSeedGroupData";
                    doseMl: number;
                    flaskNumbers: Array<number | null>;
                    flaskSizeCm2: number;
                }>;
            } | null;
        } | null;
        protocol: { __typename?: "Protocol"; id: string } | null;
    } | null;
};

export type DevicePageQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DevicePageQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        name: string | null;
        orgId: string | null;
        isOnline: boolean;
        status: DeviceStatus | null;
        cultureId: string | null;
        balena: {
            __typename?: "Balena";
            updateAvailable: boolean;
            updating: boolean;
        } | null;
        culture: {
            __typename?: "Culture";
            id: string;
            name: string | null;
            isActive: boolean | null;
            errorMessage: string | null;
            state: CultureState | null;
            mintedByServer: boolean | null;
            isWetTestCulture: boolean | null;
            schedule: {
                __typename?: "Schedule";
                isWaitingOnConfirmation: boolean | null;
                nextStep: { __typename?: "Step"; id: string } | null;
                nextProcedure: { __typename?: "Procedure"; id: string } | null;
            } | null;
        } | null;
        statusChecks: Array<{
            __typename?: "StatusCheck";
            name: string;
            type: StatusCheckType;
            state: StatusCheckState;
            reason: string;
        }> | null;
    } | null;
};

export type CultureImageResultsQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
    flaskIds?: InputMaybe<
        Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
    >;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CultureImageResultsQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        startDate: string | null;
        dayStartIndex: number | null;
        results: {
            __typename?: "ResultConnection";
            totalCount: number;
            nodes: Array<{
                __typename?: "Result";
                id: string;
                type: ResultType | null;
                timestampISO: string | null;
                data:
                    | { __typename: "CountingChipImages" }
                    | {
                          __typename: "FlaskImages";
                          flask: {
                              __typename?: "ResultFlask";
                              flaskId: string | null;
                              number: number | null;
                              size: number | null;
                          } | null;
                          images: Array<{
                              __typename?: "ImageData";
                              id: string;
                              imageUrl: string | null;
                              timestampISO: string | null;
                              pixelScale: number | null;
                              width: number | null;
                              position: {
                                  __typename?: "Position";
                                  absolutePosition: {
                                      __typename?: "XYZ";
                                      x: number | null;
                                      y: number | null;
                                      z: number | null;
                                  } | null;
                                  relativePosition: {
                                      __typename?: "RelativePosition";
                                      itemId: string | null;
                                      positionName: string | null;
                                      relativeCoords: {
                                          __typename?: "XYZ";
                                          x: number | null;
                                          y: number | null;
                                          z: number | null;
                                      } | null;
                                  } | null;
                              } | null;
                          } | null> | null;
                      }
                    | null;
            } | null> | null;
            pageInfo: {
                __typename?: "PageInfo";
                startCursor: string | null;
                endCursor: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type CultureFlaskResultQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CultureFlaskResultQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        results: {
            __typename?: "ResultConnection";
            flaskIds: Array<string> | null;
        } | null;
        flasks: {
            __typename?: "FlaskConnection";
            nodes: Array<{
                __typename?: "Flask";
                id: string;
                containerReference: string | null;
                flaskNumber: number | null;
                trayNumber: number | null;
                imageable: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type DeviceFlaskResultQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceFlaskResultQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        culture: {
            __typename?: "Culture";
            id: string;
            results: {
                __typename?: "ResultConnection";
                flaskIds: Array<string> | null;
            } | null;
            flasks: {
                __typename?: "FlaskConnection";
                nodes: Array<{
                    __typename?: "Flask";
                    id: string;
                    containerReference: string | null;
                    flaskNumber: number | null;
                    trayNumber: number | null;
                    imageable: boolean | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type DeviceResultsQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
    after?: InputMaybe<Scalars["String"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type DeviceResultsQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        name: string | null;
        results: {
            __typename?: "ResultConnection";
            totalCount: number;
            nodes: Array<{
                __typename?: "Result";
                id: string;
                type: ResultType | null;
                timestampISO: string | null;
                data:
                    | { __typename: "CountingChipImages" }
                    | {
                          __typename: "FlaskImages";
                          flask: {
                              __typename?: "ResultFlask";
                              flaskId: string | null;
                              number: number | null;
                          } | null;
                          images: Array<{
                              __typename?: "ImageData";
                              id: string;
                              timestampISO: string | null;
                          } | null> | null;
                          videos: Array<{
                              __typename?: "ImageData";
                              id: string;
                              timestampISO: string | null;
                          } | null> | null;
                      }
                    | null;
            } | null> | null;
            pageInfo: {
                __typename?: "PageInfo";
                startCursor: string | null;
                endCursor: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type CultureResultsQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
    after?: InputMaybe<Scalars["String"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CultureResultsQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        name: string | null;
        results: {
            __typename?: "ResultConnection";
            totalCount: number;
            nodes: Array<{
                __typename?: "Result";
                id: string;
                type: ResultType | null;
                timestampISO: string | null;
                data:
                    | { __typename: "CountingChipImages" }
                    | {
                          __typename: "FlaskImages";
                          flask: {
                              __typename?: "ResultFlask";
                              flaskId: string | null;
                              number: number | null;
                          } | null;
                          images: Array<{
                              __typename?: "ImageData";
                              id: string;
                              timestampISO: string | null;
                          } | null> | null;
                          videos: Array<{
                              __typename?: "ImageData";
                              id: string;
                              timestampISO: string | null;
                          } | null> | null;
                      }
                    | null;
            } | null> | null;
            pageInfo: {
                __typename?: "PageInfo";
                startCursor: string | null;
                endCursor: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type CreateInvocationMutationVariables = Exact<{
    input: CreateInvocationInput;
}>;

export type CreateInvocationMutation = {
    __typename?: "Mutation";
    createInvocation: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type EditInvocationParametersMutationVariables = Exact<{
    input: EditInvocationParametersInput;
}>;

export type EditInvocationParametersMutation = {
    __typename?: "Mutation";
    editInvocationParameters: {
        __typename?: "InvocationMutationResponse";
        ok: boolean;
        message: string;
        invocation: {
            __typename?: "Invocation";
            id: string;
            state: InvocationState | null;
            description: string | null;
            parameters: any | null;
        } | null;
    };
};

export type ControlInvocationMutationVariables = Exact<{
    input: ControlInvocationInput;
}>;

export type ControlInvocationMutation = {
    __typename?: "Mutation";
    controlInvocation: {
        __typename?: "InvocationMutationResponse";
        ok: boolean;
        message: string;
        invocation: {
            __typename?: "Invocation";
            id: string;
            state: InvocationState | null;
            operationId: string | null;
            description: string | null;
        } | null;
    };
};

export type DuplicateInvocationMutationVariables = Exact<{
    input: DuplicateInvocationInput;
}>;

export type DuplicateInvocationMutation = {
    __typename?: "Mutation";
    duplicateInvocation: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type DelayStepMutationVariables = Exact<{
    input: DelayStepInput;
}>;

export type DelayStepMutation = {
    __typename?: "Mutation";
    delayStep: {
        __typename?: "StepConnectionMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type ConfirmStepMutationLegacyMutationVariables = Exact<{
    input: ControlStepInput;
}>;

export type ConfirmStepMutationLegacyMutation = {
    __typename?: "Mutation";
    controlStep: {
        __typename?: "StepMutationResponse";
        ok: boolean;
        message: string;
        step: {
            __typename?: "Step";
            id: string;
            name: string | null;
            state: StepState | null;
            confirmationRequired: boolean | null;
            waitingForConfirmation: boolean | null;
            confirmed: boolean | null;
            timeStarted: string | null;
        } | null;
    };
};

export type ControlStepMutationLegacyMutationVariables = Exact<{
    input: ControlStepInput;
}>;

export type ControlStepMutationLegacyMutation = {
    __typename?: "Mutation";
    controlStep: {
        __typename?: "StepMutationResponse";
        ok: boolean;
        message: string;
        step: {
            __typename?: "Step";
            id: string;
            name: string | null;
            state: StepState | null;
        } | null;
    };
};

export type DuplicateStepMutationLegacyMutationVariables = Exact<{
    input: DuplicateStepInput;
}>;

export type DuplicateStepMutationLegacyMutation = {
    __typename?: "Mutation";
    duplicateStep: {
        __typename?: "DuplicateStepMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type UpdateStepRuntimeReferenceLegacyMutationVariables = Exact<{
    input: UpdateStepRuntimeReferenceInput;
}>;

export type UpdateStepRuntimeReferenceLegacyMutation = {
    __typename?: "Mutation";
    updateStepRuntimeReference: {
        __typename?: "StepMutationResponse";
        ok: boolean;
        message: string;
        step: {
            __typename: "Step";
            id: string;
            name: string | null;
            runtimeReference: {
                __typename: "StepRuntimeReference";
                runtimeInSeconds: number | null;
            } | null;
        } | null;
    };
};

export type AddOperationToStepMutationVariables = Exact<{
    input: AddOperationToStepInput;
}>;

export type AddOperationToStepMutation = {
    __typename?: "Mutation";
    addOperationToStep: {
        __typename?: "AddOperationToStepMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type DeviceCulturePollingInternalQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceCulturePollingInternalQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        culture: {
            __typename?: "Culture";
            id: string;
            isActive: boolean | null;
            state: CultureState | null;
            errorMessage: string | null;
            mintedByServer: boolean | null;
        } | null;
    } | null;
};

export type CulturePollingInternalQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CulturePollingInternalQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        isActive: boolean | null;
        state: CultureState | null;
        errorMessage: string | null;
        mintedByServer: boolean | null;
    } | null;
};

export type UpdateBalenaUuidMutationVariables = Exact<{
    updateDeviceUpdateDeviceInput: UpdateDeviceInput;
}>;

export type UpdateBalenaUuidMutation = {
    __typename?: "Mutation";
    updateDevice: {
        __typename?: "UpdateDeviceResponse";
        ok: boolean;
        message: string;
        device: {
            __typename?: "Device";
            id: string;
            name: string | null;
            balena: { __typename?: "Balena"; deviceUuid: string | null } | null;
        } | null;
    };
};

export type AllFleetsQueryVariables = Exact<{ [key: string]: never }>;

export type AllFleetsQuery = {
    __typename?: "Query";
    fleets: Array<{
        __typename?: "Fleet";
        id: number;
        name: string;
    } | null> | null;
};

export type MoveDeviceMutationVariables = Exact<{
    input: MoveDeviceInput;
}>;

export type MoveDeviceMutation = {
    __typename?: "Mutation";
    moveDevice: {
        __typename?: "MoveDeviceResponse";
        ok: boolean;
        fleetId: number | null;
        message: string;
    };
};

export type UpdateMaintenanceMutationVariables = Exact<{
    updateDeviceMaintenanceInput: UpdateDeviceMaintenanceInput;
}>;

export type UpdateMaintenanceMutation = {
    __typename?: "Mutation";
    updateDeviceMaintenance: {
        __typename?: "UpdateDeviceMaintenanceResponse";
        ok: boolean;
        message: string;
        device: {
            __typename?: "Device";
            id: string;
            isInMaintenance: boolean | null;
            maintenanceUntil: string | null;
        } | null;
    };
};

export type DeviceSettingQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceSettingQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        isOnline: boolean;
        isInMaintenance: boolean | null;
        maintenanceUntil: string | null;
        envVarConfigOk: boolean | null;
        name: string | null;
        status: DeviceStatus | null;
        url: string | null;
        balena: {
            __typename?: "Balena";
            deviceUuid: string | null;
            deviceName: string | null;
            deviceTypeName: string | null;
            isOnline: boolean | null;
            location: string | null;
            ipAddresses: Array<string> | null;
            macAddresses: Array<string> | null;
            publicAddress: string | null;
            lastConnected: string | null;
            fleetName: string | null;
            overrideLockEnabled: boolean | null;
        } | null;
        meta: {
            __typename?: "DeviceMetaInfo";
            boot: {
                __typename?: "DeviceBootInfo";
                startTime: number | null;
                uptime: number | null;
            } | null;
            network: {
                __typename?: "DeviceNetworkInfo";
                connectionType: NetworkConnectionType | null;
                localIp: string | null;
                networkName: string | null;
                uptime: number | null;
            } | null;
            equipment: {
                __typename?: "DeviceEquipmentInfo";
                kits: Array<{
                    __typename?: "DeviceKitInfo";
                    name: string | null;
                    commitHash: string | null;
                    environment: string | null;
                }>;
            } | null;
        } | null;
    } | null;
};

export type DeviceSoftwareVersionQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceSoftwareVersionQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        balena: {
            __typename?: "Balena";
            currentCommit: string | null;
            currentCommitDate: string | null;
            targetCommit: string | null;
            fleetName: string | null;
            fleetTargetCommit: string | null;
            pinned: boolean;
            updateAvailable: boolean;
            updating: boolean;
        } | null;
    } | null;
};

export type AddDeviceUserMutationVariables = Exact<{
    input: AddDeviceUserInput;
}>;

export type AddDeviceUserMutation = {
    __typename?: "Mutation";
    addDeviceUser: {
        __typename?: "ModifyDeviceUserResponse";
        ok: boolean;
        message: string;
        device: {
            __typename?: "Device";
            id: string;
            users: {
                __typename?: "DeviceUserConnection";
                totalCount: number;
                edges: Array<{
                    __typename?: "DeviceUserEdge";
                    id: string;
                    role: DeviceUserRole;
                    user: { __typename?: "User"; id: string };
                } | null> | null;
            } | null;
        } | null;
    };
};

export type UpdateDeviceUserMutationVariables = Exact<{
    input: UpdateDeviceUserInput;
}>;

export type UpdateDeviceUserMutation = {
    __typename?: "Mutation";
    updateDeviceUser: {
        __typename?: "ModifyDeviceUserResponse";
        ok: boolean;
        message: string;
        device: {
            __typename?: "Device";
            id: string;
            users: {
                __typename?: "DeviceUserConnection";
                totalCount: number;
                edges: Array<{
                    __typename?: "DeviceUserEdge";
                    id: string;
                    role: DeviceUserRole;
                    createdAt: string | null;
                    lastChangedAt: string | null;
                    user: { __typename?: "User"; id: string; email: string };
                    lastChangedBy: {
                        __typename?: "User";
                        email: string;
                        id: string;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    };
};

export type DeviceUsersQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceUsersQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        users: {
            __typename?: "DeviceUserConnection";
            edges: Array<{
                __typename?: "DeviceUserEdge";
                id: string;
                role: DeviceUserRole;
                createdAt: string | null;
                lastChangedAt: string | null;
                user: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    status: UserStatus | null;
                };
                lastChangedBy: {
                    __typename?: "User";
                    email: string;
                    id: string;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};

export type RemoveDeviceUserMutationVariables = Exact<{
    input: RemoveDeviceUserInput;
}>;

export type RemoveDeviceUserMutation = {
    __typename?: "Mutation";
    removeDeviceUser: {
        __typename?: "ModifyDeviceUserResponse";
        ok: boolean;
        message: string;
        device: {
            __typename?: "Device";
            id: string;
            users: {
                __typename?: "DeviceUserConnection";
                totalCount: number;
                edges: Array<{
                    __typename?: "DeviceUserEdge";
                    id: string;
                    role: DeviceUserRole;
                    user: { __typename?: "User"; id: string };
                } | null> | null;
            } | null;
        } | null;
    };
};

export type SetBalenaOverrideLockMutationVariables = Exact<{
    input: SetBalenaOverrideLockInput;
}>;

export type SetBalenaOverrideLockMutation = {
    __typename?: "Mutation";
    setBalenaOverrideLock: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type DeviceReleaseQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceReleaseQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        balena: {
            __typename?: "Balena";
            deviceUuid: string | null;
            availableReleases: Array<{
                __typename?: "BalenaRelease";
                commit: string | null;
                id: string | null;
                createdAt: string | null;
            } | null> | null;
        } | null;
    } | null;
};

export type PinDeviceToBalenaReleaseMutationVariables = Exact<{
    input: PinDeviceToBalenaReleaseInput;
}>;

export type PinDeviceToBalenaReleaseMutation = {
    __typename?: "Mutation";
    pinDeviceToBalenaRelease: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type UnpinDeviceFromBalenaReleaseMutationVariables = Exact<{
    input: UnpinDeviceFromBalenaReleaseInput;
}>;

export type UnpinDeviceFromBalenaReleaseMutation = {
    __typename?: "Mutation";
    unpinDeviceFromBalenaRelease: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type DevicesContainerQueryVariables = Exact<{ [key: string]: never }>;

export type DevicesContainerQuery = {
    __typename?: "Query";
    devices: Array<{
        __typename?: "Device";
        id: string;
        orgId: string | null;
        isOnline: boolean;
        name: string | null;
        status: DeviceStatus | null;
        migrated: boolean | null;
        balena: {
            __typename?: "Balena";
            deviceUuid: string | null;
            isOnline: boolean | null;
            lastConnected: string | null;
            updateAvailable: boolean;
            updating: boolean;
        } | null;
        users: {
            __typename?: "DeviceUserConnection";
            myAccess: {
                __typename?: "DeviceUserEdge";
                id: string;
                notificationsEnabled: boolean | null;
            } | null;
        } | null;
    } | null> | null;
};

export type DevicesContainerCultureQueryVariables = Exact<{
    [key: string]: never;
}>;

export type DevicesContainerCultureQuery = {
    __typename?: "Query";
    devices: Array<{
        __typename?: "Device";
        id: string;
        name: string | null;
        isOnline: boolean;
        culture: {
            __typename?: "Culture";
            id: string;
            name: string | null;
            state: CultureState | null;
            errorMessage: string | null;
            isWetTestCulture: boolean | null;
            schedule: {
                __typename?: "Schedule";
                isWaitingOnConfirmation: boolean | null;
                nextStep: { __typename?: "Step"; id: string } | null;
                nextProcedure: {
                    __typename?: "Procedure";
                    id: string;
                    waitingForConfirmation: boolean | null;
                } | null;
            } | null;
        } | null;
    } | null> | null;
};

export type ResultQueryVariables = Exact<{
    input: ResultInput;
}>;

export type ResultQuery = {
    __typename?: "Query";
    result: {
        __typename?: "Result";
        id: string;
        type: ResultType | null;
        timestampISO: string | null;
        device: { __typename?: "Device"; id: string };
        culture: {
            __typename?: "Culture";
            id: string;
            name: string | null;
        } | null;
        data:
            | {
                  __typename: "CountingChipImages";
                  countingChipId: string | null;
                  previousFlaskNumber: number | null;
                  nextFlaskNumber: number | null;
                  images: Array<{
                      __typename?: "ImageData";
                      id: string;
                      imageUrl: string | null;
                      timestampISO: string | null;
                      width: number | null;
                      height: number | null;
                      pixelScale: number | null;
                      fileFormat: string | null;
                      confluence: number | null;
                      position: {
                          __typename?: "Position";
                          absolutePosition: {
                              __typename?: "XYZ";
                              x: number | null;
                              y: number | null;
                              z: number | null;
                          } | null;
                          relativePosition: {
                              __typename?: "RelativePosition";
                              itemId: string | null;
                              positionName: string | null;
                              relativeCoords: {
                                  __typename?: "XYZ";
                                  x: number | null;
                                  y: number | null;
                                  z: number | null;
                              } | null;
                          } | null;
                      } | null;
                  } | null> | null;
              }
            | {
                  __typename: "FlaskImages";
                  flask: {
                      __typename?: "ResultFlask";
                      flaskId: string | null;
                      number: number | null;
                      size: number | null;
                  } | null;
                  images: Array<{
                      __typename?: "ImageData";
                      id: string;
                      imageUrl: string | null;
                      timestampISO: string | null;
                      width: number | null;
                      height: number | null;
                      pixelScale: number | null;
                      fileFormat: string | null;
                      confluence: number | null;
                      position: {
                          __typename?: "Position";
                          absolutePosition: {
                              __typename?: "XYZ";
                              x: number | null;
                              y: number | null;
                              z: number | null;
                          } | null;
                          relativePosition: {
                              __typename?: "RelativePosition";
                              itemId: string | null;
                              positionName: string | null;
                              relativeCoords: {
                                  __typename?: "XYZ";
                                  x: number | null;
                                  y: number | null;
                                  z: number | null;
                              } | null;
                          } | null;
                      } | null;
                  } | null> | null;
                  videos: Array<{
                      __typename?: "ImageData";
                      id: string;
                      imageUrl: string | null;
                      timestampISO: string | null;
                      width: number | null;
                      height: number | null;
                      pixelScale: number | null;
                      fileFormat: string | null;
                      confluence: number | null;
                      position: {
                          __typename?: "Position";
                          absolutePosition: {
                              __typename?: "XYZ";
                              x: number | null;
                              y: number | null;
                              z: number | null;
                          } | null;
                          relativePosition: {
                              __typename?: "RelativePosition";
                              itemId: string | null;
                              positionName: string | null;
                              relativeCoords: {
                                  __typename?: "XYZ";
                                  x: number | null;
                                  y: number | null;
                                  z: number | null;
                              } | null;
                          } | null;
                      } | null;
                  } | null> | null;
              }
            | null;
    } | null;
};

export type CaptureFragment = {
    __typename?: "ImageData";
    id: string;
    imageUrl: string | null;
    timestampISO: string | null;
    width: number | null;
    height: number | null;
    pixelScale: number | null;
    fileFormat: string | null;
    confluence: number | null;
    position: {
        __typename?: "Position";
        absolutePosition: {
            __typename?: "XYZ";
            x: number | null;
            y: number | null;
            z: number | null;
        } | null;
        relativePosition: {
            __typename?: "RelativePosition";
            itemId: string | null;
            positionName: string | null;
            relativeCoords: {
                __typename?: "XYZ";
                x: number | null;
                y: number | null;
                z: number | null;
            } | null;
        } | null;
    } | null;
};

export type DeviceImageResultQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceImageResultQuery = {
    __typename?: "Query";
    device: { __typename?: "Device"; id: string; name: string | null } | null;
};

export type NotificationChannelPreferenceMutationVariables = Exact<{
    global?: InputMaybe<Scalars["Boolean"]["input"]>;
    email?: InputMaybe<Scalars["Boolean"]["input"]>;
    sms?: InputMaybe<Scalars["Boolean"]["input"]>;
    inApp?: InputMaybe<Scalars["Boolean"]["input"]>;
    slack?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type NotificationChannelPreferenceMutation = {
    __typename?: "Mutation";
    updateGlobalNotificationsPreferences: {
        __typename?: "UpdateUserResponse";
        ok: boolean;
        message: string;
        user: {
            __typename?: "User";
            id: string;
            settings: {
                __typename?: "UserSettings";
                notifications: {
                    __typename?: "NotificationPreferences";
                    global: boolean;
                    email: {
                        __typename?: "GenericNotificationConfig";
                        enabled: boolean;
                    };
                    sms: {
                        __typename?: "GenericNotificationConfig";
                        enabled: boolean;
                    };
                    inApp: {
                        __typename?: "GenericNotificationConfig";
                        enabled: boolean;
                    };
                    slack: {
                        __typename?: "SlackChannelConfig";
                        isPaired: boolean;
                        enabled: boolean;
                        slackAppClientId: string;
                        slackAppScope: string;
                    };
                    events: Array<{
                        __typename?: "EventNotificationPreference";
                        event: NotificationEvent;
                        enabled: boolean;
                        email: boolean;
                        sms: boolean;
                        inApp: boolean;
                        slack: boolean;
                    }> | null;
                };
            } | null;
        } | null;
    };
};

export type NotificationEventPreferenceMutationVariables = Exact<{
    event: NotificationEvent;
    enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
    email?: InputMaybe<Scalars["Boolean"]["input"]>;
    sms?: InputMaybe<Scalars["Boolean"]["input"]>;
    inApp?: InputMaybe<Scalars["Boolean"]["input"]>;
    slack?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type NotificationEventPreferenceMutation = {
    __typename?: "Mutation";
    updateEventNotificationsPreference: {
        __typename?: "UpdateUserResponse";
        ok: boolean;
        message: string;
        user: {
            __typename?: "User";
            id: string;
            settings: {
                __typename?: "UserSettings";
                notifications: {
                    __typename?: "NotificationPreferences";
                    global: boolean;
                    email: {
                        __typename?: "GenericNotificationConfig";
                        enabled: boolean;
                    };
                    sms: {
                        __typename?: "GenericNotificationConfig";
                        enabled: boolean;
                    };
                    inApp: {
                        __typename?: "GenericNotificationConfig";
                        enabled: boolean;
                    };
                    slack: {
                        __typename?: "SlackChannelConfig";
                        isPaired: boolean;
                        enabled: boolean;
                        slackAppClientId: string;
                        slackAppScope: string;
                    };
                    events: Array<{
                        __typename?: "EventNotificationPreference";
                        enabled: boolean;
                        event: NotificationEvent;
                        email: boolean;
                        sms: boolean;
                        inApp: boolean;
                        slack: boolean;
                    }> | null;
                };
            } | null;
        } | null;
    };
};

export type UserNotificationPreferencesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type UserNotificationPreferencesQuery = {
    __typename?: "Query";
    user: {
        __typename?: "User";
        id: string;
        settings: {
            __typename?: "UserSettings";
            notifications: {
                __typename?: "NotificationPreferences";
                global: boolean;
                email: {
                    __typename?: "GenericNotificationConfig";
                    enabled: boolean;
                };
                sms: {
                    __typename?: "GenericNotificationConfig";
                    enabled: boolean;
                };
                inApp: {
                    __typename?: "GenericNotificationConfig";
                    enabled: boolean;
                };
                slack: {
                    __typename?: "SlackChannelConfig";
                    isPaired: boolean;
                    enabled: boolean;
                    slackAppClientId: string;
                    slackAppScope: string;
                };
                events: Array<{
                    __typename?: "EventNotificationPreference";
                    event: NotificationEvent;
                    enabled: boolean;
                    email: boolean;
                    sms: boolean;
                    inApp: boolean;
                    slack: boolean;
                }> | null;
            };
        } | null;
    } | null;
};

export type UpdateUserMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
    __typename?: "Mutation";
    updateUser: {
        __typename?: "UpdateUserResponse";
        ok: boolean;
        message: string;
        user: {
            __typename?: "User";
            id: string;
            email: string;
            firstName: string | null;
            lastName: string | null;
            phoneNumber: string | null;
        } | null;
    };
};

export type CreateApiKeyMutationVariables = Exact<{
    name?: InputMaybe<Scalars["String"]["input"]>;
    expiry?: InputMaybe<Scalars["String"]["input"]>;
    scopes?: InputMaybe<Array<InputMaybe<ApiScope>> | InputMaybe<ApiScope>>;
}>;

export type CreateApiKeyMutation = {
    __typename?: "Mutation";
    createApiKey: {
        __typename?: "CreateApiKeyResponse";
        ok: boolean;
        message: string;
        token: string | null;
        metadata: {
            __typename?: "ApiKey";
            id: string;
            name: string | null;
            created: string;
            expiry: string | null;
            scopes: Array<ApiScope | null> | null;
        } | null;
    };
};

export type RenameApiKeyMutationVariables = Exact<{
    keyId: Scalars["String"]["input"];
    newName: Scalars["String"]["input"];
}>;

export type RenameApiKeyMutation = {
    __typename?: "Mutation";
    renameApiKey: {
        __typename?: "RenameRevokeResponse";
        ok: boolean;
        message: string;
        user: {
            __typename?: "User";
            id: string;
            apiKeys: Array<{ __typename?: "ApiKey"; id: string } | null> | null;
        } | null;
    };
};

export type RevokeApiKeyMutationVariables = Exact<{
    keyId: Scalars["String"]["input"];
}>;

export type RevokeApiKeyMutation = {
    __typename?: "Mutation";
    revokeApiKey: {
        __typename?: "RenameRevokeResponse";
        ok: boolean;
        message: string;
        user: {
            __typename?: "User";
            id: string;
            apiKeys: Array<{ __typename?: "ApiKey"; id: string } | null> | null;
        } | null;
    };
};

export type ApiKeysQueryVariables = Exact<{ [key: string]: never }>;

export type ApiKeysQuery = {
    __typename?: "Query";
    user: {
        __typename?: "User";
        id: string;
        apiKeys: Array<{
            __typename?: "ApiKey";
            id: string;
            name: string | null;
            created: string;
            expiry: string | null;
            scopes: Array<ApiScope | null> | null;
            payload: any | null;
        } | null> | null;
    } | null;
};

export type UserSessionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserSessionsQuery = {
    __typename?: "Query";
    user: {
        __typename?: "User";
        id: string;
        sessions: Array<{
            __typename?: "UserSession";
            id: string;
            createdAt: string | null;
            expiresAt: string | null;
            userAgent: string | null;
        } | null> | null;
    } | null;
};

export type RevokeUserSessionsMutationVariables = Exact<{
    [key: string]: never;
}>;

export type RevokeUserSessionsMutation = {
    __typename?: "Mutation";
    revokeAllSessions: {
        __typename?: "UpdateUserResponse";
        ok: boolean;
        message: string;
        user: {
            __typename?: "User";
            id: string;
            sessions: Array<{
                __typename?: "UserSession";
                id: string;
                createdAt: string | null;
                expiresAt: string | null;
                userAgent: string | null;
            } | null> | null;
        } | null;
    };
};

export type SessionsForUserFragment = {
    __typename?: "User";
    id: string;
    sessions: Array<{
        __typename?: "UserSession";
        id: string;
        createdAt: string | null;
        expiresAt: string | null;
        userAgent: string | null;
    } | null> | null;
};

export type CultureImageResultsTestQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
    flaskIds?: InputMaybe<
        Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
    >;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CultureImageResultsTestQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        results: {
            __typename?: "ResultConnection";
            nodes: Array<{
                __typename?: "Result";
                id: string;
                type: ResultType | null;
                timestampISO: string | null;
            } | null> | null;
        } | null;
    } | null;
};

export type CultureControlMutationVariables = Exact<{
    input: ControlCultureInput;
}>;

export type CultureControlMutation = {
    __typename?: "Mutation";
    controlCulture: {
        __typename?: "ControlCultureResponse";
        ok: boolean;
        message: string;
        culture: {
            __typename?: "Culture";
            id: string;
            state: CultureState | null;
            isActive: boolean | null;
            errorMessage: string | null;
            mintedByServer: boolean | null;
        };
    };
};

export type ProcedureControlMutationVariables = Exact<{
    input: ControlProcedureInput;
}>;

export type ProcedureControlMutation = {
    __typename?: "Mutation";
    controlProcedure: {
        __typename?: "ControlProcedureResponse";
        ok: boolean;
        message: string;
        procedure: {
            __typename?: "Procedure";
            id: string;
            state: ProcedureState | null;
            confirmed: boolean | null;
            confirmedBy: {
                __typename?: "User";
                id: string;
                firstName: string | null;
                lastName: string | null;
            } | null;
        } | null;
    };
};

export type StepControlMutationVariables = Exact<{
    input: ControlStepInput;
}>;

export type StepControlMutation = {
    __typename?: "Mutation";
    controlStep: {
        __typename?: "StepMutationResponse";
        ok: boolean;
        message: string;
        step: {
            __typename?: "Step";
            id: string;
            state: StepState | null;
            confirmed: boolean | null;
            confirmedBy: {
                __typename?: "User";
                id: string;
                firstName: string | null;
                lastName: string | null;
            } | null;
        } | null;
    };
};

export type CultureContainersQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CultureContainersQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        isActive: boolean | null;
        device: { __typename?: "Device"; id: string } | null;
        bottles: {
            __typename?: "BottleConnection";
            nodes: Array<{
                __typename?: "Bottle";
                id: string;
                name: string | null;
                containerReference: string | null;
                liquidVolumeMl: number | null;
                totalVolumeMl: number | null;
            } | null> | null;
        } | null;
        flasks: {
            __typename?: "FlaskConnection";
            nodes: Array<{
                __typename?: "Flask";
                id: string;
                name: string | null;
                liquidVolumeMl: number | null;
                totalVolumeMl: number | null;
                trayNumber: number | null;
                flaskNumber: number | null;
                imageable: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type DeviceCultureControlQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceCultureControlQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        culture: {
            __typename?: "Culture";
            id: string;
            state: CultureState | null;
            name: string | null;
        } | null;
    } | null;
};

export type CultureOverviewSimpleQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CultureOverviewSimpleQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        state: CultureState | null;
        isActive: boolean | null;
        mintedByServer: boolean | null;
        device: { __typename?: "Device"; id: string; isOnline: boolean } | null;
    } | null;
};

export type ProcedureStepsQueryVariables = Exact<{
    procedureId: Scalars["String"]["input"];
}>;

export type ProcedureStepsQuery = {
    __typename?: "Query";
    procedure: {
        __typename?: "Procedure";
        id: string;
        name: string | null;
        state: ProcedureState | null;
        timePlanned: string | null;
        timeStarted: string | null;
        timeFinished: string | null;
        estimatedDuration: number | null;
        steps: {
            __typename?: "StepConnection";
            totalCount: number;
            nodes: Array<{
                __typename?: "Step";
                id: string;
                name: string | null;
                stageName: string | null;
                state: StepState | null;
                timeStarted: string | null;
                timeFinished: string | null;
                timePlanned: string | null;
                estimatedDuration: number | null;
                confirmationRequired: boolean | null;
                confirmationText: string | null;
                waitingForConfirmation: boolean | null;
                confirmed: boolean | null;
                errorMessage: string | null;
                runtimeReference: {
                    __typename?: "StepRuntimeReference";
                    runtimeInSeconds: number | null;
                    referenceStep: {
                        __typename?: "Step";
                        id: string;
                        name: string | null;
                        timeFinished: string | null;
                    } | null;
                } | null;
                invocationSummary: {
                    __typename?: "StepInvocationsSummary";
                    id: string;
                    numberOfFailedInvocations: number | null;
                } | null;
                invocations: {
                    __typename?: "InvocationConnection";
                    totalCount: number;
                } | null;
                confirmedBy: {
                    __typename?: "User";
                    id: string;
                    firstName: string | null;
                    lastName: string | null;
                } | null;
            }> | null;
        } | null;
    } | null;
};

export type StepFragment = {
    __typename?: "Step";
    id: string;
    name: string | null;
    stageName: string | null;
    state: StepState | null;
    timeStarted: string | null;
    timeFinished: string | null;
    timePlanned: string | null;
    estimatedDuration: number | null;
    confirmationRequired: boolean | null;
    confirmationText: string | null;
    waitingForConfirmation: boolean | null;
    confirmed: boolean | null;
    errorMessage: string | null;
    runtimeReference: {
        __typename?: "StepRuntimeReference";
        runtimeInSeconds: number | null;
        referenceStep: {
            __typename?: "Step";
            id: string;
            name: string | null;
            timeFinished: string | null;
        } | null;
    } | null;
    invocationSummary: {
        __typename?: "StepInvocationsSummary";
        id: string;
        numberOfFailedInvocations: number | null;
    } | null;
    invocations: {
        __typename?: "InvocationConnection";
        totalCount: number;
    } | null;
    confirmedBy: {
        __typename?: "User";
        id: string;
        firstName: string | null;
        lastName: string | null;
    } | null;
};

export type ScheduleWithAllProceduresFragment = {
    __typename?: "Schedule";
    isWaitingOnConfirmation: boolean | null;
    currentProcedure: { __typename?: "Procedure"; id: string } | null;
    nextProcedure: { __typename?: "Procedure"; id: string } | null;
    nextStep: { __typename?: "Step"; id: string } | null;
    nextInvocation: { __typename?: "Invocation"; id: string } | null;
    procedures: {
        __typename?: "ProcedureConnection";
        totalCount: number;
        nodes: Array<{
            __typename?: "Procedure";
            id: string;
            name: string | null;
            state: ProcedureState | null;
            estimatedDuration: number | null;
            timePlanned: string | null;
            timeStarted: string | null;
            timeFinished: string | null;
            confirmationRequired: boolean | null;
            confirmationText: string | null;
            waitingForConfirmation: boolean | null;
            confirmed: boolean | null;
            lastSentToDeviceAt: string | null;
            confirmedBy: {
                __typename?: "User";
                id: string;
                firstName: string | null;
                lastName: string | null;
            } | null;
            steps: { __typename?: "StepConnection"; totalCount: number } | null;
            stepsSummary: {
                __typename?: "ProcedureStepsSummary";
                id: string;
                numberOfStepsWithOutstandingConfirmation: number | null;
                hasStepsWithErrors: boolean | null;
            } | null;
        } | null> | null;
    } | null;
};

export type DeviceCultureScheduleQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceCultureScheduleQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        name: string | null;
        cultureId: string | null;
        culture: {
            __typename?: "Culture";
            id: string;
            name: string | null;
            state: CultureState | null;
            isActive: boolean | null;
            mintedByServer: boolean | null;
            isWetTestCulture: boolean | null;
            dayStartIndex: number | null;
            errorMessage: string | null;
            device: { __typename?: "Device"; id: string } | null;
            schedule: {
                __typename?: "Schedule";
                isWaitingOnConfirmation: boolean | null;
                currentProcedure: {
                    __typename?: "Procedure";
                    id: string;
                } | null;
                nextProcedure: { __typename?: "Procedure"; id: string } | null;
                nextStep: { __typename?: "Step"; id: string } | null;
                nextInvocation: {
                    __typename?: "Invocation";
                    id: string;
                } | null;
                procedures: {
                    __typename?: "ProcedureConnection";
                    totalCount: number;
                    nodes: Array<{
                        __typename?: "Procedure";
                        id: string;
                        name: string | null;
                        state: ProcedureState | null;
                        estimatedDuration: number | null;
                        timePlanned: string | null;
                        timeStarted: string | null;
                        timeFinished: string | null;
                        confirmationRequired: boolean | null;
                        confirmationText: string | null;
                        waitingForConfirmation: boolean | null;
                        confirmed: boolean | null;
                        lastSentToDeviceAt: string | null;
                        confirmedBy: {
                            __typename?: "User";
                            id: string;
                            firstName: string | null;
                            lastName: string | null;
                        } | null;
                        steps: {
                            __typename?: "StepConnection";
                            totalCount: number;
                        } | null;
                        stepsSummary: {
                            __typename?: "ProcedureStepsSummary";
                            id: string;
                            numberOfStepsWithOutstandingConfirmation:
                                | number
                                | null;
                            hasStepsWithErrors: boolean | null;
                        } | null;
                    } | null> | null;
                } | null;
            } | null;
            lastSimulationError: {
                __typename?: "CultureSimulationFailure";
                id: string;
                errorMessage: string | null;
                problemInvocation: {
                    __typename?: "Invocation";
                    id: string;
                } | null;
                problemStep: { __typename?: "Step"; id: string } | null;
                problemProcedure: {
                    __typename?: "Procedure";
                    id: string;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type CultureScheduleQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CultureScheduleQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        name: string | null;
        state: CultureState | null;
        isActive: boolean | null;
        mintedByServer: boolean | null;
        isWetTestCulture: boolean | null;
        dayStartIndex: number | null;
        errorMessage: string | null;
        device: { __typename?: "Device"; id: string } | null;
        schedule: {
            __typename?: "Schedule";
            isWaitingOnConfirmation: boolean | null;
            currentProcedure: { __typename?: "Procedure"; id: string } | null;
            nextProcedure: { __typename?: "Procedure"; id: string } | null;
            nextStep: { __typename?: "Step"; id: string } | null;
            nextInvocation: { __typename?: "Invocation"; id: string } | null;
            procedures: {
                __typename?: "ProcedureConnection";
                totalCount: number;
                nodes: Array<{
                    __typename?: "Procedure";
                    id: string;
                    name: string | null;
                    state: ProcedureState | null;
                    estimatedDuration: number | null;
                    timePlanned: string | null;
                    timeStarted: string | null;
                    timeFinished: string | null;
                    confirmationRequired: boolean | null;
                    confirmationText: string | null;
                    waitingForConfirmation: boolean | null;
                    confirmed: boolean | null;
                    lastSentToDeviceAt: string | null;
                    confirmedBy: {
                        __typename?: "User";
                        id: string;
                        firstName: string | null;
                        lastName: string | null;
                    } | null;
                    steps: {
                        __typename?: "StepConnection";
                        totalCount: number;
                    } | null;
                    stepsSummary: {
                        __typename?: "ProcedureStepsSummary";
                        id: string;
                        numberOfStepsWithOutstandingConfirmation: number | null;
                        hasStepsWithErrors: boolean | null;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
        lastSimulationError: {
            __typename?: "CultureSimulationFailure";
            id: string;
            errorMessage: string | null;
            problemInvocation: { __typename?: "Invocation"; id: string } | null;
            problemStep: { __typename?: "Step"; id: string } | null;
            problemProcedure: { __typename?: "Procedure"; id: string } | null;
        } | null;
    } | null;
};

export type DeviceCultureScheduleLegacyQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceCultureScheduleLegacyQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        name: string | null;
        cultureId: string | null;
        culture: {
            __typename?: "Culture";
            isActive: boolean | null;
            id: string;
            name: string | null;
            state: CultureState | null;
            schedule: {
                __typename?: "Schedule";
                nextStep: { __typename?: "Step"; id: string } | null;
                steps: {
                    __typename?: "StepConnection";
                    totalCount: number;
                    nodes: Array<{
                        __typename?: "Step";
                        id: string;
                        name: string | null;
                        state: StepState | null;
                        timePlanned: string | null;
                        timeStarted: string | null;
                        timeFinished: string | null;
                        estimatedDuration: number | null;
                        confirmationRequired: boolean | null;
                        confirmationText: string | null;
                        waitingForConfirmation: boolean | null;
                        confirmed: boolean | null;
                        errorMessage: string | null;
                        runtimeReference: {
                            __typename?: "StepRuntimeReference";
                            runtimeInSeconds: number | null;
                            referenceStep: {
                                __typename?: "Step";
                                id: string;
                                name: string | null;
                                timeFinished: string | null;
                            } | null;
                        } | null;
                        invocations: {
                            __typename?: "InvocationConnection";
                            totalCount: number;
                        } | null;
                    }> | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type CultureScheduleLegacyQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CultureScheduleLegacyQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        name: string | null;
        state: CultureState | null;
        isActive: boolean | null;
        device: { __typename?: "Device"; id: string } | null;
        schedule: {
            __typename?: "Schedule";
            nextStep: { __typename?: "Step"; id: string } | null;
            steps: {
                __typename?: "StepConnection";
                totalCount: number;
                nodes: Array<{
                    __typename?: "Step";
                    id: string;
                    name: string | null;
                    state: StepState | null;
                    timePlanned: string | null;
                    timeStarted: string | null;
                    timeFinished: string | null;
                    estimatedDuration: number | null;
                    confirmationRequired: boolean | null;
                    confirmationText: string | null;
                    waitingForConfirmation: boolean | null;
                    confirmed: boolean | null;
                    errorMessage: string | null;
                    runtimeReference: {
                        __typename?: "StepRuntimeReference";
                        runtimeInSeconds: number | null;
                        referenceStep: {
                            __typename?: "Step";
                            id: string;
                            name: string | null;
                            timeFinished: string | null;
                        } | null;
                    } | null;
                    invocations: {
                        __typename?: "InvocationConnection";
                        totalCount: number;
                    } | null;
                }> | null;
            } | null;
        } | null;
    } | null;
};

export type DeviceCultureStateQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceCultureStateQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        name: string | null;
        culture: {
            __typename?: "Culture";
            id: string;
            state: CultureState | null;
        } | null;
    } | null;
};

export type CultureStateQueryVariables = Exact<{
    cultureId: Scalars["String"]["input"];
}>;

export type CultureStateQuery = {
    __typename?: "Query";
    culture: {
        __typename?: "Culture";
        id: string;
        state: CultureState | null;
    } | null;
};

export type StepInvocationsQueryVariables = Exact<{
    stepId: Scalars["String"]["input"];
}>;

export type StepInvocationsQuery = {
    __typename?: "Query";
    step: {
        __typename?: "Step";
        id: string;
        name: string | null;
        state: StepState | null;
        timePlanned: string | null;
        timeStarted: string | null;
        timeFinished: string | null;
        estimatedDuration: number | null;
        confirmationRequired: boolean | null;
        confirmationText: string | null;
        waitingForConfirmation: boolean | null;
        confirmed: boolean | null;
        errorMessage: string | null;
        runtimeReference: {
            __typename?: "StepRuntimeReference";
            runtimeInSeconds: number | null;
            referenceStep: {
                __typename?: "Step";
                id: string;
                name: string | null;
                timeFinished: string | null;
            } | null;
        } | null;
        invocations: {
            __typename?: "InvocationConnection";
            totalCount: number;
            nodes: Array<{
                __typename?: "Invocation";
                id: string;
                operationId: string | null;
                description: string | null;
                state: InvocationState | null;
                finished: boolean | null;
                estimatedDuration: number | null;
                timePlanned: string | null;
                timeStarted: string | null;
                timeFinished: string | null;
                parameters: any | null;
                output: any | null;
                errorMessage: string | null;
            }> | null;
        } | null;
    } | null;
};

export type InvocationFragment = {
    __typename?: "Invocation";
    id: string;
    operationId: string | null;
    description: string | null;
    state: InvocationState | null;
    finished: boolean | null;
    estimatedDuration: number | null;
    timePlanned: string | null;
    timeStarted: string | null;
    timeFinished: string | null;
    parameters: any | null;
    output: any | null;
    errorMessage: string | null;
};

export type DeviceNameQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceNameQuery = {
    __typename?: "Query";
    device: { __typename?: "Device"; id: string; name: string | null } | null;
};

export type DeviceOperationsQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type DeviceOperationsQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        operations: Array<{
            __typename?: "Operation";
            operationId: string | null;
            name: string | null;
            description: string | null;
            parameters: Array<
                | {
                      __typename?: "BooleanParameter";
                      parameterId: string | null;
                      required: boolean | null;
                      name: string | null;
                      description: string | null;
                      defaultBoolean: boolean | null;
                  }
                | {
                      __typename?: "NumberParameter";
                      name: string | null;
                      parameterId: string | null;
                      required: boolean | null;
                      description: string | null;
                      units: string | null;
                      min: number | null;
                      max: number | null;
                      step: number | null;
                      defaultNumber: number | null;
                  }
                | {
                      __typename?: "StringArrayParameter";
                      parameterId: string | null;
                      required: boolean | null;
                      name: string | null;
                      description: string | null;
                      options: Array<string> | null;
                      defaultStringArray: Array<string> | null;
                  }
                | {
                      __typename?: "StringParameter";
                      parameterId: string | null;
                      required: boolean | null;
                      name: string | null;
                      description: string | null;
                      options: Array<string> | null;
                      defaultString: string | null;
                  }
            > | null;
        }> | null;
    } | null;
};

export type UseDeviceRoleQueryVariables = Exact<{
    deviceId: Scalars["String"]["input"];
}>;

export type UseDeviceRoleQuery = {
    __typename?: "Query";
    device: {
        __typename?: "Device";
        id: string;
        users: {
            __typename?: "DeviceUserConnection";
            edges: Array<{
                __typename?: "DeviceUserEdge";
                id: string;
                role: DeviceUserRole;
                user: { __typename?: "User"; id: string };
            } | null> | null;
        } | null;
    } | null;
};

export type EditBottleMutationVariables = Exact<{
    editBottleInput: EditBottleInput;
}>;

export type EditBottleMutation = {
    __typename?: "Mutation";
    editBottle: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type RefreshEditedBottleQueryVariables = Exact<{
    bottleId: Scalars["String"]["input"];
}>;

export type RefreshEditedBottleQuery = {
    __typename?: "Query";
    bottle: {
        __typename?: "Bottle";
        id: string;
        name: string | null;
        liquidVolumeMl: number | null;
        totalVolumeMl: number | null;
    } | null;
};

export type EditFlaskMutationVariables = Exact<{
    editFlaskInput: EditFlaskInput;
}>;

export type EditFlaskMutation = {
    __typename?: "Mutation";
    editFlask: {
        __typename?: "GenericMutationResponse";
        ok: boolean;
        message: string;
    };
};

export type RefreshEditedFlaskQueryVariables = Exact<{
    flaskId: Scalars["String"]["input"];
}>;

export type RefreshEditedFlaskQuery = {
    __typename?: "Query";
    flask: {
        __typename?: "Flask";
        id: string;
        name: string | null;
        liquidVolumeMl: number | null;
        totalVolumeMl: number | null;
    } | null;
};

export type AuthenticatedUserQueryVariables = Exact<{ [key: string]: never }>;

export type AuthenticatedUserQuery = {
    __typename?: "Query";
    user: {
        __typename?: "User";
        id: string;
        email: string;
        firstName: string | null;
        lastName: string | null;
        phoneNumber: string | null;
    } | null;
};

export type UserUiPreferencesQueryVariables = Exact<{
    userId: Scalars["String"]["input"];
}>;

export type UserUiPreferencesQuery = {
    __typename?: "Query";
    userUiPreferences: {
        __typename?: "UserUiPreferences";
        id: string;
        userId: string;
        values: any | null;
    } | null;
};

export type UpdateUserUiPreferencesMutationVariables = Exact<{
    input: UpdateUserUiPreferencesInput;
}>;

export type UpdateUserUiPreferencesMutation = {
    __typename?: "Mutation";
    updateUserUiPreferences: {
        __typename?: "UpdateUserUiPreferencesResponse";
        ok: boolean;
        userUiPreferences: {
            __typename?: "UserUiPreferences";
            id: string;
            userId: string;
            values: any | null;
        } | null;
    };
};

export const CultureSetupQueryDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CultureSetupQueryData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Culture" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isWetTestCulture" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "wetTestPrimaryProtocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "initialSeedProcedureData",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "flaskSeedGroups",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "doseMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskNumbers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSizeCm2",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "seedVesselCellSuspensionMl",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "protocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CultureSetupQueryDataFragment, unknown>;
export const CaptureFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "Capture" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "ImageData" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timestampISO" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "position" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "absolutePosition",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "x",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "y",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "z",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "relativePosition",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "itemId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "positionName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relativeCoords",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "x",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "y",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "z",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "width" } },
                    { kind: "Field", name: { kind: "Name", value: "height" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "pixelScale" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "fileFormat" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confluence" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CaptureFragment, unknown>;
export const SessionsForUserFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SessionsForUser" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "User" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "sessions" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expiresAt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userAgent" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionsForUserFragment, unknown>;
export const StepFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "Step" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Step" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "stageName" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeStarted" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeFinished" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timePlanned" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "estimatedDuration" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "runtimeReference" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceStep",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeFinished",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "runtimeInSeconds",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmationRequired" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmationText" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "waitingForConfirmation" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmed" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "errorMessage" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invocationSummary" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "numberOfFailedInvocations",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invocations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmedBy" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StepFragment, unknown>;
export const ScheduleWithAllProceduresFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ScheduleWithAllProcedures" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Schedule" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "isWaitingOnConfirmation",
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "currentProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextStep" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextInvocation" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "procedures" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "estimatedDuration",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timePlanned",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeStarted",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeFinished",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationText",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "waitingForConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "steps",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "stepsSummary",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "numberOfStepsWithOutstandingConfirmation",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasStepsWithErrors",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastSentToDeviceAt",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ScheduleWithAllProceduresFragment, unknown>;
export const InvocationFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "Invocation" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Invocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "operationId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "finished" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "estimatedDuration" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timePlanned" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeStarted" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeFinished" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "parameters" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "output" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "errorMessage" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InvocationFragment, unknown>;
export const NotificationsCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "NotificationsCount" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "read" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "archived" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "notifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "read",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "read",
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "archived",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationsCountQuery,
    NotificationsCountQueryVariables
>;
export const NotificationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "Notifications" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "before" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "last" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "archived" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "notifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "archived",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "last",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "last",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "before",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "before",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "event",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "subject",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "Result",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "id",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "device",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "name",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "read",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "readDate",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archivedDate",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "createdDate",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasPreviousPage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const NotificationsIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "NotificationsId" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "archived" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "notifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "archived",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationsIdQuery,
    NotificationsIdQueryVariables
>;
export const UpdateNotificationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateNotification" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "notificationId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "archived" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "read" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateNotification" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "notificationId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "notificationId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "archived",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "read",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "read",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "notification",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "event",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subject",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "Result",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "device",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "id",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "read",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "readDate",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archivedDate",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "createdDate",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
>;
export const UpdateNotificationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateNotifications" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "notificationIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "archived" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "read" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateNotifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "notificationIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "notificationIds",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "archived",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "read",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "read",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateNotificationsMutation,
    UpdateNotificationsMutationVariables
>;
export const PinnedDevicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "PinnedDevices" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "devices" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOnline" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PinnedDevicesQuery, PinnedDevicesQueryVariables>;
export const ServerStatusDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ServerStatus" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ServerStatusQuery, ServerStatusQueryVariables>;
export const DeviceOnlineChangeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceOnlineChange" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "devices" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOnline" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceOnlineChangeQuery,
    DeviceOnlineChangeQueryVariables
>;
export const UpdateDeviceNotificationPreferenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateDeviceNotificationPreference" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateDeviceNotificationPreferenceInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateDeviceNotificationPreference",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edge" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "notificationsEnabled",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateDeviceNotificationPreferenceMutation,
    UpdateDeviceNotificationPreferenceMutationVariables
>;
export const DeviceNotificationsEnabledDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceNotificationsEnabled" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "users" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "myAccess",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "notificationsEnabled",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceNotificationsEnabledQuery,
    DeviceNotificationsEnabledQueryVariables
>;
export const CulturePageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CulturePage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isActive" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isWetTestCulture",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "mintedByServer",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "errorMessage",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "schedule" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isWaitingOnConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nextStep",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nextProcedure",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CulturePageQuery, CulturePageQueryVariables>;
export const ConfirmProcedureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ConfirmProcedure" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ControlProcedureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlProcedure" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "procedure" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ConfirmProcedureMutation,
    ConfirmProcedureMutationVariables
>;
export const ProcedureStepsLightDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ProcedureStepsLight" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "procedure" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "id" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "steps" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "totalCount",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProcedureStepsLightQuery,
    ProcedureStepsLightQueryVariables
>;
export const EditProcedureStepsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "EditProcedureSteps" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "procedureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "steps" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "EditProcedureStepInput",
                                    },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editProcedureSteps" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "procedureId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "procedureId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "orderedSteps",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "steps",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "procedureSteps",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditProcedureStepsMutation,
    EditProcedureStepsMutationVariables
>;
export const DuplicateProcedureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DuplicateProcedure" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "DuplicateProcedureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "duplicateProcedure" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "ScheduleWithAllProcedures",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "newProcedure",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ScheduleWithAllProcedures" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Schedule" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "isWaitingOnConfirmation",
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "currentProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextStep" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextInvocation" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "procedures" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "estimatedDuration",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timePlanned",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeStarted",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeFinished",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationText",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "waitingForConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "steps",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "stepsSummary",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "numberOfStepsWithOutstandingConfirmation",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasStepsWithErrors",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastSentToDeviceAt",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DuplicateProcedureMutation,
    DuplicateProcedureMutationVariables
>;
export const RescheduleProcedureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RescheduleProcedure" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "RescheduleProcedureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "rescheduleProcedure" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "ScheduleWithAllProcedures",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ScheduleWithAllProcedures" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Schedule" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "isWaitingOnConfirmation",
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "currentProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextStep" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextInvocation" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "procedures" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "estimatedDuration",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timePlanned",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeStarted",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeFinished",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationText",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "waitingForConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "steps",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "stepsSummary",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "numberOfStepsWithOutstandingConfirmation",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasStepsWithErrors",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastSentToDeviceAt",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RescheduleProcedureMutation,
    RescheduleProcedureMutationVariables
>;
export const ConfirmStepDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ConfirmStep" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ControlStepInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "step" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "waitingForConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeStarted",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmStepMutation, ConfirmStepMutationVariables>;
export const DuplicateStepDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DuplicateStep" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DuplicateStepInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "duplicateStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DuplicateStepMutation,
    DuplicateStepMutationVariables
>;
export const UpdateStepRuntimeReferenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateStepRuntimeReference" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateStepRuntimeReferenceInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateStepRuntimeReference",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "step" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "__typename",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "runtimeReference",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "__typename",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runtimeInSeconds",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateStepRuntimeReferenceMutation,
    UpdateStepRuntimeReferenceMutationVariables
>;
export const DevicesFilterDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DevicesFilter" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "devices" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DevicesFilterQuery, DevicesFilterQueryVariables>;
export const CulturesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "Cultures" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "last" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "before" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filterBy" },
                    },
                    type: {
                        kind: "NamedType",
                        name: {
                            kind: "Name",
                            value: "CultureConnectionFilters",
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "cultures" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "last" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "last" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "before" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "before" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "first" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "first" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filterBy" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filterBy" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uploadDate",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isActive",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "device",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isWaitingOnConfirmation",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasPreviousPage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startCursor",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CulturesQuery, CulturesQueryVariables>;
export const DeviceOrgDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceOrg" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceOrgQuery, DeviceOrgQueryVariables>;
export const UpdateDeviceOrgDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateDeviceOrg" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "updateDeviceUpdateDeviceOrgInput",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateDeviceOrgInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateDeviceOrg" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: {
                                    kind: "Name",
                                    value: "updateDeviceOrgInput",
                                },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "updateDeviceUpdateDeviceOrgInput",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "orgId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateDeviceOrgMutation,
    UpdateDeviceOrgMutationVariables
>;
export const TriggerServerToDeviceSyncDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "TriggerServerToDeviceSync" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "TriggerServerToDeviceSyncInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "triggerServerToDeviceSync",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TriggerServerToDeviceSyncMutation,
    TriggerServerToDeviceSyncMutationVariables
>;
export const TriggerDeviceToServerSyncDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "TriggerDeviceToServerSync" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "TriggerDeviceToServerSyncInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "triggerDeviceToServerSync",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TriggerDeviceToServerSyncMutation,
    TriggerDeviceToServerSyncMutationVariables
>;
export const TriggerCultureSimulationForUpcomingInvocationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "TriggerCultureSimulationForUpcomingInvocations",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "TriggerCultureSimulationInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "triggerCultureSimulationForUpcomingInvocations",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TriggerCultureSimulationForUpcomingInvocationsMutation,
    TriggerCultureSimulationForUpcomingInvocationsMutationVariables
>;
export const DeviceSyncPageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceSyncPage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceSyncPageQuery, DeviceSyncPageQueryVariables>;
export const UseCultureFlaskConfluenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UseCultureFlaskConfluence" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "flaskId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDate" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dayStartIndex",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "results" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "flaskIds",
                                                        },
                                                        value: {
                                                            kind: "ListValue",
                                                            values: [
                                                                {
                                                                    kind: "Variable",
                                                                    name: {
                                                                        kind: "Name",
                                                                        value: "flaskId",
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "timestampISO",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "data",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "__typename",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "FlaskImages",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "flask",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "number",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "images",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "confluence",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "position",
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: "SelectionSet",
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: "Field",
                                                                                                                                    name: {
                                                                                                                                        kind: "Name",
                                                                                                                                        value: "relativePosition",
                                                                                                                                    },
                                                                                                                                    selectionSet:
                                                                                                                                        {
                                                                                                                                            kind: "SelectionSet",
                                                                                                                                            selections:
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        kind: "Field",
                                                                                                                                                        name: {
                                                                                                                                                            kind: "Name",
                                                                                                                                                            value: "relativeCoords",
                                                                                                                                                        },
                                                                                                                                                        selectionSet:
                                                                                                                                                            {
                                                                                                                                                                kind: "SelectionSet",
                                                                                                                                                                selections:
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            kind: "Field",
                                                                                                                                                                            name: {
                                                                                                                                                                                kind: "Name",
                                                                                                                                                                                value: "x",
                                                                                                                                                                            },
                                                                                                                                                                        },
                                                                                                                                                                        {
                                                                                                                                                                            kind: "Field",
                                                                                                                                                                            name: {
                                                                                                                                                                                kind: "Name",
                                                                                                                                                                                value: "y",
                                                                                                                                                                            },
                                                                                                                                                                        },
                                                                                                                                                                        {
                                                                                                                                                                            kind: "Field",
                                                                                                                                                                            name: {
                                                                                                                                                                                kind: "Name",
                                                                                                                                                                                value: "z",
                                                                                                                                                                            },
                                                                                                                                                                        },
                                                                                                                                                                    ],
                                                                                                                                                            },
                                                                                                                                                    },
                                                                                                                                                ],
                                                                                                                                        },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UseCultureFlaskConfluenceQuery,
    UseCultureFlaskConfluenceQueryVariables
>;
export const DeviceAutomatedCheckDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceAutomatedCheck" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "statusChecks",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "type",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "reason",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceAutomatedCheckQuery,
    DeviceAutomatedCheckQueryVariables
>;
export const ProtocolCellDataDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ProtocolCellData" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "protocolId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "protocol" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "protocolId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "initialCoatingTimeMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "initialSeedProcedureData",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "flaskSeedGroups",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "doseMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskNumbers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSizeCm2",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "seedVesselCellSuspensionMl",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProtocolCellDataQuery,
    ProtocolCellDataQueryVariables
>;
export const CreateProtocolV2Document = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateProtocolV2" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateProtocolInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createProtocol" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "protocol" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialCoatingTimeMinutes",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialSeedProcedureData",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSeedGroups",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "doseMl",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskNumbers",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskSizeCm2",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "seedVesselCellSuspensionMl",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startingBottles",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "liquidVolume",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reagentName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalVolume",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "wetTestProtocol",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialCoatingTimeMinutes",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialSeedProcedureData",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSeedGroups",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "doseMl",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskNumbers",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskSizeCm2",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "seedVesselCellSuspensionMl",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startingBottles",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "liquidVolume",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reagentName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalVolume",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateProtocolV2Mutation,
    CreateProtocolV2MutationVariables
>;
export const ReplaceWetTestWithCultureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ReplaceWetTestWithCulture" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ReplaceWetTestWithCultureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "replaceWetTestWithCulture",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ReplaceWetTestWithCultureMutation,
    ReplaceWetTestWithCultureMutationVariables
>;
export const CreateAndSyncCultureV2Document = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateAndSyncCultureV2" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateAndSyncCultureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createAndSyncCulture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateAndSyncCultureV2Mutation,
    CreateAndSyncCultureV2MutationVariables
>;
export const DevicePlatformDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DevicePlatform" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "platform" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DevicePlatformQuery, DevicePlatformQueryVariables>;
export const DeviceJobsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceJobs" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deviceJobs" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "type",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "progress",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updatedAt",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "requestedAt",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceJobsQuery, DeviceJobsQueryVariables>;
export const StartDeviceJobDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "StartDeviceJob" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "StartDeviceJobs" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "startDeviceJob" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartDeviceJobMutation,
    StartDeviceJobMutationVariables
>;
export const CancelDeviceJobDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CancelDeviceJob" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "CancelDeviceJob" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "cancelDeviceJob" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CancelDeviceJobMutation,
    CancelDeviceJobMutationVariables
>;
export const ProtocolStartingBottlesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ProtocolStartingBottles" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "protocolId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "protocol" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "protocolId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "startingBottles",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "liquidVolume",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "reagentName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "totalVolume",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProtocolStartingBottlesQuery,
    ProtocolStartingBottlesQueryVariables
>;
export const WetTestsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "WetTests" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "description",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isWetTestCulture",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "errorMessage",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "schedule" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "procedures",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nodes",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "estimatedDuration",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "timeStarted",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "timeFinished",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<WetTestsQuery, WetTestsQueryVariables>;
export const ProtocolUploadConditionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ProtocolUploadConditions" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filterBy" },
                    },
                    type: {
                        kind: "NamedType",
                        name: {
                            kind: "Name",
                            value: "CultureConnectionFilters",
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOnline" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "platform" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balenaUuid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balena" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "ipAddresses",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updateAvailable",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updating",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isWetTestCulture",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cultures" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "filterBy",
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProtocolUploadConditionsQuery,
    ProtocolUploadConditionsQueryVariables
>;
export const CreateAndSyncCultureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateAndSyncCulture" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateAndSyncCultureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createAndSyncCulture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "CultureSetupQueryData",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CultureSetupQueryData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Culture" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isWetTestCulture" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "wetTestPrimaryProtocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "initialSeedProcedureData",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "flaskSeedGroups",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "doseMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskNumbers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSizeCm2",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "seedVesselCellSuspensionMl",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "protocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateAndSyncCultureMutation,
    CreateAndSyncCultureMutationVariables
>;
export const CreateProtocolDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateProtocol" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateProtocolInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createProtocol" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "protocol" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialCoatingTimeMinutes",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialSeedProcedureData",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSeedGroups",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "doseMl",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskNumbers",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskSizeCm2",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "seedVesselCellSuspensionMl",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startingBottles",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "liquidVolume",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reagentName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalVolume",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "wetTestProtocol",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialCoatingTimeMinutes",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "initialSeedProcedureData",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSeedGroups",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "doseMl",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskNumbers",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskSizeCm2",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "seedVesselCellSuspensionMl",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startingBottles",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "liquidVolume",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reagentName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalVolume",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateProtocolMutation,
    CreateProtocolMutationVariables
>;
export const DeviceCultureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceCulture" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filterBy" },
                    },
                    type: {
                        kind: "NamedType",
                        name: {
                            kind: "Name",
                            value: "CultureConnectionFilters",
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "platform" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDate",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uploadDate",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "mintedByServer",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "plannedDuration",
                                                },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "CultureSetupQueryData",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "protocol",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nextProcedure",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastSimulationError",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "errorMessage",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cultures" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "filterBy",
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CultureSetupQueryData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Culture" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isWetTestCulture" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "wetTestPrimaryProtocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "initialSeedProcedureData",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "flaskSeedGroups",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "doseMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskNumbers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSizeCm2",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "seedVesselCellSuspensionMl",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "protocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceCultureQuery, DeviceCultureQueryVariables>;
export const CultureOverviewDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureOverview" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "description",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDate" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uploadDate" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "plannedDuration",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "errorMessage",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "mintedByServer",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "CultureSetupQueryData",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "platform",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "schedule" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nextProcedure",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "lastSimulationError",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CultureSetupQueryData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Culture" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isWetTestCulture" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "wetTestPrimaryProtocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "initialSeedProcedureData",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "flaskSeedGroups",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "doseMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskNumbers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskSizeCm2",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "seedVesselCellSuspensionMl",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "protocol" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureOverviewQuery,
    CultureOverviewQueryVariables
>;
export const DevicePageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DevicePage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOnline" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balena" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updateAvailable",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updating",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isActive",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "mintedByServer",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isWetTestCulture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isWaitingOnConfirmation",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nextStep",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nextProcedure",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "statusChecks",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "type",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "reason",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DevicePageQuery, DevicePageQueryVariables>;
export const CultureImageResultsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureImageResults" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "flaskIds" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "String" },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDate" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dayStartIndex",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "results" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "flaskIds",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskIds",
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "first",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "first",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "after",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "after",
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "type",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "timestampISO",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "data",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "__typename",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "FlaskImages",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "flask",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "flaskId",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "number",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "size",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "images",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "imageUrl",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "timestampISO",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "pixelScale",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "width",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "position",
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: "SelectionSet",
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: "Field",
                                                                                                                                    name: {
                                                                                                                                        kind: "Name",
                                                                                                                                        value: "absolutePosition",
                                                                                                                                    },
                                                                                                                                    selectionSet:
                                                                                                                                        {
                                                                                                                                            kind: "SelectionSet",
                                                                                                                                            selections:
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        kind: "Field",
                                                                                                                                                        name: {
                                                                                                                                                            kind: "Name",
                                                                                                                                                            value: "x",
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        kind: "Field",
                                                                                                                                                        name: {
                                                                                                                                                            kind: "Name",
                                                                                                                                                            value: "y",
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        kind: "Field",
                                                                                                                                                        name: {
                                                                                                                                                            kind: "Name",
                                                                                                                                                            value: "z",
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                ],
                                                                                                                                        },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: "Field",
                                                                                                                                    name: {
                                                                                                                                        kind: "Name",
                                                                                                                                        value: "relativePosition",
                                                                                                                                    },
                                                                                                                                    selectionSet:
                                                                                                                                        {
                                                                                                                                            kind: "SelectionSet",
                                                                                                                                            selections:
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        kind: "Field",
                                                                                                                                                        name: {
                                                                                                                                                            kind: "Name",
                                                                                                                                                            value: "itemId",
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        kind: "Field",
                                                                                                                                                        name: {
                                                                                                                                                            kind: "Name",
                                                                                                                                                            value: "positionName",
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        kind: "Field",
                                                                                                                                                        name: {
                                                                                                                                                            kind: "Name",
                                                                                                                                                            value: "relativeCoords",
                                                                                                                                                        },
                                                                                                                                                        selectionSet:
                                                                                                                                                            {
                                                                                                                                                                kind: "SelectionSet",
                                                                                                                                                                selections:
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            kind: "Field",
                                                                                                                                                                            name: {
                                                                                                                                                                                kind: "Name",
                                                                                                                                                                                value: "x",
                                                                                                                                                                            },
                                                                                                                                                                        },
                                                                                                                                                                        {
                                                                                                                                                                            kind: "Field",
                                                                                                                                                                            name: {
                                                                                                                                                                                kind: "Name",
                                                                                                                                                                                value: "y",
                                                                                                                                                                            },
                                                                                                                                                                        },
                                                                                                                                                                        {
                                                                                                                                                                            kind: "Field",
                                                                                                                                                                            name: {
                                                                                                                                                                                kind: "Name",
                                                                                                                                                                                value: "z",
                                                                                                                                                                            },
                                                                                                                                                                        },
                                                                                                                                                                    ],
                                                                                                                                                            },
                                                                                                                                                    },
                                                                                                                                                ],
                                                                                                                                        },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "pageInfo",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startCursor",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endCursor",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasNextPage",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasPreviousPage",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "totalCount",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureImageResultsQuery,
    CultureImageResultsQueryVariables
>;
export const CultureFlaskResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureFlaskResult" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "results" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "flaskIds",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flasks" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "containerReference",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "trayNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "imageable",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureFlaskResultQuery,
    CultureFlaskResultQueryVariables
>;
export const DeviceFlaskResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceFlaskResult" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "results",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskIds",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "flasks",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nodes",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "containerReference",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskNumber",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "trayNumber",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "imageable",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceFlaskResultQuery,
    DeviceFlaskResultQueryVariables
>;
export const DeviceResultsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceResults" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "results" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "after",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "after",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "first",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "first",
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "type",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "timestampISO",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "data",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "__typename",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "FlaskImages",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "flask",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "flaskId",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "number",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "images",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "timestampISO",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "videos",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "timestampISO",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "pageInfo",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startCursor",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endCursor",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasNextPage",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasPreviousPage",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "totalCount",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceResultsQuery, DeviceResultsQueryVariables>;
export const CultureResultsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureResults" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "results" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "after",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "after",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "first",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "first",
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "type",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "timestampISO",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "data",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "__typename",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "FlaskImages",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "flask",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "flaskId",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "number",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "images",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "timestampISO",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "videos",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "timestampISO",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "pageInfo",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startCursor",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endCursor",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasNextPage",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasPreviousPage",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "totalCount",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CultureResultsQuery, CultureResultsQueryVariables>;
export const CreateInvocationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateInvocation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateInvocationInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createInvocation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateInvocationMutation,
    CreateInvocationMutationVariables
>;
export const EditInvocationParametersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "EditInvocationParameters" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "EditInvocationParametersInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "editInvocationParameters",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "invocation" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "parameters",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditInvocationParametersMutation,
    EditInvocationParametersMutationVariables
>;
export const ControlInvocationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ControlInvocation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ControlInvocationInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlInvocation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "invocation" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "operationId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ControlInvocationMutation,
    ControlInvocationMutationVariables
>;
export const DuplicateInvocationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DuplicateInvocation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "DuplicateInvocationInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "duplicateInvocation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DuplicateInvocationMutation,
    DuplicateInvocationMutationVariables
>;
export const DelayStepDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DelayStep" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DelayStepInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "delayStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DelayStepMutation, DelayStepMutationVariables>;
export const ConfirmStepMutationLegacyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ConfirmStepMutationLegacy" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ControlStepInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "step" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "waitingForConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeStarted",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ConfirmStepMutationLegacyMutation,
    ConfirmStepMutationLegacyMutationVariables
>;
export const ControlStepMutationLegacyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ControlStepMutationLegacy" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ControlStepInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "step" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ControlStepMutationLegacyMutation,
    ControlStepMutationLegacyMutationVariables
>;
export const DuplicateStepMutationLegacyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DuplicateStepMutationLegacy" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DuplicateStepInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "duplicateStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DuplicateStepMutationLegacyMutation,
    DuplicateStepMutationLegacyMutationVariables
>;
export const UpdateStepRuntimeReferenceLegacyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateStepRuntimeReferenceLegacy" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateStepRuntimeReferenceInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateStepRuntimeReference",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "step" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "__typename",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "runtimeReference",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "__typename",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runtimeInSeconds",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateStepRuntimeReferenceLegacyMutation,
    UpdateStepRuntimeReferenceLegacyMutationVariables
>;
export const AddOperationToStepDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "AddOperationToStep" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "AddOperationToStepInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOperationToStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddOperationToStepMutation,
    AddOperationToStepMutationVariables
>;
export const DeviceCulturePollingInternalDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceCulturePollingInternal" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isActive",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "mintedByServer",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceCulturePollingInternalQuery,
    DeviceCulturePollingInternalQueryVariables
>;
export const CulturePollingInternalDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CulturePollingInternal" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isActive" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "errorMessage",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "mintedByServer",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CulturePollingInternalQuery,
    CulturePollingInternalQueryVariables
>;
export const UpdateBalenaUuidDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateBalenaUuid" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "updateDeviceUpdateDeviceInput",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "UpdateDeviceInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateDevice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: {
                                    kind: "Name",
                                    value: "updateDeviceInput",
                                },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "updateDeviceUpdateDeviceInput",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "balena",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "deviceUuid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateBalenaUuidMutation,
    UpdateBalenaUuidMutationVariables
>;
export const AllFleetsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "AllFleets" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "fleets" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AllFleetsQuery, AllFleetsQueryVariables>;
export const MoveDeviceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "MoveDevice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "MoveDeviceInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "moveDevice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fleetId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MoveDeviceMutation, MoveDeviceMutationVariables>;
export const UpdateMaintenanceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateMaintenance" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "updateDeviceMaintenanceInput",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateDeviceMaintenanceInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateDeviceMaintenance",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: {
                                    kind: "Name",
                                    value: "updateDeviceMaintenanceInput",
                                },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "updateDeviceMaintenanceInput",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isInMaintenance",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "maintenanceUntil",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateMaintenanceMutation,
    UpdateMaintenanceMutationVariables
>;
export const DeviceSettingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceSetting" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOnline" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isInMaintenance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "maintenanceUntil",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "envVarConfigOk",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balena" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "deviceUuid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "deviceName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "deviceTypeName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isOnline",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "location",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "ipAddresses",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "macAddresses",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "publicAddress",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastConnected",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "fleetName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "overrideLockEnabled",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "meta" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "boot",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startTime",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uptime",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "network",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "connectionType",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "localIp",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "networkName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uptime",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "equipment",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "kits",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "commitHash",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "environment",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceSettingQuery, DeviceSettingQueryVariables>;
export const DeviceSoftwareVersionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceSoftwareVersion" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balena" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "currentCommit",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "currentCommitDate",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "targetCommit",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "fleetName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "fleetTargetCommit",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "pinned",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updateAvailable",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updating",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceSoftwareVersionQuery,
    DeviceSoftwareVersionQueryVariables
>;
export const AddDeviceUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "AddDeviceUser" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "AddDeviceUserInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addDeviceUser" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "users",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "edges",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "role",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "user",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "id",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddDeviceUserMutation,
    AddDeviceUserMutationVariables
>;
export const UpdateDeviceUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateDeviceUser" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateDeviceUserInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateDeviceUser" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "users",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "edges",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "role",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "user",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "id",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "email",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "createdAt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "lastChangedBy",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "email",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "id",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "lastChangedAt",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateDeviceUserMutation,
    UpdateDeviceUserMutationVariables
>;
export const DeviceUsersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceUsers" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "users" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "edges",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "role",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "user",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "email",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "status",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "createdAt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastChangedBy",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "email",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastChangedAt",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceUsersQuery, DeviceUsersQueryVariables>;
export const RemoveDeviceUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RemoveDeviceUser" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "RemoveDeviceUserInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "removeDeviceUser" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "users",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "edges",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "role",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "user",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "id",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveDeviceUserMutation,
    RemoveDeviceUserMutationVariables
>;
export const SetBalenaOverrideLockDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "SetBalenaOverrideLock" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetBalenaOverrideLockInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setBalenaOverrideLock" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetBalenaOverrideLockMutation,
    SetBalenaOverrideLockMutationVariables
>;
export const DeviceReleaseDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceRelease" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balena" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "deviceUuid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "availableReleases",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "commit",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "createdAt",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceReleaseQuery, DeviceReleaseQueryVariables>;
export const PinDeviceToBalenaReleaseDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "PinDeviceToBalenaRelease" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "PinDeviceToBalenaReleaseInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "pinDeviceToBalenaRelease",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PinDeviceToBalenaReleaseMutation,
    PinDeviceToBalenaReleaseMutationVariables
>;
export const UnpinDeviceFromBalenaReleaseDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UnpinDeviceFromBalenaRelease" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UnpinDeviceFromBalenaReleaseInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "unpinDeviceFromBalenaRelease",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UnpinDeviceFromBalenaReleaseMutation,
    UnpinDeviceFromBalenaReleaseMutationVariables
>;
export const DevicesContainerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DevicesContainer" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "devices" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOnline" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "migrated" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balena" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "deviceUuid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isOnline",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastConnected",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updateAvailable",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "updating",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "users" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "myAccess",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "notificationsEnabled",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DevicesContainerQuery,
    DevicesContainerQueryVariables
>;
export const DevicesContainerCultureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DevicesContainerCulture" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "devices" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOnline" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isWetTestCulture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isWaitingOnConfirmation",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nextStep",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nextProcedure",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "waitingForConfirmation",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DevicesContainerCultureQuery,
    DevicesContainerCultureQueryVariables
>;
export const ResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "Result" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ResultInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "resultInput" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timestampISO",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "__typename",
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "FlaskImages",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flask",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "flaskId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "number",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "size",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "images",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "FragmentSpread",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "Capture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "videos",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "FragmentSpread",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "Capture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "CountingChipImages",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "countingChipId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "previousFlaskNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nextFlaskNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "images",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "FragmentSpread",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "Capture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "Capture" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "ImageData" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timestampISO" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "position" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "absolutePosition",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "x",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "y",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "z",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "relativePosition",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "itemId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "positionName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relativeCoords",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "x",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "y",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "z",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "width" } },
                    { kind: "Field", name: { kind: "Name", value: "height" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "pixelScale" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "fileFormat" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confluence" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResultQuery, ResultQueryVariables>;
export const DeviceImageResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceImageResult" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceImageResultQuery,
    DeviceImageResultQueryVariables
>;
export const NotificationChannelPreferenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "NotificationChannelPreference" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "global" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sms" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "inApp" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slack" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateGlobalNotificationsPreferences",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "global",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "global",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "sms",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "sms",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "inApp",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "inApp",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "slack",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "slack",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "settings",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "notifications",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "global",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "email",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "sms",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "inApp",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "slack",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "isPaired",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "slackAppClientId",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "slackAppScope",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "events",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "event",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "email",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "sms",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "inApp",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "slack",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationChannelPreferenceMutation,
    NotificationChannelPreferenceMutationVariables
>;
export const NotificationEventPreferenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "NotificationEventPreference" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "event" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "NotificationEvent" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "enabled" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sms" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "inApp" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slack" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateEventNotificationsPreference",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "event",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "event",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "enabled",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "enabled",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "sms",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "sms",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "inApp",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "inApp",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "slack",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "slack",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "settings",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "notifications",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "global",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "email",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "sms",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "inApp",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "slack",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "isPaired",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "slackAppClientId",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "slackAppScope",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "events",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "enabled",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "event",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "email",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "sms",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "inApp",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "slack",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationEventPreferenceMutation,
    NotificationEventPreferenceMutationVariables
>;
export const UserNotificationPreferencesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UserNotificationPreferences" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "settings" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "notifications",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "global",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "email",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "enabled",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "sms",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "enabled",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "inApp",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "enabled",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "slack",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "isPaired",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "enabled",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "slackAppClientId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "slackAppScope",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "events",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "event",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "enabled",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "email",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "sms",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "inApp",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "slack",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserNotificationPreferencesQuery,
    UserNotificationPreferencesQueryVariables
>;
export const UpdateUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateUser" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "UpdateUserInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateUser" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: {
                                    kind: "Name",
                                    value: "updateUserInput",
                                },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "firstName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateApiKeyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateApiKey" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "expiry" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "scopes" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ApiScope" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createApiKey" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: {
                                    kind: "Name",
                                    value: "createApiKeyInput",
                                },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "expiry",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "expiry",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "scopes",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "scopes",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "token" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expiry",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "scopes",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateApiKeyMutation,
    CreateApiKeyMutationVariables
>;
export const RenameApiKeyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RenameApiKey" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "keyId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "newName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "renameApiKey" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "keyId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "keyId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "newName" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "newName" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "apiKeys",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RenameApiKeyMutation,
    RenameApiKeyMutationVariables
>;
export const RevokeApiKeyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RevokeApiKey" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "keyId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "revokeApiKey" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "keyId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "keyId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "apiKeys",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RevokeApiKeyMutation,
    RevokeApiKeyMutationVariables
>;
export const ApiKeysDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ApiKeys" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "apiKeys" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expiry",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "scopes",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "payload",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ApiKeysQuery, ApiKeysQueryVariables>;
export const UserSessionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UserSessions" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SessionsForUser",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SessionsForUser" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "User" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "sessions" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expiresAt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userAgent" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserSessionsQuery, UserSessionsQueryVariables>;
export const RevokeUserSessionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RevokeUserSessions" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "revokeAllSessions" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "SessionsForUser",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SessionsForUser" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "User" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "sessions" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expiresAt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userAgent" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RevokeUserSessionsMutation,
    RevokeUserSessionsMutationVariables
>;
export const CultureImageResultsTestDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureImageResultsTest" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "flaskIds" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "String" },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "last" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "before" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "results" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "filterBy",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "flaskIds",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskIds",
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "last",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "last",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "before",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "before",
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "type",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "timestampISO",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureImageResultsTestQuery,
    CultureImageResultsTestQueryVariables
>;
export const CultureControlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CultureControl" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ControlCultureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlCulture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isActive",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "mintedByServer",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureControlMutation,
    CultureControlMutationVariables
>;
export const ProcedureControlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ProcedureControl" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ControlProcedureInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlProcedure" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "procedure" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProcedureControlMutation,
    ProcedureControlMutationVariables
>;
export const StepControlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "StepControl" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ControlStepInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "controlStep" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "step" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StepControlMutation, StepControlMutationVariables>;
export const CultureContainersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureContainers" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isActive" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "bottles" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "containerReference",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "liquidVolumeMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalVolumeMl",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flasks" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "liquidVolumeMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalVolumeMl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "trayNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "flaskNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "imageable",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureContainersQuery,
    CultureContainersQueryVariables
>;
export const DeviceCultureControlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceCultureControl" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceCultureControlQuery,
    DeviceCultureControlQueryVariables
>;
export const CultureOverviewSimpleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureOverviewSimple" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isActive" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "mintedByServer",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isOnline",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureOverviewSimpleQuery,
    CultureOverviewSimpleQueryVariables
>;
export const ProcedureStepsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ProcedureSteps" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "procedureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "procedure" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "procedureId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timePlanned",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeStarted",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeFinished",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "estimatedDuration",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "steps" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "totalCount",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "Step",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "Step" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Step" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "stageName" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeStarted" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeFinished" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timePlanned" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "estimatedDuration" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "runtimeReference" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceStep",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeFinished",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "runtimeInSeconds",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmationRequired" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmationText" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "waitingForConfirmation" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmed" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "errorMessage" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invocationSummary" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "numberOfFailedInvocations",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invocations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmedBy" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProcedureStepsQuery, ProcedureStepsQueryVariables>;
export const DeviceCultureScheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceCultureSchedule" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isActive",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "mintedByServer",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isWetTestCulture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "dayStartIndex",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "device",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "ScheduleWithAllProcedures",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastSimulationError",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "errorMessage",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "problemInvocation",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "problemStep",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "problemProcedure",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ScheduleWithAllProcedures" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Schedule" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "isWaitingOnConfirmation",
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "currentProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextStep" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextInvocation" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "procedures" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "estimatedDuration",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timePlanned",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeStarted",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeFinished",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationText",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "waitingForConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "steps",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "stepsSummary",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "numberOfStepsWithOutstandingConfirmation",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasStepsWithErrors",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastSentToDeviceAt",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceCultureScheduleQuery,
    DeviceCultureScheduleQueryVariables
>;
export const CultureScheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureSchedule" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isActive" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "mintedByServer",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isWetTestCulture",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dayStartIndex",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "errorMessage",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "schedule" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "ScheduleWithAllProcedures",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "lastSimulationError",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "errorMessage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "problemInvocation",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "problemStep",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "problemProcedure",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ScheduleWithAllProcedures" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Schedule" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "isWaitingOnConfirmation",
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "currentProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextProcedure" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextStep" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "nextInvocation" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "procedures" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "estimatedDuration",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timePlanned",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeStarted",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "timeFinished",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationRequired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmationText",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "waitingForConfirmation",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmedBy",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "steps",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "stepsSummary",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "numberOfStepsWithOutstandingConfirmation",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hasStepsWithErrors",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "lastSentToDeviceAt",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureScheduleQuery,
    CultureScheduleQueryVariables
>;
export const DeviceCultureScheduleLegacyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceCultureScheduleLegacy" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isActive",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nextStep",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "steps",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "totalCount",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "nodes",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "id",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "state",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "timePlanned",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "timeStarted",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "timeFinished",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "estimatedDuration",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "runtimeReference",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "referenceStep",
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: "SelectionSet",
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: "Field",
                                                                                                                                    name: {
                                                                                                                                        kind: "Name",
                                                                                                                                        value: "id",
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: "Field",
                                                                                                                                    name: {
                                                                                                                                        kind: "Name",
                                                                                                                                        value: "name",
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: "Field",
                                                                                                                                    name: {
                                                                                                                                        kind: "Name",
                                                                                                                                        value: "timeFinished",
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "runtimeInSeconds",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "confirmationRequired",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "confirmationText",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "waitingForConfirmation",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "confirmed",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "errorMessage",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "invocations",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "totalCount",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceCultureScheduleLegacyQuery,
    DeviceCultureScheduleLegacyQueryVariables
>;
export const CultureScheduleLegacyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureScheduleLegacy" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isActive" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "device" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "schedule" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nextStep",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "steps",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalCount",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "nodes",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "state",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "timePlanned",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "timeStarted",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "timeFinished",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "estimatedDuration",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "runtimeReference",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "referenceStep",
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: "SelectionSet",
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "id",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "name",
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: "Field",
                                                                                                                name: {
                                                                                                                    kind: "Name",
                                                                                                                    value: "timeFinished",
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "runtimeInSeconds",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "confirmationRequired",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "confirmationText",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "waitingForConfirmation",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "confirmed",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "errorMessage",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "invocations",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "totalCount",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CultureScheduleLegacyQuery,
    CultureScheduleLegacyQueryVariables
>;
export const DeviceCultureStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceCultureState" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "culture" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceCultureStateQuery,
    DeviceCultureStateQueryVariables
>;
export const CultureStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CultureState" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cultureId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "culture" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cultureId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CultureStateQuery, CultureStateQueryVariables>;
export const StepInvocationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "StepInvocations" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "stepId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "step" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "stepId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timePlanned",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeStarted",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeFinished",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "estimatedDuration",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "runtimeReference",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "referenceStep",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "timeFinished",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "runtimeInSeconds",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "confirmationRequired",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "confirmationText",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "waitingForConfirmation",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "errorMessage",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invocations",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "totalCount",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "nodes",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "Invocation",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "Invocation" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Invocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "operationId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "state" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "finished" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "estimatedDuration" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timePlanned" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeStarted" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "timeFinished" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "parameters" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "output" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "errorMessage" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StepInvocationsQuery,
    StepInvocationsQueryVariables
>;
export const DeviceNameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceName" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceNameQuery, DeviceNameQueryVariables>;
export const DeviceOperationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "DeviceOperations" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "operations" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "operationId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "parameters",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "NumberParameter",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "parameterId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "required",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "description",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        alias: {
                                                                            kind: "Name",
                                                                            value: "defaultNumber",
                                                                        },
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "default",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "units",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "min",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "max",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "step",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "StringParameter",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "parameterId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "required",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "description",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        alias: {
                                                                            kind: "Name",
                                                                            value: "defaultString",
                                                                        },
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "default",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "options",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "StringArrayParameter",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "parameterId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "required",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "description",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        alias: {
                                                                            kind: "Name",
                                                                            value: "defaultStringArray",
                                                                        },
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "default",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "options",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "BooleanParameter",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "parameterId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "required",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "description",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        alias: {
                                                                            kind: "Name",
                                                                            value: "defaultBoolean",
                                                                        },
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "default",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeviceOperationsQuery,
    DeviceOperationsQueryVariables
>;
export const UseDeviceRoleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UseDeviceRole" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "device" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deviceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "users" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "edges",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "role",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "user",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseDeviceRoleQuery, UseDeviceRoleQueryVariables>;
export const EditBottleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "EditBottle" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "editBottleInput" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "EditBottleInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editBottle" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "editBottleInput",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EditBottleMutation, EditBottleMutationVariables>;
export const RefreshEditedBottleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "RefreshEditedBottle" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bottleId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bottle" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bottleId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "liquidVolumeMl",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "totalVolumeMl",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RefreshEditedBottleQuery,
    RefreshEditedBottleQueryVariables
>;
export const EditFlaskDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "EditFlask" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "editFlaskInput" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "EditFlaskInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editFlask" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "editFlaskInput",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EditFlaskMutation, EditFlaskMutationVariables>;
export const RefreshEditedFlaskDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "RefreshEditedFlask" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "flaskId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "flask" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "flaskId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "liquidVolumeMl",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "totalVolumeMl",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RefreshEditedFlaskQuery,
    RefreshEditedFlaskQueryVariables
>;
export const AuthenticatedUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "AuthenticatedUser" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AuthenticatedUserQuery,
    AuthenticatedUserQueryVariables
>;
export const UserUiPreferencesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UserUiPreferences" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "userUiPreferences" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "userId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "userId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "values" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserUiPreferencesQuery,
    UserUiPreferencesQueryVariables
>;
export const UpdateUserUiPreferencesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateUserUiPreferences" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateUserUiPreferencesInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateUserUiPreferences",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "input" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ok" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "userUiPreferences",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "userId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "values",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateUserUiPreferencesMutation,
    UpdateUserUiPreferencesMutationVariables
>;
