import React, { ReactElement } from "react";

import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    useTheme,
} from "@mui/material";

import Callout from "components/common/Callout";
import Checkbox from "components/common/Checkbox";
import Txt from "components/common/Text";
import { dayjs, Dayjs, isInPast } from "services/date";
import { useRenderTimer } from "services/hooks/useRenderTimer";

interface SuggestedRescheduleContentProps {
    timePlanned: string | null;
    selectedSuggestion: number | null;
    setDesiredDate: React.Dispatch<React.SetStateAction<Dayjs | undefined>>;
    setSelectedSuggestion: React.Dispatch<React.SetStateAction<number | null>>;
}

export function SuggestedRescheduleContent(
    props: SuggestedRescheduleContentProps,
): ReactElement {
    useRenderTimer({ seconds: 10 });
    const {
        timePlanned,
        selectedSuggestion,
        setDesiredDate,
        setSelectedSuggestion,
    } = props;
    const recommendedTimeHours = [0.5, 1, 2, 6, 12];
    if (timePlanned === null) {
        return <Callout variant="error">Time planned is not defined</Callout>;
    }
    const currentTime = dayjs();
    const plannedTime = dayjs(timePlanned);
    const inPast = isInPast(timePlanned);
    const delayTimes = recommendedTimeHours.map(delay => {
        return (inPast ? currentTime : plannedTime).add(delay, "hours");
    });
    const tomorrow9am = dayjs(inPast ? undefined : plannedTime)
        .add(1, "day")
        .set("hour", 9)
        .set("minute", 0);
    const tomorrow4pm = dayjs(inPast ? undefined : plannedTime)
        .add(1, "day")
        .set("hour", 16)
        .set("minute", 0);
    const recommendedDelayTimes = [...delayTimes, tomorrow9am, tomorrow4pm];

    const handleToggle = (index: number) => () => {
        setSelectedSuggestion(oldValue => (index === oldValue ? null : index));
        setDesiredDate(recommendedDelayTimes[index]);
    };

    return (
        <List
            sx={{
                width: "100%",
                border: "2px blue",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}>
            {recommendedDelayTimes.map((value, index) => (
                <DelaySuggestionListItem
                    key={index}
                    value={value}
                    isSelected={selectedSuggestion === index}
                    handleItemSelected={handleToggle(index)}
                    timePlanned={timePlanned}
                />
            ))}
        </List>
    );
}

interface DelaySuggestionListItemProps {
    value: Dayjs;
    handleItemSelected: () => void;
    timePlanned: string;
    isSelected: boolean;
}

function DelaySuggestionListItem(
    props: DelaySuggestionListItemProps,
): ReactElement {
    const { value, isSelected, handleItemSelected, timePlanned } = props;
    const theme = useTheme();
    const delayText = dayjs(value).calendar(null, {
        sameDay: "[Today at] H:mm A", // The same day ( Today at 2:30 AM )
        nextDay: "[Tomorrow at] H:mm A", // The next day ( Tomorrow at 2:30 AM )
        nextWeek: "dddd H:mm A", // The next week ( Sunday at 2:30 AM )
        lastDay: "[Yesterday] H:mm A", // The day before ( Yesterday at 2:30 AM )
        lastWeek: "[Last] dddd H:mm A", // Last week ( Last Monday at 2:30 AM )
        sameElse: "DD/MM/YYYY H:mm A", // Everything else ( 7/10/2011 )
    });
    const realisticPlannedTime = isInPast(timePlanned)
        ? dayjs()
        : dayjs(timePlanned);
    const floatingPoint = true;
    const delayMinutes = Math.ceil(
        value.diff(realisticPlannedTime, "m", floatingPoint),
    );
    const delayHours = Math.ceil(
        value.diff(realisticPlannedTime, "h", floatingPoint),
    );
    const timeDiffText =
        delayMinutes < 60
            ? `Delay ${delayMinutes} minutes`
            : `Delay ${delayHours} hours`;
    return (
        <ListItem
            disablePadding
            sx={{
                backgroundColor: isSelected
                    ? theme.colours.brand.polar
                    : "white",
                marginTop: "10px",
                borderRadius: "5px",
                border: isSelected
                    ? `1px solid ${theme.colours.brand.greenDark}`
                    : `1px solid ${theme.colours.neutral[300]}`,
                maxWidth: "500px",
            }}>
            <ListItemButton
                sx={{ shadow: "1px red" }}
                role={undefined}
                onClick={handleItemSelected}
                dense>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={isSelected}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    <Txt font="secondary" emphasis level={8}>
                        {delayText}
                    </Txt>
                    <Txt
                        style={{
                            color: theme.colours.neutral[700],
                        }}
                        font="secondary"
                        level={8}>
                        {timeDiffText}
                    </Txt>
                </Box>
            </ListItemButton>
        </ListItem>
    );
}
