import { ReactElement } from "react";

import styled from "@emotion/styled";

import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import Txt from "components/common/Text";
import { useCultureStepInvocations } from "services/hooks/useCultureStepInvocations";

import { InvocationItem } from "../Invocations/InvocationItem";
import { log as parentLog } from "../Procedures/log";

export const log = parentLog.extend("StepInvocations");

export interface StepInvocationsProps {
    deviceId: string;
    stepId: string;
    isActive: boolean;
    numInvocations: number;
}

export function StepInvocations(props: StepInvocationsProps): ReactElement {
    const { isActive, stepId } = props;

    // If the Step is active (i.e. its running or is up next) then we poll
    const { data, loading, error } = useCultureStepInvocations(
        stepId,
        isActive ? 5_000 : 0,
    );
    const invocations = data?.step?.invocations?.nodes;

    if (error) log.error("Issue when loading invocations for step", error);

    let invocationItems: ReactElement | ReactElement[] = (
        <Callout message="This step does not have any operations" />
    );

    if (!invocations && loading) {
        invocationItems = Array(props.numInvocations)
            .fill(0)
            .map((_, i) => (
                <div key={i}>
                    {/** Wrap in a div here because Skeleton won't accept a margin-top override */}
                    <Skeleton height={30} />
                </div>
            ));
    }

    if (invocations && invocations.length > 0) {
        invocationItems = invocations.map(invocation => (
            <InvocationItem
                key={invocation.id}
                invocation={invocation}
                deviceId={props.deviceId}
            />
        ));
    }

    return (
        <StepInvocationsContainer>
            <InvocationsHeader>
                <Txt font="secondary" level={10} emphasis>
                    Operations
                </Txt>
            </InvocationsHeader>
            <InvocationsList>{invocationItems}</InvocationsList>
        </StepInvocationsContainer>
    );
}

const StepInvocationsContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

const InvocationsHeader = styled.div`
    padding: 4px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colours.neutral[700]};
`;

const InvocationsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > * {
        padding-bottom: 8px;
        border-bottom: 1px solid ${({ theme }) => theme.colours.neutral[300]};
    }
`;
