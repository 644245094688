import { useQuery, ApolloError } from "@apollo/client";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { sortBy } from "lodash";
import { useLocation } from "react-router-dom";

import { gql } from "__generated__/apollo";
import { removeNullables } from "services/utils";

const pinnedDevicesAtom = atomWithStorage<Record<string, null>>(
    "pinned_devices",
    // we do not use a JS 'Set()` here, despite it being the semantically
    // correct option, since it does not deserialise well out of JSON from the
    // local storage cache. So instead we use a plain old object and use the
    // keys (ignoring the values).
    {},
);

const PINNED_DEVICES_QUERY = gql(`
    query PinnedDevices {
        devices {
            id
            isOnline
            name
        }
    }
`);

type PinnedDevice = {
    id: string;
    name: string | null;
    isOnline: boolean;
    isSelected: boolean;
};

export function usePinnedDevices(): {
    loading: boolean;
    error: ApolloError | undefined;
    devices: PinnedDevice[];
    isDevicePinned: (id: string) => boolean;
    togglePinnedDevice: (id: string) => void;
} {
    const [pinnedDevices, updatePinnedDevices] = useAtom(pinnedDevicesAtom);
    const location = useLocation();
    const { loading, error, data } = useQuery(PINNED_DEVICES_QUERY, {
        returnPartialData: true, // https://github.com/apollographql/apollo-client/issues/7128#issuecomment-778686466
    });

    const devices = removeNullables(data?.devices ?? []);

    const isDevicePinned = (id: string): boolean => {
        const pinned = pinnedDevices[id];
        return pinned !== undefined;
    };
    return {
        loading,
        error,
        devices: sortBy(
            devices
                .filter(device =>
                    Object.keys(pinnedDevices).includes(device.id),
                )
                .map(device => ({
                    ...device,
                    isSelected: location.pathname.includes(device.id),
                })),
            "name",
        ),
        isDevicePinned,
        togglePinnedDevice: (id: string) => {
            const pinned = isDevicePinned(id);
            if (pinned) {
                updatePinnedDevices(devices => {
                    delete devices[id];
                    return { ...devices };
                });
            } else {
                updatePinnedDevices(devices => {
                    devices[id] = null; // a key just needs to be set with the ID
                    return { ...devices };
                });
            }
        },
    };
}
