import { useState, Fragment, ReactElement } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import Button from "components/common/Button";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { TextInput } from "components/common/TextInput";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from "../log";

export const log = parentLog.extend("ChangeBalenaUuidDialog");

interface ChangeBalenaUuidDialogProps {
    deviceId: string;
    balenaUuid: string;
}

export const UPDATE_BALENA_UUID_MUTATION = gql(`
    mutation UpdateBalenaUuid(
        $updateDeviceUpdateDeviceInput: UpdateDeviceInput!
    ) {
        updateDevice(updateDeviceInput: $updateDeviceUpdateDeviceInput) {
            ok
            message
            device {
                id
                name
                balena {
                    deviceUuid
                }
            }
        }
    }
`);

export default function ChangeBalenaUuidDialog(
    props: ChangeBalenaUuidDialogProps,
): ReactElement {
    const { deviceId, balenaUuid } = props;
    const [open, setOpen] = useState(false);
    const [balenaUuidValue, setBalenaUuidValue] = useState(balenaUuid);
    const { toast } = useToasts();
    const [updateBalenaUuidMutation] = useMutation(UPDATE_BALENA_UUID_MUTATION);
    const [changePending, setChangePending] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChangeUuid = async () => {
        log.debug("change uuid to", balenaUuidValue);
        let res;
        try {
            setChangePending(true);
            res = await updateBalenaUuidMutation({
                variables: {
                    updateDeviceUpdateDeviceInput: {
                        deviceId: deviceId,
                        balenaUuid: balenaUuidValue,
                    },
                },
            });
        } catch (e) {
            log.debug("Error encountered during api key creation mutation", e);
        }
        setChangePending(false);
        if (res) {
            log.debug(
                "received response from change balena uuid mutation",
                res,
            );
            if (res.data?.updateDevice?.ok) {
                toast.success("Balena UUID updated");
                setOpen(false);
                return;
            }
        }
        log.debug("no response received for change balena uuid mutation");
        toast.error("There was an issue changing Device Balena UUID");
    };

    return (
        <Fragment>
            <Button
                variant="tertiary"
                size="s"
                iconRight="edit"
                onClick={handleOpen}>
                Change
            </Button>
            <ConfirmationDialog
                variant="warning"
                open={open}
                onSecondaryAction={handleClose}
                onDismiss={handleClose}
                onPrimaryAction={handleChangeUuid}
                primaryButtonDisabled={changePending}
                title="Change Device's Balena UUID"
                body="Are you sure you want to change the Balena UUID of this Device? Metadata about this Device, as well as some ability to communicate with the Device, relies on using the correct Balena UUID."
                primaryButtonText="Confirm change">
                <TextInput
                    value={balenaUuidValue}
                    onChange={setBalenaUuidValue}
                    required={true}
                    fullWidth={true}
                />
            </ConfirmationDialog>
        </Fragment>
    );
}
