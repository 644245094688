import { ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { ProcedureControl, ProcedureState } from "__generated__/apollo/graphql";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { useToasts } from "components/common/toasts/useToasts";
import { Procedure } from "services/hooks/useCultureSchedule";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { Confirmed, NeedsConfirmation, Unconfirmable } from "../Confirmation";

import { log as parentLog } from "./log";

export const log = parentLog.extend("confirm-procedure");

function getProcedureConfirmationStatus(procedure: Procedure) {
    const confirmationRequired = procedure.confirmationRequired;
    const confirmed = procedure.confirmed;
    const procedurePlanned = procedure.state === ProcedureState.Planned;

    if (!confirmationRequired) {
        return "confirmationNotRequired";
    }

    if (confirmationRequired && procedurePlanned && !confirmed) {
        return "needsConfirmation";
    }

    if (confirmed && procedurePlanned) {
        return "canBeUnconfirmed";
    }

    if (confirmed) {
        return "confirmed";
    }
}

interface Props {
    procedure: Procedure;
    deviceId: string;
    children: ReactElement;
}

export default function ConfirmableProcedure({
    procedure,
    deviceId,
    children,
}: Props): ReactElement {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [executing, setExecuting] = useState(false);
    const { canOperate } = useDeviceRole(deviceId);

    const { toast } = useToasts();

    const [controlProcedure] = useMutation(CONFIRM_PROCEDURE_MUTATION);
    const executeAction = async () => {
        setExecuting(true);
        const toastId = toast.loading(
            `${
                procedure.confirmed ? "Unconfirming" : "Confirming"
            } procedure...`,
            {
                autoClose: false,
            },
        );
        try {
            const output = await controlProcedure({
                variables: {
                    input: {
                        control: procedure.confirmed
                            ? ProcedureControl.Unconfirm
                            : ProcedureControl.Confirm,
                        procedureId: procedure.id,
                    },
                },
            });

            if (output.data?.controlProcedure.ok) {
                toast.update(toastId, {
                    render: `Procedure ${
                        procedure.confirmed ? "unconfirmed" : "confirmed"
                    } `,
                    type: "success",
                    isLoading: false,
                });
                closeDialog();
            } else {
                throw new Error(output.data?.controlProcedure.message);
            }
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                render: `Failed to ${
                    procedure.confirmed ? "unconfirm" : "confirm"
                } procedure`,
                type: "error",
                isLoading: false,
            });
        } finally {
            setExecuting(false);
        }
    };

    const closeDialog = () => setDialogOpen(false);

    const onButtonClick = procedure.waitingForConfirmation
        ? executeAction
        : () => setDialogOpen(true);

    const confirmDisabled = !canOperate || executing;
    const tooltipMessage = canOperate
        ? undefined
        : "You do not have permission to control the device. Contact an admin to change your role.";

    const commonDialogProps = {
        variant: "warning" as const,
        open: dialogOpen,
        primaryButtonDisabled: confirmDisabled,
        onSecondaryAction: closeDialog,
        onDismiss: closeDialog,
        onPrimaryAction: executeAction,
        primaryButtonText: "Confirm",
        secondaryButtonText: "Cancel",
    };

    const confirmationStatus = getProcedureConfirmationStatus(procedure);

    if (confirmationStatus === "confirmationNotRequired") {
        return children;
    }

    if (confirmationStatus === "needsConfirmation") {
        return (
            <NeedsConfirmation
                confirmationText={procedure.confirmationText}
                onConfirmClick={onButtonClick}
                disabled={confirmDisabled}
                buttonTooltip={tooltipMessage}>
                {children}
                <ConfirmationDialog
                    {...commonDialogProps}
                    title="Confirm the Procedure"
                    body={
                        <>
                            <strong>
                                <em>{procedure.name}</em>
                            </strong>{" "}
                            is waiting for confirmation. Once confirmed, the
                            Device will automatically run this procedure at the
                            scheduled time.
                        </>
                    }
                />
            </NeedsConfirmation>
        );
    }

    if (confirmationStatus === "canBeUnconfirmed") {
        return (
            <Unconfirmable
                confirmationText={procedure.confirmationText}
                confirmedBy={procedure.confirmedBy}
                onUnconfirmClick={onButtonClick}
                disabled={confirmDisabled}
                buttonTooltip={tooltipMessage}>
                {children}
                <ConfirmationDialog
                    {...commonDialogProps}
                    title="Undo the Procedure confirmation"
                    body={
                        <>
                            <strong>
                                <em>{procedure.name}</em>
                            </strong>{" "}
                            will require confirmation again before it is run.
                        </>
                    }
                />
            </Unconfirmable>
        );
    }

    return (
        <Confirmed
            confirmationText={procedure.confirmationText}
            confirmedBy={procedure.confirmedBy}>
            {children}
        </Confirmed>
    );
}

const CONFIRM_PROCEDURE_MUTATION = gql(`
    mutation ConfirmProcedure($input: ControlProcedureInput!) {
        controlProcedure(input: $input) {
            ok
            message
            procedure {
                id
                name
                state
                confirmationRequired
                confirmed
                confirmedBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`);
