import { DeviceUserRole } from "__generated__/apollo/graphql";
import { Select, SelectProps } from "components/common/Select";
import { toTitleCase } from "services/string-utils";

const ROLE_OPTIONS = Object.values(DeviceUserRole).map(role => ({
    value: role,
    label: toTitleCase(role.toLowerCase()),
}));

export const SelectUserRole = (
    props: Omit<SelectProps<DeviceUserRole, false>, "options" | "multi">,
) => <Select multi={false} options={ROLE_OPTIONS} minWidth={120} {...props} />;
