import { ReactElement, ReactNode } from "react";

import { LogLevel } from "@optimizely/optimizely-sdk";
import {
    createInstance,
    OptimizelyDecideOption,
    OptimizelyDecision,
    OptimizelyProvider,
    useDecision,
} from "@optimizely/react-sdk";
import { throttle } from "lodash";

import { log as parentLog } from ".";
import { authentication, createAuthHeader } from "./auth";
import { config } from "./config";
import { useUser } from "./hooks/useUser";

const log = parentLog.extend("optimizely");

/**
 * The property key we use for the user email. This is a global constant as it
 * needs to match what we encode in the Optimizely Web UI and therefore should
 * not change.
 */
const USER_EMAIL_ATTRIBUTE = "userEmail";

/**
 * Initialisation of the Optimizely SDK.
 *
 * https://docs.developers.optimizely.com/full-stack/docs/initialize-sdk-react
 */
export const optimizely = createInstance({
    // sdkKey: // ! We DO NOT set this value
    datafileOptions: {
        // ! We manage the datafile ourselves with a custom DatafileManager so
        // that we can use strong authentication.
    },
    logger: {
        log: (level, message) => {
            switch (level) {
                case LogLevel.NOTSET:
                    log.debug(message);
                    return;
                case LogLevel.DEBUG:
                    log.debug(message);
                    return;
                case LogLevel.INFO:
                    log.info(message);
                    return;
                case LogLevel.WARNING:
                    log.warn(message);
                    return;
                case LogLevel.ERROR:
                    if (message.includes("No datafile specified")) {
                        log.warn(message);
                    } else {
                        log.error(message);
                    }
                    return;
            }
        },
    },
});

export class DatafileManager {
    private readonly DATAFILE_UPDATE_INTERVAL_MS = 30_000;
    private readonly DATAFILE_CACHE_LOCALSTORAGE_KEY = "datafile-manager-cache";

    private url = new URL(`/app/feature-flags`, config.serverUrl);
    private timer: NodeJS.Timeout | undefined;
    private log = parentLog.extend("DatafileManager");

    private constructor() {
        // constructor is private to prevent multiple instances
    }

    private static _instance: DatafileManager;
    /**
     *
     * @returns
     */
    static instance() {
        DatafileManager._instance = new DatafileManager();
        return DatafileManager._instance;
    }

    /**
     * Starts a recurring series of fetches to refresh the Datafile
     */
    start() {
        const cachedDatafile = this.getCachedDatafile();
        if (cachedDatafile) {
            log.info("Restoring datafile from cache");
            this.updateOptimizelyDatafile(cachedDatafile);
        }
        log.info("Starting interval for fetching datafile");
        this.syncDatafile({ recurring: true });
    }

    /**
     * Clear any pending calls of the internal fetch function
     */
    stop() {
        if (this.timer) clearInterval(this.timer);
    }

    /**
     * Clear the Datafile from the cache (used for logout etc.)
     */
    clearCache() {
        delete localStorage[this.DATAFILE_CACHE_LOCALSTORAGE_KEY];
        this.updateOptimizelyDatafile("");
    }

    /**
     * A method that automatically recalls itself after
     * `DATAFILE_UPDATE_INTERVAL_MS` has elapsed.
     *
     * Call `stop()` to cancel any scheduled calls.
     */
    syncDatafile({ recurring = false }: { recurring?: boolean } = {}) {
        try {
            this.fetchDatafile();
        } catch (error) {
            this.log.error("Datafile fetching error", error);
        }
        if (!recurring) return; // exit early

        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(
            this.syncDatafile.bind(this),
            this.DATAFILE_UPDATE_INTERVAL_MS,
        );
    }

    /**
     * Handles secure fetching and caching of the Datafile.
     */
    private fetchDatafile() {
        const authorization = createAuthHeader();
        fetch(this.url, {
            headers: { authorization },
            credentials: "include",
        })
            .then(response => response.text())
            .then(text => {
                const loggedIn = authentication.isAuthenticated();
                if (loggedIn && text) {
                    this.cacheDatafile(text);
                    this.updateOptimizelyDatafile(text);
                } else {
                    this.log.info(
                        "Discarding response for datafile since client not authenticated",
                    );
                }
            })
            .catch(error => {
                this.log.error("Error fetching datafile", error);
            });
    }

    /**
     * Stores a datafile in local cache
     * @param text Datafile as string
     */
    private cacheDatafile(text: string) {
        const encoded = btoa(text);
        localStorage[this.DATAFILE_CACHE_LOCALSTORAGE_KEY] = encoded;
    }

    /**
     * Retrieves the datafile from the local cache, if available.
     * @returns Datafile string or undefined
     */
    private getCachedDatafile(): string | undefined {
        const encoded = localStorage[this.DATAFILE_CACHE_LOCALSTORAGE_KEY];
        if (!encoded) return;
        try {
            const decoded = atob(encoded);
            return decoded;
        } catch (error) {
            this.log.error({ error }, "Could not decode data in local cache");
        }
    }

    /**
     * Updates the Optimizely client with a new Datafile string.
     * @param content String content of the datafile
     */
    private updateOptimizelyDatafile(content: string) {
        if (content) {
            (
                optimizely as unknown as {
                    _client: {
                        projectConfigManager: {
                            handleNewDatafile: (datafile: string) => void;
                        };
                    };
                }
            )["_client"].projectConfigManager.handleNewDatafile(content);
        } else {
            this.log.warn("Datafile content is empty", content);
        }
    }
}

/**
 * A debounced function for synchronising the DatafileManager for Optimizely.
 *
 * Used for log in.
 */
export const syncFeatureFlagDatafile = throttle(
    () => DatafileManager.instance().syncDatafile(),
    5_000, // minimum time between actual syncs
    { leading: true },
);

//
DatafileManager.instance().start();

/**
 * Gets all features flag IDs that are enabled for the current context (i.e.
 * logged in user).
 *
 * @returns String array of feature flag IDs
 */
export function getEnabledFeatures(): string[] {
    return optimizely.getEnabledFeatures();
}

/**
 * A wrapper provider for Feature Flags which is required for the associated
 * hooks to work.
 *
 * @param children Any ReactNode children
 */
export function FeatureFlagsProvider({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const { user } = useUser();
    return (
        <OptimizelyProvider
            optimizely={optimizely}
            user={{
                id: user?.id ?? null,
                attributes: {
                    [USER_EMAIL_ATTRIBUTE]: user?.email ?? null,
                },
            }}>
            {children}
        </OptimizelyProvider>
    );
}

type FeatureFlag = {
    enabled: boolean;
    variables: {
        [variableKey: string]: unknown;
    };
    decision?: OptimizelyDecision;
};

/**
 * Hook that determines whether a particular feature flag is enabled or not. The
 * hook returns a `FeatureFlag` object that can provide the caller an `enabled`
 * boolean to know whether to apply the feature or not.
 *
 * @example
 * const feature = useFeature("my_feature_key");
 *
 * if (feature.enabled) {
 *   return <p>Special new feature</p>;
 * } else {
 *   return null;
 * }
 *
 * @example
 * const feature = useFeature("my_feature_key_with_variable");
 * const displayed_message = feature.variables.displayed_message;
 *  return (
 *    <p>Variable value is {displayed_message}</p>
 *  );
 *
 * @param featureKey ID of feature flag
 * @returns FeatureFlag object: `{ enabled }`
 */
export function useFeature(
    featureKey: keyof typeof featureFlagReference,
    attributes?: Record<string, string>,
): FeatureFlag {
    const { user } = useUser({ fetchPolicy: "cache-only" });
    const [decision] = useDecision(
        featureKey,
        {
            autoUpdate: true,
            decideOptions: [OptimizelyDecideOption.INCLUDE_REASONS],
        },
        {
            overrideAttributes: {
                // we need to ensure user email continues to get passed as an
                // attribute by default, rather than being overriden by only
                // providing the additional attributes passed to this hook
                [USER_EMAIL_ATTRIBUTE]: user?.email ?? null,
                ...attributes,
            },
        },
    );

    return {
        enabled: decision.enabled,
        variables: decision.variables,
        decision,
    };
}

export const featureFlagReference: Record<
    string,
    { title: string; purpose: string; hidden?: boolean }
> = {
    test_message: {
        title: "A thing",
        purpose: "A small test feature that does not effect user experience.",
    },
    server_use_mqtt_in_device_proxy: {
        title: "Proxy uses MQTT",
        purpose: "Reliable next-generation communication via Mytos Cloud",
        hidden: true,
    },
    use_mqtt_in_device_manager: {
        title: "Device Manager uses MQTT",
        purpose: "Reliable next-generation communication via Mytos Cloud",
        hidden: true,
    },
    result_comparison_page: {
        title: "Result Comparison Page",
        purpose: "Compare imaging results over time side-by-side",
    },
    add_action_from_schedule: {
        // https://app.optimizely.com/v2/projects/20208804455/flags/manage/add_action_from_schedule
        title: "Add new Operations to the Schedule",
        purpose:
            "Edit the Schedule by dynamically adding new Operations during culture",
    },
    app_device_status_updates_alerts: {
        title: "Device status alerts",
        purpose: "Receive in-app alerts when a Device's status changes",
    },
    app_updates_skip_confirmation: {
        title: "Automatically update Mytos App",
        purpose: "Automatically keeps the Mytos App up-to-date",
    },
    device_advanced_tab: {
        title: "Device Advanced Tab",
        purpose: "Advanced low-level control and information about a Device",
    },
    app_device_settings_advanced: {
        title: "Advanced Information in Device Settings",
        purpose:
            "View advanced low-level information about a Device in the Settings tab",
    },
    app_results_feature: {
        title: "Results",
        purpose: "View Results from a Device, such as imaging events",
    },
    serial_page: {
        title: "Serial page",
        purpose:
            "A development UI for directly communicating with Mytos firmware over USB serial",
    },
    fetch_server_proxy_device_transport_mqtt: {
        title: "Server proxy to device using MQTT",
        purpose:
            "Determines whether the App should request that MQTT be used as a transport when proxying requests via the server to a device",
        hidden: true,
    },
    app_advanced_protocol_upload: {
        title: "Advanced Protocol Upload",
        purpose:
            "Access to additional features and granular control regarding advanced protocol upload",
    },
    app_device_images_settings: {
        title: "Device Images Settings",
        purpose: "Advanced control over the display of device images",
    },
    app_cultures_page: {
        title: "Cultures page",
        purpose:
            "Ability to view all cultures on the devices you have access to",
    },
    app_culture_page: {
        title: "Culture page",
        purpose: "Ability to view specific past cultures",
    },
    app_schedule_v2: {
        title: "Schedule Tab V2",
        purpose:
            "A new design for the Schedule view introducing 'Procedures' as a grouping of Steps.",
    },
    app_protocol_upload_to_server: {
        title: "Protocol upload to server",
        purpose:
            "A new method to upload protocols to the server instead of directly to the device",
    },
    dry_tests_kickoff_page: {
        title: "Dry Tests Kickoff Page",
        purpose:
            "Ability to view the Dry Tests Kickoff Page when creating a new culture",
    },
    dry_setup_kickoff_page: {
        title: "Dry Setup Kickoff Page",
        purpose:
            "Ability to view the Dry Setup Kickoff Page when creating a new culture",
    },
    cell_metadata_kickoff_pages: {
        title: "Cell Metadata Kickoff Pages",
        purpose:
            "Ability to view the Cell Preparation & Cell Line Information Pages when creating a new culture",
    },
    automated_status_checks_kickoff_page: {
        title: "Automated Status Checks Kickoff Page",
        purpose:
            "Ability to view the Automated Status Checks Kickoff Page when creating a new culture",
    },
    ability_to_skip_required_create_culture_checks: {
        title: "Ability to skip required create culture checks",
        purpose:
            "Users with this active feature flag can skip the requirements during the culture upload flow",
    },
    ability_to_create_api_keys: {
        title: "Ability to create API keys",
        purpose: "Users with this active feature flag can create API keys",
    },
    filter_devices_list_by_org_id: {
        title: "Filter Devices List by Org ID",
        purpose:
            "Users may use an additional filter on the Devices List based on the ID of the Org the Device belongs to",
    },
    procedure_and_step_error_badges: {
        title: "Procedure and Step Error Badges",
        purpose:
            "Users may see error badges on Steps that have failed invocations or Procedures that have failed Steps/Invocations",
    },
    wet_tests_beta: {
        title: "Wet Tests (Beta)",
        purpose:
            "Users will be taken through the Wet Test upload flow if Wet Tests are present in a protocol export",
    },
} satisfies FeatureFlagDescriptions;

type FeatureFlagDescriptions = {
    [k: string]:
        | undefined
        | { title: string; purpose: string; hidden?: boolean };
};
