import { memo, Fragment, ReactElement, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Collapse, Tooltip, useTheme } from "@mui/material";

import { StepState } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Txt from "components/common/Text";
import { dayjs, isToday } from "services/date";
import { LegacyStep } from "services/hooks/useCultureScheduleLegacy";
import { useWindowEventListener } from "services/hooks/useWindowEventListener";
import { deepCompareProps } from "services/react-utils";

import { ScheduleEvents } from "./CultureSchedule";
import { log as parentLog } from "./log";
import { StepItem } from "./Steps/StepItem";

export const log = parentLog.extend("DayBlock");

export interface DayBlockProps {
    deviceId: string;
    dayNum: number;
    date: string;
    steps: LegacyStep[];
    nextStepId: string | undefined;
}

export function DayBlock(props: DayBlockProps): ReactElement {
    const theme = useTheme();
    const [collapsed, setCollapsed] = useState(() => {
        const dateIsToday = isToday(props.date);
        if (dateIsToday) return false;
        return true;
    });

    useWindowEventListener({
        id: ScheduleEvents.Collapse,
        handler: () => setCollapsed(true),
    });
    useWindowEventListener({
        id: ScheduleEvents.Expand,
        handler: () => setCollapsed(false),
    });

    return (
        <div>
            <div
                css={css`
                    position: sticky;
                    top: 115px; // matches AppBar(64) + Schedule controls height
                    z-index: 5;
                    background: white;
                    ${theme.breakpoints.down("sm")} {
                        top: 56px; // matches AppBar height on small screen
                    }
                    margin-top: 4px;
                    padding-top: 8px;
                    padding-bottom: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}>
                <DayInfo {...props} />
                <Button
                    variant="tertiary"
                    iconRight={collapsed ? "expand" : "collapse"}
                    size="m"
                    colour={theme.colours.neutral[600]}
                    onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? "Show" : "Hide"}
                </Button>
            </div>
            <Collapse in={!collapsed} mountOnEnter unmountOnExit>
                <DayContent {...props} />
            </Collapse>
        </div>
    );
}

function DayInfo(props: DayBlockProps): ReactElement {
    const theme = useTheme();

    const formattedDate = dayjs(props.date).format("dddd D MMMM");
    const today = isToday(props.date);

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 24px;
                ${theme.breakpoints.down("sm")} {
                    column-gap: 12px;
                    row-gap: 4px;
                }
            `}>
            <Txt
                level={7}
                emphasis
                display="inline"
                style={{
                    color: today
                        ? theme.colours.neutral[900]
                        : theme.colours.neutral[700],
                }}>
                Day {props.dayNum} - {formattedDate}
            </Txt>
            <div
                css={css`
                    display: flex;
                    gap: 8px;
                `}>
                <StepsInfoBadge {...props} />
                <UnconfirmedStepsBadge steps={props.steps} />
            </div>
        </div>
    );
}

function UnconfirmedStepsBadge(props: { steps: LegacyStep[] }) {
    const theme = useTheme();
    const unconfirmedSteps = countUnconfirmedSteps(props.steps);

    if (!unconfirmedSteps) return <Fragment />;

    return (
        <InfoPill color={theme.colours.accent.alertOrange} opacity={0.5}>
            <StepIndicator color={theme.colours.accent.alertOrange}>
                <Icon name="touch-select" size="sm" />
                <Txt font="secondary" level={9} display="inline">
                    {unconfirmedSteps} confirmations
                </Txt>
            </StepIndicator>
        </InfoPill>
    );
}

function countUnconfirmedSteps(steps: LegacyStep[]): number {
    return steps.reduce((total, { confirmationRequired, confirmed, state }) => {
        const stepPlanned = state === StepState.Planned;
        if (confirmationRequired && stepPlanned && !confirmed) {
            total += 1;
        }
        return total;
    }, 0);
}

function StepsInfoBadge(props: DayBlockProps) {
    const theme = useTheme();

    const totalSteps = props.steps.length;

    const totalStepsComplete = props.steps.reduce(
        (total, step) =>
            step.state === StepState.Complete ? total + 1 : total,
        0,
    );
    const totalStepsFailed = props.steps.reduce(
        (total, step) => (step.state === StepState.Failed ? total + 1 : total),
        0,
    );
    const totalStepsPlanned = props.steps.reduce(
        (total, step) => (step.state === StepState.Planned ? total + 1 : total),
        0,
    );

    return (
        <InfoPill>
            {totalStepsPlanned > 0 && (
                <Tooltip title={`${totalStepsPlanned} planned`}>
                    <StepIndicator>
                        <Icon name="clock" size="sm" />
                        <Txt font="secondary" level={9} display="inline">
                            {totalStepsPlanned}
                        </Txt>
                    </StepIndicator>
                </Tooltip>
            )}
            {totalStepsComplete > 0 && (
                <Tooltip title={`${totalStepsComplete} complete`}>
                    <StepIndicator>
                        <Icon name="check" />
                        <Txt font="secondary" level={9} display="inline">
                            {totalStepsComplete}
                        </Txt>
                    </StepIndicator>
                </Tooltip>
            )}
            {totalStepsFailed > 0 && (
                <Tooltip title={`${totalStepsFailed} failed`}>
                    <StepIndicator
                        color={theme.colours.accent.errorRed}
                        style={{ opacity: 0.6 }}>
                        <Icon name="cross" size="sm" />
                        <Txt font="secondary" level={9} display="inline">
                            {totalStepsFailed}
                        </Txt>
                    </StepIndicator>
                </Tooltip>
            )}
            <StepIndicator color={theme.colours.neutral[400]}>
                <Txt font="secondary" level={9} display="inline">
                    {totalStepsPlanned +
                        totalStepsComplete +
                        totalStepsFailed ===
                    0
                        ? ""
                        : "of "}
                    {totalSteps} steps
                </Txt>
            </StepIndicator>
        </InfoPill>
    );
}

const InfoPill = styled.div<{ color?: string; opacity?: number }>`
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid
        ${({ theme, color }) => color ?? theme.colours.neutral[300]};
    border-radius: 20px;
    padding-left: 8px;
    padding-right: 8px;
    opacity: ${({ opacity }) => opacity ?? 1};
`;

const StepIndicator = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${({ theme, color }) => color ?? theme.colours.neutral[400]};
`;

const DayContent = memo(function DayContent(props: DayBlockProps) {
    return (
        <div
            id={`schedule-step-list-${props.dayNum}`}
            css={css`
                margin-top: 12px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            `}>
            {props.steps.map((step, i) => (
                <StepItem
                    deviceId={props.deviceId}
                    key={step.id}
                    index={i}
                    step={step}
                    isNextStep={props.nextStepId === step.id}
                />
            ))}
        </div>
    );
}, deepCompareProps(log));
