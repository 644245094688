import { isEqual } from "lodash";

import { Logger } from "./logger";

export function deepCompareProps<T>(log?: Logger) {
    return (prevProps: T, nextProps: T): boolean => {
        const equal = isEqual(prevProps, nextProps);
        if (equal) {
            log?.debug("Props have not changed, skipping rerender");
        } else {
            log?.debug("Props have changed, rendering content");
        }
        return equal;
    };
}
