import { ReactElement } from "react";

import { css } from "@emotion/react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import Txt from "./Text";

export interface ButtonGroupProps<V extends string> {
    value: V | null;
    onChange: (value: V) => void;
    values: V[];
}

export function ButtonGroup<V extends string>(
    props: ButtonGroupProps<V>,
): ReactElement {
    return (
        <ToggleButtonGroup
            onClick={e => e.stopPropagation()}
            value={props.value}
            exclusive
            onChange={(_, v) => {
                if (v) props.onChange(v);
            }}
            aria-label="unknown group"
            css={theme => css`
                & > button {
                    padding: 4px 8px;
                }
                border: 1px solid ${theme.colours.neutral[300]};
            `}>
            {props.values.map((val, i) => (
                <ToggleButton key={i} value={val} aria-label={val}>
                    <Txt font="secondary" level={10}>
                        {val}
                    </Txt>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}
