import { sortBy, zip } from "lodash";

import { objectFromEntriesTyped } from "services/object-utils";

import {
    CultureQueryFlaskImageData,
    CultureQueryFlaskImages,
    CultureQueryResultData,
} from "./DeviceImages";
import { log as parentLog } from "./log";
import { FLASK_POSITIONS, FlaskPosition } from "./ResultGrid";

const log = parentLog.extend("DeviceImagesUtils");

/**
 * Type guard for Result.data being of the "FlaskImages" type.
 */
export function isResultDataFlaskImages(
    resultData: CultureQueryResultData | undefined | null,
): resultData is CultureQueryFlaskImages {
    return !!resultData && resultData.__typename === "FlaskImages";
}

/**
 * Ordered list of flask positions to use when filtering images
 * to find the closest to the ideal capture positions.
 */
const ORDERED_FLASK_POSITIONS: FlaskPosition[] = [
    // Prefer the 4x quadrants
    "top_left",
    "top_right",
    "bottom_left",
    "bottom_right",
    // Then populate the remaining
    "middle_centre",
    "top_centre",
    "middle_left",
    "middle_right",
    "bottom_centre",
];

/**
 * Mapping between a named position and the relative position in the flask
 * as a percentage of the width or height of the flask. These percentages
 * are calculated from from the 3x3 capture positions for the t175 specified
 * in the device repository.
 */
const FLASK_POSITION_TO_RELATIVE_POSITION: Record<
    FlaskPosition,
    { x: number; y: number }
> = {
    top_left: {
        x: 0.17,
        y: 0.83,
    },
    top_centre: {
        x: 0.41,
        y: 0.83,
    },
    top_right: {
        x: 0.83,
        y: 0.83,
    },
    middle_left: {
        x: 0.17,
        y: 0.5,
    },
    middle_centre: {
        x: 0.59,
        y: 0.5,
    },
    middle_right: {
        x: 0.83,
        y: 0.5,
    },
    bottom_left: {
        x: 0.17,
        y: 0.24,
    },
    bottom_centre: {
        x: 0.5,
        y: 0.17,
    },
    bottom_right: {
        x: 0.83,
        y: 0.17,
    },
};

/**
 * The limits of relative x-y positions in a 100 capture
 */
type Bounds = {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
};

/**
 * Get the min and max positions of the flask capture positions in x-y plane
 * @param imageData - The list of flask images with position data
 */
export function getBounds(imageData: CultureQueryFlaskImages): Bounds {
    const images = imageData.images ?? [];
    return images.reduce(
        (bounds, image) => {
            const x = image?.position?.relativePosition?.relativeCoords?.x ?? 0;
            const y = image?.position?.relativePosition?.relativeCoords?.y ?? 0;
            if (x < bounds.xMin) {
                bounds.xMin = x;
            }
            if (x > bounds.xMax) {
                bounds.xMax = x;
            }
            if (y < bounds.yMin) {
                bounds.yMin = y;
            }
            if (y > bounds.yMax) {
                bounds.yMax = y;
            }
            return bounds;
        },
        {
            xMin: Number.MAX_SAFE_INTEGER,
            xMax: 0,
            yMin: Number.MAX_SAFE_INTEGER,
            yMax: 0,
        },
    );
}

/**
 * Dimensions of the flask in mm
 */
type FlaskDimensions = {
    width: number;
    height: number;
};

/**
 * Convert the capture positions bounds to a flask width and height.
 * This is not exact as we are assuming an equal buffer on each side
 * of the 10x10 capture positions. In future we may want to attach the
 * flask dimensions to the result data.
 * @param bounds - The bounds of the flask capture positions in x-y plane
 * @returns width and height of the flask
 */
export function inferFlaskSizeFromBounds(bounds: Bounds): FlaskDimensions {
    const width = bounds.xMax + bounds.xMin;
    const height = bounds.yMax + bounds.yMin;
    return { width, height };
}

/**
 * Get the ideal relative capture positions based on the flask dimensions
 * @param flaskDimensions - The width and height of the flask in mm
 * @returns The ideal relative capture positions in mm from bottom left corner of the flask
 */
export function getIdealCapturePositions(
    flaskDimensions: FlaskDimensions,
): Record<FlaskPosition, [number, number]> {
    return objectFromEntriesTyped(
        FLASK_POSITIONS.map(position => {
            const relativePosition =
                FLASK_POSITION_TO_RELATIVE_POSITION[position];
            const x = (flaskDimensions.width * relativePosition.x).toFixed(3);
            const y = (flaskDimensions.height * relativePosition.y).toFixed(3);
            return [position, [parseFloat(x), parseFloat(y)]];
        }),
    );
}

/**
 * Get the distance between an image and an ideal position
 * @param image - The image data
 * @param idealX - The ideal x position
 * @param idealY - The ideal y position
 * @returns The distance between the image and the ideal position
 */
export function getDistance(
    image: CultureQueryFlaskImageData | null,
    idealX: number,
    idealY: number,
): number {
    const x = image?.position?.relativePosition?.relativeCoords?.x ?? 0;
    const y = image?.position?.relativePosition?.relativeCoords?.y ?? 0;
    const distance = Math.sqrt((idealX - x) ** 2 + (idealY - y) ** 2);
    return distance;
}

/**
 * Filter data with a list of 100 images to 9 images that are closest to the
 * ideal image capture positions (top_left, top_centre... etc).
 * @param resultData - Result of 10x10 capture which will have 100 images to be filtered
 * @returns - a list of 9 images, the closest to the ideal capture positions
 */
export function findImagesClosestToFlaskPositionsRecord(
    resultData: CultureQueryFlaskImages,
): Partial<Record<FlaskPosition, CultureQueryFlaskImageData>> {
    const bounds = getBounds(resultData);
    const flaskDimensions = inferFlaskSizeFromBounds(bounds);
    const idealCapturePositions = getIdealCapturePositions(flaskDimensions);

    const imageData = resultData.images;
    if (!imageData) {
        return {};
    }

    const closestImages = ORDERED_FLASK_POSITIONS.reduce(
        (
            closestImages: CultureQueryFlaskImageData[],
            position,
        ): CultureQueryFlaskImageData[] => {
            const idealCapturePosition = idealCapturePositions[position];

            const imagesWithDistance = imageData.map(
                (
                    image: CultureQueryFlaskImageData | null,
                ): [CultureQueryFlaskImageData | null, number] => {
                    const [idealX, idealY] = idealCapturePosition;
                    return [image, getDistance(image, idealX, idealY)];
                },
            );

            const imagesWithDistanceSorted = sortBy(
                imagesWithDistance,
                ([, distance]) => distance,
            );

            const closestImageWithDistance = imagesWithDistanceSorted.find(
                ([image]) => image && !closestImages?.includes(image),
            );

            if (closestImageWithDistance) {
                const [closestImage] = closestImageWithDistance;

                if (closestImage) {
                    return [...closestImages, closestImage];
                }
            }

            return closestImages;
        },
        [],
    );

    const uniqueClosestImages = Array.from(new Set(closestImages));
    if (uniqueClosestImages.length !== FLASK_POSITIONS.length) {
        log.error(
            "A unique set of closest images was not found, ideal capture positions may need to be edited",
        );
    }

    return Object.fromEntries(zip(ORDERED_FLASK_POSITIONS, closestImages));
}

/**
 * Filter data with a list of 100 images to 9 images that are closest to the
 * ideal image capture positions (top_left, top_centre... etc).
 * @param resultData - Result of 10x10 capture which will have 100 images to be filtered
 * @returns - a list of 9 images, the closest to the ideal capture positions
 */
export function findImagesClosestToFlaskPositions(
    resultData: CultureQueryFlaskImages,
): Array<CultureQueryFlaskImageData | null> | null {
    const closestToFlaskPositionsRecord =
        findImagesClosestToFlaskPositionsRecord(resultData);

    // Return the closest images in the order of the flask positions array
    return FLASK_POSITIONS.map(
        flaskPosition => closestToFlaskPositionsRecord[flaskPosition] ?? null,
    );
}
