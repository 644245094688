import { ReactElement } from "react";

import { Tooltip } from "@mui/material";

import Button from "components/common/Button";
import { useDownloadCultureData } from "services/hooks/useCultureDataDownload";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { log as parentLog } from "./log";

export const log = parentLog.extend("DeviceCulture");

export interface DownloadDataButtonProps {
    disabled?: boolean;
    deviceId: string;
    cultureId: string | undefined;
}

export function DownloadDataButton(
    props: DownloadDataButtonProps,
): ReactElement {
    const { disabled, deviceId, cultureId } = props;
    const { downloadData } = useDownloadCultureData();
    // Authentication
    const { canOperate } = useDeviceRole(deviceId);
    const overrideTooltip =
        "You do not have permission to control the device. Contact an admin to change your role.";
    const tooltip = (!canOperate && overrideTooltip) || "";

    return (
        <Tooltip title={tooltip}>
            <Button
                disabled={!canOperate || disabled || cultureId === undefined}
                variant="secondary"
                iconLeft="download"
                style={{ minWidth: 200 }}
                onClick={async () => {
                    await downloadData(cultureId);
                }}>
                Download data
            </Button>
        </Tooltip>
    );
}
