import { startCase, camelCase } from "lodash";

/**
 * Takes a string and capitalises the first letter of every word (following a
 * space, including the first character).
 *
 * Example input: `lives-Down_by the.River`
 *
 * Example output: `Lives Down By The River`
 *
 * @param str String to manipulate
 * @returns Same string with case adjusted
 */
export function toTitleCase(str: string): string {
    return startCase(camelCase(str));
    //     return str.replace(/\w\S*/g, txt => {
    //         return txt.charAt(0).toUpperCase() + txt.substr(1);
    //     });
}

/**
 * Safely converts to a Number. It will either be a valid number, or if the
 * Number constructor determines it's not a number (NaN) then undefined is
 * returned.
 * @param str - A string
 * @returns A Number or undefined
 */
export function toNumber(str: string): number | undefined {
    const num = Number(str);
    return isNaN(num) ? undefined : num;
}

/**
 * Attempt to take a string and round it if numeric. If a non-numeric string was
 * provided then undefined is returned.
 * @param str - A numeric string
 * @param decimalPlaces - Number of decimal places precision
 * @returns a rounded string or undefined if `str` was not a number
 */
export function roundString(
    str: string,
    decimalPlaces: number,
): string | undefined {
    return toNumber(str)?.toFixed(decimalPlaces);
}

/**
 * Converts a decimal number to its percentage representation without decimal places.
 * @param decimal - The decimal number to be converted.
 * @param withSymbol - Include percentage symbol in string.
 * @returns The percentage representation as a string without decimal places.
 */
export function decimalToPercent(decimal: number, withSymbol = true): string {
    const percentage = Math.round(decimal * 100);
    return withSymbol ? `${percentage}%` : `${percentage}`;
}
