import { Fragment, ReactElement, useState } from "react";

import styled from "@emotion/styled";
import { Box, Tooltip } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { isNull } from "lodash";

import { ProcedureState } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";
import { InfoPill } from "components/common/Pills";
import Skeleton from "components/common/Skeleton";
import Txt from "components/common/Text";
import { dayjs, isInPast } from "services/date";
import { useFeature } from "services/feature-flags";
import { Procedure } from "services/hooks/useCultureSchedule";
import { useRenderTimer } from "services/hooks/useRenderTimer";
import { useUserUiPreferences } from "services/hooks/useUserUiPreferences";

import { ConfirmationPillBody } from "../DayHeader";

import ConfirmableProcedure from "./ConfirmableProcedure";
import { ProcedureControls } from "./ProcedureControls";
import { ProcedureStateIcon } from "./ProcedureStateIcon";
import { ProcedureSteps } from "./ProcedureSteps";

export interface ProcedureItemProps {
    /**
     * !WARNING do not use the device to infer culture information, use cultureId instead
     */
    deviceId: string;
    cultureId: string;
    index: number;
    procedure: Procedure | null;
    isNext: boolean;
    isCurrent: boolean;
    collidesWithPrevious: boolean;
    cultureIsActive: boolean;
    hasSimulationError: boolean;
    cultureIsWaitingForConfirmation: boolean | null;
    nextStepId?: string;
}

export function ProcedureItem(props: ProcedureItemProps): ReactElement {
    const {
        deviceId,
        cultureId,
        procedure,
        isCurrent,
        cultureIsWaitingForConfirmation,
        hasSimulationError,
    } = props;

    const { uiPreferences } = useUserUiPreferences();
    const errorBadgesEnabled = useFeature(
        "procedure_and_step_error_badges",
    ).enabled;

    const shouldBeOpenByDefault =
        hasSimulationError ||
        (isCurrent && uiPreferences?.scheduleDefaultExpandEverything);

    const [collapsed, setCollapsed] = useState(!shouldBeOpenByDefault);

    if (!procedure) return <Skeleton width="100%" height="90px" />;

    const totalSteps = procedure.steps?.totalCount;
    const totalStepsDisplayed =
        totalSteps === undefined
            ? ""
            : totalSteps === 1
              ? `1 step`
              : `${totalSteps} steps`;

    const outstandingConfirmations =
        procedure?.stepsSummary?.numberOfStepsWithOutstandingConfirmation ?? 0;

    const showStepErrorBadge =
        errorBadgesEnabled && procedure.stepsSummary?.hasStepsWithErrors;

    return (
        <ConfirmableProcedure procedure={procedure} deviceId={deviceId}>
            <ProcedureCard
                hasSimulationError={hasSimulationError}
                onClick={e => {
                    e.stopPropagation();
                    setCollapsed(!collapsed);
                }}>
                <StateIconPosition>
                    <ProcedureStateIcon
                        state={procedure.state}
                        needsConfirmation={
                            cultureIsWaitingForConfirmation && isCurrent
                        }
                        hasSimulationError={hasSimulationError}
                    />
                </StateIconPosition>
                <FlexSpaceBetween>
                    <ProcedureTimeStamp {...props} procedure={procedure} />

                    <div style={{ marginLeft: "auto" }}>
                        <ProcedureControls
                            deviceId={deviceId}
                            cultureId={cultureId}
                            procedure={procedure}
                        />
                    </div>
                </FlexSpaceBetween>

                <FlexSpaceBetween>
                    <FlexSpaceBetween>
                        <Txt font="secondary" level={6} display="inline">
                            {procedure.name}
                        </Txt>
                        {showStepErrorBadge && (
                            <Tooltip
                                title={
                                    "A step or invocation in this procedure was unsuccessful."
                                }
                                placement="right">
                                <Flex
                                // A non-React wrapper component is needed for MUI Tooltip to work
                                >
                                    <ErrorIcon name="alert-circle" />
                                </Flex>
                            </Tooltip>
                        )}
                    </FlexSpaceBetween>

                    <FlexSpaceBetween>
                        {uiPreferences?.scheduleShowProcedureRefreshTimes && (
                            <ProcedureLastSentToDeviceAt
                                lastSentToDeviceAt={
                                    procedure.lastSentToDeviceAt
                                }
                            />
                        )}
                        {outstandingConfirmations > 0 && (
                            <InfoPill>
                                <ConfirmationPillBody
                                    numberOfConfirmations={
                                        outstandingConfirmations
                                    }
                                />
                            </InfoPill>
                        )}
                        <Txt
                            display="inline"
                            font="secondary"
                            level={8}
                            emphasis
                            skeleton={totalSteps === undefined}
                            sx={{
                                color: theme => theme.colours.neutral[500],
                            }}>
                            {totalStepsDisplayed}
                        </Txt>
                    </FlexSpaceBetween>
                </FlexSpaceBetween>

                <Collapse
                    in={!collapsed}
                    style={{ width: "100%" }}
                    mountOnEnter
                    unmountOnExit>
                    <ProcedureSteps
                        deviceId={deviceId}
                        cultureId={cultureId}
                        procedureId={procedure.id}
                        numSteps={totalSteps ?? 1}
                        nextStepId={props.nextStepId}
                    />
                </Collapse>
                <CollapsibleTriggerRegion>
                    <Icon name={collapsed ? "chevron-down" : "chevron-up"} />
                </CollapsibleTriggerRegion>
            </ProcedureCard>
        </ConfirmableProcedure>
    );
}

function ProcedureLastSentToDeviceAt(props: {
    lastSentToDeviceAt?: string | null;
}) {
    if (!props.lastSentToDeviceAt) {
        return null;
    }
    return (
        <Tooltip
            title={`Last sent to device at ${dayjs(
                props.lastSentToDeviceAt,
            ).format("YYYY-MM-DD HH:mm:ss")}`}>
            <InfoPill>
                <Box
                    sx={{
                        color: theme => theme.colours.neutral[500],
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                    }}>
                    <Icon name="clock" size="sm" />
                    <Txt
                        font="secondary"
                        level={9}
                        sx={{ color: theme => theme.colours.neutral[500] }}>
                        {dayjs
                            .duration(dayjs().diff(props.lastSentToDeviceAt))
                            .humanize()}{" "}
                        ago
                    </Txt>
                </Box>
            </InfoPill>
        </Tooltip>
    );
}

function ProcedureTimeStamp(
    props: Omit<ProcedureItemProps, "procedure" | "deviceId" | "index"> & {
        procedure: Procedure;
    },
): ReactElement {
    const { procedure, cultureIsActive, isNext, collidesWithPrevious } = props;

    useRenderTimer({
        seconds: 10,
        enabled: cultureIsActive && isNext,
    });

    const timeSpanDisplayed = determineTimeSpanDisplayed(
        procedure,
        cultureIsActive,
    );
    if (!timeSpanDisplayed) return <Fragment />;
    const timeSpan = (
        <TimeSpanTxt font="secondary" level={8} emphasis>
            {determineTimeSpanDisplayed(procedure, cultureIsActive)}
        </TimeSpanTxt>
    );

    const { timePlanned } = procedure;
    const timePlannedIsPassed = timePlanned ? isInPast(timePlanned) : false;
    const timePlannedShouldNotHavePassed =
        timePlannedIsPassed && cultureIsActive && isNext;

    let tooltipMessage: string | null = null;

    if (timePlannedShouldNotHavePassed) {
        tooltipMessage =
            "Procedure is scheduled to have started, but hasn't yet.";
    } else if (
        collidesWithPrevious &&
        procedure.state === ProcedureState.Planned
    ) {
        tooltipMessage =
            "Procedure scheduled to start before previous ends, consider rescheduling.";
    }

    if (tooltipMessage) {
        return (
            <Tooltip
                enterDelay={0}
                arrow
                placement="right"
                title={tooltipMessage}>
                <Flex>
                    {timeSpan}
                    <AlertIcon name={"alert-circle"} size="md" />
                </Flex>
            </Tooltip>
        );
    } else {
        return timeSpan;
    }
}

const ProcedureCard = styled.div<{ hasSimulationError: boolean }>`
    position: relative;
    background-color: ${({ theme }) => theme.colours.neutral[100]};
    border: 1px solid
        ${({ theme, hasSimulationError }) =>
            hasSimulationError
                ? theme.colours.red[500]
                : theme.colours.neutral[300]};
    border-radius: 4px;
    cursor: pointer;

    /* Shadow / Shadow 1 */
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
`;

const StateIconPosition = styled.div`
    position: absolute;
    top: 18px;
    left: -8px;
`;

const FlexSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const AlertIcon = styled(Icon)`
    margin-left: 4px;
    margin-right: 4px;
    color: ${({ theme }) => theme.colours.orange[500]};
`;

const ErrorIcon = styled(Icon)`
    color: ${({ theme }) => theme.colours.red[500]};
`;

const CollapsibleTriggerRegion = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    color: ${({ theme }) => theme.colours.neutral[400]};
    padding-bottom: 4px;
`;
const TimeSpanTxt = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

function determineTimeSpanDisplayed(
    procedure: Procedure,
    cultureIsActive: boolean,
): ReactElement | string | undefined {
    const format = "HH:mm";
    const { state, timePlanned, timeStarted, timeFinished, estimatedDuration } =
        procedure;
    const timePlannedIsPassed = timePlanned ? isInPast(timePlanned) : false;
    const startTime = dayjs(timeStarted).format(format);
    const startTimePlanned = dayjs(timePlanned).format(format);
    const endTime = dayjs(timeFinished).format(format);
    const estimatedEndTime = estimatedDuration
        ? dayjs(timeStarted ?? timePlanned)
              .add(estimatedDuration, "milliseconds")
              .format(format)
        : null;

    switch (state) {
        case ProcedureState.Planned: {
            const timePlannedString = `${startTimePlanned} - ${estimatedEndTime}`;
            if (cultureIsActive && timePlannedIsPassed) {
                return (
                    <span title={timePlannedString}>As soon as possible</span>
                );
            }
            if (isNull(estimatedEndTime)) {
                return `${startTimePlanned} - unknown`;
            }

            return timePlannedString;
        }
        case ProcedureState.Complete:
            return `${startTime} - ${endTime}`;
        case ProcedureState.Running:
            if (isNull(estimatedEndTime)) {
                return `Running since ${startTime}`;
            }
            return `${startTime} - ${estimatedEndTime}`;
        case ProcedureState.Paused:
            if (isNull(estimatedEndTime)) {
                return `Began at ${startTime}`;
            }
            return `${startTime} - ${estimatedEndTime}`;
        case ProcedureState.Stalled:
            return `Began running at ${startTime}`;
        case ProcedureState.Unknown:
            return "Unknown";
        case ProcedureState.Removed:
            return undefined;
        case ProcedureState.Ignored:
            return undefined;
    }
}
