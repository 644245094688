import { useState } from "react";

import { Dayjs } from "dayjs";

import { ModalContent } from "components/common/Modal/Modal";
import { useToasts } from "components/common/toasts/useToasts";
import { guessTimezone, Timezone, today } from "services/date";

import { ConfirmView } from "../ConfirmView";
import { log as parentLog } from "../log";

export const log = parentLog.extend("CultureMetadataModalContent");

export function CultureMetadataModalContent({
    protocolName,
    onBackClick,
    onSubmit,
}: {
    onBackClick: () => void;
    protocolName?: string;
    onSubmit: (input: {
        cultureTimezone: Timezone;
        cultureStartDate: Dayjs;
    }) => Promise<void | Error>;
}) {
    const { toast } = useToasts();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);

    const [cultureStartDate, setCultureStartDate] = useState<Dayjs>(today());
    const [cultureTimezone, setCultureTimezone] = useState<
        Timezone | undefined
    >(guessTimezone());

    async function handleSubmit() {
        if (!cultureTimezone) {
            toast.error("Timezone is required");
            return;
        }

        setSubmitting(true);
        setError(undefined);
        try {
            const result = await onSubmit({
                cultureTimezone,
                cultureStartDate,
            });
            if (result instanceof Error) {
                setError(result);
            }
        } catch (err) {
            setError(err instanceof Error ? err : new Error("Unknown error"));
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <ModalContent
            title="New Culture"
            buttonLeft={{
                label: "Back",
                onClick: onBackClick,
            }}
            buttonRight={{
                label: "Confirm",
                disabled: submitting,
                onClick: handleSubmit,
            }}>
            <ConfirmView
                cultureStartDate={cultureStartDate}
                setCultureStartDate={setCultureStartDate}
                cultureTimezone={cultureTimezone}
                setCultureTimezone={setCultureTimezone}
                protocolName={protocolName}
                protocolUploadErrorMessage={error?.message}
            />
        </ModalContent>
    );
}
