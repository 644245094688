import { useEffect, ReactElement, useRef, useState } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import copy from "copy-to-clipboard";
import { saveAs } from "file-saver";
import Zoom from "react-medium-image-zoom";

import { downloadMediaBlob } from "components/common/Carousel/MoreInfoPanel";
import Icon, { IconName } from "components/common/Icon";
import { ImageScaleBar } from "components/common/ImageScaleBar";
import { MoreButton } from "components/common/MoreButton";
import Skeleton from "components/common/Skeleton";
import { useToasts } from "components/common/toasts/useToasts";
import { useQueryString } from "services/hooks/useQueryString";
import { isDefined } from "services/utils";

import "react-medium-image-zoom/dist/styles.css";
import { CultureQueryFlaskImageData } from "./DeviceImages";

export function GridImage(props: {
    value: CultureQueryFlaskImageData | null;
    hidden?: boolean;
}): ReactElement {
    const { value, hidden = false } = props;

    if (!value) {
        return <BlankImage inheritHeight={hidden} />;
    } else if (hidden) {
        return <BlankImage inheritHeight icon="eye-closed" />;
    } else {
        return (
            <div
                css={css`
                    position: relative;
                `}>
                <Image image={value} />
            </div>
        );
    }
}

const IMAGE_ID_PARAM_KEY = "i";

function Image(props: {
    image: CultureQueryFlaskImageData | null;
}): ReactElement {
    const src = props.image?.imageUrl ?? undefined;

    const { toast } = useToasts();
    const [loaded, setLoaded] = useState(false);
    const handleImageLoad = () => setLoaded(true);

    const imageRef = useRef<HTMLImageElement>(null);
    const defaultImageWidth = 340;

    const imageWidth = props.image?.width;
    const pixelScale = props.image?.pixelScale;
    const showImageScaleBar =
        isDefined(imageWidth) && isDefined(pixelScale) && loaded;

    // console.log({ objectUrl, src });
    const ts = props.image?.timestampISO;
    const thisId = ts ? String(new Date(ts).valueOf()) : undefined;

    const { query, replaceQueryString } = useQueryString();
    const queryImageId = query.get(IMAGE_ID_PARAM_KEY);

    useEffect(() => {
        if (queryImageId === thisId) {
            // Run focus once on mount to bring into view
            document.getElementById(queryImageId ?? "")?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryImageId]);

    const setSelectedImage = () => {
        if (thisId) {
            document.getElementById(thisId ?? "")?.focus();
            query.set(IMAGE_ID_PARAM_KEY, thisId);
            replaceQueryString(query);
        }
    };

    return (
        <div
            id={thisId}
            tabIndex={0}
            css={theme => css`
                border-radius: 4px;
                overflow: hidden;
                &:focus {
                    /* border: 1px solid blue; */
                    outline: 2px solid ${theme.colours.brand.blueDark};
                    outline-offset: 1px;
                }
                width: ${loaded ? "fit-content" : "unset"};
            `}>
            {!loaded && <Skeleton variant="rectangular" aspectRatio={4 / 3} />}
            {src && (
                <div
                    style={{
                        display: "flex",
                        width: "fit-content",
                        top: 0,
                        left: 0,
                        ...(loaded ? {} : { position: "absolute" }),
                    }}>
                    <Zoom transitionDuration={150}>
                        <img
                            data-private // disable log-rocket recording
                            ref={imageRef}
                            src={src}
                            loading="lazy"
                            onLoad={handleImageLoad}
                            style={{
                                display: "block",
                                width: "100%",
                                pointerEvents: "none",
                            }}
                        />
                        {showImageScaleBar && (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 2,
                                    right: 4,
                                }}>
                                <ImageScaleBar
                                    displayMicrons={100}
                                    displayDefaultWidth={defaultImageWidth}
                                    mediaRef={imageRef}
                                    imagePixelWidth={imageWidth}
                                    pixelScale={pixelScale}
                                />
                            </div>
                        )}
                    </Zoom>
                    <div
                        style={{
                            position: "absolute",
                            top: 2,
                            right: 4,
                        }}>
                        <MoreButton
                            size="sm"
                            colour="white"
                            options={[
                                {
                                    icon: "hyperlink",
                                    label: "Share link",
                                    onClick: () => {
                                        if (thisId) {
                                            setSelectedImage();
                                            copy(location.href);
                                            toast.success("Copied link");
                                        }
                                    },
                                },
                                {
                                    icon: "download",
                                    label: "Download",
                                    onClick: async () => {
                                        const { blob, filename } =
                                            await downloadMediaBlob(src);
                                        saveAs(blob, filename);
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

function BlankImage(props: {
    icon?: IconName;
    inheritHeight?: boolean;
}): ReactElement {
    const { icon, inheritHeight } = props;
    const theme = useTheme();
    const aspectRatio = 4 / 3;
    const paddingTop: string | undefined = inheritHeight
        ? undefined
        : String((1 / aspectRatio) * 100) + "%";

    return (
        <>
            <div
                style={{
                    height: inheritHeight ? "100%" : 0,
                    paddingTop,
                    background: theme.colours.neutral[300],
                    borderRadius: 4,
                    position: "relative",
                }}>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: theme.colours.neutral[500],
                    }}>
                    <Icon name={icon ?? "cross"} size="lg" />
                </div>
            </div>
        </>
    );
}
