import { useQuery } from "@apollo/client";
import { isString } from "lodash";

import { gql } from "__generated__/apollo";
import { useToasts } from "components/common/toasts/useToasts";
import { parseResponseBody } from "services/requests";
import { toTitleCase } from "services/string-utils";
import { getMessageFromBody } from "services/utils";

import { log as parentLog } from "./log";
import { useDeviceControl } from "./useDeviceControl";

export const log = parentLog.extend("useCultureControl");

/**
 * Hook that provides methods to start a loaded Culture on a target Device.
 *
 * @param deviceId - ID of Device to control
 * @returns Object to be destructured
 */
export function useStartCulture(deviceId: string): () => Promise<boolean> {
    return useGenericCultureControl(deviceId, "start");
}

/**
 * Hook that provides methods to pause a Culture running on a target Device
 *
 * @param deviceId - ID of Device to control
 * @returns Object to be destructured
 */
export function usePauseCulture(deviceId: string): () => Promise<boolean> {
    return useGenericCultureControl(deviceId, "pause");
}

/**
 * Hook that provides methods to resume a paused culture running on a target
 * Device.
 *
 * @param deviceId - ID of Device to control
 * @returns Object to be destructured
 */
export function useResumeCulture(deviceId: string): () => Promise<boolean> {
    return useGenericCultureControl(deviceId, "resume");
}

/**
 * Hook that provides methods to clear the culture from a target Device.
 *
 * @param deviceId - ID of Device to control
 * @returns Object to be destructured
 */
export function useClearCulture(deviceId: string): () => Promise<boolean> {
    return useGenericCultureControl(deviceId, "clear");
}

const CONTROL_LANGUAGE = {
    start: {
        path: "/controller/api/protocol/actions/start",
        gerund: "starting",
        past: "started",
    },
    pause: {
        path: "/controller/api/protocol/actions/stop",
        gerund: "pausing",
        past: "paused",
    },
    resume: {
        path: "/controller/api/protocol/actions/resume",
        gerund: "resuming",
        past: "resumed",
    },
    clear: {
        path: "/controller/api/protocol/actions/clear",
        gerund: "clearing",
        past: "cleared",
    },
} satisfies {
    [k: string]: {
        path: string;
        gerund: string;
        past: string;
    };
};

/**
 * Hook that provides methods to generica a Culture running on a target Device
 *
 * @param deviceId - ID of Device to control
 * @returns Object to be destructured
 */
export function useGenericCultureControl(
    deviceId: string,
    controlOption: keyof typeof CONTROL_LANGUAGE,
): () => Promise<boolean> {
    const { toast } = useToasts();
    const control = CONTROL_LANGUAGE[controlOption];
    const gerund = control.gerund;

    const { data, refetch } = useQuery(DEVICE_CULTURE_CONTROL_QUERY, {
        variables: { deviceId },
        fetchPolicy: "cache-first",
    });
    const culture = data?.device?.culture;
    const maybeCultureName = culture?.name;
    const cultureName = maybeCultureName
        ? `${maybeCultureName} culture`
        : "Culture";

    const { execute } = useDeviceControl(deviceId, control.path, [deviceId]);

    return async () => {
        const toastId = toast.loading(`${toTitleCase(gerund)} ${cultureName}`);
        const res = await execute();
        const body = await parseResponseBody(res);
        log.debug(`Response for ${controlOption} culture`, { res, body });
        let outcome: boolean;
        if (!isString(body) && body.ok) {
            outcome = true;
            toast.update(toastId, {
                render: `${cultureName} is ${control.past}`,
                type: "success",
                isLoading: false,
            });
        } else {
            outcome = false;
            toast.update(toastId, {
                render: `${cultureName} could not be ${
                    control.past
                }. Response from Device was: ${getMessageFromBody(body)}`,
                type: "error",
                isLoading: false,
            });
        }
        void refetch();
        return outcome;
    };
}

export const DEVICE_CULTURE_CONTROL_QUERY = gql(`
    query DeviceCultureControl($deviceId: String!) {
        device(id: $deviceId) {
            id
            culture {
                id
                state
                name
            }
        }
    }
`);
