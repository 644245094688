import { cachePersistor } from "./apollo/cache";
import defaultLog from "./logger";

const log = defaultLog.extend("config");

interface Configuration {
    appVersion: string | undefined;
    gitHash: string | undefined;
    env: string;
    isLocal: boolean;
}

/**
 * This is the config mananger
 */
class ConfigManager implements Configuration {
    private _serverUrl: string | undefined;
    private _serverGraphqlPath = "/graphql";
    private _serviceWorkerUpdateAvailable = false;
    private _serviceWorkerRegistration: ServiceWorkerRegistration | null = null;

    /**
     * Initialises the application configuration object.
     */
    async init(): Promise<void> {
        if (!["development", "production"].includes(this.env)) {
            log.warn("WARNING: Do not recognise environment:", this.env);
        }

        // TODO sort this out. Maybe islocal=false automatically forces an env change
        if (this.isLocal) {
            // Running in development
            // log("Running locally. Hostname:", window.location.hostname);
        } else {
            // Running in production
            if (this.env !== "production") {
                log.warn(
                    "WARNING: It seems this is running in deployment with 'development' environment set.",
                );
            }
            // TODO should disable all loggers if not locally developing
            // logger.disableAll();
        }

        // TODO this should only be logged in development
        log.debug("Application Configuration:", this);
    }

    /**
     * Logs to console a load of information about the application and its
     * runtime environment.
     */
    dump(): void {
        /*eslint-disable no-console */
        console.log("App version:", this.appVersion);
        console.log("Git hash:", this.gitHash);
        console.log("Env:", this.env);
        console.log("Local:", this.isLocal);
        console.log("Server:", {
            http: this.serverUrl,
            graphqlPath: this.serverPathForGraphQL,
        });
        /*eslint-enable no-console */
    }

    get appVersion(): string | undefined {
        return import.meta.env.REACT_APP_VERSION;
    }

    get gitHash(): string | undefined {
        return import.meta.env.REACT_APP_GIT_HASH;
    }

    /**
     * Resolves the environment state. Likely to be "development", "test", or
     * "production".
     */
    get env(): string {
        return import.meta.env.NODE_ENV || "production";
    }

    /**
     * The hostname of the current web application instance. e.g.
     * "app.mytos.bio" or "localhost"
     */
    get hostname(): string {
        return window.location.hostname;
    }

    /**
     * Returns boolean on whether the device is running on a developer machine.
     */
    get isLocal(): boolean {
        return (
            ["localhost", "127.0.0.1"].includes(window.location.hostname) ||
            this.hostname.startsWith("192.168")
        );
    }

    /**
     * Returns boolean on whether the device is running on a chromatic.
     */
    get isChromatic(): boolean {
        return this.hostname.includes(".chromatic.com");
    }

    /**
     * Returns boolean on whether the app is running in staging environment.
     */
    get isStg(): boolean {
        return this.hostname === "app.stg.mytos.bio";
    }

    /**
     * Returns boolean on whether the app is running in development environment.
     */
    get isDev(): boolean {
        return this.hostname === "app.dev.mytos.bio";
    }

    /**
     * Returns boolean on whether the app is running in a review in the staging environment.
     * e.g., https://deploy-preview-1010.app.stg.mytos.bio
     */
    get isStgReview(): boolean {
        return !this.isStg && this.hostname.endsWith("app.stg.mytos.bio");
    }

    /**
     * Returns boolean on whether the app is running in production environment.
     */
    get isProd(): boolean {
        return this.hostname === "app.mytos.bio";
    }

    /**
     * Returns boolean on whether the app is running in a review in the production environment.
     * e.g., https://deploy-preview-1010.app.mytos.bio
     */
    get isProdReview(): boolean {
        return !this.isProd && this.hostname.endsWith("app.mytos.bio");
    }

    get envIdentifier(): string {
        if (this.isDev) {
            return "(DEV) ";
        }
        if (this.isStg) {
            return "(STG) ";
        }
        return "";
    }

    /**
     * Evaluates the current hostname and determines an appropriate environment
     * string to describe it, e.g. "production" or "development"
     * @returns environment string
     */
    evaluateEnvironment() {
        if (this.isLocal) {
            return "local";
        }
        if (this.isDev) {
            return "development";
        }
        if (this.isStg) {
            return "staging";
        }
        if (this.isProd) {
            return "production";
        }
        if (this.isStgReview || this.isProdReview) {
            return "review";
        }
        return "unknown";
    }

    appHostnameForEnvironment(
        env: Exclude<
            ReturnType<typeof this.evaluateEnvironment>,
            "review" | "unknown"
        >,
    ) {
        switch (env) {
            case "local":
                return "localhost:3000";
            case "development":
                return "app.dev.mytos.bio";
            case "staging":
                return "app.stg.mytos.bio";
            case "production":
                return "app.mytos.bio";
        }
    }

    /**
     * Returns the most appropriate server HTTP URL based on current runtime
     * conditions.
     *
     * **This method should not used frequently as it's inefficient. Please use
     * the `serverUrl()` method which caches its results**
     *
     * How does it evaluate what the server URL should be?
     * - Firstly checks the `REACT_APP_SERVER_URL` for a value.
     * - If nothing provided, will use hardcoded value for either default local
     *   URL, staging server, or production server.
     *
     * @returns Example: `https://api.mytos.bio`
     */
    evaluateServerOrigin(): string {
        // First try retrieving from environment variable
        const envServerUrl = import.meta.env.REACT_APP_SERVER_URL;
        if (envServerUrl) {
            log.debug("Retrieved server URL from environment:", envServerUrl);
            return envServerUrl;
        }

        if (this.isLocal || this.isChromatic) {
            return `http://${this.hostname}:4000`;
        }
        if (this.isDev) {
            return "https://api.dev.mytos.bio";
        }
        if (this.isStg || this.isStgReview) {
            return "https://api.stg.mytos.bio";
        }
        if (this.isProd || this.isProdReview) {
            return "https://api.mytos.bio";
        }

        const msg = `ERROR: The hostname '${this.hostname}' is not recognised for use. Please contact developers@mytos.bio if you encounter this message.`;
        log.error(msg);
        throw new Error(msg);
    }

    /**
     * Gets the HTTP URL (without path) of the backend server.
     *
     * If a new URL is passed as a parameter, this will be used as the server
     * URL going forward. This however is not persisted between refreshes.
     *
     * e.g. https://api.mytos.bio
     */
    get serverUrl(): string {
        if (!this._serverUrl) {
            this._serverUrl = this.evaluateServerOrigin();
        }
        return this._serverUrl;
    }

    /**
     * Handler method for determining the path of the GraphQL endpoint on the
     * server. If an argument is passed, it will be saved as the new path to
     * use. Function always returns the new path if provided, or the last
     * provided (or default) path.
     *
     * **Note: paths begin with `/` the root**
     *
     * @example
     * serverPathForGraphQL() // returns "/graphql"
     *
     * @param newPath Optional string for a new path to be saved
     * @returns The up to date path
     */
    get serverPathForGraphQL(): string {
        return this._serverGraphqlPath;
    }

    /**
     * Provides current state on service workers
     */
    get serviceWorker() {
        return {
            updateAvailable: this._serviceWorkerUpdateAvailable,
            registration: this._serviceWorkerRegistration,
        };
    }

    /**
     * Array of callback functions to be called when an update becomes
     * available.
     */
    private _onUpdateAvailableCallbacks: OnUpdateAvailableCallback[] = [];

    /**
     * Takes callbacks functions and registers them for later under the
     * conditions that an app update becomes available.
     *
     * @param callback Function to be registers for call back
     */
    onUpdateAvailable(callback: OnUpdateAvailableCallback) {
        this._onUpdateAvailableCallbacks.push(callback);
    }

    /**
     * Takes the service worker registration and determines whether an update is
     * available. If an update is available, it will call all the callback
     * functions that have been registered to `onUpdateAvailable`.
     *
     * @param registration Service Worker Registration object
     */
    setServiceWorkerRegistration(
        registration: ServiceWorkerRegistration,
    ): void {
        this._serviceWorkerRegistration = registration;
        if (registration.waiting) {
            this._serviceWorkerUpdateAvailable = true;
            this._onUpdateAvailableCallbacks.map(cb => cb());
        }
    }

    /**
     * Checks if there is a waiting service worker. If there is, it will
     * dispatch a message to tell it to skip waiting and activate itself. Upon
     * service worker state change to activated, the window will be reloaded.
     *
     * @returns Boolean on whether waiting service worker has been told to skip
     * waiting
     */
    updateServiceWorker(): boolean {
        const registrationWaiting = this._serviceWorkerRegistration?.waiting;
        if (registrationWaiting) {
            try {
                log.debug("Attempting to update service worker");
                registrationWaiting.postMessage({ type: "SKIP_WAITING" });
                registrationWaiting.addEventListener("statechange", e => {
                    const serviceWorker = e?.target as
                        | ServiceWorker
                        | null
                        | undefined;
                    if (serviceWorker?.state === "activated") {
                        window.location.reload();
                    }
                });
            } catch (error) {
                log.error(
                    "There was an issue trying to update the service worker",
                    { error },
                );
            }
            return true;
        }
        log.debug("There is no waiting service worker to update");
        return false;
    }

    /**
     * Clears the cache and returns boolean of completion status
     */
    async clearCache(): Promise<boolean> {
        await cachePersistor.purge();
        return true;
    }

    /**
     * Clears the service worker cache and returns boolean of completion status
     */
    async clearServiceWorkerCache(): Promise<boolean> {
        await caches.delete("mediaImages");
        await caches.delete("mediaVideos");
        return true;
    }
}

type OnUpdateAvailableCallback = () => void;

export const config = new ConfigManager();
