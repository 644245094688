import React, { ReactElement, ReactNode } from "react";

import { Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import { styled, SxProps } from "@mui/material/styles";

import { IconButton } from "./IconButton";

export interface CollapsibleCardProps {
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    header: () => ReactElement;
    collapsibleContent: () => ReactElement;
    /**
     * Override style is applied to the outermost component - the <Box>.
     */
    sx?: SxProps;
}

export function CollapsibleCard(props: CollapsibleCardProps): ReactElement {
    const { header, collapsibleContent, sx, expanded, setExpanded } = props;

    const handleExpandClick = () => {
        setExpanded(expanded => !expanded);
    };

    return (
        <Card
            sx={{
                pb: expanded ? 4 : 2,
                ...sx,
            }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                }}
                onClick={handleExpandClick}>
                {header()}
                <ExpandMore expand={expanded} />
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider sx={{ mt: 2, mb: 4 }} />
                {collapsibleContent()}
            </Collapse>
        </Card>
    );
}

export const Card = ({
    sx,
    children,
}: {
    sx: SxProps;
    children: ReactNode;
}) => (
    <Box
        sx={{
            borderRadius: 1,
            border: "1px solid",
            borderColor: theme => theme.colours.neutral[300],
            backgroundColor: theme => theme.colours.neutral[100],
            boxShadow: "0px 5px 15px 0px #00000012",
            pt: 2,
            pb: 2,
            px: {
                xs: 2,
                sm: 4,
            },
            ...sx,
        }}>
        {children}
    </Box>
);

interface ExpandMoreProps {
    expand: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExpandMore = styled((props: ExpandMoreProps) => {
    return <IconButton icon="chevron-down" size="sm" />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));
