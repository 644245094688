import { ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import copy from "copy-to-clipboard";
import { filter } from "lodash";

import { gql } from "__generated__/apollo";
import {
    InvocationControl,
    InvocationState,
} from "__generated__/apollo/graphql";
import { IconName } from "components/common/Icon";
import { MoreButton, MoreButtonOptions } from "components/common/MoreButton";
import { useToasts } from "components/common/toasts/useToasts";
import { CULTURE_SCHEDULE_LEGACY_QUERY } from "services/hooks/useCultureScheduleLegacy";
import {
    Invocation,
    STEP_INVOCATIONS_QUERY,
} from "services/hooks/useCultureStepInvocations";

import { useScheduleIsViewOnly } from "../schedule-data";

import { EditInvocationModal } from "./EditInvocationsModal";
import { log as parentLog } from "./log";

export const log = parentLog.extend("InvocationControls");

const CONTROLS: {
    [key in InvocationControl]: {
        control: key;
        label: string;
        states: InvocationState[];
        gerund: string;
        icon?: IconName;
    };
} = {
    RESTORE: {
        control: InvocationControl.Restore,
        label: "Don't skip",
        gerund: "Restoring",
        states: [InvocationState.Removed],
        icon: "sync-arrows",
    },
    REMOVE: {
        control: InvocationControl.Remove,
        label: "Skip",
        gerund: "Removing",
        states: [InvocationState.Planned],
        icon: "skip",
    },
    CANCEL: {
        control: InvocationControl.Cancel,
        label: "Cancel",
        gerund: "Cancelling",
        states: [InvocationState.Running],
        icon: "disable",
    },
};

/** States where invocation duplication is permitted */
const DUPLICATE_INVOCATION_STATES = [
    InvocationState.Failed,
    InvocationState.Ignored,
    InvocationState.Complete,
];

/** States where editing is permitted */
const EDIT_INVOCATION_STATES = [InvocationState.Planned];

export interface InvocationControlProps {
    deviceId: string;
    invocation: Invocation;
}

export function InvocationControls(
    props: InvocationControlProps,
): ReactElement {
    const { deviceId, invocation } = props;
    const invocationState = invocation.state ?? InvocationState.Unknown;
    const scheduleViewOnly = useScheduleIsViewOnly();
    const { toast } = useToasts();
    const [controlInvocation] = useMutation(CONTROL_INVOCATION_MUTATION);
    const [duplicateInvocation] = useMutation(DUPLICATE_INVOCATION_MUTATION);

    // filter controls for state
    const visibleControls = filter(CONTROLS, control =>
        control.states.includes(invocationState),
    );
    // map controls into button options
    const controlOptions: MoreButtonOptions = visibleControls.map(control => ({
        label: control.label,
        icon: control.icon,
        onClick: async () => {
            log.debug(control.label, invocation.id);
            const toastId = toast.loading(control.gerund + " Operation");
            const res = await controlInvocation({
                variables: {
                    input: {
                        control: control.control,
                        deviceId,
                        invocationId: invocation.id,
                    },
                },
            });
            if (res.data?.controlInvocation.ok) {
                toast.update(toastId, {
                    type: "success",
                    render: control.label + " successful",
                    isLoading: false,
                    autoClose: 3_000,
                });
            } else {
                toast.update(toastId, {
                    type: "error",
                    render: control.label + " failed",
                    isLoading: false,
                    autoClose: 6_000,
                });
            }
        },
    }));

    // if duplications is allowed, add an option
    const allowDuplication =
        DUPLICATE_INVOCATION_STATES.includes(invocationState);
    if (allowDuplication && !scheduleViewOnly) {
        // add a divider, if there are already control options in the list
        if (controlOptions.length) controlOptions.push("divider");
        controlOptions.push({
            label: "Duplicate",
            icon: "copy",
            onClick: async () => {
                log.debug("Duplicate invocation", invocation.id);
                const toastId = toast.loading("Duplicating operation");
                const res = await duplicateInvocation({
                    variables: {
                        input: {
                            invocationId: invocation.id,
                            deviceId,
                        },
                    },
                    refetchQueries: [
                        CULTURE_SCHEDULE_LEGACY_QUERY,
                        STEP_INVOCATIONS_QUERY,
                    ],
                });
                if (res.data?.duplicateInvocation.ok) {
                    toast.update(toastId, {
                        type: "success",
                        render: "Operation duplicated successfully",
                        isLoading: false,
                        autoClose: 3_000,
                    });
                } else {
                    toast.update(toastId, {
                        type: "error",
                        render: "Unable to duplicate Operation",
                        isLoading: false,
                        autoClose: 6_000,
                    });
                }
            },
        });
    }

    // if editing is allowed, add an option
    const allowEdit = EDIT_INVOCATION_STATES.includes(invocationState);
    const [editInvocationModalOpen, setEditInvocationModalOpen] =
        useState(false);
    if (allowEdit && !scheduleViewOnly) {
        // add a divider, if there are already control options in the list
        if (controlOptions.length) controlOptions.push("divider");
        controlOptions.push({
            label: "Edit parameters",
            icon: "edit",
            onClick: () => {
                setEditInvocationModalOpen(true);
            },
        });
    }
    controlOptions.push({
        label: "Copy ID",
        icon: "clipboard",
        onClick: () => copy(invocation.id),
    });

    // We'll add a basic message
    if (controlOptions.length === 0)
        controlOptions.push({
            label: "No controls available",
            onClick: () => undefined,
            disabled: true,
        });

    return (
        <div
            onClick={e => e.stopPropagation()}
            css={css`
                display: flex;
                align-items: center;
                column-gap: 8px;
            `}>
            <MoreButton size="sm" options={controlOptions} />
            {allowEdit && (
                <EditInvocationModal
                    invocation={invocation}
                    deviceId={deviceId}
                    modalOpen={editInvocationModalOpen}
                    setModalOpen={setEditInvocationModalOpen}
                />
            )}
        </div>
    );
}

const CONTROL_INVOCATION_MUTATION = gql(`
    mutation ControlInvocation($input: ControlInvocationInput!) {
        controlInvocation(input: $input) {
            ok
            message
            invocation {
                id
                state
                operationId
                description
            }
        }
    }
`);

const DUPLICATE_INVOCATION_MUTATION = gql(`
    mutation DuplicateInvocation($input: DuplicateInvocationInput!) {
        duplicateInvocation(input: $input) {
            ok
            message
        }
    }
`);
