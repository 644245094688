import { ReactElement, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { cloneDeep } from "lodash";

import { gql } from "__generated__/apollo";
import { DevicePlatform } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Txt from "components/common/Text";

import { DrySetupGuideData, getDrySetupGuideData } from "./DrySetupGuide";
import { DrySetupSection } from "./DrySetupSection";

export type DrySetupViewProps = {
    deviceId: string;
    drySetupComplete: boolean;
    setDrySetupComplete: (newValue: boolean) => void;
};

export function DrySetupView({
    deviceId,
    drySetupComplete,
    setDrySetupComplete,
}: DrySetupViewProps): ReactElement {
    const { platform, error, loading } = useDevicePlatform(deviceId);

    if (loading) {
        return <Txt level={7}>Loading...</Txt>;
    }

    if (error) {
        return (
            <Callout variant="error">
                Error loading Dry Setup requirements
            </Callout>
        );
    }

    const setupGuideData = getDrySetupGuideData(
        platform ?? DevicePlatform.Mock,
    );

    return (
        <DrySetupViewContent
            setupGuideData={setupGuideData}
            setDrySetupComplete={setDrySetupComplete}
            drySetupComplete={drySetupComplete}
        />
    );
}

export type DrySetupViewContentProps = {
    setupGuideData: DrySetupGuideData;
    setDrySetupComplete: (newValue: boolean) => void;
    drySetupComplete?: boolean;
};

export function DrySetupViewContent({
    setupGuideData,
    setDrySetupComplete,
    drySetupComplete,
}: DrySetupViewContentProps): ReactElement {
    const initialCheckBoxStates: boolean[][] = setupGuideData.map(({ steps }) =>
        steps.map(() => drySetupComplete ?? false),
    );

    const [checkBoxStates, setCheckBoxStates] = useState(initialCheckBoxStates);

    useEffect(() => {
        if (checkBoxStates.flat().every(value => value)) {
            setDrySetupComplete(true);
        } else {
            setDrySetupComplete(false);
        }
    }, [checkBoxStates, setDrySetupComplete]);

    return (
        <div
            style={{
                display: "flex",
                gap: "24px",
                flexDirection: "column",
                height: "fit-content",
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                }}>
                <Txt font="primary" level={5} emphasis>
                    Dry Setup
                </Txt>
                <Txt font="secondary" level={8}>
                    Please follow the instructions below to complete dry setup
                    of the consumables.
                </Txt>
            </div>

            {setupGuideData.map((sectionData, index) => (
                <DrySetupSection
                    key={`${sectionData.sectionTitle}-${index}`}
                    sectionData={sectionData}
                    setCheckBoxStates={(newSectionState: boolean[]) => {
                        /** Set check box states for particular section index */
                        setCheckBoxStates(fullState => {
                            const newState = cloneDeep(fullState);
                            newState[index] = newSectionState;
                            return newState;
                        });
                    }}
                    checkBoxStates={checkBoxStates[index]}
                />
            ))}
        </div>
    );
}

export const DEVICE_PLATFORM_QUERY = gql(`
    query DevicePlatform($deviceId: String!) {
        device(id: $deviceId) {
            id
            platform
        }
    }
`);

export function useDevicePlatform(deviceId: string) {
    const { data, loading, error } = useQuery(DEVICE_PLATFORM_QUERY, {
        variables: { deviceId },
        fetchPolicy: "cache-first",
    });

    return { platform: data?.device?.platform ?? null, loading, error };
}
