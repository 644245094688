import { useState, ReactElement, useCallback } from "react";

import { ApolloError, useQuery } from "@apollo/client";
import { Breakpoint } from "@mui/material";
import Container from "@mui/material/Container";
import { cloneDeep } from "lodash";

import { gql } from "__generated__/apollo";
import { CultureImageResultsQuery } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import { useFeature } from "services/feature-flags";
import { useDisableOverscrollBehaviour } from "services/hooks/useDisableOverscrollBehaviour";
import { removeNullables } from "services/utils";

import { useGridSettings } from "./device-images-state";
import { isResultDataFlaskImages } from "./device-images-utils";
import { DeviceImagesSettings } from "./DeviceImagesSettings";
import { log as parentLog } from "./log";
import { ResultGrid } from "./ResultGrid";
import { SelectFlask, useFlaskIds } from "./SelectFlask";

export const log = parentLog.extend("DeviceImages");

export interface DeviceImagesByDeviceProps {
    deviceId: string;
}

export interface DeviceImagesByCultureProps {
    cultureId: string;
}

/**
 * The type of imaging results we are interested in.
 * At the moment we only want to show results from
 * 10x10 imaging.
 */
const TARGET_NUM_IMAGES = 100;
/**
 * Max width of all non-ResultGrid items on the page
 */
const MAX_WIDTH_CONTAINER: Breakpoint = "xl";

function useDeviceImages(
    props: DeviceImagesByDeviceProps | DeviceImagesByCultureProps,
): {
    cultureId?: string;
    cultureLoading: boolean;
    cultureError?: ApolloError;
    results: CultureQueryResultsConnectionNode;
    resultsLoading: boolean;
    resultsError?: ApolloError;
    invalidFlaskId: boolean;
    startDate?: string;
    onLoadMore: () => Promise<void>;
    loadingMoreResults: boolean;
    dayStartIndex: number;
} {
    const {
        cultureId,
        selectedFlaskReference,
        invalidFlaskId,
        loading: cultureLoading,
        error: cultureError,
    } = useFlaskIds(props);

    const flaskReferences = selectedFlaskReference
        ? [selectedFlaskReference]
        : undefined;

    const {
        loading: resultsLoading,
        data,
        error: resultsError,
        fetchMore,
    } = useQuery(CULTURE_IMAGE_RESULTS_QUERY, {
        variables: {
            cultureId: cultureId ?? "",
            flaskIds: flaskReferences,
            first: 10,
        },
        skip: !cultureId || !selectedFlaskReference,
    });

    /**
     * Results should be filtered to only acceptable ones
     */
    const results =
        data?.culture?.results?.nodes?.filter(node => {
            const resultData = node?.data;
            return Boolean(
                resultData &&
                    isResultDataFlaskImages(resultData) &&
                    resultData?.images?.length === TARGET_NUM_IMAGES &&
                    resultData.flask?.flaskId === selectedFlaskReference,
            );
        }) ?? [];

    const [loadingMore, setLoadingMore] = useState(false);

    const hasNextPage = data?.culture?.results?.pageInfo?.hasNextPage;
    const endCursor = data?.culture?.results?.pageInfo?.endCursor;

    const onLoadMore = useCallback(async () => {
        if (hasNextPage) {
            setLoadingMore(true);
            try {
                await fetchMore({
                    variables: {
                        after: endCursor,
                    },
                });
            } finally {
                setLoadingMore(false);
            }
        }
    }, [endCursor, hasNextPage, fetchMore]);

    const dayStartIndex = data?.culture?.dayStartIndex ?? 0;

    return {
        cultureId,
        cultureLoading,
        cultureError,
        results,
        resultsLoading,
        resultsError,
        invalidFlaskId,
        startDate: data?.culture?.startDate ?? undefined,
        loadingMoreResults: loadingMore,
        dayStartIndex,
        onLoadMore,
    };
}

export function DeviceImages(
    props: DeviceImagesByDeviceProps | DeviceImagesByCultureProps,
): ReactElement {
    const {
        cultureId,
        cultureLoading,
        cultureError,
        results: allResults,
        resultsLoading,
        resultsError,
        invalidFlaskId,
        startDate,
        onLoadMore,
        loadingMoreResults,
        dayStartIndex,
    } = useDeviceImages(props);

    /**
     * Disable horizontal overscroll behaviour that controls browser navigation
     */
    useDisableOverscrollBehaviour();

    const feature = useFeature("app_device_images_settings");
    const { customGridSettings, cols } = useGridSettings();
    const numResults = customGridSettings ? cols : allResults.length;
    const limitedResults = allResults.slice(0, numResults);
    const results = cloneDeep(removeNullables(limitedResults));
    let DeviceImagesContent: ReactElement | null = null;

    const hasValidData = allResults && results.length > 0;

    if (cultureLoading) {
        // This will be a very short loading time so
        // don't show a skeleton.
        DeviceImagesContent = null;
    } else if (cultureError) {
        DeviceImagesContent = (
            <Callout variant="warn">Unable to load culture data</Callout>
        );
    } else if (!cultureId) {
        DeviceImagesContent = (
            <Callout>There is no culture on this device</Callout>
        );
    } else if (invalidFlaskId) {
        DeviceImagesContent = (
            <Callout variant="warn">
                The selected flask is not valid for this culture
            </Callout>
        );
    } else if (allResults.length && results.length === 0) {
        DeviceImagesContent = (
            <Callout>No image events available for this flask</Callout>
        );
    } else if (hasValidData) {
        DeviceImagesContent = (
            <ResultGrid
                results={results}
                startDate={startDate}
                onLoadMore={onLoadMore}
                loadingMore={loadingMoreResults}
                dayStartIndex={dayStartIndex}
            />
        );
    } else if (resultsError) {
        log.error(
            { resultsError },
            "Unable to fetch results for Device Images view",
        );
        DeviceImagesContent = (
            <Callout variant="warn">
                There was a problem loading the imaging events
            </Callout>
        );
    } else if (resultsLoading) {
        DeviceImagesContent = <Skeleton />;
    }

    if (!hasValidData) {
        // wrap only when not data
        DeviceImagesContent = (
            <Container maxWidth={MAX_WIDTH_CONTAINER}>
                {DeviceImagesContent}
            </Container>
        );
    }

    return (
        <div
            style={{
                paddingBottom: 40, // tab content spacing from bottom of screen
            }}>
            <Container
                id="DeviceImagesHeader"
                maxWidth={MAX_WIDTH_CONTAINER}
                sx={theme => ({
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    padding: theme.spacing(4),
                    paddingBottom: 0,
                    [theme.breakpoints.up("md")]: {
                        paddingLeft: theme.spacing(8),
                        paddingRight: theme.spacing(8),
                    },
                })}
                style={{
                    marginBottom: 16,
                }}>
                {cultureId && <SelectFlask cultureId={cultureId} />}
                {feature.enabled && <DeviceImagesSettings />}
            </Container>
            {DeviceImagesContent}
        </div>
    );
}

const CULTURE_IMAGE_RESULTS_QUERY = gql(`
    query CultureImageResults(
        $cultureId: String!
        $flaskIds: [String!]
        $first: Int
        $after: String
    ) {
        culture(id: $cultureId) {
            id
            startDate
            dayStartIndex
            results(
                filterBy: { flaskIds: $flaskIds }
                first: $first
                after: $after
            ) {
                nodes {
                    id
                    type
                    timestampISO
                    data {
                        __typename
                        ... on FlaskImages {
                            flask {
                                flaskId
                                number
                                size
                            }
                            images {
                                id
                                imageUrl
                                timestampISO
                                pixelScale
                                width
                                position {
                                    absolutePosition {
                                        x
                                        y
                                        z
                                    }
                                    relativePosition {
                                        itemId
                                        positionName
                                        relativeCoords {
                                            x
                                            y
                                            z
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
                totalCount
            }
        }
    }
`);

type CultureQueryCulture = NonNullable<CultureImageResultsQuery["culture"]>;
type CultureQueryResultsConnection = NonNullable<
    CultureQueryCulture["results"]
>;
export type CultureQueryResultsConnectionNode = NonNullable<
    CultureQueryResultsConnection["nodes"]
>;
export type CultureQueryImageResult = NonNullable<
    CultureQueryResultsConnectionNode[number]
>;
export type CultureQueryResultData = NonNullable<
    CultureQueryImageResult["data"]
>;
export type CultureQueryFlaskImages = Extract<
    CultureQueryResultData,
    { __typename: "FlaskImages" }
>;
export type CultureQueryFlaskImageData = NonNullable<
    NonNullable<CultureQueryFlaskImages["images"]>[number]
>;
