import { useState, ReactElement } from "react";

import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

import {
    Bottle,
    BottleCapacity,
    isValidBottleCapacity,
} from "components/common/Bottle";
import { Flask } from "components/common/Flask";
import Icon from "components/common/Icon";
import Txt, { TxtProps } from "components/common/Text";

import { EditContainerModal } from "./EditContainerModal";

export interface ContainerTileProps {
    id: string;
    name: string;
    liquidVolumeMl: number | null;
    totalVolumeMl: number | null;
    isFlask?: boolean;
    isVolumeModifiable?: boolean;
    fixedWidth?: boolean;
}

function formatVolume(num: number | null): string {
    if (num === null) {
        return "Unknown";
    }
    if (num >= 1000) {
        return `${Number((num / 1000).toFixed(2))} L`;
    }
    return `${num.toFixed(0)} mL`;
}

export function ContainerTile(props: ContainerTileProps): ReactElement {
    const {
        liquidVolumeMl,
        totalVolumeMl,
        name,
        isFlask,
        isVolumeModifiable,
        fixedWidth = true,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const theme = useTheme();

    const volumePercent =
        liquidVolumeMl && totalVolumeMl ? liquidVolumeMl / totalVolumeMl : 0;

    return (
        <Tile fixedWidth={fixedWidth}>
            <IconContainer>
                {isFlask ? (
                    <Flask displayLiquid={volumePercent > 0} />
                ) : (
                    totalVolumeMl &&
                    isValidBottleCapacity(totalVolumeMl) && (
                        <Bottle
                            capacity={totalVolumeMl as BottleCapacity}
                            liquidStyle="media"
                            volumePercent={volumePercent}
                        />
                    )
                )}
            </IconContainer>
            <TileTextArea>
                <TextItem
                    text={name}
                    level={8}
                    emphasis
                    textColor={theme.colours.neutral[900]}
                />
                <TextItem
                    text={formatVolume(liquidVolumeMl)}
                    level={5}
                    emphasis
                    textColor={theme.colours.neutral[700]}
                    modifiable={isVolumeModifiable}
                    modifyCallback={() => setModalOpen(true)}
                />
                <TextItem
                    text={`Capacity: ${formatVolume(totalVolumeMl)}`}
                    level={9}
                    textColor={theme.colours.neutral[600]}
                />
                {isVolumeModifiable && (
                    <EditContainerModal
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        {...props}
                    />
                )}
            </TileTextArea>
        </Tile>
    );
}

interface TextItemProps extends Omit<TxtProps, "children"> {
    text?: string;
    textColor: string;
    modifiable?: boolean;
    modifyCallback?: () => void;
}

function TextItem({
    text,
    textColor,
    modifiable,
    modifyCallback,
    ...txtProps
}: TextItemProps) {
    return (
        <TextItemContainer
            textColor={textColor}
            modifiable={modifiable}
            onClick={modifiable ? modifyCallback : () => undefined}>
            <Txt {...txtProps}>
                {text}
                {modifiable && <Icon name="edit" size="sm" />}
            </Txt>
        </TextItemContainer>
    );
}

const Tile = styled.div<{ fixedWidth?: boolean }>`
    display: flex;
    height: 150px;
    margin: 0 8px;
    align-items: center;
    ${({ fixedWidth }) => fixedWidth && "width: 200px;"}
`;

const IconContainer = styled.div`
    flex-shrink: 0;
    margin-right: 8px;
    height: 50%;
`;

const TileTextArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const TextItemContainer = styled.div<{
    modifiable?: boolean;
    textColor: string;
}>`
    text-align: left;
    width: fit-content;
    color: ${({ textColor }) => textColor};
    svg {
        margin-left: 0.5em;
        color: ${({ theme }) => theme.colours.neutral[600]};
    }
    &:hover {
        cursor: ${({ modifiable }) => (modifiable ? "pointer" : "default")};
        color: ${({ theme, modifiable, textColor }) =>
            modifiable ? theme.colours.neutral[900] : textColor};
    }
    &:hover svg {
        color: ${({ theme }) => theme.colours.neutral[900]};
    }
`;
