import { gql } from "__generated__/apollo";
// import { NotificationsModal } from "components/AppBar/NotificationsModal";
// import { useToasts } from "components/common/toasts/useToasts";

// import { log as parentLog } from "./log";
import { NotificationsButton } from "./NotificationsButton";

// const log = parentLog.extend("NotificationsButton");

export const NOTIFICATIONS_COUNT_QUERY = gql(`
    query NotificationsCount(
        $read: Boolean = false
        $archived: Boolean = false
    ) {
        notifications(
            input: { filterBy: { read: $read, archived: $archived } }
        ) {
            totalCount
        }
    }
`);

export function NotificationsContainer(): JSX.Element {
    // const { toast } = useToasts();
    // const alertId = useRef<string | number | null>(null);

    // const { error, data } = useQuery(NOTIFICATIONS_COUNT_QUERY, {
    //     pollInterval: 30_000,
    //     ssr: false,
    // });
    // const location = useLocation();
    // const navigate = useNavigate();
    // const queryParams = new URLSearchParams(location.search);
    // const notificationsOpen = Boolean(queryParams.get("notifications_open"));

    // if (error) {
    //     log.debug(error);
    //     log.error("Encountered an error for query notifications count", error);

    //     const noActiveAlert = !(
    //         alertId.current && toast.isActive(alertId.current)
    //     );
    //     if (noActiveAlert) {
    //         // only if there is no existing alert or the most recent alert is no
    //         // longer active
    //         const newAlertId = toast.error("Could not retrieve notifications");
    //         alertId.current = newAlertId;
    //     }
    // }

    // const badgeNumber = data?.notifications.totalCount ?? 0;
    const badgeNumber = 0;

    return (
        <>
            <NotificationsButton
                badgeNumber={badgeNumber}
                onClick={() => {
                    return;
                    // queryParams.set("notifications_open", "true");
                    // navigate(
                    //     {
                    //         search: queryParams.toString(),
                    //     },
                    //     { replace: true },
                    // );
                }}
            />
            {/* <NotificationsModal notificationsOpen={notificationsOpen} /> */}
        </>
    );
}
