import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";

import Button from "components/common/Button";
import { CodeText } from "components/common/CodeText";
import Txt from "components/common/Text";

export default function InvalidPath(): JSX.Element {
    const location = useLocation();
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: "20%" }}
            spacing={6}>
            <Grid item>
                <Txt level={7}>
                    Could not find the page you are looking for...
                </Txt>
            </Grid>
            <Grid item>
                <CodeText text={location.pathname} copyToClipboard={false} />
            </Grid>
            <Grid item>
                <Button linkTo="/" variant="secondary">
                    Go to Home
                </Button>
            </Grid>
        </Grid>
    );
}
