import { ReactElement } from "react";

import { css } from "@emotion/react";
import { upperFirst, lowerCase } from "lodash";
import { useSearchParams } from "react-router-dom";

import Modal from "components/common/Modal";
import Txt from "components/common/Text";
import { InvocationControls } from "components/pages/Device/Schedule/Invocations/InvocationControls";
import { Invocation } from "services/hooks/useCultureStepInvocations";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { InvocationDetails } from "./InvocationDetails";

export function useInvocationDetailsModalState() {
    const [searchParams, setSearchParams] = useSearchParams();

    return {
        openInvocationId: searchParams.get("inv"),
        setOpenState: (invocationId: string, open: boolean) => {
            setSearchParams(
                prev => {
                    if (open) {
                        prev.set("inv", invocationId);
                    } else {
                        prev.delete("inv");
                    }
                    return prev;
                },
                { replace: true },
            );
        },
    };
}

interface InvocationDetailsModalProps {
    deviceId: string;
    invocation: Invocation;
    isOpen: boolean;
    setModalOpen: (update: boolean) => void;
}

export default function InvocationDetailsModal({
    deviceId,
    invocation,
    isOpen,
    setModalOpen,
}: InvocationDetailsModalProps): ReactElement | null {
    const { canOperate } = useDeviceRole(deviceId);

    const operationName = invocation.operationId
        ? upperFirst(lowerCase(invocation.operationId))
        : "Unknown";

    const invocationDescription =
        invocation.description && invocation.description.length
            ? invocation.description
            : operationName;

    return (
        <div onClick={e => e.stopPropagation()} style={{ height: 0 }}>
            <Modal
                title="Invocation Details"
                modalOpen={isOpen}
                setModalOpen={setModalOpen}
                buttonLeft={{
                    label: "Back",
                    onClick: close => close(),
                }}>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    `}>
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        `}>
                        <Txt font="secondary" level={6}>
                            {invocationDescription}
                        </Txt>
                        {canOperate && (
                            <InvocationControls
                                deviceId={deviceId}
                                invocation={invocation}
                            />
                        )}
                    </div>

                    <InvocationDetails invocation={invocation} />
                </div>
            </Modal>
        </div>
    );
}
