import { ReactElement } from "react";

import { CultureControl, CultureState } from "__generated__/apollo/graphql";
import Button, { ButtonSize, ButtonVariant } from "components/common/Button";
import { IconName } from "components/common/Icon";
import { useCultureState } from "services/hooks/useCultureState";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import useCultureControl from "../../../../services/hooks/server-minted-cultures/useCultureControl";

import { log as parentLog } from "./log";

export const log = parentLog.extend("CultureControlButton");

export interface CultureControlButtonProps {
    deviceId: string;
    cultureId?: string;
    variant?: ButtonVariant;
    size?: ButtonSize;
    cultureHasProcedureToRun: boolean;
    noun?: "Culture" | "Wet Tests";
    isSetupRequired?: boolean;
}

export function CultureControlButton(
    props: CultureControlButtonProps,
): ReactElement {
    const {
        cultureId,
        variant,
        size,
        cultureHasProcedureToRun,
        noun = "Culture",
        isSetupRequired = false,
    } = props;

    const { canOperate } = useDeviceRole(props.deviceId);

    const [handleCultureControl] = useCultureControl({
        cultureId: cultureId ?? "",
    });

    const { data } = useCultureState(cultureId ?? "");

    const state = data?.culture?.state;

    const tooltipMessage: string = !canOperate
        ? "You do not have permission to control the device. Contact an admin to change your role."
        : isSetupRequired
          ? "Please complete the setup process before starting the culture."
          : state === CultureState.Pausing
            ? `The ${noun} is in the process of pausing. Cancel any running steps to pause the ${noun} sooner.`
            : "";

    const stateConfig: {
        [k in CultureState | "UNKNOWN"]: ButtonConfig;
    } = {
        LOADING: {
            iconLeft: "play-circle",
            onClick: undefined,
            text: `Start ${noun}`,
        },
        READY: {
            iconLeft: "play-circle",
            onClick: () =>
                handleCultureControl({ control: CultureControl.Start }),
            text: `Start ${noun}`,
        },
        RUNNING: {
            iconLeft: "pause-circle",
            onClick: () =>
                handleCultureControl({ control: CultureControl.Pause }),
            text: `Pause ${noun}`,
        },
        PAUSED: {
            iconLeft: "play-circle",
            onClick: () =>
                handleCultureControl({ control: CultureControl.Resume }),
            text: `Resume ${noun}`,
        },
        PAUSING: {
            iconLeft: "play-circle",
            onClick: () =>
                handleCultureControl({ control: CultureControl.Resume }),
            text: `Resume ${noun}`,
        },
        UPDATING: {
            iconLeft: "play-circle",
            onClick: undefined,
            text: `Resume ${noun}`,
        },
        COMPLETE: {
            iconLeft: "play-circle",
            onClick: () =>
                handleCultureControl({ control: CultureControl.Restart }),
            text: `Restart ${noun}`,
            // Only allow the user to restart a culture if they've first
            // duplicates some procedures to run
            disabled: !cultureHasProcedureToRun,
            tooltip: !cultureHasProcedureToRun
                ? `You can only restart this ${noun} if you first duplicate a procedure that should be run.`
                : undefined,
        },
        UNKNOWN: {
            iconLeft: "play-circle",
            onClick: undefined,
            text: `Start ${noun}`,
        },
    };

    const config = stateConfig[state ?? "UNKNOWN"];

    const disabledCultureStates: (CultureState | undefined)[] = [
        CultureState.Loading,
        CultureState.Updating,
        undefined,
    ];
    const disabled =
        !canOperate || disabledCultureStates.includes(state ?? undefined);
    return (
        <ConfiguredButton
            disabled={disabled}
            variant={variant}
            size={size}
            tooltip={tooltipMessage}
            {...config}
        />
    );
}

type ButtonConfig = {
    iconLeft: IconName;
    onClick: (() => void) | undefined;
    text: string;
    disabled?: boolean;
    tooltip?: string;
};

export type ConfiguredButtonProps = ButtonConfig & {
    disabled: boolean | undefined;
    variant: ButtonVariant | undefined;
    size: ButtonSize | undefined;
    tooltip: string;
};

const sizeWidth: { [k in ButtonSize]: number } = {
    l: 200,
    m: 160,
    s: 135,
};

export const ConfiguredButton = (props: ConfiguredButtonProps) => (
    <Button
        variant={props.variant ?? "secondary"}
        size={props.size}
        style={{ minWidth: sizeWidth[props.size ?? "l"] }}
        disabled={props.disabled}
        tooltip={props.tooltip}
        iconLeft={props.iconLeft}
        onClick={props.onClick}>
        {props.text}
    </Button>
);
