import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import {
    DeviceJob,
    DeviceJobState,
    DeviceJobType,
} from "__generated__/apollo/graphql";

import {
    DryTest,
    RequiredDryTestsForProgression,
    dryChecksAreComplete,
} from "./utils";

export const DEVICE_DRY_TESTS_QUERY = gql(`
    query DeviceJobs($deviceId: String!) {
        device(id: $deviceId) {
            id
            deviceJobs {
                id
                type
                state
                errorMessage
                progress
                updatedAt
                requestedAt
            }
        }
    }
`);

export function useDeviceDryTests({
    deviceId,
    skipQuery = false,
}: {
    deviceId: string;
    skipQuery?: boolean;
}) {
    const [pollInterval, setPollInterval] = useState(3_000);
    const { data, loading, error } = useQuery(DEVICE_DRY_TESTS_QUERY, {
        variables: { deviceId },
        pollInterval,
        skip: skipQuery,
    });

    const dryTests = data?.device?.deviceJobs
        ? ensureAllDryTestsPresent(data.device.deviceJobs)
        : [];

    const activeStates: (DeviceJobState | null | undefined)[] = [
        DeviceJobState.Triggered,
        DeviceJobState.Pending,
        DeviceJobState.InProgress,
        DeviceJobState.Cancelling,
    ];

    const someActive = dryTests.some(dryTest => {
        return activeStates.includes(dryTest.state);
    });

    const pinchValveCheck = dryTests.find(
        dryTest => dryTest.type === DeviceJobType.PinchValveBedding,
    );

    const pinchValveActive = activeStates.includes(pinchValveCheck?.state);

    const allComplete = dryChecksAreComplete(dryTests);

    useEffect(() => {
        if (!someActive) {
            setPollInterval(15_000);
            return;
        }
        if (pinchValveActive) {
            setPollInterval(3_000);
        } else {
            setPollInterval(500);
        }
    }, [someActive, pinchValveActive]);

    return { dryTests, loading, error, someActive, allComplete };
}

const ensureAllDryTestsPresent = (dryTests: DeviceJob[]): DryTest[] => {
    const dryTestsEnsured = RequiredDryTestsForProgression.map(
        DeviceJobType => {
            const dryTest = dryTests.find(
                dryTest => dryTest.type === DeviceJobType,
            );
            if (dryTest) {
                return {
                    ...dryTest,
                    type: DeviceJobType,
                };
            }
            return {
                id: `no-historic-instances-${DeviceJobType}`,
                type: DeviceJobType,
                state: null,
                errorMessage: null,
                progress: null,
                requestedAt: null,
                updatedAt: null,
            };
        },
    );
    return dryTestsEnsured;
};
