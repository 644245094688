import { useState, ReactElement, useEffect } from "react";

import styled from "@emotion/styled";

import { CultureState } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import { CultureStatusBadge } from "components/common/CultureStatusBadge";

import { CultureControlButton } from "../Device/Culture/CultureControlButton";

export enum CultureScheduleEvents {
    CollapseAll = "culture-schedule-collapse-all",
    ExpandAll = "culture-schedule-expand-all",
    ScrollToNext = "culture-schedule-scroll-to-next",
}

const ANIMATE_STATUS_BUTTON_IN_Y = 150;

export default function CultureScheduleToolbar({
    deviceId,
    cultureId,
    isWetTest,
    refetchCulture,
    cultureState,
    cultureIsWaitingForConfirmation,
    cultureHasProcedureToRun,
    cultureErrorMessage,
}: {
    refetchCulture: () => void;
    deviceId?: string;
    cultureId?: string;
    isWetTest?: boolean;
    cultureState?: CultureState | null;
    cultureIsWaitingForConfirmation: boolean | null | undefined;
    cultureHasProcedureToRun: boolean;
    cultureErrorMessage?: string | null;
}): ReactElement {
    const [cultureControlsVisible, setCultureControlsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scaledScrollPosition =
                ANIMATE_STATUS_BUTTON_IN_Y - window.scrollY;
            setCultureControlsVisible(scaledScrollPosition <= 0);
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <StickyWrapper>
            <ToolbarWrapper>
                <AnimateInWrapper
                    cultureControlsVisible={cultureControlsVisible}>
                    <CultureStatusBadge
                        cultureState={isWetTest ? null : cultureState}
                        wetTestCultureState={isWetTest ? cultureState : null}
                        loading={false}
                        waitingForConfirmation={cultureIsWaitingForConfirmation}
                        errorMessage={cultureErrorMessage}
                    />
                </AnimateInWrapper>

                <ButtonWrapper
                    cultureControlsVisible={cultureControlsVisible}
                    style={{}}>
                    <Button
                        variant="tertiary"
                        size="s"
                        iconRight="rotate-cw"
                        colour="currentColor"
                        onClick={refetchCulture}>
                        Refresh
                    </Button>
                    <Button
                        variant="tertiary"
                        size="s"
                        iconRight="arrow-into-circle"
                        colour="currentColor"
                        onClick={() =>
                            window.dispatchEvent(
                                new Event(CultureScheduleEvents.ScrollToNext),
                            )
                        }>
                        Go to latest
                    </Button>
                    <Button
                        variant="tertiary"
                        size="s"
                        iconRight="collapse"
                        colour="currentColor"
                        onClick={() =>
                            window.dispatchEvent(
                                new Event(CultureScheduleEvents.CollapseAll),
                            )
                        }>
                        Collapse All
                    </Button>
                    <Button
                        variant="tertiary"
                        size="s"
                        iconRight="expand"
                        colour="currentColor"
                        onClick={() =>
                            window.dispatchEvent(
                                new Event(CultureScheduleEvents.ExpandAll),
                            )
                        }>
                        Expand All
                    </Button>
                </ButtonWrapper>
                <AnimateInWrapper
                    cultureControlsVisible={cultureControlsVisible}>
                    {deviceId && cultureId && (
                        <CultureControlButton
                            deviceId={deviceId}
                            cultureId={cultureId}
                            size="s"
                            variant="primary"
                            noun={isWetTest ? "Wet Tests" : "Culture"}
                            cultureHasProcedureToRun={cultureHasProcedureToRun}
                        />
                    )}
                </AnimateInWrapper>
            </ToolbarWrapper>
        </StickyWrapper>
    );
}

const AnimateInWrapper = styled.div<{ cultureControlsVisible: boolean }>`
    transition: transform 0.6s;
    transition-delay: ${props => (props.cultureControlsVisible ? "0.2s" : "")};
    transform: ${props =>
        props.cultureControlsVisible ? "translateY(0)" : "translateY(-200%)"};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        display: none;
    }
`;

const ButtonWrapper = styled.div<{ cultureControlsVisible: boolean }>`
    display: flex;
    align-items: center;
    justify-content: right;
    flex-wrap: wrap;
    gap: 8px;
    margin-left: auto;

    ${({ theme }) => theme.breakpoints.up("sm")} {
        transform: ${props =>
            props.cultureControlsVisible ? undefined : "translateX(130px)"};
        transition: transform 0.6s;
        transition-delay: ${props =>
            props.cultureControlsVisible ? undefined : "0.2s"};
    }
`;

const ToolbarWrapper = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 8px;
    gap: 8px;
    color: ${({ theme }) => theme.colours.neutral[500]};
`;

const StickyWrapper = styled.div`
    position: sticky;
    top: 64px; // matches AppBar height
    z-index: 10;
    background: white;
    color: ${({ theme }) => theme.colours.neutral[500]};
    border-bottom: 1px solid currentColor;
`;
