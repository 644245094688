import React, { CSSProperties, ReactElement } from "react";

import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import { useTheme, Theme } from "@mui/material/styles";
import { darken } from "polished";

import Icon, { IconName } from "./Icon";
import Txt from "./Text";

export interface CalloutCardProps {
    /**
     * Style of the Callout. Defaults to "info" if not specified.
     */
    variant?: CalloutVariant;
    /**
     * Children nodes that get inserted into the body of the callout
     */
    children?: React.ReactNode;
    /**
     * Override style is applied to the outermost component (Mui `Paper`)
     */
    style?: CSSProperties;
}

export interface CalloutProps extends CalloutCardProps {
    /**
     * Emphasised text on top row next to icon
     */
    title?: string;
    /**
     * Primary longer-length message. Render position depends on other props
     */
    message?: string;
}

type CalloutVariant = "info" | "warn" | "error" | "help";

type VariantStyle = {
    icon?: IconName;
    iconColor: string;
    borderColor: string;
    background: string;
    color: string;
};

const VARIANT_STYLES = (
    theme: Theme,
): { [k in CalloutVariant]: VariantStyle } => ({
    info: {
        icon: "alert-circle",
        iconColor: theme.colours.neutral[500],
        borderColor: theme.colours.neutral[300],
        background: theme.colours.neutral[100],
        color: theme.colours.neutral[700],
    },
    help: {
        icon: "help-book",
        iconColor: theme.colours.neutral[500],
        borderColor: theme.colours.neutral[300],
        background: theme.colours.neutral[100],
        color: theme.colours.neutral[700],
    },
    warn: {
        icon: "alert-triangle",
        borderColor: theme.colours.accent.alertOrange,
        background: theme.colours.accent.alertOrange + "1A",
        iconColor: theme.colours.accent.alertOrange,
        color: darken(0.1, theme.colours.accent.alertOrange), // TODO this should use an official darker shade rather than programmatic
    },
    error: {
        icon: "alert-cross",
        borderColor: theme.colours.accent.errorRed,
        background: theme.colours.accent.errorRed + "1A",
        iconColor: theme.colours.accent.errorRed,
        color: theme.colours.accent.errorRed,
    },
});

/**
 * Similar to toasts, but sit within the page’s content, Callouts are designed
 * to highlight a particularly important piece of information. These can be all
 * kinds of sizes/lengths and have plenty of variations.
 *
 * The default variant is `info`. However you can specify this to use different
 * themes that match the type of information being provided (e.g. `warn` or `error`).
 *
 * There are three ways to provide content:
 * - `title` prop
 * - `message` prop
 * - `children` React nodes
 *
 * Note that if both `title` and `message` are present, then `children` will not
 * be rendered.
 */
export default function Callout(props: CalloutProps): ReactElement {
    const { title, message, variant = "info", children, style } = props;
    const theme = useTheme();
    const variantStyles = VARIANT_STYLES(theme);
    const { icon, iconColor } = variantStyles[variant];

    const topRow = title ?? message ?? children;
    const bottomRow = title
        ? (message ?? children)
        : message
          ? children
          : undefined;

    return (
        <CalloutCard variant={variant} style={style}>
            <div style={{ display: "flex" }}>
                {icon && (
                    <Icon
                        name={icon}
                        level={8}
                        sx={{
                            marginRight: theme => theme.spacing(3),
                            color: iconColor,
                        }}
                    />
                )}
                <Txt
                    font="secondary"
                    level={8}
                    emphasis={Boolean(title)}
                    style={{ flexGrow: 1 }}>
                    {topRow}
                </Txt>
            </div>
            {bottomRow && (
                <Txt
                    font="secondary"
                    level={8}
                    style={{ width: "100%", overflowWrap: "break-word" }}>
                    {bottomRow}
                </Txt>
            )}
        </CalloutCard>
    );
}

/**
 * Base component for the CalloutCard.
 * This component is used by the `Callout` component to provide a styled paper background.
 * The `Callout` component builds on top of this base component, providing consistent icons, titles, and message usage.
 */
export function CalloutCard(props: CalloutProps): ReactElement {
    const { variant = "info", children, style } = props;
    const theme = useTheme();

    const variantStyles = VARIANT_STYLES(theme);
    const { borderColor, background, color } = variantStyles[variant];

    return (
        <BaseCalloutCard
            style={{
                borderColor,
                background,
                color,
                ...style,
            }}>
            {children}
        </BaseCalloutCard>
    );
}

const BaseCalloutCard = styled(Paper)`
    border: 1px solid;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: stretch;
    box-shadow: none;
`;
