import { ReactElement, ReactNode } from "react";

import { useQuery } from "@apollo/client";
import { css } from "@emotion/react";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import { gql } from "__generated__/apollo";
import { DeviceSoftwareVersionQuery } from "__generated__/apollo/graphql";
import { CodeText } from "components/common/CodeText";
import Icon, { IconName } from "components/common/Icon";
import { SoftwareUpdateBadge } from "components/common/SoftwareUpdateBadge";
import Txt from "components/common/Text";
import { TextLabel } from "components/common/TextLabel";
import { formatDatetime, relativeDatetime } from "services/date";
import { useDeviceRole } from "services/hooks/useDeviceRole";
import { useUser } from "services/hooks/useUser";

import { log as parentLog } from "../log";

import ChangeFleetDialog from "./ChangeFleetDialog";
import PinDeviceDialog from "./PinDeviceDialog";

export const log = parentLog.extend("DeviceSoftware");

type DeviceSoftwareProps = {
    deviceId: string;
};

const DEVICE_SOFTWARE_VERSION_QUERY = gql(`
    query DeviceSoftwareVersion($deviceId: String!) {
        device(id: $deviceId) {
            id
            balena {
                currentCommit
                currentCommitDate
                targetCommit
                fleetName
                fleetTargetCommit
                pinned
                updateAvailable
                updating
            }
        }
    }
`);

type Device = NonNullable<DeviceSoftwareVersionQuery["device"]>;
type Balena = NonNullable<Device["balena"]>;

export function useDeviceSoftwareVersion(options: {
    deviceId: string;
    pollInterval?: number;
}): Balena | null {
    const { deviceId, pollInterval } = options;
    const { data } = useQuery(DEVICE_SOFTWARE_VERSION_QUERY, {
        variables: { deviceId },
        pollInterval, // milliseconds
    });
    return data?.device?.balena ?? null;
}

export function DeviceSoftwareCard(props: DeviceSoftwareProps): ReactElement {
    const { deviceId } = props;

    const { isAdmin } = useDeviceRole(deviceId);
    const { isMytosUser } = useUser();

    const data = useDeviceSoftwareVersion({ deviceId, pollInterval: 15_000 });

    const {
        currentCommit,
        currentCommitDate,
        targetCommit,
        fleetName,
        pinned = false,
        updateAvailable = false,
        updating = false,
    } = data ?? {};

    log.debug("Software version", data);

    const currentRelease = currentCommit?.slice(0, 7) ?? "unknown";
    const targetRelease = targetCommit?.slice(0, 7) ?? "unknown";

    const softwareAge = currentCommitDate
        ? formatDatetime(currentCommitDate)
        : "Unknown";
    const outOfDate = currentCommitDate
        ? relativeDatetime(currentCommitDate)
        : "some time ago";

    return (
        <div style={{ marginBottom: 32 }}>
            <DeviceSoftwareCardSurface
                updateAvailable={updateAvailable}
                updating={updating}>
                <div
                    id="device-software-card-header"
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                    `}>
                    <div
                        css={css`
                            display: flex;
                            gap: 12px;
                        `}>
                        <Icon name="software-download" size="lg" />
                        <Txt level={4} display="inline">
                            Software
                        </Txt>
                    </div>

                    <SoftwareUpdateBadge
                        deviceId={props.deviceId}
                        updateAvailable={updateAvailable}
                        updating={updating}
                    />
                </div>
                <Grid container spacing={10}>
                    <Grid item>
                        <TextLabel
                            label="Release date"
                            childrenDirection="column">
                            <Txt level={6}>{softwareAge}</Txt>
                            <Txt level={9} italic>
                                {outOfDate}
                            </Txt>
                        </TextLabel>
                    </Grid>
                    <Grid item>
                        <TextLabel label="Fleet">
                            <div style={{ display: "flex", gap: 4 }}>
                                <Txt level={6}>{fleetName ?? "Unknown"}</Txt>
                                {isAdmin && isMytosUser && (
                                    <ChangeFleetDialog
                                        currentFleetName={
                                            fleetName ?? "Unknown"
                                        }
                                        deviceId={deviceId}
                                    />
                                )}
                            </div>
                        </TextLabel>
                    </Grid>
                    <Grid item>
                        <TextLabel label="Current version">
                            <CodeText
                                text={currentRelease}
                                copyText={currentCommit ?? undefined}
                            />
                        </TextLabel>
                    </Grid>
                    <Grid item>
                        <TextLabel label="Updates">
                            <div style={{ display: "flex", gap: 4 }}>
                                <TrackingBadge pinned={pinned} />
                                {isAdmin && isMytosUser && (
                                    <PinDeviceDialog
                                        deviceId={deviceId}
                                        currentPinnedCommit={
                                            pinned
                                                ? (targetCommit ?? undefined)
                                                : undefined
                                        }
                                    />
                                )}
                            </div>
                        </TextLabel>
                    </Grid>
                    {updateAvailable && (
                        <Grid item>
                            <TextLabel label="Available version">
                                <CodeText
                                    text={targetRelease}
                                    copyText={targetCommit ?? undefined}
                                />
                            </TextLabel>
                        </Grid>
                    )}
                </Grid>
            </DeviceSoftwareCardSurface>
        </div>
    );
}

function DeviceSoftwareCardSurface(props: {
    children: ReactNode;
    updateAvailable: boolean;
    updating: boolean;
}): ReactElement {
    const theme = useTheme();
    const accentColour = props.updating
        ? theme.colours.accent.errorRed
        : props.updateAvailable
          ? theme.colours.accent.alertOrange
          : theme.colours.neutral[300];

    const normalBackground = "linear-gradient(90deg, #ffffff 0%, #f0fafa 100%)";
    const alertBackground = "linear-gradient(90deg, #FFFFFF 0%, #FFEBE0 100%)";

    const background =
        props.updateAvailable || props.updating
            ? alertBackground
            : normalBackground;

    const footerMessage: string | null = props.updating
        ? "Device cannot be used whilst a software update is in progress."
        : props.updateAvailable
          ? "To update this Device, contact Support."
          : null;

    return (
        <div
            id="device-software-card"
            css={css`
                background: ${background};
                border: 1px solid ${accentColour};
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0px 5px 15px 0px #00000012;
            `}>
            <div
                id="device-software-card-content"
                css={css`
                    padding: 24px;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                `}>
                {props.children}
            </div>
            {footerMessage && (
                <div
                    css={css`
                        background-color: ${accentColour};
                        color: white;
                        padding: 10px 24px;
                    `}>
                    <Txt font="secondary" emphasis level={7}>
                        {footerMessage}
                    </Txt>
                </div>
            )}
        </div>
    );
}

function TrackingBadge(props: { pinned: boolean }): ReactElement {
    const theme = useTheme();
    const colour = theme.colours.neutral[700];
    const text: string = props.pinned ? "Pinned" : "Tracking";
    const icon: IconName = props.pinned ? "pin" : "arrow-into-circle";
    const tooltip: string = props.pinned
        ? "Device won't keep up to date with the latest software automatically"
        : "Device will keep up to date with the latest software automatically";
    return (
        <Tooltip title={tooltip}>
            <div
                css={css`
                    border: 1px solid ${colour};
                    border-radius: 4px;
                    color: ${colour};
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    padding: 2px 6px;
                    cursor: pointer;
                `}>
                <Icon name={icon} size="sm" />
                <Txt font="secondary" emphasis level={10}>
                    {text}
                </Txt>
            </div>
        </Tooltip>
    );
}
