import {
    ApolloClient,
    ApolloLink,
    Operation,
    HttpLink,
    DefaultOptions,
    ServerError,
    ServerParseError,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLFormattedError } from "graphql";

import { log as servicesLog } from "services";
import { authentication, createAuthHeader } from "services/auth";
import { config } from "services/config";
import { createId } from "services/utils";

import { cache } from "./cache";

const log = servicesLog.extend("apollo:client");

export function errorIsUnauthenticated(
    graphQLError: GraphQLFormattedError,
): boolean {
    return graphQLError.extensions?.code === "UNAUTHENTICATED";
}

function handleGraphqlErrors(
    graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
    operation: Operation,
) {
    let shouldSignout = false;
    graphQLErrors.forEach(graphQLError => {
        const { message, locations, path } = graphQLError;

        if (errorIsUnauthenticated(graphQLError)) {
            log.debug("Error code was UNAUTHENTICATED. Planning signout.");
            shouldSignout = true;
        }

        const locationsString = JSON.stringify(locations);
        const newMessage = `[GraphQL Error] Operation ${operation.operationName}: ${message}, Location: ${locationsString}, Path: ${path}`;
        const updatedGraphQLError = { ...graphQLError, message: newMessage };
        log.error(newMessage, { error: updatedGraphQLError });
    });
    if (shouldSignout) {
        log.debug("Signing out.");
        void authentication.signout();
    }
}

type MergedNetworkError = Partial<Error> &
    Partial<ServerParseError> &
    Partial<ServerError>;

function is4xx(statusCode: number | undefined): boolean {
    return statusCode !== undefined && statusCode >= 400 && statusCode < 500;
}

function handleNetworkError(networkError: MergedNetworkError) {
    if (networkError.statusCode === 401) {
        log.debug("Network Error status code 401. Logging out.");
        void authentication.signout();
    } else {
        if (is4xx(networkError.statusCode)) {
            networkError.name = "ClientError";
        }
        log.error(`[Network error]: ${networkError}`, {
            error: networkError,
        });
    }
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        handleGraphqlErrors(graphQLErrors, operation);
    }
    if (networkError) {
        handleNetworkError(networkError);
    }
    if (!graphQLErrors && !networkError) {
        log.error("Apollo client detected an error but nothing was returned", {
            graphQLErrors,
            networkError,
        });
    }
});

const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: createAuthHeader(),
        },
    };
});

const requestIdLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            "x-request-id": createId(),
        },
    };
});

/**
 * Custom fetcher for the HTTP Link of Apollo Client. Used to ensure Logrocket
 * can successfully capture all HTTP requests.
 *
 * https://docs.logrocket.com/docs/graphql#apollo-client-with-graphql
 */
const fetcher: typeof window.fetch = (...args) => {
    return window.fetch(...args);
};

const httpLink = new HttpLink({
    uri: ({ operationName }: Operation) => {
        const origin = config.serverUrl;
        const graphqlPath = config.serverPathForGraphQL;
        const uri = origin + graphqlPath + "/" + operationName;
        log.debug("URI for HTTP request:", uri);
        return uri;
    },
    credentials: "include",
    fetch: fetcher,
});

/**
 * You can override any default option you specify in this object by providing a
 * different value for the same option in individual function calls.
 *
 * Note: The `useQuery` hook uses Apollo Client's `watchQuery` function. To set
 * `defaultOptions` when using the `useQuery` hook, make sure to set them under
 * the `defaultOptions.watchQuery` property.
 *
 * Reference: https://www.apollographql.com/docs/react/api/core/ApolloClient/
 */
const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all", // will return partial data even if error occurred
    },
    query: {
        errorPolicy: "all", // will return partial data even if error occurred
    },
    mutate: {
        errorPolicy: "all", // will return partial data even if error occurred
    },
};

export const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, requestIdLink, httpLink]),
    cache,
    name: "app-web",
    version: config.gitHash,
    defaultOptions,
});
