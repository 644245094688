/**
 * Extracts the value type of a record
 */
type ValueOf<T> = T[keyof T];

/**
 * Object entries type
 */
type ObjectEntries<T extends Record<string, unknown>> = readonly [
    keyof T,
    ValueOf<T>,
][];

/**
 * Equivalent to Object.fromEntries, but preserves type information
 */
export function objectFromEntriesTyped<T extends Record<string, unknown>>(
    entries: ObjectEntries<T>,
): T {
    return Object.fromEntries(entries) as T;
}
