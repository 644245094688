import { useToasts } from "components/common/toasts/useToasts";
import { createAuthHeader } from "services/auth";
import { config } from "services/config";

import { log as parentLog } from "./log";

export const log = parentLog.extend("CultureDataDownload");

export function useDownloadCultureData(): {
    downloadData: (cultureId?: string) => Promise<void>;
} {
    const { toast } = useToasts();
    const downloadData = async (cultureId?: string) => {
        const toastId = toast.loading("Downloading data");
        try {
            if (!cultureId) {
                throw new Error("No culture ID provided");
            }
            const endpoint = new URL(
                `cultures/${cultureId}/download`,
                config.serverUrl,
            );
            const authorization = createAuthHeader();
            const res = await fetch(endpoint.href, {
                headers: { authorization },
                credentials: "include",
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = `culture-data-${cultureId}.csv`;
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            link?.parentNode?.removeChild(link);

            log.debug("Saved culture data");
            toast.update(toastId, {
                type: "success",
                render: "Data file saved",
                isLoading: false,
            });
        } catch (error) {
            log.error("Error downloading culture data", error);
            toast.update(toastId, {
                type: "error",
                render: "Unable to download data",
                isLoading: false,
            });
        }
    };
    return { downloadData };
}
