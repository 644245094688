import { Fragment, ReactElement, useState } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import { throttle } from "lodash";
import { darken } from "polished";

import { InvocationState } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Txt from "components/common/Text";
import { client } from "services/apollo/client";
import { dayjs } from "services/date";
import {
    Invocation,
    STEP_INVOCATIONS_QUERY,
} from "services/hooks/useCultureStepInvocations";
import { useDeviceRole } from "services/hooks/useDeviceRole";
import { useRenderTimer } from "services/hooks/useRenderTimer";
import { clamp } from "services/utils";

import { determineInvocationStatusString } from "./invocation-utils";
import { InvocationControls } from "./InvocationControls";
import { InvocationDetails } from "./InvocationDetails";
import { InvocationStateIcon } from "./InvocationStateIcon";
import { log as parentLog } from "./log";

export const log = parentLog.extend("InvocationItem");

export interface InvocationItemProps {
    deviceId: string;
    invocation: Invocation;
}

export function InvocationItem(props: InvocationItemProps): ReactElement {
    const { invocation } = props;

    const theme = useTheme();
    const { canOperate } = useDeviceRole(props.deviceId);
    const [collapsed, setCollapsed] = useState(true);

    const errorMessage = invocation.errorMessage;

    const backgroundColour = theme.colours.neutral.white;
    return (
        <div
            onClick={() => setCollapsed(!collapsed)}
            css={css`
                position: relative;
                border: 1px solid ${theme.colours.neutral[300]};
                border-radius: 4px;
                background-color: ${backgroundColour};
                padding: 12px;
                overflow: hidden;
                &:hover {
                    background-color: ${darken(0.01)(backgroundColour)};
                }
            `}>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}>
                <div style={{ marginRight: 8 }}>
                    <InvocationStateIcon
                        state={invocation.state ?? InvocationState.Unknown}
                    />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <Txt font="secondary" level={8}>
                        {invocation.description}
                    </Txt>
                    <InvocationStatusText invocation={invocation} />
                </div>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}>
                    {canOperate && <InvocationControls {...props} />}
                </div>
            </div>
            {errorMessage && (
                <Callout
                    variant="error"
                    message={errorMessage}
                    style={{ margin: "8px 0" }}
                />
            )}
            <Collapse
                in={!collapsed}
                style={{ width: "100%" }}
                mountOnEnter
                unmountOnExit>
                <InvocationDetails {...props} />
            </Collapse>
            <div
                css={css`
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                `}>
                <InvocationProgressBar
                    state={invocation.state}
                    timeStarted={invocation.timeStarted}
                    estimatedDuration={invocation.estimatedDuration}
                />
            </div>
        </div>
    );
}

function InvocationStatusText(props: { invocation: Invocation }): ReactElement {
    const isRunning = InvocationState.Running === props.invocation.state;
    // we use this to force recompute and display of the counter
    useRenderTimer({ seconds: 1, enabled: isRunning });
    const statusString = determineInvocationStatusString(props.invocation);
    return (
        <Txt
            font="secondary"
            level={9}
            sx={{ color: theme => theme.colours.neutral[700] }}>
            {statusString}
        </Txt>
    );
}

const refetchStepInvocations = () =>
    client.refetchQueries({
        include: [STEP_INVOCATIONS_QUERY],
    });

const refetchStepInvocationsThrottled = throttle(
    refetchStepInvocations,
    1_000, // ms
);

function InvocationProgressBar(props: {
    state: InvocationState | null;
    timeStarted: string | null;
    estimatedDuration: number | null;
}): ReactElement {
    const isRunning = InvocationState.Running === props.state;
    useRenderTimer({ seconds: 0.2, enabled: isRunning });
    if (!isRunning) return <Fragment />;
    if (props.estimatedDuration === null || props.timeStarted === null) {
        return <LinearProgress variant="indeterminate" />;
    }
    const msSinceStart = dayjs().diff(props.timeStarted, "millisecond");
    const MIN = 0;
    const MAX: number = props.estimatedDuration;
    const clampedTime = clamp(msSinceStart, { min: 0, max: MAX });
    const normalise = (value: number) => ((value - MIN) * 100) / (MAX - MIN);
    const normalisedTime = normalise(clampedTime);
    if (msSinceStart > MAX) {
        // we begin requesting a refetch of the data regularly because we
        // believe we are over the estimated duration anyways
        void refetchStepInvocationsThrottled();
    }
    return <LinearProgress variant="determinate" value={normalisedTime} />;
}
