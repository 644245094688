import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

import Icon from "components/common/Icon";

const ConfirmationRequiredState = styled.div`
    width: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colours.orange[500]};
`;

const ConfirmationIcon = styled(Icon)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export function ConfirmationRequiredIcon() {
    const theme = useTheme();

    return (
        <ConfirmationRequiredState>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.528809 6.05529L6.05523 0.52887L13.8708 0.52887L19.3972 6.05529V13.8708L13.8708 19.3972H6.05523L0.528809 13.8708L0.528809 6.05529Z"
                    fill={theme.colours.neutral.white}
                    stroke={theme.colours.accent.alertOrange}
                />
            </svg>
            <ConfirmationIcon name="touch-select" size="sm" />
        </ConfirmationRequiredState>
    );
}
