import { InMemoryCache, InMemoryCacheConfig } from "@apollo/client/cache";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";

import {
    mergePaginatedResults,
    readPaginatedResults,
} from "./cache-pagination";

export const cacheConfig: InMemoryCacheConfig = {
    typePolicies: {
        Device: {
            fields: {
                users: {
                    merge: true,
                },
                balena: {
                    merge: true,
                },
            },
        },
        Culture: {
            fields: {
                schedule: {
                    merge: true,
                },
                // The below is used to make pagination work when fetching pages of images
                // See here for details: https://www.apollographql.com/docs/react/pagination/cursor-based#keeping-cursors-separate-from-items
                results: {
                    // We want to merge the cache for results that only differ by pagination arguments
                    // But we don't want to merge any results that contain different filters - as these are logically different queries
                    // https://www.apollographql.com/docs/react/pagination/key-args/
                    keyArgs: ["filterBy"],
                    merge: (existing, incoming) => {
                        return mergePaginatedResults({ existing, incoming });
                    },

                    read(existing, { readField }) {
                        return readPaginatedResults({
                            sortByField: "timestampISO",
                            existing,
                            readField,
                        });
                    },
                },
            },
        },
        Step: {
            fields: {
                runtimeReference: {
                    merge: true,
                },
                invocations: {
                    merge: true,
                },
            },
        },
    },
};

/**
 * Controlled behaviour of Apollo cache
 *
 * Read more:
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior/
 */
export const cache = new InMemoryCache(cacheConfig);

export const cachePersistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
});

restoreCacheIfPossible();

/**
 * Restore the cache from local storage so long as we haven't changed the structure of the cache (determined by the cache config).
 *
 * If we've changed the cache config we do not restore as it can lead to bugs when data in the old format is loaded. In this case we clear the cache instead.
 */
function restoreCacheIfPossible() {
    // Stringify the cache config, serializing functions to strings
    const cacheKey = JSON.stringify(cacheConfig, (_, value) => {
        if (typeof value === "function") {
            return value.toString();
        }

        return value;
    });
    const localStorageKeyName = "mytos-apollo-cache-key";

    const lastCacheKey = localStorage.getItem(localStorageKeyName);

    if (lastCacheKey === cacheKey) {
        // Note in future we may want to await the restoration such that the app doesn't
        // render until cache is restored (since this method is asynchronous). For
        // further reading on how this could be achieved (likely with hooks), check:
        // https://github.com/apollographql/apollo-cache-persist#how-do-i-wait-for-the-cache-to-be-restored-before-rendering-my-app
        void cachePersistor.restore();
    } else {
        localStorage.setItem(localStorageKeyName, cacheKey);
        void cachePersistor.purge();
    }
}
