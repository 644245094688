import { ReactElement } from "react";

import { isError, isUndefined } from "lodash";

import {
    getLinearMultiViewModalViewProps,
    LinearMultiViewModalClickHandler,
} from "components/common/Modal/modal-utils";
import { MultiViewModal } from "components/common/Modal/MultiViewModal";
import { useToasts } from "components/common/toasts/useToasts";
import { useFeature } from "services/feature-flags";

import { AutomatedStartChecksView } from "./AutomatedChecks/AutomatedChecksView";
import { useAutomatedChecks } from "./AutomatedChecks/useAutomatedChecks";
import { CellLineMetadataView } from "./CellLineMetadata/CellLineMetadataView";
import { CellPreparationView } from "./CellPreparation/CellPreparationView";
import { useCellPreparationInfo } from "./CellPreparation/useCellPreparationData";
import { LegacyConfirmView } from "./ConfirmView";
import {
    CreateCultureModalViewId,
    useCultureCreationModalOpen,
    useCultureCreationModalViewId,
    useLegacyCultureUploadType,
} from "./create-culture-state";
import {
    useAutomatedStartCheckOverride,
    useDrySetupComplete,
    useDryTestOverride,
} from "./CreateCultureState";
import { CultureUploadType } from "./culture-setup-state";
import { DrySetupView } from "./DrySetup/DrySetupView";
import { DryTestsView } from "./DryTests/DryTestsView";
import { useDeviceDryTests } from "./DryTests/useDeviceDryTests";
import { log } from "./log";
import {
    useCellLineMetadata,
    useCreateAndSyncCulture,
    useDeviceUploadConditions,
    useProtocolState,
} from "./protocol-upload-hooks";
import { ReviewBottlesView } from "./ReviewBottles/ReviewBottlesView";
import { LegacySelectProtocolView } from "./SelectProtocol/SelectProtocolView";
import { useCreateProtocol } from "./useCreateProtocol";

export interface CreateCultureModalProps {
    deviceId: string;
}

export function CreateCultureModal(
    props: CreateCultureModalProps,
): ReactElement {
    const { deviceId } = props;

    const { toast } = useToasts();

    const [modalOpen, setModalOpen] = useCultureCreationModalOpen();
    const [viewId, setViewId] = useCultureCreationModalViewId();

    const [cultureUploadType] = useLegacyCultureUploadType();

    const { dryTestOverride } = useDryTestOverride();
    const { automatedStartCheckOverride } = useAutomatedStartCheckOverride();
    const isDevelopmentCultureUpload =
        cultureUploadType === CultureUploadType.Development;

    const drySetupPageEnabled = useFeature("dry_setup_kickoff_page").enabled;
    const dryTestPageEnabled = useFeature("dry_tests_kickoff_page").enabled;
    const statusChecksPageEnabled = useFeature(
        "automated_status_checks_kickoff_page",
    ).enabled;
    const cellMetadataPagesEnabled = useFeature(
        "cell_metadata_kickoff_pages",
    ).enabled;

    const {
        allComplete: allDryChecksComplete,
        someActive: someDryChecksActive,
    } = useDeviceDryTests({
        deviceId,
        // TODO(MYT-5601): Once modal flow is removed, remove skipQuery param
        // (In the new modal flow this hook is only mounted when the modal page is open)
        skipQuery: !modalOpen,
    });

    const enableDryCheckNextButton =
        (allDryChecksComplete && !someDryChecksActive) || dryTestOverride;

    const { allPass: allAutomatedChecksPass } = useAutomatedChecks(deviceId);

    const { drySetupComplete, setDrySetupComplete } = useDrySetupComplete();

    const { protocolObject, cultureTimezone, cultureStartDate } =
        useProtocolState();

    const { deviceCanAcceptCultureUpload } =
        useDeviceUploadConditions(deviceId);

    // populating protocol data happens within SelectProtocolView
    const {
        clearProtocol: clearGqlProtocolData,
        createProtocol,
        protocolData,
        uploadingProtocol,
    } = useCreateProtocol();

    const { createAndSyncCulture, creatingCulture } =
        useCreateAndSyncCulture(deviceId);

    const { protocolHasSeedProcedure, cellConcentration } =
        useCellPreparationInfo(protocolData);

    const { cellLine, passageNumber, vialID, cellFormat } =
        useCellLineMetadata();

    const invalidProtocolObject =
        isError(protocolObject) || isUndefined(protocolObject);

    const hasValidCultureMetadata = Boolean(
        cultureTimezone && cultureStartDate,
    );

    const enableCreateCultureButton =
        deviceCanAcceptCultureUpload &&
        !invalidProtocolObject &&
        hasValidCultureMetadata &&
        !creatingCulture;

    const showCellPreparationPages =
        cultureUploadType !== CultureUploadType.Development &&
        protocolHasSeedProcedure &&
        cellMetadataPagesEnabled;

    log.debug("upload/create culture button state", {
        deviceCanAcceptCultureUpload,
        protocolData,
        creatingCulture,
        uploadingProtocol,
        hasValidCultureMetadata,
        enableCreateCultureButton,
    });

    const handleCreateProtocol: LinearMultiViewModalClickHandler = async () => {
        if (!protocolObject || isError(protocolObject)) {
            log.error("No protocol object. Cannot attempt upload.");
            return false;
        }
        const toastId = toast.loading("Checking protocol...");

        const { ok, message } = await createProtocol({
            protocol: protocolObject,
            ignoreWetTests: true,
        });

        log.debug("Attempting to create protocol");

        if (ok) {
            log.debug({ ok, message }, "protocol creation successful");
            toast.dismiss([toastId]);
        } else {
            log.error({ ok, message }, "protocol creation failed");
            toast.update(toastId, {
                render: `Protocol failed checks. Please check it is using an up-to-date format.`,
                type: "error",
                isLoading: false,
            });
            clearGqlProtocolData();
        }

        return ok;
    };

    const handleCreateCulture: LinearMultiViewModalClickHandler = async () => {
        // Note that the below conditions put this button in a disabled state,
        // hence we abort immediately if we face this unexpected behaviour
        if (!protocolData) {
            log.error("No protocol data. Cannot attempt upload.");
            return false;
        }
        if (!(cultureTimezone && cultureStartDate)) {
            log.error("Invalid culture metadata. Cannot attempt upload.");
            return false;
        }

        const toastId = toast.loading("Creating culture...");
        log.debug("Attempting to create culture");

        const { ok, message } = await createAndSyncCulture({
            protocolId: protocolData.id,
            skipValidation: cultureUploadType === CultureUploadType.Development,
            cultureMetadata: {
                cellLine: cellLine || null,
                passageNumber,
                vialID: vialID || null,
                initialSeedCellConcentration: cellConcentration,
                cellFormat: cellFormat,
            },
            startDate: cultureStartDate.format("YYYY-MM-DD"),
            timezone: cultureTimezone,
        });

        if (ok) {
            log.debug({ ok, message }, "culture creation successful");
            toast.update(toastId, {
                render: "Culture created",
                type: "success",
                isLoading: false,
            });
            clearGqlProtocolData();
        } else {
            log.error({ ok, message }, "culture creation failed");
            toast.update(toastId, {
                render: `Failed to create culture`,
                type: "error",
                isLoading: false,
            });
        }

        return ok;
    };

    const views = getLinearMultiViewModalViewProps({
        setModalOpen,
        setViewId,
        idsAndViews: [
            [
                CreateCultureModalViewId.AutomatedChecks,
                {
                    render: () => (
                        <AutomatedStartChecksView deviceId={deviceId} />
                    ),
                    buttonRight: {
                        disabled:
                            !allAutomatedChecksPass &&
                            !automatedStartCheckOverride,
                    },
                    omit:
                        isDevelopmentCultureUpload || !statusChecksPageEnabled,
                },
            ],
            [
                CreateCultureModalViewId.DrySetup,
                {
                    render: () => (
                        <DrySetupView
                            deviceId={deviceId}
                            drySetupComplete={drySetupComplete}
                            setDrySetupComplete={setDrySetupComplete}
                        />
                    ),
                    buttonRight: { disabled: !drySetupComplete },
                    omit: isDevelopmentCultureUpload || !drySetupPageEnabled,
                },
            ],
            [
                CreateCultureModalViewId.DryTests,
                {
                    render: () => <DryTestsView deviceId={deviceId} />,
                    buttonRight: { disabled: !enableDryCheckNextButton },
                    omit: isDevelopmentCultureUpload || !dryTestPageEnabled,
                },
            ],

            [
                CreateCultureModalViewId.SelectProtocol,
                {
                    render: () => <LegacySelectProtocolView />,
                    buttonRight: {
                        disabled: invalidProtocolObject || uploadingProtocol,
                        onClick: handleCreateProtocol,
                    },
                },
            ],
            [
                CreateCultureModalViewId.ReviewBottles,
                {
                    render: () => {
                        return protocolData?.id ? (
                            <ReviewBottlesView protocolId={protocolData.id} />
                        ) : (
                            <></>
                        );
                    },
                },
            ],
            [
                CreateCultureModalViewId.CellLineMetadata,
                {
                    render: () => <CellLineMetadataView />,
                    omit: !showCellPreparationPages,
                },
            ],
            [
                CreateCultureModalViewId.CellPreparation,
                {
                    render: () => (
                        <CellPreparationView protocolId={protocolData?.id} />
                    ),
                    omit: !showCellPreparationPages,
                },
            ],
            [
                CreateCultureModalViewId.CultureMetadata,
                {
                    render: () => (
                        <LegacyConfirmView
                            protocolName={protocolData?.name ?? undefined}
                        />
                    ),
                    buttonRight: {
                        label: creatingCulture ? "Uploading" : "Upload",
                        disabled: !enableCreateCultureButton,
                        onClick: handleCreateCulture,
                    },
                },
            ],
        ],
    });

    return (
        <MultiViewModal
            title="New Culture"
            views={views}
            viewId={viewId}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            maxWidth="sm"
        />
    );
}
