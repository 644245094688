import { ReactElement, useState } from "react";

import { Box, FormHelperText, OutlinedInput, styled } from "@mui/material";

import { fontFamilyMap, textLevelFontSize, textLevelLineHeight } from "./Text";

export interface TimeInputProps {
    onChange: (newValue: string) => void;
    /**
     * Valid time string to set minimum e.g. "07:00"
     */
    min?: string;
    /**
     * Valid time string to set maximum e.g. "18:00"
     */
    max?: string;
    /**
     * Default value is the initial value of the input. Should be provided in
     * time format, e.g. "18:00"
     */
    defaultValue?: string;
    /** Text string displayed to help user */
    helperText?: string;
    /**
     * If `true`, helper text should be displayed in an error state.
     */
    error?: boolean;
}

export function TimeInput({
    onChange,
    min,
    max,
    defaultValue,
    error,
    helperText,
}: TimeInputProps): ReactElement {
    const [value, setValue] = useState(defaultValue ?? "");

    const updateValue = (v: string) => {
        setValue(v);
        onChange(v);
    };

    const parseValue = (v: string): void => {
        if (min && v < min) {
            updateValue(min);
            return;
        }
        if (max && v > max) {
            updateValue(max);
            return;
        }
        updateValue(v);
    };

    return (
        <StyledInputWrapper>
            <OutlinedInput
                value={value}
                onChange={e => parseValue(e.target.value)}
                type="time"
                error={error}
            />
            <FormHelperText error={error}>{helperText}</FormHelperText>
        </StyledInputWrapper>
    );
}

const StyledInputWrapper = styled(Box)(({ theme }) => ({
    "display": "flex",
    "flexDirection": "column",
    "& .MuiOutlinedInput-root": {
        "height": 32,
        "border": "1px solid " + theme.colours.neutral[300],
        "borderRadius": 4,
        "width": 100,
        "textAlign": "center",
        "fontFamily": fontFamilyMap.secondary,
        "fontSize": textLevelFontSize[8],
        "&.Mui-error": {
            "borderColor": theme.colours.orange[500],
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
            },
        },
    },
    "& .MuiFormHelperText-root": {
        "marginLeft": 0,
        "marginTop": 4,
        "fontFamily": fontFamilyMap.secondary,
        "fontSize": textLevelFontSize[9],
        "lineHeight": textLevelLineHeight[9],
        "&.Mui-error": {
            color: theme.colours.orange[500],
        },
    },
}));
