import { ReactElement } from "react";

import { css } from "@emotion/react";
import { Badge, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Icon from "components/common/Icon";

export interface NotificationsButtonProps {
    badgeNumber: number;
    onClick: () => void;
}

export function NotificationsButton(
    props: NotificationsButtonProps,
): ReactElement {
    const { badgeNumber, onClick } = props;
    const theme = useTheme();
    return (
        <IconButton
            onClick={onClick}
            edge="start"
            style={{ marginRight: "10px" }} // TODO parent's responsibility
            aria-label="menu">
            <Badge
                badgeContent={badgeNumber}
                max={99}
                css={css`
                    .MuiBadge-badge {
                        color: white;
                        background-color: ${theme.colours.accent.alertOrange};
                    }
                `}>
                <Icon name="bell" colourOverride={theme.colours.neutral[700]} />
            </Badge>
        </IconButton>
    );
}
