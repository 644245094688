/* eslint-disable no-console */
import React, { ReactElement } from "react";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { config } from "./config";
import { setSentryScope } from "./sentry";

const PUBLIC_POSTHOG_KEY = "phc_ryj1WWzfPS6hDp5WbrNATdrxuSbrrE8Kbd7MsqeblLM";

if (typeof window !== "undefined") {
    console.log("PostHog initialising...");

    // Replace the default "https://eu.i.posthog.com" with our own reverse proxy
    // managed with Netlify to avoid adblockers.
    const posthogReverseProxyUrl = new URL(window.location.origin);
    posthogReverseProxyUrl.pathname = "/r/posthog/ingest"; // see redirects in netlify.toml

    const api_host: string = config.isLocal
        ? "https://eu.i.posthog.com"
        : posthogReverseProxyUrl.toString();

    posthog.init(PUBLIC_POSTHOG_KEY, {
        api_host,
        ui_host: "https://eu.posthog.com",
        // When hosting locally, we don't want to be sending data to PostHog so
        // we default to disable and enable it under certain conditions.
        disable_session_recording: true,
        enable_recording_console_log: true,
        loaded: posthog => {
            console.log("PostHog loaded");

            posthog.onSessionId(sessionId => {
                console.log("New PostHog Session ID", sessionId);
                const sessionUrl = posthog.get_session_replay_url();
                console.log("Session URL", sessionUrl);
                setSentryScope("PostHog Session URL", sessionUrl);
            });

            if (!config.isLocal) {
                posthog.startSessionRecording();
            }
        },
    });
}

export function identifyUserInPostHog(
    id: string,
    additional: {
        email: string;
        [propName: string]: string | number | boolean;
    },
): void {
    posthog.identify(id, { ...additional });
}

export interface PostHogProps {
    children: React.ReactNode;
}

export function PostHog(props: PostHogProps): ReactElement {
    return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>;
}
