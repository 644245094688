import React, { ReactElement } from "react";

import { useMutation } from "@apollo/client";
import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useWindowWidth } from "@react-hook/window-size";
import { motion } from "framer-motion";

import { gql } from "__generated__/apollo";
import { CreateInvocationMutationVariables } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import { IconButton } from "components/common/IconButton";
import Modal from "components/common/Modal";
import Txt from "components/common/Text";
import { useToasts } from "components/common/toasts/useToasts";
import { StepInvocationsQueryName } from "services/hooks/useCultureStepInvocations";
import {
    DeviceOperation,
    useDeviceOperationsQuery,
} from "services/hooks/useDeviceOperations";
import { useIsMobile } from "services/hooks/window";

import { log as parentLog } from "./log";
import { ParameterInput } from "./ParameterInput";
import { useAddOperationToStep } from "./Steps/addOperationToStep";

export const log = parentLog.extend("AddOperationModal");

export type AddOperationModalProps = {
    /** Device ID to retrieve the available operations and parameter reported by device */
    deviceId: string;
    /** Optional step ID to add an operation to a step, instead of legacy behaviour */
    stepId?: string;
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
};

export function AddOperationModal(props: AddOperationModalProps): ReactElement {
    const { modalOpen, setModalOpen, stepId } = props;
    const { toast } = useToasts();
    const { addOperationToStep } = useAddOperationToStep();
    const isMobile = useIsMobile();
    const winWidth = useWindowWidth();
    const [tabIndex, setTabIndex] = React.useState<number | undefined>(
        isMobile ? undefined : 0,
    );
    const [isOperationSelected, setIsOperationSelected] =
        React.useState<boolean>(tabIndex !== undefined);
    const [operationParameters, setOperationParameters] = React.useState<
        Record<string, Record<string, unknown>>
    >({});
    const response = useDeviceOperationsQuery(props.deviceId);
    const operations = response.data?.device?.operations;

    // This is the legacy behaviour, for the old schedule tab, which does not
    // let user select stepId
    const [createInvocationMutation] = useMutation(CREATE_INVOCATION_MUTATION, {
        refetchQueries: [StepInvocationsQueryName],
    });
    const changeTab = (index: number) => {
        setTabIndex(index);
        setIsOperationSelected(true);
    };

    const OperationModalContainer = (children: React.ReactNode) => (
        <Modal
            title="Add Operation to the schedule"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            buttonLeft={{ label: "Back", onClick: close => close() }}
            buttonRight={{
                label: "Confirm",
                onClick: close => {
                    void onSubmit();
                    close();
                },
            }}
            contentPadding={0}>
            {children}
        </Modal>
    );

    if (!operations)
        return OperationModalContainer(<Callout>Loading...</Callout>);

    const onSubmit = async () => {
        const selectedOperation =
            tabIndex !== undefined
                ? operations[tabIndex].operationId
                : undefined;
        if (!selectedOperation) return;
        const userSelectedParameters = operationParameters[selectedOperation];

        if (stepId) {
            await addOperationToStep({
                stepId,
                operationId: selectedOperation,
                parameters: userSelectedParameters ?? {},
            });
            return;
        }

        const variables: CreateInvocationMutationVariables = {
            input: {
                deviceId: props.deviceId,
                parameters: userSelectedParameters ?? {},
                operationId: selectedOperation,
            },
        };
        const toastId = toast.loading("Adding Operation");
        const res = await createInvocationMutation({ variables });
        if (res?.data?.createInvocation?.ok) {
            toast.update(toastId, {
                render: "Operation added",
                type: "success",
                isLoading: false,
            });
        } else {
            toast.update(toastId, {
                render: `Failed to add Operation: ${
                    res?.data?.createInvocation?.message ?? "Unknown error"
                }`,
                type: "error",
                isLoading: false,
            });
        }
    };

    const containerPosition = isMobile && isOperationSelected ? -winWidth : 0;

    return OperationModalContainer(
        <div
            style={{
                overflow: "hidden",
                position: "relative",
                width: "100%",
            }}>
            <motion.div
                animate={{ x: containerPosition }}
                transition={{ type: "linear" }}
                style={{
                    position: isMobile ? "absolute" : "static",
                    width: isMobile ? `${winWidth * 2}px` : `100%`,
                    height: "100%",
                    flexGrow: 1,
                    display: "flex",
                    textAlign: "left",
                    justifyContent: "stretch",
                    overflow: "hidden",
                }}>
                <div
                    id="OperationsListContainer"
                    style={{
                        width: isMobile ? "100%" : "30%",
                        height: "100%",
                        position: "relative",
                        overflowY: "auto",
                        display: "flex",
                        alignItems: "stretch",
                    }}>
                    <OperationsList
                        isMobile={isMobile}
                        operations={operations}
                        tabIndex={tabIndex}
                        changeTab={changeTab}
                    />
                </div>
                <div
                    id="OperationPanelContainer"
                    style={{
                        width: isMobile ? "100%" : "70%",
                        position: "relative",
                        height: "100%",
                        overflowY: "auto",
                        display: "block",
                    }}>
                    <SelectedOperationPanel
                        isMobile={isMobile}
                        operations={operations}
                        setIsOperationSelected={setIsOperationSelected}
                        tabIndex={tabIndex}
                        setInvocationParameters={setOperationParameters}
                        invocationParameters={operationParameters}
                    />
                </div>
            </motion.div>
        </div>,
    );
}

export type OperationListItemProps = { label: string; tabValue: number };

export type OperationTabPanelProps = Omit<DeviceOperation, "__typename"> & {
    value: string;
    index: number;
};

export type OperationTabsProps = {
    isMobile: boolean;
    operations: DeviceOperation[];
    tabIndex?: number;
    changeTab: (index: number) => void;
};

export type TabPanelProps = {
    isMobile: boolean;
    operations: DeviceOperation[];
    setIsOperationSelected: React.Dispatch<React.SetStateAction<boolean>>;
    tabIndex?: number;
    invocationParameters: Record<string, Record<string, unknown>>;
    setInvocationParameters: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, unknown>>>
    >;
};

function OperationsList(props: OperationTabsProps): ReactElement {
    const { operations, tabIndex, changeTab, isMobile } = props;
    const theme = useTheme();
    const handleChange = (
        _event: React.ChangeEvent<unknown>,
        newValue: number,
    ) => {
        changeTab(newValue);
    };

    return (
        <Tabs
            sx={{
                "display": "flex",
                "borderRight": isMobile
                    ? ""
                    : `1px solid ${theme.colours.neutral[300]}`,
                "flexGrow": 1,
                "overflow": "visible",
                "& > *": {
                    overflow: "visible !important",
                },
            }}
            visibleScrollbar={true}
            orientation="vertical"
            value={tabIndex !== undefined && tabIndex >= 0 ? tabIndex : false} // set to false to avoid throwing errors
            onChange={handleChange}
            aria-label="Settings vertical tabs"
            variant={isMobile ? "fullWidth" : "standard"}>
            {operations.map((operation, i) => (
                <Tab
                    sx={{
                        "textTransform": "none",
                        "alignItems": "flex-start",
                        "borderBottom": `1px solid ${theme.colours.neutral[300]}`,
                        "&:last-child": {
                            borderBottom: `0px`,
                        },
                    }}
                    key={i}
                    label={
                        <>
                            <Txt font="primary" level={8} emphasis align="left">
                                {operation.name}
                            </Txt>
                            <Txt font="secondary" level={10} align="left">
                                {operation.description}
                            </Txt>
                        </>
                    }
                    id={`operation-list-item-${i}`}
                    aria-controls={`operation-list-${i}`}
                />
            ))}
        </Tabs>
    );
}

function SelectedOperationPanel(props: TabPanelProps): ReactElement {
    const {
        operations,
        tabIndex,
        setIsOperationSelected,
        setInvocationParameters,
        invocationParameters,
        isMobile,
    } = props;
    const theme = useTheme();
    const selectedOperation =
        tabIndex !== undefined ? operations[tabIndex] : undefined;
    if (!selectedOperation) return <React.Fragment />;

    const parameters =
        (selectedOperation.parameters?.length ?? 0) < 1 ? (
            <Callout>No parameters to provide</Callout>
        ) : (
            selectedOperation.parameters?.map(parameter => {
                const { parameterId } = parameter;
                return (
                    <ParameterInput
                        key={`${selectedOperation.operationId}-${parameterId}-input`}
                        isMobile={isMobile}
                        setOperationParameters={setInvocationParameters}
                        operationParameters={invocationParameters}
                        operationId={selectedOperation.operationId ?? ""}
                        {...parameter}
                    />
                );
            })
        );

    return (
        <Box
            sx={{
                display: "block",
                margin: isMobile ? "20px" : "20px 24px",
            }}>
            {isMobile && (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}>
                        <IconButton
                            size="sm"
                            // variant="tertiary"
                            // iconLeft="arrow-left"
                            icon="arrow-left"
                            onClick={() => setIsOperationSelected(false)}>
                            Back
                        </IconButton>
                        <Txt
                            font="primary"
                            level={6}
                            display="inline"
                            emphasis
                            align="left">
                            {selectedOperation.name}
                        </Txt>
                        <div style={{ width: "47px" }}></div>
                    </div>
                    <Txt
                        font="secondary"
                        italic
                        level={8}
                        align="left"
                        style={{ marginBottom: "16px" }}>
                        {selectedOperation.description}
                    </Txt>
                    {/* <Divider
                        variant="middle"
                        sx={{
                            marginLeft: "0px",
                            marginRight: "0px",
                            marginTop: "8px",
                        }}
                    /> */}
                </>
            )}
            {!isMobile && (
                <Txt
                    font="secondary"
                    level={4}
                    emphasis
                    align="left"
                    style={{
                        borderBottom: `1px solid ${theme.colours.neutral[300]}`,
                    }}>
                    Parameters
                </Txt>
            )}

            <ParametersList
                sx={{
                    "& > *": {
                        marginTop: {
                            xs: 1,
                            md: 3,
                        },
                    },
                }}>
                {parameters}
            </ParametersList>
        </Box>
    );
}

const ParametersList = styled("div")``;

const CREATE_INVOCATION_MUTATION = gql(`
    mutation CreateInvocation($input: CreateInvocationInput!) {
        createInvocation(input: $input) {
            ok
            message
        }
    }
`);
