import { Dispatch, ReactElement, SetStateAction, useState } from "react";

import { useMutation } from "@apollo/client";
import { Container, Divider } from "@mui/material";

import { gql } from "__generated__/apollo";
import Modal from "components/common/Modal";
import { NumberInput } from "components/common/NumberInput";
import Txt from "components/common/Text";
import { TextLabel } from "components/common/TextLabel";
import { useToasts } from "components/common/toasts/useToasts";
import { Step } from "services/hooks/useCultureProcedureSteps";

import { log as parentLog } from "./log";

const log = parentLog.extend("UpdateStepRuntimeReferenceModal");

interface UpdateStepRuntimeReferenceModalProps {
    step: Step;
    deviceId: string;
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export function UpdateStepRuntimeReferenceModal({
    deviceId,
    step,
    modalOpen,
    setModalOpen,
}: UpdateStepRuntimeReferenceModalProps): ReactElement {
    const { runtimeReference } = step;
    const { toast } = useToasts();
    const [runtimeInSeconds, setRuntimeInSeconds] = useState(
        runtimeReference?.runtimeInSeconds ?? 0,
    );
    const [updateStepRuntimeReference] = useMutation(
        UPDATE_STEP_RUNTIME_REFERENCE_MUTATION,
    );
    const onSubmitUpdateStepRuntimeReference = async () => {
        const toastId = toast.loading("Updating step runtime reference");
        try {
            const res = await updateStepRuntimeReference({
                variables: {
                    input: {
                        deviceId,
                        stepId: step.id,
                        runtimeInSeconds,
                    },
                },
            });
            if (res.data?.updateStepRuntimeReference.ok) {
                toast.update(toastId, {
                    type: "success",
                    render: "Step runtime reference updated",
                    isLoading: false,
                    autoClose: 3_000,
                });
            } else {
                const errorMessage =
                    res.data?.updateStepRuntimeReference.message ??
                    "Unable to update step runtime reference";
                toast.update(toastId, {
                    type: "error",
                    render: errorMessage,
                    isLoading: false,
                    autoClose: 6_000,
                });
            }
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                type: "error",
                render: "An unknown error has occurred: please contact technical support",
                isLoading: false,
                autoClose: 6_000,
            });
        }
    };

    const sxPadding = {
        py: { xs: 2, sm: 4 },
        px: { xs: 4, sm: 6 },
    };
    return (
        <Modal
            title={`Update target runtime`}
            buttonLeft={{ label: "Back", onClick: () => setModalOpen(false) }}
            buttonRight={{
                label: "Confirm",
                onClick: onSubmitUpdateStepRuntimeReference,
            }}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            contentPadding={0}>
            <Container maxWidth={"xl"} sx={sxPadding}>
                <Txt font="secondary" level={8} display="inline">
                    You are editing the target runtime of the{" "}
                    <strong>{step.name}</strong> step. The target runtime is
                    measured as the time since the{" "}
                    <strong>{runtimeReference?.referenceStep?.name}</strong>{" "}
                    step has finished.
                </Txt>
                <Divider sx={{ margin: "12px 0" }} variant={"fullWidth"} />
                <TextLabel
                    label="New planned runtime"
                    childrenDirection="column">
                    <NumberInput
                        value={runtimeInSeconds}
                        onChange={value => {
                            if (value) {
                                setRuntimeInSeconds(value);
                            }
                        }}
                        min={0}
                        units="seconds"
                    />
                    <Txt
                        sx={{ margin: "2px 0" }}
                        font="secondary"
                        level={9}
                        italic
                        align="left">
                        {`Previously ${runtimeReference?.runtimeInSeconds?.toFixed(
                            1,
                        )} seconds`}
                    </Txt>
                </TextLabel>
            </Container>
        </Modal>
    );
}

const UPDATE_STEP_RUNTIME_REFERENCE_MUTATION = gql(`
    mutation UpdateStepRuntimeReference(
        $input: UpdateStepRuntimeReferenceInput!
    ) {
        updateStepRuntimeReference(input: $input) {
            ok
            message
            step {
                __typename
                id
                name
                runtimeReference {
                    __typename
                    runtimeInSeconds
                }
            }
        }
    }
`);
