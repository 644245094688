import { ReactElement } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

import { CountingChipImages, FlaskImages } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Txt from "components/common/Text";
import { formatTime } from "services/date";
import { resultsTypeToName } from "services/results";

import { log as parentLog } from "../log";

import { DeviceResult } from "./DeviceResults";

const log = parentLog.extend("ResultCard");

interface ResultCardProps {
    result: DeviceResult;
}

export default function ResultCard({ result }: ResultCardProps): ReactElement {
    const theme = useTheme();

    log.debug("result card for", result);

    let resultName = resultsTypeToName(result.type);

    const resultData = result.data;
    if (resultData?.__typename === "FlaskImages") {
        const flaskImageData = resultData as FlaskImages;
        if (flaskImageData.flask?.number) {
            const flaskNumber = flaskImageData.flask?.number;
            const numberImages = flaskImageData.images?.length ?? 0;
            const numberVideos = flaskImageData?.videos?.length ?? 0;
            const numberToDisplay = numberImages || numberVideos;
            const pluralString = numberToDisplay === 1 ? "" : "s";

            const captureDescription = [
                ["Image", numberImages],
                ["Video", numberVideos],
            ]
                .flatMap(([item, number]) =>
                    number ? [`${item}${pluralString}`] : [],
                )
                .join(" & ");

            resultName = `Flask ${flaskNumber} - ${numberToDisplay} ${captureDescription}`;
        }
    }

    let countingChipContext: string | undefined;
    if (resultData?.__typename === "CountingChipImages") {
        const chipImageData = resultData as CountingChipImages;
        const fromFlask = chipImageData.previousFlaskNumber;
        const toFlask = chipImageData.nextFlaskNumber;
        if (fromFlask) countingChipContext = `Sampled from flask ${fromFlask}`;
        if (toFlask) countingChipContext += ` depositing in flask ${toFlask}`;
        if (fromFlask && toFlask && fromFlask === toFlask) {
            countingChipContext = `Sampled from flask ${fromFlask}`;
        }
    }

    const timestamp = result.timestampISO;
    const completedTime = timestamp ? formatTime(timestamp) : "unknown";
    const displayedCompleted = `Completed at ${completedTime}`;

    log.debug(result);

    return (
        <Card variant="outlined">
            <CardContent
                sx={{
                    "&:last-child": {
                        paddingBottom: "16px", // override the Mui card styling
                    },
                }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={9}>
                        <Txt
                            level={7}
                            emphasis
                            display="inline"
                            style={{ marginRight: theme.spacing(4) }}>
                            {resultName}
                        </Txt>
                        <div style={{ display: "inline-block" }}>
                            <Txt
                                level={10}
                                display="inline"
                                style={{ color: theme.colours.neutral[500] }}>
                                {result.id}
                            </Txt>
                        </div>

                        <Box sx={{ color: theme.colours.neutral[700] }}>
                            <Txt
                                font="secondary"
                                level={9}
                                display="inline"
                                style={{ marginRight: theme.spacing(3) }}>
                                {displayedCompleted}
                            </Txt>

                            {countingChipContext && (
                                <div style={{ display: "inline-block" }}>
                                    <Txt
                                        font="secondary"
                                        level={9}
                                        italic
                                        display="inline">
                                        {countingChipContext}
                                    </Txt>
                                </div>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={3} style={{ textAlign: "right" }}>
                        <Button
                            variant="secondary"
                            size="s"
                            iconRight="arrow-right">
                            View
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
