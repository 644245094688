import { ReactElement } from "react";

import { ButtonBase } from "@mui/material";

import Icon from "components/common/Icon";
import Txt from "components/common/Text";

import { MenuWrapper, MenuWrapperProps } from "./Menu";

export type HeaderSelectProps = {
    /**
     * The string value of the header
     */
    value: string;
    loading?: boolean;
} & Pick<MenuWrapperProps, "options">;

export function HeaderSelect(props: HeaderSelectProps): ReactElement {
    const { value, options, loading } = props;
    return (
        <MenuWrapper
            arrowPositionRight={10}
            options={options}
            render={props => (
                <ButtonBase
                    {...props}
                    sx={{
                        "display": "flex",
                        "alignItems": "center",
                        "gap": 3,
                        "padding": 1,
                        "borderRadius": 1,
                        "&:hover": {
                            backgroundColor: theme =>
                                theme.colours.neutral[200],
                        },
                    }}>
                    <Txt skeleton={loading ? 8 : 0} emphasis level={4}>
                        {value}
                    </Txt>
                    <Icon name="chevron-down" size="lg" />
                </ButtonBase>
            )}
        />
    );
}
