import { Dispatch, ReactElement, SetStateAction, useState } from "react";

import { FetchResult, useMutation, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";

import { gql } from "__generated__/apollo";
import { Fleet, MoveDeviceMutation } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { IconButton } from "components/common/IconButton";
import { Select } from "components/common/Select";
import { useToasts } from "components/common/toasts/useToasts";
import { isDefined } from "services/utils";

interface ChangeFleetDialogProps {
    currentFleetName: string;
    deviceId: string;
}

export const ALL_FLEETS = gql(`
    query AllFleets {
        fleets {
            id
            name
        }
    }
`);

export const MOVE_DEVICE = gql(`
    mutation MoveDevice($input: MoveDeviceInput!) {
        moveDevice(input: $input) {
            ok
            fleetId
            message
        }
    }
`);

export default function ChangeFleetDialog({
    currentFleetName,
    deviceId,
}: ChangeFleetDialogProps): ReactElement {
    const { toast } = useToasts();

    const [open, setOpen] = useState(false);
    const [fleetId, setFleetId] = useState<number | undefined>();
    const [fleetName, setFleetName] = useState<string>(currentFleetName);
    const [moveDevice] = useMutation(MOVE_DEVICE);

    const [changePending, setChangePending] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChangeFleet = async () => {
        const toastId = toast.loading("Moving device to another fleet");
        if (fleetId === undefined) {
            toast.update(toastId, {
                render: "Moving device to another fleet failed - No fleet selected to move device to",
                type: "error",
                isLoading: false,
            });
            return;
        }

        let res: FetchResult<MoveDeviceMutation> | undefined = undefined;
        let errorMessage: string | undefined = undefined;
        try {
            setChangePending(true);
            res = await moveDevice({
                variables: {
                    input: {
                        deviceId,
                        targetFleetId: fleetId,
                    },
                },
            });
        } catch (e) {
            errorMessage = e instanceof Error ? e.message : "Unknown error";
        }

        setChangePending(false);

        if (res?.data?.moveDevice) {
            if (res.data.moveDevice.ok) {
                toast.update(toastId, {
                    render: "Moving device to another fleet successful",
                    type: "success",
                    isLoading: false,
                });
                setOpen(false);
                return;
            }
            errorMessage = res.data.moveDevice.message;
        }

        toast.update(toastId, {
            render: `Moving device to another fleet failed - ${errorMessage}`,
            type: "error",
            isLoading: false,
        });
    };

    const disableConfirm =
        changePending ||
        fleetId === undefined ||
        fleetName === currentFleetName;

    return (
        <>
            <IconButton
                icon="edit"
                size="sm"
                onClick={handleOpen}
                tooltipText="Set device fleet"
            />
            <ConfirmationDialog
                variant="destructive"
                open={open}
                onSecondaryAction={handleClose}
                onDismiss={handleClose}
                onPrimaryAction={handleChangeFleet}
                primaryButtonDisabled={disableConfirm}
                title="Set device fleet"
                body={
                    <>
                        <Callout
                            variant="warn"
                            message="If any image or video capture has completed recently, then there will be data loss if the images or videos have not finished uploading."
                        />
                        <br />
                        Changing fleet will move the device to the new fleet in
                        Balena.
                    </>
                }
                primaryButtonText="Confirm change">
                {open ? (
                    <ChangeFleetModalContent
                        currentFleetName={currentFleetName}
                        setFleetId={setFleetId}
                        setFleetName={setFleetName}
                    />
                ) : null}
            </ConfirmationDialog>
        </>
    );
}

export function menuOptionFromFleet(fleet: Fleet) {
    return {
        value: fleet.id.toString(),
        label: fleet.name,
    } as const;
}

function ChangeFleetModalContent({
    currentFleetName,
    setFleetId,
    setFleetName,
}: {
    currentFleetName: string;
    setFleetId: Dispatch<SetStateAction<number | undefined>>;
    setFleetName: Dispatch<SetStateAction<string>>;
}) {
    const { loading, data } = useQuery(ALL_FLEETS);
    const fleets = data?.fleets ?? [];
    const menuOptions = fleets.filter(isDefined).map(menuOptionFromFleet);
    const defaultOption = fleets
        .find(f => f?.name === currentFleetName)
        ?.id.toString();

    const updateSelectedFleet = (fleetId: string) => {
        setFleetId(parseInt(fleetId));
        setFleetName(
            fleets.find(f => f?.id.toString() === fleetId)?.name ?? "",
        );
    };

    return (
        <Stack direction={"row"} spacing={4} sx={{ mt: 2 }}>
            {loading ? (
                "Loading fleets..."
            ) : (
                <Select
                    defaultValue={defaultOption}
                    options={menuOptions}
                    onChange={updateSelectedFleet}
                />
            )}
        </Stack>
    );
}
