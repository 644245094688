import { useMutation } from "@apollo/client";
import { Dayjs } from "dayjs";
import { omit } from "lodash";

import { gql } from "__generated__/apollo";
import { CreateAndSyncCultureInput } from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";
import { config } from "services/config";
import { Timezone } from "services/date";

import {
    CULTURE_OVERVIEW_QUERY,
    DEVICE_CULTURE_QUERY,
} from "../../DeviceCulture";
import { log as parentLog } from "../log";

const REPLACE_WET_TEST_WITH_CULTURE_MUTATION = gql(`
    mutation ReplaceWetTestWithCulture($input: ReplaceWetTestWithCultureInput!) {
        replaceWetTestWithCulture(input: $input) {
            ok
            message
        }
    }
`);

const CREATE_AND_SYNC_CULTURE_MUTATION = gql(`
    mutation CreateAndSyncCultureV2($input: CreateAndSyncCultureInput!) {
        createAndSyncCulture(input: $input) {
            ok
            message
        }
    }
`);

export const log = parentLog.extend("useCreatePrimaryCulture");

export function useCreatePrimaryCulture({
    deviceId,
    primaryProtocolId,
    wetTestCultureId,
    cultureMetadata,
    isReplacingWetTestCulture,
    skipValidation,
}: {
    deviceId: string;
    primaryProtocolId?: string;
    wetTestCultureId?: string;
    cultureMetadata: CreateAndSyncCultureInput["cultureMetadata"];
    isReplacingWetTestCulture: boolean;
    skipValidation?: boolean;
}) {
    const { toast } = useToasts();

    const [replaceWetTestWithCultureMutation] = useMutation(
        REPLACE_WET_TEST_WITH_CULTURE_MUTATION,
        {
            refetchQueries: [CULTURE_OVERVIEW_QUERY, DEVICE_CULTURE_QUERY],
        },
    );

    const [createAndSyncCultureMutation] = useMutation(
        CREATE_AND_SYNC_CULTURE_MUTATION,
        {
            refetchQueries: [CULTURE_OVERVIEW_QUERY, DEVICE_CULTURE_QUERY],
        },
    );

    return async function handleSubmit({
        cultureTimezone,
        cultureStartDate,
    }: {
        cultureTimezone: Timezone;
        cultureStartDate: Dayjs;
    }): Promise<void | Error> {
        if (!primaryProtocolId) {
            return new Error("Primary protocol ID is required");
        }

        const toastId = toast.loading("Loading culture...");

        const cultureDetails = {
            deviceId,
            protocolId: primaryProtocolId,
            startDate: cultureStartDate.format("YYYY-MM-DD"),
            timezone: cultureTimezone,
            cultureMetadata,
            // If we are replacing the wet test culture we will have already
            // validated the device was in the correct state when we uploaded
            // the wet test culture, so we can skip validation here.
            skipValidation:
                skipValidation || isReplacingWetTestCulture || config.isLocal,
        };

        const replaceWetTestWithCulture = async () => {
            if (!wetTestCultureId) {
                toast.update(toastId, {
                    render: "Wet test culture ID is required",
                    type: "error",
                    isLoading: false,
                });
                return new Error("Wet test culture ID is required");
            }
            return replaceWetTestWithCultureMutation({
                variables: {
                    input: {
                        cultureId: wetTestCultureId,
                        ...omit(cultureDetails, "deviceId"),
                    },
                },
            });
        };

        const result = isReplacingWetTestCulture
            ? await replaceWetTestWithCulture()
            : await createAndSyncCultureMutation({
                  variables: {
                      input: cultureDetails,
                  },
              });

        if (result instanceof Error) {
            return result;
        }

        const data =
            result?.data &&
            ("replaceWetTestWithCulture" in result.data
                ? result.data.replaceWetTestWithCulture
                : result.data.createAndSyncCulture);

        if (data?.ok) {
            toast.update(toastId, {
                render: "Culture loaded",
                type: "success",
                isLoading: false,
            });

            return;
        }

        log.error(
            { ok: data?.ok, message: data?.message },
            "culture replacement failed",
        );
        return new Error("Failed to load culture");
    };
}
