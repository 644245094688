import { ReactElement, useState } from "react";

import { TextInput } from "components/common/TextInput";
import { TextLabel } from "components/common/TextLabel";
import { authentication } from "services/auth/authentication";

export function Developer(): ReactElement {
    const [value, setValue] = useState(
        authentication.getDeveloperJWT() ?? undefined,
    );

    return (
        <div
            style={{
                padding: 16,
            }}>
            <TextLabel label="Developer API Key">
                <TextInput
                    value={value}
                    type="password"
                    onChange={newVal => {
                        authentication.storeDeveloperJWT(newVal);
                        setValue(newVal);
                    }}
                />
            </TextLabel>
        </div>
    );
}
