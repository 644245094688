import { ApolloError, useQuery } from "@apollo/client";
import { mean } from "lodash";

import { gql } from "__generated__/apollo";
import { UseCultureFlaskConfluenceQuery } from "__generated__/apollo/graphql";
import { DataPoint } from "components/common/Graph/Graph";

export const USE_CULTURE_FLASK_CONFLUENCE = gql(`
    query UseCultureFlaskConfluence($cultureId: String!, $flaskId: String!) {
        culture(id: $cultureId) {
            id
            startDate
            dayStartIndex
            results(filterBy: { flaskIds: [$flaskId] }) {
                nodes {
                    id
                    timestampISO
                    data {
                        __typename
                        ... on FlaskImages {
                            flask {
                                number
                            }
                            images {
                                id
                                confluence
                                position {
                                    relativePosition {
                                        relativeCoords {
                                            x
                                            y
                                            z
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`);

type CultureData = NonNullable<UseCultureFlaskConfluenceQuery["culture"]>;
type ResultData = NonNullable<CultureData["results"]>;
export type ResultNodes = NonNullable<ResultData["nodes"]>;

export function useFlaskConfluence(
    cultureId: string,
    flaskId?: string,
): {
    results: ResultNodes | null;
    datapoints: DataPoint[];
    startDate?: string;
    loading: boolean;
    dayStartIndex: number;
    error?: ApolloError;
} {
    const {
        data: cultureData,
        loading: cultureLoading,
        error: cultureError,
    } = useQuery(USE_CULTURE_FLASK_CONFLUENCE, {
        skip: !flaskId,
        variables: { cultureId, flaskId: flaskId ?? "" },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    const cultureNodes = cultureData?.culture?.results?.nodes ?? null;
    const data = getConfluenceDataPoints(cultureNodes);
    const startDate = cultureData?.culture?.startDate ?? undefined;
    const dayStartIndex = cultureData?.culture?.dayStartIndex ?? 0;
    return {
        results: cultureNodes,
        datapoints: data,
        startDate,
        dayStartIndex,
        loading: cultureLoading,
        error: cultureError,
    };
}

/**
 * Retrieves Confluence data points from the provided nodes array.
 *
 * @param nodes - The array of nodes containing Confluence data.
 * @param requiredDataPointLength - The required length of the data points
 * array. Defaults to 20. Used to avoid displaying inaccurate data as we
 * currently rely on the aggregation to increase data quality
 * @returns An array of DataPoint objects.
 */
function getConfluenceDataPoints(
    nodes: NonNullable<
        NonNullable<UseCultureFlaskConfluenceQuery["culture"]>["results"]
    >["nodes"],
    requiredDataPointLength = 20,
): DataPoint[] {
    const nullOrDataPoint = nodes?.map(node => {
        if (
            !node ||
            !node.timestampISO ||
            node?.data?.__typename !== "FlaskImages" ||
            !node.data.images ||
            !node.data.images.length
        ) {
            return null;
        }
        const confluenceArray = node.data.images?.map(
            image => image?.confluence,
        );
        const definedArray: number[] = confluenceArray.filter(
            (item): item is number => item !== null && item !== undefined,
        );
        if (definedArray.length < requiredDataPointLength) {
            return;
        }
        return {
            date: new Date(node.timestampISO),
            value: mean(definedArray),
            values: definedArray,
        };
    });
    const dataPoints = nullOrDataPoint?.filter(Boolean);
    return dataPoints ?? [];
}
