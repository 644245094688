import { atom, useAtom } from "jotai";

const dryTestOverrideAtom = atom(false);

export function useDryTestOverride() {
    const [dryTestOverride, setDryTestOverride] = useAtom(dryTestOverrideAtom);

    return {
        dryTestOverride,
        setDryTestOverride,
    };
}

const automatedStartCheckOverrideAtom = atom(false);

export function useAutomatedStartCheckOverride() {
    const [automatedStartCheckOverride, setAutomatedStartCheckOverride] =
        useAtom(automatedStartCheckOverrideAtom);

    return {
        automatedStartCheckOverride,
        setAutomatedStartCheckOverride,
    };
}

const drySetupCompleteAtom = atom<boolean>(false);

export function useDrySetupComplete() {
    const [drySetupComplete, setDrySetupComplete] =
        useAtom(drySetupCompleteAtom);

    return {
        drySetupComplete,
        setDrySetupComplete,
    };
}

export function useResetCreateCultureState() {
    const { setDryTestOverride } = useDryTestOverride();
    const { setAutomatedStartCheckOverride } = useAutomatedStartCheckOverride();
    const { setDrySetupComplete } = useDrySetupComplete();

    const resetCreateCultureState = () => {
        setDryTestOverride(false);
        setAutomatedStartCheckOverride(false);
        setDrySetupComplete(false);
    };

    return { resetCreateCultureState };
}

const wetTestOverrideAtom = atom(false);

export function useWetTestOverride() {
    const [wetTestOverride, setWetTestOverride] = useAtom(wetTestOverrideAtom);

    return {
        wetTestOverride,
        setWetTestOverride,
    };
}
