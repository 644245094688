import { ModalContent } from "components/common/Modal/Modal";

import { useDrySetupComplete } from "../CreateCultureState";
import { DrySetupView } from "../DrySetup/DrySetupView";
import { log as parentLog } from "../log";

export const log = parentLog.extend("DrySetupModalContent");

export function DrySetupModalContent({
    deviceId,
    onBackClick,
    onSubmit,
}: {
    deviceId: string;
    onBackClick: () => void;
    onSubmit: () => Promise<void | Error>;
}) {
    const { drySetupComplete, setDrySetupComplete } = useDrySetupComplete();

    return (
        <ModalContent
            title="New Culture"
            buttonLeft={{
                label: "Back",
                onClick: onBackClick,
            }}
            buttonRight={{
                label: "Continue",
                disabled: !drySetupComplete,
                onClick: onSubmit,
            }}>
            <DrySetupView
                deviceId={deviceId}
                drySetupComplete={drySetupComplete}
                setDrySetupComplete={setDrySetupComplete}
            />
        </ModalContent>
    );
}
