import { ReactElement } from "react";

import styled from "@emotion/styled";
import { cloneDeep } from "lodash";

import Callout from "components/common/Callout";
import { CultureFlask } from "services/hooks/useCultureContainers";
import { isNotNull } from "services/utils";

import { ContainerTile } from "./ContainerTile";

interface FlaskTilesProps {
    flasks: CultureFlask[];
    volumesEditable: boolean;
}

export function FlaskTiles({
    flasks,
    volumesEditable,
}: FlaskTilesProps): ReactElement {
    if (!flasks?.length) {
        return (
            <CardContent>
                <Callout
                    variant="info"
                    message="Culture does not have any flasks"
                />
            </CardContent>
        );
    }

    const flasksSorted = cloneDeep(flasks)
        .filter(isNotNull)
        .sort((f1, f2) => {
            return (f1?.flaskNumber ?? 0) - (f2?.flaskNumber ?? 0);
        });

    return (
        <>
            {flasksSorted.map(f => (
                <ContainerTile
                    key={f.id}
                    id={f.id}
                    name={f.flaskNumber ? `Flask ${f.flaskNumber}` : f.id}
                    liquidVolumeMl={f.liquidVolumeMl}
                    totalVolumeMl={f.totalVolumeMl}
                    isFlask
                    isVolumeModifiable={volumesEditable}
                />
            ))}
        </>
    );
}

const CardContent = styled.div`
    grid-column: 1 / span 2;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        grid-column: 1 / span 1;
    }
`;
