import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { StepControl } from "__generated__/apollo/graphql";
import {
    getToastifyMessages,
    useToasts,
} from "components/common/toasts/useToasts";

const STEP_CONTROL_MUTATION = gql(`
    mutation StepControl($input: ControlStepInput!) {
        controlStep(input: $input) {
            ok
            message
            step {
                id
                state
                confirmed
                confirmedBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`);

const CONTROL_LANGUAGE: Record<StepControl, { past: string; gerund: string }> =
    {
        CANCEL: {
            past: "cancelled",
            gerund: "cancelling",
        },
        REMOVE: {
            past: "removed",
            gerund: "removing",
        },
        RESTORE: {
            past: "restored",
            gerund: "restoring",
        },
        PAUSE: {
            past: "paused",
            gerund: "pausing",
        },
        CONFIRM: {
            past: "confirmed",
            gerund: "confirming",
        },
        UNCONFIRM: {
            past: "unconfirmed",
            gerund: "unconfirming",
        },
    };

export default function useStepControl({
    onSuccess,
}: {
    onSuccess?: () => void;
} = {}) {
    const [stepControl, mutationDetails] = useMutation(STEP_CONTROL_MUTATION);

    const { toastifyAction } = useToasts();

    const handleStepControl = async ({
        stepId,
        deviceId,
        control,
    }: {
        stepId: string;
        deviceId: string;
        control: StepControl;
    }) => {
        const { gerund, past } = CONTROL_LANGUAGE[control];

        return toastifyAction(
            getToastifyMessages({
                entity: "step",
                verb: {
                    past,
                    gerund,
                },
            }),
            async () => {
                const output = await stepControl({
                    variables: {
                        input: {
                            deviceId,
                            stepId,
                            control,
                        },
                    },
                });

                if (output.data?.controlStep.ok) {
                    onSuccess?.();
                    return output;
                } else {
                    throw new Error(output.data?.controlStep.message);
                }
            },
        );
    };

    return [handleStepControl, mutationDetails] as const;
}
