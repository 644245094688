import { ReactElement } from "react";

import { styled } from "@mui/material";
import Box from "@mui/material/Box";

import { ProtocolStartingBottleData } from "__generated__/apollo/graphql";
import { Bottle, isValidBottleCapacity } from "components/common/Bottle";
import Txt from "components/common/Text";

interface ProtocolBottlesTableProps {
    containers: ProtocolStartingBottleData[];
}

export function ProtocolBottlesTable({
    containers,
}: ProtocolBottlesTableProps): ReactElement {
    const sortedContainers = containers.sort(
        (
            { liquidVolume: vA, totalVolume: cA },
            { liquidVolume: vB, totalVolume: cB },
        ) => {
            const s1 = vB - vA; // descending fill volume
            if (s1 === 0) return cA - cB; // ascending capacity
            return s1;
        },
    );

    return (
        <StyledTable>
            <thead>
                <tr>
                    {["Bottle", "Name & fill volume", "Port"].map(header => (
                        <th key={header}>
                            <Txt font="primary" level={7} emphasis>
                                {header}
                            </Txt>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {sortedContainers.map(bottle => (
                    <tr key={bottle.reagentName}>
                        <td>
                            <BottleCell bottle={bottle} />
                        </td>
                        <td>
                            <Txt font="secondary" level={7}>
                                {bottle.reagentName}
                            </Txt>
                            <Txt
                                font="secondary"
                                level={8}
                                italic={!bottle.liquidVolume}
                                sx={{
                                    color: theme =>
                                        bottle.liquidVolume
                                            ? theme.colours.neutral[700]
                                            : theme.colours.neutral[500],
                                }}>
                                {bottle.liquidVolume
                                    ? `Fill to ${bottle.liquidVolume} mL`
                                    : "Don't fill"}
                            </Txt>
                        </td>
                        <td>
                            <Txt
                                font="secondary"
                                level={8}
                                italic
                                sx={{
                                    color: theme => theme.colours.neutral[500],
                                }}>
                                See Setup Guide
                            </Txt>
                        </td>
                    </tr>
                ))}
            </tbody>
        </StyledTable>
    );
}

const StyledTable = styled("table")`
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        border-bottom: 1px solid;
        text-align: left;
        padding: 8px;
        border-color: ${({ theme }) => theme.colours.neutral[500]};
    }

    td {
        border-color: ${({ theme }) => theme.colours.neutral[300]};
    }
`;
function BottleCell({
    bottle,
}: {
    bottle: ProtocolStartingBottleData;
}): ReactElement {
    return (
        <div style={{ display: "flex", width: "100%", alignItems: "left" }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}>
                {isValidBottleCapacity(bottle.totalVolume) && (
                    <div
                        style={{
                            height: "50px",
                            width: "50px",
                            display: "inline-flex",
                        }}>
                        <Bottle
                            capacity={bottle.totalVolume}
                            liquidStyle={
                                bottle.reagentName.toLowerCase() === "pbs"
                                    ? "pbs"
                                    : "media"
                            }
                            volumePercent={
                                bottle.liquidVolume / bottle.totalVolume
                            }
                        />
                    </div>
                )}
                <Txt
                    font="secondary"
                    level={8}
                    sx={{
                        color: theme => theme.colours.neutral[600],
                    }}
                    style={{ whiteSpace: "nowrap" }}>
                    {bottle.totalVolume} mL
                </Txt>
            </Box>
        </div>
    );
}
