import { isString } from "lodash";

import { DevicePlatform } from "__generated__/apollo/graphql";

/**
 * A subset of Mytos Support pages that are currently linked to by the App
 */
export enum HowToGuideStage {
    /** Incubator & fridge configuration checks */
    PreSetupChecks = "PreSetupChecks",
    /** Consumable retrieval */
    ConsumableChecks = "ConsumableChecks",
    /** Dry setup for incubator-side consumables */
    IncubatorSetup = "IncubatorSetup",
    /** Dry setup for sidecar plate */
    SidecarSetup = "SidecarSetup",
    /** Reagent preparation and connection */
    ReagentPrep = "ReagentPrep",
    /** Initial seed information */
    InitialSeeding = "InitialSeeding",
}

const howToGuideLinks: Record<
    HowToGuideStage,
    | string
    | {
          [key in Exclude<DevicePlatform, DevicePlatform.Triple>]: string;
      }
> = {
    [HowToGuideStage.ConsumableChecks]:
        "https://mytos.notion.site/Consumable-Unboxing-2206cd89df42430ba179f80e6b732263",
    [HowToGuideStage.PreSetupChecks]: {
        [DevicePlatform.Echo]:
            "https://mytos.notion.site/Pre-Setup-Checks-5e5b61d2e1694a5db793ab1798110197",
        [DevicePlatform.Foxtrot]:
            "https://mytos.notion.site/Pre-Setup-Checks-bd66838ea47644f38ad137a9ec76a7bd",
        [DevicePlatform.Mock]:
            "https://mytos.notion.site/Pre-Setup-Checks-bd66838ea47644f38ad137a9ec76a7bd",
    },
    [HowToGuideStage.IncubatorSetup]: {
        [DevicePlatform.Echo]:
            "https://mytos.notion.site/Incubator-Setup-4e98d7181c194167a1c3a8686422d4c7",
        [DevicePlatform.Foxtrot]:
            "https://mytos.notion.site/Incubator-Setup-b74764b9db8e4d759f4c00d0908ef6f5",
        [DevicePlatform.Mock]:
            "https://mytos.notion.site/Incubator-Setup-b74764b9db8e4d759f4c00d0908ef6f5",
    },
    [HowToGuideStage.SidecarSetup]: {
        [DevicePlatform.Echo]:
            "https://mytos.notion.site/Sidecar-Setup-43b7ffe4cc8446989204b5a025e7aa9c",
        [DevicePlatform.Foxtrot]:
            "https://mytos.notion.site/Sidecar-Setup-5df59e71585d4939af4a11a5d7544b74",
        [DevicePlatform.Mock]:
            "https://mytos.notion.site/Sidecar-Setup-5df59e71585d4939af4a11a5d7544b74",
    },
    [HowToGuideStage.ReagentPrep]: {
        [DevicePlatform.Echo]:
            "https://mytos.notion.site/Reagent-Preparation-Connections-c7f95112a9ee4ac0ab337c618b22b58a",
        [DevicePlatform.Foxtrot]:
            "https://mytos.notion.site/Reagent-Preparation-Connections-91f2850af6b248b9bd0a558bbb4a60b6",
        [DevicePlatform.Mock]:
            "https://mytos.notion.site/Reagent-Preparation-Connections-91f2850af6b248b9bd0a558bbb4a60b6",
    },
    [HowToGuideStage.InitialSeeding]: {
        [DevicePlatform.Echo]:
            "https://mytos.notion.site/Initial-Seeding-46c3a6f25b044d75a871a632ad10f94f",
        [DevicePlatform.Foxtrot]:
            "https://mytos.notion.site/Initial-Seeding-13d75c16dd754bef93baf2c8bcfbf378",
        [DevicePlatform.Mock]:
            "https://mytos.notion.site/Initial-Seeding-13d75c16dd754bef93baf2c8bcfbf378",
    },
};

export function getHowToGuideLink(
    stage: HowToGuideStage,
    platform: Exclude<DevicePlatform, DevicePlatform.Triple>,
) {
    return isString(howToGuideLinks[stage])
        ? howToGuideLinks[stage]
        : howToGuideLinks[stage][platform];
}
