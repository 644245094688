import { useState, ReactElement } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { RemoveDeviceUserMutationVariables } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import { useToasts } from "components/common/toasts/useToasts";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { log as parentLog } from "../../log";

export const log = parentLog.extend("RevokeDeviceAccessButton");

const REMOVE_DEVICE_USER_MUTATION = gql(`
    mutation RemoveDeviceUser($input: RemoveDeviceUserInput!) {
        removeDeviceUser(input: $input) {
            ok
            message
            device {
                id
                users {
                    totalCount
                    edges {
                        id
                        role
                        user {
                            id
                        }
                    }
                }
            }
        }
    }
`);

interface RevokeDeviceAccessButtonProps {
    edgeId: string;
    deviceId: string;
}

export function RevokeDeviceAccessButton(
    props: RevokeDeviceAccessButtonProps,
): ReactElement {
    const { edgeId, deviceId } = props;
    const [changePending, setChangePending] = useState(false);
    const { toast } = useToasts();
    const { isAdmin } = useDeviceRole(deviceId);
    const [removeDeviceUserMutation] = useMutation(REMOVE_DEVICE_USER_MUTATION);

    const handleChange = async () => {
        log.debug("removing user on edge", edgeId);
        try {
            setChangePending(true);
            const variables: RemoveDeviceUserMutationVariables = {
                input: {
                    edgeId,
                },
            };
            const res = await removeDeviceUserMutation({
                variables,
            });
            setChangePending(false);

            log.debug("Response to adding new device user", res);
            const mutationResponse = res.data?.removeDeviceUser;
            if (mutationResponse) {
                if (mutationResponse.ok) {
                    toast.success("User access revoked");
                    return;
                } else {
                    toast.error(
                        "Unable to revoke access for user because " +
                            mutationResponse.message,
                    );
                    return;
                }
            }
            toast.error(
                "Unable to revoke user access. Please contact support.",
            );
        } catch (e) {
            log.error(
                "Error encountered during remove device user mutation",
                e,
            );
        }
    };

    return (
        <Button
            colour="alert"
            variant="secondary"
            size="s"
            onClick={handleChange}
            disabled={changePending || !isAdmin}>
            {changePending ? "Revoking" : "Revoke access"}
        </Button>
    );
}
