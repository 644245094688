import { useEffect } from "react";

import { ApolloError, useQuery } from "@apollo/client";
import { atom, useAtom } from "jotai";

import { gql } from "__generated__/apollo/gql";
import {
    CultureScheduleQuery,
    DeviceCultureScheduleQuery,
} from "__generated__/apollo/graphql";
import { useCulturePollInterval } from "components/pages/Device/Schedule/schedule-data";

import { log as parentLog } from "./log";

export const log = parentLog.extend("useCultureSchedule");

gql(`
    fragment ScheduleWithAllProcedures on Schedule {
            isWaitingOnConfirmation
            currentProcedure {
                id
            }
            nextProcedure {
                id
            }
            nextStep {
                id
            }
            nextInvocation {
                id
            }
            procedures {
                totalCount
                nodes {
                    id
                    name
                    state
                    estimatedDuration
                    timePlanned
                    timeStarted
                    timeFinished
                    confirmationRequired
                    confirmationText
                    waitingForConfirmation
                    confirmed
                    confirmedBy {
                        id
                        firstName
                        lastName
                    }
                    steps {
                        totalCount
                    }
                    stepsSummary {
                        id
                        numberOfStepsWithOutstandingConfirmation
                        hasStepsWithErrors
                    }
                    lastSentToDeviceAt
                }
            }
    }
`);

export const DEVICE_CULTURE_SCHEDULE_QUERY = gql(`
    query DeviceCultureSchedule($deviceId: String!) {
        device(id: $deviceId) {
            id
            name
            cultureId
            culture {
                id
                name
                state
                isActive
                mintedByServer
                isWetTestCulture
                dayStartIndex
                errorMessage
                device {
                    id
                }
                schedule {
                    ...ScheduleWithAllProcedures
                }
                lastSimulationError {
                    id
                    errorMessage
                    problemInvocation {
                        id
                    }
                    problemStep {
                        id
                    }
                    problemProcedure {
                        id
                    }
                }
            }
        }
    }
`);

export const CULTURE_SCHEDULE_QUERY = gql(`
    query CultureSchedule($cultureId: String!) {
        culture(id: $cultureId) {
            id
            name
            state
            isActive
            mintedByServer
            isWetTestCulture
            dayStartIndex
            errorMessage
            device {
                id
            }
            schedule {
                ...ScheduleWithAllProcedures
            }
            lastSimulationError {
                id
                errorMessage
                problemInvocation {
                    id
                }
                problemStep {
                    id
                }
                problemProcedure {
                    id
                }
            }
        }
    }
`);

export type SimulationErrorDetails = NonNullable<
    CultureScheduleQuery["culture"]
>["lastSimulationError"];

const simulationErrorDetailsAtom = atom<
    SimulationErrorDetails | null | undefined
>(null);

export function useSimulationErrorDetails() {
    const [simulationErrorDetails] = useAtom(simulationErrorDetailsAtom);
    return simulationErrorDetails;
}

export function useCultureSchedule(
    props: { deviceId: string } | { cultureId: string },
): {
    culture?: Culture;
    loading: boolean;
    error?: ApolloError;
    deviceId?: string;
    deviceHasCulture?: boolean;
    refetch: () => void;
} {
    const queryViaDevice = "deviceId" in props;
    const deviceId = queryViaDevice ? props.deviceId : "";
    const cultureId = queryViaDevice ? "" : props.cultureId;

    const culturePollingInternal = useCulturePollInterval(
        queryViaDevice ? { deviceId } : { cultureId },
    );

    /**
     * Loading data via the device ID
     */
    const {
        data: deviceData,
        loading: deviceLoading,
        error: deviceError,
        refetch: refetchDevice,
    } = useQuery(DEVICE_CULTURE_SCHEDULE_QUERY, {
        skip: !queryViaDevice,
        variables: { deviceId },
        pollInterval: culturePollingInternal,
    });

    /**
     * Loading data via the Culture ID
     */
    const {
        data: cultureData,
        loading: cultureLoading,
        error: cultureError,
        refetch: refetchCulture,
    } = useQuery(CULTURE_SCHEDULE_QUERY, {
        skip: queryViaDevice,
        variables: { cultureId },
        pollInterval: culturePollingInternal,
    });

    const [, setSimulationErrorDetails] = useAtom(simulationErrorDetailsAtom);

    const simulationErrorDetails = queryViaDevice
        ? deviceData?.device?.culture?.lastSimulationError
        : cultureData?.culture?.lastSimulationError;

    useEffect(() => {
        setSimulationErrorDetails(simulationErrorDetails);
    }, [setSimulationErrorDetails, simulationErrorDetails]);

    /**
     * Resolve the data
     */
    if (queryViaDevice) {
        return {
            deviceId: props.deviceId,
            culture: deviceData?.device?.culture ?? undefined,
            loading: deviceLoading,
            error: deviceError,
            deviceHasCulture: Boolean(deviceData?.device?.cultureId),
            refetch: refetchDevice,
        };
    } else {
        return {
            deviceId: cultureData?.culture?.device?.id,
            culture: cultureData?.culture ?? undefined,
            loading: cultureLoading,
            error: cultureError,
            refetch: refetchCulture,
        };
    }
}

type Device = NonNullable<DeviceCultureScheduleQuery["device"]>;
type Culture = NonNullable<Device["culture"]>;
export type Schedule = NonNullable<Culture["schedule"]>;
export type Procedure = NonNullable<
    NonNullable<NonNullable<Schedule["procedures"]>["nodes"]>[number]
>;
