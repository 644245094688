import { ReactElement } from "react";

import { groupBy, keyBy } from "lodash";

import { ProcedureState } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import { dayjs, isInPast } from "services/date";
import {
    Procedure,
    Schedule,
    SimulationErrorDetails,
} from "services/hooks/useCultureSchedule";

import { DayBlock } from "./DayBlock";
import { log as parentLog } from "./log";

export type ProcedureWithRealisticTimePlanned = Procedure & {
    timePlannedRealistic: string | null;
};

export const log = parentLog.extend("CultureSchedule");

export type CultureScheduleListProps = {
    schedule: Schedule;
    deviceId: string;
    cultureId: string;
    cultureIsActive: boolean;
    simulationErrorDetails?: SimulationErrorDetails | null;
    dayStartIndex: number;
};

export function CultureScheduleList({
    schedule,
    cultureIsActive,
    dayStartIndex,
    simulationErrorDetails,
    cultureId,
    deviceId,
}: CultureScheduleListProps): ReactElement {
    const scheduleHasSteps = (schedule.procedures?.totalCount ?? 0) > 0;

    if (!scheduleHasSteps) {
        return (
            <div style={{ marginTop: 24 }}>
                <Callout message="The schedule doesn't have any steps in it yet." />
            </div>
        );
    }

    // Find realistic time planned for all procedures, this uses the previous
    // procedure realistic finish time to infer correct time in the schedule.
    const proceduresWithRealisticTime =
        schedule.procedures?.nodes?.reduce((accumulator, procedure) => {
            if (!procedure) {
                return accumulator;
            }

            const previousProcedure = accumulator?.at(-1) ?? null;
            const timePlannedRealistic = realisticTimePlanned(
                procedure,
                previousProcedure,
            );
            return [
                ...accumulator,
                {
                    ...procedure,
                    timePlannedRealistic,
                },
            ];
        }, [] as ProcedureWithRealisticTimePlanned[]) ?? [];

    // Group procedures by day based on the realistic time planned
    const realisticTimesById = keyBy(proceduresWithRealisticTime, "id");
    const proceduresDayGrouped = groupBy<null | Procedure>(
        schedule.procedures?.nodes ?? [],
        procedure => {
            if (!procedure || !realisticTimesById[procedure.id]) {
                return;
            }
            const { timePlannedRealistic } = realisticTimesById[procedure.id];
            return dayjs(timePlannedRealistic).format("YYYY-MM-DD");
        },
    );

    const cultureFirstDay = Object.keys(proceduresDayGrouped)[0];

    return (
        <>
            {Object.keys(proceduresDayGrouped).map(date => {
                const dayNum =
                    dayjs(date).diff(cultureFirstDay, "days") + dayStartIndex;
                return (
                    <DayBlock
                        deviceId={deviceId}
                        cultureId={cultureId}
                        key={date}
                        dayNum={dayNum}
                        date={date}
                        procedures={proceduresDayGrouped[date]}
                        nextProcedureId={schedule.nextProcedure?.id}
                        currentProcedureId={schedule.currentProcedure?.id}
                        cultureIsActive={cultureIsActive}
                        // Drill this down the first couple of layers so we can
                        // auto open days/procedures with simulation errors.
                        // Deeper down the tree we use the atom to access this
                        // state, but that doesn't work nicely with out
                        // defaulting of collapsed state.
                        simulationErrorDetails={simulationErrorDetails}
                        cultureIsWaitingForConfirmation={
                            schedule.isWaitingOnConfirmation
                        }
                        nextStepId={schedule.nextStep?.id}
                    />
                );
            })}
        </>
    );
}

/**
 * When the state of a Procedure is some kind 'not done yet' (i.e. non-terminal)
 * state, and the planned time is in the past, we will provide a more realistic
 * start time. This more realistic start time is a current timestamp
 * (representing 'as soon as possible').
 *
 * This makes Procedures that are scheduled to begin on previous days (but
 * haven't yet) accumulate on today as they can't possibly run in the past.
 *
 * Note that if the timeStarted is already provided, then we default to that as
 * it correctly overrides any planned start time.
 *
 * @param procedure - Procedure to calculate realistic time for
 * @param previousProcedure - Previous procedure to compare against
 * @returns ISO timestamp (most realistic start time)
 */
export function realisticTimePlanned(
    procedure: Pick<
        Procedure,
        "name" | "state" | "timePlanned" | "timeStarted"
    >,
    previousProcedure: Pick<
        ProcedureWithRealisticTimePlanned,
        "timePlannedRealistic" | "estimatedDuration" | "timeFinished"
    > | null,
): string | null {
    const { state, timePlanned, timeStarted } = procedure;

    // firstly, the most realistic is the actual start time
    if (timeStarted) return timeStarted;

    // if we don't have both time planned and state, fallback
    if (!(timePlanned && state)) {
        return timePlanned;
    }

    // If planned time is still in the future then return that
    if (!isInPast(timePlanned)) {
        return timePlanned;
    }

    // We no longer transition removed procedures to ignored
    const terminalStates = [
        ProcedureState.Complete,
        ProcedureState.Ignored,
        ProcedureState.Removed,
    ];

    // If the procedure is in a terminal state we want to return the later of
    // the previous procedure finish time or the planned time
    if (terminalStates.includes(state)) {
        const previousTime =
            previousProcedure?.timeFinished ??
            previousProcedure?.timePlannedRealistic;

        if (previousTime && dayjs(timePlanned).isBefore(previousTime)) {
            return previousTime;
        }

        return timePlanned;
    }

    // if its not in a terminal state and was schedule to begin in the past we
    // pull it forward to current date
    return dayjs().toISOString();
}
