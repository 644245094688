import { isString } from "lodash";

import defaultLogger from "../logger";

const logger = defaultLogger.extend("apollo:cache");

/**
 * This is the shape of the data that Apollo passes to us in the "merge"/"read" functions.
 *
 * Typically this __ref is `__typename:id` e.g. `Result:our-result-uuid`.
 *
 * If you need to look up any other data for the object you need to use the `readField` function Apollo provides.
 */
type ResultNode = {
    __ref: string;
};

/**
 * How the data is returned from the server and how the web app consumes it
 */
type PaginatedResults = {
    nodes: ResultNode[];
    pageInfo: unknown;
};

/**
 * How we store the data in the Apollo cache
 */
type CachedPaginatedResults = {
    /**
     * We store the nodes in a map (rather than an array) as that allows us to easily de-duplicate
     * any incoming nodes with the existing nodes. This does mean we need to transform the nodes
     * back into an array when we read them from the cache.
     *
     * Read more here:
     * https://www.apollographql.com/docs/react/pagination/cursor-based/#keeping-cursors-separate-from-items
     */
    nodes: { [__ref: string]: ResultNode };
    pageInfo: unknown;
};

export function mergePaginatedResults({
    existing,
    incoming,
}: {
    existing: CachedPaginatedResults;
    incoming: PaginatedResults;
}): CachedPaginatedResults {
    const nodes = existing ? { ...existing.nodes } : {};

    incoming?.nodes?.forEach(node => {
        if (node) {
            nodes[node.__ref] = node;
        }
    });

    return {
        ...incoming,
        nodes: nodes,
    };
}

export function readPaginatedResults({
    sortByField,
    existing,
    readField,
}: {
    /**
     * The name of the field to order the results by.
     * This is typically a timestamp field.
     * The field selected should be a string.
     */
    sortByField: string;
    existing: CachedPaginatedResults;
    readField: (field: string, object: { __ref: string }) => unknown;
}): PaginatedResults | undefined {
    if (existing) {
        const getTimestamp = (objectWithRef: { __ref: string }): string => {
            const output = readField(sortByField, objectWithRef);
            if (!output) {
                logger.error(
                    `Unable to read ${sortByField} from cached object: ${objectWithRef}`,
                );
            }
            if (!isString(output)) {
                logger.error(
                    `Expected "${sortByField}" field on cached object to be a string but got ${JSON.stringify(
                        typeof output,
                    )}: ${objectWithRef}`,
                );

                // This is not ideal, but it's better to show results out of order than to crash the app or not show a result
                return "";
            }

            return output;
        };

        return {
            ...existing,
            nodes: Object.values<ResultNode>(existing.nodes || {}).sort(
                (a, b) => getTimestamp(b).localeCompare(getTimestamp(a)),
            ),
        };
    }
}
