import React from "react";

import { useQuery, ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { find, merge } from "lodash";

import { gql } from "__generated__/apollo";
import {
    DevicesContainerCultureQuery,
    DevicesContainerQuery,
} from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import { Options, Select } from "components/common/Select";
import Skeleton from "components/common/Skeleton";
import Txt from "components/common/Text";
import { useFeature } from "services/feature-flags";
import { usePersistentState } from "services/hooks/usePersistentState";
import { isNotNil } from "services/utils";

import { DevicesList } from "./DevicesList";
import { log as parentLog } from "./log";

const log = parentLog.extend("DevicesContainer");

export default function DevicesContainer(): JSX.Element {
    const theme = useTheme();
    const { loading, error, devices } = useDevicesContainerData();
    const { sortBy, setSortBy } = useDevicesSort();

    const [orgIdFilter, setOrgIdFilter] = useDeviceOrgFilter();
    const deviceOrgFilterFeature = useFeature(
        "filter_devices_list_by_org_id",
    ).enabled;
    const orgIds = devices?.map(d => d.orgId) ?? [];
    const orgIdSet = new Set(orgIds);
    const uniqueOrgIds = Array.from(orgIdSet);
    const orgSelectOptions = uniqueOrgIds.map(orgId => ({
        value: String(orgId), // This also handles a "null" orgId
        label: String(orgId),
    }));

    let devicesList = (
        <Callout
            variant="error"
            message="There was an error getting your devices. Mytos Support has been informed."
        />
    );
    if (error) log.error("Error in DevicesContainer", { error });

    if (devices) {
        if (devices.length === 0) {
            devicesList = (
                <Callout message="You do not have access to any Devices yet." />
            );
        } else {
            if (sortBy === "online") {
                devices.sort(compareByOnline);
            } else {
                devices.sort(compareByName);
            }
            const filteredDevices = devices.filter(
                device => orgIdFilter === device.orgId,
            );
            const devicesToDisplay = deviceOrgFilterFeature
                ? filteredDevices
                : devices;
            devicesList = <DevicesList devices={devicesToDisplay} />;
        }
    } else if (loading) {
        devicesList = (
            <Skeleton
                variant="rectangular"
                height={60}
                style={{ borderRadius: 4 }}
            />
        );
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={4}
                css={css`
                    margin-bottom: ${theme.spacing(4)};
                `}>
                <Grid item>
                    <Txt font="primary" level={4} emphasis>
                        Devices
                    </Txt>
                </Grid>
                <Grid
                    item
                    style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                    {deviceOrgFilterFeature && (
                        <Select
                            defaultValue={orgIdFilter}
                            options={orgSelectOptions}
                            onChange={v => setOrgIdFilter(v)}
                            minWidth={155}
                        />
                    )}
                    <Select
                        defaultValue={sortBy}
                        options={DEVICES_SORT_OPTIONS}
                        onChange={v => setSortBy(v)}
                        minWidth={155}
                    />
                </Grid>
            </Grid>
            {devicesList}
        </React.Fragment>
    );
}

const DEVICES_CONTAINER_QUERY = gql(`
    query DevicesContainer {
        devices {
            id
            orgId
            isOnline
            name
            status
            migrated
            balena {
                deviceUuid
                isOnline
                lastConnected
                updateAvailable
                updating
            }
            users {
                myAccess {
                    id
                    notificationsEnabled
                }
            }
        }
    }
`);

const DEVICES_CONTAINER_CULTURE_QUERY = gql(`
    query DevicesContainerCulture {
        devices {
            id
            name
            isOnline
            culture {
                id
                name
                state
                errorMessage
                isWetTestCulture
                schedule {
                    isWaitingOnConfirmation
                    nextStep {
                        id
                    }
                    nextProcedure {
                        id
                        waitingForConfirmation
                    }
                }
            }
        }
    }
`);

export type Device = NonNullable<
    NonNullable<DevicesContainerQuery["devices"]>[number] &
        NonNullable<DevicesContainerCultureQuery["devices"]>[number]
>;

function useDevicesContainerData(): {
    loading: boolean;
    error: ApolloError | undefined;
    devices: Device[] | undefined;
} {
    /**
     * We split this GraphQL query into two parts due to the poor performance of
     * the culture field. All other fields can resolve in half a second or less,
     * whereas the culture field typically takes 2 seconds or more.
     *
     * By splitting them out we can eagerly render one part of the data, and
     * then the hydrate when the culture information comes through.
     *
     * This hook handles the messiness of splitting these requests, and then
     * merging the data together so that consumers of the data see it as a
     * single list and equivalent of a single request.
     */
    const {
        loading,
        error,
        data: baseData,
    } = useQuery(DEVICES_CONTAINER_QUERY, {
        returnPartialData: true, // https://github.com/apollographql/apollo-client/issues/7128#issuecomment-778686466
    });
    log.debug("device container query", { baseData, loading, error });

    const { data: cultureData } = useQuery(DEVICES_CONTAINER_CULTURE_QUERY, {
        returnPartialData: true, // https://github.com/apollographql/apollo-client/issues/7128#issuecomment-778686466
    });

    log.debug("Before merge", { baseData, cultureData });
    const devices = mergeData(baseData, cultureData);
    log.debug("After merge", { devices });
    return {
        loading,
        error,
        devices,
    };
}

/**
 * Merges the two devices queries. The base query handles most of the device
 * data and is faster. If this is not available, then `undefined` is returned.
 * The culture query data returns array of device data with culture information.
 * If both of these arrays are available, then the culture data is merged into
 * the array of base data.
 *
 * @param baseQuery Base case for array of device data
 * @param cultureQuery Array of device data with culture info
 * @returns Merged array of full device data
 */
function mergeData(
    baseQuery?: DevicesContainerQuery,
    cultureQuery?: DevicesContainerCultureQuery,
): Device[] | undefined {
    if (baseQuery === undefined) {
        return undefined;
    }
    const baseDevices = baseQuery.devices ?? [];
    const cultureDevices = cultureQuery?.devices ?? [];
    const baseDevicesClean = baseDevices.filter(isNotNil);
    if (cultureDevices === undefined) {
        return baseDevicesClean as Device[];
    }

    const baseDevicesFiltered = baseDevicesClean.filter(
        d => d.migrated !== true && d.balena?.deviceUuid,
    );

    const cultureDevicesClean = cultureDevices.filter(isNotNil);
    const mergedList: Device[] = baseDevicesFiltered.map(item =>
        merge(
            { ...item, culture: undefined }, // merge() will only copy across fields that are present on the target object
            find(cultureDevicesClean, { id: item.id }),
        ),
    );

    return mergedList;
}

type DevicesSort = "name" | "online";

const DEVICES_SORT_OPTIONS: Options<DevicesSort> = [
    { value: "name", label: "Sort by name" },
    { value: "online", label: "Sort by online" },
] as const;

function useDevicesSort(): {
    sortBy: DevicesSort;
    setSortBy: (s: DevicesSort) => void;
} {
    const [sortBy, setSortBy] = usePersistentState<DevicesSort>(
        "devices-sortby",
        "name",
    );
    return {
        sortBy,
        setSortBy,
    };
}

function useDeviceOrgFilter(): [
    string,
    React.Dispatch<React.SetStateAction<string>>,
] {
    const state = usePersistentState<string>("devices-org-filter", "mytos");
    return state;
}

function compareByName(devA: Device, devB: Device): number {
    if (devA.name && devB.name) {
        if (devA.name > devB.name) return 1; // a is after b
        if (devA.name < devB.name) return -1; // a is before b
    } else {
        if (devA.name && !devB.name) return -1;
        if (!devA.name && devB.name) return 1;
        if (!devA.name && !devB.name) return 0;
    }
    return 0;
}

function compareByOnline(a: Device, b: Device): number {
    if (!a.isOnline && b.isOnline) return 1; // a is after b
    if (a.isOnline && !b.isOnline) return -1; // a is before b
    return compareByName(a, b); // fall back to ordering by name
}
