import React, { ReactElement, ReactNode, useState } from "react";

import {
    Breakpoint,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    Divider,
    Slide,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { isNumber } from "lodash";
import { Helmet } from "react-helmet-async";

import { IconButton } from "components/common/IconButton";
import Text from "components/common/Text";

import Button, { ButtonProps } from "../Button";

export type ModalButtonClickHandler = (close: () => void) => void;

export type ModalFooterButtonProps = {
    /**
     * Label displayed in the button
     */
    label: string;
    /**
     * Disable the button
     */
    disabled?: boolean;
    /**
     * Callback function executed when Footer Button is clicked. Is provided a
     * close() handler to control the closure of the modal.
     *
     * @param close - Function to handle closing the modal
     */
    onClick?: ModalButtonClickHandler;
};

export type ModalProps = {
    /**
     * String used in the title bar at top of modal
     */
    title: string;
    /**
     * Components rendered in the body of the modal
     */
    children: ReactNode;
    /**
     * Padding applied to the body where the content (children) of the modal is
     * rendered
     */
    contentPadding?: number;
    /**
     * Specify the maximum width of the modal in terms of a width breakpoint.
     * This is optional for specific use cases.
     */
    maxWidth?: Breakpoint;
    /**
     * Properties for configuring the left action button on the modal footer
     */
    buttonLeft: ModalFooterButtonProps;
    /**
     * Properties for configuring the right action button on the modal footer
     */
    buttonRight?: ModalFooterButtonProps;
    /**
     * Function to execute just before modal opens
     */
    onOpen?: () => void;
    /**
     * Function to execute just after modal closes
     */
    onClose?: () => void;
};

/**
 * Extended properties for Modal that are needed when state is being managed by
 * Modal itself.
 */
type ModalManagedState = {
    /**
     * Function which is provided a callback in order to open the modal and
     * should return the component that should be rendered in the DOM to control
     * opening the modal
     */
    openModalElement: (openModal: () => void) => ReactElement;
};

/**
 * Extended properties for Modal when parent is managing state on its behalf.
 */
export type ParentManagedState = {
    /**
     * Parent-driven state of whether the modal is open
     */
    modalOpen: boolean;
    /**
     * Parent-driven state control to update modalOpen
     */
    setModalOpen: (update: boolean) => void;
};

const ModalContext = React.createContext<{ handleClose: () => void } | null>(
    null,
);

export function Modal(
    props: ModalProps & (ModalManagedState | ParentManagedState),
): ReactElement {
    return (
        <ModalWrapper {...props}>
            <ModalContent {...props} />
        </ModalWrapper>
    );
}

type ModalContentProps = Pick<
    ModalProps,
    "title" | "children" | "contentPadding" | "buttonLeft" | "buttonRight"
>;

export function ModalContent(props: ModalContentProps) {
    const { title, children, contentPadding, buttonLeft, buttonRight } = props;
    const context = React.useContext(ModalContext);
    if (!context) {
        throw new Error("ModalContent must be used within a ModalWrapper");
    }
    const { handleClose } = context;
    return (
        <>
            <ModalHeader
                title={title}
                id="notifications-dialog-title"
                onClose={handleClose}
            />
            <Divider />
            <DialogContent
                sx={{
                    padding: contentPadding,
                    px: isNumber(contentPadding) ? undefined : { xs: 4, sm: 6 },
                    display: "flex",
                }}>
                {children}
            </DialogContent>
            <Divider />
            <ModalFooter
                leftButtonProps={{
                    onClick: () => buttonLeft.onClick?.(handleClose),
                    children: buttonLeft.label,
                    disabled: buttonLeft.disabled,
                }}
                rightButtonProps={
                    buttonRight && {
                        onClick: () => buttonRight.onClick?.(handleClose),
                        children: buttonRight.label,
                        disabled: buttonRight.disabled,
                    }
                }
            />
        </>
    );
}

type ModalWrapperProps = Omit<ModalProps, keyof ModalContentProps> & {
    children: ReactNode;
} & (ModalManagedState | ParentManagedState);

export function ModalWrapper(props: ModalWrapperProps): ReactElement {
    const { maxWidth, onOpen, onClose, children } = props;
    const hasParentState = "modalOpen" in props;
    const [modalOpen, setModalOpen] = useState(
        hasParentState ? props.modalOpen : false,
    );
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const handleOpen = () => {
        onOpen?.();
        if (hasParentState) {
            props.setModalOpen(true);
        } else {
            setModalOpen(true);
        }
    };
    const handleClose = () => {
        if (hasParentState) {
            props.setModalOpen(false);
        } else {
            setModalOpen(false);
        }
        onClose?.();
    };
    const isOpen = hasParentState ? props.modalOpen : modalOpen;

    return (
        <>
            {!hasParentState && props.openModalElement(handleOpen)}
            {isOpen && (
                <Helmet>
                    <meta
                        name="theme-color"
                        content={theme.colours.brand.lightBlue}
                    />
                </Helmet>
            )}
            <Dialog
                BackdropProps={{
                    style: {
                        backgroundColor: "rgba(0,0,0,0.2)",
                        backdropFilter: "blur(8px)",
                        WebkitBackdropFilter: "blur(8px)",
                    },
                }}
                fullScreen={fullScreen}
                fullWidth
                maxWidth={maxWidth ?? "md"}
                scroll="paper"
                TransitionComponent={
                    fullScreen ? FullScreenTransition : undefined
                }
                open={isOpen}
                onClose={handleClose}>
                <ModalContext.Provider value={{ handleClose }}>
                    {children}
                </ModalContext.Provider>
            </Dialog>
        </>
    );
}

const FullScreenTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalHeaderProps {
    id: string;
    title: string;
    onClose: () => void;
}

function ModalHeader(props: ModalHeaderProps) {
    const { title, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            sx={{ backgroundColor: theme => theme.colours.brand.lightBlue }}
            {...other}>
            <div
                style={{
                    display: "flex",
                    alignItems: "start",
                }}>
                <Text
                    level={5}
                    emphasis
                    display="inline"
                    style={{ flexGrow: 1 }}>
                    {title}
                </Text>
                <IconButton
                    icon="cross"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    // Padding of 2 makes the height of the icon 28px, which
                    // matches the heigh of level 5 text, therefore when one
                    // line of title is present it appears as if align items
                    // center is called, however when there are multiple lines,
                    // this does not pull the icon down, it stays inline with
                    // the first line of text
                    style={{ padding: "2px 0" }}
                />
            </div>
        </MuiDialogTitle>
    );
}

interface ModalFooterProps {
    leftButtonProps: ButtonProps;
    rightButtonProps?: ButtonProps;
}

function ModalFooter(props: ModalFooterProps) {
    const { leftButtonProps, rightButtonProps } = props;

    return (
        <DialogActions
            sx={{
                backgroundColor: theme => theme.colours.neutral[100],
                justifyContent: "space-between",
                py: "16px",
                px: "24px",
            }}>
            <Button variant="secondary" size="m" {...leftButtonProps} />
            {rightButtonProps && <Button size="m" {...rightButtonProps} />}
        </DialogActions>
    );
}
