import { useLazyQuery, useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { EditBottleInput } from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from "./log";

const log = parentLog.extend("useUpdateBottle");

const EDIT_BOTTLE_MUTATION = gql(`
    mutation EditBottle($editBottleInput: EditBottleInput!) {
        editBottle(input: $editBottleInput) {
            ok
            message
        }
    }
`);

const REFRESH_EDITED_BOTTLE_QUERY = gql(`
    query RefreshEditedBottle($bottleId: String!) {
        bottle(id: $bottleId) {
            id
            name
            liquidVolumeMl
            totalVolumeMl
        }
    }
`);

/**
 * Returns true if bottle successfully updated
 */
export function useUpdateBottle() {
    const [editBottle] = useMutation(EDIT_BOTTLE_MUTATION);
    const [refreshEditedBottle] = useLazyQuery(REFRESH_EDITED_BOTTLE_QUERY);

    const { toast } = useToasts();

    const handleEditBottle = async (editBottleInput: EditBottleInput) => {
        const toastId = toast.loading("Editing bottle");

        let timeoutId: NodeJS.Timeout | undefined;
        let pollId: NodeJS.Timeout | undefined;

        const clearTimeouts = () => {
            clearTimeout(timeoutId);
            clearTimeout(pollId);
        };

        try {
            const output = await editBottle({
                variables: {
                    editBottleInput,
                },
            });

            if (!output.data?.editBottle.ok) {
                throw new Error(output.data?.editBottle.message);
            }

            await new Promise<void>((resolve, reject) => {
                timeoutId = setTimeout(() => {
                    clearTimeouts();
                    reject(new Error("Data was not updated in 30 seconds"));
                }, 30_000);

                void pollForUpdate();

                async function pollForUpdate() {
                    const { data } = await refreshEditedBottle({
                        variables: { bottleId: editBottleInput.bottleId },
                    });

                    const { liquidVolumeMl, totalVolumeMl } =
                        data?.bottle ?? {};
                    const updatedCompleted =
                        liquidVolumeMl === editBottleInput.liquidVolume &&
                        totalVolumeMl === editBottleInput.totalVolume;

                    if (!updatedCompleted) {
                        pollId = setTimeout(pollForUpdate, 500);
                        return;
                    }

                    clearTimeouts();
                    resolve();
                }
            });

            toast.update(toastId, {
                render: "Bottle edited successfully",
                type: "success",
                isLoading: false,
            });
            return true;
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                render: "Failed to edit bottle",
                type: "error",
                isLoading: false,
            });
            return false;
        } finally {
            clearTimeouts();
        }
    };

    return handleEditBottle;
}
