import { RefObject, useEffect, useRef } from "react";

import scrollIntoView, {
    StandardBehaviorOptions,
} from "scroll-into-view-if-needed";

import { log as parentLog } from "..";

const log = parentLog.extend("scroll");

interface UseScrollIntoViewProps {
    /**
     * Scroll will only occur when set to true
     */
    enabled: boolean;
    /**
     * The scroll behaviour, if this is enabled, will only be run if enabled is
     * true and the hook is mounting for the first time. Any future times the
     * hook is run, even if `enabled=true`, the scroll behaviour will not be
     * triggered.
     *
     * Defaults to `true`
     */
    onlyOnFirstRender?: boolean;
}

type UseScrollIntoView<T> = {
    /**
     * React Ref Object to be attached to target DOM element.
     *
     * Attach by setting `ref={ref}` on the React component to target.
     */
    ref: RefObject<T>;
    /**
     * Handle for manually calling scroll
     */
    scroll: () => void;
};

/**
 * Hook that will scroll a target element into view based on conditions. For the
 * scroll to occur, the `ref` returned from the hook must be attached to an
 * HTMLElement and the `enabled` arg must be `true`.
 *
 * @param enabled - Boolean to determine whether scroll should be actioned
 * @param onlyOnFirstRender - Defaults `true`. Only activates scroll on first render
 * @returns
 * - `ref` to be attached to scroll target element
 */
export function useScrollIntoView<T extends HTMLElement>(
    args: UseScrollIntoViewProps,
): UseScrollIntoView<T> {
    const { enabled, onlyOnFirstRender = true } = args;
    const ref = useRef<T>(null);
    const firstRenderRef = useRef(true);

    const handleScroll = () => {
        log.debug("handleScroll was called");
        if (ref.current) scrollIntoViewIfNeeded(ref.current);
    };
    useEffect(() => {
        const runOnRender = onlyOnFirstRender ? firstRenderRef.current : true;
        if (enabled && runOnRender) {
            handleScroll();
            log.debug("useScrollIntoView useEffect called handleScroll");
        }
        firstRenderRef.current = false;
    }, [ref, enabled, onlyOnFirstRender]);

    return {
        ref,
        scroll: handleScroll,
    };
}

export function scrollIntoViewByHtmlId(
    htmlId: string,
    options?: StandardBehaviorOptions,
) {
    const element = document.getElementById(htmlId);
    if (element) {
        scrollIntoViewIfNeeded(element, options);
    }
}

export function scrollIntoViewIfNeeded(
    element: HTMLElement,
    options?: StandardBehaviorOptions,
) {
    scrollIntoView(element, {
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
        behavior: "smooth",
        ...options,
    });
}
