import React from "react";

import { css } from "@emotion/react";
import MuiIconButton, {
    IconButtonProps as MuiIconButtonProps,
} from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Icon, { IconName } from "./Icon";

export interface IconButtonProps extends Omit<MuiIconButtonProps, "size"> {
    /**
     * The icon to be displayed in the button
     */
    icon: IconName;
    /**
     * The display size of the icon. "md" is default, and matches the medium
     * size of the `Button` component.
     *
     * Other sizes do not match the `Button` component.
     */
    size?: "sm" | "md" | "lg";
    /**
     * Optional tooltip text to display
     */
    tooltipText?: string;
    /**
     * Manually override the colour of the icon. If no value is provided, the
     * icon inherits its colour from the parent by default.
     */
    colour?: string;
    ref?: React.RefObject<HTMLButtonElement> | null | undefined;
}

export function IconButton(props: IconButtonProps): JSX.Element {
    const { icon, tooltipText, colour, size = "md", ...remainingProps } = props;

    let muiSize: "medium" | "small" | undefined = "medium";
    let padding: number | undefined;
    if (size === "sm") {
        muiSize = "small";
        padding = 3;
    }
    if (size === "md") {
        padding = 5;
    }
    return (
        <Tooltip
            // disableFocusListener={!navPage.disabled}
            // disableHoverListener={!navPage.disabled}
            // disableTouchListener={!navPage.disabled}
            title={tooltipText ?? ""}
            arrow>
            <MuiIconButton
                ref={props.ref}
                css={css`
                    && {
                        padding: ${padding}px;
                        /* color: inherit; */
                    }
                    &&:disabled {
                        opacity: 0.2;
                    }
                `}
                aria-label="delete"
                size={muiSize}
                {...remainingProps}>
                <Icon name={icon} colourOverride={colour} />
            </MuiIconButton>
        </Tooltip>
    );
}
