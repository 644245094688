import { ChangeEvent, ReactElement } from "react";

import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { fontFamilyMap, textLevelFontSize, textLevelLineHeight } from "./Text";

export interface TextInputProps {
    /** Value displayed in text input */
    value?: string;
    /** Callback that get called on every character change of the input */
    onChange?: (value: string) => void;
    /** Input becomes required in context of a form */
    required?: boolean;
    /** Input takes up full width of container */
    fullWidth?: boolean;
    /** Disable editing of the input */
    disabled?: boolean;
    /** Specify value type e.g. "tel" */
    type?: string;
    /** Text string displayed to help user */
    helperText?: string;
    /** Set whether input should be in error state */
    error?: boolean;
    /**
     * Optional id for the input.
     */
    id?: string;
}

export function TextInput(props: TextInputProps): ReactElement {
    const {
        required = false,
        fullWidth = false,
        value,
        onChange,
        ...restOfProps
    } = props;
    const onValueChange = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        onChange?.(event.target.value);
    };

    return (
        <StyledTextField
            id={props.id ?? "outlined-basic"}
            size="small"
            variant="outlined"
            required={required}
            fullWidth={fullWidth}
            value={value}
            onChange={onValueChange}
            {...restOfProps}
        />
    );
}

export const StyledTextField = styled(TextField)<TextFieldProps>(
    ({ theme, fullWidth }) => ({
        "& .MuiOutlinedInput-root": {
            "& input": {
                fontFamily: fontFamilyMap.secondary,
                fontSize: textLevelFontSize[8],
                width: fullWidth ? undefined : 232,
                textOverflow: "ellipsis",
                height: 22,
                padding: "6px 12px",
            },
            "color": theme.colours.neutral[900],
            "&.Mui-error fieldset": {
                borderColor: theme.colours.orange[500],
            },
            "&.Mui-focused fieldset": {
                borderWidth: 1,
            },
        },
        "& .MuiFormHelperText-root": {
            "marginLeft": 0,
            "marginTop": 4,
            "fontFamily": fontFamilyMap.secondary,
            "fontSize": textLevelFontSize[9],
            "lineHeight": textLevelLineHeight[9],
            "&.Mui-error": {
                color: theme.colours.orange[500],
            },
        },
    }),
);
