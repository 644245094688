import { ComponentProps, ReactElement } from "react";

import { styled } from "@mui/material/styles";
import { type CloseButton } from "react-toastify";

import { IconButton } from "components/common/IconButton";

type CloseButtonProps = ComponentProps<typeof CloseButton>;

export function ToastCloseButton(props: CloseButtonProps): ReactElement {
    const { closeToast } = props;
    return (
        <IconContainer onClick={closeToast}>
            <IconButton icon="cross" size="sm" />
        </IconContainer>
    );
}

const IconContainer = styled("div")(({ theme }) => ({
    "color": theme.colours.neutral[600],
    "fontSize": 16,
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "minWidth": 60,
    "&:hover": {
        // backgroundColor: "#0000000A",
    },
}));
