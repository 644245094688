import { ReactElement } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

import Icon, { IconName } from "components/common/Icon";
import Txt from "components/common/Text";

import { log as parentLog } from "..";

export const log = parentLog.extend("SoftwareUpdateBadge");

export interface SoftwareUpdateBadgeProps {
    deviceId: string;
    updateAvailable: boolean;
    updating: boolean;
    border?: boolean;
    size?: "default" | "sm";
}

export function SoftwareUpdateBadge(
    props: SoftwareUpdateBadgeProps,
): ReactElement {
    const { updateAvailable, updating, size = "default" } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const accentColour = updating
        ? theme.colours.accent.errorRed
        : updateAvailable
          ? theme.colours.accent.alertOrange
          : theme.colours.brand.greenDark;

    const text: string = updating
        ? "Updating software"
        : updateAvailable
          ? "Software update available"
          : "Software up to date";

    const icon: IconName = updating
        ? "sync-arrows"
        : updateAvailable
          ? "alert-triangle"
          : "checkmark";

    const mainElement = (
        <div
            css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                color: ${accentColour};
            `}>
            <Icon name={icon} />
            {size === "default" && (
                <Txt font="secondary" level={9}>
                    {text}
                </Txt>
            )}
        </div>
    );

    if (!props.border || size === "sm") return mainElement;

    return (
        <Tooltip title="Go to Device settings to update">
            <span
                onClick={e => {
                    e.stopPropagation();
                    // we use navigate instead of a Link here to avoid
                    // nested anchor tags
                    navigate(`/devices/${props.deviceId}/settings`);
                }}
                css={css`
                    cursor: pointer;
                    /* height: 38px; */
                    display: inline-flex;
                    align-items: center;
                    border: 1px solid;
                    border-color: ${accentColour};
                    border-radius: 4px;
                    padding: 2px 8px;
                `}>
                {mainElement}
            </span>
        </Tooltip>
    );
}
