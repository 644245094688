import { ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";
import copy from "copy-to-clipboard";

import { gql } from "__generated__/apollo";
import {
    CultureState,
    ProcedureState,
    ProcedureControl,
} from "__generated__/apollo/graphql";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { IconName } from "components/common/Icon";
import { MoreButton, MoreButtonOptions } from "components/common/MoreButton";
import { RescheduleModal } from "components/common/RescheduleModal/RescheduleModal";
import {
    getToastifyMessages,
    useToasts,
} from "components/common/toasts/useToasts";
import {
    procedureIsUpcoming,
    procedureStatePhaseMap,
} from "services/culture-utils";
import useProcedureControl from "services/hooks/server-minted-cultures/useProcedureControl";
import { usePauseCulture } from "services/hooks/useCultureControlLegacy";
import { useCultureOverviewSimple } from "services/hooks/useCultureOverview";
import { Procedure } from "services/hooks/useCultureSchedule";
import { useDeviceRole } from "services/hooks/useDeviceRole";
import { useSearchParamsState } from "services/hooks/useSearchParamsState";

import { log as parentLog } from "../log";

import EditProcedureStepsModal from "./EditProcedureStepsModal";
import { useRescheduleProcedure } from "./useRescheduleProcedure";

export const log = parentLog.extend("ProcedureControls");

const duplicateProcedureMutation = gql(`
    mutation DuplicateProcedure($input: DuplicateProcedureInput!) {
        duplicateProcedure(input: $input) {
            ok
            message
            culture {
                id

                # Ensure we show the new procedure to the user on complete
                schedule {
                    ...ScheduleWithAllProcedures
                }
            }
            newProcedure {
                id
            }
        }
    }
`);

enum ProcedureControlLabels {
    Skip = "Skip",
    Restore = "Restore",
    Cancel = "Cancel",
    CopyId = "Copy ID",
    Reschedule = "Reschedule",
    Edit = "Edit",
    Duplicate = "Duplicate",
}

type Control = {
    control: ProcedureControl;
    /** Button label for control */
    label: ProcedureControlLabels;
    /** Procedure states where the control can apply */
    states: (ProcedureState | null)[];
    icon?: IconName;
};

const CONTROLS: {
    [key in Exclude<
        ProcedureControl,
        | ProcedureControl.Confirm
        | ProcedureControl.Unconfirm
        | ProcedureControl.Pause
    >]: Control;
} = {
    REMOVE: {
        control: ProcedureControl.Remove,
        label: ProcedureControlLabels.Skip,
        states: [ProcedureState.Planned],
        icon: "skip",
    },
    RESTORE: {
        control: ProcedureControl.Restore,
        label: ProcedureControlLabels.Restore,
        states: [ProcedureState.Removed],
        icon: "sync-arrows",
    },
    CANCEL: {
        control: ProcedureControl.Cancel,
        label: ProcedureControlLabels.Cancel,
        states: [...procedureStatePhaseMap.active],
        icon: "disable",
    },
};

export interface ProcedureControlsProps {
    deviceId: string;
    cultureId: string;
    procedure: Procedure;
}

export function ProcedureControls({
    deviceId,
    cultureId,
    procedure,
}: ProcedureControlsProps): ReactElement {
    const { canOperate } = useDeviceRole(deviceId);
    const { rescheduleProcedure } = useRescheduleProcedure();

    // Fetch culture overview to check if the culture is active and the device is online
    const { data: cultureOverview } = useCultureOverviewSimple({ cultureId });
    const deviceIsOnline = cultureOverview?.culture?.device?.isOnline ?? false;
    const cultureIsActive = cultureOverview?.culture?.isActive ?? false;

    const procedureIsInThePast = !procedureIsUpcoming(procedure.state);
    const [attemptedControl, setAttemptedControl] = useState<string | null>(
        null,
    );
    const [rescheduleStepModalOpen, setRescheduleStepModalOpen] =
        useState(false);
    const { toastifyAction } = useToasts();

    // The procedure ID is stored in query string to open the modal
    const [editStepsModalQueryString, setEditStepsModalQueryString] =
        useSearchParamsState({ name: "edit-steps" });

    const editStepsModalOpen = editStepsModalQueryString === procedure.id;
    const setEditStepsModalOpen = (open: boolean) =>
        setEditStepsModalQueryString(open ? procedure.id : undefined);

    const pauseCulture = usePauseCulture(deviceId);

    // Running cultures should be paused before performing certain actions
    const cultureState = cultureOverview?.culture?.state;
    const shouldPause = cultureState === CultureState.Running;

    const cultureUploadedViaOldFlow = !cultureOverview?.culture?.mintedByServer;

    const onlyAllowForServerMinted = <
        T extends { disabled?: boolean; tooltip?: string },
    >(
        value: T,
    ): T => ({
        ...value,
        disabled: Boolean(cultureUploadedViaOldFlow || value.disabled),
        tooltip: cultureUploadedViaOldFlow
            ? "This functionality is only available for cultures uploaded via the new flow."
            : value.tooltip,
    });

    const [duplicateProcedureSteps] = useMutation(duplicateProcedureMutation);
    const [controlProcedure, { loading: controlSubmitting }] =
        useProcedureControl();

    async function handleDuplicateProcedure() {
        void toastifyAction(
            getToastifyMessages({
                entity: "procedure",
                verb: {
                    past: "duplicated",
                    gerund: "duplicating",
                },
            }),
            async () => {
                const output = await duplicateProcedureSteps({
                    variables: {
                        input: {
                            procedureId: procedure.id,
                        },
                    },
                });

                if (output.data?.duplicateProcedure.ok) {
                    return;
                } else {
                    throw new Error(output.data?.duplicateProcedure.message);
                }
            },
        );
    }

    const controlOptions: MoreButtonOptions = [
        {
            icon: "clipboard",
            label: ProcedureControlLabels.CopyId,
            onClick: () => copy(procedure.id),
        },
    ];

    if (canOperate && cultureIsActive) {
        controlOptions.push("divider");

        const rescheduleWarningTooltip = !deviceIsOnline
            ? "Procedures cannot be rescheduled while the device is offline."
            : procedureIsInThePast
              ? "Only upcoming procedures can be rescheduled."
              : undefined;

        controlOptions.push(
            onlyAllowForServerMinted({
                icon: "calendar" as const,
                label: ProcedureControlLabels.Reschedule,
                onClick: () => {
                    if (shouldPause) {
                        setAttemptedControl(ProcedureControlLabels.Reschedule);
                    } else {
                        setRescheduleStepModalOpen(true);
                    }
                },
                disabled: rescheduleWarningTooltip !== undefined,
                tooltip: rescheduleWarningTooltip,
            }),
        );

        const editWarningTooltip = !deviceIsOnline
            ? "Procedures cannot be edited while the device is offline."
            : procedureIsInThePast
              ? "Only upcoming procedures can have their steps edited."
              : undefined;

        controlOptions.push(
            onlyAllowForServerMinted({
                icon: "list" as const,
                label: ProcedureControlLabels.Edit,
                onClick: () => {
                    if (shouldPause) {
                        setAttemptedControl(ProcedureControlLabels.Edit);
                    } else {
                        setEditStepsModalOpen(true);
                    }
                },
                disabled: editWarningTooltip !== undefined,
                tooltip: editWarningTooltip,
            }),
        );

        const duplicateWarningTooltip = !deviceIsOnline
            ? "Procedures cannot be duplicated while the device is offline."
            : undefined;

        controlOptions.push(
            onlyAllowForServerMinted({
                icon: "copy" as const,
                label: ProcedureControlLabels.Duplicate,
                onClick: () => {
                    if (shouldPause) {
                        setAttemptedControl(ProcedureControlLabels.Duplicate);
                    } else {
                        void handleDuplicateProcedure();
                    }
                },
                disabled: duplicateWarningTooltip !== undefined,
                tooltip: duplicateWarningTooltip,
            }),
        );

        Object.values(CONTROLS).forEach(control => {
            if (control.states.includes(procedure.state)) {
                controlOptions.push("divider");
                controlOptions.push(
                    onlyAllowForServerMinted({
                        icon: control.icon,
                        label: control.label,
                        onClick: () =>
                            controlProcedure({
                                control: control.control,
                                procedureId: procedure.id,
                            }),
                        disabled: controlSubmitting,
                    }),
                );
            }
        });
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            <MoreButton size="sm" options={controlOptions} />
            <ConfirmationDialog
                variant="advised"
                delayPrimaryAction
                open={Boolean(attemptedControl)}
                onPrimaryAction={async () => {
                    const paused = await pauseCulture();
                    if (paused) {
                        switch (attemptedControl) {
                            case ProcedureControlLabels.Reschedule:
                                setRescheduleStepModalOpen(true);
                                break;
                            case ProcedureControlLabels.Edit:
                                setEditStepsModalOpen(true);
                                break;
                            case ProcedureControlLabels.Duplicate:
                                void handleDuplicateProcedure();
                                break;
                            default:
                                break;
                        }
                    }
                    setAttemptedControl(null);
                }}
                onDismiss={() => {
                    setAttemptedControl(null);
                }}
                title={"Culture is running"}
                body={`Pausing the culture will allow you to ${attemptedControl} this procedure.`}
                primaryButtonText="Pause culture"
            />
            <RescheduleModal
                type="procedure"
                name={procedure.name}
                initialTimePlanned={procedure.timePlanned}
                modalOpen={rescheduleStepModalOpen}
                setModalOpen={setRescheduleStepModalOpen}
                showCascadeOption={true}
                onSubmit={async ({ targetDate, cascade }) => {
                    await rescheduleProcedure({
                        targetISOTimestamp: targetDate.toISOString(),
                        cascade,
                        procedureId: procedure.id,
                    });
                }}
            />
            <EditProcedureStepsModal
                modalOpen={editStepsModalOpen}
                setModalOpen={setEditStepsModalOpen}
                procedure={procedure}
            />
        </div>
    );
}
