import { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { IconName } from "components/common/Icon";
import { useFeature } from "services/feature-flags";

export type NavOption = {
    name: string;
    icon: IconName;
    path: string;
    disabled?: boolean;
    new?: boolean;
};

export function useNavOptions(): {
    navIndex: number | false;
    navOptions: NavOption[];
} {
    const location = useLocation();
    const enabledCulturesPage = useFeature("app_cultures_page").enabled;
    const navOptions: NavOption[] = [
        {
            name: "Dashboard",
            icon: "dashboard",
            path: "/dashboard",
            disabled: true,
        },
        {
            name: "Devices",
            icon: "device-sidecar",
            path: "/devices",
        },
        {
            name: "Cultures",
            icon: "lab-tube-experiment",
            path: "/cultures",
            new: true,
            disabled: !enabledCulturesPage,
        },
        {
            name: "Protocols",
            icon: "design-tool-wireframe",
            path: "/protocols",
            disabled: true,
        },
        // We hide these for now as we don't want to gaslight customers by promising anything too soon.
        // {
        //     name: "Data",
        //     icon: "notes-book",
        //     path: "/data",
        //     disabled: true,
        // },
        // {
        //     name: "Stock",
        //     icon: "task-list-clipboard",
        //     path: "/stock",
        //     disabled: true,
        // },
    ];

    // on first render we need to provide an initial value that matches the
    // current path
    const initialIndex = getCurrentNavIndex(navOptions, location.pathname);
    const [navTabIndex, setNavTabIndex] = useState(initialIndex);

    // we need to register a side effect to run if the location is ever updated
    // by an external function, to make sure the nav is updated
    useEffect(() => {
        const newIndex = getCurrentNavIndex(navOptions, location.pathname);
        setNavTabIndex(newIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return { navIndex: navTabIndex, navOptions };
}

/**
 * Takes current path and returns the tab index it believes should be
 * active. If no tab should be active, it will return false.
 *
 * @param navPages List of navigation page data
 * @param currentPath Current path of the browser location
 */
export function getCurrentNavIndex(
    navData: NavOption[],
    currentPath: string,
): number | false {
    for (let i = 0; i < navData.length; i++) {
        // previously we would assess if it shared the same path prefix:
        // currentPath.startsWith(navData[i].path)
        if (currentPath === navData[i].path) {
            return i;
        }
    }
    return false; // no appropriate tab was chosen
}
