import * as Sentry from "@sentry/react";

import { config } from "./config";
import defaultLog from "./logger";

const logger = defaultLog.extend("sentry");

/**
 * Initialisation of the Sentry monitoring logic
 */
export function initialiseSentry(): void {
    const environment = config.evaluateEnvironment();
    if (environment === "development" || config.isLocal) {
        logger.info(
            "Sentry has not been initialised as you are running a development environment",
        );
        return;
    }

    Sentry.init({
        dsn: "https://c2ab0c82ab22461492d1be37552401f9@o83899.ingest.sentry.io/5664658",
        integrations: [Sentry.browserTracingIntegration()],

        // Sentry recommends adjusting this value in production, or using
        // tracesSampler for finer control
        tracesSampleRate: 1.0,
        environment,
        release: config.gitHash,

        // We are explicitly ignoring these errors since they cannot be handled
        // and are part of the usual browser behaviour that we cannot control.
        // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios/60860369#60860369
        ignoreErrors: [
            "TypeError: Failed to fetch",
            "TypeError: NetworkError when attempting to fetch resource.",
            "TypeError: Cancelled",
            "AbortError: Fetch is aborted",
            "TypeError: Load failed",
            "TypeError: The network connection was lost",
        ],
    });
    setSentryScope("app-version", config.appVersion || "unknown");
}

/**
 * Sets an additional scope key-value pair for better metadata on Sentry
 * reports.
 *
 * @param key Sentry scope key
 * @param value Sentry scope value
 */
export function setSentryScope(key: string, value: string): void {
    Sentry.getCurrentScope().setExtra(key, value);
}

/**
 * Configures Sentry reporting with the context of the current logged in user
 * @param user { id, email } of user
 */
export function setUserInSentryContext(user: {
    id?: string;
    email?: string;
}): void {
    const { id, email } = user;
    Sentry.setUser({ id, email });
}

/**
 * Clears the current user from Sentry's reporting. To be used when a user is
 * logged out from their account.
 */
export function clearUserFromSentryContext(): void {
    Sentry.getCurrentScope().setUser(null);
}
