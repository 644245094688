import { ReactElement, ReactNode } from "react";

import styled from "@emotion/styled";

export interface TabContentProps {
    children: NonNullable<ReactNode>;
}

/**
 * A custom container for common tab content. Sets a max width for the content
 * and adds some padding.
 *
 * @param props Takes children only
 */
export function TabContent(props: TabContentProps): ReactElement {
    const { children } = props;
    return <Container id="tabcontent">{children}</Container>;
}

const Container = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    padding-top: 8px;
    padding-bottom: 40px;

    padding-left: 8px;
    padding-right: 8px;

    ${({ theme }) => theme.breakpoints.up("sm")} {
        padding-left: 12px;
        padding-right: 12px;
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
        max-width: 960px;
    }
`;
