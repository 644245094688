import { useState } from "react";

import { useQuery } from "@apollo/client";
import { useAtom } from "jotai";

import { gql } from "__generated__/apollo/gql";
import { Protocol } from "__generated__/apollo/graphql";
import { removeNullables } from "services/utils";

import { cellConcentrationAtom } from "../create-culture-state";
import { log as parentLog } from "../log";

export const log = parentLog.extend("use-cell-preparation-data");

const PROTOCOL_CELL_DATA_QUERY = gql(`
    query ProtocolCellData($protocolId: String!) {
        protocol(id: $protocolId) {
            id
            name
            initialCoatingTimeMinutes
            initialSeedProcedureData {
                flaskSeedGroups {
                    doseMl
                    flaskNumbers
                    flaskSizeCm2
                }
                seedVesselCellSuspensionMl
            }
        }
    }
`);

type ProtocolCellData = Pick<
    Protocol,
    "initialCoatingTimeMinutes" | "initialSeedProcedureData"
>;

export function useProtocolCellDataQuery(protocolId?: string): {
    protocolCellData: ProtocolCellData;
    loading: boolean;
    error: Error | undefined;
} {
    const { data, loading, error } = useQuery(PROTOCOL_CELL_DATA_QUERY, {
        skip: !protocolId,
        variables: { protocolId: protocolId ?? "" },
        fetchPolicy: "cache-and-network",
    });

    return {
        protocolCellData: {
            initialCoatingTimeMinutes:
                data?.protocol?.initialCoatingTimeMinutes ?? null,
            initialSeedProcedureData:
                data?.protocol?.initialSeedProcedureData ?? null,
        },
        loading,
        error,
    };
}

export type CellPreparationStaticDetails = {
    flaskGroupDetails: {
        flasks: number[];
        flaskSizeCm2: number;
        doseMl: number;
    }[];
    seedVesselCellSuspensionMl: number | null;
};

type FlaskCellDetails = {
    cellDensity: number | null;
    cellCount: number | null;
};

function flaskCellDetailsFromConcentration(
    seedVesselCellConcentration: number | null,
    staticDetails: CellPreparationStaticDetails,
): FlaskCellDetails[] {
    if (!seedVesselCellConcentration) {
        return staticDetails.flaskGroupDetails.map(() => {
            return { cellCount: null, cellDensity: null };
        });
    }

    return staticDetails.flaskGroupDetails.map(({ doseMl, flaskSizeCm2 }) => {
        const cellCount = seedVesselCellConcentration * doseMl;
        const cellDensity = cellCount / flaskSizeCm2;
        return { cellCount, cellDensity };
    });
}
function flaskCellDetailsAndConcentrationFromSeedDensity(
    newSeedDensity: number | null,
    flasksIndex: number,
    staticDetails: CellPreparationStaticDetails,
): { cellDetails: FlaskCellDetails[]; cellConcentration: number | null } {
    if (!newSeedDensity) {
        return {
            cellConcentration: null,
            cellDetails: flaskCellDetailsFromConcentration(null, staticDetails),
        };
    }

    const newCellCount =
        newSeedDensity *
        staticDetails.flaskGroupDetails[flasksIndex].flaskSizeCm2;

    const newCellConcentration =
        newCellCount / staticDetails.flaskGroupDetails[flasksIndex].doseMl;

    return {
        cellConcentration: newCellConcentration,
        cellDetails: flaskCellDetailsFromConcentration(
            newCellConcentration,
            staticDetails,
        ),
    };
}

function seedCellCountFromConcentration(
    cellConcentration: number | null,
    seedVesselCellSuspensionMl: number | null,
): number | null {
    if (!cellConcentration || !seedVesselCellSuspensionMl) {
        return null;
    }

    return cellConcentration * seedVesselCellSuspensionMl;
}

type CellPreparationInfo = {
    protocolHasSeedProcedure: boolean;
    coatingTimeMinutes: number | null;
    cellPreparationStaticDetails: CellPreparationStaticDetails;
};

function getInitialCellPreparationInfo(
    protocolData: ProtocolCellData | null,
): CellPreparationInfo {
    const blankInfo: CellPreparationInfo = {
        protocolHasSeedProcedure: false,
        cellPreparationStaticDetails: {
            flaskGroupDetails: [],
            seedVesselCellSuspensionMl: null,
        },
        coatingTimeMinutes: null,
    };

    if (!protocolData) {
        log.debug("No protocol; returning blank cell preparation info");
        return blankInfo;
    } else {
        const { initialCoatingTimeMinutes, initialSeedProcedureData } =
            protocolData;

        if (!initialSeedProcedureData) {
            // If null, there is no initial seed procedure
            return blankInfo;
        }

        return {
            protocolHasSeedProcedure: true,
            cellPreparationStaticDetails: {
                flaskGroupDetails: initialSeedProcedureData.flaskSeedGroups.map(
                    ({ flaskNumbers, ...others }) => ({
                        ...others,
                        flasks: removeNullables(flaskNumbers),
                    }),
                ),
                seedVesselCellSuspensionMl:
                    initialSeedProcedureData.seedVesselCellSuspensionMl,
            },
            coatingTimeMinutes: initialCoatingTimeMinutes,
        };
    }
}

export interface UseCellPreparationInfo {
    /**
     * Whether the protocol has a seed procedure (if not, none of the other
     * fields are relevant and should stay null)
     */
    protocolHasSeedProcedure: boolean;
    /**
     * The cell concentration in the seed vessel initially, if applicable (else null)
     */
    cellConcentration: number | null;
    /**
     * The cell count in the seed vessel initially, if applicable (else null)
     */
    seedVesselCellCount: number | null;
    /**
     * The coating time in minutes of the initial coating, if applicable (else null)
     */
    coatingTimeMinutes: number | null;
    /**
     * The cell concentration and count of each set of flasks seeded in the
     * initial seed if applicable (else an empty list)
     * Indexing corresponds to cellPreparationStaticDetails.flaskGroupDetails.
     */
    flaskCellDetails: FlaskCellDetails[];
    /**
     * Immutable information relevant to preparing cells, obtained from protocol object.
     */
    cellPreparationStaticDetails: CellPreparationStaticDetails;
    /**
     * Function to modify the cell concentration.
     *
     * Cascades effects of new concentration to other cell preparation parameters.
     */
    setCellConcentration: (newConcentration: number | null) => void;
    /**
     * Function to modify the cell count in the seed vessel.
     *
     * Cascades effects of new count to other cell preparation parameters.
     */
    setSeedVesselCellCount: (newConcentration: number | null) => void;
    /**
     * Function to modify the seed density of a particular set of flasks with
     * the specified index.
     *
     * Cascades effects of new density to other cell preparation parameters.
     */
    setSeedDensity: (
        flasksIndex: number,
        newSeedDensity: number | null,
    ) => void;
}

/**
 * Hook to use details about cell preparation, derived from the protocol object.
 * A user can modify either concentration or flask seed densities; these will
 * both affect one another when calling setCellConcentration/setSeedDensity.
 */
export function useCellPreparationInfo(
    protocolCellData: ProtocolCellData | null,
): UseCellPreparationInfo {
    const {
        cellPreparationStaticDetails,
        coatingTimeMinutes,
        protocolHasSeedProcedure,
    } = getInitialCellPreparationInfo(protocolCellData);

    const [cellConcentration, setCellConcentration] = useAtom<number | null>(
        cellConcentrationAtom,
    );

    const [seedVesselCellCount, setSeedVesselCellCount] = useState<
        number | null
    >(
        seedCellCountFromConcentration(
            cellConcentration,
            cellPreparationStaticDetails.seedVesselCellSuspensionMl,
        ),
    );

    // Don't need an atom for remaining state as it's derived from cellConcentration
    const [flaskCellDetails, setFlaskCellDetails] = useState<
        FlaskCellDetails[]
    >(
        flaskCellDetailsFromConcentration(
            cellConcentration,
            cellPreparationStaticDetails,
        ),
    );

    function setSeedVesselCellCountCascade(newValue: null | number) {
        const seedVolume =
            cellPreparationStaticDetails.seedVesselCellSuspensionMl;

        const newConcentration =
            !newValue || !seedVolume ? null : newValue / seedVolume;

        const flaskCellDetails = flaskCellDetailsFromConcentration(
            newConcentration,
            cellPreparationStaticDetails,
        );

        setFlaskCellDetails(flaskCellDetails);
        setCellConcentration(newConcentration);
        setSeedVesselCellCount(newValue);
    }

    function setCellConcentrationCascade(newValue: null | number) {
        const flaskCellDetails = flaskCellDetailsFromConcentration(
            newValue,
            cellPreparationStaticDetails,
        );

        const newCellCount = seedCellCountFromConcentration(
            newValue,
            cellPreparationStaticDetails.seedVesselCellSuspensionMl,
        );

        setFlaskCellDetails(flaskCellDetails);
        setCellConcentration(newValue);
        setSeedVesselCellCount(newCellCount);
    }

    function setSeedDensityCascade(
        flasksIndex: number,
        newValue: null | number,
    ) {
        const { cellDetails, cellConcentration } =
            flaskCellDetailsAndConcentrationFromSeedDensity(
                newValue,
                flasksIndex,
                cellPreparationStaticDetails,
            );

        const newCellCount = seedCellCountFromConcentration(
            cellConcentration,
            cellPreparationStaticDetails.seedVesselCellSuspensionMl,
        );

        setFlaskCellDetails(cellDetails);
        setCellConcentration(cellConcentration);
        setSeedVesselCellCount(newCellCount);
    }

    return {
        protocolHasSeedProcedure,
        cellPreparationStaticDetails,
        coatingTimeMinutes,
        cellConcentration,
        seedVesselCellCount,
        flaskCellDetails,
        setCellConcentration: setCellConcentrationCascade,
        setSeedDensity: setSeedDensityCascade,
        setSeedVesselCellCount: setSeedVesselCellCountCascade,
    };
}

export function useCellConcentration() {
    return { cellConcentration: useAtom(cellConcentrationAtom)[0] };
}
