import { styled } from "@mui/material";

import { NumberInput, NumberInputProps } from "components/common/NumberInput";
import { Select, SelectProps } from "components/common/Select";
import Txt from "components/common/Text";
import { TextInput, TextInputProps } from "components/common/TextInput";
import { OmitFrom } from "services/util-types";

type RowProps<V extends string, isMulti extends boolean> =
    | ({ type: "text" } & NamedTextInputRowProps)
    | ({ type: "number" } & NamedNumberInputRowProps)
    | ({ type: "select" } & NamedSelectInputRowProps<V, isMulti>);

interface NamedInputRowsProps<V extends string, isMulti extends boolean> {
    sectionName?: string;
    sectionDescription?: string;
    rows: RowProps<V, isMulti>[];
}

/**
 * Warning: The function signature has a known limitation in typing when used
 * with 2 or more select components of different types.
 */
export function NamedInputRows<V extends string, isMulti extends boolean>({
    sectionName,
    sectionDescription,
    rows,
}: NamedInputRowsProps<V, isMulti>) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                width: "100%",
            }}>
            {(sectionName || sectionDescription) && (
                <div>
                    {sectionName && (
                        <SubTitleTxt font="secondary" level={8} emphasis>
                            {sectionName}
                        </SubTitleTxt>
                    )}
                    {sectionDescription && (
                        <SectionDescriptionTxt
                            level={9}
                            style={{ marginBottom: 6 }}>
                            {sectionDescription}
                        </SectionDescriptionTxt>
                    )}
                </div>
            )}

            {rows.map((rowProps, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 8,
                        }}>
                        <Txt level={7}> {rowProps.name} </Txt>
                        <div style={{ width: 160 }}>
                            {rowProps.type === "number" ? (
                                <NumberInput fullWidth {...rowProps} />
                            ) : rowProps.type === "select" ? (
                                <Select {...rowProps} />
                            ) : (
                                <TextInput fullWidth {...rowProps} />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

interface NamedNumberInputRowProps
    extends OmitFrom<NumberInputProps, "helperText" | "fullWidth"> {
    name: string;
}

interface NamedTextInputRowProps
    extends OmitFrom<TextInputProps, "helperText" | "fullWidth"> {
    name: string;
}

interface NamedSelectInputRowProps<V extends string, isMulti extends boolean>
    extends SelectProps<V, isMulti> {
    name: string;
}

interface NamedNumericRowsProps {
    sectionName: string;
    rows: NamedNumericRowProps[];
}

export function NamedNumericRows({ sectionName, rows }: NamedNumericRowsProps) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                width: "100%",
            }}>
            <SubTitleTxt font="secondary" level={8} emphasis>
                {sectionName}
            </SubTitleTxt>
            {rows.map((props, index) => (
                <NamedNumericRow key={index} {...props} />
            ))}
        </div>
    );
}

interface NamedNumericRowProps {
    name: string;
    value: number | null;
    units?: string;
}

function NamedNumericRow({ name, value, units = "" }: NamedNumericRowProps) {
    const valueString = `${value ?? "N/A"} ${units}`.trimEnd();
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
            <Txt level={8}> {name} </Txt>
            <NumberTxt level={8}> {valueString} </NumberTxt>
        </div>
    );
}

const SubTitleTxt = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

const SectionDescriptionTxt = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

const NumberTxt = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
`;
