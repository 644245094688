import { Fragment, ReactElement, useState } from "react";

import { css } from "@emotion/react";
import { Collapse, Grid } from "@mui/material";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useParams } from "react-router-dom";

import Button from "components/common/Button";
import { useToasts } from "components/common/toasts/useToasts";

import { log as componentsLog } from "../..";
import { CodeText } from "../CodeText";
import { TextLabel } from "../TextLabel";

import { CarouselImageData } from "./Carousel";

const log = componentsLog.extend("MoreInfoPanel");

function getCleanedFilenameFromResourceUrl(src: string): string {
    const url = new URL(src);
    const pathParts = url.pathname.split("/");
    if (pathParts[0] === "") {
        pathParts.shift();
    }
    if (pathParts[0] === "resources") {
        const objectKey = url.searchParams.get("objectKey");
        if (objectKey) {
            return objectKey.replace(/\//g, "_");
        }
    }
    if (pathParts[0].startsWith("media.devices")) {
        pathParts.shift();
    }
    return pathParts.join("_");
}

export async function downloadMediaBlob(src: string): Promise<{
    blob: Blob;
    filename: string;
}> {
    const filename = getCleanedFilenameFromResourceUrl(src);
    // When the image is fetched using the img src attribute, the browser will
    // automatically cache the request as no-cors. This will prevent the
    // browser from downloading the image using fetch as the cached request
    // will be returned and fails the CORS check. To prevent this, we need to
    // fetch the image with the cache set to no-cache.
    const request = await fetch(src, { cache: "no-cache" });
    const blob = await request.blob();
    return { blob, filename };
}

export function MoreInfoPanel(props: {
    images: CarouselImageData[];
    imageIndex: number;
}): ReactElement {
    const { images, imageIndex } = props;
    const { resultId } = useParams<{ resultId?: string }>();
    const [moreInformation, setMoreInformation] = useState(false);
    const [downloadingAll, setDownloadingAll] = useState(false);
    const { toast } = useToasts();

    const currentImage = images[imageIndex];
    const src = currentImage.imageUrl ?? undefined;

    const downloadCurrentImage = async () => {
        if (src) {
            const { blob, filename } = await downloadMediaBlob(src);

            saveAs(blob, filename);
        }
    };

    const downloadAllHandler = async () => {
        setDownloadingAll(true);
        const toastId = toast.loading("Downloading all images");
        try {
            const zip = new JSZip();
            let zipName = "all-images";
            if (resultId) {
                zipName = `result-${resultId}-images`;
            }
            const folder = zip.folder(zipName);
            if (!folder) throw new Error("Unable to generate zip folder");

            const downloadImagePromises = images.map(async image => {
                if (image.imageUrl) {
                    const { blob, filename } = await downloadMediaBlob(
                        image.imageUrl,
                    );

                    folder.file(filename, blob);
                }
            });
            await Promise.all(downloadImagePromises);

            const zipBlob = await zip.generateAsync({ type: "blob" });

            toast.update(toastId, {
                render: "Downloading zipped images",
            });
            saveAs(zipBlob, `${zipName}.zip`);

            toast.update(toastId, {
                render: "Zipped images downloaded",
                type: "success",
                isLoading: false,
            });
        } catch (error) {
            const message = "There was a problem downloading all images";
            log.error(error, message);
            toast.update(toastId, {
                render: message,
                type: "error",
                isLoading: false,
            });
        }
        setDownloadingAll(false);
    };

    return (
        <div
            css={css`
                margin: 16px 8px;
            `}>
            <div
                css={css`
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;
                `}>
                <Button
                    variant="secondary"
                    iconRight={"download"}
                    onClick={downloadCurrentImage}
                    size="s">
                    Download
                </Button>
                <Button
                    variant="secondary"
                    iconRight={"download"}
                    onClick={downloadAllHandler}
                    disabled={downloadingAll}
                    size="s">
                    Download all
                </Button>
                <Button
                    variant="secondary"
                    iconRight={moreInformation ? "chevron-up" : "chevron-down"}
                    onClick={() => setMoreInformation(!moreInformation)}
                    size="s">
                    More Information
                </Button>
            </div>
            <Collapse
                style={{ marginTop: "16px" }}
                in={moreInformation}
                timeout="auto">
                <Grid container spacing={2}>
                    <AbsolutePositionInfo position={currentImage.position} />
                    <RelativePositionInfo position={currentImage.position} />
                </Grid>
            </Collapse>
        </div>
    );
}

function AbsolutePositionInfo(props: {
    position: CarouselImageData["position"];
}): ReactElement {
    const { position } = props;
    if (position?.absolutePosition) {
        const { x, y, z } = position.absolutePosition;

        return (
            <Grid item xs={12} sm={6}>
                <TextLabel label="Absolute position">
                    <CodeText text={formatXYZString(x, y, z)} />
                </TextLabel>
            </Grid>
        );
    }
    return <Fragment />;
}

function RelativePositionInfo(props: {
    position: CarouselImageData["position"];
}): ReactElement {
    const { position } = props;

    const positionName: string =
        position?.relativePosition?.positionName ?? "unknown";
    const itemId: string = position?.relativePosition?.itemId ?? "unknown";
    const x = position?.relativePosition?.relativeCoords?.x ?? null;
    const y = position?.relativePosition?.relativeCoords?.y ?? null;
    const z = position?.relativePosition?.relativeCoords?.z ?? null;

    return (
        <>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Relative position name">
                    <CodeText text={positionName} />
                </TextLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Relative Item ID">
                    <CodeText text={itemId} />
                </TextLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Relative position">
                    <CodeText text={formatXYZString(x, y, z)} />
                </TextLabel>
            </Grid>
        </>
    );
}

function formatXYZString(
    x: number | null,
    y: number | null,
    z: number | null,
): string {
    const round = (n: number) => String(Math.round(n * 100) / 100);
    const xs: string = "X " + (x === null ? "unknown" : round(x));
    const ys: string = "Y " + (y === null ? "unknown" : round(y));
    const zs: string = "Z " + (z === null ? "unknown" : round(z));
    const reportedPosition = [xs, ys, zs].join(", ");
    return reportedPosition;
}
