import React, { ReactElement, CSSProperties, useState } from "react";

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import { Theme, useTheme } from "@mui/material/styles";
import { saveAs } from "file-saver";
import { opacify, transparentize } from "polished";
import { Link } from "react-router-dom";

import Icon, { IconName } from "components/common/Icon";

export const buttonVariants = ["primary", "secondary", "tertiary"] as const;
export type ButtonVariant = (typeof buttonVariants)[number];

export type ButtonSize = "l" | "m" | "s";

export interface ButtonProps {
    /**
     * Text to be inserted into the button
     */
    children: string;
    /**
     * Variant (e.g. "primary") of the button
     */
    variant?: ButtonVariant;
    /**
     * Preset size of the button
     */
    size?: ButtonSize;
    /**
     * Disables the button from being pressed
     */
    disabled?: boolean;
    /**
     * Icon to be displayed on left side of button. If set to null, will
     * override and set to no icon.
     */
    iconLeft?: IconName | null;
    /**
     * Icon to be displayed on right side of button. If set to null, will
     * override and set to no icon.
     */
    iconRight?: IconName | null;
    /**
     * Optional quick link to a different path in the application. If this prop
     * is used then the base component will use a react-router-dom Link instead
     * of a button.
     */
    linkTo?: string;
    /**
     * If true, the link will open in a new tab. This prop is only used if
     * `linkTo` is also provided.
     */
    openLinkInNewTab?: boolean;
    /**
     * A manual colour override for Buttons. If a theme is provided, e.g.
     * "alert", the colour will be determined automatically.
     */
    colour?: "alert" | string;
    /**
     * A manual colour override for Tertiary buttons when hovered.
     */
    hoverColour?: string;
    /**
     * Click event callback function
     */
    onClick?: (event?: React.MouseEvent) => void; // TODO should automatically stop propagation?
    /**
     * URL of resource to download. This causes the underlying component type to
     * change to an anchor <a> tag. This prop is commonly paired with the
     * `downloadFilename` prop.
     *
     * Once the button is clicked, the data at the URL provided will be
     * downloaded as a file immediately.
     *
     * When using this prop, avoid other behavioural props such as `linkTo` or
     * `type`.
     */
    downloadUrl?: string;
    /**
     * The name of the file for the resource to download. This is paired with
     * the `downloadUrl` prop. The file that is downloaded will be saved with
     * the name provided.
     */
    downloadFilename?: string;
    /**
     * Title used in tooltip. The tooltip will not be displayed if the value is
     * a zero length string, null, or undefined.
     */
    tooltip?: string | null;
    /**
     * If defined, the button will be disabled (non-interactiable) for a period
     * of time before automatically becoming interactable. During this time, the
     * button displays a loading hydrating animation.
     *
     * Defaults `false`. Can either be a `number` (milliseconds of delay) or
     * `true` (use default delay time).
     */
    delayInteractive?: number | boolean;
    /**
     * Inline CSS passed directly to the button element. This is NOT recommended!
     */
    style?: CSSProperties;
    autoFocus?: boolean;
    type?: ButtonBaseProps["type"];
}

/**
 * _The_ Button component.
 * @param props - ButtonProps
 */
export default function Button({
    variant = "primary",
    size = "l",
    disabled = false,
    children,
    iconLeft = undefined,
    iconRight = undefined,
    linkTo,
    openLinkInNewTab,
    colour: colourOverride,
    hoverColour,
    downloadUrl,
    downloadFilename,
    onClick,
    tooltip,
    delayInteractive,
    ...restOfProps
}: ButtonProps): ReactElement {
    const applyIconRight: IconName | null | undefined =
        variant === "tertiary" && !iconLeft && iconRight === undefined
            ? "arrow-right"
            : iconRight;
    const theme = useTheme();
    const props = {
        variant,
        size,
        disabled,
        children,
        iconLeft,
        iconRight: applyIconRight,
        linkTo,
        colour: colourOverride,
        hoverColour,
        downloadUrl,
        downloadFilename,
        onClick,
        tooltip,
        delayInteractive,
        ...restOfProps,
    };
    const variantStyle = createVariantStyles(theme, props)[variant]();
    const sizeStyle = createSizeStyles(theme, props)[size]();

    const delayMs: number = disabled
        ? 0 // set to 0 if prop is already disabled
        : delayInteractive === true
          ? 3000 // default delay
          : delayInteractive
            ? delayInteractive // use value provided by prop
            : 0;

    const initialDelayState = delayMs ? true : false;
    const [delaying, setDelaying] = useState(initialDelayState);
    const delayStyles = createDelayAnimationStyle(
        theme,
        delayMs,
        delaying,
        props,
    );

    // store the previous state of the 'disabled' prop
    const [prevPropDisabled, setPrevPropDisabled] = useState(disabled);
    if (disabled !== prevPropDisabled) {
        // if disabled gets programmatically changed, we want to ensure the
        // delay interaction is reapplied
        setDelaying(initialDelayState);
        setPrevPropDisabled(disabled); // update the stored state from this render
    }

    return (
        <Tooltip title={tooltip ?? ""}>
            <span // this wrapper is needed since disabled buttons are problematic for tooltips
                style={{ display: "inline-flex", width: "auto" }}>
                <ButtonBase
                    disabled={delaying || disabled}
                    // disableRipple
                    css={[
                        { flexGrow: 1 },
                        variantStyle,
                        sizeStyle,
                        ...delayStyles,
                    ]}
                    onAnimationEnd={() => setDelaying(false)}
                    component={linkTo ? Link : "button"}
                    target={linkTo && openLinkInNewTab ? "_blank" : undefined}
                    to={linkTo}
                    onClick={(event: React.MouseEvent) => {
                        // if downloadUrl was specified we need to support this
                        // behaviour manually since the href prop no longer
                        // works since updating to react-router v6
                        if (downloadUrl) {
                            saveAs(downloadUrl, downloadFilename);
                        }
                        // we maintain the functionality expected from the
                        // parent which is to still execute any onClick logic as
                        // normal. this should be a no-op though as its bad
                        // practice for the parent to use downloadUrl and
                        // onClick on the same component call.
                        onClick?.(event);
                    }}
                    {...restOfProps}>
                    {iconLeft && (
                        <IconContainer position="left" size={size}>
                            <Icon name={iconLeft} />
                        </IconContainer>
                    )}
                    {children && <LabelSpan size={size}>{children}</LabelSpan>}
                    {applyIconRight && (
                        <IconContainer position="right" size={size}>
                            <Icon name={applyIconRight} />
                        </IconContainer>
                    )}
                </ButtonBase>
            </span>
        </Tooltip>
    );
}

function createVariantStyles(
    theme: Theme,
    {
        disabled,
        colour: colourOverride,
        hoverColour,
        iconLeft,
        iconRight,
    }: ButtonProps,
): { [V in ButtonVariant]: () => SerializedStyles } {
    return {
        primary: () => {
            const labelColour = "white";
            return css({
                "fontFamily": "Work Sans",
                "fontWeight": 600,
                "background": theme.colours.gradients.A,
                "color": labelColour,
                "width": "auto",
                "opacity": disabled ? 0.33 : 1,
                "zIndex": 0,
                "&:active": {
                    background: theme.colours.gradients.C,
                },
                // Gradient transition
                "&::before": {
                    borderRadius: "inherit",
                    backgroundImage: theme.colours.gradients.D,
                    content: '""',
                    display: "block",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    opacity: 0,
                    width: "100%",
                    zIndex: -100,
                    transition: "opacity 0.5s",
                },
                "&:hover": {
                    "&::before": {
                        opacity: 1,
                    },
                },
            });
        },
        secondary: () => {
            let background = "rgba(0, 0, 0, 0.03)";
            if (colourOverride === "alert") {
                colourOverride = theme.colours.accent.alertOrange;
                background = theme.colours.accent.alertOrange + "2e";
            }

            const colour = colourOverride ?? theme.colours.neutral[700];
            const borderColour = colourOverride ?? theme.colours.neutral[600];
            const hoverColour = colourOverride ?? theme.colours.neutral[800]; // TODO maybe use darken() instead for when colourOverride=inherit
            const hoverBorderColour =
                colourOverride ?? theme.colours.neutral[700]; // TODO maybe use darken() instead for when colourOverride=inherit
            const hasIcon = Boolean(iconLeft || iconRight);
            return css({
                "fontFamily": "Work Sans",
                "fontWeight": 600,
                "background": background,
                "color": colour,
                "border": "1px solid",
                "borderColor": borderColour,
                "width": "auto",
                "paddingLeft": hasIcon ? 16 : undefined,
                "paddingRight": hasIcon ? 16 : undefined,
                "opacity": disabled ? 0.33 : 1,
                "@media(hover: hover) and (pointer: fine)": {
                    "&:hover": {
                        background: opacify(0.1, background),
                        color: hoverColour,
                        borderColor: hoverBorderColour,
                    },
                },
                "&:focus": {
                    "color": colour,
                    "borderColor": borderColour,
                    "&:hover": {
                        color: hoverColour,
                        borderColor: hoverBorderColour,
                    },
                },
                "&:active": {
                    color: colour,
                },
            });
        },
        tertiary: () => {
            const appliedColour = colourOverride ?? theme.colours.neutral[900];
            const hover = hoverColour ?? theme.colours.brand.green;
            return css({
                "fontFamily": "Work Sans",
                "borderRadius": 0,
                "fontWeight": 600,
                "background": "none",
                "color": appliedColour,
                "padding": 0,
                "width": "auto",
                "opacity": disabled ? 0.33 : 1,
                // this media query applies only to mouse-based interaction
                "@media(hover: hover) and (pointer: fine)": {
                    "&:hover": {
                        color: hover,
                    },
                    "&:focus": {
                        "color": appliedColour,
                        "&:hover": {
                            color: hover,
                        },
                    },
                },
                // we otherwise assume we are using touch interaction
                "&:focus": {
                    color: appliedColour,
                },
                "&:active": {
                    color: appliedColour,
                },
            });
        },
    };
}

function createSizeStyles(
    theme: Theme,
    { variant, iconLeft, iconRight }: ButtonProps,
): { [V in ButtonSize]: () => SerializedStyles } {
    return {
        l: () => {
            const verticalPadding = variant === "secondary" ? "6px" : "7px";
            const horizontalPadding =
                variant === "tertiary"
                    ? "0px"
                    : iconLeft || iconRight
                      ? "16px"
                      : "24px";
            return css({
                fontSize: 16,
                borderRadius: 8,
                paddingTop: verticalPadding,
                paddingBottom: verticalPadding,
                paddingLeft: horizontalPadding,
                paddingRight: horizontalPadding,
                gap: 10,
            });
        },
        m: () => {
            const verticalPadding = variant === "secondary" ? "5px" : "6px";
            const horizontalPadding =
                variant === "tertiary"
                    ? "0px"
                    : iconLeft || iconRight
                      ? "12px"
                      : "16px";
            return css({
                fontSize: 14,
                borderRadius: 8,
                paddingTop: verticalPadding,
                paddingBottom: verticalPadding,
                paddingLeft: horizontalPadding,
                paddingRight: horizontalPadding,
                gap: 8,
            });
        },
        s: () => {
            const verticalPadding = variant === "secondary" ? "5px" : "6px";
            const horizontalPadding =
                variant === "tertiary"
                    ? "0px"
                    : iconLeft || iconRight
                      ? "10px"
                      : "14px";
            return css({
                fontSize: 12,
                borderRadius: 8,
                paddingTop: verticalPadding,
                paddingBottom: verticalPadding,
                paddingLeft: horizontalPadding,
                paddingRight: horizontalPadding,
                gap: 6,
            });
        },
    };
}

function createDelayAnimationStyle(
    theme: Theme,
    delayMs: number,
    delaying: boolean,
    { variant, colour }: ButtonProps,
): SerializedStyles[] {
    let backgroundColour = "#000";
    if (variant === "primary") backgroundColour = theme.colours.brand.greenDark;
    if (colour === "alert") backgroundColour = theme.colours.accent.alertOrange;
    return [
        delayMs
            ? css`
                  & {
                      // this is to clip the ::after pseudo element to
                      // within the bounds of the button component
                      overflow: hidden;
                  }
                  &::after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background-color: ${transparentize(
                          0.3,
                          backgroundColour,
                      )};
                      background-image: none;
                      // this is the start position, and animation will reset to this after completing
                      transform: translateX(-100%);
                      // animation configuration
                      animation-duration: ${delayMs}ms;
                      animation-timing-function: linear;
                      animation-delay: 0s;
                      animation-iteration-count: 1;
                      animation-direction: normal;
                      animation-fill-mode: none;
                      animation-play-state: running;
                      animation-name: loading;
                  }
                  @keyframes loading {
                      from {
                          transform: translateX(-100%);
                      }
                      to {
                          transform: translateX(0%);
                      }
                  }
              `
            : css``,
        delaying
            ? css`
                  & {
                      opacity: 0.4;
                  }
                  // in the case where we're delaying we want to
                  // override the default disabled styling
                  &.Mui-disabled {
                      pointer-events: auto;
                      cursor: not-allowed;
                  }
              `
            : css``,
    ];
}

const labelSizeStyles: { [S in ButtonSize]: React.CSSProperties } = {
    l: {
        height: 24,
        lineHeight: "1.5rem", // 24px
    },
    m: {
        height: 22,
        lineHeight: "1.375rem", // 22px
    },
    s: {
        height: 18,
        lineHeight: "1.125rem", // 18px
    },
};

const LabelSpan = styled.span<{ size: ButtonSize }>(props => {
    return {
        whiteSpace: "nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...labelSizeStyles[props.size],
    };
});

const IconContainer = styled.div<{
    size: ButtonSize;
    position: "left" | "right";
}>(({ size }) => {
    const fontSize = size === "s" ? 12 : size === "m" ? 16 : 20;
    return {
        display: "flex",
        fontSize,
        alignItems: "center",
        ...labelSizeStyles[size],
    };
});
