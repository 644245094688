import {
    WatchQueryFetchPolicy,
    gql,
    useMutation,
    useQuery,
} from "@apollo/client";
import { isPlainObject } from "lodash";

import { useUser } from "./useUser";

interface UserPreferences {
    /**
     * This is mainly aimed at internal users who want to be able to see the low
     * level details of what the device is up to at a glance.
     */
    scheduleDefaultExpandEverything: boolean;

    /**
     * This is for developers who want to the server to device sync status of
     * each procedure.
     */
    scheduleShowProcedureRefreshTimes?: boolean;
}

/**
 * Query for the current authenticated User. By design this only queries the
 * core attributes of the User and does not query many nested objects.
 */
const USER_UI_PREFERENCES = gql`
    query UserUiPreferences($userId: String!) {
        userUiPreferences(userId: $userId) {
            id
            userId
            values
        }
    }
`;

const UPDATE_USER_UI_PREFERENCES = gql`
    mutation UpdateUserUiPreferences($input: UpdateUserUiPreferencesInput!) {
        updateUserUiPreferences(input: $input) {
            ok
            userUiPreferences {
                id
                userId
                values
            }
        }
    }
`;

export function useUserUiPreferences({
    fetchPolicy = "cache-first",
}: {
    /**
     * Once at the root of the application we want to force this hook to fetch
     * the users preferences from the network. Everywhere else you don't need to
     * pass a value for this, it will just return the cached value.
     */
    fetchPolicy?: WatchQueryFetchPolicy;
} = {}) {
    const { user } = useUser();

    const { data } = useQuery(USER_UI_PREFERENCES, {
        variables: {
            userId: user?.id,
        },
        skip: !user,
        fetchPolicy,
    });

    const [updateUserUiPreferences] = useMutation(UPDATE_USER_UI_PREFERENCES);

    const setUiPreference = async <KeyT extends keyof UserPreferences>(
        key: KeyT,
        value: UserPreferences[KeyT],
    ) => {
        await updateUserUiPreferences({
            variables: {
                input: {
                    values: {
                        [key]: value,
                    },
                },
            },
        });
    };

    const uiPreferences = isPlainObject(data?.userUiPreferences?.values)
        ? (data.userUiPreferences.values as UserPreferences)
        : undefined;

    return {
        uiPreferences,
        setUiPreference,
    };
}
