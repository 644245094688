import { WatchQueryFetchPolicy, ApolloError, useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { AuthenticatedUserQuery } from "__generated__/apollo/graphql";
import { errorIsUnauthenticated } from "services/apollo/client";
import { authentication } from "services/auth";

import { log as parentLog } from "..";

const log = parentLog.extend("useUser");

/**
 * Query for the current authenticated User. By design this only queries the
 * core attributes of the User and does not query many nested objects.
 */
const AUTHENTICATED_USER_QUERY = gql(`
    query AuthenticatedUser {
        user {
            id
            email
            firstName
            lastName
            phoneNumber
        }
    }
`);

export type User = NonNullable<AuthenticatedUserQuery["user"]>;

export type UseUser = {
    /**
     * The loading state of the query to fetch the current authenticated User.
     */
    loading: boolean;
    /**
     * The current authenticated User data.
     */
    user: User | null;
    /**
     * Any Apollo Error object from the underlying query to fetch the current
     * User.
     */
    error: ApolloError | undefined;
    /**
     * Function refetches user data when called.
     */
    refetch: () => void;
    /**
     * Function when called will log out currently authenticated user from app.
     */
    logout: () => void;
    /**
     * Returns full name of User or null if no name set. Will return only first
     * name if last name is not available.
     */
    fullName: string | undefined;
    /**
     * Display name for the current logged in User. Will use the first name,
     * last name, and email in order to decide what can be displayed.
     */
    displayName: string | undefined;
    /**
     * Returns true if the current user is a Mytos user.
     */
    isMytosUser: boolean;
};

/**
 * This hook provides a range of core options for retrieving User data of the
 * currently authenticated user and associated state management tools.
 *
 * @param options `{ fetchPolicy }`
 * @returns `{ loading, user, error, refetch, logout, fullName, displayName }`
 */
export function useUser(
    options: {
        fetchPolicy?: WatchQueryFetchPolicy;
    } = {},
): UseUser {
    const { fetchPolicy = "cache-and-network" } = options;
    const { loading, error, data, refetch } = useQuery(
        AUTHENTICATED_USER_QUERY,
        {
            fetchPolicy: fetchPolicy,
        },
    );
    const user = data?.user ? data.user : null;
    if (user) {
        authentication.storeAuthenticatedUser({
            user: { id: user.id, email: user.email },
        });
    }

    if (error) {
        log.debug("Encountered an error for query current user", error);
        const shoudSignout = error.graphQLErrors.some(error =>
            errorIsUnauthenticated(error),
        );
        if (shoudSignout) {
            void authentication.signout();
        }
    }

    return {
        user,
        loading,
        refetch,
        error,
        logout: () => authentication.signout(),
        fullName: user ? getFullName(user) : undefined,
        displayName: user ? getDisplayName(user) : undefined,
        isMytosUser: user?.email.endsWith("@mytos.bio") ?? false,
    };
}

/**
 * Returns full name of User or null if no name set. Will return only first
 * name if last name is not available.
 */
function getFullName(user: User): string | undefined {
    // assembling the display name what to display
    let fullName: string | undefined;
    if (user.firstName && user.lastName) {
        fullName = user.firstName + " " + user.lastName;
    } else if (user.firstName) {
        fullName = user.firstName;
    }
    return fullName;
}

/**
 * Returns a display name for the current logged in User. Will use the first
 * name, last name, and email in order to decide what can be displayed.
 */
function getDisplayName(user: User): string {
    let displayName = "Unknown user";
    if (user.firstName && user.lastName) {
        displayName = user.firstName + " " + user.lastName;
    } else if (user.firstName) {
        displayName = user.firstName;
    } else if (user.email) {
        displayName = user.email;
    }
    return displayName;
}
