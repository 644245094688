import { ReactElement, ReactNode } from "react";

import { authentication } from "services/auth";

interface RequireAuthenticationProps {
    /**
     * Components to render if the user is authenticated
     */
    children: ReactNode;
    /**
     * Boolean override to disable the requirement for user to be authenticated
     */
    disabled?: boolean;
}

export function RequireAuthentication(
    props: RequireAuthenticationProps,
): ReactElement {
    const { children, disabled } = props;
    let renderChildren = authentication.isAuthenticated();
    if (disabled) renderChildren = true; // override and enable route rendering

    if (renderChildren) {
        return <>{children}</>;
    } else {
        authentication.redirectToSignin();
        return <></>;
    }
}
