import { ReactElement, useState } from "react";

import { Box, LinearProgress } from "@mui/material";
import { add } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";

import Button from "components/common/Button";
import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import Txt from "components/common/Text";
import { dayjs, formatTime } from "services/date";
import { clamp } from "services/utils";

import { LoadingPhrases } from "./LoadingPhrases";

export type WetTestState = {
    status: "READY" | "RUNNING" | "COMPLETE";
    estimatedDurationMinutes?: number;
    timeStarted?: Date;
    timeFinished?: Date;
};

export interface WetTestsActivityCardProps {
    wetTestState: WetTestState | null;
    /**
     * Callback to start the Wet Tests.
     * Should resolve once Wet Tests have started.
     */
    startCallback: () => Promise<void>;
    /**
     * Presence of an error message indicates a current issue with the wet
     * tests.
     */
    errorMessage?: string;
    loading: boolean;
}

export function WetTestsActivityCard(
    props: WetTestsActivityCardProps,
): ReactElement {
    const { wetTestState, errorMessage, loading, startCallback } = props;

    const [startingWetTests, setStartingWetTests] = useState(false);

    if (!wetTestState && loading) {
        return <Skeleton height="84px" />;
    }

    if (!wetTestState) {
        return (
            <Callout variant="error" title="Issue loading Wet Tests">
                {errorMessage ?? "No Wet Test data found."}
            </Callout>
        );
    }

    const startTimeMs = wetTestState.timeStarted?.getTime() ?? Infinity;
    const minutesRunning = (Date.now() - startTimeMs) / 60_000;
    const percentComplete = wetTestState.estimatedDurationMinutes
        ? minutesRunning / wetTestState.estimatedDurationMinutes
        : 0;

    const clampedPercentComplete = clamp(percentComplete, {
        min: 0,
        max: 1,
    });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: 3,
                gap: 3,
                border: "1px solid",
                borderColor: theme => theme.colours.neutral[300],
                borderRadius: 1,
                boxShadow:
                    "rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px",
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "8px",
                }}>
                <div>
                    <Txt
                        font="secondary"
                        level={8}
                        sx={{ color: theme => theme.colours.neutral[800] }}>
                        <strong>Wet Tests</strong>{" "}
                        {!errorMessage && timeRemainingText(wetTestState)}
                    </Txt>
                    <Txt
                        font="secondary"
                        level={9}
                        sx={{ color: theme => theme.colours.neutral[700] }}>
                        {errorMessage
                            ? "Failed"
                            : estimatedTimeCompletedText(wetTestState)}
                    </Txt>
                </div>
                <Button
                    size="m"
                    disabled={
                        wetTestState.status !== "READY" || startingWetTests
                    }
                    onClick={async () => {
                        setStartingWetTests(true);
                        await startCallback();
                        setStartingWetTests(false);
                    }}>
                    Start
                </Button>
            </div>
            <AnimatePresence>
                {wetTestState.status === "RUNNING" && (
                    <motion.div
                        key="progress-display"
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            gap: "8px",
                        }}>
                        <LinearProgress
                            variant="determinate"
                            value={
                                errorMessage
                                    ? 100
                                    : clampedPercentComplete * 100
                            }
                            sx={{
                                "borderRadius": 100,
                                "height": "10px",
                                "border": "1px solid",
                                "borderColor": theme =>
                                    errorMessage
                                        ? theme.colours.red[600]
                                        : theme.colours.brandGreen[400],
                                "backgroundColor": "transparent",
                                "& > span": {
                                    backgroundColor: theme =>
                                        errorMessage
                                            ? theme.colours.red[600]
                                            : theme.colours.brandGreen[400],
                                },
                            }}
                        />
                        {!errorMessage && <LoadingPhrases />}
                    </motion.div>
                )}
            </AnimatePresence>
            {errorMessage && <Callout variant="error">{errorMessage}</Callout>}
        </Box>
    );
}

function timeRemainingText({
    status,
    estimatedDurationMinutes,
    timeStarted,
}: Pick<WetTestState, "status" | "estimatedDurationMinutes" | "timeStarted">) {
    if (status === "READY") {
        return estimatedDurationMinutes
            ? `are ready — will take about ${estimatedDurationMinutes.toFixed()} minutes`
            : "are ready to start";
    }

    if (status === "RUNNING") {
        if (!estimatedDurationMinutes || !timeStarted) {
            return "are running";
        }

        const startTimeMs = timeStarted.getTime();

        const minutesRunning = Math.floor((Date.now() - startTimeMs) / 60_000);
        const minutesRemainingRunning = Math.max(
            1, // Never show less than 1 minute remaining
            estimatedDurationMinutes - minutesRunning,
        );
        const durationToUse = dayjs.duration({
            minutes: minutesRemainingRunning,
        });

        return `have about ${durationToUse.humanize()} remaining`;
    }

    if (status === "COMPLETE") {
        return "are done";
    }

    return "";
}

function estimatedTimeCompletedText({
    status,
    estimatedDurationMinutes,
    timeStarted,
    timeFinished,
}: Pick<
    WetTestState,
    "status" | "estimatedDurationMinutes" | "timeStarted" | "timeFinished"
>) {
    const timeFormatter = (date: Date) =>
        formatTime(date.toISOString(), { withSeconds: false });

    if (status === "READY") {
        if (!estimatedDurationMinutes) {
            return "Estimating timings...";
        }
        const readyETA = add(new Date(), { minutes: estimatedDurationMinutes });
        return `Would finish at ${timeFormatter(readyETA)}`;
    }

    if (status === "RUNNING") {
        if (!timeStarted) {
            return "Started";
        }

        const estimatedFinishTime = add(timeStarted, {
            minutes: estimatedDurationMinutes,
        });

        return `Started at ${timeFormatter(timeStarted)} — Finish at ${timeFormatter(estimatedFinishTime)}`;
    }

    if (status === "COMPLETE") {
        return timeFinished
            ? `Finished at ${timeFormatter(timeFinished)}`
            : "Finished";
    }

    return "Status unknown";
}
