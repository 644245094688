import React, { ReactElement, useEffect, useState } from "react";

import Callout from "components/common/Callout";
import { DatePicker } from "components/common/DatePicker";
import { DateTimeInput } from "components/common/DateTimeInput";
import { TextLabel } from "components/common/TextLabel";
import { TimeInput } from "components/common/TimeInput";
import { Dayjs, dayjs, isToday } from "services/date";
import { useIsMobile } from "services/hooks/window";

interface ManualRescheduleContentProps {
    timePlanned: string | null;
    desiredDate: Dayjs | undefined;
    setDesiredDate: React.Dispatch<React.SetStateAction<Dayjs | undefined>>;
    setScheduleViewConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ManualRescheduleContent(
    props: ManualRescheduleContentProps,
): ReactElement {
    const { timePlanned, desiredDate, setDesiredDate, setScheduleViewConfirm } =
        props;
    const isMobile = useIsMobile();
    const [dateFilled, setDateFilled] = useState(desiredDate ? true : false);
    const [timeFilled, setTimeFilled] = useState(desiredDate ? true : false);
    const [dateTimeFilled, setDateTimeFilled] = useState(
        desiredDate ? true : false,
    );
    const [timeError, setTimeError] = useState(false);

    const isTodaySelected = desiredDate
        ? isToday(desiredDate.toString())
        : false;
    const currentTime = dayjs(new Date()).format("HH:mm");

    useEffect(() => {
        if (!isMobile && dateFilled && timeFilled) {
            setScheduleViewConfirm(true);
        } else if (isMobile && dateTimeFilled) {
            setScheduleViewConfirm(true);
        } else {
            setScheduleViewConfirm(false);
        }
    }, [
        dateFilled,
        timeFilled,
        dateTimeFilled,
        isMobile,
        setScheduleViewConfirm,
    ]);

    const onChangeDatePicker = (newDate: Date) => {
        if (!newDate) {
            setDateFilled(false);
        } else {
            // Checks if new date ends up setting desiredDate in the past
            if (
                isToday(newDate.toString()) &&
                dayjs(desiredDate).format("HH:mm") < currentTime
            ) {
                setTimeError(true);
                setScheduleViewConfirm(false);
            } else {
                setTimeError(false);
                setScheduleViewConfirm(true);
            }
            const selectedDate = dayjs(newDate).date();
            const month = dayjs(newDate).month();
            const year = dayjs(newDate).year();
            setDesiredDate(date =>
                dayjs(date)
                    .date(selectedDate)
                    .month(month)
                    .year(year)
                    .hour(date?.hour() || 0) //Preserve time data if it exists
                    .minute(date?.minute() || 0),
            );
            setDateFilled(true);
        }
    };

    const onChangeTimePicker = (newTime: string) => {
        if (!newTime) {
            setTimeFilled(false);
        } else {
            // Checks if new time is in the past
            if (isTodaySelected && newTime < currentTime) {
                setTimeError(true);
                setScheduleViewConfirm(false);
                return;
            } else {
                setTimeError(false);
                setScheduleViewConfirm(true);
            }
            const hour = Number(newTime.split(":")[0]);
            const minute = Number(newTime.split(":")[1]);
            setDesiredDate(date => dayjs(date).hour(hour).minute(minute));
            setTimeFilled(true);
        }
    };

    const onChangeDateTimeInput = (newValue: Dayjs | undefined) => {
        setDesiredDate(newValue);
        setDateTimeFilled(!!newValue);
    };

    if (timePlanned === null) {
        return <Callout variant="error">Time planned is not defined</Callout>;
    }
    return isMobile ? (
        <TextLabel label="Set date & time">
            <DateTimeInput
                defaultValue={desiredDate}
                onChange={onChangeDateTimeInput}
            />
        </TextLabel>
    ) : (
        <div style={{ display: "flex", gap: 16 }}>
            <TextLabel label="Select date">
                <DatePicker
                    defaultValue={desiredDate?.toDate()}
                    onChange={onChangeDatePicker}
                    disabled={{
                        before: new Date(),
                    }}
                />
            </TextLabel>
            <div>
                <TextLabel label="Select time">
                    <TimeInput
                        defaultValue={desiredDate?.format("HH:mm")}
                        onChange={onChangeTimePicker}
                        error={timeError}
                        helperText={
                            timeError
                                ? "Please select a time in the future"
                                : ""
                        }
                    />
                </TextLabel>
            </div>
        </div>
    );
}
