import { WatchQueryFetchPolicy, useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";

export const DEVICE_CULTURE_STATE_QUERY = gql(`
    query DeviceCultureState($deviceId: String!) {
        device(id: $deviceId) {
            id
            name
            culture {
                id
                state
            }
        }
    }
`);

export const useDeviceCultureState = (
    deviceId: string,
    opts: { fetchPolicy?: WatchQueryFetchPolicy | undefined } = {},
) =>
    useQuery(DEVICE_CULTURE_STATE_QUERY, {
        variables: { deviceId },
        fetchPolicy: opts.fetchPolicy,
    });

export const CULTURE_STATE_QUERY = gql(`
    query CultureState($cultureId: String!) {
        culture(id: $cultureId) {
            id
            state
        }
    }
`);

export const useCultureState = (cultureId: string) =>
    useQuery(CULTURE_STATE_QUERY, {
        variables: { cultureId },
        fetchPolicy: "cache-first",
    });
