import { ReactElement, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { isError } from "lodash";

import { Protocol } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Callout from "components/common/Callout";
import { IconButton } from "components/common/IconButton";
import { Switch } from "components/common/Switch";
import Txt from "components/common/Text";
import { TextArea } from "components/common/TextArea";
import { useToasts } from "components/common/toasts/useToasts";
import {
    Dropzone,
    FileImportHandler,
    FileInfo,
} from "components/pages/Device/Culture/CreateCulture/Dropzone";
import { formatDatetime } from "services/date";

import { log as parentLog } from "../../log";

import {
    fileToStoredFileData,
    StoredFileData,
    useRecentFiles,
} from "./useRecentFiles";

export const log = parentLog.extend("ImportJson");

export function parseProtocolString(protocolString: string): Protocol | Error {
    try {
        return JSON.parse(protocolString) as Protocol;
    } catch (e) {
        log.error("Issue parsing protocol JSON", e);
        return isError(e) ? e : new Error(`Issue parsing protocol JSON: ${e}`);
    }
}

export function ImportJson({
    onProtocolChange,
    selectedFile,
    onSelectFile,
    onClearProtocol,
}: {
    onProtocolChange: (protocol: unknown | null) => void;
    selectedFile?: StoredFileData | null;
    onSelectFile: (file: StoredFileData | null) => void;
    onClearProtocol?: () => void;
}): ReactElement {
    const [textMode, setTextMode] = useState(false);
    const { pushFile } = useRecentFiles();
    const { toast } = useToasts();

    const [protocolJsonString, setProtocolJsonString] = useState<null | string>(
        null,
    );

    const handleFileImport: FileImportHandler = data => {
        const { content } = data;
        if (content === "") {
            toast.error("The provided JSON file is empty");
            return;
        }

        setProtocolJsonString(content);
        onSelectFile(fileToStoredFileData(data));
        pushFile(data);
    };

    const protocolObject = useMemo(() => {
        if (!protocolJsonString) return null;
        const parsed = parseProtocolString(protocolJsonString);

        if (!isError(parsed)) {
            onProtocolChange(parsed);
        }

        return parsed;

        // We explicitly exclude onProtocolChange from the dependencies to
        // reduce the risk of infinite loops; that would happen if the function
        // was not properly memoised. We'll get the latest version of
        // onProtocolChange each time this hook runs, which is all we need
        // anyway.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [protocolJsonString]);

    const clearProtocol = () => {
        setProtocolJsonString(null);
        onProtocolChange(null);
        onSelectFile(null);
        onClearProtocol?.();
    };

    return (
        <Box
            sx={{
                color: theme => theme.colours.neutral[600],
                display: "flex",
                flexDirection: "column",
            }}>
            <div
                id="import-json-heading"
                style={{ display: "flex", alignItems: "center" }}>
                <Txt font="secondary" level={8} emphasis sx={{ flexGrow: 1 }}>
                    Protocol
                </Txt>
                <Txt font="secondary" level={8}>
                    View JSON
                </Txt>
                <Switch
                    id="toggle-text-mode"
                    size="sm"
                    checked={textMode}
                    onChange={setTextMode}
                />
            </div>

            <div id="import-json-zone" style={{ height: 100, marginTop: 8 }}>
                {textMode ? (
                    <TextArea
                        value={protocolJsonString ?? ""}
                        onChange={setProtocolJsonString}
                        placeholder="Paste your protocol JSON here..."
                        valid={!isError(protocolObject)}
                        invalidPrompt=""
                        // No need for invalid prompt as error dialogue is shared with DropZone component
                    />
                ) : (
                    <Dropzone
                        onFileImport={handleFileImport}
                        renderElementInZone={open =>
                            selectedFile ? (
                                <motion.div
                                    initial={{ scale: 0.95 }}
                                    animate={{ scale: 1 }}
                                    transition={{ duration: 0.05 }}>
                                    <FileCard
                                        fileInfo={selectedFile.filedata}
                                        clearProtocol={clearProtocol}
                                    />
                                </motion.div>
                            ) : (
                                <Box
                                    sx={{
                                        border: "1px dashed",
                                        borderColor: theme =>
                                            theme.colours.neutral[300],
                                        borderRadius: 1,
                                        padding: 2,
                                        display: "flex",
                                        width: "100%",
                                        height: "100%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 2,
                                    }}>
                                    <Txt level={8} style={{ minWidth: 150 }}>
                                        Drop your <strong>.json</strong> file,
                                        or
                                    </Txt>
                                    <Button
                                        onClick={open}
                                        variant="tertiary"
                                        iconLeft="notes-paper-text"
                                        colour="inherit"
                                        size="s">
                                        Browse for file
                                    </Button>
                                </Box>
                            )
                        }
                    />
                )}
            </div>

            {isError(protocolObject) && (
                <Callout variant="error">
                    {
                        "Protocol file has invalid format. Please ensure your protocol uses the latest format version."
                    }
                </Callout>
            )}
        </Box>
    );
}

function FileCard(props: {
    fileInfo: FileInfo;
    clearProtocol: () => void;
}): ReactElement {
    const { fileInfo, clearProtocol } = props;
    return (
        <Box
            sx={{
                border: "1px solid",
                borderColor: theme => theme.colours.neutral[300],
                color: theme => theme.colours.neutral[900],
                borderRadius: 1,
                padding: 4,
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
                boxShadow: "0px 5px 15px 0px #00000012",
            }}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                <Txt level={8} style={{ minWidth: 150 }}>
                    {fileInfo.filename}
                </Txt>
                <Box
                    sx={{
                        color: theme => theme.colours.neutral[600],
                    }}>
                    <Txt display="inline" level={8}>
                        {Math.round(fileInfo.size / 1000)} KB
                    </Txt>
                    <Txt display="inline" level={8} style={{ paddingLeft: 16 }}>
                        {formatDatetime(fileInfo.lastModified)}
                    </Txt>
                </Box>
            </Box>
            <IconButton
                onClick={clearProtocol}
                icon="cross"
                // colour="inherit"
                size="sm"
            />
        </Box>
    );
}
