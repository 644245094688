import Callout from "components/common/Callout";

export function SimulationError({
    errorMessage,
}: {
    errorMessage?: string | null;
}) {
    return (
        <Callout variant="error" title="Simulation error">
            The following error was encountered whilst simulating the culture,
            this is likely due to recent change made to the culture. If you are
            unsure how to address this please contact Mytos support.
            <br />
            <br />
            {errorMessage}
        </Callout>
    );
}
