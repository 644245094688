import { ApolloError, gql, useQuery } from "@apollo/client";

import { CultureContainersQuery } from "__generated__/apollo/graphql";

export const CULTURE_CONTAINERS_QUERY = gql(`
    query CultureContainers($cultureId: String!) {
        culture(id: $cultureId) {
            id
            isActive
            device {
                id
            }
            bottles {
                nodes {
                    id
                    name
                    containerReference
                    liquidVolumeMl
                    totalVolumeMl
                }
            }
            flasks {
                nodes {
                    id
                    name
                    liquidVolumeMl
                    totalVolumeMl
                    trayNumber
                    flaskNumber
                    imageable
                }
            }
        }
    }
`);

type Culture = NonNullable<CultureContainersQuery["culture"]>;
export type CultureBottle = NonNullable<
    NonNullable<NonNullable<Culture["bottles"]>["nodes"]>[number]
>;
export type CultureFlask = NonNullable<
    NonNullable<NonNullable<Culture["flasks"]>["nodes"]>[number]
>;

export interface CultureContainersDetails {
    cultureLoaded: boolean;
    deviceId: string | null;
    isActive: boolean;
    bottles: CultureBottle[];
    flasks: CultureFlask[];
    loading: boolean;
    error?: ApolloError;
}

export function useCultureContainers(
    cultureId?: string,
): CultureContainersDetails {
    const { data, loading, error } = useQuery(CULTURE_CONTAINERS_QUERY, {
        skip: !cultureId,
        variables: { cultureId },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    return {
        isActive: data?.culture?.isActive ?? false,
        deviceId: data?.culture?.device?.id ?? null,
        cultureLoaded: !!cultureId,
        bottles: data?.culture?.bottles?.nodes ?? null,
        flasks: data?.culture?.flasks?.nodes ?? null,
        loading,
        error,
    };
}
