import { ReactElement } from "react";

import styled from "@emotion/styled";
import { Divider } from "@mui/material";

import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import Txt from "components/common/Text";
import { useCultureProcedureSteps } from "services/hooks/useCultureProcedureSteps";

import { StepItem } from "../Steps/StepItem";

import { groupStepsByStageName } from "./ProcedureUtils";

export interface ProcedureStepsProps {
    deviceId: string;
    cultureId: string;
    procedureId: string;
    numSteps: number;
    nextStepId?: string;
}

export function ProcedureSteps(props: ProcedureStepsProps): ReactElement {
    const { deviceId, cultureId, procedureId, numSteps } = props;
    const { loading, data } = useCultureProcedureSteps(procedureId, 5_000);
    const steps = data?.procedure?.steps?.nodes;

    const groupedSteps = steps ? groupStepsByStageName(steps) : undefined;

    let stepItems: ReactElement | ReactElement[] = (
        <Callout message="This step does not have any operations" />
    );

    if (groupedSteps) {
        stepItems = groupedSteps.map((group, i) => {
            const isNotFirst = i > 0;
            return (
                <div key={i}>
                    {isNotFirst && (
                        <Divider
                            sx={{
                                marginBottom: group.stageName ? "8px" : "16px",
                            }}
                        />
                    )}
                    <StageGroup>
                        {group.stageName && (
                            <StageName>
                                <Txt level={8} emphasis>
                                    {group.stageName}
                                </Txt>
                            </StageName>
                        )}
                        <StageSteps>
                            {group.steps.map(step => (
                                <StepItem
                                    key={step.id}
                                    step={step}
                                    deviceId={deviceId}
                                    cultureId={cultureId}
                                    procedureState={data?.procedure?.state}
                                    isNextStep={props.nextStepId === step.id}
                                />
                            ))}
                        </StageSteps>
                    </StageGroup>
                </div>
            );
        });
    }

    if (!data && loading) {
        stepItems = Array(numSteps)
            .fill(0)
            .map((_, i) => (
                <Skeleton key={i} height={66} sx={{ marginBottom: 2 }} />
            ));
    }

    return <StepList onClick={e => e.stopPropagation()}>{stepItems}</StepList>;
}

const StageName = styled.div`
    color: ${props => props.theme.colours.neutral[500]};
    margin-bottom: 8px;
`;

const StageGroup = styled.div`
    margin-bottom: 16px;
`;

const StageSteps = styled.div`
    gap: 8px;
    display: flex;
    flex-direction: column;
`;

const StepList = styled.div`
    margin-top: 24px;
    & > * {
        margin-top: 4px;
    }
`;
