import { ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import copy from "copy-to-clipboard";
import { filter } from "lodash";

import { gql } from "__generated__/apollo";
import {
    CultureState,
    StepControl,
    StepState,
} from "__generated__/apollo/graphql";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { IconName } from "components/common/Icon";
import { MoreButton, MoreButtonOptions } from "components/common/MoreButton";
import { RescheduleModal } from "components/common/RescheduleModal/RescheduleModal";
import { useToasts } from "components/common/toasts/useToasts";
import { dayjs } from "services/date";
import { usePauseCulture } from "services/hooks/useCultureControlLegacy";
import {
    CULTURE_SCHEDULE_LEGACY_QUERY,
    LegacyStep,
} from "services/hooks/useCultureScheduleLegacy";
import { useDeviceCultureState } from "services/hooks/useCultureState";

import { log as parentLog } from "../log";
import { useScheduleIsViewOnly } from "../schedule-data";

import { useRescheduleStep } from "./RescheduleStep";
import { UpdateStepRuntimeReferenceModal } from "./UpdateStepRuntimeReferenceModal";

export const log = parentLog.extend("StepControls");

const CONTROLS: {
    [key in Exclude<
        StepControl,
        StepControl.Confirm | StepControl.Unconfirm | StepControl.Pause
    >]: {
        control: key;
        /** Button label for control */
        label: string;
        /** Step states where the control can apply */
        states: (StepState | null)[];
        icon?: IconName;
        /** Gerund form of control taking place (sentence case) */
        gerund: string;
    };
} = {
    REMOVE: {
        control: StepControl.Remove,
        label: "Skip",
        gerund: "Skipping",
        states: [StepState.Planned],
        icon: "skip",
    },
    RESTORE: {
        control: StepControl.Restore,
        label: "Restore",
        gerund: "Restoring",
        states: [StepState.Removed],
        icon: "sync-arrows",
    },
    CANCEL: {
        control: StepControl.Cancel,
        label: "Cancel",
        gerund: "Cancelling",
        states: [StepState.Running],
        icon: "disable",
    },
};

/** States where step duplication is permitted */
const DUPLICATE_STEP_STATES: (StepState | null)[] = [
    StepState.Failed,
    StepState.Ignored,
    StepState.Complete,
];

const EDIT_STEP_STATES: (StepState | null)[] = [StepState.Planned];

export interface StepControlsProps {
    deviceId: string;
    step: LegacyStep;
}

export function StepControls(props: StepControlsProps): ReactElement {
    const { deviceId, step } = props;
    const scheduleViewOnly = useScheduleIsViewOnly();
    const [requesting, setRequesting] = useState(false);
    const { toast } = useToasts();
    const { delayStep } = useRescheduleStep();
    const [controlStep] = useMutation(CONTROL_STEP_MUTATION);
    const [duplicateStep] = useMutation(DUPLICATE_STEP_MUTATION);
    const visibleControls = filter(CONTROLS, control =>
        control.states.includes(step.state),
    );

    const controlOptions: MoreButtonOptions = visibleControls.map(control => ({
        label: control.label,
        icon: control.icon,
        disabled: requesting,
        onClick: async () => {
            log.debug(control.label, step.id);
            const toastId = toast.loading(control.gerund + " step");
            setRequesting(true);
            const res = await controlStep({
                variables: {
                    input: {
                        control: control.control,
                        deviceId,
                        stepId: step.id,
                    },
                },
            });
            setRequesting(false);
            if (res.data?.controlStep.ok) {
                toast.update(toastId, {
                    type: "success",
                    render: control.label + " successful",
                    isLoading: false,
                    autoClose: 3_000,
                });
            } else {
                toast.update(toastId, {
                    type: "error",
                    render: control.label + " failed",
                    isLoading: false,
                    autoClose: 6_000,
                });
            }
        },
    }));

    // if duplications is allowed, add an option
    const allowDuplication = DUPLICATE_STEP_STATES.includes(step.state);
    if (allowDuplication && !scheduleViewOnly) {
        // add a divider, if there are already control options in the list
        if (controlOptions.length) controlOptions.push("divider");
        controlOptions.push({
            label: "Duplicate step",
            icon: "copy",
            onClick: async () => {
                log.debug("Duplicate step", step.id);
                const toastId = toast.loading("Duplicating step");
                const res = await duplicateStep({
                    variables: {
                        input: {
                            deviceId,
                            stepId: step.id,
                        },
                    },
                    refetchQueries: [CULTURE_SCHEDULE_LEGACY_QUERY],
                });
                if (res.data?.duplicateStep.ok) {
                    toast.update(toastId, {
                        type: "success",
                        render: "Step duplicated successfully",
                        isLoading: false,
                        autoClose: 3_000,
                    });
                } else {
                    toast.update(toastId, {
                        type: "error",
                        render: "Unable to duplicate step",
                        isLoading: false,
                        autoClose: 6_000,
                    });
                }
            },
        });
    }

    // if editing is allowed, add an option
    const allowReschedule = EDIT_STEP_STATES.includes(step.state);
    const [rescheduleStepModalOpen, setRescheduleStepModalOpen] =
        useState(false);
    const [suggestPauseOpen, setSuggestPauseOpen] = useState(false);
    const { data } = useDeviceCultureState(deviceId);
    const state = data?.device?.culture?.state;
    const shouldPause = state === CultureState.Running;
    const pauseCulture = usePauseCulture(deviceId);

    if (allowReschedule && !scheduleViewOnly) {
        // add a divider, if there are already control options in the list
        if (controlOptions.length) controlOptions.push("divider");
        controlOptions.push({
            icon: "calendar",
            label: "Reschedule",
            onClick: () => {
                if (shouldPause) {
                    setSuggestPauseOpen(true);
                } else {
                    setRescheduleStepModalOpen(true);
                }
            },
        });
    }

    // Update runtime reference
    const allowUpdateStepRuntimeReference =
        EDIT_STEP_STATES.includes(step.state) &&
        !!step.runtimeReference?.referenceStep;
    const [
        updateStepRuntimeReferenceModalOpen,
        setUpdateStepRuntimeReferenceModalOpen,
    ] = useState(false);
    if (allowUpdateStepRuntimeReference && !scheduleViewOnly) {
        controlOptions.push({
            icon: "hourglass",
            label: "Update runtime",
            onClick: () => {
                setUpdateStepRuntimeReferenceModalOpen(true);
            },
        });
    }

    controlOptions.push({
        icon: "clipboard",
        label: "Copy ID",
        onClick: () => copy(step.id),
    });

    return (
        <div
            onClick={e => e.stopPropagation()}
            css={css`
                display: flex;
                align-items: center;
                column-gap: 8px;
            `}>
            <MoreButton size="sm" options={controlOptions} />
            {allowReschedule && (
                <>
                    <ConfirmationDialog
                        variant="advised"
                        delayPrimaryAction
                        open={suggestPauseOpen}
                        primaryButtonDisabled={false}
                        onPrimaryAction={async () => {
                            await pauseCulture();
                            setRescheduleStepModalOpen(true);
                            setSuggestPauseOpen(false);
                        }}
                        onSecondaryAction={() => {
                            setRescheduleStepModalOpen(true);
                            setSuggestPauseOpen(false);
                        }}
                        onDismiss={() => {
                            setSuggestPauseOpen(false);
                        }}
                        title={"Culture is running"}
                        body={
                            "It's recommended you pause the culture before making changes to the schedule, as it will be easier to make further adjustments if needed."
                        }
                        primaryButtonText="Pause culture"
                        secondaryButtonText="Skip"
                    />
                    <RescheduleModal
                        type="step"
                        name={step.name}
                        initialTimePlanned={step.timePlanned}
                        modalOpen={rescheduleStepModalOpen}
                        setModalOpen={setRescheduleStepModalOpen}
                        onSubmit={async ({ targetDate }) => {
                            const delay = Number(
                                dayjs(targetDate).diff(
                                    step.timePlanned,
                                    "millisecond",
                                ),
                            );
                            await delayStep({
                                delay,
                                deviceId,
                                stepId: step.id,
                            });
                        }}
                    />
                </>
            )}
            {allowUpdateStepRuntimeReference && (
                <UpdateStepRuntimeReferenceModal
                    step={step}
                    deviceId={deviceId}
                    modalOpen={updateStepRuntimeReferenceModalOpen}
                    setModalOpen={setUpdateStepRuntimeReferenceModalOpen}
                />
            )}
        </div>
    );
}

const CONTROL_STEP_MUTATION = gql(`
    mutation ControlStepMutationLegacy($input: ControlStepInput!) {
        controlStep(input: $input) {
            ok
            message
            step {
                id
                name
                state
            }
        }
    }
`);

const DUPLICATE_STEP_MUTATION = gql(`
    mutation DuplicateStepMutationLegacy($input: DuplicateStepInput!) {
        duplicateStep(input: $input) {
            ok
            message
        }
    }
`);
