import { Fragment, ReactElement, RefObject } from "react";

import { useTheme } from "@mui/material/styles";
import { useResizeDetector } from "react-resize-detector";

import { log as componentsLog } from "..";

import Txt from "./Text";

const log = componentsLog.extend("ImageScaleBar");

export interface ImageScaleBarProps {
    /**
     * Width (in pixels) of source image
     */
    imagePixelWidth: number | null;
    /**
     * Size of the scale bar, in desired 'microns'
     */
    displayMicrons: number;
    /**
     * Conversion ratio of microns/pixel as defined by the image
     */
    pixelScale: number | null;
    /**
     * Line thickness of bar in pixels
     */
    lineThicknessPx?: number;
    /**
     * Tick height from bottom line in pixels
     */
    tickHeightPx?: number;
    /**
     * Reference to the media element used to calculate the display width
     */
    mediaRef: RefObject<HTMLElement>;
    /**
     * Default width to display the scale bar
     */
    displayDefaultWidth: number;
}

export function ImageScaleBar({
    displayDefaultWidth,
    mediaRef,
    pixelScale,
    imagePixelWidth,
    displayMicrons,
    lineThicknessPx = 1,
    tickHeightPx = 4,
}: ImageScaleBarProps): ReactElement {
    const resizeDetectorProps = mediaRef ? { targetRef: mediaRef } : {};
    const { width: targetWidth } = useResizeDetector(resizeDetectorProps);
    const displayWidth = targetWidth ? targetWidth : displayDefaultWidth;

    const theme = useTheme();
    if (pixelScale === null || imagePixelWidth === null) {
        return <Fragment />;
    }
    const scaleBarWidth = Math.round(
        (displayMicrons / pixelScale) * (displayWidth / imagePixelWidth),
    );
    const scaleBarText = `${displayMicrons}µm`;
    log.debug("Calculated scale", {
        displayMicrons,
        displayWidth,
        pixelScale,
        imagePixelWidth,
        scaleBarWidth,
    });
    return (
        <>
            <div
                id="ImageScaleBar-bar"
                style={{
                    width: `${scaleBarWidth}px`,
                    position: "relative",
                }}>
                <div
                    id="scale-bar-bottom-line"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        height: lineThicknessPx,
                        width: `${scaleBarWidth}px`,
                        backgroundColor: "white",
                    }}
                />
                <div
                    id="scale-bar-left-tick"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        height: tickHeightPx,
                        width: lineThicknessPx,
                        backgroundColor: "white",
                    }}
                />
                <div
                    id="scale-bar-right-tick"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        height: tickHeightPx,
                        width: lineThicknessPx,
                        backgroundColor: "white",
                    }}
                />
            </div>
            <Txt
                font="secondary"
                level={9}
                style={{ color: theme.colours.neutral.white }}>
                {scaleBarText}
            </Txt>
        </>
    );
}
