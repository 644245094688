import { isNull } from "lodash";

import { dayjs } from "services/date";

type Details = {
    timePlanned: string | null;
    estimatedDuration: number | null;
};

export function procedureCollidesWithPrevious(props: {
    current: Details | null;
    previous: Details | null;
}) {
    const { current, previous } = props;
    if (isNull(current) || isNull(previous)) return false;
    if (isNull(current.timePlanned) || isNull(previous.timePlanned))
        return false;
    const estimatedEndTime = previous.estimatedDuration
        ? dayjs(previous.timePlanned).add(
              previous.estimatedDuration,
              "milliseconds",
          )
        : dayjs(previous.timePlanned);
    const currentStart = dayjs(current.timePlanned);
    return currentStart.isBefore(estimatedEndTime);
}

export function groupStepsByStageName<
    StepI extends { stageName: string | null },
>(steps: StepI[]) {
    const groupedSteps: { stageName: string | null; steps: StepI[] }[] = [];

    steps.forEach(step => {
        const lastGroup = groupedSteps[groupedSteps.length - 1];
        const stageName = step.stageName;

        if (lastGroup && lastGroup.stageName === stageName) {
            lastGroup.steps.push(step);
        } else {
            groupedSteps.push({ stageName, steps: [step] });
        }
    });

    return groupedSteps;
}
