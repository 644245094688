import { ReactElement } from "react";

import {
    ModalContent,
    ModalFooterButtonProps,
    ModalProps,
    ModalWrapper,
    ParentManagedState,
} from "./Modal";

export type MultiViewModalViewButton = Partial<
    Omit<ModalFooterButtonProps, "onClick">
> & {
    onClick: () => Promise<void>;
};

export type MultiViewModalViewSimple = {
    /**
     * Props for the left-hand footer button on this view
     */
    buttonLeft: MultiViewModalViewButton;
    /**
     * Props for the right-hand footer button on this view
     */
    buttonRight: MultiViewModalViewButton;
    /**
     * Callback to render this view
     */
    render: () => ReactElement;
};

export type MultiViewModalView =
    | {
          /**
           * Props for the left-hand footer button on this view
           */
          buttonLeft: MultiViewModalViewButton;
          /**
           * Props for the right-hand footer button on this view
           */
          buttonRight: MultiViewModalViewButton;
          /**
           * Callback to render this view
           */
          render: () => ReactElement;
      }
    | {
          renderModalContent: () => ReactElement | null;
      };

/**
 * Record of view IDs to corresponding view data
 */
export type MultiViewModalViewRecord<T extends string> = Record<
    T,
    MultiViewModalView | null
>;

/**
 * Props for the MultiViewModal component.
 */
export type MultiViewModalProps<T extends string> = ParentManagedState &
    Omit<ModalProps, "children" | "buttonLeft" | "buttonRight"> & {
        /**
         * Map of view IDs to corresponding views
         */
        views: MultiViewModalViewRecord<T>;
        /**
         * The ID of the current modal view
         */
        viewId: T;
    };

/**
 * Wrapper around the Modal component that allows for multiple pages of content.
 *
 * Does not handle state management for progressing through the views - however,
 * utilities exist for constructing a simple linear flow of views.
 */
export function MultiViewModal<T extends string>(
    props: MultiViewModalProps<T>,
): ReactElement {
    const { views, viewId, setModalOpen, ...modalProps } = props;

    const viewProps = views[viewId];

    if (!viewProps) {
        return <></>;
    }

    return (
        <ModalWrapper {...modalProps} setModalOpen={setModalOpen}>
            {"renderModalContent" in viewProps ? (
                viewProps.renderModalContent()
            ) : (
                <ModalContent
                    title={modalProps.title}
                    contentPadding={modalProps.contentPadding}
                    buttonLeft={{ label: "Back", ...viewProps.buttonLeft }}
                    buttonRight={{
                        label: "Continue",
                        ...viewProps.buttonRight,
                    }}>
                    {viewProps.render()}
                </ModalContent>
            )}
        </ModalWrapper>
    );
}
