import { useState } from "react";

import { gql, useMutation as useApolloMutation } from "@apollo/client";
import { atom, useAtom } from "jotai";
import { isNull, isUndefined } from "lodash";

import {
    CreateProtocolInput,
    CreateProtocolMutation,
    Protocol as GqlProtocol,
} from "__generated__/apollo/graphql";

import { log as parentLog } from "./log";
import { PROTOCOL_STARTING_BOTTLES_QUERY } from "./ReviewBottles/ReviewBottlesView";

export const log = parentLog.extend("use-create-protocol");

type CreateProtocolFunction = (
    params: CreateProtocolInput & { ignoreWetTests: boolean },
) => Promise<{
    ok: boolean;
    message: string;
    protocol: GqlProtocol | null;
    isWetTest: boolean | null;
}>;

export interface UseCreateProtocol {
    createProtocol: CreateProtocolFunction;
    clearProtocol: () => void;
    protocolData: GqlProtocol | null;
    uploadingProtocol: boolean;
    /** Whether the created protocol is protocol for a Wet Test */
    isWetTestProtocol: boolean;
}

const CREATE_PROTOCOL_MUTATION = gql(`
    mutation CreateProtocol($input: CreateProtocolInput!) {
        createProtocol(input: $input) {
            ok
            message
            protocol {
                id
                name
                initialCoatingTimeMinutes
                initialSeedProcedureData {
                    flaskSeedGroups {
                        doseMl
                        flaskNumbers
                        flaskSizeCm2
                    }
                    seedVesselCellSuspensionMl
                }
                startingBottles {
                    liquidVolume
                    reagentName
                    totalVolume
                }
            }
            wetTestProtocol {
                id
                name
                initialCoatingTimeMinutes
                initialSeedProcedureData {
                    flaskSeedGroups {
                        doseMl
                        flaskNumbers
                        flaskSizeCm2
                    }
                    seedVesselCellSuspensionMl
                }
                startingBottles {
                    liquidVolume
                    reagentName
                    totalVolume
                }
            }
        }
    }
`);

/**
 * State atom that stores the GraphQL protocol information for a protocol
 * that exists in the server's database
 */
const protocolAtom = atom<null | GqlProtocol>(null);

export function useCreateProtocol(): UseCreateProtocol {
    const [protocol, setProtocol] = useAtom(protocolAtom);
    const [isWetTestProtocol, setIsWetTestProtocol] = useState(false);
    const [uploadingProtocol, setUploadingProtocol] = useState(false);

    const [createProtocolMutation] = useApolloMutation<CreateProtocolMutation>(
        CREATE_PROTOCOL_MUTATION,
        {
            update(cache, { data }) {
                // Apollo does not update IDs for multiple entities in one go.
                if (data?.createProtocol.protocol) {
                    cache.writeQuery({
                        query: PROTOCOL_STARTING_BOTTLES_QUERY,
                        data: { protocol: data?.createProtocol?.protocol },
                        variables: {
                            protocolId: data?.createProtocol?.protocol.id,
                        },
                    });
                }
                if (data?.createProtocol.wetTestProtocol) {
                    cache.writeQuery({
                        query: PROTOCOL_STARTING_BOTTLES_QUERY,
                        data: {
                            protocol: data?.createProtocol?.wetTestProtocol,
                        },
                        variables: {
                            protocolId:
                                data?.createProtocol?.wetTestProtocol.id,
                        },
                    });
                }
            },
        },
    );

    function clearProtocol() {
        setProtocol(null);
    }

    /**
     * Creates a protocol entity on the server, required to progress through
     * the culture creation flow as protocol information will be queried via
     * GraphQL.
     */
    const createProtocol: CreateProtocolFunction = async ({
        protocol,
        ignoreWetTests,
    }) => {
        // We have already checked that protocol is valid JSON & somewhat parsed it

        setUploadingProtocol(true);
        const res = await createProtocolMutation({
            variables: { input: { protocol } },
        });

        const errors = res.errors?.length;
        const data = res.data?.createProtocol;
        const noData = isNull(data) || isUndefined(data);

        if (errors || noData || data.ok === false) {
            log.error(res, "Error creating protocol");
            const message = data?.message || "Error creating protocol";

            return {
                ok: false,
                message: message,
                protocol: null,
                isWetTest: null,
            };
        }

        if (!ignoreWetTests && data.wetTestProtocol) {
            setProtocol(data.wetTestProtocol);
            setIsWetTestProtocol(true);
            setUploadingProtocol(false);

            return {
                ok: true,
                message: data.message,
                protocol: data.protocol,
                isWetTest: true,
            };
        }

        setProtocol(data.protocol);
        setIsWetTestProtocol(false);
        setUploadingProtocol(false);

        return {
            ok: true,
            message: data.message,
            protocol: data.protocol,
            isWetTest: false,
        };
    };

    return {
        createProtocol,
        clearProtocol,
        protocolData: protocol,
        uploadingProtocol,
        isWetTestProtocol,
    };
}
