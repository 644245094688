import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { RescheduleProcedureInput } from "__generated__/apollo/graphql";
import {
    getToastifyMessages,
    useToasts,
} from "components/common/toasts/useToasts";

export function useRescheduleProcedure(): {
    rescheduleProcedure: (props: RescheduleProcedureInput) => Promise<void>;
} {
    const { toastifyAction } = useToasts();
    const [rescheduleProcedureMutation] = useMutation(
        RESCHEDULE_PROCEDURE_MUTATION,
    );

    const rescheduleProcedure = async (input: RescheduleProcedureInput) => {
        await toastifyAction(
            getToastifyMessages({
                entity: input.cascade ? "procedures" : "procedure",
                verb: {
                    past: "rescheduled",
                    gerund: "rescheduling",
                },
            }),
            async () => {
                const output = await rescheduleProcedureMutation({
                    variables: { input },
                });

                if (output.data?.rescheduleProcedure.ok) {
                    return output;
                } else {
                    throw new Error(output.data?.rescheduleProcedure.message);
                }
            },
        );
    };
    return { rescheduleProcedure };
}

const RESCHEDULE_PROCEDURE_MUTATION = gql(`
    mutation RescheduleProcedure($input: RescheduleProcedureInput!) {
        rescheduleProcedure(input: $input) {
            ok
            message
            culture {
                id

                # Ensure we show the new procedure to the user on complete
                schedule {
                    ...ScheduleWithAllProcedures
                }
            }
        }
    }
`);
