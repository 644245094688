import { Fragment, ReactElement } from "react";

import { css } from "@emotion/react";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { CultureStatusBadge } from "components/common/CultureStatusBadge";
import Icon from "components/common/Icon";
import { SoftwareUpdateBadge } from "components/common/SoftwareUpdateBadge";
import Txt from "components/common/Text";
import { getCultureName } from "services/culture-utils";
import { useIsMobile } from "services/hooks/window";

import { Device } from "./DevicesContainer";
import { log as parentLog } from "./log";

const log = parentLog.extend("DeviceCard");

interface DeviceCardProps {
    device: Device;
}

export default function DeviceCard(props: DeviceCardProps): ReactElement {
    const { device } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const notificationsEnabled = device?.users?.myAccess?.notificationsEnabled;

    log.debug("DeviceCard for", device);

    const devicePagePath = `/devices/${device.id}`;
    const updateAvailable: boolean = device?.balena?.updateAvailable ?? false;
    const updating: boolean = device?.balena?.updating ?? false;

    const statusColour = device.isOnline
        ? theme.colours.brand.greenDark
        : theme.colours.neutral[500];

    return (
        <Card
            variant="outlined"
            css={css`
                border-left: 4px solid ${statusColour};
                cursor: pointer;
            `}
            onClick={e => {
                e.stopPropagation();
                // we use navigate instead of a Link to avoid nested anchor
                // tags and full page reloads
                if (e.metaKey || e.button === 1) {
                    // metaKey is CMD/CTRL held at same time as click
                    const newUrl = new URL(window.location.origin);
                    newUrl.pathname = devicePagePath;
                    window.open(newUrl.href, "_blank")?.focus();
                } else {
                    navigate(devicePagePath);
                }
            }}>
            <div
                css={css`
                    padding: 16px;
                `}>
                <Grid container direction="row" alignItems="center">
                    <Grid container alignItems="center">
                        <Grid item style={{ flexGrow: 1 }}>
                            <DeviceName device={device} />
                        </Grid>
                        <Grid item>
                            <div
                                style={{
                                    display: "flex",
                                    gap: 16,
                                }}>
                                {notificationsEnabled === true && (
                                    <Tooltip title="Alerts enabled">
                                        <span style={{ marginTop: 4 }}>
                                            <Icon
                                                name="bell"
                                                colourOverride={
                                                    theme.colours.neutral[700]
                                                }
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                                {(updateAvailable || updating) && (
                                    <SoftwareUpdateBadge
                                        updateAvailable={updateAvailable}
                                        updating={updating}
                                        deviceId={device.id}
                                        border
                                        size={isMobile ? "sm" : undefined}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CultureInfo device={device} />
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
}

function DeviceName(props: { device: Device }): ReactElement {
    const theme = useTheme();
    const displayName = props.device.name ?? props.device.id;
    const color = props.device.isOnline
        ? theme.colours.neutral[900]
        : theme.colours.neutral[600];
    return (
        <Txt
            level={6}
            style={{
                color,
                marginRight: 16,
            }}>
            {displayName}
        </Txt>
    );
}

function CultureInfo(props: { device: Device }): ReactElement {
    if (!props.device.culture) return <Fragment />;

    const { culture } = props.device;
    const cultureName = getCultureName(culture);

    return (
        <div style={{ display: "flex", gap: 8, marginTop: 8 }}>
            <CultureStatusBadge
                cultureState={culture.isWetTestCulture ? null : culture.state}
                wetTestCultureState={
                    culture.isWetTestCulture ? culture.state : null
                }
                waitingForConfirmation={
                    culture.schedule?.isWaitingOnConfirmation
                }
                loading={false}
                errorMessage={culture?.errorMessage}
            />
            <Txt
                level={8}
                italic
                display="inline"
                sx={{ color: theme => theme.colours.neutral[600] }}>
                {cultureName}
            </Txt>
        </div>
    );
}
