import { ReactElement, useCallback, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Collapse } from "@mui/material";

import { isToday } from "services/date";
import {
    Procedure,
    SimulationErrorDetails,
} from "services/hooks/useCultureSchedule";
import {
    scrollIntoViewByHtmlId,
    scrollIntoViewIfNeeded,
} from "services/hooks/useScrollIntoView";
import { useWindowEventListener } from "services/hooks/useWindowEventListener";

import { CultureScheduleEvents } from "./CultureScheduleToolbar";
import { DayHeader } from "./DayHeader";
import { log as parentLog } from "./log";
import { ProcedureItem } from "./Procedures/ProcedureItem";
import { procedureCollidesWithPrevious } from "./Procedures/ProcedureUtils";

export const log = parentLog.extend("DayBlock");

export interface DayBlockProps {
    /**
     * !!WARNING!! Do not used the device id to infer culture information
     */
    deviceId: string;
    cultureId: string;
    dayNum: number;
    date: string;
    procedures: (Procedure | null)[];
    nextProcedureId: string | undefined;
    currentProcedureId: string | undefined;
    cultureIsActive: boolean;
    simulationErrorDetails?: SimulationErrorDetails | null;
    cultureIsWaitingForConfirmation: boolean | null;
    nextStepId?: string;
}

export function DayBlock(props: DayBlockProps): ReactElement {
    const dateIsToday = isToday(props.date);

    const [expanded, setExpanded] = useState(() => {
        // If a user doesn't confirm a procedure on the day it's scheduled, we
        // want to ensure the day with the outstanding confirmation is expanded
        // by default, so they can see that is needs their attention.
        const hasCurrentProcedure = props.procedures.some(
            procedure => procedure?.id == props.currentProcedureId,
        );

        // Simulation errors also need immediate user attention, so ensure we
        // expand any days with problem procedures in
        const hasSimulationError = props.procedures.some(
            p => p?.id === props.simulationErrorDetails?.problemProcedure?.id,
        );

        return hasCurrentProcedure || hasSimulationError || dateIsToday;
    });

    const scrollHandler = useCallback(() => {
        if (dateIsToday)
            scrollToLatest({
                dayNum: props.dayNum,
                expanded,
                currentProcedureId: props.currentProcedureId,
                nextProcedureId: props.nextProcedureId,
            });
    }, [
        dateIsToday,
        props.dayNum,
        expanded,
        props.currentProcedureId,
        props.nextProcedureId,
    ]);

    // Scroll to 'today' when the schedule is first loaded
    useEffect(() => {
        scrollHandler();
        // deliberately omitting dependencies so we only run on the first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useWindowEventListener({
        id: CultureScheduleEvents.ScrollToNext,
        handler: scrollHandler,
    });
    useWindowEventListener({
        id: CultureScheduleEvents.CollapseAll,
        handler: () => setExpanded(false),
    });
    useWindowEventListener({
        id: CultureScheduleEvents.ExpandAll,
        handler: () => setExpanded(true),
    });

    return (
        <div
            id={makeDayBlockHtmlId(props.dayNum)}
            style={{
                margin: "0 8px",
                scrollMarginTop: "75px", //  This is to ensure the day is not hidden under the fixed header when we scroll to it
            }}>
            <DayHeader
                {...props}
                expanded={expanded}
                toggleExpanded={() => setExpanded(e => !e)}
            />
            <Collapse in={expanded} mountOnEnter unmountOnExit>
                <DayContent {...props} />
            </Collapse>
        </div>
    );
}

function DayContent(props: DayBlockProps) {
    return (
        <DayContentContainer id={`schedule-step-list-${props.dayNum}`}>
            {props.procedures.map((procedure, i, procedures) => (
                <div
                    id={makeProcedureHtmlId(procedure?.id ?? "unknown")}
                    key={procedure?.id ?? i}
                    style={{
                        scrollMarginTop: "110px", //  This is to ensure the procedure is not hidden under the sticky day header when we scroll to it
                    }}>
                    <ProcedureItem
                        deviceId={props.deviceId}
                        cultureId={props.cultureId}
                        index={i}
                        procedure={procedure}
                        isNext={Boolean(
                            procedure && props.nextProcedureId === procedure.id,
                        )}
                        isCurrent={Boolean(
                            procedure &&
                                props.currentProcedureId === procedure.id,
                        )}
                        cultureIsWaitingForConfirmation={
                            props.cultureIsWaitingForConfirmation
                        }
                        cultureIsActive={props.cultureIsActive}
                        collidesWithPrevious={
                            i > 0 &&
                            procedureCollidesWithPrevious({
                                current: procedure,
                                previous: procedures[i - 1],
                            })
                        }
                        hasSimulationError={
                            procedure?.id ===
                            props.simulationErrorDetails?.problemProcedure?.id
                        }
                        nextStepId={props.nextStepId}
                    />
                </div>
            ))}
        </DayContentContainer>
    );
}

const makeDayBlockHtmlId = (dayNum: number) => `day-${dayNum}`;
const makeProcedureHtmlId = (id: string) => `procedure-${id}`;

function scrollToLatest({
    dayNum,
    expanded,
    currentProcedureId,
    nextProcedureId,
}: {
    dayNum: number;
    expanded: boolean;
    currentProcedureId: string | undefined;
    nextProcedureId: string | undefined;
}) {
    // For all of the below we scroll the element to the top of the viewport.
    // This is to allow the user to see as much as possible of the upcoming
    // entities. The ensure we don't scroll the element under the fixed header
    // we've had to add `scroll-margin-top` css properties to the appropriate
    // elements.

    if (!expanded) {
        const dayBlockHtmlId = makeDayBlockHtmlId(dayNum);
        return scrollIntoViewByHtmlId(dayBlockHtmlId, {
            block: "start",
        });
    }

    if (currentProcedureId) {
        const currentProcedureHtmlId = makeProcedureHtmlId(currentProcedureId);
        return scrollIntoViewByHtmlId(currentProcedureHtmlId, {
            block: "start",
        });
    }

    const nextProcedureElement =
        nextProcedureId &&
        document.getElementById(makeProcedureHtmlId(nextProcedureId));

    if (nextProcedureElement) {
        return scrollIntoViewIfNeeded(nextProcedureElement, {
            block: "start",
        });
    }

    const dayBlockHtmlId = makeDayBlockHtmlId(dayNum);
    return scrollIntoViewByHtmlId(dayBlockHtmlId, {
        block: "start",
    });
}

const DayContentContainer = styled.div`
    margin-top: 12px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
