import { ReactElement, ReactNode } from "react";

import { css, useTheme } from "@mui/material/styles";

import Txt from "./Text";

export interface TextLabelProps {
    /**
     * React Nodes being labelled (usually Text)
     */
    children: ReactNode;
    /**
     * Text used to label the content
     */
    label: string;
    /**
     * Sets the flex direction for the container of the children elements
     */
    childrenDirection?: "row" | "column";
}

export function TextLabel(props: TextLabelProps): ReactElement {
    const { childrenDirection } = props;
    const theme = useTheme();
    const childrenContainerCss = css({
        width: "100%",
        display: "flex",
        flexDirection: childrenDirection ?? "row",
        alignItems: "baseline",
        flexWrap: "wrap",
        columnGap: theme.spacing(5),
    });
    return (
        <div>
            <Txt
                font="secondary"
                level={8}
                emphasis
                style={{
                    color: theme.colours.neutral[500],
                    marginBottom: theme.spacing(1),
                }}>
                {props.label}
            </Txt>
            <div css={childrenContainerCss}>{props.children}</div>
        </div>
    );
}
