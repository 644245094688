import { ReactElement, ReactNode } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";

import Button, { ButtonProps } from "components/common/Button";

import Txt from "./Text";

interface ConfirmationDialogProps {
    /**
     * Sets the styling and behaviour of the dialog component.
     * - `advised` = Primary action is encouraged / low risk / easily reversible
     * - `warning` = Primary action could be undesirable
     * - `destructive` = Primary action is not reversible/recoverable
     */
    variant: "advised" | "warning" | "destructive";
    /**
     * Override default variant behaviour to delay the primary action
     */
    delayPrimaryAction?: boolean;
    /**
     * Open state of the dialog
     */
    open: boolean;
    /**
     * Text used as title for dialog
     */
    title: string;
    /**
     * Components placed in dialog content
     */
    body: ReactNode;
    /**
     * Children are rendered after dialog content without standard formatting
     */
    children?: ReactNode;
    /**
     * The text displayed in the primary (lower right) button
     */
    primaryButtonText: string;
    /**
     * Controls if the primary (lower right) button is disabled
     */
    primaryButtonDisabled?: boolean;
    /**
     * The function called when the primary (lower right) button is clicked
     */
    onPrimaryAction?: () => void;
    /**
     * The text displayed in the secondary (lower left) button
     */
    secondaryButtonText?: string;
    /**
     * The function called when the secondary (lower left) button is clicked
     */
    onSecondaryAction?: () => void;
    /**
     * The function called when the dialog requests dismissal (e.g. Esc key is
     * pressed or the area outside the dialog is clicked)
     */
    onDismiss?: () => void;
}

const primaryButtonVariantStyle: {
    [k in ConfirmationDialogProps["variant"]]: Partial<ButtonProps>;
} = {
    advised: {
        variant: "primary",
        delayInteractive: undefined,
        autoFocus: true,
    },
    warning: {
        variant: "secondary",
        colour: "alert",
        delayInteractive: true,
        autoFocus: true,
    },
    destructive: {
        variant: "secondary",
        colour: "alert",
        delayInteractive: true,
        autoFocus: true,
    },
};

export default function ConfirmationDialog({
    variant,
    delayPrimaryAction,
    open,
    title,
    body,
    primaryButtonText,
    secondaryButtonText = "Cancel",
    primaryButtonDisabled = false,
    onPrimaryAction,
    onSecondaryAction,
    onDismiss,
    children,
}: ConfirmationDialogProps): ReactElement {
    const handlePrimaryClick = () => {
        onPrimaryAction?.();
    };
    const handleSecondaryClick = () => {
        onSecondaryAction?.();
    };
    const handleOnClose = () => {
        onDismiss?.();
    };

    const delayPrimaryButtonStyle =
        delayPrimaryAction !== undefined
            ? { delayInteractive: delayPrimaryAction }
            : {};

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleOnClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <TitleContainer id="alert-dialog-title">
                    <Txt font="secondary" level={6} emphasis>
                        {title}
                    </Txt>
                </TitleContainer>
                <ContentContainer>
                    <Txt
                        font="secondary"
                        level={7}
                        sx={{ color: theme => theme.colours.neutral[700] }}>
                        {body}
                    </Txt>
                    {children && (
                        <div style={{ marginBottom: 12 }}>{children}</div>
                    )}
                </ContentContainer>
                <DialogActions style={{ padding: 24, paddingTop: 0 }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <Button
                            variant="secondary"
                            size="m"
                            onClick={handleSecondaryClick}>
                            {secondaryButtonText}
                        </Button>
                        <Button
                            disabled={primaryButtonDisabled}
                            size="m"
                            onClick={handlePrimaryClick}
                            {...primaryButtonVariantStyle[variant]}
                            {...delayPrimaryButtonStyle}>
                            {primaryButtonText}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const TitleContainer = styled("div")`
    padding: 24px;
    padding-bottom: 0px;
`;

const ContentContainer = styled("div")`
    padding: 20px 24px;
    padding-top: 8px;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    gap: 12px;
    display: flex;
    flex-direction: column;
`;
