import { max } from "lodash";

import { StepState } from "__generated__/apollo/graphql";
import {
    dayjs,
    formatTime,
    isToday,
    relativeDatetime,
    isInPast,
    formatSecondsDuration,
    differenceInSeconds,
} from "services/date";
import { LegacyStep } from "services/hooks/useCultureScheduleLegacy";
import { useRenderTimer } from "services/hooks/useRenderTimer";
import { toTitleCase } from "services/string-utils";

export function calculateRerenderSecondsInterval(msUntil: number): number {
    return msUntil < 0 // if in the past
        ? 3600 // then every hour
        : msUntil < 60_000 // if less than a minute away
          ? 5 + Math.random() * 5 // then every 5-10 seconds
          : msUntil < 3600_000 // if less than an hour away
            ? 60 // then every minute
            : 600; // else every 10 minutes
}

/**
 * Generates the estimated duration message that may be added to the secondary
 * text on a Step.
 * @param step Step data
 * @returns string to append
 */
export function useEstimatedDurationMessage(step: LegacyStep): string {
    const referenceStepName =
        step.runtimeReference?.referenceStep?.name ?? undefined;
    const runtimeInSeconds =
        step.runtimeReference?.runtimeInSeconds ?? undefined;

    const refStepTimeFinished =
        step.runtimeReference?.referenceStep?.timeFinished ?? undefined;
    const isRunning = step.state === StepState.Running;

    const liveReferenceRuntime =
        runtimeInSeconds && refStepTimeFinished && isRunning;

    useRenderTimer({ seconds: 1, enabled: Boolean(liveReferenceRuntime) });

    if (liveReferenceRuntime) {
        const stopTime = dayjs(refStepTimeFinished).add(
            runtimeInSeconds,
            "seconds",
        );
        const diffSeconds = stopTime.diff(dayjs(), "seconds");
        const remainingSeconds = max([diffSeconds, 0]);
        return ` (${remainingSeconds} seconds remaining)`;
    }

    if (referenceStepName && runtimeInSeconds) {
        const formattedRuntime = formatSecondsDuration(runtimeInSeconds);
        return ` (will run for ${formattedRuntime} following completion of ${referenceStepName})`;
    }

    if (step.estimatedDuration) {
        const estimatedDuration = dayjs
            .duration(step.estimatedDuration, "milliseconds")
            .humanize();
        return ` (takes about ${estimatedDuration})`;
    }
    return "";
}

/**
 * Generates the secondary text that should be displayed on a Step based on its
 * current state.
 * @param step Step data
 * @returns String
 */
export function useStepSecondaryText(step: LegacyStep): string {
    const isPlanned = step.state === StepState.Planned;
    const isRunning = step.state === StepState.Running;

    const estDurationMsg = useEstimatedDurationMessage(step);

    const stepDuration =
        step.timeStarted && step.timeFinished
            ? differenceInSeconds(step.timeStarted, step.timeFinished)
            : null;
    const runtime = stepDuration
        ? dayjs.duration(stepDuration, "seconds").humanize()
        : "unknown";
    const finishTime = step.timeFinished
        ? formatTime(step.timeFinished)
        : "some point";

    const state = step.state ?? "unknown";
    const presentableState = toTitleCase(state);

    let secondaryText = `${presentableState} at ${finishTime} (runtime ${runtime})`;
    if (isRunning) {
        secondaryText = "Running" + estDurationMsg;
    } else if (isPlanned) {
        const { timePlanned } = step;
        if (timePlanned) {
            const timePlannedIsPassed = isInPast(timePlanned);
            const absoluteTime = dayjs(timePlanned).format("HH:mm");
            if (timePlannedIsPassed) {
                secondaryText = "As soon as possible";
            } else if (isToday(timePlanned)) {
                const relativeTimePlanned = relativeDatetime(timePlanned);
                secondaryText = `${relativeTimePlanned}, at ${absoluteTime}`;
            } else {
                secondaryText = `at ${absoluteTime}`;
            }
        } else {
            secondaryText = "At some point";
        }
        secondaryText += estDurationMsg;
    } else if (step.state === StepState.Removed) {
        secondaryText = "Will be skipped";
    } else if (step.state === StepState.Ignored) {
        secondaryText = "Ignored and was not run";
    }
    return secondaryText;
}
