import { Fragment, ReactElement } from "react";

import { useToasts } from "components/common/toasts/useToasts";
import { config } from "services/config";
import { useFeature } from "services/feature-flags";

import { log as parentLog } from ".";

export const log = parentLog.extend("UpdateAvailableChecker");

export function UpdateAvailableChecker(): ReactElement {
    const { toast } = useToasts();
    const flag = "app_updates_skip_confirmation";
    const { enabled: skipAlert } = useFeature(flag);

    const handleUpdateAvailable = () => {
        if (skipAlert) {
            log.info(
                `Feature flag ${flag} is enabled. Skipping user alert and triggering update to service worker.`,
            );
            config.updateServiceWorker();
        } else {
            toast.action({
                message:
                    "There is an new app version available. Updating will reload the page. Any partially completed fields or unsaved changes may be lost.",
                actionLabel: "Update",
                actionLabelPressed: "Updating",
                onClick: () => config.updateServiceWorker(),
                alertId: "update-available-alert", // prevent duplicates
                dismiss: false,
            });
        }
    };

    if (config.serviceWorker.updateAvailable) {
        handleUpdateAvailable();
    } else {
        config.onUpdateAvailable(handleUpdateAvailable);
    }
    return <Fragment />;
}
