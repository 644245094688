import styled from "@emotion/styled";
import { cloneDeep } from "lodash";

import Checkbox from "components/common/Checkbox";
import { IconButton } from "components/common/IconButton";
import Txt from "components/common/Text";

import { DrySetupGuideData } from "./DrySetupGuide";

type DrySetupSectionProps = {
    sectionData: DrySetupGuideData[number];
    checkBoxStates: boolean[];
    setCheckBoxStates: (newValue: boolean[]) => void;
};

export function DrySetupSection({
    sectionData: { sectionTitle, steps, howToGuide },
    checkBoxStates,
    setCheckBoxStates,
}: DrySetupSectionProps) {
    /** Change handler for a particular check box index */
    const checkBoxChangeHandler = ({
        stepIndex,
        newValue,
    }: {
        stepIndex: number;
        newValue: boolean;
    }) => {
        return () => {
            const newState = cloneDeep(checkBoxStates);
            newState[stepIndex] = newValue;
            setCheckBoxStates(newState);
        };
    };

    return (
        <div
            key={sectionTitle}
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
            }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                <SubTitleTxt font="secondary" level={8} emphasis>
                    {sectionTitle}
                </SubTitleTxt>
                {howToGuide && (
                    <a href={howToGuide} target="blank" rel="noreferrer">
                        <IconButton
                            icon="help-book"
                            size="sm"
                            sx={{ marginLeft: 2 }}
                        />
                    </a>
                )}
            </div>

            <div>
                {steps.map((step, stepIndex) => (
                    <div
                        key={step}
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                        }}>
                        <Checkbox
                            id={step}
                            checked={checkBoxStates[stepIndex]}
                            onChange={checkBoxChangeHandler({
                                stepIndex,
                                newValue: !checkBoxStates[stepIndex],
                            })}
                            name={step}
                            disableRipple
                            style={{ padding: "4px" }}
                        />
                        <Txt
                            sx={{
                                fontcolor: theme => theme.colours.neutral[900],
                                // Slightly more than checkbox padding -> first line should always align
                                marginTop: "5px",
                            }}
                            font="secondary"
                            level={8}>
                            {step}
                        </Txt>
                    </div>
                ))}
            </div>
        </div>
    );
}

const SubTitleTxt = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
`;
