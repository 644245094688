import { ApolloError, useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { DeviceCultureScheduleLegacyQuery } from "__generated__/apollo/graphql";

import { log as parentLog } from "./log";

export const log = parentLog.extend("useCultureSchedule");

export const DEVICE_CULTURE_SCHEDULE_QUERY = gql(`
    query DeviceCultureScheduleLegacy($deviceId: String!) {
        device(id: $deviceId) {
            id
            name
            cultureId
            culture {
                isActive
                id
                name
                state
                schedule {
                    nextStep {
                        id
                    }
                    steps {
                        totalCount
                        nodes {
                            id
                            name
                            state
                            timePlanned
                            timeStarted
                            timeFinished
                            estimatedDuration
                            runtimeReference {
                                referenceStep {
                                    id
                                    name
                                    timeFinished
                                }
                                runtimeInSeconds
                            }
                            confirmationRequired
                            confirmationText
                            waitingForConfirmation
                            confirmed
                            errorMessage
                            invocations {
                                totalCount
                            }
                        }
                    }
                }
            }
        }
    }
`);

export const CULTURE_SCHEDULE_LEGACY_QUERY = gql(`
    query CultureScheduleLegacy($cultureId: String!) {
        culture(id: $cultureId) {
            id
            name
            state
            isActive
            device {
                id
            }
            schedule {
                nextStep {
                    id
                }
                steps {
                    totalCount
                    nodes {
                        id
                        name
                        state
                        timePlanned
                        timeStarted
                        timeFinished
                        estimatedDuration
                        runtimeReference {
                            referenceStep {
                                id
                                name
                                timeFinished
                            }
                            runtimeInSeconds
                        }
                        confirmationRequired
                        confirmationText
                        waitingForConfirmation
                        confirmed
                        errorMessage
                        invocations {
                            totalCount
                        }
                    }
                }
            }
        }
    }
`);

// TODO remove this legacy hook
export function useCultureSchedule(
    props: { deviceId: string } | { cultureId: string },
): {
    culture?: LegacyCulture;
    loading: boolean;
    error?: ApolloError;
    deviceId?: string;
    deviceHasCulture?: boolean;
} {
    const isDeviceProp = "deviceId" in props;
    const pollInterval = 5_000; // milliseconds // TODO remove in favour of a Step requerying own data if relevant

    /**
     * Loading data via the device ID
     */
    const {
        data: deviceData,
        loading: deviceLoading,
        error: deviceError,
    } = useQuery(DEVICE_CULTURE_SCHEDULE_QUERY, {
        skip: !isDeviceProp,
        variables: { deviceId: isDeviceProp ? props.deviceId : "" },
        pollInterval,
    });

    /**
     * Loading data via the Culture ID
     */
    const {
        data: cultureData,
        loading: cultureLoading,
        error: cultureError,
    } = useQuery(CULTURE_SCHEDULE_LEGACY_QUERY, {
        skip: isDeviceProp,
        variables: { cultureId: !isDeviceProp ? props.cultureId : "" },
        pollInterval,
    });

    /**
     * Resolve the data
     */
    if (isDeviceProp) {
        return {
            deviceId: props.deviceId,
            culture: deviceData?.device?.culture ?? undefined,
            loading: deviceLoading,
            error: deviceError,
            deviceHasCulture: Boolean(deviceData?.device?.cultureId),
        };
    } else {
        return {
            deviceId: cultureData?.culture?.device?.id,
            culture: cultureData?.culture ?? undefined,
            loading: cultureLoading,
            error: cultureError,
        };
    }
}

type LegacyDevice = NonNullable<DeviceCultureScheduleLegacyQuery["device"]>;
type LegacyCulture = NonNullable<LegacyDevice["culture"]>;
export type LegacySchedule = NonNullable<LegacyCulture["schedule"]>;
export type LegacyStep = NonNullable<
    NonNullable<NonNullable<LegacySchedule["steps"]>["nodes"]>[number]
>;
