import { ReactElement } from "react";

import styled from "@emotion/styled";

import Callout from "components/common/Callout";
import { CultureBottle } from "services/hooks/useCultureContainers";
import { isNotNull } from "services/utils";

import { ContainerTile } from "./ContainerTile";

interface BottleTilesProps {
    bottles: CultureBottle[];
    volumesEditable: boolean;
}

export function BottleTiles({
    bottles,
    volumesEditable,
}: BottleTilesProps): ReactElement {
    if (!bottles?.length) {
        return (
            <CardContent>
                <Callout
                    variant="info"
                    message="Culture does not have any bottles"
                />
            </CardContent>
        );
    }
    /**
     * This function does a rudimentary check from container reference to see
     * if the container a seed, waste or harvest vessel.
     *
     * The seed and harvest vessel volume reset times can sometimes not 100%
     * represent reality in the protocol currently. Also, they should have
     * different icons, which leads to a conflict at 250ml where there could be
     * one of two graphics displayed depending if we show a bottle or vessel.
     * The simplest way out is to not display seed, harvest or waste vessels -
     * the value of displaying these is less clear anyway.
     */
    function isNotVessel(b: CultureBottle) {
        return !b?.containerReference?.toLowerCase()?.endsWith("vessel");
    }

    return (
        <>
            {bottles
                .filter(isNotNull)
                .filter(isNotVessel)
                .map(b => (
                    <ContainerTile
                        key={b.id}
                        id={b.id}
                        name={b.name ?? b.containerReference ?? b.id}
                        liquidVolumeMl={b.liquidVolumeMl}
                        totalVolumeMl={b.totalVolumeMl}
                        isVolumeModifiable={volumesEditable}
                    />
                ))}
        </>
    );
}

const CardContent = styled.div`
    grid-column: 1 / span 2;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        grid-column: 1 / span 1;
    }
`;
