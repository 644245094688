import { ReactElement, useCallback, useState } from "react";

import styled from "@emotion/styled";
import { throttle } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTimeoutFn } from "react-use";

import { log as componentsLog } from "components";
import Button from "components/common/Button";
import Skeleton from "components/common/Skeleton";
import Txt from "components/common/Text";
import { authentication } from "services/auth";
import { config } from "services/config";
import { useUser } from "services/hooks/useUser";

const log = componentsLog.extend("Signin");

export function getSigninRedirectUrl(): URL {
    const url = new URL("/auth/workos/authkit", config.serverUrl);

    const redirectUrl = authentication.getRedirectUrl();
    url.searchParams.append("redirectUrl", redirectUrl);

    const lastEmailAuthenticated = authentication.lastEmailAuthenticated();
    if (lastEmailAuthenticated) {
        url.searchParams.append("email", lastEmailAuthenticated);
    }

    return url;
}

export function Signin(): ReactElement {
    const navigate = useNavigate();

    const location = useLocation();
    log.debug("location.state", location.state);
    const { from } = location.state || { from: { pathname: "/" } };
    const { user, loading } = useUser({ fetchPolicy: "network-only" });

    const [manualRedirect, setManualRedirect] = useState(false);
    const [params] = useSearchParams();
    const status = params.get("status");
    const didReturnError = status !== null;
    const redirectUrl = getSigninRedirectUrl();

    log.info("State of Signin page", {
        user: Boolean(user),
        loading,
        redirectUrl,
    });

    const shouldRedirect = !user && !loading && !didReturnError;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const redirect = useCallback(
        throttle(
            () => {
                window.location.href = redirectUrl.toString();
            },
            6_000,
            { leading: true, trailing: false },
        ),
        [redirectUrl],
    );

    useTimeoutFn(() => {
        if (shouldRedirect) {
            redirect();
        }
        setManualRedirect(true);
    }, 5_000);

    if (shouldRedirect) {
        redirect();
    }

    if (user) {
        log.debug("Already logged in", user.id);
        navigate(from, { replace: true }); // redirect to wherever use was last (or home)
    }

    if (didReturnError) {
        log.error(
            "The sign-in flow failed to authenticate. Received status",
            status,
        );
        return (
            <Container>
                <Glass>
                    <Txt
                        level={5}
                        emphasis
                        style={
                            {
                                // opacity: 0.8,
                            }
                        }>
                        {"We can't sign you in right now..."}
                    </Txt>
                    <Txt
                        level={7}
                        style={
                            {
                                // opacity: 0.8,
                            }
                        }>
                        Please try again. If the problem hangs around, you can
                        get in touch.
                    </Txt>
                    <Button
                        variant="secondary"
                        linkTo={redirectUrl.toString()}
                        // colour="alert"
                        style={{}}>
                        {"Try again"}
                    </Button>
                    <Button
                        variant="tertiary"
                        iconRight={null}
                        linkTo="mailto:support@mytos.bio"
                        style={{
                            opacity: 0.5,
                        }}>
                        {"Get in touch"}
                    </Button>
                </Glass>
            </Container>
        );
    }

    // Display the below as a fallback if there is an issue with redirect
    return (
        <Container>
            {manualRedirect ? (
                <Button
                    variant="primary"
                    linkTo={redirectUrl.toString()}
                    // colour="alert"
                    style={{}}>
                    {"Sign in"}
                </Button>
            ) : (
                <Skeleton width={350} height={200} />
            )}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Glass = styled.div`
    background: ${({ theme }) => theme.colours.orange[100]};
    backdrop-filter: blur(25px);

    // border with light to dark gradient
    border: 1px solid ${({ theme }) => theme.colours.orange[300]};
    border-radius: 10px;

    // dropshadow
    box-shadow: 0 0 10px rgba(20, 20, 20, 0.1);

    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px;
    max-width: 440px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        margin: 20px;
        padding: 20px;
    }
`;
