import { useCallback, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";

// TODO: consider replacing with `useSearchParams` from react-router-dom
/**
 *
 * Hook to get the query string as URLStringParams, and additional methods to
 * push or replace updates to the URLStringParams.
 *
 * @returns `{query, pushQueryString, replaceQueryString}` - The current query
 * and update methods
 */
export function useQueryString(): {
    query: URLSearchParams;
    pushQueryString: (newQuery: URLSearchParams) => void;
    replaceQueryString: (newQuery: URLSearchParams) => void;
} {
    const { search } = useLocation();
    const navigate = useNavigate();

    /**
     * Update the query string and push to history
     */
    const pushQueryString = useCallback(
        (newQuery: URLSearchParams) => {
            navigate({
                search: newQuery.toString(),
            });
        },
        [navigate],
    );

    /**
     * Update the query string but replace the history
     */
    const replaceQueryString = useCallback(
        (newQuery: URLSearchParams) => {
            navigate(
                {
                    search: newQuery.toString(),
                },
                { replace: true },
            );
        },
        [navigate],
    );

    const query = useMemo(() => new URLSearchParams(search), [search]);

    return { query, pushQueryString, replaceQueryString };
}
