import React, { ReactElement, ReactNode } from "react";

import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom"; // ! We do not use the official "Route" component

import { gql } from "__generated__/apollo";
import { log as componentsLog } from "components";
import { AppBar } from "components/AppBar";
import Button from "components/common/Button";
import { config } from "services/config";
import { useFeature } from "services/feature-flags";

import { SIDEBAR_WIDTH_PX, useSidebar } from "./AppBar/Sidebar";
import { DeviceStatusUpdates } from "./DeviceStatusUpdates";

const log = componentsLog.extend("AppBarWrapper");

interface AppBarWrapperProps {
    children?: ReactElement;
    [K: string]: unknown;
}

/**
 * Query for the current authenticated User. By design this only queries the
 * core attributes of the User and does not query many nested objects.
 */
const SERVER_STATUS_QUERY = gql(`
    query ServerStatus {
        status {
            ok
            message
        }
    }
`);

export function AppBarWrapper(props: AppBarWrapperProps): ReactElement {
    const location = useLocation();
    const theme = useTheme();
    const serialIsEnabled = useFeature("serial_page").enabled || config.isLocal;
    const isSerialPage = location.pathname === "/serial";
    const isDeveloperPage = location.pathname === "/developer";

    // Make a server status query where we at least don't expect any network
    // errors (otherwise this may be indicative of a larger problem)
    const { loading, error, data, refetch } = useQuery(SERVER_STATUS_QUERY, {
        fetchPolicy: "network-only",
    });
    log.debug("Server status response:", data);

    // base style
    const style: React.CSSProperties = {
        backgroundColor: theme.colours.neutral.white,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    };

    // if rendering the login page, this is the special app screen where we
    // actually don't want to have the app bar added
    if (["/login", "/signin", "/welcome"].includes(location.pathname)) {
        return <div style={style}>{props.children}</div>;
    }

    // if we get a network error, we show a special screen. the app kinda needs
    // internet y'know - unless we're using serial over USB (!!)
    if (
        error?.networkError &&
        !isDeveloperPage &&
        !isSerialPage &&
        serialIsEnabled
    ) {
        return (
            <UnableToReachServer
                retry={async () => {
                    await refetch();
                    window.location.reload();
                }}
                serialIsEnabled={serialIsEnabled}
            />
        );
    }

    // app is blank on initial loading...
    if (loading) {
        return <React.Fragment />;
    }

    // assuming no special cases, we should simply add the app bar in addition
    // to the children components that need to be rendered
    return (
        <div id="app-bar-wrapper-container" style={style}>
            <DeviceStatusUpdates />
            <AppBar />
            <Main>{props.children}</Main>
        </div>
    );
}

function Main({ children }: { children: ReactNode }) {
    const { sidebarIsOpen, sidebarIsTemporary } = useSidebar();
    const avoidSidebar = sidebarIsOpen && !sidebarIsTemporary;
    return (
        <MainContainer avoidSidebar={avoidSidebar}>{children}</MainContainer>
    );
}

const MainContainer = styled("div")<{
    avoidSidebar: boolean;
}>(({ theme, avoidSidebar }) => ({
    // these properties are to match the animation of the sidebar opening and closing
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(avoidSidebar && {
        marginLeft: `${SIDEBAR_WIDTH_PX}px`,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function UnableToReachServer(props: {
    retry: () => void;
    serialIsEnabled: boolean;
}): ReactElement {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%" }}>
            <p>Unable to reach server.</p>
            <p>Please check your network connection & refresh.</p>
            <p>If problem persists contact:</p>
            <br />
            <a href="mailto:support@mytos.bio">support@mytos.bio</a>
            <Button
                variant="secondary"
                style={{ margin: 24 }}
                onClick={() => props.retry()}>
                Retry
            </Button>
            {props.serialIsEnabled && (
                <Button
                    variant="secondary"
                    iconLeft="cable"
                    style={{ margin: 24 }}
                    linkTo="/serial">
                    Use Serial controls
                </Button>
            )}
        </Grid>
    );
}
