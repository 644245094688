import { gql } from "__generated__/apollo";
import {
    CultureSetupQueryDataFragment,
    CultureState,
} from "__generated__/apollo/graphql";

import { log as parentLog } from "../../log";

export const log = parentLog.extend("CultureSetupState");

export enum CultureSetupPhase {
    NoCultureLoaded = "no-culture-loaded",
    WetTestReady = "wet-test-ready",
    WetTestStarted = "wet-test-running",
    WetTestComplete = "wet-test-complete",
    PrimaryCultureLoaded = "primary-culture-loaded",
}

/**
 * Local state of culture creation flow
 */
export enum SetupStep {
    Inactive = "inactive",
    AutomatedChecks = "automated-checks",
    SelectProtocol = "select-protocol",
    DrySetup = "dry-setup",
    DryTests = "dry-tests",
    WetTestsSetup = "wet-test-setup",
    WetTests = "wet-tests",
    CultureWetSetup = "culture-wet-setup",
    CellLineInfo = "cell-line-info",
    CellPreparation = "cell-preparation",
    CultureMetadata = "culture-metadata",
}

export function getSetupEntryStep(
    cultureSetupPhase: CultureSetupPhase,
    cultureUploadType: CultureUploadType,
): SetupStep {
    if (
        cultureSetupPhase === CultureSetupPhase.NoCultureLoaded ||
        cultureSetupPhase === CultureSetupPhase.PrimaryCultureLoaded
    ) {
        if (cultureUploadType === CultureUploadType.Development) {
            return SetupStep.SelectProtocol;
        } else {
            return SetupStep.AutomatedChecks;
        }
    }
    if (cultureSetupPhase === CultureSetupPhase.WetTestReady) {
        return SetupStep.WetTestsSetup;
    }
    if (cultureSetupPhase === CultureSetupPhase.WetTestStarted) {
        return SetupStep.WetTests;
    }
    if (cultureSetupPhase === CultureSetupPhase.WetTestComplete) {
        return SetupStep.CultureWetSetup;
    }
    // Typescript still seems to want an ending return statement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const exhaustiveCheck: never = cultureSetupPhase;
    return SetupStep.Inactive;
}

export enum CultureUploadType {
    ServerOwned = "server-owned",
    Development = "development",
}

export interface CultureSetupState {
    /**
     * The current local upload type - not queried from the server
     */
    cultureUploadType: CultureUploadType;
    /**
     * Information about the protocol associated with the culture currently
     * loaded on the device, if one is loaded
     */
    wetTestProtocol?: {
        id: string;
    };
    /**
     * Information about the primary protocol associated with the wet test
     * culture currently loaded on the device, if one is loaded
     */
    primaryProtocol?: {
        id: string;
        name: string | null;
        canCollectInitialSeedData: boolean;
    };
    /**
     * Information about the culture currently loaded on the device, if one is
     * loaded
     */
    culture?: {
        id: string;
        name?: string;
        state?: CultureState;
        isWetTest?: boolean;
        protocolId?: string;
        primaryProtocolId?: string;
    };
}

export function getCultureSetupPhase({
    culture,
}: CultureSetupState): CultureSetupPhase {
    if (!culture) {
        return CultureSetupPhase.NoCultureLoaded;
    }
    if (culture.isWetTest) {
        if (culture.state === CultureState.Complete) {
            return CultureSetupPhase.WetTestComplete;
        }
        if (
            culture.state === CultureState.Loading ||
            culture.state === CultureState.Ready
        ) {
            return CultureSetupPhase.WetTestReady;
        }
        return CultureSetupPhase.WetTestStarted;
    }
    return CultureSetupPhase.PrimaryCultureLoaded;
}

export const cultureSetupQueryDataFragment = gql(`
    fragment CultureSetupQueryData on Culture {
        id
        name
        state
        isWetTestCulture
        wetTestPrimaryProtocol {
            id
            name
            initialSeedProcedureData {
                flaskSeedGroups {
                    doseMl
                    flaskNumbers
                    flaskSizeCm2
                }
                seedVesselCellSuspensionMl
            }
        }
        protocol {
            id
        }
    }
`);

export function setQueriedStateAdapter(
    culture?: CultureSetupQueryDataFragment | null,
): Partial<CultureSetupState> {
    return {
        culture: culture
            ? {
                  id: culture.id,
                  protocolId: culture?.protocol?.id,
                  primaryProtocolId: culture?.wetTestPrimaryProtocol?.id,
                  isWetTest: culture.isWetTestCulture ?? undefined,
                  name: culture.name ?? undefined,
                  state: culture.state ?? undefined,
              }
            : undefined,
        wetTestProtocol:
            culture?.isWetTestCulture && culture.protocol?.id
                ? { id: culture.protocol?.id }
                : undefined,
        primaryProtocol:
            culture?.isWetTestCulture && culture.wetTestPrimaryProtocol?.id
                ? {
                      id: culture.wetTestPrimaryProtocol?.id,
                      canCollectInitialSeedData: Boolean(
                          culture.wetTestPrimaryProtocol
                              .initialSeedProcedureData,
                      ),
                      name: culture.wetTestPrimaryProtocol.name,
                  }
                : undefined,
    };
}
