import { ReactElement } from "react";

import { ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";

import { useIsMobile } from "services/hooks/window";

import Icon from "./Icon";
import Txt from "./Text";

interface NavigationCrumb {
    /**
     * Text displayed
     */
    text: string;
    /**
     * Path to link to if crumb is interactable
     */
    linkTo?: string;
    /**
     * Controls whether text is replaced by a loading skeleton
     */
    skeleton?: boolean;
}

export interface HeaderBreadcrumbProps {
    /**
     * Array of items to be displayed in the navigation trail
     */
    crumbs: NavigationCrumb[];
}

export function HeaderBreadcrumb(props: HeaderBreadcrumbProps): ReactElement {
    const { crumbs } = props;
    const isSmScreen = useIsMobile();
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: 2,
            }}>
            {crumbs.map((crumb, i) => {
                const { text, linkTo, skeleton } = crumb;
                const firstItem = i === 0;
                const lastItem = crumbs.length === i + 1;
                return (
                    <div
                        key={i}
                        style={{ display: "flex", alignItems: "center" }}>
                        <ButtonBase
                            disabled={!linkTo}
                            component={linkTo ? Link : "button"}
                            to={linkTo}
                            sx={{
                                "display": "flex",
                                "alignItems": "center",
                                "gap": 3,
                                "ml": firstItem ? -2 : undefined, // ! temporary workaround for DeviceHeaderContent
                                "py": 1,
                                "px": 2,
                                "borderRadius": 1,
                                "&:hover": {
                                    backgroundColor: theme =>
                                        theme.colours.neutral[300],
                                    color: "inherit",
                                },
                            }}>
                            <Txt
                                emphasis
                                level={isSmScreen ? 6 : 5}
                                skeleton={skeleton ? 15 : false}
                                style={{ whiteSpace: "nowrap" }}>
                                {text}
                            </Txt>
                        </ButtonBase>
                        {!lastItem && <Icon name="chevron-right" size="md" />}
                    </div>
                );
            })}
        </div>
    );
}
