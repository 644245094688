import React from "react";

import Button from "@mui/material/Button";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { capitalize } from "lodash";
import {
    Id,
    toast as toastify,
    TypeOptions,
    ToastOptions,
} from "react-toastify";

import Icon from "components/common/Icon";

export type ToastId = Id;

export type Toast = {
    info: (
        message: string,
        options?: {
            toastId?: string;
            pauseOnFocusLoss?: boolean;
            autoClose?: number | false;
        },
    ) => Id;
    error: (
        message: string,
        options?: {
            toastId?: string;
            pauseOnFocusLoss?: boolean;
            autoClose?: number | false;
        },
    ) => Id;
    success: (
        message: string,
        options?: {
            toastId?: string;
            pauseOnFocusLoss?: boolean;
            autoClose?: number | false;
        },
    ) => Id;
    loading: typeof toastify.loading;
    update: typeof toastify.update;
    action: (options: {
        message: string;
        /** Text of the action button */
        actionLabel: string;
        /** Text of the action button after being pressed */
        actionLabelPressed: string;
        onClick: () => void;
        /** Manually defined ID to prevent duplicates */
        alertId?: string;
        /** Shows a dismiss button on the toast. Defaults `true` */
        dismiss?: boolean;
    }) => Id;
    dismiss: (ids: Id[]) => void;
    isActive: (id: Id) => boolean;
};

interface ToastifyMessages {
    loading: string;
    success: string;
    failed: string;
}

type ToastifyAction<R> = (
    message: ToastifyMessages,
    action: () => Promise<R | Error>,
) => Promise<R | Error>;

export type UseToasts<R = unknown> = {
    toast: Toast;
    /**
     * A function that wraps a promise with a loading toast, and updates the toast
     * to success of failed based on the promise's resolution.
     */
    toastifyAction: ToastifyAction<R>;
};

type ToastIcon = Exclude<ToastOptions["icon"], undefined>;

const toastTypeIcon: { [T in TypeOptions]: ToastIcon } = {
    default: false,
    info: false,
    success: <Icon name="checkmark" defaultColour style={{ marginRight: 8 }} />,
    warning: false,
    error: <Icon name="alert-triangle" style={{ marginRight: 8 }} />,
};

export function useToasts(): UseToasts {
    const theme = useTheme();

    const toast: Toast = () => {
        //
    };

    toast.info = (message, { autoClose, ...options } = {}) => {
        return toastify.info(message, {
            autoClose: autoClose ?? 10_000,
            icon: false,
            ...options,
        }) as string;
    };

    toast.error = (message, { autoClose, ...options } = {}) => {
        return toastify.error(message, {
            autoClose: autoClose ?? 15_000,
            icon: toastTypeIcon["error"],
            ...options,
        }) as string;
    };

    toast.success = (message, { autoClose, ...options } = {}) => {
        return toastify.success(message, {
            autoClose: autoClose ?? 5_000,
            icon: toastTypeIcon["success"],
            ...options,
        }) as string;
    };

    toast.loading = toastify.loading;

    toast.update = (message, options) => {
        return toastify.update(message, {
            autoClose: options?.autoClose ?? 5_000,
            icon: toastTypeIcon[options?.type ?? "warning"],
            ...options,
        });
    };

    toast.action = ({
        message,
        actionLabel,
        actionLabelPressed,
        onClick,
        alertId,
        dismiss = true,
    }) => {
        let toastId: string | undefined = undefined;

        const handleAction: React.MouseEventHandler<HTMLButtonElement> = e => {
            e.currentTarget.disabled = true;
            e.currentTarget.textContent = actionLabelPressed;
            onClick();
        };
        const handleDismiss = () => toastify.dismiss(toastId);

        const buttonSx: SxProps<Theme> = {
            "color": theme => theme.colours.neutral[800],
            "flex": "1 1",
            "border": "none",
            "backgroundColor": "transparent",
            "minHeight": 28,
            "minWidth": 90,
            "borderLeft": theme => "1px solid " + theme.colours.neutral[500],
            "padding": "8px 12px",
            "textTransform": "none",
            "borderRadius": 0,
            "&:hover": {
                backgroundColor: "#0000000A",
            },
            "&:nth-of-type(2)": {
                borderTop: theme => "1px solid " + theme.colours.neutral[500],
            },
            "&:disabled": {
                color: theme => theme.colours.neutral[500],
            },
        };

        toastId = toastify(
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1, margin: "12px 16px" }}>
                    {message}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                        sx={buttonSx}
                        onClick={handleAction}
                        // className={classes.actionToastButton}
                    >
                        {actionLabel}
                    </Button>
                    {dismiss && (
                        <Button sx={buttonSx} onClick={handleDismiss}>
                            Ignore
                        </Button>
                    )}
                </div>
            </div>,
            {
                autoClose: false,
                closeButton: false,
                style: {
                    border: "1px solid",
                    borderColor: theme.colours.neutral[500],
                    backgroundColor: theme.colours.neutral[100],
                },
                bodyStyle: {
                    margin: 0,
                    padding: 0,
                    color: theme.colours.neutral[900],
                },
                toastId: alertId,
            },
        ) as string;

        return toastId;
    };

    toast.dismiss = ids => {
        ids.map(id => toastify.dismiss(id));
    };

    toast.isActive = id => toastify.isActive(id);

    return {
        toast,
        toastifyAction: function <R>(
            message: ToastifyMessages,
            action: () => Promise<R | Error>,
        ) {
            return toastifyAction(message, action, toast);
        },
    };
}

async function toastifyAction<R>(
    { loading, success, failed }: ToastifyMessages,
    action: () => Promise<R | Error>,
    toast: Toast,
): Promise<R | Error | unknown> {
    const toastId = toast.loading(loading);

    try {
        const output = await action();
        if (output instanceof Error) {
            throw output;
        }
        toast.update(toastId, {
            render: success,
            type: "success",
            isLoading: false,
            autoClose: 3_000,
        });

        return output;
    } catch (err) {
        toast.update(toastId, {
            render: failed,
            type: "error",
            isLoading: false,
            autoClose: 6_000,
        });

        return err;
    }
}

/**
 * Utility function to generate toast messages for a given entity and verb.
 *
 * This is used to generate messages in a consistent format.
 */
export function getToastifyMessages({
    entity,
    verb: { past, gerund },
}: {
    entity: string;
    verb: {
        /**
         * The past tense of the verb. E.g. "rescheduled" for "reschedule"
         */
        past: string;
        /**
         * The gerund form of the verb. E.g. "rescheduling" for "reschedule"
         */
        gerund: string;
    };
}): ToastifyMessages {
    const capitalizedEntity = capitalize(entity);

    return {
        loading: `${capitalize(gerund)} ${capitalizedEntity}`,
        success: `Successfully ${past.toLocaleLowerCase()} ${capitalizedEntity}`,
        failed: `Error ${gerund.toLocaleLowerCase()} ${capitalizedEntity}`,
    };
}
