import { useLazyQuery, useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { EditFlaskInput } from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from "./log";

const log = parentLog.extend("useUpdateFlask");

const EDIT_FLASK_MUTATION = gql(`
    mutation EditFlask($editFlaskInput: EditFlaskInput!) {
        editFlask(input: $editFlaskInput) {
            ok
            message
        }
    }
`);

const REFRESH_EDITED_FLASK_QUERY = gql(`
    query RefreshEditedFlask($flaskId: String!) {
        flask(id: $flaskId) {
            id
            name
            liquidVolumeMl
            totalVolumeMl
        }
    }
`);

/**
 * Returns true is flask successfully updated
 */
export function useUpdateFlask() {
    const [editFlask] = useMutation(EDIT_FLASK_MUTATION);
    const [refreshEditedFlask, { stopPolling }] = useLazyQuery(
        REFRESH_EDITED_FLASK_QUERY,
        {
            pollInterval: 1_000,
        },
    );

    const { toast } = useToasts();

    const handleEditFlask = async (editFlaskInput: EditFlaskInput) => {
        const toastId = toast.loading("Editing flask");
        try {
            const output = await editFlask({
                variables: {
                    editFlaskInput,
                },
            });

            if (!output.data?.editFlask.ok) {
                throw new Error(output.data?.editFlask.message);
            }

            await new Promise<void>((resolve, reject) => {
                const timeoutId = setTimeout(() => {
                    reject(new Error("Data was not updated in 10 seconds"));
                }, 10_000);

                void refreshEditedFlask({
                    variables: { flaskId: editFlaskInput.flaskId },
                    onCompleted: data => {
                        if (
                            data.flask?.liquidVolumeMl ===
                                editFlaskInput.liquidVolume &&
                            data.flask?.totalVolumeMl ===
                                editFlaskInput.totalVolume
                        ) {
                            clearTimeout(timeoutId);
                            stopPolling();
                            resolve();
                        }
                    },
                });
            });

            toast.update(toastId, {
                render: "Flask edited successfully",
                type: "success",
                isLoading: false,
            });
            return true;
        } catch (error) {
            stopPolling();
            log.error(error);
            toast.update(toastId, {
                render: "Failed to edit flask",
                type: "error",
                isLoading: false,
            });
            return false;
        }
    };

    return handleEditFlask;
}
