import { useState } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { CreateProtocolV2Mutation } from "__generated__/apollo/graphql";
import { ModalContent } from "components/common/Modal/Modal";
import { useToasts } from "components/common/toasts/useToasts";
import { config } from "services/config";
import { useFeature } from "services/feature-flags";

import { CultureSetupState } from "../culture-setup-state/culture-setup-state";
import { log as parentLog } from "../log";
import { PROTOCOL_STARTING_BOTTLES_QUERY } from "../ReviewBottles/ReviewBottlesView";
import { SelectProtocolView } from "../SelectProtocol/SelectProtocolView";

export const log = parentLog.extend("SelectProtocolModalContent");

const CREATE_PROTOCOL_MUTATION = gql(`
    mutation CreateProtocolV2($input: CreateProtocolInput!) {
        createProtocol(input: $input) {
            ok
            message
            protocol {
                id
                name
                initialCoatingTimeMinutes
                initialSeedProcedureData {
                    flaskSeedGroups {
                        doseMl
                        flaskNumbers
                        flaskSizeCm2
                    }
                    seedVesselCellSuspensionMl
                }
                startingBottles {
                    liquidVolume
                    reagentName
                    totalVolume
                }
            }
            wetTestProtocol {
                id
                name
                initialCoatingTimeMinutes
                initialSeedProcedureData {
                    flaskSeedGroups {
                        doseMl
                        flaskNumbers
                        flaskSizeCm2
                    }
                    seedVesselCellSuspensionMl
                }
                startingBottles {
                    liquidVolume
                    reagentName
                    totalVolume
                }
            }
        }
    }
`);

export function SelectProtocolModalContent({
    onBackClick,
    onProtocolUpload,
}: {
    onBackClick: () => void;
    onProtocolUpload: (
        arg: Pick<CultureSetupState, "primaryProtocol" | "wetTestProtocol">,
    ) => void;
}) {
    const [protocol, setProtocol] = useState<unknown | null>(null);
    const { toast } = useToasts();

    const isWetTestsFeatureEnabled =
        useFeature("wet_tests_beta").enabled || config.isLocal;

    const [createProtocolMutation, { loading }] =
        useMutation<CreateProtocolV2Mutation>(CREATE_PROTOCOL_MUTATION, {
            update(cache, { data }) {
                // Apollo does not update IDs for multiple entities in one go.
                if (data?.createProtocol.protocol) {
                    cache.writeQuery({
                        query: PROTOCOL_STARTING_BOTTLES_QUERY,
                        data: { protocol: data?.createProtocol?.protocol },
                        variables: {
                            protocolId: data?.createProtocol?.protocol.id,
                        },
                    });
                }
                if (data?.createProtocol.wetTestProtocol) {
                    cache.writeQuery({
                        query: PROTOCOL_STARTING_BOTTLES_QUERY,
                        data: {
                            protocol: data?.createProtocol?.wetTestProtocol,
                        },
                        variables: {
                            protocolId:
                                data?.createProtocol?.wetTestProtocol.id,
                        },
                    });
                }
            },
        });

    const handleCreateProtocol = async (protocolInput: unknown) => {
        const toastId = toast.loading("Checking protocol...");

        log.debug("Attempting to create protocol");

        const { data } = await createProtocolMutation({
            variables: { input: { protocol: protocolInput } },
        });

        if (!data?.createProtocol || !data?.createProtocol.ok) {
            toast.update(toastId, {
                render: `Protocol failed checks. Please check it is using an up-to-date format.`,
                type: "error",
                isLoading: false,
            });
            return new Error(data?.createProtocol.message ?? "Unknown error");
        }

        const { protocol, wetTestProtocol } = data.createProtocol;
        toast.dismiss([toastId]);

        if (!protocol) {
            toast.update(toastId, {
                render: `Protocol failed checks. No protocol returned from server.`,
                type: "error",
                isLoading: false,
            });
            return new Error("No protocol returned from server");
        }

        onProtocolUpload({
            primaryProtocol: {
                id: protocol.id,
                name: protocol.name,
                canCollectInitialSeedData: Boolean(
                    protocol.initialSeedProcedureData,
                ),
            },
            wetTestProtocol:
                wetTestProtocol && isWetTestsFeatureEnabled
                    ? { id: wetTestProtocol.id }
                    : undefined,
        });
    };

    return (
        <ModalContent
            title="Select protocol"
            buttonLeft={{
                label: "Back",
                onClick: onBackClick,
            }}
            buttonRight={{
                label: "Continue",
                disabled: loading || !protocol,
                onClick: protocol
                    ? () => handleCreateProtocol(protocol)
                    : undefined,
            }}>
            <SelectProtocolView onSelectProtocol={setProtocol} />
        </ModalContent>
    );
}
