import { ReactElement } from "react";

import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

import { ProcedureState } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";

import { ConfirmationRequiredIcon } from "../StateIcons";

import { log as parentLog } from "./log";

export const log = parentLog.extend("ProcedureStateIcon");

export type ProcedureStateIconProps = {
    state: ProcedureState | null;
    needsConfirmation: boolean | null;
    hasSimulationError?: boolean;
};

const IconFrame = styled.div`
    box-sizing: border-box;

    border: 1px solid ${({ theme }) => theme.colours.neutral[300]};
    border-radius: 16px;

    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
`;

const CompleteState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.brandGreen[500]};
    color: ${({ theme }) => theme.colours.neutral.white};
`;

const FailedState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.neutral.white};
    color: ${({ theme }) => theme.colours.red[500]};
    border: 1px solid ${({ theme }) => theme.colours.red[500]};
`;

const RunningState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.brandBlue[600]};
    padding: 1px;
`;

const PausedState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.neutral.white};
    color: ${({ theme }) => theme.colours.orange[500]};
`;

const IgnoredState = styled(IconFrame)`
    background-color: ${({ theme }) => theme.colours.neutral.white};
    color: ${({ theme }) => theme.colours.neutral[500]};
`;

const WhiteBuffer = styled.div`
    border-radius: 20px;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colours.neutral.white};
`;

const SmallGreyCircle = styled.div`
    border-radius: 20px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${({ theme }) => theme.colours.neutral[300]};
`;
export function ProcedureStateIcon(
    props: ProcedureStateIconProps,
): ReactElement {
    const theme = useTheme();

    // TODO (MYT-5359) - Use StatusIcon

    const { state, needsConfirmation } = props;

    if (needsConfirmation) {
        return <ConfirmationRequiredIcon />;
    }

    if (props.hasSimulationError) {
        return (
            <FailedState title="Simulation failure">
                <Icon name="cross" size="sm" />
            </FailedState>
        );
    }

    switch (state) {
        case ProcedureState.Planned:
            return (
                <IconFrame title={state}>
                    <WhiteBuffer>
                        <SmallGreyCircle />
                    </WhiteBuffer>
                </IconFrame>
            );
        case ProcedureState.Cancelled:
        case ProcedureState.Ignored:
        case ProcedureState.Removed:
            return (
                <IgnoredState title={state}>
                    <Icon name="disable" size="md" />
                </IgnoredState>
            );
        case ProcedureState.Cancelling:
        case ProcedureState.Pausing:
        case ProcedureState.Running:
            return (
                <RunningState title={state}>
                    <RotatingLines
                        width="18"
                        strokeColor={theme.colours.neutral.white}
                        strokeWidth="5"
                        animationDuration="1"
                    />
                </RunningState>
            );
        case ProcedureState.Failed:
            return (
                <FailedState title={state}>
                    <Icon name="cross" size="sm" />
                </FailedState>
            );
        case ProcedureState.Paused:
            return (
                <PausedState title={state}>
                    <Icon name="pause-circle" size="md" />
                </PausedState>
            );
        case ProcedureState.Complete:
            return (
                <CompleteState title={state}>
                    <Icon
                        name="check"
                        size="sm"
                        sx={{ transform: "scale(80%)" }}
                    />
                </CompleteState>
            );
        case ProcedureState.Stalled:
        case ProcedureState.Unknown:
        case null:
            return (
                <IconFrame title={state ?? ""}>
                    <WhiteBuffer>
                        <SmallGreyCircle />
                    </WhiteBuffer>
                </IconFrame>
            );
    }
}
