import { memo, ReactElement, useCallback, useState } from "react";

import { css } from "@emotion/react";
import { Collapse, useTheme } from "@mui/material";
import { darken } from "polished";

import { CultureState, StepState } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Icon from "components/common/Icon";
import Txt from "components/common/Text";
import { dayjs, isToday } from "services/date";
import { LegacyStep } from "services/hooks/useCultureScheduleLegacy";
import { useDeviceCultureState } from "services/hooks/useCultureState";
import { useDeviceRole } from "services/hooks/useDeviceRole";
import { useRenderTimer } from "services/hooks/useRenderTimer";
import { useScrollIntoView } from "services/hooks/useScrollIntoView";
import { useWindowEventListener } from "services/hooks/useWindowEventListener";
import { deepCompareProps } from "services/react-utils";

import { realisticTimePlanned, ScheduleEvents } from "../CultureSchedule";
import { log as parentLog } from "../log";

import {
    calculateRerenderSecondsInterval,
    useStepSecondaryText,
} from "./step-render-utils";
import { StepConfirmation } from "./StepConfirmation";
import { StepControls } from "./StepControls";
import { StepInvocations } from "./StepInvocations";
import { StepStateIcon } from "./StepStateIcon";

export const log = parentLog.extend("StepItem");

export type StepItemProps = {
    deviceId: string;
    index: number;
    step: LegacyStep;
    isNextStep: boolean;
};

const MemoStepItem = memo(StepItem, deepCompareProps(log));
export { MemoStepItem as StepItem };

function StepItem(props: StepItemProps): ReactElement {
    const theme = useTheme();
    const { ref, scroll } = useScrollIntoView<HTMLDivElement>({
        enabled: props.isNextStep,
    });

    const scrollHandler = useCallback(() => {
        if (props.isNextStep) scroll();
    }, [props.isNextStep, scroll]);

    useWindowEventListener({
        id: ScheduleEvents.ScrollToNext,
        handler: scrollHandler,
    });
    const { data } = useDeviceCultureState(props.deviceId);
    const cultureState = data?.device?.culture?.state;

    const cultureIsPaused =
        cultureState &&
        [CultureState.Paused, CultureState.Pausing].includes(cultureState);
    const stepIsRunning = props.step.state === StepState.Running;
    const displayedStepState =
        cultureIsPaused && stepIsRunning ? StepState.Paused : props.step.state;

    return (
        <div ref={ref} style={{ position: "relative" }}>
            <div style={{ position: "relative", zIndex: 1 }}>
                <StepConfirmation {...props} />
            </div>
            <div
                css={css`
                    margin: 4px 0;
                    display: flex;
                    align-items: flex-start;
                    gap: 18px;

                    /** This is the visual timeline */
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 9px;
                        background-color: ${theme.colours.neutral[300]};
                        width: 2px;
                    }
                `}>
                <div
                    style={{
                        zIndex: 1, // render above the timeline
                        height: 58,
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <StepStateIcon state={displayedStepState} />
                </div>
                <StepItemCard {...props} />
            </div>
        </div>
    );
}

function StepItemCard(props: StepItemProps): ReactElement {
    const { step } = props;
    const theme = useTheme();
    const [collapsed, setCollapsed] = useState(() => {
        const rtp = realisticTimePlanned(
            step.timePlanned,
            step.timeStarted,
            step.state,
        );
        if (rtp && isToday(rtp) && step.state === StepState.Running) {
            log.debug("Step is suitable for intialising expanded", step);
            return false;
        }
        return true;
    });

    useWindowEventListener({
        id: ScheduleEvents.Collapse,
        handler: () => setCollapsed(true),
    });
    useWindowEventListener({
        id: ScheduleEvents.Expand,
        handler: () => setCollapsed(false),
    });

    const stepNum = props.index + 1;
    const errorMessage = step.errorMessage;

    const backgroundColour = theme.colours.neutral[100];
    return (
        <div
            id={`step-item-card-${props.index}-${step.id}`}
            onClick={() => setCollapsed(!collapsed)}
            css={css`
                cursor: pointer;
                flex-grow: 1;
                border: 1px solid ${theme.colours.neutral[300]};
                border-radius: 4px;
                padding: 16px;
                padding-left: 40px; // override for step number label
                ${theme.breakpoints.down("sm")} {
                    padding-left: 16px; // override for step number label
                    padding-top: 8px;
                }
                background-color: ${backgroundColour};
                display: flex;
                flex-direction: column;
                align-items: stretch;
                position: relative;
                &:hover {
                    background-color: ${darken(0.01)(backgroundColour)};
                }
            `}>
            <StepNumberLabel stepNum={stepNum} />
            <StepHeader
                deviceId={props.deviceId}
                step={step}
                collapsed={collapsed}
            />

            <Collapse
                in={!collapsed}
                style={{ width: "100%" }}
                mountOnEnter
                unmountOnExit>
                {errorMessage && (
                    <Callout
                        variant="error"
                        message={errorMessage}
                        style={{ margin: "8px 0" }}
                    />
                )}
                <StepInvocations
                    deviceId={props.deviceId}
                    stepId={step.id}
                    isActive={Boolean(
                        props.isNextStep ||
                            (step.state &&
                                [StepState.Running].includes(step.state)),
                    )}
                    numInvocations={step.invocations?.totalCount ?? 1}
                />
            </Collapse>
        </div>
    );
}

function StepNumberLabel(props: { stepNum: number }) {
    const theme = useTheme();
    return (
        <div // step number label
            css={css`
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid ${theme.colours.neutral[300]};
                border-left: none;
                border-top: none;
                border-radius: 0 0 4px 0;
                color: ${theme.colours.neutral[700]};
                background-color: ${theme.colours.neutral[200]};
            `}>
            <Txt font="secondary" level={10}>
                {props.stepNum}
            </Txt>
        </div>
    );
}

function StepHeader(props: {
    deviceId: string;
    step: LegacyStep;
    collapsed: boolean;
}) {
    const { step } = props;

    const msUntil = dayjs(step.timePlanned).diff();
    const secondsUntilRerender = calculateRerenderSecondsInterval(msUntil);
    log.debug("Rerendering header in", secondsUntilRerender, "seconds", {
        timePlanned: step.timePlanned,
    });
    useRenderTimer({ seconds: secondsUntilRerender });

    const theme = useTheme();
    const { canOperate } = useDeviceRole(props.deviceId);
    const breaksize = "xs";
    const totalSubItems = step.invocations?.totalCount;
    const totalSubitemsLabel =
        totalSubItems === 0
            ? "no operations"
            : totalSubItems === 1
              ? "1 operation"
              : `${totalSubItems} operations`;

    const dimmedStates: (StepState | null)[] = [
        StepState.Removed,
        StepState.Ignored,
    ];
    const opacity: number = dimmedStates.includes(props.step.state) ? 0.5 : 1;
    const primaryText: string = step.name ?? "Unnamed step";
    const secondaryText = useStepSecondaryText(step);

    return (
        <div // step header
            css={css`
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 8px;
                ${theme.breakpoints.down(breaksize)} {
                    flex-direction: column-reverse;
                }
            `}>
            <div
                css={css`
                    opacity: ${opacity};
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    column-gap: 24px;
                `}>
                <Txt font="secondary" level={7} display="inline">
                    {primaryText}
                </Txt>
                <Txt
                    font="secondary"
                    level={9}
                    display="inline"
                    style={{ color: theme.colours.neutral[700] }}>
                    {secondaryText}
                </Txt>
            </div>
            <div
                css={css`
                    ${theme.breakpoints.down(breaksize)} {
                        width: 100%;
                    }
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-wrap: nowrap;
                    gap: 16px;
                    color: ${theme.colours.neutral[500]};
                `}>
                <Txt
                    font="secondary"
                    level={8}
                    style={{ whiteSpace: "nowrap" }}
                    align="right">
                    {totalSubitemsLabel}
                </Txt>
                <Icon
                    name={props.collapsed ? "chevron-down" : "chevron-up"}
                    size="lg"
                />
                {canOperate && (
                    <StepControls deviceId={props.deviceId} step={props.step} />
                )}
            </div>
        </div>
    );
}
