import { ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { StepControl, StepState } from "__generated__/apollo/graphql";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { useToasts } from "components/common/toasts/useToasts";
import { Step } from "services/hooks/useCultureProcedureSteps";
import { CULTURE_SCHEDULE_QUERY } from "services/hooks/useCultureSchedule";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { Confirmed, NeedsConfirmation, Unconfirmable } from "../Confirmation";

import { log as parentLog } from "./log";

export const log = parentLog.extend("ConfirmStepDialog");

function getStepConfirmationStatus(step: Step) {
    const confirmationRequired = step.confirmationRequired;
    const confirmed = step.confirmed;
    const stepPlanned = step.state === StepState.Planned;

    if (confirmationRequired && stepPlanned && !confirmed) {
        return "needsConfirmation";
    }

    if (confirmed && stepPlanned) {
        return "canBeUnconfirmed";
    }

    if (confirmed) {
        return "confirmed";
    }
}

interface Props {
    step: Step;
    deviceId: string;
    isNextStep: boolean;
    children: ReactElement;
}

export default function ConfirmableStep({
    step,
    deviceId,
    isNextStep,
    children,
}: Props): ReactElement {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [unConfirmDialogOpen, setUnconfirmDialogOpen] = useState(false);
    const [executing, setExecuting] = useState(false);
    const { canOperate } = useDeviceRole(deviceId);

    const { toast } = useToasts();

    const [controlStep] = useMutation(CONFIRM_STEP_MUTATION, {
        refetchQueries: [CULTURE_SCHEDULE_QUERY],
    });
    const executeAction = async (type: "confirm" | "unconfirm") => {
        const isConfirm = type === "confirm";
        setExecuting(true);
        const toastId = toast.loading(
            `${isConfirm ? "Confirming" : "Unconfirming"} step...`,
            {
                autoClose: false,
            },
        );
        try {
            const output = await controlStep({
                variables: {
                    input: {
                        control: isConfirm
                            ? StepControl.Confirm
                            : StepControl.Unconfirm,
                        deviceId,
                        stepId: step.id,
                    },
                },
            });

            if (output.data?.controlStep.ok) {
                toast.update(toastId, {
                    render: `Step ${isConfirm ? "confirmed" : "unconfirmed"} `,
                    type: "success",
                    isLoading: false,
                });
                const closeDialog = () =>
                    isConfirm
                        ? setConfirmDialogOpen(false)
                        : setUnconfirmDialogOpen(false);
                closeDialog();
            } else {
                throw new Error(output.data?.controlStep.message);
            }
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                render: `Failed to ${isConfirm ? "confirm" : "unconfirm"} step`,
                type: "error",
                isLoading: false,
            });
        } finally {
            setExecuting(false);
        }
    };

    const onButtonClick = isNextStep
        ? (type: "confirm" | "unconfirm") => executeAction(type)
        : (type: "confirm" | "unconfirm") =>
              type === "confirm"
                  ? setConfirmDialogOpen(true)
                  : setUnconfirmDialogOpen(true);

    const confirmDisabled = !canOperate || executing;
    const tooltipMessage = canOperate
        ? ""
        : "You do not have permission to control the device. Contact an admin to change your role.";

    const commonDialogProps = {
        variant: "warning" as const,
        primaryButtonDisabled: confirmDisabled,
        primaryButtonText: "Confirm",
        secondaryButtonText: "Cancel",
    };

    const confirmationStatus = getStepConfirmationStatus(step);

    if (confirmationStatus === "needsConfirmation") {
        return (
            <NeedsConfirmation
                confirmationText={step.confirmationText}
                onConfirmClick={() => onButtonClick("confirm")}
                disabled={confirmDisabled}
                buttonTooltip={tooltipMessage}>
                {children}
                <ConfirmationDialog
                    {...commonDialogProps}
                    open={confirmDialogOpen}
                    onSecondaryAction={() => setConfirmDialogOpen(false)}
                    onDismiss={() => setConfirmDialogOpen(false)}
                    onPrimaryAction={() => executeAction("confirm")}
                    title="Confirm the Step"
                    body={
                        <>
                            <strong>
                                <em>{step.name}</em>
                            </strong>{" "}
                            is waiting for confirmation. Once confirmed, the
                            Device will automatically run this step at the
                            scheduled time.
                        </>
                    }
                />
            </NeedsConfirmation>
        );
    }

    if (confirmationStatus === "canBeUnconfirmed") {
        return (
            <Unconfirmable
                confirmationText={step.confirmationText}
                confirmedBy={step.confirmedBy}
                onUnconfirmClick={() => onButtonClick("unconfirm")}
                disabled={confirmDisabled}
                buttonTooltip={tooltipMessage}>
                {children}
                <ConfirmationDialog
                    {...commonDialogProps}
                    title="Undo the Step confirmation"
                    open={unConfirmDialogOpen}
                    onSecondaryAction={() => setUnconfirmDialogOpen(false)}
                    onDismiss={() => setUnconfirmDialogOpen(false)}
                    onPrimaryAction={() => executeAction("unconfirm")}
                    body={
                        <>
                            <strong>
                                <em>{step.name}</em>
                            </strong>{" "}
                            will require confirmation again before it is run.
                        </>
                    }
                />
            </Unconfirmable>
        );
    }

    return (
        <Confirmed
            confirmationText={step.confirmationText}
            confirmedBy={step.confirmedBy}>
            {children}
        </Confirmed>
    );
}

const CONFIRM_STEP_MUTATION = gql(`
    mutation ConfirmStep($input: ControlStepInput!) {
        controlStep(input: $input) {
            ok
            message
            step {
                id
                name
                state
                confirmationRequired
                waitingForConfirmation
                confirmed
                timeStarted
            }
        }
    }
`);
