import { ReactElement } from "react";

import styled from "@emotion/styled";

import Callout from "components/common/Callout";
import { Card } from "components/common/Card";
import StatusBadge from "components/common/StatusBadge";
import Txt from "components/common/Text";
import { TextLabel } from "components/common/TextLabel";
import {
    CultureContainersDetails,
    useCultureContainers,
} from "services/hooks/useCultureContainers";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { log as parentLog } from "../log";

import { BottleTiles } from "./BottleTiles";
import { FlaskTiles } from "./FlaskTiles";

export const log = parentLog.extend("ContainersCard");

export interface ContainersCardProps {
    cultureId?: string;
}

export interface ContainersCardContentProps {
    cultureId: string;
}
export interface ConfluenceContainersProps {
    flaskId: string;
    cultureId: string;
}

export default function ContainersCard({ cultureId }: ContainersCardProps) {
    const data = useCultureContainers(cultureId);
    return (
        <ContainersCardContent
            isActive={data.isActive}
            deviceId={data.deviceId}
            cultureLoaded={data.cultureLoaded}
            bottles={data.bottles}
            flasks={data.flasks}
            loading={data.loading}
            error={data.error}
        />
    );
}

export function ContainersCardContent({
    deviceId,
    isActive,
    cultureLoaded,
    bottles,
    flasks,
    loading,
    error,
}: CultureContainersDetails): ReactElement {
    const { canOperate } = useDeviceRole(deviceId);
    const volumesEditable = canOperate && isActive;

    const header = (
        <TitleRow>
            <Txt level={4}>Containers</Txt>
            <StatusBadge text="Beta" size="md" status="beta" />
        </TitleRow>
    );

    if (!cultureLoaded) {
        return (
            <MainCard>
                {header}
                <Callout variant="info" message="No culture loaded" />
            </MainCard>
        );
    }

    if (loading) {
        return (
            <MainCard>
                {header}
                <Callout variant="info" message="Loading container data..." />
            </MainCard>
        );
    }

    if (error) {
        log.error("Failed to load container data", { error });
        return (
            <MainCard>
                {header}
                <Callout
                    variant="error"
                    message="Failed to load container data. Mytos Support has been informed."
                />
            </MainCard>
        );
    }

    return (
        <MainCard>
            {header}
            <CardSection>
                <TextLabel label="Bottles">
                    <TileFlexRow>
                        <BottleTiles
                            bottles={bottles}
                            volumesEditable={volumesEditable}
                        />
                    </TileFlexRow>
                </TextLabel>
            </CardSection>

            <CardSection>
                <TextLabel label="Flasks">
                    <TileFlexRow>
                        <FlaskTiles
                            flasks={flasks}
                            volumesEditable={volumesEditable}
                        />
                    </TileFlexRow>
                </TextLabel>
            </CardSection>
        </MainCard>
    );
}

const CardSection = styled.div``;

const TitleRow = styled.div`
    display: flex;
    gap: 10px;
`;

const TileFlexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px 0 16px;
`;

const MainCard = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
