import { useState } from "react";

import { ModalContent } from "components/common/Modal/Modal";

import { useDryTestOverride } from "../CreateCultureState";
import { DryTestsView } from "../DryTests/DryTestsView";
import { useDeviceDryTests } from "../DryTests/useDeviceDryTests";
import { log as parentLog } from "../log";

export const log = parentLog.extend("DryTestsModalContent");

export function DryTestsModalContent({
    deviceId,
    onBackClick,
    onSubmit,
}: {
    deviceId: string;
    onBackClick: () => void;
    onSubmit: () => Promise<void | Error>;
}) {
    const [submitting, setSubmitting] = useState<boolean>(false);

    async function handleSubmit() {
        setSubmitting(true);
        try {
            await onSubmit();
        } finally {
            setSubmitting(false);
        }
    }

    const { allComplete, someActive } = useDeviceDryTests({
        deviceId,
    });

    const { dryTestOverride } = useDryTestOverride();

    const dryChecksCompleted = allComplete && !someActive;
    const disableDryCheckNextButton = !dryChecksCompleted && !dryTestOverride;

    return (
        <ModalContent
            title="Dry tests"
            buttonLeft={{
                label: "Back",
                onClick: onBackClick,
            }}
            buttonRight={{
                label: "Continue",
                disabled: submitting || disableDryCheckNextButton,
                onClick: handleSubmit,
            }}>
            <DryTestsView deviceId={deviceId} />
        </ModalContent>
    );
}
