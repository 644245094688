import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { SetBalenaOverrideLockInput } from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from "../log";

const log = parentLog.extend("useDeviceOverrideLock");

const SET_BALENA_OVERRIDE_LOCK_MUTATION = gql(`
    mutation SetBalenaOverrideLock($input: SetBalenaOverrideLockInput!) {
        setBalenaOverrideLock(input: $input) {
            ok
            message
        }
    }
`);

/**
 * Returns true if flask was successfully updated
 */
export function useDeviceOverrideLock() {
    const [setBalenaOverrideLock, { loading }] = useMutation(
        SET_BALENA_OVERRIDE_LOCK_MUTATION,
    );

    const { toast } = useToasts();

    const handleSetBalenaOverrideLock = async (
        input: SetBalenaOverrideLockInput,
    ) => {
        const toastId = toast.loading("Setting Balena override lock");
        try {
            const output = await setBalenaOverrideLock({
                variables: {
                    input,
                },
            });

            if (!output.data?.setBalenaOverrideLock.ok) {
                throw new Error(output.data?.setBalenaOverrideLock.message);
            }

            toast.update(toastId, {
                render: `Balena override lock ${input?.enabled ? "enabled" : "disabled"} successfully`,
                type: "success",
                isLoading: false,
            });
            return true;
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                render: "Failed to set Balena override lock",
                type: "error",
                isLoading: false,
            });
            return false;
        }
    };

    return {
        loading,
        handleSetBalenaOverrideLock,
    };
}
