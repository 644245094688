/**
 * Attempts to find a user friendly name for the Result type provided
 * @param resultType Result type as returned by Graphql
 */
export function resultsTypeToName(
    resultType: string | null | undefined,
): string {
    let resultName = "Unknown Result";
    switch (resultType) {
        case "FLASK_IMAGES":
            resultName = "Flask Imaging Result";
            break;
        case "COUNTING_CHIP_IMAGES":
            resultName = "Counting Chip Images";
    }
    return resultName;
}
