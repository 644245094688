import { config } from "./config";

// Define attachments of custom Mytos objects to the global Window namespace
declare global {
    interface Window {
        __MYTOS: { config: typeof config };
    }
}

export const attachGlobals = (): void => {
    window.__MYTOS = {
        config: config,
    };
};
