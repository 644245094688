import { useState } from "react";

import copy from "copy-to-clipboard";

import Button from "components/common/Button";

import { IconButton } from "./IconButton";

export interface CopyToClipboardProps {
    variant: "compact" | "full";
    copytext: string;
}

export function CopyToClipboard({
    variant = "full",
    copytext,
}: CopyToClipboardProps): JSX.Element {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        copy(copytext, {
            format: "text/plain",
            onCopy: () => {
                setCopied(true);
                setTimeout(() => setCopied(false), 5000); // reset after 5 sec
            },
        });
    };

    if (variant === "full") {
        return (
            <Button
                disabled={copied}
                variant="tertiary"
                colour="inherit"
                size="s"
                iconRight="clipboard"
                onClick={handleCopy}>
                {copied ? "Copied!" : "Copy to clipboard"}
            </Button>
        );
    } else if (variant === "compact") {
        return copied ? (
            <IconButton icon="check" size="sm" />
        ) : (
            <IconButton
                icon="clipboard"
                size="sm"
                onClick={handleCopy}
                tooltipText="Copy to clipboard"
            />
        );
    }
    return <div></div>;
}
