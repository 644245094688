import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import {
    InvocationFragment,
    StepInvocationsQuery,
} from "__generated__/apollo/graphql";

import { log as parentLog } from "./log";

export const log = parentLog.extend("useCultureStepInvocations");

/**
 * Used to trigger refetching of step invocations
 *
 * Needs to match the name of the query in STEP_INVOCATIONS_QUERY
 */
export const StepInvocationsQueryName = "StepInvocationsQuery";

export const STEP_INVOCATIONS_QUERY = gql(`
    query StepInvocations($stepId: String!) {
        step(id: $stepId) {
            id
            name
            state
            timePlanned
            timeStarted
            timeFinished
            estimatedDuration
            runtimeReference {
                referenceStep {
                    id
                    name
                    timeFinished
                }
                runtimeInSeconds
            }
            confirmationRequired
            confirmationText
            waitingForConfirmation
            confirmed
            errorMessage
            invocations {
                totalCount
                nodes {
                    id
                    ...Invocation
                }
            }
        }
    }

    fragment Invocation on Invocation {
        id
        operationId
        description
        state
        finished
        estimatedDuration
        timePlanned
        timeStarted
        timeFinished
        parameters
        output
        errorMessage
    }
`);

export const useCultureStepInvocations = (
    stepId: string,
    pollIntervalMs: number,
) =>
    useQuery(STEP_INVOCATIONS_QUERY, {
        variables: { stepId },
        pollInterval: pollIntervalMs, // milliseconds
    });

export type Step = NonNullable<StepInvocationsQuery["step"]>;
export type Invocation = InvocationFragment;
