// This optional code is used to register a service worker.
// register() is not called by default.

import defaultLog from "services/logger";

export const log = defaultLog.extend("serviceWorkerRegistration");

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
);

type Config = {
    /**
     * A `number` defining the milliseconds between calls to registering the
     * service worker, or `false` to disable. Will only be configured for a
     * deployed instance, so will not poll when on localhost.
     */
    updateInterval: number | false;
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
    onRegistration?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config): void {
    log.debug("service worker register() called");
    if (import.meta.env.PROD && "serviceWorker" in navigator) {
        log.debug("Service worker is available in production");
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(
            import.meta.env.PUBLIC_URL,
            window.location.href,
        );
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            log.debug(
                "Our service worker won't work if PUBLIC_URL is on a different origin from what our page is served on. This might happen if a CDN is used to serve assets; see https://github.com/facebook/create-react-app/issues/2374",
            );
            return;
        }

        window.addEventListener("load", () => {
            log.debug("on window load");
            const swUrl = "/service-worker.js";

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                void navigator.serviceWorker.ready.then(() => {
                    log.debug(
                        "This web app is being served cache-first by a service " +
                            "worker. To learn more, visit https://vite-pwa-org.netlify.app/",
                    );
                });
            } else {
                // Host is not localhost so just register service worker
                // immediately without delay
                registerValidSW(swUrl, config);
            }
        });
    } else {
        log.debug(
            "NODE_ENV must be production and service workers must be available",
            import.meta.env.MODE,
            "serviceWorker" in navigator,
        );
    }
}

function registerValidSW(swUrl: string, config?: Config) {
    log.debug("Registering valid service worker", swUrl, config);
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            log.debug("Service worker registered", registration);
            config?.onRegistration?.(registration);

            if (config?.updateInterval) {
                // Configure an interval to repeatedly call update to the
                // registered service worker (first call will be after the
                // interval period)
                // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
                setInterval(() => {
                    log.debug(
                        "Checking for updates on registered service worker",
                        registration,
                    );
                    void registration.update();
                }, config.updateInterval);
            }

            registration.onupdatefound = () => {
                log.debug("Update found on registration", registration);
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated pre-cached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            log.debug(
                                "New content is available and will be used when all " +
                                    "tabs for this page are closed. See https://cra.link/PWA.",
                            );

                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been pre-cached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            log.debug("Content is cached for offline use.");

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch(error => {
            log.error("Error during service worker registration:", error);
        });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then(response => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get("content-type");
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf("javascript") === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                void navigator.serviceWorker.ready.then(registration => {
                    void registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            log.debug(
                "No internet connection found. App is running in offline mode.",
            );
        });
}

export function unregister(): void {
    log.debug("Deregistering the service worker");
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                void registration.unregister();
            })
            .catch(error => {
                log.error(error instanceof Error ? error.message : error);
            });
    }
}
