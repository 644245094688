import { ReactElement } from "react";

import { styled } from "@mui/material";

import { dayjs, Dayjs } from "services/date";

import { fontFamilyMap, textLevelFontSize } from "./Text";

export interface DateTimeInputProps {
    /**
     * Emits the new Date when the input value is changed
     */
    onChange: (newValue: Dayjs | undefined) => void;
    /**
     * Default value is the initial value of the input. Should be provided as
     * a Date
     */
    defaultValue?: Dayjs;
    /**
     * Value for external control
     */
    value?: Dayjs | undefined;
    /**
     * Minimum date to allow user to input
     */
    min?: Dayjs;
    /**
     * Maximum date to allow user to input
     */
    max?: Dayjs;
    /**
     * Matches the components width to the parent containers width
     */
    fullWidth?: boolean;
    /**
     * Disables the time specificity to the date (note this will provide Date
     * objects with the time set to 00:00)
     */
    disableTime?: boolean;
}

/**
 * Allow users to select a date and time built from native input
 *
 * @param DateTimeInputProps - Props to configure date time input component
 * @returns
 */
export function DateTimeInput({
    onChange,
    min,
    max,
    defaultValue,
    value,
    fullWidth,
    disableTime,
}: DateTimeInputProps): ReactElement {
    const dateFormatter = disableTime ? dateOnlyFormat : datetimeFormat;
    return (
        <StyledInput
            style={fullWidth ? { width: "100%" } : {}}
            type={disableTime ? "date" : "datetime-local"}
            defaultValue={dateFormatter(defaultValue)}
            value={dateFormatter(value) ?? dateFormatter(defaultValue) ?? ""}
            onChange={e => {
                const newValue = e.target.value;
                const newDate = dayjs(newValue);
                onChange(newDate);
            }}
            min={dateFormatter(min)}
            max={dateFormatter(max)}
        />
    );
}

/** Formats a Date object into a date-time string (YYYY-MM-DDTHH:mm) */
const datetimeFormat = (date?: Dayjs): string | undefined =>
    date?.format("YYYY-MM-DD[T]HH:mm");

/** Formats a Date object into a date-only string (YYYY-MM-DD) */
const dateOnlyFormat = (date?: Dayjs): string | undefined =>
    date?.format("YYYY-MM-DD");

const StyledInput = styled("input")(({ theme }) => ({
    height: 32,
    border: "1px solid " + theme.colours.neutral[300],
    borderRadius: 4,
    padding: "0 8px",
    textAlign: "left",
    fontFamily: fontFamilyMap.secondary,
    fontSize: textLevelFontSize[8],
}));
