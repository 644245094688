import React from "react";

import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Txt from "components/common/Text";
import { getUserNameText } from "services/utils";

export function NeedsConfirmation({
    confirmationText,
    onConfirmClick,
    children,
    disabled,
    buttonTooltip,
}: {
    confirmationText?: string | null;
    onConfirmClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    buttonTooltip?: string;
}) {
    const theme = useTheme();
    return (
        <ToConfirm>
            <ConfirmationRow>
                {confirmationText ? (
                    <Txt level={8}>{confirmationText}</Txt>
                ) : (
                    <div />
                )}
                <Button
                    variant="tertiary"
                    size="s"
                    tooltip={buttonTooltip}
                    disabled={disabled}
                    onClick={onConfirmClick}
                    iconRight="check"
                    colour={theme.colours.orange[500]}
                    hoverColour={theme.colours.neutral[700]}>
                    Confirm to continue
                </Button>
            </ConfirmationRow>
            <ToConfirmChildren>{children}</ToConfirmChildren>
        </ToConfirm>
    );
}

type ConfirmedBy = { firstName: string | null; lastName: string | null };

export function Unconfirmable({
    confirmedBy,
    confirmationText,
    onUnconfirmClick,
    children,
    disabled,
    buttonTooltip,
}: {
    confirmedBy: null | ConfirmedBy;
    confirmationText?: string | null;
    onUnconfirmClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    buttonTooltip?: string;
}) {
    const theme = useTheme();
    const username = getUserNameText(confirmedBy);

    return (
        <UnconfirmableWrapper>
            <ConfirmationRow>
                {confirmationText ? (
                    <Txt level={8}>{confirmationText}</Txt>
                ) : (
                    <div />
                )}

                <Button
                    variant="tertiary"
                    size="s"
                    tooltip={buttonTooltip}
                    disabled={disabled}
                    onClick={onUnconfirmClick}
                    iconRight="undo-filled"
                    colour={theme.colours.neutral[500]}
                    hoverColour={theme.colours.neutral[700]}>
                    {`Undo confirmation ${username ? `by ${username}` : ""}`}
                </Button>
            </ConfirmationRow>
            <UnconfirmableChildren>{children}</UnconfirmableChildren>
        </UnconfirmableWrapper>
    );
}

export function Confirmed({
    confirmationText,
    confirmedBy,
    children,
}: {
    confirmationText?: string | null;
    confirmedBy: null | ConfirmedBy;
    children: React.ReactNode;
}) {
    const username = getUserNameText(confirmedBy);
    return (
        <ConfirmedWrapper>
            <ConfirmationRow>
                {confirmationText ? (
                    <Txt level={8}>{confirmationText}</Txt>
                ) : (
                    <div />
                )}
                <ConfirmedTxt>
                    <Txt
                        font="secondary"
                        level={9}
                        sx={{
                            fontWeight: 700,
                        }}>
                        {`Confirmed ${username ? `by ${username}` : ""}`}
                    </Txt>
                    <Icon name="check" size="sm" />
                </ConfirmedTxt>
            </ConfirmationRow>
            <ConfirmedChildren>{children}</ConfirmedChildren>
        </ConfirmedWrapper>
    );
}

const ToConfirm = styled.div`
    outline: 1px solid ${({ theme }) => theme.colours.orange[500]};
    background: ${({ theme }) => theme.colours.orange[100]};
    color: ${({ theme }) => theme.colours.orange[500]};
    border-radius: 4px;
`;

const ToConfirmChildren = styled.div`
    color: ${({ theme }) => theme.colours.neutral[900]};
    outline: 1px solid ${({ theme }) => theme.colours.orange[500]};
    border-radius: 4px;
`;

const UnconfirmableWrapper = styled.div`
    outline: 1px solid ${({ theme }) => theme.colours.neutral[500]};
    background: ${({ theme }) => theme.colours.neutral[100]};
    color: ${({ theme }) => theme.colours.neutral[500]};
    border-radius: 4px;
`;

const UnconfirmableChildren = styled.div`
    color: ${({ theme }) => theme.colours.neutral[900]};
    outline: 1px solid ${({ theme }) => theme.colours.neutral[500]};
    border-radius: 4px;
`;

const ConfirmedWrapper = styled.div`
    outline: 1px solid ${({ theme }) => theme.colours.neutral[500]};
    background: ${({ theme }) => theme.colours.neutral[100]};
    color: ${({ theme }) => theme.colours.neutral[500]};
    border-radius: 4px;
`;

const ConfirmedChildren = styled.div`
    color: ${({ theme }) => theme.colours.neutral[900]};
    outline: 1px solid ${({ theme }) => theme.colours.neutral[500]};
    border-radius: 4px;
`;

const ConfirmationRow = styled.div`
    padding: 4px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        flex-direction: column;
    }
`;

const ConfirmedTxt = styled(Txt)`
    padding: 6px 0;
    color: ${({ theme }) => theme.colours.neutral[500]};
    display: flex;
    align-items: center;
    gap: 8px;
`;
