import React from "react";

import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useWindowWidth } from "@react-hook/window-size";
import { Link } from "react-router-dom";

import CellDivisionMark from "logo/cell-division-mark.svg?react";

import { NotificationsContainer } from "./NotificationsContainer";
import { SidebarIcon, Sidebar } from "./Sidebar";
import { useNavOptions } from "./useNavOptions";
import { UserButton } from "./UserButton";

export function AppBar(): JSX.Element {
    const { navIndex, navOptions } = useNavOptions();
    const winWidth = useWindowWidth();
    const isCompact: boolean = winWidth < 1200;

    return (
        <React.Fragment>
            <ElevationScroll>
                <MuiAppBar
                    sx={{
                        backgroundColor: theme => theme.colours.neutral.white,
                        borderBottom: theme =>
                            "1px solid " + theme.colours.neutral[300],
                        zIndex: theme => theme.zIndex.drawer + 1,
                    }}>
                    <Toolbar>
                        <Grid
                            item
                            display="flex"
                            alignItems="center"
                            sx={{ flexGrow: 1, gap: 4, paddingLeft: "3px" }}>
                            <SidebarIcon />
                            <Box
                                sx={{
                                    display: "block",
                                    height: "24px",
                                }}>
                                <Link to="/">
                                    <CellDivisionMark
                                        height="24px"
                                        width="48px"
                                    />
                                </Link>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            sx={{
                                flexGrow: 1,
                                textAlign: "right",
                            }}>
                            <NotificationsContainer />
                            <UserButton compact={isCompact} />
                        </Grid>
                    </Toolbar>
                </MuiAppBar>
            </ElevationScroll>
            <Toolbar
            // ? we put this invisible toolbar in to deal with the fact the
            // AppBar takes up no vertical space for the other content
            />
            <Sidebar activeIndex={navIndex} navOptions={navOptions} />
        </React.Fragment>
    );
}

function ElevationScroll(props: { children: React.ReactElement }) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(props.children, {
        elevation: trigger ? 4 : 0,
    });
}
