import { Fragment, ReactElement } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import Txt from "components/common/Text";
import { dayjs } from "services/date";

import { DeviceResult } from "./DeviceResults";
import ResultCard from "./ResultCard";

export interface ResultsListProps {
    resultsData: DeviceResult[];
    viewMore: () => void;
    numberToView: number;
    hasMore: boolean;
}

export default function ResultsList(props: ResultsListProps): ReactElement {
    const { resultsData, viewMore, numberToView, hasMore } = props;

    let previousDayText: string | null = null;

    const allItems = resultsData.map((rd, i) => {
        const linkPath = `/results/${rd.id}`;

        const displayedDayText = rd.timestampISO
            ? dayjs(rd.timestampISO).format("dddd, Do MMMM YYYY")
            : "No date";
        const newDay = previousDayText !== displayedDayText;
        if (newDay) {
            previousDayText = displayedDayText;
        }

        return (
            <Fragment key={i}>
                {newDay ? (
                    <Txt
                        level={7}
                        emphasis
                        sx={{
                            "marginTop": 5,
                            "marginBottom": 4,
                            "&:first-child": {
                                marginTop: 0, // if first item in list
                            },
                        }}>
                        {displayedDayText}
                    </Txt>
                ) : null}
                <div key={i} style={{ padding: "4px 0" }}>
                    <Link to={linkPath}>
                        <ResultCard result={rd} />
                    </Link>
                </div>
            </Fragment>
        );
    });

    return (
        <InfiniteScroll
            dataLength={numberToView}
            next={viewMore}
            hasMore={hasMore}
            loader={
                <Txt font="secondary" level={7}>
                    Loading...
                </Txt>
            }
            endMessage={
                <div style={{ textAlign: "center", padding: 24 }}>
                    <Txt font="secondary" level={8} italic>
                        {"You've seen all the results!"}
                    </Txt>
                </div>
            }>
            {allItems}
        </InfiniteScroll>
    );
}
