import { ReactElement } from "react";

import CircularProgress from "@mui/material/CircularProgress";

export interface ProgressProps {
    size?: number;
    /**
     * If defined, determines the state of progress for the loading bar.
     *
     * Value between 0 and 100.
     */
    value?: number;
    /**
     * Optional override to colour of progress bar
     */
    colour?: string;
}

export function Progress({
    size = 16,
    value,
    colour,
}: ProgressProps): ReactElement {
    const indeterminate = value === undefined;
    return (
        <div style={{ display: "inline-flex", position: "relative" }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: theme => theme.colours.neutral[300],
                }}
                size={size}
                thickness={6}
                value={100}
            />
            <CircularProgress
                size={size}
                disableShrink={indeterminate ? true : undefined}
                thickness={6}
                sx={{
                    "position": "absolute",
                    "color": theme => colour ?? theme.colours.brand.blueDark,
                    "animationDuration": "800ms",
                    "& .MuiCircularProgress-circle": {
                        strokeLinecap: "round",
                    },
                }}
                variant={indeterminate ? "indeterminate" : "determinate"}
                value={value}
            />
        </div>
    );
}
