import { ReactElement, useState } from "react";

import { Stack } from "@mui/material";
import { isUndefined } from "lodash";

import Callout from "components/common/Callout";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { IconButton } from "components/common/IconButton";
import { Select } from "components/common/Select";
import Txt from "components/common/Text";
import { formatDatetime } from "services/date";
import { isNotNull } from "services/utils";

import { useDeviceReleases } from "./useDeviceReleases";
import {
    usePinDeviceToBalenaRelease,
    useUnpinDeviceFromBalenaRelease,
} from "./usePinDeviceToFleet";

interface PinDeviceDialogProps {
    deviceId: string;
    currentPinnedCommit?: string;
}

export default function PinDeviceDialog({
    deviceId,
    currentPinnedCommit,
}: PinDeviceDialogProps): ReactElement {
    const [open, setOpen] = useState(false);

    const { handlePinDeviceToBalenaRelease, loading: pinDeviceLoading } =
        usePinDeviceToBalenaRelease();

    const { handleUnpinDeviceFromBalenaRelease, loading: unpinDeviceLoading } =
        useUnpinDeviceFromBalenaRelease();

    const defaultValue = currentPinnedCommit ?? "track-latest";

    const { releases, loading: releasesLoading } = useDeviceReleases(deviceId);

    const releaseOptionsWithNull = [
        { value: "track-latest", label: "Track Latest" },
        ...(releases?.map(release => {
            if (!release || !release.commit || !release.createdAt) {
                return null;
            }
            return {
                value: release.commit,
                label: `${release.commit.substring(0, 7)} - ${formatDatetime(release.createdAt)}`,
            };
        }) ?? []),
    ];

    const releaseOptions = releaseOptionsWithNull.filter(isNotNull);

    const [releaseId, setReleaseId] = useState<string | undefined>(
        defaultValue,
    );

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const disableConfirm =
        pinDeviceLoading ||
        releasesLoading ||
        unpinDeviceLoading ||
        isUndefined(releaseId);

    const handlePinDevice = async () => {
        if (!releaseId) {
            return;
        }

        let response: boolean;
        if (releaseId === "track-latest") {
            response = await handleUnpinDeviceFromBalenaRelease({ deviceId });
        } else {
            response = await handlePinDeviceToBalenaRelease({
                deviceId,
                targetReleaseId: releaseId,
            });
        }

        if (response) {
            handleClose();
        }
    };

    return (
        <>
            <IconButton
                icon="edit"
                size="sm"
                onClick={handleOpen}
                tooltipText="Set device fleet"
            />
            <ConfirmationDialog
                variant="destructive"
                open={open}
                onSecondaryAction={handleClose}
                onDismiss={handleClose}
                onPrimaryAction={handlePinDevice}
                primaryButtonDisabled={disableConfirm}
                title="Pin Software Version"
                body={
                    <>
                        <Stack direction={"column"} spacing={4} sx={{ mt: 2 }}>
                            <Callout
                                variant="warn"
                                message="If any image or video capture has completed recently, then there may be data loss if the images or videos have not finished uploading."
                            />
                            <Txt level={8} font="secondary">
                                Pinning the software version will cause a update
                                if the device is not running the selected
                                version
                            </Txt>
                            {open ? (
                                <Select
                                    loading={releasesLoading}
                                    defaultValue={defaultValue}
                                    options={releaseOptions}
                                    onChange={setReleaseId}
                                />
                            ) : null}
                        </Stack>
                    </>
                }
                primaryButtonText="Confirm change"></ConfirmationDialog>
        </>
    );
}
