import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import {
    ProcedureStepsQuery,
    StepFragment,
} from "__generated__/apollo/graphql";

import { log as parentLog } from "./log";

export const log = parentLog.extend("useCultureStepInvocations");

export const PROCEDURE_STEPS_QUERY = gql(`
    query ProcedureSteps($procedureId: String!) {
        procedure(id: $procedureId) {
            id
            name
            state
            timePlanned
            timeStarted
            timeFinished
            estimatedDuration
            steps {
                totalCount
                nodes {
                    id
                    ...Step
                }
            }
        }
    }

    fragment Step on Step {
        id
        name
        stageName
        state
        timeStarted
        timeFinished
        timePlanned
        estimatedDuration
        runtimeReference {
            referenceStep {
                id
                name
                timeFinished
            }
            runtimeInSeconds
        }
        confirmationRequired
        confirmationText
        waitingForConfirmation
        confirmed
        errorMessage
        invocationSummary {
            id
            numberOfFailedInvocations
        }
        invocations {
            totalCount
        }
        confirmedBy {
            id
            firstName
            lastName
        }
    }
`);

export const useCultureProcedureSteps = (
    procedureId: string,
    pollIntervalMs?: number,
) =>
    useQuery(PROCEDURE_STEPS_QUERY, {
        variables: { procedureId },
        pollInterval: pollIntervalMs, // milliseconds
    });

export type Procedure = NonNullable<ProcedureStepsQuery["procedure"]>;
export type Step = StepFragment;
