import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import {
    PinDeviceToBalenaReleaseInput,
    UnpinDeviceFromBalenaReleaseInput,
} from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from "../log";

import { DeviceSettingQueryName } from "./DeviceSettings";

const log = parentLog.extend("usePinDeviceToFleet");

const PIN_DEVICE_TO_BALENA_RELEASE_MUTATION = gql(`
    mutation PinDeviceToBalenaRelease($input: PinDeviceToBalenaReleaseInput!) {
        pinDeviceToBalenaRelease(input: $input) {
            ok
            message
        }
    }
`);

const UNPIN_DEVICE_FROM_BALENA_RELEASE_MUTATION = gql(`
    mutation UnpinDeviceFromBalenaRelease($input: UnpinDeviceFromBalenaReleaseInput!) {
        unpinDeviceFromBalenaRelease(input: $input) {
            ok
            message
        }
    }
`);

/**
 * Returns true if device was successfully pinned to Balena release
 */
export function usePinDeviceToBalenaRelease() {
    const [pinDeviceToBalenaRelease, { loading }] = useMutation(
        PIN_DEVICE_TO_BALENA_RELEASE_MUTATION,
    );

    const { toast } = useToasts();

    const handlePinDeviceToBalenaRelease = async (
        input: PinDeviceToBalenaReleaseInput,
    ) => {
        const toastId = toast.loading("Pinning device to Balena release");
        try {
            const output = await pinDeviceToBalenaRelease({
                variables: {
                    input,
                },
                refetchQueries: [DeviceSettingQueryName],
                awaitRefetchQueries: true,
            });

            if (!output.data?.pinDeviceToBalenaRelease.ok) {
                throw new Error(output.data?.pinDeviceToBalenaRelease.message);
            }

            toast.update(toastId, {
                render: "Device pinned to Balena release successfully",
                type: "success",
                isLoading: false,
            });
            return true;
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                render: "Failed to pin device to Balena release",
                type: "error",
                isLoading: false,
            });
            return false;
        }
    };

    return {
        loading,
        handlePinDeviceToBalenaRelease,
    };
}

/**
 * Returns true if device was successfully unpinned from Balena release
 */
export function useUnpinDeviceFromBalenaRelease() {
    const [unpinDeviceFromBalenaRelease, { loading }] = useMutation(
        UNPIN_DEVICE_FROM_BALENA_RELEASE_MUTATION,
    );

    const { toast } = useToasts();

    const handleUnpinDeviceFromBalenaRelease = async (
        input: UnpinDeviceFromBalenaReleaseInput,
    ) => {
        const toastId = toast.loading("Unpinning device from Balena release");
        try {
            const output = await unpinDeviceFromBalenaRelease({
                variables: {
                    input,
                },
                refetchQueries: [DeviceSettingQueryName],
                awaitRefetchQueries: true,
            });
            if (!output.data?.unpinDeviceFromBalenaRelease.ok) {
                throw new Error(
                    output.data?.unpinDeviceFromBalenaRelease.message,
                );
            }
            toast.update(toastId, {
                render: "Device unpinned from Balena release successfully",
                type: "success",
                isLoading: false,
            });
            return true;
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                render: "Failed to unpin device from Balena release",
                type: "error",
                isLoading: false,
            });
            return false;
        }
    };

    return {
        loading,
        handleUnpinDeviceFromBalenaRelease,
    };
}
