import { useEffect, useRef, useState } from "react";

interface UseRenderTimerProps {
    enabled?: boolean;
    seconds: number;
}

/**
 * For components that use this hook, a rerender is triggered on the cadence set
 * by the parameters.
 *
 * **WARNING: Consider use of this hook carefully. It should not be used in
 * components with many children, as they will all be rerendered.**
 *
 * Options:
 * - `seconds` interval
 *
 * @param param options
 */
export function useRenderTimer({
    seconds,
    enabled = true,
}: UseRenderTimerProps): void {
    const intervalValue = seconds * 1000;
    const [, setNow] = useState<Date>(new Date());
    const intervalRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (enabled) {
            clearInterval(intervalRef.current); // clear previous before new
            intervalId = setInterval(() => {
                setNow(new Date());
            }, intervalValue);
            intervalRef.current = intervalId;
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalValue, enabled]);
}
