import { ReactElement } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material";

import { InvocationState } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";
import { Progress } from "components/common/Progress";

import { log as parentLog } from "./log";

export const log = parentLog.extend("InvocationStateIndicator");

export interface InvocationStateIconProps {
    state: InvocationState;
}

export function InvocationStateIcon(
    props: InvocationStateIconProps,
): ReactElement {
    const theme = useTheme();
    let el: ReactElement | undefined;
    el = <span>?</span>;

    if (props.state === InvocationState.Complete) {
        el = (
            <Icon
                name="check"
                size="md"
                colourOverride={theme.colours.brand.greenDark}
            />
        );
    }
    if (props.state === InvocationState.Running) {
        el = <Progress />;
    }
    if (props.state === InvocationState.Failed) {
        el = (
            <div
                css={css`
                    height: 16px;
                    width: 16px;
                    border-radius: 20px;
                    background-color: ${theme.colours.accent.errorRed};
                    color: ${theme.colours.neutral.white};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}>
                <Icon name="cross" size="sm" />
            </div>
        );
    }
    if (
        [InvocationState.Planned, InvocationState.Waiting].includes(props.state)
    ) {
        el = (
            <div
                css={css`
                    border-radius: 20px;
                    width: 10px;
                    height: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid ${theme.colours.neutral[300]};
                `}
            />
        );
    }
    if (
        [
            InvocationState.Ignored,
            InvocationState.Removed,
            InvocationState.Unknown,
            InvocationState.Cancelled,
        ].includes(props.state)
    ) {
        el = (
            <div
                css={css`
                    height: 16px;
                    width: 16px;
                    border-radius: 20px;
                    background-color: ${theme.colours.neutral[300]};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}>
                <div
                    css={css`
                        height: 2px;
                        width: 8px;
                        background-color: ${theme.colours.neutral[100]};
                    `}
                />
            </div>
        );
    }
    if (props.state === InvocationState.Paused) {
        el = <Icon name="pause-circle" size="md" />;
    }

    return (
        <div
            css={css`
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            `}>
            {el}
        </div>
    );
}
