import { ReactElement, useState } from "react";

import { Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

import { DeviceUserRole } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import { CodeText } from "components/common/CodeText";
import StatusBadge from "components/common/StatusBadge";
import Txt from "components/common/Text";
import { TextLabel } from "components/common/TextLabel";

import { log as parentLog } from "../../log";

import { ChangeUserRole } from "./ChangeUserRole";
import { RevokeDeviceAccessButton } from "./RevokeDeviceAccessButton";

export const log = parentLog.extend("DeviceUserCard");

export interface DeviceUserCardProps {
    edgeId: string;
    deviceId: string;
    email: string;
    userId: string;
    userDeactivated: boolean;
    role: DeviceUserRole;
    createdAt: string;
    lastChangedAt: string;
    lastChangedByEmail: string;
}

export function DeviceUserCard(props: DeviceUserCardProps): ReactElement {
    const { email, role, userDeactivated } = props;
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme();

    return (
        <Box
            sx={{
                minHeight: 40,
                px: 4,
                py: 2,
                gap: 2,
            }}
            justifyContent="space-between"
            alignItems="center">
            <Box // top row
                display="flex"
                flexWrap="wrap"
                gap={2}>
                <Box
                    display="inline-flex"
                    flexGrow={1}
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        columnGap: theme.spacing(3),
                    }}>
                    <Txt
                        level={7}
                        sx={{
                            color: theme =>
                                userDeactivated
                                    ? theme.colours.neutral[600]
                                    : undefined,
                        }}>
                        {email}
                    </Txt>
                    {userDeactivated && (
                        <StatusBadge
                            status={"disabled"}
                            text={"Deactivated"}
                            size="sm"
                        />
                    )}
                </Box>
                <Box display="inline-flex" sx={{ gap: 4 }}>
                    <ChangeUserRole
                        role={role}
                        edgeId={props.edgeId}
                        deviceId={props.deviceId}
                    />
                    <Button
                        variant="tertiary"
                        size="s"
                        iconRight={expanded ? "chevron-up" : "chevron-down"}
                        colour={theme.colours.neutral[600]}
                        onClick={() => setExpanded(!expanded)}>
                        Details
                    </Button>
                </Box>
            </Box>
            <Collapse in={expanded} mountOnEnter unmountOnExit>
                <DeviceUserExpanded {...props} />
                {/* <Box>
                </Box> */}
            </Collapse>
        </Box>
    );
}

function DeviceUserExpanded(props: DeviceUserCardProps): ReactElement {
    const {
        edgeId,
        deviceId,
        userId,
        createdAt,
        lastChangedAt,
        lastChangedByEmail,
    } = props;

    return (
        <Grid container spacing={4} style={{ paddingTop: 16 }}>
            <Grid item xs={12} sm={6}>
                <TextLabel label="User ID">
                    <CodeText text={userId} />
                </TextLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Access granted at">
                    <Txt level={8}>{createdAt}</Txt>
                </TextLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Last changed at">
                    <Txt level={8}>{lastChangedAt}</Txt>
                </TextLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextLabel label="Last changed by">
                    <Txt level={8}>{lastChangedByEmail}</Txt>
                </TextLabel>
            </Grid>

            <Grid
                item
                xs={12}
                sm={6}
                container
                // justify="flex-end"
                alignItems="center">
                <RevokeDeviceAccessButton edgeId={edgeId} deviceId={deviceId} />
            </Grid>
        </Grid>
    );
}
