import React from "react";

import { useTheme } from "@mui/material/styles";

import Txt from "components/common/Text";

interface SettingsSectionProps {
    heading: string;
    children?: React.ReactNode;
}

export default function SettingsSection(
    props: SettingsSectionProps,
): JSX.Element {
    const theme = useTheme();
    return (
        <div style={{ marginBottom: theme.spacing(10) }}>
            <Txt
                level={4}
                style={{
                    marginBottom: theme.spacing(4),
                }}>
                {props.heading}
            </Txt>
            {props.children}
        </div>
    );
}
