import React, { ReactElement } from "react";

import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";

import { ProcedureState } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import { IconButton } from "components/common/IconButton";
import { InfoPill } from "components/common/Pills";
import Txt from "components/common/Text";
import { dayjs, isToday } from "services/date";

import { DayBlockProps } from "./DayBlock";
import { log as parentLog } from "./log";

export const log = parentLog.extend("DayHeader");

interface DayHeaderProps extends DayBlockProps {
    expanded: boolean;
    toggleExpanded: () => void;
}

export function DayHeader({
    expanded,
    toggleExpanded,
    ...props
}: DayHeaderProps): ReactElement {
    const formattedDate = dayjs(props.date).format("dddd D MMMM");
    const today = isToday(props.date);
    const handleToggleClick = (event?: React.MouseEvent) => {
        event?.stopPropagation();
        toggleExpanded();
    };

    return (
        <DayHeaderContainer
            isToday={today}
            id={"day-" + props.dayNum + "-header"}
            onClick={handleToggleClick}>
            <div>
                <Txt
                    className="day-title"
                    level={7}
                    emphasis
                    font="secondary"
                    style={{
                        fontVariant: "tabular-nums",
                        minWidth: "53px",
                        marginRight: "1rem",
                        display: "inline-block",
                    }}>
                    Day {replaceDashWithMinusSign(props.dayNum.toString())}
                </Txt>
                <Txt
                    className="day-date"
                    level={7}
                    emphasis
                    display="inline"
                    font="secondary">
                    {formattedDate}
                </Txt>
            </div>
            <DayMetadata>
                <InfoBadge {...props} />
                <DesktopButton
                    variant="tertiary"
                    iconRight={expanded ? "collapse" : "expand"}
                    size="m"
                    colour="inherit"
                    onClick={handleToggleClick}>
                    {expanded ? "Hide" : "Show"}
                </DesktopButton>
                <MobileIconButton
                    icon={expanded ? "collapse" : "expand"}
                    size="md"
                    onClick={handleToggleClick}
                />
            </DayMetadata>
        </DayHeaderContainer>
    );
}

const DayHeaderContainer = styled.div<{ isToday: boolean }>`
    container-type: inline-size;
    position: sticky;
    top: 114px; // matches AppBar(64) + Schedule controls height
    z-index: 5;
    background: white;
    ${({ theme }) => theme.breakpoints.down("sm")} {
        top: 56px; // matches AppBar height on small screen
    }
    margin-top: 4px;
    padding-top: 8px;
    padding-bottom: 4px;

    // when the header is sticky positioned some icons on the children that are offset to the right where showing
    // as the children scrolled under the header. This negative margin fixes that by forcing the headers white background
    // to be wider than the children.
    margin-right: -8px;
    margin-left: -8px;
    padding-right: 8px;
    padding-left: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${({ theme }) => theme.colours.neutral[600]};

    cursor: pointer;

    .day-title {
        transition: color 0.2s ease-in-out;
        color: ${({ theme, isToday }) =>
            theme.colours.neutral[isToday ? 900 : 700]};
    }

    &:hover {
        .day-title {
            color: ${({ theme }) => theme.colours.neutral[900]};
        }
    }
`;

const DayMetadata = styled.div`
    display: flex;
    align-items: center;

    @container (min-width: 400px) {
        gap: 16px;
    }
`;

const DesktopButton = styled(Button)`
    @container (max-width: 400px) {
        display: none;
    }
`;

const MobileIconButton = styled(IconButton)`
    @container (min-width: 400px) {
        display: none;
    }
`;

function InfoBadge(props: DayBlockProps) {
    const { procedures } = props;

    const totalProceduresComplete = procedures.reduce(
        (total, procedure) =>
            procedure === null
                ? total
                : procedure.state === ProcedureState.Complete
                  ? total + 1
                  : total,
        0,
    );
    const totalProceduresStalled = procedures.reduce(
        (total, procedure) =>
            procedure === null
                ? total
                : procedure.state === ProcedureState.Stalled
                  ? total + 1
                  : total,
        0,
    );
    const totalProceduresPlanned = procedures.reduce(
        (total, procedure) =>
            procedure === null
                ? total + 1 // we count null procedures as 'planned'
                : procedure.state === ProcedureState.Planned
                  ? total + 1
                  : total,
        0,
    );

    const totalProceduresWithConfirmations = procedures.reduce(
        (total, procedure) => {
            if (
                procedure?.state &&
                [ProcedureState.Removed, ProcedureState.Cancelled].includes(
                    procedure?.state,
                )
            ) {
                return total;
            }
            const procedureNeedsConfirmation =
                procedure?.confirmationRequired && !procedure?.confirmed;

            return (
                total +
                (procedureNeedsConfirmation ? 1 : 0) +
                (procedure?.stepsSummary
                    ?.numberOfStepsWithOutstandingConfirmation ?? 0)
            );
        },
        0,
    );

    if (
        totalProceduresPlanned +
            totalProceduresStalled +
            totalProceduresComplete +
            totalProceduresWithConfirmations ===
        0
    ) {
        return <></>;
    }

    return (
        <InfoPill id="info-pill">
            {totalProceduresPlanned > 0 && (
                <Tooltip title={`${totalProceduresPlanned} planned`}>
                    <StepIndicator>
                        <Icon name="clock" size="sm" />
                        <Txt font="secondary" level={9} display="inline">
                            {totalProceduresPlanned}
                        </Txt>
                    </StepIndicator>
                </Tooltip>
            )}
            {totalProceduresComplete > 0 && (
                <Tooltip title={`${totalProceduresComplete} complete`}>
                    <StepIndicator>
                        <Icon name="check" size="sm" />
                        <Txt font="secondary" level={9} display="inline">
                            {totalProceduresComplete}
                        </Txt>
                    </StepIndicator>
                </Tooltip>
            )}
            {totalProceduresStalled > 0 && (
                <Tooltip title={`${totalProceduresStalled} failed`}>
                    <StepIndicator stalled>
                        <Icon name="cross" size="sm" />
                        <Txt font="secondary" level={9} display="inline">
                            {totalProceduresStalled}
                        </Txt>
                    </StepIndicator>
                </Tooltip>
            )}
            {totalProceduresWithConfirmations > 0 && (
                <ConfirmationPillBody
                    numberOfConfirmations={totalProceduresWithConfirmations}
                />
            )}
        </InfoPill>
    );
}

export function ConfirmationPillBody({
    numberOfConfirmations,
}: {
    numberOfConfirmations: number;
}) {
    return (
        <StepIndicator confirmation>
            <Icon name="touch-select" size="sm" />
            <Txt font="secondary" level={9} display="inline">
                {numberOfConfirmations} confirmation
                {numberOfConfirmations > 1 ? "s" : ""}
            </Txt>
        </StepIndicator>
    );
}

const StepIndicator = styled.div<{ stalled?: boolean; confirmation?: boolean }>`
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${({ theme, stalled, confirmation }) =>
        stalled
            ? theme.colours.red[500]
            : confirmation
              ? theme.colours.orange[300]
              : theme.colours.neutral[400]};

    opacity: ${({ stalled }) => (stalled ? 0.6 : undefined)};
`;

function replaceDashWithMinusSign(text: string) {
    return text.replaceAll("-", "−");
}
