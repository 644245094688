import { ReactElement } from "react";

import { css, useTheme } from "@mui/material";
import { lighten } from "polished";
import ReactSelect, {
    GroupBase,
    MultiValue,
    OnChangeValue,
    SingleValue,
    StylesConfig,
} from "react-select";

import Icon, { IconName } from "./Icon";
import { fontFamilyMap, textLevelFontSize, textLevelLineHeight } from "./Text";

export type SelectProps<V extends string, IsMulti extends boolean> = {
    /**
     * Set multi-select mode. Defaults to false.
     */
    multi?: IsMulti;
    /**
     * The value to mark as selected on initial render.
     *
     * If using `multi` this should be an array.
     */
    defaultValue?: DefaultValue<V, IsMulti>;
    /**
     * Value for the selected option. When passing this prop the component is a controlled component.
     */
    value?: DefaultValue<V, IsMulti>;
    /**
     * Array of values that represent the options to be displayed within the
     * Select dropdown.
     *
     * The array can either contain numerous `Option` or `Group` objects.
     *
     * An `Option` represents a single value, with a `value` and optional
     * `label` properties.
     *
     * A `Group` represents a grouping of values, and thus has a required
     * `label` property and `options` nested array.
     */
    options: Options<V>;
    /**
     * Callback function that receives new value when its selected from the
     * dropdown list.
     *
     * When `multi` is used, an array will be returned of all selected options,
     * on each change.
     */
    onChange?: (newValue: OnChangeOutput<V, IsMulti>) => void;
    /**
     * Disables the dropdown from being selectable
     */
    disabled?: boolean;
    /**
     * Minimum width of container
     */
    minWidth?: number | false;
    /**
     * Maximum width of container.
     *
     * Set to `false` for the component to fill available width.
     */
    maxWidth?: number | false;
    /**
     * Set whether Select value can be cleared. Defaults to `false`.
     */
    clearable?: boolean;
    /**
     * Set whether the Select field is searchable (if `true`, will open keyboard
     * on mobile devices when Select clicked). Defaults to `true`.
     */
    searchable?: boolean;
    /**
     * Displays a loading indicator to show that options may not be fully
     * populated yet.
     */
    loading?: boolean;
    /**
     * Value to display in the input box when nothing is selected
     */
    placeholder?: string;
    /**
     * Whether the drop down menu should always stay open
     */
    stayOpen?: boolean;
    /**
     * Additional react-select style config object to be merged into our base style
     */
    styles?: StylesConfig<Option<V>, IsMulti, GroupBase<Option<V>>>;
};

/**
 * Type of an Option passed to `Select` via prop `options` array
 */
export type Option<V> = {
    /** Value of the option when selected */
    value: V;
    /** Label of the option as displayed in component */
    label?: string;
    /** Option is disabled and cannot be selected */
    disabled?: boolean;
    /** Icon to display before text. If provided, all options will be indented */
    icon?: IconName;
};

/**
 * Type of a group of options passed to `Select` via prop `options` array
 */
export type Group<V> = {
    /** Group label */
    label: string;
    /** Array of options */
    options: Option<V>[];
};

type OptionOrGroup<V> = Option<V> | Group<V>;

export type Options<V> = readonly OptionOrGroup<V>[];

function isOptionGroup<V>(item: OptionOrGroup<V>): item is Group<V> {
    return "options" in item;
}

/**
 * Defines the type of `defaultValue` passed to the Select component. When in
 * multi mode, the value type will be an array generic, else it will be just the
 * generic type directly.
 */
type DefaultValue<V, IsMulti extends boolean> = IsMulti extends true
    ? MultiValue<V>
    : SingleValue<V>;

type OnChangeOutput<V, IsMulti extends boolean> = IsMulti extends true
    ? MultiValue<V>
    : V;

function useStyleConfig<V extends string, IsMulti extends boolean>(
    style: StylesConfig<Option<V>, IsMulti, GroupBase<Option<V>>>,
): StylesConfig<Option<V>, IsMulti, GroupBase<Option<V>>> {
    const theme = useTheme();
    const borderColour = theme.colours.neutral[500];
    const outlineColour = theme.colours.brandBlue[500];
    const hoverColour = theme.colours.neutral[900];
    const baseStyle: StylesConfig<Option<V>, IsMulti, GroupBase<Option<V>>> = {
        container: provided => ({
            ...provided,
            fontFamily: fontFamilyMap["secondary"],
            fontSize: textLevelFontSize[8],
            lineHeight: textLevelLineHeight[8],
            color: theme.colours.neutral[900],
            textAlign: "left", // ensure no parent accidentally sets this
            flexGrow: 1,
        }),
        control: (provided, state) => ({
            ...provided,
            "minHeight": 34,
            "boxShadow": "none",
            "border": state.isFocused
                ? "1px solid " + outlineColour
                : "1px solid " + borderColour,
            ":hover": {
                border: state.isFocused
                    ? "1px solid " + outlineColour
                    : "1px solid " + hoverColour,
            },
        }),
        valueContainer: provided => ({
            ...provided,
            padding: 4,
            // paddingTop: 0,
            // paddingBottom: 0,
            gap: 4,
        }),
        clearIndicator: provided => ({
            ...provided,
            padding: 6,
            color: borderColour,
        }),
        indicatorSeparator: provided => ({
            ...provided,
            marginTop: 6,
            marginBottom: 6,
            backgroundColor: borderColour,
        }),
        dropdownIndicator: provided => ({
            ...provided,
            padding: 6,
            color: theme.colours.neutral[600],
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: provided => ({
            ...provided,
            border: "1px solid " + borderColour,
            boxShadow: "0 4px 11px hsla(0, 0%, 0%, 0.1)",
            fontFamily: fontFamilyMap["secondary"],
            fontSize: textLevelFontSize[8],
            lineHeight: textLevelLineHeight[8],
        }),
        menuList: provided => ({
            ...provided,
            padding: 6,
        }),
        group: provided => ({
            ...provided,
            paddingLeft: 6,
        }),
        groupHeading: provided => ({
            ...provided,
            paddingLeft: 4,
        }),
        option: (provided, state) => ({
            ...provided,
            "paddingLeft": 10,
            "paddingTop": 6,
            "paddingBottom": 6,
            "borderRadius": 4,
            "color": state.isSelected
                ? theme.colours.brand.greenDark
                : state.isDisabled
                  ? theme.colours.neutral[400]
                  : theme.colours.neutral[700],
            "backgroundColor": state.isFocused
                ? theme.colours.brand.lightBlue
                : "none",
            ":hover": {
                backgroundColor: state.isFocused
                    ? theme.colours.brand.lightBlue
                    : undefined,
            },
        }),
        input: provided => ({
            ...provided,
            margin: 0,
            padding: 0,
            height: 24,
            /**
             * Applying fontsize here doesn't majorly affect how text is
             * rendered in the Select component, however it's necessary
             * because a fontsize smaller than 16px for an <input> field
             * on iOS automatically leads to the browser 'zooming in' on
             * behalf of the user for accessibility reasons.
             *
             * https://stackoverflow.com/a/62827297
             * https://stackoverflow.com/a/32884742
             */
            fontSize: "16px",
        }),
        singleValue: provided => ({
            ...provided,
            margin: 0,
        }),
        multiValue: provided => ({
            ...provided,
            lineHeight: "85%", // to match the fontSize 85% builtin
            backgroundColor: theme.colours.neutral[200],
            color: theme.colours.neutral[600],
            margin: 0,
            height: 24,
        }),
        multiValueLabel: provided => ({
            ...provided,
            alignSelf: "center",
            margin: 2,
        }),
        multiValueRemove: provided => ({
            ...provided,
            "alignSelf": "stretch",
            ":hover": {
                color: theme.colours.accent.alertOrange,
                backgroundColor: lighten(0.3, theme.colours.accent.alertOrange),
            },
        }),
    };
    return { ...baseStyle, ...style };
}

/**
 * Select (dropdown) component.
 */
export function Select<V extends string, IsMulti extends boolean = false>(
    props: SelectProps<V, IsMulti>,
): ReactElement {
    const {
        defaultValue,
        value,
        options,
        onChange,
        disabled = false,
        minWidth = 80,
        maxWidth = 300,
        clearable = false,
        searchable = true,
        loading = false,
        multi = false as IsMulti,
        placeholder = "Select...",
        styles = {},
        stayOpen,
    } = props;
    const theme = useTheme();

    const handleChange = (newValue: OnChangeValue<Option<V>, IsMulti>) => {
        if (!newValue) return;
        if (multi) {
            // newValue will be an array of options
            const multiValue = newValue as MultiValue<Option<V>>;
            const justV = multiValue.map(v => v.value);
            onChange?.(justV as never);
        } else {
            const singleValue = newValue as SingleValue<Option<V>>;
            onChange?.(singleValue?.value as never);
        }
    };

    const outlineColour = theme.colours.brand.green;

    const dynamicStyles: StylesConfig<
        Option<V>,
        IsMulti,
        GroupBase<Option<V>>
    > = {
        valueContainer: provided => ({
            ...provided,
            padding: 4,
            gap: 4,
            paddingLeft: multi ? 4 : 8,
        }),
        container: provided => ({
            ...provided,
            ...(maxWidth && { maxWidth }),
            ...(minWidth && { minWidth }),
            fontFamily: fontFamilyMap["secondary"],
            fontSize: textLevelFontSize[8],
            lineHeight: textLevelLineHeight[8],
            color: theme.colours.neutral[900],
            textAlign: "left", // ensure no parent accidentally sets this
            flexGrow: 1,
        }),
    };

    const requestedStyles = { ...dynamicStyles, ...styles };
    const searchIndicator = () => (
        <Icon
            size="md"
            name={"search"}
            colourOverride={theme.colours.neutral[600]}
            css={css`
                padding: 8px;
                box-sizing: content-box;
            `}
        />
    );
    const openComponents = {
        DropdownIndicator: searchIndicator,
        IndicatorSeparator: null,
    };

    const findValue = (v?: DefaultValue<V, IsMulti>) =>
        multi
            ? flattenOptions(options).filter(op => v?.includes(op.value))
            : flattenOptions(options).find(op => op.value === v);

    return (
        <ReactSelect
            // defaultMenuIsOpen={true} // ! for debugging only
            // menuIsOpen={true} // ! for debugging only
            isMulti={multi}
            options={options}
            isDisabled={disabled}
            isClearable={clearable}
            isSearchable={searchable}
            isLoading={loading}
            menuIsOpen={stayOpen}
            placeholder={placeholder}
            components={stayOpen ? openComponents : undefined}
            // hideSelectedOptions={false} // May be needed in future
            // closeMenuOnSelect={false} // May be needed in future
            /**
             * Enabling the following causes the body to compress in width every
             * time a Select component is opened.
             */
            // menuShouldBlockScroll={true}
            /**
             * Should not be defined to prevent weird positioning behaviour
             */
            // menuPosition="fixed"
            /**
             * If `true`, this can cause buggy small scroll effects when menu
             * placement isn't ideal. Example is result comparison in desktop
             * mode.
             */
            menuShouldScrollIntoView={false}
            /**
             * We want the component to intelligently pick between displaying
             * the menu above, or below, depending on render scenario.
             */
            menuPlacement="auto"
            /**
             * By using the body, we can be sure that no parent element of the
             * Select component will clip the menu with `overflow: hidden`
             */
            menuPortalTarget={stayOpen ? undefined : document.body}
            defaultValue={findValue(defaultValue)}
            value={value !== undefined ? findValue(value) : undefined}
            formatOptionLabel={data => {
                if (isOptionGroup(data)) return data.label;
                return (
                    <span
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        `}>
                        <span
                            css={css`
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            `}>
                            {data.label ?? data.value}
                        </span>
                        {data.icon && <Icon size="sm" name={data.icon} />}
                    </span>
                );
            }}
            isOptionDisabled={option => option.disabled === true}
            onChange={handleChange}
            theme={builtin => ({
                ...builtin,
                colors: {
                    ...builtin.colors,
                    primary: outlineColour, // outline
                    primary25: theme.colours.brand.lightBlue, // hover
                },
            })}
            styles={useStyleConfig(requestedStyles)}
        />
    );
}

/**
 * Takes an array of Options or Groups and flattens it into a single array of
 * Options, with grouping removed.
 */
function flattenOptions<V>(options: Options<V>): Option<V>[] {
    const flattened: Option<V>[] = [];
    options?.map(opt => {
        if (isOptionGroup(opt)) flattened.push(...opt.options);
        else flattened.push(opt);
    });
    return flattened;
}
