import { ReactElement } from "react";

import styled from "@emotion/styled";
import { isNull } from "lodash";

import { InvocationState } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";
import { Progress } from "components/common/Progress";
import { Invocation } from "services/hooks/useCultureStepInvocations";
import { useRenderTimer } from "services/hooks/useRenderTimer";

import { log as parentLog } from "../log";

export const log = parentLog.extend("InvocationStateIndicator");

export interface InvocationStateIconProps {
    invocation: Invocation;
    hasSimulationError?: boolean;
}

export function InvocationStateIconContainer(
    props: InvocationStateIconProps,
): ReactElement {
    return (
        <IconContainerDiv>
            <InvocationStateIcon {...props} />
        </IconContainerDiv>
    );
}

function InvocationStateIcon(props: InvocationStateIconProps): ReactElement {
    const { state, timeStarted, estimatedDuration } = props.invocation;
    const runningProgressAvailable =
        !isNull(timeStarted) &&
        !isNull(estimatedDuration) &&
        estimatedDuration > 0;

    if (props.hasSimulationError) {
        return (
            <FailedIconContainer>
                <Icon name="cross" size="sm" />
            </FailedIconContainer>
        );
    }

    switch (state) {
        case InvocationState.Complete:
            return (
                <CompleteIconContainer>
                    <Icon name="check" size="md" />
                </CompleteIconContainer>
            );
        case InvocationState.Failed:
            return (
                <FailedIconContainer>
                    <Icon name="cross" size="sm" />
                </FailedIconContainer>
            );
        case InvocationState.Running:
            if (runningProgressAvailable) {
                return (
                    <InvocationProgressWheel
                        timeStarted={timeStarted}
                        estimatedDuration={estimatedDuration}
                    />
                );
            } else {
                return <Progress value={undefined} />;
            }
        case InvocationState.Paused:
            return <Icon name="pause-circle" size="md" />;
        case InvocationState.Planned:
        case InvocationState.Waiting:
            return <Progress value={0} />;
        case InvocationState.Ignored:
        case InvocationState.Removed:
        case InvocationState.Unknown:
        case InvocationState.Cancelled:
        case InvocationState.Cancelling:
        case null:
            return (
                <IgnoredContainer>
                    <IgnoredIcon />
                </IgnoredContainer>
            );
    }
}

function InvocationProgressWheel({
    timeStarted,
    estimatedDuration,
}: {
    timeStarted: string;
    estimatedDuration: number;
}) {
    useRenderTimer({ seconds: 0.2 });
    const now = new Date();
    const timeDiffMilliseconds =
        now.getTime() - new Date(timeStarted).getTime();
    const progress = (timeDiffMilliseconds / estimatedDuration) * 100;
    const cappedProgress = progress >= 100 ? undefined : progress;
    const cappedAndMinProgress = progress <= 10 ? 10 : cappedProgress;
    return <Progress value={cappedAndMinProgress} />;
}

const IconContainerDiv = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FailedIconContainer = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colours.accent.errorRed};
    color: ${({ theme }) => theme.colours.neutral.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IgnoredContainer = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colours.neutral[300]};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IgnoredIcon = styled.div`
    height: 2px;
    width: 8px;
    background-color: ${({ theme }) => theme.colours.neutral[100]};
`;

const CompleteIconContainer = styled.div`
    color: ${({ theme }) => theme.colours.brand.greenDark};
`;
