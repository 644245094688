import { useState, ReactElement } from "react";

import styled from "@emotion/styled";
import { Box, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";

import { StatusCheck, StatusCheckState } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Icon from "components/common/Icon";
import { IconButton } from "components/common/IconButton";
import Skeleton from "components/common/Skeleton";
import { Switch } from "components/common/Switch";
import Txt from "components/common/Text";
import { useFeature } from "services/feature-flags";

import { useAutomatedStartCheckOverride } from "../CreateCultureState";

import { useAutomatedChecks } from "./useAutomatedChecks";

export interface AutomatedStartChecksViewProps {
    deviceId: string;
}

export interface AutomatedStartChecksViewContentProps {
    checks: Array<StatusCheck>;
    allPass: boolean;
    loading: boolean;
    canSkipDryTest: boolean;
}

export function AutomatedStartChecksView({
    deviceId,
}: AutomatedStartChecksViewProps): ReactElement {
    const checks = useAutomatedChecks(deviceId);
    const canSkipDryTest = useFeature(
        "ability_to_skip_required_create_culture_checks",
    ).enabled;
    return (
        <AutomatedStartChecksViewContent
            {...checks}
            canSkipDryTest={canSkipDryTest}
        />
    );
}

export function AutomatedStartChecksViewContent({
    checks,
    allPass,
    loading,
    canSkipDryTest,
}: AutomatedStartChecksViewContentProps): ReactElement {
    const { automatedStartCheckOverride, setAutomatedStartCheckOverride } =
        useAutomatedStartCheckOverride();

    const numberSkeletonChecks = 3;

    return (
        <div
            style={{
                display: "flex",
                gap: "32px",
                flexDirection: "column",
                width: "100%",
            }}>
            <div
                style={{
                    display: "flex",
                    gap: "12px",
                    flexDirection: "column",
                }}>
                <Txt font="primary" level={5} emphasis>
                    Device status
                </Txt>
                <Txt font="secondary" level={8}>
                    If all checks are passing, the device is ready to start a
                    culture.
                </Txt>
            </div>
            {canSkipDryTest && (
                <Callout variant="info">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}>
                        <Txt font="secondary" level={8}>
                            Override status checks requirement
                        </Txt>
                        <Switch
                            size="sm"
                            id={"override-status-checks"}
                            checked={automatedStartCheckOverride}
                            onChange={setAutomatedStartCheckOverride}
                        />
                    </div>
                </Callout>
            )}
            <div>
                <Box
                    sx={{
                        paddingBottom: "12px",
                        color: theme => theme.colours.neutral[700],
                    }}>
                    <Txt font="secondary" level={8} emphasis>
                        Automated status checks
                    </Txt>
                </Box>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    }}>
                    {loading && isEmpty(checks)
                        ? Array.from({ length: numberSkeletonChecks }).map(
                              (_, index) => (
                                  // Same height as automated check component
                                  <Skeleton key={index} height={24} />
                              ),
                          )
                        : checks.map(check => (
                              <AutomatedCheck key={check.type} {...check} />
                          ))}
                </div>
            </div>
            {!allPass && (
                <Callout variant="error">
                    Device failed status checks. Please contact support.
                </Callout>
            )}
        </div>
    );
}

const AutomatedCheckTextAndIcon = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const CheckIcon = styled(Icon)`
    color: ${({ theme }) => theme.colours.red[500]};
`;

const InfoButton = styled(IconButton)`
    color: ${({ theme }) => theme.colours.neutral[500]};
    &:hover {
        color: ${({ theme }) => theme.colours.neutral[700]};
    }
`;

function AutomatedCheck(check: StatusCheck) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipToggle = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const { state, reason, type, name } = check;
    const pass = state !== StatusCheckState.Error;

    return (
        <div
            style={{
                display: "flex",
                gap: "4px",
            }}>
            <AutomatedCheckTextAndIcon key={type}>
                <CheckIcon
                    name={pass ? "checkmark" : "alert-cross"}
                    size="lg"
                />
                <Txt font="secondary" level={8}>
                    {name}
                </Txt>
            </AutomatedCheckTextAndIcon>
            <Tooltip
                title={reason}
                placement="right"
                open={tooltipOpen}
                onClose={handleTooltipToggle}>
                <InfoButton
                    name="info-circle"
                    icon={"info-circle"}
                    size="sm"
                    disableRipple
                    onClick={handleTooltipToggle}
                />
            </Tooltip>
        </div>
    );
}
