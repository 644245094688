import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";

export function useDeviceNotificationPreference(deviceId: string): {
    enabled: boolean | null;
    loading: boolean;
} {
    const { loading, data } = useQuery(DEVICE_NOTIFICATION_ENABLED_QUERY, {
        variables: { deviceId },
    });

    return {
        enabled: data?.device?.users?.myAccess?.notificationsEnabled ?? null,
        loading,
    };
}

const DEVICE_NOTIFICATION_ENABLED_QUERY = gql(`
    query DeviceNotificationsEnabled($deviceId: String!) {
        device(id: $deviceId) {
            id
            users {
                myAccess {
                    id
                    notificationsEnabled
                }
            }
        }
    }
`);
