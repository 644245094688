import React, { ReactElement } from "react";

import { SvgIcon, SxProps, Theme } from "@mui/material";

import AlertCross from "icons/alert-cross.svg?react";
import AlertCircle from "icons/alerts/alert-circle.svg?react";
import AlertTriangle from "icons/alerts/alert-triangle.svg?react";
import ArrowIntoCircle from "icons/arrows/arrow-into-circle.svg?react";
import ArrowLeft from "icons/arrows/arrow-left.svg?react";
import ArrowRight from "icons/arrows/arrow-right.svg?react";
import BarcodeScan from "icons/barcode-scan.svg?react";
import BellCrossed from "icons/bell-crossed.svg?react";
import BellRing from "icons/bell-ring.svg?react";
import BellSnooze from "icons/bell-snooze.svg?react";
import BellTimer from "icons/bell-timer.svg?react";
import Bell from "icons/bell.svg?react";
import Bin from "icons/bin.svg?react";
import Cable from "icons/cable.svg?react";
import Calendar from "icons/calendar.svg?react";
import Check from "icons/check.svg?react";
import CheckMark from "icons/checkmark.svg?react";
import ChevronDoubleLeft from "icons/chevrons/chevron-double-left.svg?react";
import ChevronDoubleRight from "icons/chevrons/chevron-double-right.svg?react";
import ChevronDown from "icons/chevrons/chevron-down.svg?react";
import ChevronLeft from "icons/chevrons/chevron-left.svg?react";
import ChevronRight from "icons/chevrons/chevron-right.svg?react";
import ChevronUp from "icons/chevrons/chevron-up.svg?react";
import Clipboard from "icons/clipboard.svg?react";
import Clock from "icons/clock.svg?react";
import Collapse from "icons/collapse.svg?react";
import ConstructionSign from "icons/construction-sign.svg?react";
import Copy from "icons/copy.svg?react";
import Cross from "icons/cross.svg?react";
import DesignToolWireframe from "icons/design-tool-wireframe.svg?react";
import DeviceSidecar from "icons/device-sidecar.svg?react";
import Disable from "icons/disable.svg?react";
import Download from "icons/download.svg?react";
import Draggable from "icons/draggable.svg?react";
import Edit from "icons/edit.svg?react";
import Email from "icons/email.svg?react";
import Expand from "icons/expand.svg?react";
import ExternalLink from "icons/external-link.svg?react";
import EyeClosed from "icons/eye-closed.svg?react";
import EyeOpen from "icons/eye-open.svg?react";
import Filter from "icons/filter.svg?react";
import Filter2 from "icons/filter2.svg?react";
import Flag from "icons/flag.svg?react";
import HandStop from "icons/hand-stop.svg?react";
import Heart from "icons/heart.svg?react";
import HelpBook from "icons/help-book.svg?react";
import Hourglass from "icons/hourglass.svg?react";
import Hyperlink from "icons/hyperlink.svg?react";
import InfoCircle from "icons/info-circle.svg?react";
import InfoFilled from "icons/info-filled.svg?react";
import LabTubExperiment from "icons/lab-tube-experiment.svg?react";
import LayoutDashboard from "icons/layout-dashboard.svg?react";
import List from "icons/list.svg?react";
import Lock from "icons/lock.svg?react";
import Logout from "icons/logout.svg?react";
import Menu from "icons/menu.svg?react";
import MoreHorizontal from "icons/more-horizontal.svg?react";
import NotesBook from "icons/notes-book.svg?react";
import NotesPaperText from "icons/notes-paper-text.svg?react";
import PauseCircle from "icons/pause-circle.svg?react";
import Person from "icons/person.svg?react";
import Pin from "icons/pin.svg?react";
import PlayCircle from "icons/play-circle.svg?react";
import PlusCircle from "icons/plus-circle.svg?react";
import Plus from "icons/plus.svg?react";
import Power from "icons/power.svg?react";
import PushNotification from "icons/push-notification.svg?react";
import QuestionHelpMessage from "icons/question-help-message.svg?react";
import Recent from "icons/recent.svg?react";
import RotateCW from "icons/rotate/rotate-cw.svg?react";
import ScaleMaximise2 from "icons/scale/maximise-2.svg?react";
import ScaleMaximise from "icons/scale/maximise.svg?react";
import ScaleMinimise2 from "icons/scale/minimise-2.svg?react";
import ScaleMinimise from "icons/scale/minimise.svg?react";
import Search from "icons/search.svg?react";
import SettingsToggleHorizontal from "icons/settings-toggle-horizontal.svg?react";
import Settings from "icons/settings.svg?react";
import Sidebar from "icons/sidebar.svg?react";
import Skip from "icons/skip.svg?react";
import Slack from "icons/slack.svg?react";
import Sliders from "icons/sliders.svg?react";
import Sms from "icons/sms.svg?react";
import SoftwareDownload from "icons/software-download.svg?react";
import StopCircle from "icons/stop-circle.svg?react";
import Switcharoo from "icons/switcharoo.svg?react";
import SyncArrows from "icons/sync-arrows.svg?react";
import TaskListClipboard from "icons/task-list-clipboard.svg?react";
import TouchSelect from "icons/touch-select.svg?react";
import UndoFilled from "icons/undo-filled.svg?react";
import Undo from "icons/undo.svg?react";
import XSquare from "icons/x-square.svg?react";

import { textLevelLineHeight, TxtProps } from "./Text";

const iconNameMap = {
    "menu": Menu,
    "flag": Flag,
    "arrow-right": ArrowRight,
    "arrow-left": ArrowLeft,
    "person": Person,
    "logout": Logout,
    "question-help-message": QuestionHelpMessage,
    "settings-toggle-horizontal": SettingsToggleHorizontal,
    "chevron-down": ChevronDown,
    "alert-circle": AlertCircle,
    "alert-triangle": AlertTriangle,
    "rotate-cw": RotateCW,
    "scale-maximise": ScaleMaximise,
    "scale-maximise2": ScaleMaximise2,
    "scale-minimise": ScaleMinimise,
    "scale-minimise2": ScaleMinimise2,
    "clipboard": Clipboard,
    "external-link": ExternalLink,
    "plus": Plus,
    "download": Download,
    "notes-paper-text": NotesPaperText,
    "bin": Bin,
    "cross": Cross,
    "checkmark": CheckMark,
    "eye-open": EyeOpen,
    "eye-closed": EyeClosed,
    "edit": Edit,
    "chevron-up": ChevronUp,
    "chevron-left": ChevronLeft,
    "chevron-right": ChevronRight,
    "check": Check,
    "stop-circle": StopCircle,
    "play-circle": PlayCircle,
    "pause-circle": PauseCircle,
    "email": Email,
    "sms": Sms,
    "slack": Slack,
    "push-notification": PushNotification,
    "power": Power,
    "cable": Cable,
    "expand": Expand,
    "collapse": Collapse,
    "arrow-into-circle": ArrowIntoCircle,
    "pin": Pin,
    "software-download": SoftwareDownload,
    "sync-arrows": SyncArrows,
    "calendar": Calendar,
    "copy": Copy,
    "more-horizontal": MoreHorizontal,
    "plus-circle": PlusCircle,
    "search": Search,
    "sliders": Sliders,
    "touch-select": TouchSelect,
    "disable": Disable,
    "skip": Skip,
    "clock": Clock,
    "bell": Bell,
    "bell-crossed": BellCrossed,
    "bell-ring": BellRing,
    "bell-snoozed": BellSnooze,
    "bell-timer": BellTimer,
    "recent": Recent,
    "x-square": XSquare,
    "hyperlink": Hyperlink,
    "hourglass": Hourglass,
    "alert-cross": AlertCross,
    "settings": Settings,
    "chevron-double-right": ChevronDoubleRight,
    "chevron-double-left": ChevronDoubleLeft,
    "filter": Filter,
    "filter2": Filter2,
    "device-sidecar": DeviceSidecar,
    "dashboard": LayoutDashboard,
    "lab-tube-experiment": LabTubExperiment,
    "design-tool-wireframe": DesignToolWireframe,
    "notes-book": NotesBook,
    "task-list-clipboard": TaskListClipboard,
    "lock": Lock,
    "sidebar": Sidebar,
    "heart": Heart,
    "list": List,
    "draggable": Draggable,
    "construction-sign": ConstructionSign,
    "info-circle": InfoCircle,
    "undo": Undo,
    "undo-filled": UndoFilled,
    "info-filled": InfoFilled,
    "barcode-scan": BarcodeScan,
    "hand-stop": HandStop,
    "switcharoo": Switcharoo,
    "help-book": HelpBook,
} as const;

const iconNamesWithDefaultColour: IconName[] = ["checkmark"];

const iconNamesWithFill: IconName[] = [
    "checkmark",
    "expand",
    "collapse",
    "disable",
    "undo-filled",
    "info-filled",
];

/**
 * Array of strings denoting available icon names that can be rendered by the
 * Icon component
 */
export const iconNames = Object.freeze(Object.keys(iconNameMap));
/**
 * String union of available icon names that can be rendered by the Icon
 * component
 */
export type IconName = keyof typeof iconNameMap;

export interface IconProps {
    /**
     * Name of the icon to render
     */
    name: IconName;
    /**
     * Colour is usually configured by setting the CSS `color` property, however
     * if this property is provided it will override any value passed inherited
     * via CSS styling.
     *
     * Note this property will be overriden if colour is set directly via the
     * `style` property.
     */
    colourOverride?: string;
    /**
     * If `true` will prevent any other colour styles being applied to the SVG
     * to ensure its default colours remain unaffected.
     */
    defaultColour?: boolean;
    /**
     * Shortcut property to applying a numeric rotation (in degrees) to the
     * underlying SVG.
     */
    rotate?: number;
    /**
     * Mirrors the level parameter of the generic <Text> component. When set, it
     * configures the size of the icon to be equal that of the line height of
     * the the provided level.
     *
     * _**Note:** this property can be overriden by other properties to set the icon
     * size._
     */
    level?: TxtProps["level"];
    /**
     * If set will override the fontSize to configure the size of the icon. If
     * unset, the font size property defaults to inheriting from parent.
     *
     * - Small is 12px
     * - Normal is 16px
     * - Large is 24px (the svg default)
     *
     * _**Note:** this property will override other properties to control the
     * icon size._
     */
    size?: "sm" | "md" | "lg";
    /**
     * CSS Class name applied to base element
     */
    className?: string;
    /**
     * Element style applied to base
     */
    style?: React.CSSProperties;
    /**
     * Mui Sx prop passed through to wrapped Mui component
     */
    sx?: SxProps<Theme>;
}

/**
 * Standard component for Icons in the application. Wraps the Material UI
 * SvgIcon component to make it compatible with that API.
 *
 * - Size is inherited from `fontSize`.
 * - Colour is inherited from `color`. Can be overridden with the
 *   `overrideColour` property.
 */
export default function Icon(props: IconProps): ReactElement {
    const {
        name,
        style,
        level,
        size,
        colourOverride,
        defaultColour,
        rotate,
        className,
    } = props;
    const passthroughProps = { rotate, className };

    let fontSize: string | number | undefined = "inherit"; // default for override
    if (size) {
        if (size === "lg") fontSize = 24;
        else if (size === "md") fontSize = 16;
        else if (size === "sm") fontSize = 12;
    } else if (level) {
        fontSize = textLevelLineHeight[level];
    }

    /** Boolean for avoiding colour overrides */
    const leaveDefaultColour =
        defaultColour || iconNamesWithDefaultColour.includes(name);

    /** Detect icons where fill applies not stroke */
    const preferFillToStroke = iconNamesWithFill.includes(name);

    const stroke =
        preferFillToStroke || leaveDefaultColour
            ? undefined
            : (colourOverride ?? "currentColor");

    const fill =
        !preferFillToStroke || leaveDefaultColour
            ? undefined
            : (colourOverride ?? "currentColor");

    return (
        <SvgIcon
            component={iconNameMap[name]}
            sx={{
                fontSize,
                // Material UI defaults to "currentColor" but our icons
                // shouldn't have "fill" applied since they are outlines
                "fill": "none",
                // A useful shortcut to rotating an existing icon
                "transform": `rotate(${props.rotate}deg)`,
                "& path,line,rect": {
                    stroke,
                    fill,
                },
                "& circle": {
                    stroke,
                },
                ...props.sx,
            }}
            style={{
                ...style, // Apply the style object passed to this component
            }}
            // Pass the rest of the props (except those specific to this
            // component) to the underlying wrapped component
            {...passthroughProps}
        />
    );
}
