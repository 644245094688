import React, { ChangeEvent, ReactElement } from "react";

import styled from "@emotion/styled";
import { isNull, isNumber } from "lodash";

import { clamp } from "services/utils";

import Txt, {
    fontFamilyMap,
    textLevelFontSize,
    textLevelLineHeight,
} from "./Text";

export interface NumberInputProps {
    /**
     * Value displayed in text input
     */
    value: number | null;
    /**
     * Callback that get called on every character change of the input
     */
    onChange: (value: number | null) => void;
    /**
     * Input becomes required in context of a form
     */
    required?: boolean;
    /**
     * Input takes up full width of container
     */
    fullWidth?: boolean;
    /**
     * Disable editing of the input
     */
    disabled?: boolean;
    /**
     * Text string displayed to help user
     */
    helperText?: string;
    /**
     * If true, then don't show helper text, including helper text generated
     * due to e.g. min/max values being defined.
     */
    hideHelperText?: boolean;
    /**
     * Minimum allowed number for the input
     */
    min?: number;
    /**
     * Maximum allowed number for the input
     */
    max?: number;
    /**
     * The increment applied when increasing or decreasing the current
     * value.
     *
     * **Note: Does not apply to value validation.**
     */
    step?: number;
    /**
     * Automatically fixes input when user inputs invalid number
     */
    autocorrect?: boolean;
    /**
     * A dulled string to apply at the end of this input box
     */
    units?: string;
    /**
     * Automatically add commas to the input e.g. 1,000 instead of 1000,
     * defaults to false, to only be used with positive integers
     */
    autoComma?: boolean;
}

/**
 * An input field specifically tailored to receiving and managing numerical
 * input. This is a controlled component, so state must be managed by the
 * parent.
 *
 */
export function NumberInput({
    required = false,
    fullWidth = false,
    value,
    onChange,
    min,
    max,
    step,
    units,
    helperText,
    hideHelperText,
    disabled = false,
    autocorrect = true,
    autoComma = false,
}: NumberInputProps): ReactElement {
    const onValueChange = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        const { value } = event.target;
        const unclamped = value ? Number(value) : null;
        if (unclamped !== null && autocorrect) {
            const clamped = clamp(unclamped, { min, max });
            onChange?.(clamped);
            return;
        }
        onChange?.(unclamped);
    };

    const onValueChangeString = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === "") {
            onChange?.(null);
            return;
        }
        const unClampedNumber = parseNumber(value);
        if (isNull(unClampedNumber)) {
            return;
        }
        if (autocorrect) {
            const clamped = clamp(unClampedNumber, { min, max });
            onChange?.(clamped);
            return;
        }
        onChange?.(unClampedNumber);
    };

    const displayedHelperText = hideHelperText
        ? ""
        : renderHelperText(helperText, min, max, units);

    const valueBelowMin = value !== null && min !== undefined && value < min;
    const valueAboveMax = value !== null && max !== undefined && value > max;
    const error = Boolean(
        (required && value === null) || valueBelowMin || valueAboveMax,
    );

    const autoCommaOverrides: React.InputHTMLAttributes<HTMLInputElement> = {
        type: "text",
        onChange: onValueChangeString,
        value: isNumber(value) ? addCommasToNumber(value) : "",
    };

    return (
        <div
            style={{
                display: "block",
                width: fullWidth ? "100%" : 256,
            }}>
            {/* Label wraps the whole control so that native focusing
                        behaviour applies to the unit section as well as the main box */}
            <label>
                <InputBox error={error} disabled={disabled}>
                    <NumberTextArea
                        // Padding is explicitly set in child elements
                        // rather than parent to maximise the area that
                        // has 'input' behaviours
                        // (eg cursor appearing close to where you click)
                        style={{
                            paddingTop: 6,
                            paddingBottom: 6,
                            paddingLeft: 12,
                            paddingRight: units ? 6 : 12,
                        }}
                        type="number"
                        required={required}
                        onChange={onValueChange}
                        value={value ?? ""}
                        step={step}
                        max={max}
                        min={min}
                        disabled={disabled}
                        {...(autoComma ? autoCommaOverrides : {})}
                    />
                    {units && (
                        <UnitText level={7} style={{ paddingRight: 12 }}>
                            {units}
                        </UnitText>
                    )}
                </InputBox>
            </label>
            {displayedHelperText && (
                <HelperText error={error} disabled={disabled}>
                    {displayedHelperText}
                </HelperText>
            )}
        </div>
    );
}

export function addCommasToNumber(num: number): string {
    // Note: will need to update when we internationalise
    return new Intl.NumberFormat("en-US").format(num);
}

export function parseNumber(num: string): number | null {
    const numberOrNan = Number(num.replace(/,/g, ""));
    return isNaN(numberOrNan) ? null : numberOrNan;
}

const InputBox = styled.div<{ error: boolean; disabled: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;

    cursor: text;

    border-radius: 4px;
    border: 1px solid
        ${({ theme, error }) =>
            error ? theme.colours.orange[500] : theme.colours.neutral[500]};

    &:hover {
        border: 1px solid
            ${({ theme, error, disabled }) => {
                if (error) {
                    return theme.colours.orange[500];
                }
                if (disabled) {
                    return theme.colours.neutral[500];
                }
                return theme.colours.neutral[900];
            }};
    }

    &:focus-within {
        border: 1px solid
            ${({ theme, error }) =>
                error
                    ? theme.colours.orange[500]
                    : theme.colours.brandBlue[500]};
    }
`;

const NumberTextArea = styled.input`
    font-family: ${fontFamilyMap.secondary};
    font-size: ${textLevelFontSize[8]};
    width: 100%;
    text-overflow: ellipsis;
    height: 34px;
    border: none;
    outline: none;
    background-color: transparent;
    color: ${({ theme, disabled }) =>
        disabled ? theme.colours.neutral[500] : theme.colours.neutral[900]};
`;

const UnitText = styled(Txt)`
    color: ${({ theme }) => theme.colours.neutral[700]};
    height: 22px;
    padding-right: 12px;
    white-space: nowrap;
`;

const HelperText = styled.span<{ error: boolean; disabled: boolean }>`
    margin-left: 0;
    margin-top: 4px;
    font-family: ${fontFamilyMap.secondary};
    font-size: ${textLevelFontSize[9]};
    line-height: ${textLevelLineHeight[9]};
    color: ${({ theme, error, disabled }) => {
        if (error) {
            return theme.colours.orange[500];
        }
        if (disabled) {
            return theme.colours.neutral[500];
        }
        return theme.colours.neutral[700];
    }};
`;

const renderHelperText = (
    helperString = "",
    min?: number,
    max?: number,
    units = "",
): string => {
    if (min !== undefined && max !== undefined) {
        return `${helperString} (${min} - ${max}${units ? ` ${units}` : ""})`;
    } else if (min !== undefined) {
        return `${helperString} (min ${min}${units ? ` ${units}` : ""})`;
    } else if (max !== undefined) {
        return `${helperString} (max ${max}${units ? ` ${units}` : ""})`;
    } else {
        return helperString;
    }
};
