import { CSSProperties } from "react";

import MuiAvatar from "@mui/material/Avatar";

export type AvatarProps = {
    border?: boolean;
    src?: string;
};

export function Avatar(props: AvatarProps): JSX.Element {
    let style: CSSProperties = {};
    if (props.border === true) {
        style = {
            border: "1px solid #E6EBEB",
            borderRadius: "50%",
        };
    }

    return (
        <div id="avatar" style={style}>
            <MuiAvatar
                src={props.src}
                style={{
                    margin: "2px",
                    background: "#E6EBEB",
                    color: "#757575",
                    height: "35px",
                    width: "35px",
                }}></MuiAvatar>
        </div>
    );
}
