import { authentication } from "./authentication";

/**
 * Creates the value of the "Authorization" header that should be used to
 * authenticate with the server.
 *
 * @param token - Optional JWT token to use (if not provided calls the
 * authentication module to get the current JWT)
 * @returns Example: "Bearer ejKf34Rv..."
 */
export function createAuthHeader(
    token = authentication.getDeveloperJWT(),
): string {
    const header = token ? `Bearer ${token}` : "";
    return header;
}
