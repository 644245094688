import { ReactElement } from "react";

import { CultureState } from "__generated__/apollo/graphql";
import { CultureStatusBadge } from "components/common/CultureStatusBadge";
import { HeaderBreadcrumb } from "components/common/HeaderBreadcrumb";
import { useIsMobile } from "services/hooks/window";

import { CultureControlButton } from "../Device/Culture/CultureControlButton";
import { useScheduleIsViewOnly } from "../Device/Schedule/schedule-data";

interface CultureHeaderContentProps {
    deviceId?: string;
    cultureId: string;
    cultureName: string;
    loading: boolean;
    waitingForConfirmation: boolean | null | undefined;
    cultureState?: CultureState | null;
    wetTestCultureState?: CultureState | null;
    cultureHasProcedureToRun: boolean;
    cultureErrorMessage?: string | null;
}

export default function CultureHeaderContent(
    props: CultureHeaderContentProps,
): ReactElement {
    const {
        cultureName,
        cultureId,
        loading,
        waitingForConfirmation,
        cultureState,
        wetTestCultureState,
        deviceId,
        cultureHasProcedureToRun,
        cultureErrorMessage,
    } = props;
    const isSmScreen = useIsMobile();
    const viewOnly = useScheduleIsViewOnly();

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <HeaderBreadcrumb
                    crumbs={[
                        { text: "Cultures", linkTo: "/cultures" },
                        {
                            text: cultureName,
                            skeleton: !cultureName && loading,
                        },
                    ]}
                />
                <CultureStatusBadge
                    cultureState={cultureState}
                    wetTestCultureState={wetTestCultureState}
                    errorMessage={cultureErrorMessage}
                    waitingForConfirmation={waitingForConfirmation}
                    loading={!cultureState && loading}
                />
            </div>
            <div>
                {!viewOnly && deviceId && (
                    <CultureControlButton
                        cultureId={cultureId}
                        deviceId={deviceId}
                        variant="primary"
                        size={isSmScreen ? "s" : "m"}
                        cultureHasProcedureToRun={cultureHasProcedureToRun}
                    />
                )}
            </div>
        </div>
    );
}
