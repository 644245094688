import { createRoot } from "react-dom/client";

import { config } from "services/config";
import { attachGlobals } from "services/global";
import defaultLog from "services/logger";
import { initLogrocket } from "services/logrocket";
import { initialiseSentry } from "services/sentry";

import App from "./App";
import * as serviceWorker from "./serviceWorkerRegistration";

// Base css file
import "./index.css";

initialiseSentry();
initLogrocket();

const log = defaultLog.extend("index");

const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container);

const main = async (): Promise<void> => {
    await config.init();
    attachGlobals(); // TODO disable in production?

    log.debug("First app render");
    root.render(<App />);
};
void main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    updateInterval: 2 * 60 * 1000, // 2 minute
    // onSuccess: () => config.setServiceWorkerInitialised(true),
    onUpdate: reg => config.setServiceWorkerRegistration(reg),
    onRegistration: reg => config.setServiceWorkerRegistration(reg),
});
