import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";

const DEVICE_RELEASE_QUERY = gql(`
    query DeviceRelease($deviceId: String!) {
        device(id: $deviceId) {
            id
            balena {
                deviceUuid
                availableReleases {
                    commit
                    id
                    createdAt
                }
            }
        }
    }
`);

export function useDeviceReleases(deviceId: string) {
    const { data, loading } = useQuery(DEVICE_RELEASE_QUERY, {
        variables: { deviceId },
    });

    const releases = data?.device?.balena?.availableReleases;

    return {
        releases,
        loading,
    };
}
