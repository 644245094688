import { useState } from "react";

import styled from "@emotion/styled";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as ToggleGroup from "@radix-ui/react-toggle-group";

import { ButtonSize } from "./Button";
import Icon from "./Icon";
import Txt from "./Text";

const StyledContent = styled(DropdownMenu.Content)`
    background-color: white;
    border-radius: 8px;
    box-shadow:
        0px 10px 38px -10px rgba(22, 23, 24, 0.35),
        0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    width: 100%;
`;

const StyledItem = styled(DropdownMenu.Item)`
    user-select: none;
    outline: none;

    color: ${({ theme }) => theme.colours.neutral[700]};
    border-bottom: 1px solid ${({ theme }) => theme.colours.neutral[300]};

    padding: 6px 12px;
    &[data-highlighted] {
        background-color: ${({ theme }) => theme.colours.neutral[300]};
        color: ${({ theme }) => theme.colours.neutral[800]};
    }

    &[data-disabled] {
        pointer-events: none;
        color: ${({ theme }) => theme.colours.neutral[500]};
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: none;
    }
`;

const StyledArrow = styled(DropdownMenu.Arrow)`
    fill: white;
`;

const ToggleGroupWrapper = styled(ToggleGroup.Root)<{
    size: ButtonSize;
}>`
    display: flex;
    align-items: center;
    height: ${({ size }) => {
        switch (size) {
            case "s":
                return "30px";
            case "m":
                return "34px";
            case "l":
                return "38px";
        }
    }};
`;

const ToggleGroupItem = styled(ToggleGroup.Item)<{
    variant?: "primary" | "secondary";
    disabled?: boolean;
}>`
    height: 100%;
    opacity: ${({ disabled }) => (disabled ? 0.33 : 1)};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    color: ${({ theme, variant }) => {
        switch (variant) {
            case "primary":
                return "white";
            case "secondary":
                return theme.colours.neutral[700];
        }
    }};
    border: 1px solid;
    border-color: ${({ theme, variant }) => {
        switch (variant) {
            case "primary":
                return theme.colours.gradients.A;
            case "secondary":
                return theme.colours.neutral[600];
        }
    }};
    background: ${({ theme, variant, value }) => {
        switch (variant) {
            case "primary": {
                if (value === "dropdown") {
                    return theme.colours.brand.greenDark;
                }
                return theme.colours.gradients.C;
            }
            case "secondary":
                return theme.colours.neutral[200];
        }
    }};
    padding: 0;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ theme, variant, value }) => {
                switch (variant) {
                    case "primary": {
                        if (value === "dropdown") {
                            return theme.colours.brand.green;
                        }
                        return theme.colours.gradients.B;
                    }
                    case "secondary":
                        return theme.colours.neutral[300];
                }
            }};
        }
    }

    &:active {
        background: ${({ theme, variant }) => {
            switch (variant) {
                case "primary":
                    return theme.colours.gradients.C;
                case "secondary":
                    return theme.colours.neutral[400];
            }
        }};
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none;
        ${({ variant }) => variant === "primary" && "border: none;"}
    }

    &:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        ${({ variant }) =>
            variant === "primary" &&
            "border-top: none; border-right: none; border-bottom: none; "}
    }
`;

const IconWrapper = styled(Icon)<{ buttonSize: ButtonSize }>`
    width: ${({ buttonSize }) => {
        switch (buttonSize) {
            case "s":
                return "30px";
            case "m":
                return "34px";
            case "l":
                return "38px";
        }
    }};
`;

const ButtonText = styled.span<{ size: ButtonSize }>`
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;
    font-family: "Work Sans";
    font-size: ${({ size }) => {
        switch (size) {
            case "s":
                return "12px";
            case "m":
                return "14px";
            case "l":
                return "16px";
        }
    }};
`;

type DropDownButtonOption = {
    value: string;
    buttonLabel: string;
    dropdownLabel: string;
    onClick?: () => void;
    dropdownLabelDescription?: string;
    disabled?: boolean;
};

export interface DropdownButtonProps {
    size: ButtonSize;
    options: DropDownButtonOption[];
    variant?: "primary" | "secondary";
}

export const DropdownButton = ({
    size,
    options,
    variant = "secondary",
}: DropdownButtonProps) => {
    const [selectedValue, setSelectedValue] = useState(options[0]?.value);
    const selectedItem = options.find(option => option.value === selectedValue);

    const handleSelectedItemChange = (value: DropDownButtonOption) => {
        setSelectedValue(value.value);
    };

    return (
        <DropdownMenu.Root>
            <ToggleGroupWrapper
                type="single"
                value={selectedItem?.value}
                size={size}>
                <ToggleGroupItem
                    variant={variant}
                    value="option"
                    onClick={() => selectedItem?.onClick?.()}
                    disabled={selectedItem?.disabled}>
                    <ButtonText size={size}>
                        {selectedItem?.buttonLabel}
                    </ButtonText>
                </ToggleGroupItem>
                {options.length > 1 && (
                    <DropdownMenu.Trigger asChild>
                        <ToggleGroupItem value="dropdown" variant={variant}>
                            <div
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                }}>
                                <IconWrapper
                                    name="chevron-down"
                                    buttonSize={size}
                                />
                            </div>
                        </ToggleGroupItem>
                    </DropdownMenu.Trigger>
                )}
            </ToggleGroupWrapper>

            <StyledContent sideOffset={5} align="end">
                {options.map(option => (
                    <StyledItem
                        key={option.value}
                        onSelect={() => handleSelectedItemChange(option)}
                        {...(option.disabled && { "data-disabled": true })}>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "6px",
                                }}>
                                {option.value === selectedItem?.value ? (
                                    <Icon size="sm" name="check" />
                                ) : (
                                    <div style={{ width: "12px" }} />
                                )}
                                <Txt level={8} emphasis>
                                    {option.dropdownLabel}
                                </Txt>
                            </div>
                            <Txt
                                level={9}
                                font="secondary"
                                // Width of the check icon + 6px gap
                                style={{ marginLeft: "18px" }}>
                                {option.dropdownLabelDescription}
                            </Txt>
                        </div>
                    </StyledItem>
                ))}
                <StyledArrow />
            </StyledContent>
        </DropdownMenu.Root>
    );
};
