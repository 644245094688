import { ReactElement } from "react";

import { CellFormat } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Txt from "components/common/Text";

import {
    UseCellLineMetadata,
    useCellLineMetadata,
} from "../protocol-upload-hooks";
import { NamedInputRows } from "../SelectProtocol/InputRows";

export type CellLineMetadataViewContentProps = UseCellLineMetadata;

export function CellLineMetadataView(): ReactElement {
    const props = useCellLineMetadata();

    return <CellLineMetadataViewContent {...props} />;
}

export function CellLineMetadataViewContent({
    vialID,
    setVialID,
    cellLine,
    setCellLine,
    passageNumber,
    setPassageNumber,
    cellFormat,
    setCellFormat,
}: CellLineMetadataViewContentProps): ReactElement {
    return (
        <div
            style={{
                display: "flex",
                gap: 32,
                flexDirection: "column",
                width: "100%",
            }}>
            <div
                style={{
                    display: "flex",
                    gap: 12,
                    flexDirection: "column",
                }}>
                <Txt font="primary" level={5} emphasis>
                    Cell Line Information
                </Txt>
                <Callout variant="help">
                    See the Setup Guide for cell preparation instructions.
                </Callout>
                <Txt font="secondary" level={8}>
                    Optionally tag your culture with metadata about the cells
                    that you plan to use for seeding.
                </Txt>
            </div>

            <NamedInputRows
                rows={[
                    {
                        type: "text",
                        name: "Vial ID",
                        value: vialID,
                        onChange: setVialID,
                    },
                    {
                        type: "text",
                        name: "Cell line",
                        value: cellLine,
                        onChange: setCellLine,
                    },
                    {
                        type: "number",
                        name: "Passage number",
                        value: passageNumber,
                        onChange: setPassageNumber,
                        min: 0,
                        step: 1,
                        hideHelperText: true,
                        autocorrect: true,
                    },
                    {
                        type: "select",
                        name: "Cell format",
                        value: cellFormat,
                        onChange: value => setCellFormat(value),
                        multi: false,
                        options: [
                            {
                                label: "Single cell",
                                value: CellFormat.SingleCell,
                            },
                            { label: "Clump", value: CellFormat.Clump },
                        ],
                    },
                ]}
            />
        </div>
    );
}
