import { ModalContent } from "components/common/Modal/Modal";

import { AutomatedStartChecksView } from "../AutomatedChecks/AutomatedChecksView";
import { useAutomatedChecks } from "../AutomatedChecks/useAutomatedChecks";
import { useAutomatedStartCheckOverride } from "../CreateCultureState";
import { log as parentLog } from "../log";

export const log = parentLog.extend("AutomatedStartChecksModalContent");

export function AutomatedStartChecksModalContent({
    deviceId,
    onBackClick,
    onSubmit,
}: {
    deviceId: string;
    onBackClick: () => void;
    onSubmit: () => Promise<void | Error>;
}) {
    const { allPass: allAutomatedChecksPass, loading: automatedChecksLoading } =
        useAutomatedChecks(deviceId);
    const { automatedStartCheckOverride } = useAutomatedStartCheckOverride();

    const disabled =
        !(allAutomatedChecksPass || automatedStartCheckOverride) ||
        automatedChecksLoading;

    return (
        <ModalContent
            title="New Culture"
            buttonLeft={{
                label: "Back",
                onClick: onBackClick,
            }}
            buttonRight={{
                label: "Continue",
                disabled,
                onClick: onSubmit,
            }}>
            <AutomatedStartChecksView deviceId={deviceId} />
        </ModalContent>
    );
}
