import { InvocationState } from "__generated__/apollo/graphql";
import { dayjs, formatTimeDiff } from "services/date";
import { Invocation } from "services/hooks/useCultureStepInvocations";
import { toTitleCase } from "services/string-utils";

export function determineInvocationStatusString(
    invocation: Invocation,
): string {
    const state = invocation.state ?? InvocationState.Unknown;
    const isRunning = InvocationState.Running === invocation.state;
    let displayedState = toTitleCase(state?.toLowerCase());
    if (invocation.finished) {
        const invocationDuration =
            invocation.timeStarted && invocation.timeFinished
                ? formatTimeDiff(
                      invocation.timeStarted,
                      invocation.timeFinished,
                  )
                : "N/A";

        displayedState += ` after ${invocationDuration}`;
    } else if (invocation.estimatedDuration) {
        const estDuration = invocation.estimatedDuration
            ? dayjs
                  .duration(invocation.estimatedDuration, "milliseconds")
                  .humanize()
            : null;
        displayedState += ` (takes about ${estDuration})`;
    }

    const msSinceStart = dayjs().diff(invocation.timeStarted, "millisecond");
    if (isRunning) {
        const msRemaining = (invocation.estimatedDuration ?? 0) - msSinceStart;
        if (invocation.estimatedDuration && msRemaining > 0) {
            const remaining = dayjs
                .duration(msRemaining, "milliseconds")
                .format("HH:mm:ss");
            const trimmed =
                msRemaining < 3_600_000 ? remaining.slice(3) : remaining;
            displayedState = trimmed;
        } else if (invocation.estimatedDuration && msRemaining > -5_000) {
            displayedState = "00:00";
        } else {
            const runtime = dayjs.duration(msSinceStart, "milliseconds");
            let runtimeFormat: string;
            if (runtime.as("hours") < 1)
                runtimeFormat = runtime.format("mm:ss");
            else runtimeFormat = runtime.format("H:mm:ss");

            displayedState += ` for ${runtimeFormat}`;
        }
    }
    return displayedState;
}
