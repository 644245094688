import { useEffect } from "react";

/**
 * This hook disables horizontal overscrolling, which is a common interaction
 * used by browsers (such as Chrome) to control history navigation. This may
 * need to be used when page content has significant horizontal scrollable
 * content.
 *
 * To disable horizontal scroll we need to set CSS on the `html` tag.
 *
 * `html { overscroll-behavior-x: none; }`
 *
 * References:
 * - https://stackoverflow.com/questions/17474930/disable-chrome-two-fingers-back-forward-swipe
 * - https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior-x
 */
export function useDisableOverscrollBehaviour() {
    useEffect(() => {
        document.documentElement.style.overscrollBehaviorX = "none";
        return () => {
            // return to default setting
            document.documentElement.style.overscrollBehaviorX = "auto";
        };
    });
}
