import Container from "@mui/material/Container";

import DevicesContainer from "./DevicesContainer";

export function DevicesPage(): JSX.Element {
    return (
        <Container
            sx={{
                paddingTop: { xs: 4, md: 10 },
                paddingBottom: 10,
            }}
            maxWidth="md">
            <DevicesContainer />
        </Container>
    );
}
