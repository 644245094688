import { ReactElement } from "react";

import styled from "@emotion/styled";

import Button from "components/common/Button";
import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import { TabContent } from "components/common/TabContent";
import { useCultureSchedule } from "services/hooks/useCultureSchedule";

import { CultureError } from "../Device/Culture/CultureError";
import { SimulationError } from "../Device/Culture/SimulationError";

import { CultureScheduleList } from "./CultureScheduleList";
import CultureScheduleToolbar from "./CultureScheduleToolbar";
import { log as parentLog } from "./log";

export const log = parentLog.extend("CultureSchedule");

export function CultureSchedule(
    props: { deviceId: string } | { cultureId: string },
): ReactElement {
    const {
        culture,
        error,
        loading,
        deviceId,
        deviceHasCulture,
        refetch: refetchCulture,
    } = useCultureSchedule(props);

    const schedule = culture?.schedule;
    log.debug("Culture Schedule data", schedule);
    const cultureIsActive = culture?.isActive ?? false; // Default to false if null or undefined

    if (error) {
        log.error("Error requesting Culture Schedule", { error, schedule });
    }

    const content = (content: ReactElement): ReactElement => (
        <TabContent>{content}</TabContent>
    );

    if (!culture && loading) {
        return content(
            <>
                <CultureScheduleToolbar
                    refetchCulture={refetchCulture}
                    cultureIsWaitingForConfirmation={
                        schedule?.isWaitingOnConfirmation
                    }
                    cultureHasProcedureToRun={false}
                    cultureErrorMessage={null}
                />
                <Skeleton height={100} />
            </>,
        );
    }

    if (!deviceId) {
        log.error("Unable to determine Device ID for Culture Schedule");
        return content(
            <Callout
                variant="error"
                message="Something went wrong! We've let the engineers know."
            />,
        );
    }

    if (schedule) {
        return content(
            <>
                {culture?.errorMessage && (
                    <CultureError errorMessage={culture.errorMessage} />
                )}
                {culture?.lastSimulationError && (
                    <SimulationError
                        errorMessage={culture.lastSimulationError.errorMessage}
                    />
                )}
                <CultureScheduleToolbar
                    refetchCulture={refetchCulture}
                    deviceId={deviceId}
                    cultureId={culture.id}
                    isWetTest={culture.isWetTestCulture ?? undefined}
                    cultureState={culture.state}
                    cultureIsWaitingForConfirmation={
                        culture.schedule?.isWaitingOnConfirmation
                    }
                    cultureHasProcedureToRun={Boolean(schedule.nextProcedure)}
                    cultureErrorMessage={culture.errorMessage}
                />
                <CultureScheduleList
                    schedule={schedule}
                    deviceId={deviceId}
                    cultureId={culture.id}
                    cultureIsActive={cultureIsActive}
                    simulationErrorDetails={culture.lastSimulationError}
                    dayStartIndex={culture.dayStartIndex ?? 0}
                />
            </>,
        );
    }

    if (deviceHasCulture && culture === null) {
        log.error(
            "Device has culture but returned null. Unable to display culture schedule.",
        );
        return content(
            <Callout
                variant="error"
                message="It looks like the culture is unavailable right now. We've let the engineers know."
            />,
        );
    }

    if (deviceHasCulture === false && !error) {
        return content(
            <Callout message="No culture is active on this device">
                <NoCultureCalloutContent>
                    <Button
                        variant="secondary"
                        size="s"
                        iconRight="arrow-right"
                        linkTo={`/devices/${deviceId}/culture`}>
                        Set up a culture
                    </Button>
                </NoCultureCalloutContent>
            </Callout>,
        );
    }

    log.error(
        { deviceId, deviceHasCulture, culture, error },
        "Culture Schedule did not resolve to an expected case",
    );
    return content(
        <Callout
            variant="error"
            message="Something went wrong! We've let the engineers know."
        />,
    );
}

const NoCultureCalloutContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
