import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { round } from "lodash";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);

// import isToday from "dayjs/plugin/isToday";
// dayjs.extend(isToday);

export { dayjs, Dayjs };

/**
 * Supported timezones. This array is hardcoded so as to simplify the experience
 * for the user, rather than leaving it highly flexible but more complex.
 */
const TIMEZONES = [
    "Europe/London",
    "America/New_York",
    "America/Los_Angeles",
] as const;

/**
 * String union of possible timezone values
 */
export type Timezone = (typeof TIMEZONES)[number];

/**
 * Array of supported IANA timezone values
 */
export const SUPPORTED_TIMEZONES: readonly string[] = TIMEZONES;

/**
 * Guess the current timezone. Only returns timezones hardcoded in the
 * SUPPORTED_TIMEZONES array. Otherwise returns `undefined`.
 */
export function guessTimezone(): Timezone | undefined {
    const guess = dayjs.tz.guess();
    if (SUPPORTED_TIMEZONES.includes(guess)) return guess as Timezone;
}

/**
 * Returns new Dayjs object representing the current day at midnight, in the
 * local timezone.
 * @returns Dayjs object
 */
export const today = (): Dayjs => {
    const today = dayjs();
    return today.hour(0).minute(0).second(0).millisecond(0);
};

/**
 * Formats a string to describe the relative duration between provided data and
 * now.
 * @param date - Date as ISO string
 * @returns Formatted string
 */
export const relativeDatetime = (date: string): string => dayjs(date).fromNow();

/**
 * Formats a string in a full sentence including time and date.
 * @param date - Date as ISO string
 * @returns Formatted string
 */
export const formatDatetime = (date: string): string =>
    dayjs(date).format("HH:mm, D MMMM YYYY");

/**
 * Formats a string in a full sentence with just the date (no time).
 *
 * Options:
 * - `short` defaults `false`
 *
 * @param date - Date as ISO string
 * @param options - Optional options object
 * @returns Formatted string
 */
export const formatDate = (
    date: string,
    options?: { short?: boolean },
): string => {
    const template = options?.short ? "ddd, D MMM YYYY" : "D MMMM YYYY";
    return dayjs(date).format(template);
};

/**
 * Formats the timestamp in terms of the time (ignoring date).
 *
 * Options:
 * - `withSeconds` defaults `true`
 *
 * @param date - Date as ISO string
 * @param options - Optional options object
 * @returns Formatted string
 */
export const formatTime = (
    date: string,
    options?: { withSeconds?: boolean },
): string => {
    const withSeconds = options?.withSeconds ?? true;
    const template = withSeconds ? "HH:mm:ss" : "HH:mm";
    return dayjs(date).format(template);
};

/**
 * Converts Unix timestamp to ISO string
 * @param unix - Unix timestamp (seconds since epoch)
 * @returns ISO timestamp string
 */
export const unixToISO = (unix: number): string =>
    dayjs.unix(unix).toISOString();

/**
 * Converts milliseconds since the epoch into an ISO string
 * @param millis - Milliseconds since epoch
 * @returns ISO timestamp string
 */
export const millisToISO = (millis: number): string =>
    dayjs(millis).toISOString();

/**
 * Takes a duration in seconds and formats it as a duration relative to now.
 * @param uptimeSeconds - Duration in seconds
 * @returns Formatted string
 */
export const formatUptime = (uptimeSeconds: number): string =>
    dayjs().subtract(uptimeSeconds, "seconds").fromNow(false);

/**
 * Provides boolean true if the datetime provided is in the past.
 *
 * @param datetime - Time being referenced
 * @returns Relative time or the default message
 */
export const isInPast = (datetime: string): boolean => {
    const timeIsInPast = dayjs().valueOf() > dayjs(datetime).valueOf();
    return timeIsInPast;
};

/**
 * Returns true if provided date is today.
 */
export const isToday = (date: string): boolean => dayjs().isSame(date, "day");

/**
 * Returns the difference in seconds between 2 dates. This value will be
 * negative if the endDate is before the startDate.
 *
 * @param startDate - ISO timestamp for start time
 * @param endDate - ISO timestamp for end time
 * @returns The +/- time difference in number of seconds
 */
export const differenceInSeconds = (
    startDate: string | null,
    endDate: string,
): number => dayjs(endDate).diff(dayjs(startDate), "seconds");

/**
 * Returns a simple human-formatted duration. This format will ignore negative
 * durations.
 *
 * @param startDate - ISO timestamp for start time
 * @param endDate - ISO timestamp for end time
 * @returns The +/- time difference in number of seconds
 */
export const formatTimeDiff = (startDate: string, endDate: string): string => {
    const seconds = Math.abs(differenceInSeconds(startDate, endDate));
    return formatSecondsDuration(seconds);
};

/**
 * Takes a duration in seconds and formats it in human-friendly way. For example
 * `70` as input would return `1m 10s`.
 * @param seconds - Duration in seconds
 * @returns Formatted string
 */
export const formatSecondsDuration = (_seconds: number): string => {
    if (_seconds === 0) return "0s";
    const seconds = round(_seconds);
    const rSeconds = seconds % 60;
    const fSeconds = rSeconds > 0 ? `${rSeconds}s ` : "";

    const minutes = Math.floor(seconds / 60);
    const rMinutes = minutes % 60;
    const fMinutes = rMinutes > 0 ? `${rMinutes}m ` : "";

    const hours = Math.floor(minutes / 60);
    const fHours = hours > 0 ? `${hours}h ` : "";

    return `${fHours}${fMinutes}${fSeconds}`.trim();
};

/**
 * Takes an ISO date string which is assumed to be midnight in a target
 * timezone, but displayed in UTC format. This function infers the time offset
 * (in minutes) of the target timezone.
 *
 * Note: this function has a known limitation meaning it can only accurately
 * detect time offsets between -11 and +11
 *
 * @param date - An ISO date string in UTC
 * @returns Time offset
 */
export function inferUTCOffsetInMinutes(date: string): number {
    const hour = dayjs(date).utc().hour();
    const minute = dayjs(date).utc().minute();
    if (hour <= 12) {
        // we are west of GMT, therefore negative time diff
        const totalMinutes = hour * 60 + minute;
        return -totalMinutes;
    } else {
        // we must be east of GMT, therefore positive time diff
        const totalMinutes = (24 - hour) * 60 + minute;
        return totalMinutes;
    }
}

/**
 * Factory function for the `dayOfCulture` function. The factory takes the start
 * date of the culture as argument. This start date is ISO 8701 formatted, and
 * is assumed to be midnight of the timezone the protocol is configured for.
 *
 * The returned `dayOfCulture` function takes a target datetime, also formatted
 * as ISO 8701 string, and after converting it to the local timezone of the
 * culture will determine which culture day the datetime refers to.
 *
 * If either of the required dates is not provided, the function returns `null`.
 *
 * @param cultureStartDate - Start date of the culture
 * @returns Function that takes a target date and returns day of culture
 */
export function dayOfCultureFactory(
    cultureStartDate: string | null | undefined,
): (targetDate: string | null | undefined) => number | null {
    return targetDate => {
        if (!cultureStartDate || !targetDate) return null;
        const cultureTimeOffset = inferUTCOffsetInMinutes(cultureStartDate);
        /** Start date in the culture's time offset */
        const start = dayjs(cultureStartDate).utcOffset(cultureTimeOffset);
        /** Target date in the culture's time offset */
        const target = dayjs(targetDate).utcOffset(cultureTimeOffset);

        /** End point for measuring days - by looking at just the date */
        const end = target.hour(0).minute(0).second(0).millisecond(0);

        // `start` is assumed to be midnight
        return end.diff(start, "days");
    };
}

/**
 * Factory function that takes the culture start date as argument. The function
 * it returns can then take any ISO 8701 string and covert it to the culture's
 * timezone. Once in the culture's timezone, the function return 0 if the
 * timestamp occurs prior to noon, or 1 if the timestamp occurs after noon.
 *
 * If either of the required dates is not provided, the function returns `null`.
 *
 * @param timestamp - ISO 8701 timestamp
 */
export function cultureMorningOrAfternoon(
    cultureStartDate: string | null | undefined,
): (targetDate: string | null | undefined) => number | null {
    return targetDate => {
        if (!cultureStartDate || !targetDate) return null;
        const cultureTimeOffset = inferUTCOffsetInMinutes(cultureStartDate);
        const hour = dayjs(targetDate).utcOffset(cultureTimeOffset).hour();
        if (hour < 12) return 0;
        else return 1;
    };
}
