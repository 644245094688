import React, { ReactElement, useState } from "react";

import styled from "@emotion/styled";
import { Box, Container, Divider, useTheme } from "@mui/material";
import { isNull, isUndefined } from "lodash";

import Callout, { CalloutCard } from "components/common/Callout";
import Icon from "components/common/Icon";
import Modal from "components/common/Modal";
import { Tabs } from "components/common/Tabs";
import Txt from "components/common/Text";
import { TextLabel } from "components/common/TextLabel";
import { dayjs, Dayjs, isInPast } from "services/date";
import { toTitleCase } from "services/string-utils";

import { ModalButtonClickHandler } from "../Modal/Modal";
import { Select } from "../Select";

import { ManualRescheduleContent } from "./ManualRescheduleContent";
import { SuggestedRescheduleContent } from "./SuggestedRescheduleContent";

type onSubmitReschedule = (props: {
    targetDate: Date;
    cascade: boolean;
}) => Promise<void>;

/**
 * Props for the RescheduleModal component.
 */
export interface RescheduleModalProps {
    /**
     * The name of the item being rescheduled.
     */
    type: "step" | "procedure";
    /**
     * The name of the item being rescheduled.
     */
    name: string | null;
    /**
     * The initial planned time for the item being rescheduled.
     */
    initialTimePlanned: string | null;
    /**
     * Indicates whether the modal is open or not.
     */
    modalOpen: boolean;
    /**
     * Callback function to set the modal open state.
     */
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    /**
     * On submit callback function to action the reschedule.
     */
    onSubmit: onSubmitReschedule;
    /**
     * Indicate weather to show the cascade toggle option to the user in the modal
     */
    showCascadeOption?: boolean;
}

type tabIds = "suggested" | "schedule";

export function RescheduleModal(props: RescheduleModalProps): ReactElement {
    const {
        type,
        name,
        initialTimePlanned,
        modalOpen,
        setModalOpen,
        onSubmit,
        showCascadeOption = false,
    } = props;
    const theme = useTheme();
    const [desiredDate, setDesiredDate] = useState<Dayjs | undefined>(
        undefined,
    );
    const [cascade, setCascade] = useState<
        (typeof cascadeOptions)[number]["value"] | undefined
    >();
    const [cascadeMenuOpen, setCascadeMenuOpen] = useState<true | undefined>(
        undefined,
    );
    const [scheduleViewConfirm, setScheduleViewConfirm] = useState(false);
    const [activeTab, setActiveTab] = useState<tabIds>("suggested");
    const [selectedSuggestion, setSelectedSuggestion] = useState<number | null>(
        null,
    );

    const cascadeOptions = [
        {
            value: "item-only",
            label: `this ${type} only`,
        },
        {
            value: "item-and-subsequent",
            label: `this and subsequent ${type}s`,
        },
    ] as const;

    const isConfirmDisabled =
        activeTab === "suggested"
            ? isNull(selectedSuggestion)
            : !scheduleViewConfirm;

    const tabs = [
        {
            name: "Suggested",
            id: "suggested",
            content: (
                <SuggestedRescheduleContent
                    timePlanned={initialTimePlanned}
                    selectedSuggestion={selectedSuggestion}
                    setDesiredDate={setDesiredDate}
                    setSelectedSuggestion={setSelectedSuggestion}
                />
            ),
            onClick: () => {
                setActiveTab("suggested");
                setDesiredDate(undefined);
            },
        },
        {
            name: "Schedule",
            id: "schedule",
            content: (
                <ManualRescheduleContent
                    timePlanned={initialTimePlanned}
                    setDesiredDate={setDesiredDate}
                    desiredDate={desiredDate}
                    setScheduleViewConfirm={setScheduleViewConfirm}
                />
            ),
            onClick: () => {
                setActiveTab("schedule");
                setSelectedSuggestion(null);
                setDesiredDate(undefined);
            },
        },
    ];

    /**
     * Submits the reschedule request by calculating the time difference
     * between the desired date and the initial planned time.
     * @returns {Promise<void>}
     */
    async function onSubmitReschedule() {
        if (!desiredDate) {
            alert("Desired date is not defined");
            return;
        }
        await onSubmit({
            targetDate: desiredDate.toDate(),
            cascade: cascade === "item-and-subsequent",
        });
    }

    const timePlannedErrorCard = (
        <Callout variant="error">Time planned is not defined on the</Callout>
    );

    const absoluteTime = (timePlanned: string | null) =>
        dayjs(timePlanned).format("dddd, MMMM D, YYYY h:mm A");
    const relativeTimePlanned = (timePlanned: string | null) =>
        dayjs(timePlanned).fromNow();
    const functionalTimePlanned = (timePlanned: string | null) => {
        if (timePlanned)
            return isInPast(timePlanned)
                ? "As soon as possible"
                : absoluteTime(timePlanned);
        return timePlannedErrorCard;
    };

    const desiredDateISO = desiredDate?.toISOString();
    const desiredDateString = desiredDateISO
        ? absoluteTime(desiredDateISO)
        : "Time not selected";
    const desiredDateRelativeString =
        desiredDateISO && !isInPast(desiredDateISO)
            ? relativeTimePlanned(desiredDateISO)
            : "N/A";

    const sxPadding = {
        pt: { xs: 2, sm: 4 },
        px: { xs: 4, sm: 6 },
    };

    const clickConfirmHandler: ModalButtonClickHandler = async close => {
        if (showCascadeOption && isUndefined(cascade)) {
            setCascadeMenuOpen(true);
        } else {
            setCascadeMenuOpen(undefined);
            await onSubmitReschedule();
            close();
        }
    };

    return (
        <Modal
            title={`Reschedule ${toTitleCase(type)}`}
            buttonLeft={{ label: "Back", onClick: close => close() }}
            buttonRight={{
                label: "Confirm",
                disabled: isConfirmDisabled,
                onClick: clickConfirmHandler,
            }}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            contentPadding={0}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    position: "relative",
                }}>
                <Box
                    style={{
                        backgroundColor: theme.colours.neutral.white,
                        boxShadow:
                            "inset 0px -1px 0px " + theme.colours.neutral[300],
                    }}>
                    <Container maxWidth={"xl"} sx={sxPadding}>
                        <Txt
                            font="primary"
                            level={6}
                            display="inline"
                            emphasis
                            align="left">
                            {name}
                        </Txt>
                        <DividerWithYMargin />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    sm: "row",
                                },
                                alignItems: {
                                    xs: "flex-start",
                                    sm: "center",
                                },
                                gap: {
                                    xs: 2,
                                    sm: 4,
                                },
                            }}>
                            <TextLabel
                                label="Previous planned time"
                                childrenDirection="column">
                                <Txt
                                    level={7}
                                    sx={{
                                        color: theme =>
                                            theme.colours.neutral[700],
                                    }}>
                                    {functionalTimePlanned(initialTimePlanned)}
                                </Txt>
                                {initialTimePlanned &&
                                    !isInPast(initialTimePlanned) && (
                                        <Txt
                                            level={9}
                                            italic
                                            sx={{
                                                color: theme =>
                                                    theme.colours.neutral[700],
                                            }}>
                                            {relativeTimePlanned(
                                                initialTimePlanned,
                                            )}
                                        </Txt>
                                    )}
                            </TextLabel>
                            <Icon
                                name="arrow-right"
                                sx={{
                                    marginTop: 2,
                                    display: {
                                        xs: "none",
                                        sm: "initial",
                                    },
                                }}
                            />
                            <TextLabel
                                label="New planned time"
                                childrenDirection="column">
                                <Txt level={7}>{desiredDateString}</Txt>
                                <Txt level={9} italic>
                                    {desiredDateRelativeString}
                                </Txt>
                            </TextLabel>
                        </Box>
                        {showCascadeOption && (
                            <CascadeContainer>
                                <CalloutCard variant="info">
                                    <FlexRow>
                                        <Txt level={8}>
                                            {"Apply rescheduling change to"}
                                        </Txt>
                                        <Select
                                            options={cascadeOptions}
                                            stayOpen={cascadeMenuOpen}
                                            onChange={option => {
                                                setCascadeMenuOpen(undefined);
                                                setCascade(option);
                                            }}
                                        />
                                    </FlexRow>
                                </CalloutCard>
                            </CascadeContainer>
                        )}
                        <Tabs
                            tabs={tabs}
                            tabBackgroundColor={theme.colours.neutral[100]}
                        />
                    </Container>
                </Box>
                <Box
                    sx={sxPadding}
                    style={{
                        flexGrow: 1,
                        paddingBottom: 16,
                        backgroundColor: theme.colours.neutral[100],
                    }}>
                    {tabs.filter(tab => tab.id === activeTab)[0]?.content}
                </Box>
            </div>
        </Modal>
    );
}

const DividerWithYMargin = styled(Divider)`
    margin-top: 12px;
    margin-bottom: 12px;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const CascadeContainer = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
`;
