import React, { ReactElement } from "react";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { SwipeableDrawer, Toolbar } from "@mui/material";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useHotkeys } from "react-hotkeys-hook";
import { Link } from "react-router-dom";

import Icon from "components/common/Icon";
import { IconButton } from "components/common/IconButton";
import { OnlineIndicator } from "components/common/OnlineIndicator";
import StatusBadge from "components/common/StatusBadge";
import Txt from "components/common/Text";
import { useMediaQuery } from "services/hooks/window";

import { usePinnedDevices } from "./sidebar-state";
import { NavOption } from "./useNavOptions";

export const SIDEBAR_WIDTH_PX = 250;

const sidebarOpenAtom = atomWithStorage<boolean>(
    "sidebar_open",
    window.innerWidth > 900,
    undefined,
    // Note that in an SPA with getOnInit either not set or false you will always
    // get the initial value instead of the stored value on initialization. If the
    // stored value is preferred set getOnInit to true.
    // https://jotai.org/docs/utilities/storage#parameters
    { getOnInit: true },
);

export function useSidebar(): {
    sidebarIsOpen: boolean;
    toggleSidebar: (open?: boolean) => void;
    sidebarIsTemporary: boolean;
} {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useAtom(sidebarOpenAtom);

    const toggleSidebar = (open?: boolean): void => {
        setIsOpen(previous => open ?? !previous);
    };

    const sidebarIsTemporary = useMediaQuery(theme.breakpoints.down("md"));

    return {
        sidebarIsOpen: isOpen,
        toggleSidebar,
        sidebarIsTemporary,
    };
}

export function SidebarIcon(): JSX.Element {
    const theme = useTheme();
    const { toggleSidebar } = useSidebar();

    return (
        <>
            <SidebarIconTouchTarget onClick={() => toggleSidebar()}>
                <IconButton
                    icon="sidebar"
                    size="sm"
                    colour={theme.colours.neutral[700]}
                />
            </SidebarIconTouchTarget>
            <HoverZone onMouseOver={() => toggleSidebar()} />
        </>
    );
}

const SidebarIconTouchTarget = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

/**
 * An invisible zone on the left side of the screen
 */
const HoverZone = styled.div`
    /* background-color: red; */ // used for debugging the hot zone
    position: fixed;
    left: 0;
    top: 10vh;
    height: 80vh;
    width: 5px;
`;

interface SidebarProps {
    activeIndex: number | false;
    navOptions: NavOption[];
}

export function Sidebar({
    activeIndex,
    navOptions,
}: SidebarProps): JSX.Element {
    const { sidebarIsOpen, toggleSidebar, sidebarIsTemporary } = useSidebar();
    useHotkeys("s", () => toggleSidebar(), [toggleSidebar]);
    return (
        <SwipeableDrawer
            variant={sidebarIsTemporary ? "temporary" : "persistent"}
            anchor="left"
            open={sidebarIsOpen}
            onOpen={() => toggleSidebar(true)}
            onClose={() => toggleSidebar(false)}
            style={{ top: "64px" }}
            ModalProps={{}}
            sx={{
                ".MuiBackdrop-root": {
                    background: "rgba(255, 255, 255, 0.50)",
                    backdropFilter: "blur(2px)",
                },
                ".MuiPaper-root": {
                    backgroundColor: theme => theme.colours.neutral[100],
                },
            }}>
            <SidebarContent
                activeIndex={activeIndex}
                navPages={navOptions}
                toggleSidebar={toggleSidebar}
                sidebarIsTemporary={sidebarIsTemporary}
            />
        </SwipeableDrawer>
    );
}

interface SidebarContentProps {
    activeIndex: number | false;
    navPages: NavOption[];
    toggleSidebar: (state?: boolean) => void;
    sidebarIsTemporary: boolean;
}

function SidebarContent({
    activeIndex,
    navPages,
    toggleSidebar,
    sidebarIsTemporary,
}: SidebarContentProps): ReactElement {
    const { devices } = usePinnedDevices();
    return (
        <SidebarContentBox
            id="sidebar-content"
            style={{ width: SIDEBAR_WIDTH_PX }}
            role="presentation"
            onClick={() => {
                if (sidebarIsTemporary) toggleSidebar(false);
            }}>
            <Toolbar />
            <List>
                {navPages.map(
                    ({ name, disabled, path, icon, new: beta }, i) => {
                        const isActive = i === activeIndex;
                        return (
                            <ListItem
                                key={i}
                                to={disabled ? "#" : path}
                                data-active={isActive}
                                data-disabled={disabled ?? false}>
                                <Icon name={icon} />
                                <Txt font="primary" level="inherit" emphasis>
                                    {name}
                                </Txt>
                                {disabled && (
                                    <>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Icon name="lock" />
                                    </>
                                )}
                                {!disabled && beta && (
                                    <>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <StatusBadge
                                            text="Beta"
                                            size="sm"
                                            status="beta"
                                        />
                                    </>
                                )}
                            </ListItem>
                        );
                    },
                )}
            </List>
            <Divider />
            <SidebarSubheading>
                <Txt font="secondary" level={8} emphasis>
                    Favourites
                </Txt>
            </SidebarSubheading>
            <RecentDevicesList>
                {devices.map((device, i) => {
                    return (
                        <ListItem
                            key={i}
                            to={"/devices/" + device.id}
                            data-active={device.isSelected}>
                            <span
                                style={{
                                    width: 16, // to match the icons used
                                    textAlign: "center",
                                }}>
                                <OnlineIndicator
                                    online={device.isOnline}
                                    minimise
                                />
                            </span>
                            <Txt font="primary" level="inherit" emphasis>
                                {device.name ?? device.id.slice(0, 5)}
                            </Txt>
                        </ListItem>
                    );
                })}
            </RecentDevicesList>
        </SidebarContentBox>
    );
}

const SidebarContentBox = styled.div`
    padding: 24px 12px;
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
`;

const List = styled.ul`
    margin: 0px;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

const RecentDevicesList = styled(List)`
    overflow-y: auto;
    flex-grow: 1;
`;

const ListItem = styled(Link)`
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    padding: 6px 8px;
    gap: 16px;
    border-radius: 4px;

    &[data-disabled="false"]:hover {
        color: ${({ theme }) => theme.colours.neutral[800]};
        background-color: ${({ theme }) => theme.colours.neutral[700]}10;
    }
    &[data-disabled="true"] {
        color: ${({ theme }) => theme.colours.neutral[500]};
        /* background-color: ${({ theme }) => theme.colours.neutral[700]}10; */
    }
    &[data-active="true"] {
        color: ${({ theme }) => theme.colours.neutral[900]};
        background-color: ${({ theme }) => theme.colours.neutral[600]}10;
    }
`;

const Divider: React.FC = () => (
    <DividerContainer>
        <DividerLine />
    </DividerContainer>
);

const DividerContainer = styled.div`
    padding: 6px 8px;
`;

const DividerLine = styled.div`
    border: 1px solid;
    border-color: ${({ theme }) => theme.colours.neutral[300]};
`;

const SidebarSubheading = styled.div`
    padding: 0px 8px;
    color: ${({ theme }) => theme.colours.neutral[500]};
`;
