import { ReactElement, useState } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { useToasts } from "components/common/toasts/useToasts";

import { ButtonSize } from "../Button";

import DeviceNotificationsPreferenceButton from "./DeviceNotificationsPreferenceButton";
import { useDeviceNotificationPreference } from "./useDeviceNotificationPreference";

export interface NotificationsPreferenceButtonProps {
    deviceId: string;
    size?: ButtonSize;
    /**
     * Controls the display of text in the button. Without text, only icon is
     * displayed. Defaults false.
     */
    hideText?: boolean;
}

export default function DeviceNotificationsPreference(
    props: NotificationsPreferenceButtonProps,
): ReactElement {
    const { deviceId } = props;
    const { toast } = useToasts();
    const [notificationPreferenceLoading, setNotificationPreferenceLoading] =
        useState(false);

    const { loading, enabled } = useDeviceNotificationPreference(deviceId);
    const [updateDeviceNotificationPreference] = useMutation(
        UPDATE_NOTIFICATION_PREFERENCE,
    );

    const notificationsEnabled = enabled ?? false;

    const onNotificationsPreferenceClick = async () => {
        setNotificationPreferenceLoading(true);
        const toastId = toast.loading("Updating notification preference");
        const result = await updateDeviceNotificationPreference({
            variables: {
                input: {
                    deviceId,
                    notificationsEnabled: !notificationsEnabled,
                },
            },
        });
        if (result?.data?.updateDeviceNotificationPreference?.ok) {
            toast.update(toastId, {
                render: "Notification preference updated",
                type: "success",
                isLoading: false,
            });
        } else {
            toast.update(toastId, {
                render: `Failed to update Notification Preference: ${
                    result?.data?.updateDeviceNotificationPreference?.message ??
                    "unknown error"
                }`,
                type: "error",
                isLoading: false,
            });
        }
        setNotificationPreferenceLoading(false);
    };
    return (
        <DeviceNotificationsPreferenceButton
            notificationsEnabled={notificationsEnabled}
            onClick={onNotificationsPreferenceClick}
            disabled={loading || notificationPreferenceLoading}
            size={props.size}
            hideText={props.hideText}
        />
    );
}

const UPDATE_NOTIFICATION_PREFERENCE = gql(`
    mutation UpdateDeviceNotificationPreference(
        $input: UpdateDeviceNotificationPreferenceInput!
    ) {
        updateDeviceNotificationPreference(input: $input) {
            ok
            message
            edge {
                id
                notificationsEnabled
            }
        }
    }
`);
