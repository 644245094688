import { ReactElement, useEffect, useRef, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { shuffle } from "lodash";

import Txt from "components/common/Text";

export interface LoadingPhrasesProps {
    /**
     * Milliseconds between animating to the next phrase in the list.
     */
    animationIntervalMs?: number;
}

export function LoadingPhrases({
    animationIntervalMs = 10_000,
}: LoadingPhrasesProps): ReactElement {
    const phrases = usePhrases();
    const currentPhraseIndex = useAnimatedPhraseIndex({
        phrases,
        animationIntervalMs,
    });

    return (
        <AnimatePresence mode="wait">
            <motion.span
                initial={{
                    y: 5,
                    opacity: 0,
                }}
                key={`current-placeholder-${currentPhraseIndex}`}
                animate={{
                    y: 0,
                    opacity: 1,
                }}
                exit={{
                    y: -15,
                    opacity: 0,
                }}
                transition={{
                    duration: 0.3,
                    ease: "linear",
                }}>
                <Txt
                    font="secondary"
                    level={9}
                    italic
                    sx={{ color: theme => theme.colours.neutral[500] }}>
                    {phrases[currentPhraseIndex]}...
                </Txt>
            </motion.span>
        </AnimatePresence>
    );
}

function useAnimatedPhraseIndex({
    phrases,
    animationIntervalMs,
}: {
    phrases: string[];
    animationIntervalMs: number;
}) {
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const startAnimation = () => {
            intervalRef.current = setInterval(() => {
                setCurrentPhraseIndex(prev => (prev + 1) % phrases.length);
            }, animationIntervalMs);
        };
        const handleVisibilityChange = () => {
            if (document.visibilityState !== "visible" && intervalRef.current) {
                // Clear the interval when the tab is not visible
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            } else if (document.visibilityState === "visible") {
                // Restart the interval when the tab becomes visible
                startAnimation();
            }
        };

        startAnimation();
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, [phrases, animationIntervalMs]);

    return currentPhraseIndex;
}

function usePhrases() {
    const [phrases, setPhrases] = useState(PHRASES);
    useEffect(() => {
        // Shuffle the phrases except for the first one so that our visual tests
        // always load the same phrase first
        const [first, ...rest] = phrases;
        const newOrder = [first, ...shuffle(rest)];
        setPhrases(newOrder);

        // We deliberately only want to shuffle the phrases once (on mount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return phrases;
}

const PHRASES = [
    "Tuning incubator vibes",
    "Polishing interstellar manifolds",
    "Preparing cell wormholes",
    "Calibrating cell-flask continuum",
    "Aligning pinch resonance",
    "Configuring microscope oracle",
    "Deburring iPSC matrix connectors",
    "Preparing to jump into hyper-differentiation",
    "Waking up automation elves",
    "Cutting back weeds on transcription pathways",
    "Proofreading DNA",
    "Greasing enzymes",
    "Warding off contamination spirits",
    "Blessing growth media",
    "Dying media stylish pink",
    "Trimming telomeres",
    "Locating powerhouse of the cell",
    "Cranking ATP synthase rotors",
];
