import { isUndefined } from "lodash";

import {
    DeviceJob,
    DeviceJobState,
    DeviceJobType,
} from "__generated__/apollo/graphql";
import { dayjs } from "services/date";

/**
 * Number of hours until a Dry Test is stale
 */
const staleTimeHours = 84;

export const RequiredDryTestsForProgression = [
    DeviceJobType.Tilter,
    DeviceJobType.Shaker,
    DeviceJobType.PinchValveBedding,
];

export type DryTest = DeviceJob & { type: DeviceJobType };

// Map of device job types to estimated completion times in seconds
export const estimatedCompletionMap: Map<DeviceJobType, number> = new Map([
    [DeviceJobType.Tilter, 10],
    [DeviceJobType.Shaker, 30],
    [DeviceJobType.PinchValveBedding, 15 * 60],
]);

/**
 * Estimates the completion time for a device test based on the test type,
 * progress, and last updated time. If progress and last updated time are not
 * provided, it returns the estimated completion time based on the current time.
 *
 * @param type - The type of the device test.
 * @param progress - The progress of the device test, between 0 and 1.
 * @param updatedAt - The last updated time of the device test in ISO 8601
 * format.
 * @returns The estimated completion time in ISO 8601 format.
 */
export function estimateCheckCompletionTime({
    type,
    progress,
    updatedAt,
}: {
    type: DeviceJobType;
    progress?: number; // between 0 and 1
    updatedAt?: string; // ISO string
}): string {
    const durationForTestType = estimatedCompletionMap.get(type) ?? 0;

    if (isUndefined(progress) || isUndefined(updatedAt)) {
        // return the ISO string plus the estimated duration
        return dayjs().add(durationForTestType, "seconds").toISOString();
    }

    const remainingDuration = (1 - progress) * durationForTestType;

    return dayjs(updatedAt).add(remainingDuration, "seconds").toISOString();
}

/**
 * Checks if all dry tests are complete with a given applied time window.
 * @param dryTests - The array of dry tests to check.
 * @param currentTime - The current time. Defaults to the current time when not
 * provided
 * @param validTimeWindowHours - The time window in hours to consider a dry test
 * complete. Defaults to 84 hours. 3 days and 12 hours to compensate for friday
 * morning setup and monday afternoon kick-off the longest we support
 * @returns A boolean indicating whether all dry tests are complete.
 */
export function dryChecksAreComplete(
    dryTests: DryTest[],
    currentTime = dayjs(),
    validTimeWindowHours = staleTimeHours,
): boolean {
    if (dryTests.length === 0) {
        return false;
    }
    const allTestsPassed = RequiredDryTestsForProgression.every(type => {
        const test = dryTests.find(({ type: testType }) => testType === type);
        const isTestComplete = test?.state === DeviceJobState.Complete;
        if (!isTestComplete) {
            return false;
        }
        const staleTest = isTestStale(test, currentTime, validTimeWindowHours);
        return !staleTest;
    });
    return allTestsPassed;
}

/**
 * Checks if a job is stale based on the provided time.
 * @param job - The DeviceJob object to check.
 * @param currentTime - The current time. Defaults to the current system time.
 * @param validTimeWindowHours - The time window in hours to consider a dry test
 * @returns A boolean indicating whether the complete job is stale.
 */
export function isTestStale(
    test: DryTest,
    currentTime = dayjs(),
    validTimeWindowHours = staleTimeHours,
) {
    const updatedAt = dayjs(test.updatedAt);
    const duration = currentTime.diff(updatedAt, "hours");
    return duration > validTimeWindowHours;
}
