import { useEffect, useRef, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import scrollIntoView from "scroll-into-view-if-needed";
import { v4 as uuidv4 } from "uuid";

import { gql } from "__generated__/apollo";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import { IconButton } from "components/common/IconButton";
import Modal from "components/common/Modal";
import Skeleton from "components/common/Skeleton";
import {
    PreventFromTriggeringDragAndDrop,
    SortableList,
} from "components/common/SortableList";
import Txt from "components/common/Text";
import { TextInput } from "components/common/TextInput";
import { useToasts } from "components/common/toasts/useToasts";
import { Procedure } from "services/hooks/useCultureSchedule";

import { log as parentLog } from "./log";

const log = parentLog.extend("EditProcedureStepsModal");

const procedureStepsQuery = gql(`
    query ProcedureStepsLight($id: String!) {
        procedure(id: $id) {
            id
            steps {
                totalCount

                nodes {
                    id
                    name
                }
            }
        }
    }
`);

const editProcedureStepsMutation = gql(`
    mutation EditProcedureSteps(
        $procedureId: ID!
        $steps: [EditProcedureStepInput!]!
    ) {
        editProcedureSteps(
            input: { procedureId: $procedureId, orderedSteps: $steps }
        ) {
            ok
            message
            procedureSteps {
                id
                name
            }
        }
    }
`);

interface StepItem {
    id: string;
    name: string | null;
    isNewStep?: boolean;
    htmlId?: string;
    error?: boolean;
}

interface Props {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    procedure: Procedure;
}
export default function EditProcedureStepsModal({
    modalOpen,
    setModalOpen,
    procedure,
}: Props) {
    const { toast } = useToasts();
    const { data, loading } = useQuery(procedureStepsQuery, {
        variables: { id: procedure.id },
    });

    const scrollableDivRef = useRef<HTMLDivElement>(null);

    const [editProcedureSteps, mutationState] = useMutation(
        editProcedureStepsMutation,
        {
            refetchQueries: [
                {
                    query: procedureStepsQuery,
                    variables: { id: procedure.id },
                },
            ],
        },
    );

    useEffect(() => {
        if (data) {
            const steps = data.procedure?.steps?.nodes;
            setStepItems(steps ?? []);
        }
    }, [data]);

    // Reset the state when the modal is closed
    useEffect(() => {
        if (!modalOpen) {
            setStepItems(data?.procedure?.steps?.nodes ?? []);
        }
    }, [modalOpen, data]);

    const [stepItems, setStepItems] = useState<StepItem[]>([]);

    const addNewEmptyStep = () => {
        const id = uuidv4();
        const htmlId = `new-step-${id}`;

        setStepItems(items => [
            ...items,
            {
                id,
                name: "",
                isNewStep: true,
                htmlId,
            },
        ]);

        // This timeout waits for the new input to be rendered, we can only scroll to it after it's in the DOM
        setTimeout(() => {
            scrollToElementByIdAndFocus(htmlId, scrollableDivRef.current);
        }, 50);
    };

    const onStepNameChange = ({ id, value }: { id: string; value: string }) => {
        setStepItems(items => {
            const updatedItems = items.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        name: value,
                        error: false,
                    };
                }
                return item;
            });

            return updatedItems;
        });
    };

    const onRemoveNewStep = (id: string) => {
        setStepItems(items => items.filter(item => item.id !== id));
    };

    const handleSubmit = async () => {
        const invalidNewSteps = stepItems.filter(
            step => step.isNewStep && !step.name?.trim(),
        );

        // If there are new steps that haven't been named yet, mark as errored and scroll into view
        if (invalidNewSteps.length > 0) {
            setStepItems(items => {
                const updatedItems = items.map(item => {
                    const hasError = invalidNewSteps.find(
                        step => step.id === item.id,
                    );
                    return {
                        ...item,
                        error: hasError ? true : false,
                    };
                });

                return updatedItems;
            });

            scrollToElementByIdAndFocus(
                invalidNewSteps[0].htmlId as string,
                scrollableDivRef.current,
            );

            return;
        }

        try {
            const output = await editProcedureSteps({
                variables: {
                    procedureId: procedure.id,
                    steps: stepItems.map(step =>
                        step.isNewStep
                            ? { newStepName: step.name, existingStepId: null }
                            : { newStepName: null, existingStepId: step.id },
                    ),
                },
            });

            if (!output.data?.editProcedureSteps.ok) {
                toast.error("Failed to save procedure steps");
                return;
            }

            toast.success("Procedure steps saved successfully");
            setModalOpen(false);
        } catch (error) {
            log.error(error);
            toast.error("Failed to save procedure steps");
        }
    };

    return (
        <Modal
            title="Edit Procedure Steps"
            buttonLeft={{ label: "Back", onClick: close => close() }}
            buttonRight={{
                label: "Confirm",
                onClick: handleSubmit,
                disabled: mutationState.loading,
            }}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}>
            <Wrapper>
                <Txt
                    font="primary"
                    level={6}
                    display="inline"
                    emphasis
                    align="left">
                    {procedure.name}
                </Txt>
                <DividerWithYMargin />
                <Txt
                    level={8}
                    emphasis
                    sx={{
                        color: theme => theme.colours.neutral[700],
                    }}>
                    Drag to reorder
                </Txt>
                {loading ? (
                    <ScrollableList>
                        {Array.from({ length: 5 }).map((_, i) => (
                            <LoadingDragAndDropStep key={i} />
                        ))}
                    </ScrollableList>
                ) : (
                    <>
                        <ScrollableList ref={scrollableDivRef}>
                            <SortableList
                                scrollable
                                items={stepItems}
                                setItems={setStepItems}
                                renderItem={stepItem => (
                                    <DragAndDropStep
                                        stepItem={stepItem}
                                        onStepNameChange={onStepNameChange}
                                        onRemoveNewStep={() =>
                                            onRemoveNewStep(stepItem.id)
                                        }
                                    />
                                )}
                            />
                        </ScrollableList>
                        <Button
                            iconLeft="plus"
                            variant="tertiary"
                            size="s"
                            style={{
                                maxWidth: "fit-content",
                                marginLeft: "8px",
                                marginTop: "8px",
                            }}
                            onClick={addNewEmptyStep}>
                            Add step
                        </Button>
                    </>
                )}
            </Wrapper>
        </Modal>
    );
}

function DragAndDropStep({
    stepItem: { id, name, isNewStep, htmlId, error },
    onStepNameChange,
    onRemoveNewStep,
}: {
    stepItem: StepItem;
    onStepNameChange: (args: { id: string; value: string }) => void;
    onRemoveNewStep: () => void;
}) {
    if (isNewStep) {
        return (
            <DraggableStep>
                <Icon name="draggable" size="sm" />
                <PreventFromTriggeringDragAndDrop>
                    <TextInput
                        id={htmlId}
                        value={name ?? undefined}
                        onChange={value =>
                            onStepNameChange({
                                id,
                                value,
                            })
                        }
                        error={error}
                    />
                </PreventFromTriggeringDragAndDrop>
                <PreventFromTriggeringDragAndDrop>
                    <IconButton
                        icon="cross"
                        size="sm"
                        onClick={onRemoveNewStep}
                    />
                </PreventFromTriggeringDragAndDrop>
            </DraggableStep>
        );
    }

    return (
        <DraggableStep>
            <Icon name="draggable" size="sm" />
            <Txt level={8}>{name}</Txt>
        </DraggableStep>
    );
}

function LoadingDragAndDropStep() {
    return (
        <DraggableStep>
            <Skeleton height={30} width={200} />
            <Icon name="expand" />
        </DraggableStep>
    );
}

function scrollToElementByIdAndFocus(
    id: string,
    boundary?: HTMLDivElement | null,
) {
    const element = document.getElementById(id);
    if (element) {
        scrollIntoView(element, {
            behavior: "smooth",
            scrollMode: "if-needed",
            block: "center",
            inline: "center",
            boundary,
        });
        element.focus({ preventScroll: true });
    }
}

const Wrapper = styled.div`
    /* padding: 36px; */
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);
    width: 100%;
`;

const DraggableStep = styled.div`
    padding: 10px;
    /* border: 1px solid #ccc; */
    background: ${({ theme }) => theme.colours.neutral[100]};
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;
    color: ${({ theme }) => theme.colours.neutral[700]};
`;

const ScrollableList = styled.div`
    overflow-y: scroll;
    flex: 1;
    flex: 1;
    margin-top: 8px;
    padding: 4px 0;
    width: 100%;

    border-top: 1px solid;
    border-bottom: 1px solid;
    /* border-radius: 4px; */
    border-color: ${({ theme }) => theme.colours.neutral[300]};
    /* background: ${({ theme }) => theme.colours.neutral[100]}; */
`;

const DividerWithYMargin = styled(Divider)`
    margin-top: 12px;
    margin-bottom: 12px;
`;
