import { atom, useAtom } from "jotai";

const gridModeAtom = atom(false);
const rowsAtom = atom(20);
const colsAtom = atom(3);

export function useGridSettings() {
    const [customGridSettings, setCustomGridSettings] = useAtom(gridModeAtom);
    const [rows, setRows] = useAtom(rowsAtom);
    const [cols, setCols] = useAtom(colsAtom);

    return {
        customGridSettings,
        setCustomGridSettings,
        rows,
        setRows,
        cols,
        setCols,
    };
}
