import { Tooltip, useTheme } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

import { CultureState } from "__generated__/apollo/graphql";
import StatusBadge from "components/common/StatusBadge";
import { getCultureStateString } from "services/culture-utils";

export function CultureStatusBadge(props: {
    cultureState: CultureState | null | undefined;
    wetTestCultureState?: CultureState | null;
    errorMessage: string | null | undefined;
    waitingForConfirmation: boolean | null | undefined;
    loading: boolean;
}) {
    const {
        cultureState,
        wetTestCultureState,
        loading,
        waitingForConfirmation,
    } = props;

    const theme = useTheme();

    if (loading) {
        return <StatusBadge skeleton size="sm" status="disabled" text="" />;
    }

    if (!cultureState && !wetTestCultureState) {
        return null;
    }

    const stateText = getCultureStateString({
        cultureState,
        wetTestCultureState,
    });

    if (waitingForConfirmation) {
        return (
            <StatusBadge
                status="warn"
                fill={true}
                text="Waiting"
                size="sm"
                tooltip="Confirmation needed to continue"
            />
        );
    }

    if (props.errorMessage) {
        return (
            <StatusBadge
                status="warn"
                fill={true}
                text={`Error - ${stateText}`}
                size="sm"
                tooltip={props.errorMessage}
            />
        );
    }

    switch (cultureState ?? wetTestCultureState) {
        case CultureState.Ready:
            return (
                <StatusBadge
                    status="disabled"
                    fill={false}
                    text={stateText}
                    size="sm"
                />
            );
        case CultureState.Loading:
            return (
                <StatusBadge
                    status="disabled"
                    fill={false}
                    text={stateText}
                    size="sm"
                    endAdornment={
                        <RotatingLines
                            width="14"
                            strokeColor={theme.colours.neutral[500]}
                            strokeWidth="5"
                            animationDuration="1"
                        />
                    }
                />
            );
        case CultureState.Running:
            return (
                <StatusBadge
                    status="ok"
                    fill={false}
                    text={stateText}
                    size="sm"
                />
            );
        case CultureState.Pausing:
            return (
                <Tooltip
                    title="The culture will be paused when the current running operation completes"
                    arrow>
                    <StatusBadge
                        status="warn"
                        fill={true}
                        text={stateText}
                        size="sm"
                        endAdornment={
                            <RotatingLines
                                width="14"
                                strokeColor={theme.colours.neutral.white}
                                strokeWidth="5"
                                animationDuration="1"
                            />
                        }
                    />
                </Tooltip>
            );
        case CultureState.Paused:
            return (
                <StatusBadge
                    status="warn"
                    fill={true}
                    text={stateText}
                    size="sm"
                />
            );
        case CultureState.Updating:
            return (
                <StatusBadge
                    status="disabled"
                    fill={false}
                    text={stateText}
                    size="sm"
                    endAdornment={
                        <RotatingLines
                            width="14"
                            strokeColor={theme.colours.neutral[500]}
                            strokeWidth="5"
                            animationDuration="1"
                        />
                    }
                />
            );
        case CultureState.Complete:
            return (
                <StatusBadge
                    status="ok"
                    fill={true}
                    text={stateText}
                    size="sm"
                />
            );
        default:
            return null;
    }
}
