import { useState, Fragment, ReactElement } from "react";

import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { gql } from "__generated__/apollo";
import Button from "components/common/Button";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { DateTimeInput } from "components/common/DateTimeInput";
import { useToasts } from "components/common/toasts/useToasts";
import { formatDatetime } from "services/date";

import { log as parentLog } from "../log";

export const log = parentLog.extend("ChangeMaintenanceDialog");

interface ChangeMaintenanceDialogProps {
    deviceId: string;
    maintenanceUntil?: string;
}

export const UPDATE_MAINTENANCE_MUTATION = gql(`
    mutation UpdateMaintenance(
        $updateDeviceMaintenanceInput: UpdateDeviceMaintenanceInput!
    ) {
        updateDeviceMaintenance(
            updateDeviceMaintenanceInput: $updateDeviceMaintenanceInput
        ) {
            ok
            message
            device {
                id
                isInMaintenance
                maintenanceUntil
            }
        }
    }
`);

export default function ChangeMaintenanceDialog(
    props: ChangeMaintenanceDialogProps,
): ReactElement {
    const { deviceId, maintenanceUntil } = props;
    const { toast } = useToasts();
    const [open, setOpen] = useState(false);
    const withoutPast = (maintenanceUntil: string | undefined) => {
        if (!maintenanceUntil) return undefined;
        if (dayjs(maintenanceUntil).isBefore(dayjs())) return undefined;
        return dayjs(maintenanceUntil);
    };
    const [newMaintenanceUntil, setNewMaintenanceUntil] = useState<
        Dayjs | undefined
    >(withoutPast(maintenanceUntil));
    const [UpdateMaintenanceMutation] = useMutation(
        UPDATE_MAINTENANCE_MUTATION,
    );
    const [changePending, setChangePending] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChangeMaintenance = async () => {
        log.debug("change maintenanceUntil to", newMaintenanceUntil);
        let res;
        try {
            setChangePending(true);
            res = await UpdateMaintenanceMutation({
                variables: {
                    updateDeviceMaintenanceInput: {
                        maintenanceUntil:
                            newMaintenanceUntil?.toISOString() ?? null,
                        deviceId: deviceId,
                    },
                },
            });
        } catch (e) {
            log.debug(
                "Error encountered during change maintenance mutation",
                e,
            );
        }
        setChangePending(false);
        if (res) {
            log.debug(
                "received response from change maintenance mutation",
                res,
            );
            if (res.data?.updateDeviceMaintenance?.ok) {
                toast.success(
                    newMaintenanceUntil
                        ? `Maintenance mode is active until ${formatDatetime(
                              newMaintenanceUntil.toISOString(),
                          )}`
                        : "Maintenance mode cleared",
                );
                setOpen(false);
                return;
            }
        }
        log.debug(
            "no response received for change Device maintenance mutation",
        );
        toast.error("There was an issue changing Device maintenance status");
    };

    const disableConfirm =
        changePending ||
        dayjs(maintenanceUntil).isSame(newMaintenanceUntil) ||
        dayjs(newMaintenanceUntil).isBefore(dayjs()) ||
        (dayjs(maintenanceUntil).isBefore(dayjs()) && !newMaintenanceUntil);

    return (
        <Fragment>
            <Button
                variant="tertiary"
                size="s"
                iconRight="edit"
                onClick={handleOpen}>
                Edit
            </Button>
            <ConfirmationDialog
                variant="advised"
                open={open}
                onSecondaryAction={handleClose}
                onDismiss={handleClose}
                onPrimaryAction={handleChangeMaintenance}
                primaryButtonDisabled={disableConfirm}
                title="Set maintenance mode"
                body="Maintenance mode will disable common alerts whilst the device is being worked on. Provide the date and time that maintenance will end. When the time is reached, maintenance mode is automatically deactivated."
                primaryButtonText={`Confirm change`}>
                <Stack direction={"row"} spacing={4} sx={{ mt: 2 }}>
                    <DateTimeInput
                        min={dayjs()}
                        value={newMaintenanceUntil}
                        onChange={d => d && setNewMaintenanceUntil(d)}
                    />
                    <Button
                        variant="tertiary"
                        size="s"
                        iconRight="cross"
                        disabled={newMaintenanceUntil === undefined}
                        onClick={() => setNewMaintenanceUntil(undefined)}>
                        Clear
                    </Button>
                </Stack>
            </ConfirmationDialog>
        </Fragment>
    );
}
