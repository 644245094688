import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { CancelDeviceJob, StartDeviceJobs } from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from "../log";

import { DEVICE_DRY_TESTS_QUERY } from "./useDeviceDryTests";

const log = parentLog.extend("useTriggerDryTest");

const START_DRY_CHECK_MUTATION = gql(`
    mutation StartDeviceJob($input: StartDeviceJobs!) {
        startDeviceJob(input: $input) {
           ok
           message
        }
    }
`);

const CANCEL_DRY_CHECK_MUTATION = gql(`
    mutation CancelDeviceJob($input: CancelDeviceJob!) {
        cancelDeviceJob(input: $input) {
           ok
           message
        }
    }
`);

export function useStartDryTest() {
    const [startDryTest, { loading }] = useMutation(START_DRY_CHECK_MUTATION);

    const { toast } = useToasts();

    const triggerDryTest = async (input: StartDeviceJobs) => {
        try {
            const output = await startDryTest({
                variables: { input },
                refetchQueries: [DEVICE_DRY_TESTS_QUERY],
                awaitRefetchQueries: true,
            });

            if (!output.data?.startDeviceJob.ok) {
                throw new Error(output.data?.startDeviceJob.message);
            }
        } catch (error) {
            log.error(error);
            toast.error("Failed to cancel dry test");
        }
    };

    return { triggerDryTest, loading };
}

export function useCancelDryTest() {
    const [cancelDryTestMutation, { loading }] = useMutation(
        CANCEL_DRY_CHECK_MUTATION,
    );

    const { toast } = useToasts();

    const cancelDryTest = async (input: CancelDeviceJob) => {
        const toastId = toast.loading("Cancelling dry test");
        try {
            const output = await cancelDryTestMutation({
                variables: { input },
                refetchQueries: [DEVICE_DRY_TESTS_QUERY],
                awaitRefetchQueries: true,
            });

            if (!output.data?.cancelDeviceJob.ok) {
                throw new Error(output.data?.cancelDeviceJob.message);
            }

            toast.update(toastId, {
                render: "Dry test cancelled",
                type: "success",
                isLoading: false,
            });
        } catch (error) {
            log.error(error);
            toast.update(toastId, {
                render: "Failed to cancel dry test",
                type: "error",
                isLoading: false,
            });
        }
    };

    return { cancelDryTest, loading };
}
