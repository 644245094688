import { useState } from "react";

import { useTheme } from "@mui/material";

import Callout from "components/common/Callout";
import { IconButton } from "components/common/IconButton";
import Modal from "components/common/Modal";
import { NumberInput } from "components/common/NumberInput";
import { Switch } from "components/common/Switch";
import { TextLabel } from "components/common/TextLabel";

import { useGridSettings } from "./device-images-state";

export function DeviceImagesSettings() {
    const {
        customGridSettings: gridMode,
        setCustomGridSettings: setGridMode,
        rows,
        setRows,
        cols,
        setCols,
    } = useGridSettings();
    const theme = useTheme();
    const [newGridMode, setNewGridMode] = useState(gridMode);
    const [newRows, setNewRows] = useState(rows);
    const [newCols, setNewCols] = useState(cols);

    const onSave = () => {
        setRows(newRows);
        setCols(newCols);
        setGridMode(newGridMode);
    };

    return (
        <Modal
            openModalElement={open => (
                <IconButton
                    icon="settings"
                    size="sm"
                    onClick={() => open()}
                    colour={theme.colours.neutral[500]}
                />
            )}
            title="Device Images Settings"
            maxWidth="xs"
            buttonLeft={{
                label: "Cancel",
                onClick: close => close(),
            }}
            buttonRight={{
                label: "Save",
                onClick: close => {
                    onSave();
                    close();
                },
            }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                }}>
                <Switch
                    id="grid-mode"
                    label="Apply custom settings"
                    checked={newGridMode}
                    onChange={v => setNewGridMode(v)}
                    fillWidth={true}
                />
                <TextLabel label="Images (rows)">
                    <NumberInput
                        disabled={!newGridMode}
                        value={newRows}
                        onChange={v => v && setNewRows(v)}
                    />
                </TextLabel>
                <TextLabel label="Events (columns)">
                    <NumberInput
                        disabled={!newGridMode}
                        value={newCols}
                        onChange={v => v && setNewCols(v)}
                    />
                </TextLabel>
                <Callout
                    message="Setting a large number of images to display can cause performance problems"
                    style={{ marginTop: 16 }}
                />
            </div>
        </Modal>
    );
}
