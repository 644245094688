import { ReactElement } from "react";

import { useQuery as useApolloQuery } from "@apollo/client";
import Container from "@mui/material/Container";

import { gql } from "__generated__/apollo";
import {
    CultureFlaskResultQuery,
    DeviceFlaskResultQuery,
    Flask,
} from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import { HeaderSelect } from "components/common/HeaderSelect";
import { usePersistentState } from "services/hooks/usePersistentState";
import { useQueryString } from "services/hooks/useQueryString";
import { isDefined, removeNullables } from "services/utils";

export type SelectFlaskProps = {
    cultureId: string;
};
export function menuOptionDetailsFromFlask(flask: FlaskDetails) {
    let name = "";
    if (flask.trayNumber && flask.flaskNumber) {
        name = `Flask ${flask.flaskNumber}, Tray ${flask.trayNumber}`;
    } else if (flask.flaskNumber) {
        name = `Flask ${flask.flaskNumber}`;
    }

    if (!flask.containerReference) {
        return undefined;
    }

    return {
        value: flask.containerReference,
        label: name,
        icon: flask.imageable ? undefined : "eye-closed",
    } as const;
}

/**
 * Select Flask component
 */
export function SelectFlask(props: SelectFlaskProps): ReactElement {
    const {
        flasks,
        selectedFlaskId,
        setSelectedFlaskId,
        loading,
        error,
        invalidFlaskId,
        numberImagedFlasks,
    } = useFlaskIds(props);

    const selectedFlask = flasks.find(
        f => f.containerReference === selectedFlaskId,
    );
    let title = selectedFlask
        ? (menuOptionDetailsFromFlask(selectedFlask)?.label ?? "")
        : "";

    if (invalidFlaskId) {
        title = "Invalid Flask Id";
    }

    const flaskOptions = flasks.map(f => {
        const details = menuOptionDetailsFromFlask(f);
        if (!details) {
            return undefined;
        }
        return {
            ...details,
            onClick: () => {
                if (f.containerReference) {
                    setSelectedFlaskId(f.containerReference);
                }
            },
        };
    });

    const definedFlaskOptions = flaskOptions.filter(isDefined);

    if (error) {
        return (
            <Container maxWidth="xl">
                <Callout variant="warn">
                    There was a problem loading the flask ids
                </Callout>
            </Container>
        );
    }
    if (!loading && numberImagedFlasks === 0) {
        return (
            <Container maxWidth="xl">
                <Callout variant="info">
                    There are no flasks for this device
                </Callout>
            </Container>
        );
    }
    return (
        <HeaderSelect
            loading={!selectedFlaskId && loading}
            value={title}
            options={definedFlaskOptions}
        />
    );
}

const FLASK_ID_PARAM_KEY = "f";

type FlaskDetails = Omit<
    Flask,
    | "__typename"
    | "name"
    | "createdAt"
    | "cultureId"
    | "growthAreaMm2"
    | "liquidVolumeMl"
    | "totalVolumeMl"
    | "updatedAt"
    | "cultures"
>;

/**
 * Gets the flasks on the queried culture or device's current culture, as well
 * as the imaged flasks (present within the cultures' results).
 * If no flasks are present on the culture itself, then only imaged flasks are
 * returned.
 */
export function useFlaskIds(
    props: { cultureId: string } | { deviceId: string },
) {
    const isDeviceProp = "deviceId" in props;
    const {
        data: cultureData,
        loading: cultureLoading,
        error: cultureError,
    } = useApolloQuery<CultureFlaskResultQuery>(CULTURE_FLASK_ID_QUERY, {
        skip: isDeviceProp,
        variables: { cultureId: !isDeviceProp && props.cultureId },
    });
    const {
        data: deviceData,
        loading: deviceLoading,
        error: deviceError,
    } = useApolloQuery<DeviceFlaskResultQuery>(DEVICE_FLASK_ID_QUERY, {
        skip: !isDeviceProp,
        variables: { deviceId: isDeviceProp && props.deviceId },
    });

    const cultureId = isDeviceProp
        ? deviceData?.device?.culture?.id
        : props.cultureId;

    const [lastViewedFlask, setLastViewedFlask] = usePersistentState(
        `${cultureId}-last-viewed-flask`,
        "",
    );

    const allFlasks = removeNullables(
        isDeviceProp
            ? (deviceData?.device?.culture?.flasks?.nodes ?? [])
            : (cultureData?.culture?.flasks?.nodes ?? []),
    );

    const imagedFlaskReferences = removeNullables(
        isDeviceProp
            ? (deviceData?.device?.culture?.results?.flaskIds ?? [])
            : (cultureData?.culture?.results?.flaskIds ?? []),
    );

    /**
     * If we have flasks from the culture, only include those that have been imaged.
     * If we don't have any flasks from the culture, construct as much info as
     * possible from the imaging result data.
     */
    const flasksDetails: FlaskDetails[] = allFlasks.length
        ? allFlasks.filter(f =>
              f.containerReference
                  ? imagedFlaskReferences.includes(f.containerReference)
                  : false,
          )
        : imagedFlaskReferences.map(flaskReference => {
              const flaskReferenceSegments = flaskReference.split("-");
              const flaskNumber = flaskReferenceSegments[2]
                  ? parseInt(flaskReferenceSegments[2])
                  : null;
              const trayNumber = flaskReferenceSegments[3]
                  ? parseInt(flaskReferenceSegments[3])
                  : null;
              return {
                  id: flaskReference ?? "",
                  containerReference: flaskReference,
                  imageable: true,
                  flaskNumber,
                  trayNumber,
              };
          });

    flasksDetails.sort((f1, f2) => {
        return Number(
            f1.containerReference &&
                f2.containerReference &&
                f1.containerReference.localeCompare(f2.containerReference),
        );
    });

    const { query, pushQueryString, replaceQueryString } = useQueryString();
    const selectedFlaskId = query.get(FLASK_ID_PARAM_KEY);

    let invalidFlaskId = false;
    if (selectedFlaskId) {
        const isPresent = flasksDetails.some(
            f =>
                f.id === selectedFlaskId ||
                f.containerReference === selectedFlaskId,
        );
        invalidFlaskId = !isPresent;
    }

    const setSelectedFlaskId = (id: string) => {
        const prevFlaskId = query.get(FLASK_ID_PARAM_KEY);
        query.set(FLASK_ID_PARAM_KEY, id);
        setLastViewedFlask(id);
        if (prevFlaskId) {
            pushQueryString(query);
        } else {
            // We don't want to keep the page without
            // a flaskId in the browser history
            replaceQueryString(query);
        }
    };
    if (flasksDetails?.length && selectedFlaskId === null) {
        if (
            lastViewedFlask &&
            imagedFlaskReferences.includes(lastViewedFlask)
        ) {
            setSelectedFlaskId(lastViewedFlask);
        } else if (imagedFlaskReferences.length) {
            const lastIndex = imagedFlaskReferences.length - 1;
            setSelectedFlaskId(imagedFlaskReferences[lastIndex]);
        }
    }

    const selectedFlaskReference =
        flasksDetails.find(f => f.containerReference === selectedFlaskId)
            ?.containerReference ?? null;

    return {
        cultureId,
        flasks: flasksDetails,
        selectedFlaskId,
        selectedFlaskReference,
        setSelectedFlaskId,
        loading: isDeviceProp ? deviceLoading : cultureLoading,
        error: isDeviceProp ? deviceError : cultureError,
        invalidFlaskId,
        numberImagedFlasks: imagedFlaskReferences.length,
    };
}

const CULTURE_FLASK_ID_QUERY = gql(`
    query CultureFlaskResult($cultureId: String!) {
        culture(id: $cultureId) {
            id
            results {
                flaskIds
            }
            flasks {
                nodes {
                    id
                    containerReference
                    flaskNumber
                    trayNumber
                    imageable
                }
            }
        }
    }
`);

const DEVICE_FLASK_ID_QUERY = gql(`
    query DeviceFlaskResult($deviceId: String!) {
        device(id: $deviceId) {
            id
            culture {
                id
                results {
                    flaskIds # This is actually container references
                }
                flasks {
                    nodes {
                        id
                        containerReference
                        flaskNumber
                        trayNumber
                        imageable
                    }
                }
            }
        }
    }
`);
