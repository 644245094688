import { ApolloError, useQuery } from "@apollo/client";
import { sum } from "lodash";

import { gql } from "__generated__/apollo";
import { CultureState, WetTestsQuery } from "__generated__/apollo/graphql";
import { removeNullables } from "services/utils";

import { WetTestState } from "./WetTestsActivityCard";

export const CULTURE_WET_TESTS_QUERY = gql(`
    query WetTests($cultureId: String!) {
        culture(id: $cultureId) {
            id
            description
            isWetTestCulture
            state
            errorMessage
            schedule  {
                procedures  {
                    nodes  {
                        id
                        estimatedDuration
                        timeStarted
                        timeFinished
                    }
                }
            }
        }
    }
`);

export type UseDeviceWetTests = {
    wetTestState: WetTestState | null;
    loading: boolean;
    errorMessage?: string;
};

export function useDeviceWetTests({
    cultureId,
}: {
    cultureId: string;
}): UseDeviceWetTests {
    const pollInterval = 3_000;
    const { data, loading, error } = useQuery(CULTURE_WET_TESTS_QUERY, {
        variables: { cultureId },
        pollInterval,
    });

    return wetTestsQueryToWetTestProps({
        data,
        loading,
        error,
    });
}

type Culture = NonNullable<WetTestsQuery["culture"]>;
type ProcedureNodes = NonNullable<
    NonNullable<Culture["schedule"]>["procedures"]
>;
type Procedure = NonNullable<NonNullable<ProcedureNodes["nodes"]>[0]>;

function getDurationMinutes(procedures: Procedure[]): number | undefined {
    if (procedures.some(p => p.estimatedDuration === null)) {
        return undefined;
    }

    const msDuration = sum(procedures.map(p => p.estimatedDuration));
    return msDuration / (1000 * 60);
}

function getTimeStarted(procedures: Procedure[]): Date | undefined {
    const timeStarted = procedures.at(0)?.timeStarted;
    return timeStarted ? new Date(timeStarted) : undefined;
}

function getTimeFinished(procedures: Procedure[]): Date | undefined {
    const timeFinished = procedures.at(-1)?.timeFinished;
    return timeFinished ? new Date(timeFinished) : undefined;
}

function wetTestsQueryToWetTestProps({
    data,
    loading,
    error,
}: {
    data?: WetTestsQuery;
    loading: boolean;
    error?: ApolloError;
}): UseDeviceWetTests {
    if (error) {
        return {
            loading,
            errorMessage: error.message,
            wetTestState: null,
        };
    }

    if (!data) {
        return {
            loading,
            errorMessage: "No data",
            wetTestState: null,
        };
    }

    if (!data.culture?.isWetTestCulture) {
        return {
            loading,
            errorMessage: "Culture is loaded, not Wet Tests",
            wetTestState: null,
        };
    }

    const procedureNodes = data.culture?.schedule?.procedures?.nodes;
    const procedures = removeNullables(procedureNodes ?? []);

    if (!procedures.length) {
        return {
            loading,
            errorMessage: "Couldn't find procedures for Wet Tests",
            wetTestState: null,
        };
    }

    const cultureState = data.culture.state;

    if (!cultureState) {
        return {
            loading,
            errorMessage: "Couldn't find culture state",
            wetTestState: null,
        };
    }
    if (cultureState === CultureState.Loading) {
        return {
            loading: true,
            wetTestState: null,
        };
    }
    if (cultureState === CultureState.Updating) {
        return {
            loading,
            errorMessage: "Culture is updating",
            wetTestState: null,
        };
    }

    const estimatedDurationMinutes = getDurationMinutes(procedures);
    const status = (
        {
            [CultureState.Ready]: "READY",
            [CultureState.Complete]: "COMPLETE",
            [CultureState.Running]: "RUNNING",
            [CultureState.Pausing]: "RUNNING",
            [CultureState.Paused]: "RUNNING",
            [CultureState.Updating]: "RUNNING",
        } as const
    )[cultureState];

    const errorCultureStates = [
        CultureState.Paused,
        CultureState.Pausing,
        CultureState.Updating,
    ];

    const cultureStateErrorMessage = errorCultureStates.includes(cultureState)
        ? `Culture is ${cultureState.toLowerCase()}`
        : undefined;

    return {
        loading,
        errorMessage: data.culture.errorMessage ?? cultureStateErrorMessage,
        wetTestState: {
            estimatedDurationMinutes,
            status,
            timeStarted: getTimeStarted(procedures),
            timeFinished: getTimeFinished(procedures),
        },
    };
}

export const exportsForTesting = {
    wetTestsQueryToWetTestProps,
};
