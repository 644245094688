import React, { ReactElement } from "react";

import {
    Divider,
    Menu,
    MenuItem as MuiMenuItem,
    Tooltip,
    useTheme,
} from "@mui/material";

import Icon, { IconName } from "./Icon";
import Txt from "./Text";

export type MenuOption = {
    /**
     * The string displayed in the menu to represent the option
     */
    label: string;
    /**
     * The function called when the option is clicked
     */
    onClick: () => void;
    /**
     * Disabled options are unclickable
     */
    disabled?: boolean;
    /**
     * Set a colour for the option
     */
    colourType?: ColourTypes;
    /**
     * Name of icon to be displayed next to label
     */
    icon?: IconName;
    /**
     * Tooltip to display on hover
     */
    tooltip?: string;
};

type ColourTypes = "default" | "warn" | "danger";
export type MenuOptions = (MenuOption | "divider")[];

export interface MenuWrapperProps {
    /**
     * Wrapped element
     */
    render: (props: {
        onClick: (event: React.MouseEvent<HTMLElement>) => void;
    }) => ReactElement;
    /**
     * Array of options to display in the menu. Properties of options are:
     * - `label` - the text displayed in the menu
     * - `callback` - the function called when the option is selected
     */
    options: MenuOptions;
    arrowPositionRight: number;
    /**
     * Manually set the container Element by passing a ref.
     * Generally, you should not need to use this property.
     */
    _containerRef?: React.MutableRefObject<Element | null>;
}

/**
 * The `MoreButton` is a specialised IconButton with a dropdown menu for
 * additional functions that the user can use.
 *
 * By default it stops click propagation.
 */
export function MenuWrapper(props: MenuWrapperProps): ReactElement {
    const { options, arrowPositionRight } = props;

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * If any option.icon is present, display icons space for all
     */
    const displayIcons = options.reduce<boolean>((bool, option) => {
        if (option !== "divider" && option.icon) return true;
        return bool;
    }, false);

    return (
        <div
            style={{ display: "inline-block" }}
            onClick={e => {
                e.stopPropagation();
            }}>
            {props.render({ onClick: handleClick })}
            <Menu
                id="long-menu"
                container={props._containerRef?.current}
                anchorEl={anchorEl}
                elevation={0}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        "border": "1px solid " + theme.colours.neutral[300],
                        "boxShadow": "0 4px 6px 0 rgba(0, 0, 0, 0.05)",
                        "minWidth": 120,
                        "maxWidth": 300,

                        // add the arrow
                        "overflow": "visible",
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: arrowPositionRight,
                            width: 10,
                            height: 10,
                            borderTop:
                                "1px solid " + theme.colours.neutral[300],
                            borderLeft:
                                "1px solid " + theme.colours.neutral[300],
                            bgcolor: "background.paper",
                            transform: "translateY(-56%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                MenuListProps={{
                    sx: {
                        "borderRadius": "4px",
                        "overflow": "hidden",
                        "padding": 0,
                        "& > *:not(:last-child)": {
                            // marginBottom: 6,
                        },
                    },
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}>
                {options.map((option, i) => {
                    if (option === "divider") {
                        return (
                            <Divider
                                key={`divider ${i}`}
                                sx={{
                                    borderColor: theme.colours.neutral[300],
                                }}
                            />
                        );
                    }
                    const menuItem = (
                        <MenuItem
                            key={option.label}
                            option={option}
                            onClick={handleClose}
                            displayIcons={displayIcons}
                        />
                    );

                    if (option.tooltip) {
                        return (
                            <Tooltip
                                key={option.label}
                                title={option.tooltip}
                                placement="left">
                                <div>{menuItem}</div>
                            </Tooltip>
                        );
                    }

                    return menuItem;
                })}
            </Menu>
        </div>
    );
}

interface MenuItemProps {
    option: MenuOption;
    displayIcons: boolean;
    onClick: () => void;
}

function MenuItem({
    option,
    onClick,
    displayIcons,
}: MenuItemProps): ReactElement {
    const theme = useTheme();
    const colourOption = option.colourType ?? "default";
    const colourMap: {
        [P in ColourTypes]: string;
    } = {
        default: theme.colours.neutral[700],
        warn: theme.colours.accent.alertOrange,
        danger: theme.colours.accent.errorRed,
    };
    const bgColourMap: {
        [P in ColourTypes]: string;
    } = {
        default: theme.colours.neutral[200],
        warn: theme.colours.accent.alertOrange + "33",
        danger: theme.colours.accent.errorRed + "33",
    };
    return (
        <MuiMenuItem
            data-testid={option.label}
            disabled={option.disabled}
            sx={{
                "cursor": "pointer",
                "margin": 1,
                "borderRadius": 1,
                "py": "4px",
                "pl": displayIcons ? "8px" : "8px",
                "pr": "8px",
                "color": colourMap[colourOption],
                "&:hover": {
                    backgroundColor: bgColourMap[colourOption],
                    // color: theme.colours.neutral.white,
                },
                "&:focus": {
                    outline: 0,
                },
                "& 	.Mui-selected": {
                    "color": theme.colours.brand.greenDark,
                    "&:focus": {
                        outline: "none",
                    },
                    // Mui screwed up by applying higher specificity to their classes
                    "&&, &&:hover": {
                        backgroundColor: "#F0FAFA",
                    },
                },
            }}
            onClick={() => {
                onClick(); // parent callback
                option.onClick(); // option-specific callback
            }}>
            {displayIcons && (
                <div
                    style={{
                        display: "flex",
                        width: 16,
                        marginRight: 8,
                        alignItems: "center",
                    }}>
                    {option.icon && <Icon name={option.icon} />}
                </div>
            )}
            <Txt font="secondary" level={8}>
                {option.label}
            </Txt>
        </MuiMenuItem>
    );
}
