import { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

import { ButtonGroup } from "components/common/ButtonGroup";
import { CopyToClipboard } from "components/common/CopyToClipboard";
import Txt from "components/common/Text";
import { formatSecondsDuration, formatTimeDiff } from "services/date";
import { Invocation } from "services/hooks/useCultureStepInvocations";
import { roundString, toTitleCase } from "services/string-utils";

import { log as parentLog } from "../log";

export const log = parentLog.extend("InvocationDetails");

interface InvocationDetailsProps {
    invocation: Invocation;
}

export function InvocationDetails(props: InvocationDetailsProps): ReactElement {
    const views = ["Parameters", "Output"];
    const [view, setView] = useState(views[0]);
    const theme = useTheme();

    const createInvocationDetailsRow = (key: string, value: string) => {
        const name = toTitleCase(key).replace(/\b(Id)\b/, "ID");
        const formattedValue = roundString(value, 2) ?? value;
        return (
            <InvocationDetailsRow
                key={key + value}
                name={name}
                value={formattedValue}
            />
        );
    };

    log.debug(props.invocation.parameters);
    let rows: ReactElement[] = [];
    if (view === "Parameters") {
        rows = Object.keys(props.invocation.parameters).map(param =>
            createInvocationDetailsRow(
                param,
                String(props.invocation.parameters[param]),
            ),
        );
    }
    if (view === "Output") {
        rows = Object.keys(props.invocation.output ?? {})
            .sort()
            .map(param =>
                createInvocationDetailsRow(
                    param,
                    JSON.stringify(props.invocation.output?.[param]),
                ),
            );
    }

    const rawEstDuration = props.invocation.estimatedDuration;
    const estimatedDuration =
        rawEstDuration !== null
            ? formatSecondsDuration(rawEstDuration / 1000)
            : "None";

    const invocationDuration =
        props.invocation.timeStarted && props.invocation.timeFinished
            ? formatTimeDiff(
                  props.invocation.timeStarted,
                  props.invocation.timeFinished,
              )
            : "N/A";

    return (
        <div
            css={css`
                margin-top: 8px;
            `}>
            <ButtonContainerDiv>
                <ButtonGroup
                    value={view}
                    onChange={v => setView(v)}
                    values={views}
                />
            </ButtonContainerDiv>
            <div
                onClick={e => e.stopPropagation()}
                css={css`
                    border: 1px solid ${theme.colours.neutral[200]};
                    border-radius: 4px;
                    padding: 8px;
                    background-color: ${theme.colours.neutral.white};
                `}>
                <InvocationDetailsRow
                    name="Invocation ID"
                    value={props.invocation.id}
                />
                <InvocationDetailsRow
                    name="Operation Name"
                    value={props.invocation.operationId ?? "unknown"}
                />
                <InvocationDetailsRow
                    name="Estimated Duration"
                    value={estimatedDuration}
                />
                <InvocationDetailsRow
                    name="Time started"
                    value={props.invocation.timeStarted ?? "N/A"}
                />
                <InvocationDetailsRow
                    name="Time finished"
                    value={props.invocation.timeFinished ?? "N/A"}
                />
                <InvocationDetailsRow
                    name="Runtime"
                    value={invocationDuration}
                />
                {rows}
            </div>
        </div>
    );
}

function InvocationDetailsRow({
    name,
    value,
}: {
    name: string;
    value: string;
}): ReactElement {
    const theme = useTheme();
    if (
        value &&
        new Date(Date.parse(value)).toString() !== "Invalid Date" &&
        new Date(Date.parse(value)).toISOString() === value
    ) {
        // This gives us a time output in the form "08/04/2024, 15:41:11.483 GMT+01:00"
        value = new Date(Date.parse(value)).toLocaleString(undefined, {
            timeZoneName: "longOffset",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            fractionalSecondDigits: 3,
        });
    }
    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid ${theme.colours.neutral[200]};
                padding: 2px 0;
                margin-bottom: 4px;
                gap: 8px;
            `}>
            <Txt font="secondary" level={9} style={{ flexGrow: 1 }}>
                {name}
            </Txt>
            <Txt font="secondary" level={9} emphasis>
                {value.length > 100 ? value.slice(0, 20) + "..." : value}
            </Txt>
            <CopyToClipboard variant="compact" copytext={value} />
        </div>
    );
}

const ButtonContainerDiv = styled.div`
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
`;
