import React, { ReactElement } from "react";

import { IconButton } from "./IconButton";
import { MenuOptions, MenuWrapper, MenuWrapperProps } from "./Menu";

type Size = "sm" | "md" | "lg";

export type MoreButtonOptions = MenuOptions;

export type MoreButtonProps = {
    /**
     * Size of the IconButton. Default to "md".
     */
    size?: Size;
    /**
     * Orient the three dot menu vertically
     */
    vertical?: boolean;
    /**
     * Manually override the colour of the IconButton. If no value is provided
     * it is inherited from parent.
     */
    colour?: string;
    /**
     * Manually set the container Element by passing a ref.
     * Generally, you should not need to use this property.
     */
    _containerRef?: React.MutableRefObject<Element | null>;
} & Pick<MenuWrapperProps, "options">;

/**
 * The `MoreButton` is a specialised IconButton with a dropdown menu for
 * additional functions that the user can use.
 *
 * By default it stops click propagation.
 */
export function MoreButton(props: MoreButtonProps): ReactElement {
    const { vertical = false, options, size = "md", colour } = props;

    const posMap: { [S in Size]: number } = { sm: 5, md: 10, lg: 13 };
    const arrowPositionRight = posMap[size];

    return (
        <MenuWrapper
            arrowPositionRight={arrowPositionRight}
            options={options}
            _containerRef={props._containerRef}
            render={props => (
                <IconButton
                    {...props}
                    size={size}
                    disabled={options.length < 1}
                    icon="more-horizontal"
                    colour={colour}
                    style={{
                        transform: vertical ? "rotate(90deg)" : undefined,
                    }}
                />
            )}
        />
    );
}
