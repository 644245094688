import { useState, useEffect } from "react";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { log as parentLog } from "services";

import { config } from "./config";
import { setSentryScope } from "./sentry";

const log = parentLog.extend("logrocket");

export function initLogrocket(): void {
    if (!config.isLocal) {
        LogRocket.init("r8kiqb/app", {
            release: config.gitHash,
            dom: {
                // isEnabled: false, // ? only use for debugging
                // inputSanitizer: true,
            },
            /**
             * Read more about network options and examples:
             * https://docs.logrocket.com/reference/network
             */
            network: {
                // isEnabled: false, // ? only use for debugging
                requestSanitizer,
                responseSanitizer,
            },
        });
        setupLogRocketReact(LogRocket);
        // eslint-disable-next-line no-console
        console.log("Logrocket initialised");
        LogRocket.getSessionURL(sessionURL => {
            // eslint-disable-next-line no-console
            console.log("Session URL", sessionURL);
            setSentryScope("LogRocket Session URL", sessionURL);
        });
    }
}

type LRInitOptions = NonNullable<Parameters<typeof LogRocket.init>[1]>;
type LRNetworkOptions = NonNullable<LRInitOptions["network"]>;
type RequestSanitizer = LRNetworkOptions["requestSanitizer"];
type ResponseSanitizer = LRNetworkOptions["responseSanitizer"];

const requestIdToSanitise = new Set<string>();

const requestSanitizer: RequestSanitizer = request => {
    // Remove the authorization header from all requests
    if (request.headers["authorization"]) {
        request.headers["authorization"] = "";
    }
    // Remove the body and response of a login mutation
    if (request.url.toLowerCase().indexOf("graphql/loginmutation") !== -1) {
        request.body = undefined;
        requestIdToSanitise.add(request.reqId);
        log.info("Removed login mutation body from request", { request });
    }
    if (request.url.toLowerCase().indexOf("resources?bucket") !== -1) {
        requestIdToSanitise.add(request.reqId);
        log.info("Added request to sanitise list", { request });
    }
    return request; // log the request normally
};

const responseSanitizer: ResponseSanitizer = response => {
    const requestId = response.reqId;
    const shouldSanitise = requestIdToSanitise.has(requestId);
    if (shouldSanitise) {
        response.body = undefined;
        log.info("Removed body from response", { requestId });
        requestIdToSanitise.delete(requestId);
    }
    return response;
};

/**
 * Configures the Logrocket SDK to understand the context of which user is being
 * monitored through a unique identifier.
 *
 * @param id - The User identifier
 */
export function identifyUserInLogrocket(
    id: string,
    additional: {
        email: string;
        [propName: string]: string | number | boolean;
    },
): void {
    LogRocket.identify(id, additional);
}

/**
 * Retrieves the support/logging session URL
 * @returns sessionUrl - string or null
 * @returns loading - boolean
 */
export function useSessionURL(): {
    sessionUrl: string | null;
    loading: boolean;
} {
    const [sessionUrl, setUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const notInitialised = LogRocket.sessionURL?.includes("LogRocket.init");
        if (notInitialised) return;
        setLoading(true);
        LogRocket.getSessionURL(url => {
            setLoading(false);
            setUrl(url);
        });
    }, []);
    return { sessionUrl, loading };
}
