import React, { ReactElement } from "react";

import { css } from "@emotion/react";
import { clamp } from "lodash";

import Bottle0050 from "icons/bottles/bottle-0050mL.svg?react";
import Bottle0175 from "icons/bottles/bottle-0175mL.svg?react";
import Bottle0250 from "icons/bottles/bottle-0250mL.svg?react";
import Bottle0500 from "icons/bottles/bottle-0500mL.svg?react";
import Bottle1000 from "icons/bottles/bottle-1000mL.svg?react";
import Bottle2700 from "icons/bottles/bottle-2700mL.svg?react";
import Bottle5000 from "icons/bottles/bottle-5000mL.svg?react";

export interface BottleProps {
    /**
     * Volume capacity of bottle in millilitres (mL)
     */
    capacity: BottleCapacity;
    /**
     * Percentage fill of the bottle
     */
    volumePercent: number;
    /**
     * Visual style of the liquid rendered (e.g. "media")
     */
    liquidStyle: keyof typeof liquidStyles;
}

/**
 * Library of available liquid styles
 */
const liquidStyles = {
    media: { fill: "#F4A2A2", stroke: "#F06565" },
    pbs: { fill: "#EBFBFF", stroke: "#65CFF0" },
} satisfies {
    [k: string]: { fill: string; stroke: string };
};

/**
 * Bottle capacities that can be rendered
 */
export const BOTTLE_CAPACITIES = [50, 175, 250, 500, 1000, 2700, 5000] as const;

export type BottleCapacity = (typeof BOTTLE_CAPACITIES)[number];

/**
 * Type-guard for a number to confirm it is an renderable Bottle capacity
 * @param capacity - Number to check
 * @returns boolean verifying type of parameter
 */
export function isValidBottleCapacity(
    capacity: number,
): capacity is BottleCapacity {
    return BOTTLE_CAPACITIES.includes(capacity);
}

/**
 * Available bottle sizes, mapped to the corresponding SVG element as a React
 * component, and the custom scaling factor for the liquid.
 *
 * The "Y Scale Factor" is a custom per-SVG value that 'corrects' the liquid
 * volume such that a volumePercent prop value of 1 is visually accurate to a
 * 'full' bottle.
 */
const bottleResources = {
    50: { component: Bottle0050, yScaleFactor: 1.1 },
    175: { component: Bottle0175, yScaleFactor: 1.0 },
    250: { component: Bottle0250, yScaleFactor: 1.05 },
    500: { component: Bottle0500, yScaleFactor: 1.1 },
    1000: { component: Bottle1000, yScaleFactor: 1.25 },
    2700: { component: Bottle2700, yScaleFactor: 1.8 },
    5000: { component: Bottle5000, yScaleFactor: 1.6 },
} satisfies {
    [key in BottleCapacity]: {
        component: React.FunctionComponent;
        yScaleFactor: number;
    };
};

/**
 * A dynamic graphic component to render the visualisation of a Mytos consumable
 * bottle.
 */
export function Bottle({
    capacity = 50,
    volumePercent = 1,
    liquidStyle = "media",
}: BottleProps): ReactElement {
    const bottle = bottleResources[capacity];
    const clampedPercent = clamp(volumePercent, 0, 1) * bottle.yScaleFactor;
    const BottleComponent = bottle.component;
    return (
        <BottleComponent
            css={css`
                // since we have a viewport defined in the source files, we can
                // set width, and height to dynamically scale the svg container
                width: 100%;
                height: 100%;

                // sensible upper limit
                max-width: 250px;
                max-height: 250px;

                #liquid {
                    fill: ${liquidStyles[liquidStyle].fill};
                    stroke: ${liquidStyles[liquidStyle].stroke};

                    // Set the transform origin to the bottom center of the rectangle
                    transform-origin: bottom center;

                    // Scale the element along the y-axis
                    transform: scaleY(${clampedPercent});

                    // Transition for smooth transform changes
                    transition: transform 0.5s ease;
                }
            `}
        />
    );
}
