import { useState, Fragment, ReactElement } from "react";

import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import {
    AddDeviceUserMutationVariables,
    DeviceUserRole,
} from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { TextInput } from "components/common/TextInput";
import { TextLabel } from "components/common/TextLabel";
import { useToasts } from "components/common/toasts/useToasts";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { log as parentLog } from "../../log";

import { SelectUserRole } from "./SelectUserRole";

export const log = parentLog.extend("AddDeviceUserDialog");

interface AddDeviceUserDialogProps {
    deviceId: string;
}

const ADD_DEVICE_USER_MUTATION = gql(`
    mutation AddDeviceUser($input: AddDeviceUserInput!) {
        addDeviceUser(input: $input) {
            ok
            message
            device {
                id
                users {
                    totalCount
                    edges {
                        id
                        role
                        user {
                            id
                        }
                    }
                }
            }
        }
    }
`);

export default function AddDeviceUserDialog(
    props: AddDeviceUserDialogProps,
): ReactElement {
    const { deviceId } = props;
    const [open, setOpen] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState("");
    const [newUserRole, setNewUserRole] = useState(DeviceUserRole.Viewer);
    const { toast } = useToasts();
    const { isAdmin } = useDeviceRole(deviceId);
    const [addDeviceUserMutation] = useMutation(ADD_DEVICE_USER_MUTATION);
    const [changePending, setChangePending] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = async () => {
        log.debug("new user email", newUserEmail);
        log.debug("new user role", newUserRole);
        try {
            setChangePending(true);
            const variables: AddDeviceUserMutationVariables = {
                input: {
                    deviceId: deviceId,
                    userEmail: newUserEmail,
                    role: newUserRole,
                },
            };
            const res = await addDeviceUserMutation({ variables });
            setChangePending(false);

            log.debug("Response to adding new device user", res);
            if (res.data?.addDeviceUser) {
                if (res.data.addDeviceUser.ok) {
                    toast.success("New user added");
                    setOpen(false);
                    setNewUserEmail("");
                    return;
                } else {
                    toast.error(
                        "Unable to add new user because " +
                            res.data.addDeviceUser.message,
                    );
                    return;
                }
            }
            toast.error("Unable to add new user. Please contact support.");
        } catch (e) {
            log.error("Error encountered during add device user mutation", e);
        }
    };

    return (
        <Fragment>
            <Button
                disabled={!isAdmin}
                variant="secondary"
                size="s"
                iconLeft="plus"
                onClick={handleOpen}>
                Add User
            </Button>
            <ConfirmationDialog
                variant="advised"
                open={open}
                onSecondaryAction={handleClose}
                onDismiss={handleClose}
                onPrimaryAction={handleChange}
                primaryButtonDisabled={changePending}
                title="Add new user with email"
                body="Please provide the email address of the user for which you would like to grant access to this device."
                primaryButtonText="Confirm details">
                <TextLabel label="Email">
                    <TextInput
                        value={newUserEmail}
                        onChange={setNewUserEmail}
                        required={true}
                        fullWidth={true}
                    />
                </TextLabel>
                <TextLabel label="Role">
                    <SelectUserRole
                        defaultValue={newUserRole}
                        onChange={setNewUserRole}
                    />
                </TextLabel>
            </ConfirmationDialog>
        </Fragment>
    );
}
