import { ReactElement, useEffect } from "react";

import {
    ApolloQueryResult,
    OperationVariables,
    useQuery,
} from "@apollo/client";
import styled from "@emotion/styled";
import { Collapse } from "@mui/material";

import { gql } from "__generated__/apollo";
import { CultureState, DeviceCultureQuery } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Callout from "components/common/Callout";
import { Card } from "components/common/Card";
import Skeleton from "components/common/Skeleton";
import { TabContent } from "components/common/TabContent";
import Txt from "components/common/Text";
import { TextLabel } from "components/common/TextLabel";
import GraphCard from "components/pages/Device/Culture/Confluence/GraphCard";
import { getCultureStateString, getCultureName } from "services/culture-utils";
import { formatDate, formatDatetime, relativeDatetime } from "services/date";

import {
    useCulturePollInterval,
    useScheduleIsViewOnly,
} from "../Schedule/schedule-data";

// eslint-disable-next-line import/no-deprecated
import ClearCultureDialog from "./ClearCultureDialog";
import ContainersCard from "./Containers/ContainersCard";
import { CreateCulture } from "./CreateCulture";
import { CreateCultureModal } from "./CreateCulture/CreateCultureModal";
import {
    CultureSetupPhase,
    setQueriedStateAdapter,
    useCultureSetupModalState,
    useCultureSetupState,
} from "./CreateCulture/culture-setup-state";
import { CultureSetupModal } from "./CreateCulture/CultureSetupModal";
import { CultureControlButton } from "./CultureControlButton";
import { CultureError } from "./CultureError";
import { DownloadDataButton } from "./DownloadDataButton";
import { log as parentLog } from "./log";
import { SimulationError } from "./SimulationError";

export const log = parentLog.extend("DeviceCulture");

interface CultureTabByDeviceProps {
    deviceId: string;
}

interface CultureTabByCultureProps {
    cultureId: string;
}

export function CultureTab(
    props: CultureTabByDeviceProps | CultureTabByCultureProps,
): ReactElement {
    const viewOnly = useScheduleIsViewOnly();

    const { culture, loading, refetch, deviceId } = useCulture(props);

    const { setSetupState, resetSetupState } = useCultureSetupState();

    const { setModalOpen, currentSetupPhase } = useCultureSetupModalState();

    useEffect(() => {
        setSetupState(s => ({
            ...s,
            ...setQueriedStateAdapter(culture),
        }));
    }, [culture, setSetupState]);

    if (!deviceId) {
        return (
            <TabContent>
                {loading ? (
                    <Skeleton style={{ height: "100px", width: "100%" }} />
                ) : (
                    <Callout
                        variant="error"
                        message="Unable to load culture, please contact support"
                    />
                )}
            </TabContent>
        );
    }

    log.debug({ culture });

    const cultureId = culture?.id;

    const wetTestCulture = culture?.isWetTestCulture ? culture : undefined;
    const primaryCulture = culture?.isWetTestCulture ? undefined : culture;

    return (
        <TabContent>
            <SpaceChildren>
                {!viewOnly && <CreateCulture deviceId={deviceId} />}
                <>
                    <CreateCultureModal deviceId={deviceId} />
                    <CultureSetupModal deviceId={deviceId} />
                </>
                {culture?.errorMessage && (
                    <CultureError errorMessage={culture.errorMessage} />
                )}
                {culture?.lastSimulationError && (
                    <SimulationError
                        errorMessage={culture.lastSimulationError.errorMessage}
                    />
                )}
                {wetTestCulture && (
                    <Collapse in={Boolean(wetTestCulture && !loading)}>
                        <WetTestOverviewCard
                            wetTestCulture={wetTestCulture}
                            loading={loading}
                            viewOnly={viewOnly}
                            setModalOpen={setModalOpen}
                            currentSetupPhase={currentSetupPhase}
                            deviceId={deviceId}
                        />
                    </Collapse>
                )}
                <CultureOverviewCard
                    deviceId={deviceId}
                    viewOnly={viewOnly}
                    currentSetupPhase={currentSetupPhase}
                    setModalOpen={setModalOpen}
                    onCultureCleared={async () => {
                        const result = await refetch?.();
                        if (result) {
                            resetSetupState();
                        }
                    }}
                    primaryCulture={primaryCulture}
                    wetTestCulture={wetTestCulture}
                    loading={loading}
                />
                <GraphCard cultureId={cultureId} />
                <ContainersCard cultureId={cultureId} />
            </SpaceChildren>
        </TabContent>
    );
}

function WetTestOverviewCard({
    wetTestCulture,
    loading,
    viewOnly,
    setModalOpen,
    currentSetupPhase,
    deviceId,
}: {
    wetTestCulture: DeviceCultureCulture;
    loading: boolean;
    viewOnly: boolean;
    setModalOpen: (open: boolean) => void;
    currentSetupPhase: CultureSetupPhase;
    deviceId: string;
}) {
    return (
        <OverviewCard>
            <Txt level={4}>Wet Tests overview</Txt>
            <OverviewCardContent>
                <Column>
                    <TextLabel label="State">
                        <Txt
                            skeleton={!wetTestCulture?.state && loading}
                            level={6}>
                            {getCultureStateString({
                                cultureState: wetTestCulture?.state,
                            })}
                        </Txt>
                    </TextLabel>
                </Column>
                <Controls>
                    {!viewOnly &&
                        currentSetupPhase !==
                            CultureSetupPhase.WetTestComplete && (
                            <Button
                                onClick={() => setModalOpen(true)}
                                iconLeft={"arrow-into-circle"}>
                                {wetTestCulture?.state === CultureState.Ready ||
                                wetTestCulture?.state === CultureState.Loading
                                    ? "Run Wet Tests"
                                    : "View Progress"}
                            </Button>
                        )}
                    {!viewOnly &&
                        currentSetupPhase !==
                            CultureSetupPhase.WetTestReady && (
                            <CultureControlButton
                                deviceId={deviceId}
                                cultureId={wetTestCulture.id}
                                cultureHasProcedureToRun={Boolean(
                                    wetTestCulture?.schedule?.nextProcedure,
                                )}
                                noun="Wet Tests"
                            />
                        )}
                </Controls>
            </OverviewCardContent>
        </OverviewCard>
    );
}

function CultureOverviewCard({
    deviceId,
    viewOnly,
    currentSetupPhase,
    setModalOpen,
    onCultureCleared,
    primaryCulture,
    wetTestCulture,
    loading,
}: {
    deviceId: string;
    viewOnly: boolean;
    currentSetupPhase: CultureSetupPhase;
    setModalOpen: (open: boolean) => void;
    onCultureCleared: () => Promise<void>;
    primaryCulture?: DeviceCultureCulture;
    wetTestCulture?: DeviceCultureCulture;
    loading: boolean;
}) {
    const culture = primaryCulture ?? wetTestCulture;

    const cultureName = getCultureName(culture);
    const cultureDescription =
        culture?.description === "" ? "-" : culture?.description;
    const clearCultureEnabled =
        culture?.state !== CultureState.Loading ||
        culture.lastSimulationError ||
        culture.errorMessage;

    const enterModalEnabled =
        !loading &&
        wetTestCulture &&
        currentSetupPhase === CultureSetupPhase.WetTestComplete;

    return (
        <OverviewCard>
            <Txt level={4}>Culture overview</Txt>
            <OverviewCardContent>
                <Column>
                    <TextLabel label="State">
                        <Txt skeleton={!culture?.state && loading} level={6}>
                            {getCultureStateString({
                                cultureState: primaryCulture?.state,
                                wetTestCultureState: wetTestCulture?.state,
                            })}
                        </Txt>
                    </TextLabel>
                    <TextLabel label="Culture Name">
                        <Txt skeleton={!cultureName && loading} level={6}>
                            {cultureName ?? "-"}
                        </Txt>
                    </TextLabel>
                    <TextLabel label="Description">
                        <Txt
                            skeleton={!cultureDescription && loading}
                            level={6}>
                            {cultureDescription ?? "-"}
                        </Txt>
                    </TextLabel>
                    <TextLabel label="Date" childrenDirection="column">
                        <Txt
                            skeleton={!primaryCulture?.startDate && loading}
                            level={6}>
                            {primaryCulture?.startDate
                                ? formatDate(primaryCulture?.startDate)
                                : "-"}
                        </Txt>
                        {primaryCulture?.startDate && (
                            <Txt
                                skeleton={!primaryCulture?.startDate && loading}
                                level={7}
                                italic>
                                {relativeDatetime(primaryCulture?.startDate)}
                            </Txt>
                        )}
                    </TextLabel>
                    <TextLabel label="Time uploaded" childrenDirection="column">
                        <Txt
                            skeleton={!primaryCulture?.uploadDate && loading}
                            level={6}>
                            {primaryCulture?.uploadDate
                                ? formatDatetime(primaryCulture?.uploadDate)
                                : "-"}
                        </Txt>
                        {primaryCulture?.uploadDate && (
                            <Txt
                                skeleton={
                                    !primaryCulture?.uploadDate && loading
                                }
                                level={7}
                                italic>
                                {relativeDatetime(primaryCulture?.uploadDate)}
                            </Txt>
                        )}
                    </TextLabel>
                </Column>
                <Controls>
                    {!viewOnly && enterModalEnabled && (
                        <Button
                            onClick={() => setModalOpen(true)}
                            iconLeft={"arrow-into-circle"}>
                            Reagent Setup
                        </Button>
                    )}

                    {!viewOnly && culture && (
                        <CultureControlButton
                            deviceId={deviceId}
                            cultureId={primaryCulture?.id}
                            cultureHasProcedureToRun={Boolean(
                                primaryCulture?.schedule?.nextProcedure,
                            )}
                            isSetupRequired={Boolean(wetTestCulture)}
                            noun="Culture"
                        />
                    )}

                    {!viewOnly && culture && (
                        <ClearCultureDialog
                            cultureId={culture.id}
                            deviceId={deviceId}
                            cultureState={culture.state ?? undefined}
                            disabled={!clearCultureEnabled}
                            cultureName={cultureName ?? "unnamed"}
                            onCultureCleared={onCultureCleared}
                        />
                    )}
                    {!viewOnly && primaryCulture && (
                        <DownloadDataButton
                            deviceId={deviceId}
                            cultureId={primaryCulture.id}
                            disabled={primaryCulture?.state === undefined}
                        />
                    )}
                </Controls>
            </OverviewCardContent>
        </OverviewCard>
    );
}

export const DEVICE_CULTURE_QUERY = gql(`
    query DeviceCulture($deviceId: String!, $filterBy: CultureConnectionFilters) {
        device(id: $deviceId) {
            id
            platform
            culture {
                name
                description
                errorMessage
                startDate
                uploadDate
                state
                id
                mintedByServer
                plannedDuration
                ...CultureSetupQueryData
                protocol {
                    id
                }
                schedule {
                    nextProcedure {
                        id
                    }
                }
                lastSimulationError {
                    id
                    errorMessage
                }
            }
            # We will eventually migrate to this field for device culture data.
            # For the time being this is being used to update the cache for the
            # ProtocolUploadConditions query.
            cultures(filterBy: $filterBy) {
                nodes {
                    id
                    state
                }
            }
        }
    }
`);

type DeviceCultureDevice = NonNullable<DeviceCultureQuery["device"]>;
export type DeviceCultureCulture = NonNullable<DeviceCultureDevice["culture"]>;

export const CULTURE_OVERVIEW_QUERY = gql(`
    query CultureOverview($cultureId: String!) {
        culture(id: $cultureId) {
            name
            description
            startDate
            uploadDate
            state
            id
            plannedDuration
            errorMessage
            mintedByServer
            ...CultureSetupQueryData
            device {
                id
                platform
            }
            schedule {
                nextProcedure {
                    id
                }
            }
            lastSimulationError {
                id
                errorMessage
            }
        }
    }
`);

function useCulture(
    props: CultureTabByDeviceProps | CultureTabByCultureProps,
): {
    deviceId?: string;
    culture: DeviceCultureCulture | undefined;
    loading: boolean;
    refetch?: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<DeviceCultureQuery>>;
} {
    const isDeviceProp = "deviceId" in props;
    const culturePollIntervalMs = useCulturePollInterval(
        isDeviceProp
            ? {
                  deviceId: props.deviceId,
              }
            : { cultureId: props.cultureId },
    );

    const {
        data: deviceData,
        loading: deviceLoading,
        refetch: deviceRefetch,
    } = useQuery(DEVICE_CULTURE_QUERY, {
        variables: {
            deviceId: (isDeviceProp && props.deviceId) || "",
            filterBy: {
                onDevice: true,
            },
        },
        skip: !isDeviceProp,
        pollInterval: culturePollIntervalMs,
    });

    const { data: cultureData, loading: cultureLoading } = useQuery(
        CULTURE_OVERVIEW_QUERY,
        {
            variables: { cultureId: (!isDeviceProp && props.cultureId) || "" },
            skip: isDeviceProp,
            pollInterval: culturePollIntervalMs,
        },
    );

    if (isDeviceProp)
        return {
            deviceId: props.deviceId,
            culture: deviceData?.device?.culture ?? undefined,
            loading: deviceLoading,
            refetch: async variables => {
                return deviceRefetch(variables);
            },
        };
    return {
        deviceId: cultureData?.culture?.device?.id ?? undefined,
        culture: cultureData?.culture ?? undefined,
        loading: cultureLoading,
    };
}

const SpaceChildren = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const OverviewCard = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const OverviewCardContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 40px; */
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
`;

const Controls = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    button {
        margin-bottom: 20px;
    }
`;
