import { ReactElement } from "react";

import { styled } from "@mui/material";

import Txt from "./Text";

export interface OnlineIndicatorProps {
    online: boolean;
    /**
     * If enabled, will minimise its size by displaying graphic without text.
     * Suitable for mobile screens. Defaults false.
     */
    minimise?: boolean;
}

export function OnlineIndicator({
    online,
    minimise = false,
}: OnlineIndicatorProps): ReactElement {
    return (
        <IndicatorContainer online={online}>
            {online ? <IndicatorOnline /> : <IndicatorOffline />}
            {!minimise && (
                <Txt font="secondary" level={8} emphasis>
                    {online ? "Online" : "Offline"}
                </Txt>
            )}
        </IndicatorContainer>
    );
}

const IndicatorContainer = styled("div")<{ online: boolean }>`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0px;
    color: ${({ theme, online }) =>
        online ? theme.colours.green[400] : theme.colours.neutral[500]};
`;

const IndicatorBase = styled("div")`
    width: 10px;
    height: 10px;
    border-radius: 100px;
`;

const IndicatorOnline = styled(IndicatorBase)`
    background-color: currentColor;
    border: 2px solid;
    border-color: ${({ theme }) => theme.colours.green[200]};
`;

const IndicatorOffline = styled(IndicatorBase)`
    background-color: none;
    border: 2px solid;
    border-color: ${({ theme }) => theme.colours.neutral[500]};
`;
