import { useMutation } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { ProcedureControl } from "__generated__/apollo/graphql";
import {
    getToastifyMessages,
    useToasts,
} from "components/common/toasts/useToasts";

const procedureControlMutation = gql(`
    mutation ProcedureControl($input: ControlProcedureInput!) {
        controlProcedure(input: $input) {
            ok
            message
            procedure {
                id
                state
                confirmed
                confirmedBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`);

const CONTROL_LANGUAGE: Record<
    ProcedureControl,
    { past: string; gerund: string }
> = {
    CANCEL: {
        past: "cancelled",
        gerund: "cancelling",
    },
    REMOVE: {
        past: "removed",
        gerund: "removing",
    },
    RESTORE: {
        past: "restored",
        gerund: "restoring",
    },
    PAUSE: {
        past: "paused",
        gerund: "pausing",
    },
    CONFIRM: {
        past: "confirmed",
        gerund: "confirming",
    },
    UNCONFIRM: {
        past: "unconfirmed",
        gerund: "unconfirming",
    },
};

export default function useProcedureControl({
    onSuccess,
}: {
    onSuccess?: () => void;
} = {}) {
    const [procedureControl, mutationDetails] = useMutation(
        procedureControlMutation,
    );

    const { toastifyAction } = useToasts();

    const handleProcedureControl = async ({
        procedureId,
        control,
    }: {
        procedureId: string;
        control: ProcedureControl;
    }) => {
        const { gerund, past } = CONTROL_LANGUAGE[control];

        return toastifyAction(
            getToastifyMessages({
                entity: "procedure",
                verb: {
                    past,
                    gerund,
                },
            }),
            async () => {
                const output = await procedureControl({
                    variables: {
                        input: {
                            procedureId,
                            control,
                        },
                    },
                });

                if (output.data?.controlProcedure.ok) {
                    onSuccess?.();
                    return output;
                } else {
                    throw new Error(output.data?.controlProcedure.message);
                }
            },
        );
    };

    return [handleProcedureControl, mutationDetails] as const;
}
