import { ReactElement, useRef, useEffect } from "react";

import styled from "@emotion/styled";
import { useWindowHeight, useWindowWidth } from "@react-hook/window-size";
import { clamp } from "lodash";

import Button from "components/common/Button";
import Txt from "components/common/Text";

export function WelcomePage(): ReactElement {
    return (
        <>
            <WorleyNoiseCanvas />
            <Container>
                <Glass>
                    <div>
                        <Txt
                            level={3}
                            emphasis
                            style={{
                                opacity: 0.8,
                            }}>
                            Welcome
                        </Txt>
                        <Txt
                            level={5}
                            style={{
                                opacity: 0.8,
                            }}>
                            The Mytos App is home to all your devices, data, and
                            protocols. <br />
                            <br />
                            {"We're excited to have you here!"}
                        </Txt>
                    </div>
                    <Button linkTo="/" style={{}}>
                        {"Let's go"}
                    </Button>
                </Glass>
            </Container>
        </>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Glass = styled.div`
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);

    // border with light to dark gradient
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 10px;

    // dropshadow
    box-shadow: 0 0 10px rgba(20, 20, 20, 0.1);

    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px;
    max-width: 440px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        margin: 20px;
        padding: 20px;
    }
`;

function WorleyNoiseCanvas(): ReactElement {
    const canvasRef = useRef(null);
    const height = useWindowHeight();
    const width = useWindowWidth();

    useEffect(() => {
        const canvas = canvasRef.current as unknown as HTMLCanvasElement;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

        const width = canvas.width;
        const height = canvas.height;

        const points: { x: number; y: number }[] = [];
        const generatePoints = (numPoints: number) => {
            for (let i = 0; i < numPoints; i++) {
                points.push({
                    x: Math.random() * width,
                    y: Math.random() * height,
                });
            }
        };

        const totalPixels = width * height;
        const nCells = Math.ceil(totalPixels / 20_000);
        generatePoints(nCells); // Adjust the number of points for variation

        const generateWorleyNoise = (x: number, y: number) => {
            let minDistance = Number.MAX_VALUE;
            let secondMinDistance = Number.MAX_VALUE;

            for (let i = 0; i < points.length; i++) {
                const point = points[i];
                const distance = Math.sqrt(
                    (x - point.x) ** 2 + (y - point.y) ** 2,
                );
                if (distance < minDistance) {
                    secondMinDistance = minDistance;
                    minDistance = distance;
                } else if (distance < secondMinDistance) {
                    secondMinDistance = distance;
                }
            }

            return 1 - Math.min(1, minDistance / secondMinDistance);
        };

        const drawWorleyNoise = () => {
            const imageData = ctx.createImageData(width, height);
            const data = imageData.data;

            for (let y = 0; y < height; y++) {
                for (let x = 0; x < width; x++) {
                    // const index = x + y * width;
                    const noiseValue = generateWorleyNoise(x, y); // Adjust the number of points for variation
                    const index = (x + y * width) * 4;
                    const channelValue = Math.floor(noiseValue * 255);

                    // Mytos Primary Green: 12, 223, 188
                    data[index] = 12; // Red channel
                    data[index + 1] = 223; // Green channel
                    data[index + 2] = 188; // Blue channel
                    data[index + 3] = channelValue; // Alpha channel
                }
            }

            ctx.putImageData(imageData, 0, 0);
        };

        let animationFrame: number;
        let timeout: NodeJS.Timeout;
        const animate = () => {
            timeout = setTimeout(() => {
                points.forEach(point => {
                    point.x += Math.random() * 2 - 1; // Move points randomly
                    point.y += Math.random() * 2 - 1; // Move points randomly

                    point.x = clamp(point.x, 0, width);
                    point.y = clamp(point.y, 0, height);
                });

                drawWorleyNoise();
                animationFrame = requestAnimationFrame(animate);
            }, 100);
        };

        drawWorleyNoise();
        animate();

        return () => {
            cancelAnimationFrame(animationFrame);
            clearTimeout(timeout);
        };
    }, [width, height]);

    return (
        <canvas
            ref={canvasRef}
            width={width}
            height={height}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: "100vh",
                width: "100vw",
                opacity: 0.4,
                backgroundColor: "white",
            }}
        />
    );
}
