import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";

const DEVICE_NAME_QUERY = gql(`
    query DeviceName($deviceId: String!) {
        device(id: $deviceId) {
            id
            name
        }
    }
`);

/**
 * Retrieves the name of a given Device.
 *
 * @param deviceId - ID of Device to check
 */
export function useDeviceName(deviceId: string): {
    name: string | undefined;
    loading: boolean;
} {
    const { data, loading } = useQuery(DEVICE_NAME_QUERY, {
        variables: { deviceId },
        fetchPolicy: "cache-first",
    });
    const name = data?.device?.name ?? undefined;
    return { name, loading };
}
