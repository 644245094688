import React, { CSSProperties, ReactElement } from "react";

import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

import Button from "./Button";
import Icon from "./Icon";
import Txt from "./Text";

export interface CalloutCardProps {
    /**
     * Children nodes that get inserted into the body of the callout
     */
    children?: React.ReactNode;
    /**
     * Override style is applied to the outermost component (Mui `Paper`)
     */
    style?: CSSProperties;
}

export interface CalloutProps extends CalloutCardProps {
    /**
     * Primary longer-length message.
     */
    description?: string;
}

/**
 * Similar to toasts, but sit within the page’s content, Callouts are designed
 * to highlight a particularly important piece of information. These can be all
 * kinds of sizes/lengths and have plenty of variations.
 *
 * The default variant is `info`. However you can specify this to use different
 * themes that match the type of information being provided (e.g. `warn` or `error`).
 *
 * There are three ways to provide content:
 * - `title` prop
 * - `message` prop
 * - `children` React nodes
 *
 * Note that if both `title` and `message` are present, then `children` will not
 * be rendered.
 */
export default function BetaCallout(props: CalloutProps): ReactElement {
    const { description: message, style } = props;
    const theme = useTheme();

    const topRow = "Beta Feature";
    const bottomRow = message;

    return (
        <CalloutCard style={style}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}>
                <Icon
                    name="construction-sign"
                    // size="md"
                    level={9}
                    sx={{
                        marginRight: theme => theme.spacing(3),
                        color: theme.colours.neutral[500],
                    }}
                />
                <Txt
                    font="secondary"
                    level={9}
                    emphasis
                    style={{
                        flexGrow: 1,
                        fontFamily: "monospace",
                        lineHeight: "1.9rem",
                    }}>
                    {topRow}
                </Txt>
                <Button
                    variant="tertiary"
                    size="s"
                    iconLeft="email"
                    linkTo="mailto:support@mytos.bio"
                    style={{ fontFamily: "monospace", opacity: 0.5 }}>
                    Send feedback
                </Button>
            </div>
            <Txt
                font="secondary"
                level={9}
                style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    fontFamily: "monospace",
                }}>
                {bottomRow}
            </Txt>
        </CalloutCard>
    );
}

/**
 * Base component for the CalloutCard.
 * This component is used by the `Callout` component to provide a styled paper background.
 * The `Callout` component builds on top of this base component, providing consistent icons, titles, and message usage.
 */
export function CalloutCard(props: CalloutProps): ReactElement {
    const { children, style } = props;
    const theme = useTheme();

    const { borderColor, background, color } = {
        borderColor: theme.colours.neutral[300],
        background: theme.colours.neutral[100],
        color: theme.colours.neutral[700],
    };

    return (
        <BaseCalloutCard
            style={{
                borderColor,
                background,
                color,
                ...style,
            }}>
            {children}
        </BaseCalloutCard>
    );
}

const BaseCalloutCard = styled(Paper)`
    border: 1px solid;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: stretch;
    box-shadow: none;
`;
