import { Dayjs } from "dayjs";

import {
    CreateAndSyncCultureInput,
    CreateAndSyncCultureMutation,
} from "__generated__/apollo/graphql";
import { useToasts } from "components/common/toasts/useToasts";

import { log as parentLog } from "../../log";
import { useCreateAndSyncCulture } from "../protocol-upload-hooks";

export const log = parentLog.extend("useCreateWetTestCulture");

export function useCreateWetTestCulture({ deviceId }: { deviceId: string }) {
    const { toast } = useToasts();
    const { createAndSyncCulture } = useCreateAndSyncCulture(deviceId);

    return async function createWetTestCulture({
        protocolId,
        startDate,
        timezone,
        cultureMetadata,
        skipValidation,
    }: {
        protocolId: string;
        timezone: string;
        startDate: Dayjs;
        cultureMetadata: CreateAndSyncCultureInput["cultureMetadata"];
        skipValidation: boolean;
    }): Promise<
        CreateAndSyncCultureMutation["createAndSyncCulture"]["culture"] | Error
    > {
        const toastId = toast.loading("Creating culture...");
        log.debug("Attempting to create culture");

        const { ok, message, culture } = await createAndSyncCulture({
            protocolId,
            timezone,
            startDate: startDate.format("YYYY-MM-DD"),
            cultureMetadata,
            skipValidation,
        });

        if (ok) {
            log.debug({ ok, message }, "culture creation successful");
            toast.update(toastId, {
                render: "Culture created",
                type: "success",
                isLoading: false,
            });

            return culture;
        }

        log.error({ ok, message }, "culture creation failed");
        toast.update(toastId, {
            render: `Failed to create culture`,
            type: "error",
            isLoading: false,
        });

        return new Error(message);
    };
}
