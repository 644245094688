import { ReactElement, useCallback } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useMeasure } from "react-use";

import { log as parentLog } from "components";
import Txt from "components/common/Text";
import { useToasts } from "components/common/toasts/useToasts";

export const log = parentLog.extend("Dropzone");

export type FileInfo = {
    /** Name of file */
    filename: string;
    /** File size in bytes */
    size: number;
    /** Timestamp for when file was last modified */
    lastModified: string;
};

export type FileData = { content: string } & FileInfo;

export type FileImportHandler = (props: FileData) => void;

interface DropzoneProps {
    onFileImport?: FileImportHandler;
    renderElementInZone?: (openFilesystem: () => void) => ReactElement;
}

export function Dropzone(props: DropzoneProps): ReactElement {
    const { onFileImport } = props;
    const { toast } = useToasts();
    const theme = useTheme();
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();

    const onDropCallback: DropzoneOptions["onDrop"] = acceptedFiles => {
        acceptedFiles.forEach(file => {
            log.debug("accepted file", file);
            const reader = new FileReader();

            reader.onabort = () => {
                log.debug("file reading was aborted");
                toast.info("File import was aborted.");
            };
            reader.onerror = () => {
                log.debug("file reading has failed");
                toast.error("Unable to read file.");
            };
            reader.onload = () => {
                // we type cast since we used the readAsText method
                const fileString = reader.result as string;
                log.debug("JSON string", fileString);
                // toast.success("File import complete", { autoClose: 1_000 });
                onFileImport?.({
                    content: fileString,
                    filename: file.name,
                    size: file.size,
                    lastModified: new Date(file.lastModified).toISOString(),
                });
            };
            reader.readAsText(file);
        });
    };

    const onDrop = useCallback(onDropCallback, [toast, onFileImport]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        isDragAccept,
        open,
    } = useDropzone({
        onDrop,
        maxFiles: 1,
        multiple: false,
        accept: {
            "application/json": [".json"],
        },
        // Disable click and keydown behavior since we have dedicated button
        noClick: true,
        noKeyboard: true,
    });

    const borderColor = isDragReject
        ? theme.colours.accent.alertOrange
        : isDragAccept
          ? theme.colours.brand.greenDark
          : isDragActive
            ? theme.colours.brand.blue
            : undefined;

    return (
        <div
            id="dragzone-container"
            ref={ref}
            style={{ width: "100%", height: "100%" }}>
            <div
                id="dragzone"
                {...getRootProps({})}
                css={css({
                    color: theme.colours.neutral[600], // text colour
                    borderColor,
                    height: "100%",
                })}>
                <input {...getInputProps()} />
                <DragzoneOverlay
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    width={width}
                    height={height}
                />
                {props.renderElementInZone?.(open)}
            </div>
        </div>
    );
}

function DragzoneOverlay(props: {
    isDragActive: boolean;
    isDragReject: boolean;
    width: number;
    height: number;
}): ReactElement {
    const { isDragActive, isDragReject, width, height } = props;
    const theme = useTheme();

    const color = isDragReject
        ? theme.colours.accent.alertOrange
        : theme.colours.brand.greenDark;
    return (
        <div
            style={{
                pointerEvents: "none",
                opacity: isDragActive ? 1 : 0,
                transition: "opacity .1s",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
                backdropFilter: "blur(4px)",
                // backgroundColor: "red",
                color,
                borderColor: color,
                borderWidth: 2,
                borderRadius: 4,
                borderStyle: "dashed",
                // top: "-2px",
                // left: "-2px",
                transform: "translateX(-2px)",
                width: width + 4,
                height: height,
            }}>
            <Txt level={6} align="center" emphasis>
                {isDragReject
                    ? "Sorry, that look like an invalid file type!"
                    : "Looks good - drop your file!"}
            </Txt>
        </div>
    );
}
