import { WatchQueryFetchPolicy, useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";

export const CULTURE_OVERVIEW_SIMPLE_QUERY = gql(`
    query CultureOverviewSimple($cultureId: String!) {
        culture(id: $cultureId) {
            id
            state
            isActive
            mintedByServer
            device {
                id
                isOnline
            }
        }
    }
`);

/**
 * Query the culture overview to find the state, active status, and minted status.
 * @param cultureId - The ID of the culture to query.
 * @param fetchPolicy - The fetch policy to use for the query.
 * @returns The result of the query.
 */
export const useCultureOverviewSimple = ({
    cultureId,
    fetchPolicy = "cache-first",
}: {
    cultureId: string;
    fetchPolicy?: WatchQueryFetchPolicy | undefined;
}) => {
    return useQuery(CULTURE_OVERVIEW_SIMPLE_QUERY, {
        variables: { cultureId },
        fetchPolicy,
    });
};
