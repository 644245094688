import { Fragment, ReactElement } from "react";

import { useQuery } from "@apollo/client";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Route, Navigate, Routes, useParams } from "react-router-dom";

import { gql } from "__generated__/apollo";
import Callout from "components/common/Callout";
import { PageHeaderContainer, TabData } from "components/common/PageHeader";
import { config } from "services/config";
import { getCultureName } from "services/culture-utils";
import { useFeature } from "services/feature-flags";
import { useDeviceRole } from "services/hooks/useDeviceRole";

import { CultureSchedule as CultureScheduleV2 } from "../CultureSchedule/CultureSchedule";
import { CultureTab } from "../Device/Culture/DeviceCulture";
import { DeviceImages } from "../Device/Images/DeviceImages";
import DeviceResults from "../Device/Results/DeviceResults";
import { CultureSchedule } from "../Device/Schedule/CultureSchedule";
import {
    useCulturePollInterval,
    useScheduleIsViewOnly,
} from "../Device/Schedule/schedule-data";

import CultureHeaderContent from "./CultureHeaderContent";
import { log } from "./log";

const CULTURE_PAGE_QUERY = gql(`
    query CulturePage($cultureId: String!) {
        culture(id: $cultureId) {
            id
            name
            state
            isActive
            isWetTestCulture
            mintedByServer
            errorMessage
            device {
                id
            }
            schedule {
                isWaitingOnConfirmation
                nextStep {
                    id
                }
                nextProcedure {
                    id
                }
            }
        }
    }
`);

export function CulturePage(): ReactElement {
    const { cultureId } = useParams();

    const resultsEnabled = useFeature("app_results_feature").enabled;
    const scheduleV2Enabled =
        useFeature("app_schedule_v2").enabled || config.isLocal;

    const culturePollIntervalMs = useCulturePollInterval({
        cultureId: cultureId ?? "",
    });

    const { loading, error, data } = useQuery(CULTURE_PAGE_QUERY, {
        variables: { cultureId: cultureId ?? "" },
        pollInterval: culturePollIntervalMs,
        skip: !cultureId,
    });

    const isActive = data?.culture?.isActive ?? false;
    const canOperate = useDeviceRole(data?.culture?.device?.id ?? "");
    const viewOnlyState = !(isActive && canOperate);
    useScheduleIsViewOnly(viewOnlyState);

    if (!cultureId) {
        return (
            <div style={{ margin: 40 }}>
                <Callout
                    variant="error"
                    message={`There was an issue obtaining cultureId from URL`}
                />
            </div>
        );
    } else if (error) {
        log.error(error);
        return (
            <div style={{ margin: 40 }}>
                <Callout
                    variant="error"
                    message={`There was a problem with the Culture Page. Mytos Support has been informed.`}
                />
            </div>
        );
    } else if (data) {
        log.debug("data is", data);
        if (!data.culture) {
            return (
                <Container
                    maxWidth="md"
                    style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                    <Callout
                        variant="error"
                        message="This Culture either does not exist, or you do not have permission to view it."
                    />
                </Container>
            );
        }
    } else if (loading) {
        log.debug("Loading the data for Culture page");
    } else {
        // no data
        log.error("Culture page could not render anything");
        return (
            <Callout
                variant="error"
                message="There was a problem with the Culture Page. Mytos Support has been informed."
            />
        );
    }

    const tabs: TabData[] = [
        {
            name: "Overview",
            path: "overview",
            content: <CultureTab cultureId={cultureId} />,
        },
    ];

    const isDeviceMinted = data?.culture?.mintedByServer === false;

    // By default we now show only the v2 schedule UI, however if a culture is
    // minted by the device we still show the old schedule UI to allow users to
    // perform things like rescheduling of steps - which is not supported in the
    // new UI (because it doesn't make sense there)
    if (isDeviceMinted) {
        if (scheduleV2Enabled) {
            tabs.push({
                name: "Schedule",
                path: "schedule-beta",
                content: <CultureScheduleV2 cultureId={cultureId} />,
                earlyAccess: "beta",
            });
        }
        tabs.push({
            name: "Schedule",
            path: "schedule",
            content: <CultureSchedule cultureId={cultureId} />,
        });
    } else {
        tabs.push({
            name: "Schedule",
            path: "schedule",
            content: <CultureScheduleV2 cultureId={cultureId} />,
        });
    }

    tabs.push({
        name: "Images",
        path: "images",
        content: <DeviceImages cultureId={cultureId} />,
    });
    if (resultsEnabled) {
        tabs.push({
            name: "Results",
            path: "results",
            content: <DeviceResults cultureId={cultureId} />,
        });
    }

    const cultureName = getCultureName(data?.culture) ?? "Unknown Culture";

    return (
        <Fragment>
            <Helmet>
                <title>
                    {config.envIdentifier}
                    {cultureName} Culture | Mytos
                </title>
            </Helmet>
            <Routes>
                <Route
                    path={`:tabPath`}
                    element={
                        <PageHeaderContainer paramKey="tabPath" tabs={tabs}>
                            <CultureHeaderContent
                                cultureId={cultureId}
                                deviceId={data?.culture?.device?.id}
                                cultureName={cultureName}
                                loading={loading}
                                waitingForConfirmation={
                                    data?.culture?.schedule
                                        ?.isWaitingOnConfirmation
                                }
                                cultureState={
                                    data?.culture?.isWetTestCulture
                                        ? undefined
                                        : data?.culture?.state
                                }
                                wetTestCultureState={
                                    data?.culture?.isWetTestCulture
                                        ? data?.culture?.state
                                        : undefined
                                }
                                cultureHasProcedureToRun={Boolean(
                                    data?.culture?.schedule?.nextProcedure,
                                )}
                                cultureErrorMessage={
                                    data?.culture?.errorMessage
                                }
                            />
                        </PageHeaderContainer>
                    }
                />
                <Route
                    path=""
                    element={<Navigate replace to={tabs[0].path} />}
                />
            </Routes>
        </Fragment>
    );
}
