import React, { Fragment, ReactElement, useRef, useState } from "react";

import { useMutation } from "@apollo/client";
import { Divider, Grid } from "@mui/material";

import { gql } from "__generated__/apollo";
import { EditInvocationParametersMutationVariables } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Modal from "components/common/Modal";
import Txt from "components/common/Text";
import { useToasts } from "components/common/toasts/useToasts";
import { Invocation } from "services/hooks/useCultureStepInvocations";
import {
    DeviceOperation,
    DeviceOperationParameter,
    useDeviceOperationsQuery,
} from "services/hooks/useDeviceOperations";
import { useMediaQuery } from "services/hooks/window";

import { log as parentLog } from "../log";
import { ParameterInput, DisplayTitle } from "../ParameterInput";

export const log = parentLog.extend("EditInvocationsModal");

export type EditInvocationModalProps = {
    deviceId: string;
    invocation: Invocation;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type OperationParametersState = Record<string, Record<string, unknown>>;
type SetOperationParametersState = React.Dispatch<
    React.SetStateAction<OperationParametersState>
>;

export function EditInvocationModal(
    props: EditInvocationModalProps,
): ReactElement {
    const { deviceId, invocation, modalOpen, setModalOpen } = props;
    const { id: invocationId } = invocation;
    const { toast } = useToasts();
    const [newParameters, setNewParameters] =
        useState<OperationParametersState>({});
    const [editInvocationParametersMutation] = useMutation(
        EDIT_INVOCATION_PARAMETERS_MUTATION,
    );

    const onSubmit = async () => {
        if (invocation.operationId === null) {
            log.error(
                { deviceId, invocation },
                "Invocation has no operation ID",
            );
            toast.error(`Unable to make edit. The issue has been reported.`);
            return;
        }
        const variables: EditInvocationParametersMutationVariables = {
            input: {
                deviceId,
                parameters: newParameters[invocation.operationId],
                invocationId: invocationId,
            },
        };
        const toastId = toast.loading("Editing invocation");
        const res = await editInvocationParametersMutation({ variables });
        if (res?.data?.editInvocationParameters?.ok) {
            toast.update(toastId, {
                render: "Operation parameters successfully updated",
                type: "success",
                isLoading: false,
            });
        } else {
            toast.update(toastId, {
                render: `Failed to edit Operation: ${
                    res?.data?.editInvocationParameters?.message ??
                    "unknown error"
                }`,
                type: "error",
                isLoading: false,
            });
        }
    };

    return (
        <Modal
            title="Edit Operation"
            buttonLeft={{ label: "Back", onClick: close => close() }}
            buttonRight={{
                label: "Confirm",
                onClick: close => {
                    void onSubmit();
                    close();
                },
            }}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}>
            <Grid
                container
                spacing={8}
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    textAlign: "left",
                    overflow: "hidden",
                }}>
                <Grid
                    item
                    sx={{
                        height: "100%",
                        overflowY: "auto",
                    }}
                    xs={12}>
                    <DisplayTitle
                        name={invocation.operationId}
                        description={invocation.description}
                    />
                    <Divider variant={"fullWidth"} sx={{ margin: "20px 0" }} />
                    <NewParametersSection
                        deviceId={deviceId}
                        invocation={invocation}
                        operationParameters={newParameters}
                        setOperationParameters={setNewParameters}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}

function NewParametersSection(props: {
    deviceId: string;
    invocation: Invocation;
    operationParameters: OperationParametersState;
    setOperationParameters: SetOperationParametersState;
}): ReactElement {
    const {
        deviceId,
        invocation,
        operationParameters,
        setOperationParameters,
    } = props;
    const isMobile = useMediaQuery("(max-width:700px)");
    const operationsResponse = useDeviceOperationsQuery(deviceId);
    const firstRun = useRef(true);
    const operations = operationsResponse.data?.device?.operations;
    const operation = operations?.find(
        operation => operation.operationId === invocation.operationId,
    );

    const [editedOperation, setEditedOperation] = useState<
        DeviceOperation | undefined
    >(operation);

    if (
        !operation ||
        !operation.operationId ||
        !operation.parameters ||
        !editedOperation ||
        !editedOperation.parameters
    ) {
        log.error({ deviceId, invocation }, "Operation not found");
        return (
            <Callout variant="error">
                The Operation information could not be found
            </Callout>
        );
    }
    const { operationId } = operation;

    if (firstRun.current) {
        firstRun.current = false;
        /** populate the previous values */
        const editedOperationParameters = populatePreviousValues(
            operation.parameters,
            invocation.parameters,
        );
        /** operation with the parameter values populated from existing data */
        setEditedOperation({
            ...operation,
            parameters: editedOperationParameters,
        });
    }

    if (editedOperation.parameters.length < 1) {
        return <Callout>No parameters to change</Callout>;
    }

    return (
        <Fragment>
            <Txt font="secondary" level={6}>
                New Parameters
            </Txt>
            <Grid container spacing={1}>
                {editedOperation.parameters?.map(parameter => {
                    const { parameterId } = parameter;
                    return (
                        <Grid
                            item
                            key={`${operationId}-${parameterId}-input`}
                            xs={isMobile ? 12 : 4}>
                            <ParameterInput
                                key={`${operationId}-${parameterId}-input`}
                                isMobile={isMobile}
                                setOperationParameters={setOperationParameters}
                                operationParameters={operationParameters}
                                operationId={operationId}
                                displayPreviousValue={true}
                                {...parameter}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Fragment>
    );
}

/**
 * Add previousValue field to the operation parameters array
 *
 * @param operationParameters - The parameters of the operation
 * @param invocationParams - The parameters populated from the invocation
 * @returns - operation parameters with previousValue field
 */
function populatePreviousValues(
    operationParameters: DeviceOperationParameter[],
    invocationParams: Record<string, unknown>,
): (DeviceOperationParameter & {
    previousValue?: unknown;
})[] {
    return operationParameters.map(parameter => {
        const { parameterId } = parameter;
        if (parameterId === null) {
            log.error({ parameter }, "Parameter has no ID");
            return parameter;
        } else {
            if (parameterId in invocationParams) {
                return {
                    ...parameter,
                    previousValue: invocationParams[parameterId],
                };
            } else {
                return parameter;
            }
        }
    });
}

export const EDIT_INVOCATION_PARAMETERS_MUTATION = gql(`
    mutation EditInvocationParameters($input: EditInvocationParametersInput!) {
        editInvocationParameters(input: $input) {
            ok
            message
            invocation {
                id
                state
                description
                parameters
            }
        }
    }
`);
