import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import {
    DeviceUserRole,
    UseDeviceRoleQueryVariables,
} from "__generated__/apollo/graphql";
import { useUser } from "services/hooks/useUser";
import { removeNullables } from "services/utils";

export const USE_DEVICE_ROLE_QUERY = gql(`
    query UseDeviceRole($deviceId: String!) {
        device(id: $deviceId) {
            id
            users {
                edges {
                    id
                    role
                    user {
                        id
                    }
                }
            }
        }
    }
`);

export type UseDeviceRole = {
    /**
     * The granted role the User has for the given Device. May be undefined if
     * query has not yet returned.
     */
    role: DeviceUserRole | undefined;
    /**
     * Boolean determining if User is admin of Device. Will return false if
     * unknown (such as when waiting for initial query response).
     */
    isAdmin: boolean;
    /**
     * Boolean determining if the User has sufficient permissions to operate the
     * Device. For example, this may be if the User is either an `ADMIN` or
     * `OPERATOR`.
     *
     * May still return false if no value has yet been returned by the server.
     */
    canOperate: boolean;
    /**
     * Boolean determining if the User has sufficient permissions for viewing
     * Device data.
     */
    canView: boolean;
};

const GLOBAL_FIRST_MOUNT_DEVICE_IDS: string[] = [];

/**
 * Checks the role of the current User in relation to a specific Device.
 *
 * I.e. if the current user has access to a particular Device, when the ID of
 * the Device is passed, the function will return "ADMIN"
 *
 * @param deviceId ID of Device to check
 */
export function useDeviceRole(deviceId: string | null): UseDeviceRole {
    const { user } = useUser({ fetchPolicy: "cache-first" });
    const { data, refetch } = useQuery(USE_DEVICE_ROLE_QUERY, {
        variables: { deviceId } as UseDeviceRoleQueryVariables,
        skip: !deviceId, // skip query if no deviceId, as its a required variable
        fetchPolicy: "cache-and-network", // on page refresh, or remount, we do network request
        nextFetchPolicy: "cache-first", // re-renders do not cause excessive network requests
    });
    if (!deviceId) {
        return {
            role: undefined,
            isAdmin: false,
            canOperate: false,
            canView: false,
        };
    }
    if (GLOBAL_FIRST_MOUNT_DEVICE_IDS.includes(deviceId)) {
        /**
         * This global first mount ensures that if the page is reloaded, the
         * first time this hook gets used (for a given device ID) then a refetch
         * will be forced, ensuring that we don't use stale data following page
         * refreshes.
         */
        void refetch();
        GLOBAL_FIRST_MOUNT_DEVICE_IDS.push(deviceId);
    }
    const rawEdges = data?.device?.users?.edges;
    if (rawEdges) {
        const userEdges = removeNullables(rawEdges);
        const usersWithAccess = userEdges.reduce<{
            [k: string]: DeviceUserRole;
        }>((dict, edge) => {
            dict[edge.user.id] = edge.role;
            return dict;
        }, {});
        const currentUserId = user?.id;
        if (currentUserId && usersWithAccess) {
            const userRole = usersWithAccess[currentUserId];
            if (userRole) {
                return {
                    role: userRole,
                    isAdmin: userRole === DeviceUserRole.Admin,
                    canOperate: userRole !== DeviceUserRole.Viewer,
                    canView: true,
                };
            }
        }
    }
    return {
        role: undefined,
        isAdmin: false,
        canOperate: false,
        canView: false,
    };
}
