import MuiCheckbox, {
    CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

interface CheckboxProps extends MuiCheckboxProps {
    colourOverride?: string;
}

// TODO replace Icon used for Checkbox checked state
export default function Checkbox(props: CheckboxProps): JSX.Element {
    const { ...remainingProps } = props;
    return <StyledMuiCheckbox {...remainingProps} />;
}

const StyledMuiCheckbox = styled(MuiCheckbox)(({ theme }) => ({
    "&.MuiCheckbox-root": {
        "color": theme.colours.neutral[500],
        "&:hover": {
            color: theme.colours.brand.green,
        },
        "& svg": {
            height: 24,
            width: 24,
        },
    },
    "&.Mui-checked": {
        "& svg": {
            color: theme.colours.brand.green,
        },
    },
    "&.Mui-disabled": {
        "& svg": {
            color: theme.colours.neutral[300],
        },
    },
}));
