import { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { atom, useAtom } from "jotai";

import { gql } from "__generated__/apollo";
import {
    CulturePollingInternalQuery,
    CultureState,
} from "__generated__/apollo/graphql";

const scheduleViewOnlyAtom = atom(true);

/**
 * Hook to get the current view-only state of the schedule.
 *
 * !!WARNING!! Using this Jotai atom hook is discouraged and can cause bugs!!
 *
 * It assumes that somewhere up the component tree the atom is being updated.
 * This is not a good pattern to follow. Instead, consider loading the required
 * device and culture data in order to determine the view-only state.
 *
 * Remember that GraphQL queries are cached, so you can use the same query in
 * multiple places without worrying about performance.
 *
 * @returns The current view-only state.
 */
export function useScheduleIsViewOnly(viewOnlyState?: boolean): boolean {
    const [scheduleViewOnly, setScheduleViewOnly] =
        useAtom(scheduleViewOnlyAtom);

    // ? Note this is a temporary bodge until we've removed the
    // scheduleViewOnlyAtom entirely. Instead we should be working from
    // derived existing state, rather than creating a new state object.
    useEffect(() => {
        if (viewOnlyState !== undefined) {
            setScheduleViewOnly(viewOnlyState);
        }
    }, [viewOnlyState, setScheduleViewOnly]);

    return scheduleViewOnly;
}

const deviceCulturePollingQuery = gql(`
    query DeviceCulturePollingInternal($deviceId: String!) {
        device(id: $deviceId) {
            id
            culture {
                id
                isActive
                state
                errorMessage
                mintedByServer
            }
        }
    }
`);

const culturePollingQuery = gql(`
    query CulturePollingInternal($cultureId: String!) {
            culture(id: $cultureId) {
                id
                isActive
                state
                errorMessage
                mintedByServer
            }
    }
`);

export const useCulturePollInterval = (
    props:
        | {
              deviceId: string;
          }
        | { cultureId: string },
) => {
    const isDevice = "deviceId" in props;

    const { data: cultureData } = useQuery(culturePollingQuery, {
        variables: { cultureId: isDevice ? "" : props.cultureId },
        fetchPolicy: "cache-only",
        skip: isDevice,
    });

    const { data: deviceData } = useQuery(deviceCulturePollingQuery, {
        variables: { deviceId: isDevice ? props.deviceId : "" },
        fetchPolicy: "cache-first",
        skip: !isDevice,
    });

    return getPollIntervalForCulture(
        deviceData?.device?.culture ?? cultureData?.culture,
    );
};

function getPollIntervalForCulture(
    culture?: CulturePollingInternalQuery["culture"] | null,
): number | undefined {
    if (!culture) {
        return undefined;
    }

    const { isActive, errorMessage, state } = culture;

    if (
        state &&
        !errorMessage && // if we've had a culture level error we don't need to poll aggressively
        [
            CultureState.Loading,
            CultureState.Pausing,
            CultureState.Updating,
        ].includes(state)
    ) {
        return 1_000;
    }

    return isActive ? 15_000 : undefined;
}
