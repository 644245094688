import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "services/apollo/client";
import { useFetchViaServerProxyDevice } from "services/requests";

export type UseDeviceControl = {
    loading: boolean;
    execute: () => Promise<Response>;
};

/**
 * A lower-level hook to provide an easy method of sending mutation requests to
 * the Device endpoints.
 *
 * @param deviceId - ID of the Device to control
 * @param path - Path of the endpoint to send request to
 * @param queryKey - Optional QueryKey that will be used to refetch queries on
 * completion of the request
 * @param method - Optional HTTP method used. Defaults to GET.
 * @returns Hook object to be destructured
 */
export function useDeviceControl(
    deviceId: string,
    path: string,
    queryKey?: QueryKey,
    method: "GET" | "POST" = "GET",
): UseDeviceControl {
    const queryClient = useQueryClient();
    const proxyFetch = useFetchViaServerProxyDevice();
    const fetcher = () =>
        proxyFetch({
            deviceId,
            path,
            method,
        });

    const mutation = useMutation({
        mutationFn: fetcher,
        onSettled: () => {
            void client.refetchQueries({
                include: "active",
            });
            if (queryKey) {
                void queryClient.invalidateQueries(
                    { queryKey },
                    {
                        cancelRefetch: true,
                    },
                );
            }
        },
    });

    return {
        loading: mutation.isPending,
        execute: () => mutation.mutateAsync(),
    };
}
