import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import FlaskT175 from "icons/flask/flask-t175.svg?react";

export interface FlaskProps {
    /**
     * Volume capacity of bottle in millimetres squared (mm^2)
     */
    growthAreaMm2?: FlaskGrowthAreaMm2;
    /**
     * Whether to display liquid in the flask
     */
    displayLiquid: boolean;
}

/**
 * Bottle capacities that can be rendered
 */
export const GROWTH_AREAS_MM2 = [175] as const;

export type FlaskGrowthAreaMm2 = (typeof GROWTH_AREAS_MM2)[number];

/**
 * Available flask growth areas, mapped to the corresponding SVG element as a
 * React component.
 *
 * We could consider having these types driven by the server API.
 */
const flaskResources = {
    175: { component: FlaskT175 },
} satisfies {
    [key in FlaskGrowthAreaMm2]: { component: React.FunctionComponent };
};

/**
 * Library of available liquid styles
 */
const fillStyles = {
    filled: { fill: "#F4A2A2", stroke: "#F06565" },
    empty: { fill: "#bed3d9", stroke: "#628189" },
} satisfies {
    [k: string]: { fill: string; stroke: string };
};

/**
 * A dynamic graphic component to render the visualisation of a Mytos consumable
 * flask.
 */
export function Flask({
    displayLiquid,
    growthAreaMm2 = 175,
}: FlaskProps): ReactElement {
    const flask = flaskResources[growthAreaMm2];
    const FlaskComponent = flask.component;
    const fillStyle = displayLiquid ? "filled" : "empty";
    return (
        <FlaskComponent
            css={css`
                // since we have a viewport defined in the source files, we can
                // set width, and height to dynamically scale the svg container
                width: 100%;
                height: 100%;
                // sensible upper limit
                max-width: 250px;
                max-height: 250px;

                #hole {
                    fill: ${fillStyles[fillStyle].fill};
                    stroke: ${fillStyles[fillStyle].stroke};
                }
            `}
        />
    );
}
