import { ReactElement } from "react";

import { ProcedureState } from "__generated__/apollo/graphql";
import { Step } from "services/hooks/useCultureProcedureSteps";

import ConfirmableStep from "./ConfirmableStep";
import { StepCard } from "./StepCard";

export interface StepProps {
    step: Step;
    deviceId: string;
    cultureId: string;
    procedureState?: ProcedureState | null;
}

export function StepItem({
    step,
    deviceId,
    cultureId,
    procedureState,
    isNextStep,
}: StepProps & { isNextStep: boolean }): ReactElement {
    const confirmationRequired = step.confirmationRequired;

    if (confirmationRequired) {
        return (
            <ConfirmableStep
                step={step}
                deviceId={deviceId}
                isNextStep={isNextStep}>
                <StepCard
                    step={step}
                    deviceId={deviceId}
                    cultureId={cultureId}
                    procedureState={procedureState}
                />
            </ConfirmableStep>
        );
    }

    return (
        <StepCard
            step={step}
            deviceId={deviceId}
            cultureId={cultureId}
            procedureState={procedureState}
        />
    );
}
