import { useState, Fragment, ReactElement } from "react";

import { useTheme } from "@mui/material";

import Button from "components/common/Button";
import ConfirmationDialog from "components/common/ConfirmationDialog";

import { log as parentLog } from "./log";

export const log = parentLog.extend("ConfirmStepDialog");

interface ConfirmStepDialogProps {
    confirmStep: () => Promise<void>;
    disabled: boolean;
    confirmationWaiting: boolean | null;
    stepName: string | null;
}

export default function ConfirmStepDialog(
    props: ConfirmStepDialogProps,
): ReactElement {
    const { disabled: confirmDisabled, confirmStep } = props;
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    const requiresDialog = props.confirmationWaiting ? false : true;
    const onButtonClick = requiresDialog ? openDialog : confirmStep;

    return (
        <Fragment>
            <Button
                disabled={confirmDisabled}
                onClick={onButtonClick}
                variant="tertiary"
                size="s"
                iconRight="check"
                colour={theme.colours.accent.alertOrange}
                hoverColour={theme.colours.neutral[700]}>
                Confirm to continue
            </Button>
            <ConfirmationDialog
                variant="warning"
                open={open}
                primaryButtonDisabled={confirmDisabled}
                onSecondaryAction={closeDialog}
                onDismiss={closeDialog}
                onPrimaryAction={confirmStep}
                title={"Confirm the Step in advance"}
                body={
                    <>
                        <strong>
                            <em>{props.stepName}</em>
                        </strong>{" "}
                        is not yet waiting for confirmation. Once confirmed, the
                        Device will automatically run this step.
                    </>
                }
                primaryButtonText="Confirm"
                secondaryButtonText="Cancel"></ConfirmationDialog>
        </Fragment>
    );
}
