import { useState } from "react";

import Txt from "components/common/Text";

import { useProtocolState } from "../protocol-upload-hooks";
import { useCreateProtocol } from "../useCreateProtocol";

import { ImportJson, parseProtocolString } from "./ImportJson";
import { RecentFiles } from "./RecentFiles";
import { StoredFileData } from "./useRecentFiles";

// We're in the process of migrating away from the `useProtocolState` hook. Once
// we've deleted the culture upload flow that depends on that hook we can remove
// this component.
export function LegacySelectProtocolView() {
    const [selectedFile, setSelectedFile] = useState<null | StoredFileData>(
        null,
    );

    const { setProtocolJson, clearProtocol: clearProtocolJsonData } =
        useProtocolState();

    const { clearProtocol: clearGqlProtocolData } = useCreateProtocol();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                width: "100%",
            }}>
            <Txt font="primary" level={5} emphasis>
                Select protocol
            </Txt>
            <ImportJson
                onProtocolChange={protocol =>
                    setProtocolJson(protocol ?? undefined)
                }
                onClearProtocol={() => {
                    clearProtocolJsonData();
                    clearGqlProtocolData();
                }}
                selectedFile={selectedFile}
                onSelectFile={setSelectedFile}
            />
            <RecentFiles
                onSelectFile={file => {
                    setSelectedFile(file);
                    const protocol = parseProtocolString(file.filedata.content);
                    if (protocol instanceof Error) {
                        return;
                    }
                    setProtocolJson(protocol);
                }}
            />
        </div>
    );
}

interface Props {
    onSelectProtocol: (protocol: unknown | null) => void;
}

export function SelectProtocolView({ onSelectProtocol }: Props) {
    const [selectedFile, setSelectedFile] = useState<null | StoredFileData>(
        null,
    );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                width: "100%",
            }}>
            <Txt font="primary" level={5} emphasis>
                Select protocol
            </Txt>
            <ImportJson
                onProtocolChange={onSelectProtocol}
                selectedFile={selectedFile}
                onSelectFile={setSelectedFile}
            />
            <RecentFiles
                onSelectFile={file => {
                    setSelectedFile(file);
                    const protocol = parseProtocolString(file.filedata.content);
                    if (protocol instanceof Error) {
                        return;
                    }
                    onSelectProtocol(protocol);
                }}
            />
        </div>
    );
}
