import styled from "@emotion/styled";
import * as d3 from "d3";
import { eachDayOfInterval, endOfDay, isSameDay, max, min } from "date-fns";
import { motion } from "framer-motion";
import { useMeasure } from "react-use";

import { decimalToPercent } from "services/string-utils";

import Callout from "../Callout";
import { fontFamilyMap, textLevelFontSize, textLevelLineHeight } from "../Text";

export type DataPoint = {
    date: Date;
    /**
     * Value which the line should bisect
     */
    value: number;
    /**
     * Individual data points to plot
     */
    values?: number[];
};

export interface GraphProps {
    /**
     * Array of confluence entries
     */
    entries: DataPoint[];
    /**
     * ISO string of the date the x axis of the graph should begin at, defaults to earliest date within entries
     */
    startDate?: string;
    /**
     * Day offset shift (i.e., the culture may begin on day -3)
     */
    dayStartIndex?: number;
}

interface GraphSVGProps {
    /**
     * Array of confluence entries
     */
    entries: DataPoint[];
    /**
     * ISO string of the date the x axis of the graph should begin at, defaults to earliest date within entries
     */
    startDate?: string;
    /**
     * Width in pixels the graph should render to, important for internal svg size calculations
     */
    width: number;
    /**
     * Height in pixels the graph should render to, important for internal svg size calculations
     */
    height: number;
    /**
     * Day offset shift (i.e., the culture may begin on day -3)
     */
    dayStartIndex: number;
}
/**
 * Renders a graph based on confluence entries.
 * @param GraphProps
 */
export default function Graph({
    entries,
    startDate,
    dayStartIndex = 0,
}: GraphProps) {
    const [ref, bounds] = useMeasure<HTMLDivElement>();
    if (!entries.length) {
        return <Callout message="No confluence data available" />;
    }
    return (
        <ChartContainer ref={ref}>
            {bounds.width > 0 && (
                <ChartSvg
                    startDate={startDate}
                    entries={entries}
                    width={bounds.width}
                    height={bounds.height}
                    dayStartIndex={dayStartIndex}
                />
            )}
        </ChartContainer>
    );
}

/**
 * Determines whether to display an x tick based on the length and index.
 * @param length - The length of the array
 * @param index - The index of the element
 * @returns Boolean indicating whether to display the tick
 */
function showTick(length: number, index: number) {
    if (length === 1) {
        return true;
    }
    if (length === index + 1) {
        return false;
    }
    const interval = Math.ceil(length / 10);
    return index % interval === 0;
}

/**
 * Renders the inner chart component.
 */
function ChartSvg({
    startDate,
    entries,
    width,
    height,
    dayStartIndex = 0,
}: GraphSVGProps) {
    const padding = {
        top: 10,
        right: 10,
        bottom: 20,
        left: 40,
        graphAndAxis: 5,
        graphAndAxisTitle: 30,
    } as const;

    const allDates = entries.map(dataPoint => dataPoint.date);
    const startDay = startDate ? new Date(startDate) : min(allDates);
    const endDay = max(allDates);
    const days = eachDayOfInterval({ start: startDay, end: endDay });

    const xScale = d3
        .scaleTime()
        .domain([startDay, endDay])
        .range([padding.left, width - padding.right]);

    const yScale = d3
        .scaleLinear()
        .domain([0, 1])
        .range([height - padding.bottom, padding.top]);

    const line = d3
        .line<DataPoint>()
        .x(d => xScale(d.date))
        .y(d => yScale(d.value));
    const d = line(entries);

    /**
     * Calculates the width of a background box for a given date within the graph boundaries,
     * respecting the defined padding.
     * @param date - The date for which the background box width is calculated.
     * @returns The width of the background box respecting the graph boundaries and padding.
     */
    function getBackgroundBoxWidth(date: Date): number {
        return Math.min(
            xScale(endOfDay(date)) - xScale(date),
            width - padding.right - xScale(date),
        );
    }

    return (
        <svg className="" viewBox={`0 0 ${width} ${height}`}>
            {/* X axis */}
            {days.map((day, i, array) => (
                <g
                    key={day.toISOString()}
                    transform={`translate(${xScale(day)},0)`}>
                    {i % 2 === 1 && (
                        <BackgroundRect
                            width={getBackgroundBoxWidth(day)}
                            height={height - padding.bottom}
                        />
                    )}
                    {showTick(array.length, i) && (
                        <AxisText
                            x={(xScale(endOfDay(day)) - xScale(day)) / 2}
                            y={height - padding.graphAndAxis}
                            textAnchor="middle">
                            {`Day ${i + dayStartIndex}`}
                        </AxisText>
                    )}
                </g>
            ))}
            <XLine
                x1={padding.left}
                x2={width - padding.right}
                y1={height - padding.bottom}
                y2={height - padding.bottom}
            />

            {/* Y axis */}
            {yScale.ticks(10).map((max, i) => (
                <g transform={`translate(0,${yScale(max)})`} key={max}>
                    {i !== 0 && (
                        <YDashedLine
                            x1={padding.left}
                            x2={width - padding.right}
                        />
                    )}
                    <g
                        transform={`translate(${
                            padding.left - padding.graphAndAxis
                        },0)`}>
                        <YAxisText alignmentBaseline="middle">
                            {decimalToPercent(max, false)}
                        </YAxisText>
                    </g>
                </g>
            ))}
            <YLine
                x1={padding.left}
                x2={padding.left}
                y1={0}
                y2={height - padding.bottom}
            />

            {/* Y-axis title */}
            <AxisTitle
                transform={`translate(${
                    padding.left - padding.graphAndAxisTitle
                },${height / 2}) rotate(-90)`}
                textAnchor="middle"
                fontSize="small"
                fill="#333">
                Percent (%)
            </AxisTitle>

            {/* Line */}
            <Path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 1.5, type: "spring" }}
                d={d ?? undefined}
            />

            {/* ... (previous code remains unchanged) */}

            {/* Circles for each point in values */}
            {entries.map(dataPoint => (
                <g key={dataPoint.date.toISOString()}>
                    {/* Error bars code (from the previous example) */}

                    {/* Opaque circles for each point */}
                    {dataPoint.values?.map((value, index) => (
                        <CirclePoint
                            key={`${dataPoint.date.toISOString()}-circle-${index}`}
                            cx={xScale(dataPoint.date)}
                            cy={yScale(value)}
                            r={2} // Adjust the radius of the circle
                        />
                    ))}
                </g>
            ))}

            {/* Circles */}
            {entries.map(d => (
                <g key={d.date.toISOString()}>
                    {/* Opaque circles for each data point */}
                    {d.values?.map((value, index) => (
                        <CirclePoint
                            key={`${d.date.toISOString()}-data-point-${index}`}
                            cx={xScale(d.date)}
                            cy={yScale(value)}
                            r={3}
                        />
                    ))}
                    {/* Prominent circles for each point on line */}
                    <Circle
                        key={d.date.toISOString()}
                        r="4"
                        cx={xScale(d.date)}
                        cy={yScale(d.value)}
                        isGrey={
                            days.findIndex(m => isSameDay(m, d.date)) % 2 === 1
                        }
                    />
                </g>
            ))}
        </svg>
    );
}

const AxisText = styled.text`
    font-family: ${fontFamilyMap.primary};
    fill: ${({ theme }) => theme.colours.neutral[700]};
    font-size: ${textLevelFontSize[9]};
    line-height: ${textLevelLineHeight[9]};
`;

const YAxisText = styled(AxisText)`
    text-anchor: end;
`;

const AxisTitle = styled(AxisText)`
    fill: ${({ theme }) => theme.colours.neutral[800]};
    font-size: ${textLevelFontSize[8]};
    line-height: ${textLevelLineHeight[8]};
`;

const YDashedLine = styled.line`
    stroke: ${({ theme }) => theme.colours.neutral[600]};
    stroke-dasharray: 1, 3;
`;

const Path = styled(motion.path)`
    stroke: ${({ theme }) => theme.colours.blue[500]};
    stroke-width: 2;
    fill: none;
`;

const Circle = styled(motion.circle)<{ isGrey: boolean }>`
    fill: ${({ theme }) => theme.colours.blue[500]};
    stroke: ${({ theme, isGrey }) =>
        isGrey ? theme.colours.neutral[200] : theme.colours.neutral.white};
    stroke-width: 2;
`;

const CirclePoint = styled(motion.circle)`
    fill: ${({ theme }) => theme.colours.blue[300]};
    stroke-width: 2;
    opacity: 0.4;
`;

const BackgroundRect = styled.rect`
    fill: ${({ theme }) => theme.colours.neutral[200]};
`;

const ChartContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1000;
    max-height: 700;
`;

const XLine = styled.line`
    stroke: ${({ theme }) => theme.colours.neutral[600]};
    stroke-width: 1;
`;

const YLine = styled.line`
    stroke: ${({ theme }) => theme.colours.neutral[600]};
    stroke-width: 1;
`;
