import { memo, Fragment, ReactElement, useState } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import { groupBy } from "lodash";

import { StepState } from "__generated__/apollo/graphql";
import Button from "components/common/Button";
import Callout from "components/common/Callout";
import Skeleton from "components/common/Skeleton";
import { TabContent } from "components/common/TabContent";
import { client } from "services/apollo/client";
import { dayjs, isInPast } from "services/date";
import { useFeature } from "services/feature-flags";
import {
    CULTURE_SCHEDULE_LEGACY_QUERY,
    LegacySchedule,
    useCultureSchedule,
} from "services/hooks/useCultureScheduleLegacy";
import { useDeviceRole } from "services/hooks/useDeviceRole";
import { deepCompareProps } from "services/react-utils";

import { AddOperationModal } from "./AddOperationModal";
import { DayBlock } from "./DayBlock";
import { log as parentLog } from "./log";
import { useScheduleIsViewOnly } from "./schedule-data";

export const log = parentLog.extend("CultureSchedule");

export enum ScheduleEvents {
    Collapse = "culture-schedule-collapse-all",
    Expand = "culture-schedule-expand-all",
    ScrollToNext = "culture-schedule-scroll-to-next",
}

const MemoCultureSchedule = memo(CultureSchedule);
export { MemoCultureSchedule as CultureSchedule };

function CultureSchedule(
    props: { deviceId: string } | { cultureId: string },
): ReactElement {
    const { culture, error, loading, deviceId, deviceHasCulture } =
        useCultureSchedule(props);
    const scheduleViewOnly = useScheduleIsViewOnly();
    const schedule = culture?.schedule;
    log.debug("Culture Schedule data", schedule);

    if (error) {
        log.error("Error requesting Culture Schedule", { error, schedule });
    }

    const content = (content: ReactElement): ReactElement => (
        <TabContent>{content}</TabContent>
    );

    if (loading) {
        return content(<Skeleton height={100} />);
    }

    if (!deviceId) {
        return content(
            <Callout
                variant="error"
                message="Unable to determine the device ID"
            />,
        );
    }

    if (schedule) {
        return content(
            <MemoCultureScheduleContent
                schedule={schedule}
                deviceId={deviceId}
            />,
        );
    }

    if (deviceHasCulture && culture === null) {
        return content(
            <Callout
                variant="error"
                message="Unable to display the Culture Schedule"
            />,
        );
    }

    if (deviceHasCulture === false && !error) {
        return content(
            <Callout>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    `}>
                    <span>{"No culture is active on this device."}</span>
                    {scheduleViewOnly && (
                        <Button
                            variant="secondary"
                            size="s"
                            iconRight="arrow-right"
                            linkTo={`/devices/${deviceId}/culture`}>
                            Set up a culture
                        </Button>
                    )}
                </div>
            </Callout>,
        );
    }

    return content(
        <Callout
            variant="error"
            message="Unable to find the Culture Schedule"
        />,
    );
}

const MemoCultureScheduleContent = memo(
    CultureScheduleContent,
    deepCompareProps(log),
);

function CultureScheduleContent(props: {
    schedule: LegacySchedule;
    deviceId: string;
}): ReactElement {
    const { schedule } = props;
    const scheduleHasSteps = (schedule.steps?.totalCount ?? 0) > 0;

    if (!scheduleHasSteps) {
        return (
            <>
                <ScheduleToolbar deviceId={props.deviceId} />
                <div style={{ marginTop: 24 }}>
                    <Callout message="The schedule doesn't have any steps in it yet." />
                </div>
            </>
        );
    }

    const dayStepMap = groupBy(schedule.steps?.nodes ?? [], step => {
        const timePlannedRealistic = realisticTimePlanned(
            step.timePlanned,
            step.timeStarted,
            step.state,
        );
        return dayjs(timePlannedRealistic).format("YYYY-MM-DD");
    });
    const cultureFirstDay = Object.keys(dayStepMap)[0];

    return (
        <>
            <ScheduleToolbar deviceId={props.deviceId} />
            {Object.keys(dayStepMap).map(date => {
                const daysSinceFirst = dayjs(date).diff(
                    cultureFirstDay,
                    "days",
                );
                return (
                    <DayBlock
                        deviceId={props.deviceId}
                        key={date}
                        dayNum={daysSinceFirst}
                        date={date}
                        steps={dayStepMap[date]}
                        nextStepId={schedule.nextStep?.id}
                    />
                );
            })}
        </>
        // TODO below is an example design for an end-stop
        // <div style={{ position: "relative" }}>
        //     <div style={{ position: "relative", zIndex: 1 }}>
        //         {/* <StepConfirmation {...props} /> */}
        //     </div>
        //     <div
        //         css={css`
        //             /* margin: 4px 0; */
        //             display: flex;
        //             align-items: flex-end;
        //             gap: 18px;
        //             height: 12px;

        //             /** This is the visual timeline */
        //             &::before {
        //                 content: "";
        //                 position: absolute;
        //                 top: 0;
        //                 bottom: 0;
        //                 left: 9px;
        //                 background-color: ${theme.colours.neutral[300]};
        //                 width: 2px;
        //             }
        //         `}>
        //         <div
        //             style={{
        //                 zIndex: 1, // render above the timeline
        //                 height: 2,
        //                 display: "flex",
        //                 alignItems: "center",
        //                 backgroundColor: theme.colours.neutral[300],
        //                 width: 8,
        //                 marginLeft: 6,
        //             }}>
        //             {/* <StepStateIcon state={props.step.state} /> */}
        //         </div>
        //         {/* <StepItemCard {...props} /> */}
        //     </div>
        // </div>
    );
}

function ScheduleToolbar(props: { deviceId: string }): ReactElement {
    const { deviceId } = props;
    const theme = useTheme();
    const scheduleViewOnly = useScheduleIsViewOnly();
    const [addOperationModalOpen, setAddOperationModalOpen] = useState(false);
    const role = useDeviceRole(deviceId);
    const addOperationFeature = useFeature("add_action_from_schedule", {
        deviceId,
    });
    const addOperationEnabled = role.canOperate && addOperationFeature.enabled;
    return (
        <div
            css={css`
                position: sticky;
                top: 64px; // matches AppBar height
                z-index: 10;
                background: white;
                display: flex;
                align-items: center;
                justify-content: right;
                flex-wrap: wrap;
                padding-top: 12px;
                padding-bottom: 8px;
                gap: 8px;
                color: ${theme.colours.neutral[500]};
                border-bottom: 1px solid currentColor;
            `}>
            <Button
                variant="tertiary"
                size="s"
                iconRight="rotate-cw"
                colour="currentColor"
                onClick={() =>
                    client.refetchQueries({
                        include: [CULTURE_SCHEDULE_LEGACY_QUERY],
                    })
                }>
                Refresh
            </Button>
            <Button
                variant="tertiary"
                size="s"
                iconRight="arrow-into-circle"
                colour="currentColor"
                onClick={() =>
                    window.dispatchEvent(new Event(ScheduleEvents.ScrollToNext))
                }>
                Go to latest
            </Button>
            <Button
                variant="tertiary"
                size="s"
                iconRight="collapse"
                colour="currentColor"
                onClick={() =>
                    window.dispatchEvent(new Event(ScheduleEvents.Collapse))
                }>
                Collapse All
            </Button>
            <Button
                variant="tertiary"
                size="s"
                iconRight="expand"
                colour="currentColor"
                onClick={() =>
                    window.dispatchEvent(new Event(ScheduleEvents.Expand))
                }>
                Expand All
            </Button>
            {!scheduleViewOnly && (
                <Fragment>
                    <Button
                        variant="primary"
                        size="s"
                        iconRight="plus"
                        colour="currentColor"
                        disabled={!addOperationEnabled}
                        onClick={() => setAddOperationModalOpen(true)}>
                        Add Operation
                    </Button>
                    <AddOperationModal
                        modalOpen={addOperationModalOpen}
                        setModalOpen={setAddOperationModalOpen}
                        deviceId={props.deviceId}
                    />
                </Fragment>
            )}
        </div>
    );
}

/**
 * When the state of a Step is some kind 'not done yet' mode, and the planned
 * time is in the past, we will provide a more realistic start time. This more
 * realistic start time is a current timestamp (representing as soon as
 * possible).
 *
 * This makes Steps scheduled to run on previous days (but haven't yet)
 * accumulate on today as they can't possibly run in the past.
 *
 * Note that if the timeStarted is already provided, then we default to that as
 * it correctly overrides any planned start time.
 *
 * @param timePlanned - Planned start time of the Step
 * @param timeStarted - Actual start time of the Step
 * @param state - The state of the Step
 * @returns ISO timestamp (most realistic start time)
 */
export function realisticTimePlanned(
    timePlanned: string | null,
    timeStarted: string | null,
    state: StepState | null,
): string | null {
    // firstly, the most realistic is the actual start time
    if (timeStarted) return timeStarted;
    if (!(timePlanned && state)) {
        return timePlanned;
    }
    if (
        [StepState.Planned, StepState.Running, StepState.Removed].includes(
            state,
        ) &&
        isInPast(timePlanned)
    ) {
        return dayjs().toISOString();
    }
    return timePlanned;
}
