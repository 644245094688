import {
    CultureState,
    ProcedureState,
    StepState,
} from "__generated__/apollo/graphql";

export function getCultureName(
    culture:
        | {
              name: string | null;
          }
        | null
        | undefined,
) {
    if (culture?.name === "" || culture?.name === null) {
        return "Unnamed culture";
    } else {
        return culture?.name;
    }
}

const wetTestCultureStateStrings: Record<CultureState, string> = {
    [CultureState.Ready]: "Setup in progress",
    [CultureState.Loading]: "Loading",
    [CultureState.Updating]: "Updating",
    [CultureState.Running]: "Running Wet Tests",
    [CultureState.Pausing]: "Pausing",
    [CultureState.Paused]: "Paused Wet Tests",
    [CultureState.Complete]: "Wet Tests Complete",
};

const cultureStateStrings: Record<CultureState, string> = {
    [CultureState.Ready]: "Ready to start",
    [CultureState.Loading]: "Loading",
    [CultureState.Updating]: "Updating",
    [CultureState.Running]: "Running",
    [CultureState.Pausing]: "Pausing",
    [CultureState.Paused]: "Paused",
    [CultureState.Complete]: "Complete",
};

/**
 * Constructs a user-friendly phrase to mention just the current state of a
 * potential Culture. It does not mention the name in the string, so should be
 * used in scenarios where context of the Culture name is already known.
 *
 * Refer to {@link getCultureStateSentence} for a friendly string that includes
 * the Culture name.
 *
 * This function also handles `null` and `undefined` cultures appropriately.
 *
 * @param culture - Culture data object
 * @returns User-friendly string
 */
export function getCultureStateString({
    cultureState,
    wetTestCultureState,
}: {
    cultureState: CultureState | null | undefined;
    wetTestCultureState?: CultureState | null;
}): string {
    if (wetTestCultureState) {
        return wetTestCultureStateStrings[wetTestCultureState];
    }

    if (cultureState) {
        return cultureStateStrings[cultureState];
    }

    return "No culture";
}

/**
 * Used for categorising culture/procedure/workflow/invocation states
 */
export enum StatePhase {
    /**
     * Yet to be picked up by the scheduler
     */
    Upcoming = "upcoming",
    /**
     * Has been picked up by the scheduler and is still ongoing.
     *
     * It could be that the scheduler is actively working on this item (i.e.
     * it's "running") or that it's waiting for something to happen (i.e. it's
     * "paused" or "pausing" or "cancelling" etc.).
     */
    Active = "active",
    /**
     * Has either been picked up by the scheduler and finished, or was never
     * run. Either way, the scheduler has moved on and will not work on this
     * item again.
     */
    Past = "past",
}

export const procedureStatePhaseMap: Record<StatePhase, Set<ProcedureState>> = {
    [StatePhase.Upcoming]: new Set([
        ProcedureState.Planned,
        ProcedureState.Removed,
    ]),
    [StatePhase.Active]: new Set([
        ProcedureState.Running,
        ProcedureState.Pausing,
        ProcedureState.Paused,
        ProcedureState.Cancelling,
    ]),
    [StatePhase.Past]: new Set([
        ProcedureState.Complete,
        ProcedureState.Cancelled,
        ProcedureState.Failed,
        ProcedureState.Ignored,
    ]),
};

export const procedureIsActive = (state?: ProcedureState | null): boolean => {
    if (!state) return false;

    return procedureStatePhaseMap[StatePhase.Active].has(state);
};

export const procedureIsUpcoming = (state?: ProcedureState | null): boolean => {
    if (!state) return false;

    return procedureStatePhaseMap[StatePhase.Upcoming].has(state);
};

export const procedureIsPast = (state?: ProcedureState | null): boolean => {
    if (!state) return false;

    return procedureStatePhaseMap[StatePhase.Past].has(state);
};

export const stepStatePhaseMap: Record<StatePhase, Set<StepState>> = {
    [StatePhase.Upcoming]: new Set([StepState.Planned, StepState.Removed]),
    [StatePhase.Active]: new Set([
        StepState.Running,
        StepState.Pausing,
        StepState.Paused,
        StepState.Cancelling,
    ]),
    [StatePhase.Past]: new Set([
        StepState.Complete,
        StepState.Cancelled,
        StepState.Failed,
        StepState.Ignored,
    ]),
};

export const stepIsActive = (state?: StepState | null): boolean => {
    if (!state) return false;

    return stepStatePhaseMap[StatePhase.Active].has(state);
};

export const stepIsUpcoming = (state?: StepState | null): boolean => {
    if (!state) return false;

    return stepStatePhaseMap[StatePhase.Upcoming].has(state);
};

export const stepIsPast = (state?: StepState | null): boolean => {
    if (!state) return false;

    return stepStatePhaseMap[StatePhase.Past].has(state);
};
