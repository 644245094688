import { useQuery } from "@apollo/client";

import { gql } from "__generated__/apollo";
import { ProtocolStartingBottlesQuery } from "__generated__/apollo/graphql";
import Callout from "components/common/Callout";
import Txt from "components/common/Text";
import { removeNullables } from "services/utils";

import { ProtocolBottlesTable } from "./ProtocolBottlesTable";

export const PROTOCOL_STARTING_BOTTLES_QUERY = gql(`
    query ProtocolStartingBottles($protocolId: String!) {
        protocol(id: $protocolId) {
            id
            startingBottles {
                liquidVolume
                reagentName
                totalVolume
            }
        }
    }
`);

type StartingBottles = NonNullable<
    ProtocolStartingBottlesQuery["protocol"]
>["startingBottles"];

export function useStartingBottlesQuery(protocolId: string): {
    startingBottles: StartingBottles;
    loading: boolean;
    error: Error | undefined;
} {
    const { data, loading, error } = useQuery(PROTOCOL_STARTING_BOTTLES_QUERY, {
        variables: { protocolId },
        fetchPolicy: "cache-and-network",
    });
    return {
        startingBottles: data?.protocol?.startingBottles ?? null,
        loading,
        error,
    };
}

export function ReviewBottlesView({ protocolId }: { protocolId: string }) {
    const { startingBottles, loading } = useStartingBottlesQuery(protocolId);

    const containers = startingBottles
        ? removeNullables(startingBottles ?? [])
        : null;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                width: "100%",
            }}>
            <Txt font="primary" level={5} emphasis>
                Connect bottles
            </Txt>
            <Callout variant="help">
                See the Setup Guide for bottle preparation instructions.
            </Callout>
            <Txt font="secondary" level={8}>
                Fill the bottles below to the volumes specified, and connect
                them to the sidecar plate.
            </Txt>

            {containers ? (
                <ProtocolBottlesTable containers={containers} />
            ) : loading ? (
                <Callout variant="info">Loading...</Callout>
            ) : (
                <Callout variant="error">
                    Error fetching protocol bottle data
                </Callout>
            )}
        </div>
    );
}
