import { atom, useAtom } from "jotai";

import { CellFormat } from "__generated__/apollo/graphql";
import { createModalOpenAtom } from "components/common/Modal/modal-utils";
import { guessTimezone, today } from "services/date";

import { CultureUploadType } from "./culture-setup-state";
import { log as parentLog } from "./log";

export const log = parentLog.extend("protocol-upload-state");

/**
 * State atom to store whether a culture is currently being created
 */
export const creatingCultureAtom = atom<boolean>(false);

/**
 * State atom to store the desired start date for the culture, which defaults to
 * 'today'.
 */
export const cultureStartDateAtom = atom(today());

/**
 * State atom for the culture timezone, which only permits 'supported' timezones
 * that we control.
 */
export const cultureTimezoneAtom = atom(guessTimezone());

/**
 * State atom for the error message to be displayed if protocol upload failed.
 */
export const protocolUploadErrorMessageAtom = atom<string | undefined>(
    undefined,
);

export enum CreateCultureModalViewId {
    AutomatedChecks = "automated-checks",
    DrySetup = "dry-setup",
    DryTests = "dry-tests",
    SelectProtocol = "select-protocol",
    ReviewBottles = "review-bottles",
    CellLineMetadata = "cell-line-metadata",
    CellPreparation = "cell-preparation",
    CultureMetadata = "culture-metadata",
}

const cultureCreationModalViewIdAtom = atom(
    CreateCultureModalViewId.AutomatedChecks,
);

export const useCultureCreationModalViewId = () =>
    useAtom(cultureCreationModalViewIdAtom);

const legacyCultureUploadTypeAtom = atom<CultureUploadType>(
    CultureUploadType.ServerOwned,
);

export const useLegacyCultureUploadType = () =>
    useAtom(legacyCultureUploadTypeAtom);

const cultureCreationModalOpenAtom = createModalOpenAtom({
    onOpen: (get, set) => {
        // Automatically set the culture start date to today
        set(cultureStartDateAtom, today());

        const entryViewId =
            get(legacyCultureUploadTypeAtom) === CultureUploadType.Development
                ? CreateCultureModalViewId.SelectProtocol
                : CreateCultureModalViewId.AutomatedChecks;

        set(cultureCreationModalViewIdAtom, entryViewId);
    },
});

export const useCultureCreationModalOpen = () =>
    useAtom(cultureCreationModalOpenAtom);

//
// Cell metadata about the culture, hydrated from protocol info & user input
//

export const cellConcentrationAtom = atom<number | null>(null);
export const cellLineAtom = atom<string>("");
export const vialIDAtom = atom<string>("");
export const passageNumberAtom = atom<number | null>(null);
export const cellFormatAtom = atom<CellFormat>(CellFormat.SingleCell);
