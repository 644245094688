import { router } from "App";
import { client } from "services/apollo/client";
import { config } from "services/config";
import {
    DatafileManager,
    syncFeatureFlagDatafile,
} from "services/feature-flags";
import { identifyUserInLogrocket } from "services/logrocket";
import { identifyUserInPostHog } from "services/posthog";
import {
    clearUserFromSentryContext,
    setUserInSentryContext,
} from "services/sentry";

import { log as parentLog } from "./log";

const log = parentLog.extend("authentication");

const DEVELOPER_JWT_STORAGE_ID = "developer_api_key";
const LAST_EMAIL_AUTHENTICATED_ID = "last_email_authenticated";
const POST_AUTHENTICATION_URL_ID = "post_authentication_url";

type BaseUser = { id: string; email: string };

class AuthenticationManager {
    private authenticatedUser: BaseUser | undefined = undefined;

    get loginPath(): string {
        return "/signin";
    }

    lastEmailAuthenticated(email?: string): string | null {
        if (email) {
            localStorage.setItem(LAST_EMAIL_AUTHENTICATED_ID, email);
            return email;
        }
        return localStorage.getItem(LAST_EMAIL_AUTHENTICATED_ID);
    }

    /**
     * Takes data retrieved from login and stores information for continued
     * authenticated state management.
     * @param args Takes object of `{ user, jwt }`
     */
    storeAuthenticatedUser(args: { user: BaseUser; jwt?: string }): void {
        log.debug("Currently stored user", this.authenticatedUser);
        log.debug("New user", args.user);
        if (this.authenticatedUser === undefined) {
            this.authenticatedUser = args.user;
            this.lastEmailAuthenticated(args.user.email);
            if (args.jwt) this.storeDeveloperJWT(args.jwt);

            setUserInSentryContext({
                id: args.user.id,
                email: args.user.email,
            });
            identifyUserInLogrocket(args.user.id, { email: args.user.email });
            identifyUserInPostHog(args.user.id, { email: args.user.email });
        }
        syncFeatureFlagDatafile();
    }

    /**
     * Check current state of authentication. If a user has a valid login the
     * function returns `true`.
     *
     * @returns boolean
     */
    isAuthenticated(): boolean {
        const loggedIn = this.authenticatedUser !== undefined;
        log.debug("User logged in", loggedIn);
        return loggedIn;
    }

    /**
     * Logs out the current user.
     *
     * Will clear necessary authentication, data caches, and user state. Redirects
     * to login if not already at that location.
     */
    async signout(): Promise<void> {
        log.debug("Logout handler called");
        if (this.isAuthenticated()) {
            this.authenticatedUser = undefined;
            clearUserFromSentryContext();
            authentication.clearDeveloperJWT();
            // To reset the cache without refetching active queries, use
            // `client.clearStore()` instead of `client.resetStore()`
            await client.clearStore();
            DatafileManager.instance().clearCache();

            await this.serverSessionSignoutRequest();

            log.debug("Redirecting to login after clearing user data");
        }

        if (window.location.pathname === "/developer") {
            // eslint-disable-next-line no-console
            console.warn(
                "Developer page is open. Preventing redirect to the sign in path.",
            );
            return;
        }

        this.redirectToSignin();
    }

    redirectToSignin(): void {
        if (window.location.pathname !== this.loginPath) {
            this.setRedirectUrl(window.location.href);
            void router.navigate(this.loginPath, {
                state: {
                    from: {
                        pathname: window.location.pathname,
                        search: window.location.search,
                        hash: window.location.hash,
                    },
                },
            });
        }
    }

    /**
     * By triggering a signout request the server will invalidate the session
     * and respond to overwrite the HTTPOnly cookie.
     */
    private async serverSessionSignoutRequest(): Promise<void> {
        const serverUrlString = config.serverUrl;
        const signoutUrl = new URL("/auth/signout", serverUrlString);
        try {
            await fetch(signoutUrl, {
                method: "POST",
                credentials: "include",
            });
        } catch (error) {
            log.error("Error during signout request", error);
        }
    }

    private getDeveloperJWTKey(): string {
        const serverOrigin = config.serverUrl;

        if (serverOrigin === "https://api.stg.mytos.bio") {
            return `stg_${DEVELOPER_JWT_STORAGE_ID}`;
        } else if (serverOrigin === "https://api.dev.mytos.bio") {
            return `dev_${DEVELOPER_JWT_STORAGE_ID}`;
        } else {
            return DEVELOPER_JWT_STORAGE_ID;
        }
    }

    /**
     * Fetches the JWT from local storage and returns. If there is no token in
     * storage then it will return an empty string.
     */
    getDeveloperJWT(): string | null {
        const developerToken = localStorage.getItem(this.getDeveloperJWTKey());
        log.debug(
            `Retrieved developer API Key from storage of length ${developerToken?.length}`,
        );
        return developerToken;
    }

    /**
     * Returns boolean on whether JWT is locally cached
     */
    hasDeveloperJWT(): boolean {
        const developerToken = this.getDeveloperJWT();
        return Boolean(developerToken);
    }

    /**
     * Stores the JWT to local storage
     * @param jwt JSON web token
     */
    storeDeveloperJWT(jwt: string): void {
        localStorage.setItem(this.getDeveloperJWTKey(), jwt);
        log.debug("Stored Developer JWT", jwt);
    }

    /**
     * Clears the JWT from memory
     */
    clearDeveloperJWT(): void {
        localStorage.removeItem(this.getDeveloperJWTKey());
        log.debug("Cleared Developer JWT from local storage");
    }

    getRedirectUrl(): string {
        const cachedUrl = localStorage.getItem(POST_AUTHENTICATION_URL_ID);
        const homepage = `${window.location.origin}/`;
        const redirectUrl = cachedUrl || homepage;

        log.debug("Redirect URL: ", redirectUrl);
        return redirectUrl;
    }

    setRedirectUrl(redirectUrl: string): void {
        log.debug("Storing post-authentication URL: ", redirectUrl);
        localStorage.setItem(POST_AUTHENTICATION_URL_ID, redirectUrl);
    }
}

export const authentication = new AuthenticationManager();
