import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { FileData } from "../Dropzone";
import { log as parentLog } from "../log";

export const log = parentLog.extend("useRecentFiles");

/**
 * Defines the maximum number of recent files persisted to browser storage. This
 * value should be kept low if using LocalStorage, since some browsers impose a
 * limit on amount of data that can be stored there. The browser may evict data
 * if we attempt to store too much. Note other user preferences and app state
 * gets stored in LocalStorage.
 */
const MAXIMUM_RECENT_FILES_STORED = 3;

/**
 * Defines the maximum file size (in bytes) that can be stored in the recent files array.
 * Files above this limit will be ignored. This is to avoid using up the limited
 * persistent storage space the browser gives us.
 */
const MAXIMUM_FILE_SIZE_BYTES = 1_000_000;

export type StoredFileData = {
    /**
     * ISO Timestamp of when the file was imported to the browser and stored
     */
    imported: string;
    /**
     * Fingerprint that uniquely indentifies this file. Used for deduplication.
     */
    hash: string;
    /**
     * The file data including name, content, and more.
     */
    filedata: FileData;
};

const recentFilesAtom = atomWithStorage<StoredFileData[]>(
    "protocol_upload_recent_files",
    [],
);

export function fileToStoredFileData(file: FileData): StoredFileData {
    return {
        hash: createHash(file),
        filedata: file,
        imported: new Date().toISOString(),
    };
}

export function useRecentFiles(): {
    recentFiles: StoredFileData[];
    pushFile: (file: FileData) => void;
    removeFile: (fileHash: string) => void;
} {
    const [recentFiles, setRecentFiles] = useAtom(recentFilesAtom);

    const pushFile = (file: FileData) => {
        // check if file size is within acceptable limits
        if (file.size > MAXIMUM_FILE_SIZE_BYTES) {
            log.info(
                "File size is above threshold. Skipping save to recent files.",
            );
            return;
        }
        const storedFile = fileToStoredFileData(file);
        const { hash } = storedFile;
        log.debug("hash created", hash);

        // check if file already exists
        const i = recentFiles.findIndex(f => f.hash === hash);
        if (i !== -1) {
            log.info(
                "File hash already matches existing file. Skipping save to recent files.",
            );
            return;
        }

        setRecentFiles(recentFilesState => {
            // if we have reached the maximum allowable array size, we pop to remove
            // from the end of the array
            if (recentFilesState.length === MAXIMUM_RECENT_FILES_STORED) {
                recentFilesState.pop();
            }
            // we've prepared everything and can now use unshift to add the file to
            // the beginning of the array
            recentFilesState.unshift(storedFile);
            log.debug("updating recent files array");
            return [...recentFilesState];
        });
    };

    return {
        recentFiles,
        pushFile,
        removeFile: fileHashToRemove => {
            setRecentFiles(recentFilesState => {
                const updatedArray = recentFilesState.filter(
                    f => f.hash !== fileHashToRemove,
                );
                log.debug("updating recent files array after removal");
                return [...updatedArray];
            });
        },
    };
}

function createHash(file: FileData): string {
    return file.filename + file.lastModified + String(file.size);
}
