import { isString } from "lodash";
import { v4 as uuidv4 } from "uuid";

export function onMobile(): boolean {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
        )
    ) {
        return true;
    }
    return false;
}

/**
 * Converts an integer into string representation as an ordinal.
 *
 * @example
 * ordinalConversion(1) // returns "1st"
 * ordinalConversion(22) // returns "22nd"
 * ordinalConversion(33) // returns "3rd"
 * ordinalConversion(4) // returns "4th"
 * @param i Integer to convert
 * @returns String of number in ordinal notation
 */
export function ordinalConversion(i: number): string {
    const j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

/**
 * Creates a new array with all `null` or `undefined` values removed.
 *
 * @param array - The array to remove nulls
 * @returns New array with no nulls
 */
export function removeNullables<T>(array: T[]): Array<NonNullable<T>> {
    let index = -1;
    const length = array == null ? 0 : array.length;
    let resIndex = 0;
    const result: Array<NonNullable<T>> = [];

    while (++index < length) {
        const value = array[index];
        if (value !== null && value !== undefined) {
            result[resIndex++] = value as NonNullable<typeof value>;
        }
    }
    return result;
}

/**
 * Type guard to ensure the value is not null
 * @param value - value to check
 * @returns boolean of if value is null
 */
export function isNotNull<T>(value: T | null): value is T {
    return value !== null;
}

/**
 * Type guard to ensure the value is not null
 * @param value - value to check
 * @returns boolean of if value is null
 */
export function isNotNil<T>(value: T | null | undefined): value is T {
    return value !== undefined && isNotNull(value);
}

/**
 * Evaluates whether the value passed is a pure object in the semantic sense.
 * This means that despite Javascript counting null, array, and functions, also
 * as objects, this function only returns true if the value passed is of the
 * classic `{}` structure.
 *
 * @param value - Value to check
 * @returns true or false depending on evaluation
 */
export function isPureObject(value: unknown): value is Record<string, unknown> {
    const isObjectType = typeof value === "object";
    const isNotArray = !Array.isArray(value);
    const isNotNull = value !== null;
    return isObjectType && isNotArray && isNotNull;
}

/**
 * Get the error message from an object
 *
 * @param body - body to exctract the error message from
 * @returns - string of the error message
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMessageFromBody(body: any): string {
    // By only stringifying the value if it's not already a string, we can avoid
    // adding excessive backslashes to legitimate strings.
    const ensureString = (value: unknown) =>
        isString(value) ? value : JSON.stringify(value);

    if (body.error?.response?.data?.error?.message) {
        return ensureString(body.error?.response?.data?.error?.message);
    } else if (body?.error?.data?.message) {
        return ensureString(body.error.data.message);
    } else if (body?.error?.message) {
        return ensureString(body.error.message);
    } else if (body?.error?.data) {
        return ensureString(body.error.data);
    } else if (body?.error) {
        return ensureString(body.error);
    } else {
        return ensureString(body);
    }
}

/**
 * Returns promise that resolves after the given duration (in milliseconds)
 */
export const sleep = (duration: number): Promise<void> =>
    new Promise(resolve => setTimeout(resolve, duration));

/**
 * Joins an array of strings into a path using the separator.
 *
 * @param parts Array of strings to join into a path
 * @param separator Delimiter for the path. Defaults to '/'
 * @returns Formatted path string
 */
export function pathJoin(parts: string[], separator = "/"): string {
    const replace = new RegExp(separator + "{1,}", "g");
    return parts.join(separator).replace(replace, separator);
}

/**
 * Used to create unique IDs for a number of resources
 */
export function createId(): string {
    return uuidv4().replace(/-/g, "");
}

/**
 * Takes an input number and returns the nearest value which adheres to the
 * contraints provided.
 * @param num Number
 * @param min Minimum value (optional)
 * @param max Maximum value (optional)
 * @returns Nearest valid value
 */
export function clamp(
    num: number,
    { min, max }: { min?: number; max?: number },
): number {
    let clamped = num;
    if (min !== undefined) clamped = Math.max(clamped, min);
    if (max !== undefined) clamped = Math.min(clamped, max);
    return clamped;
}

/**
 * Assert the value provided is not `null` or `undefined`. Returns boolean.
 */
export function isDefined<T>(value: T): value is NonNullable<T> {
    return value !== undefined && value !== null;
}

/**
 * Get's a renderable string of the user's name
 */
export function getUserNameText(
    user: null | { firstName?: string | null; lastName?: string | null },
) {
    if (user) {
        const nameParts = [user.firstName, user.lastName].filter(Boolean);
        return nameParts.join(" ");
    }

    return "";
}

/**
 * Used to ensure all cases are handled in a switch statement
 *
 * @example
 *
 * ```ts
 *
 * enum Value {
 *  A = "a",
 *  B = "b"
 * }
 *
 * switch (value) {
 *    case Value.A:
 *     break;
 *   case Value.B:
 *     break;
 * default:
 *   // This will give a TS compile error if a new value is added to the enum but it's not handled in this switch
 *   return exhaustiveMatchingGuard(value);
 * }
 * ```
 */
export const exhaustiveMatchingGuard = (value: never): Error => {
    return new Error(
        `Unhandled value in exhaustiveMatchingGuard: ${
            value as unknown as string
        }`,
    );
};
