import { ReactElement } from "react";

import { Box, useTheme } from "@mui/material";

import { CopyToClipboard } from "./CopyToClipboard";
import { textLevelLineHeight } from "./Text";

export interface CodeTextProps {
    /**
     * Text content of the block
     */
    text: string;
    /**
     * Display a copy to clipboard option
     */
    copyToClipboard?: boolean;
    /**
     * Use full space available
     */
    fullSizeCopy?: boolean;
    /**
     * Optional override for text copied to clipboard. Useful to display a
     * truncated form of the full copyable text.
     */
    copyText?: string;
}

/**
 * A small block to visually distinguish text of a code-like nature, such as
 * URLs, unique identifiers, etc.
 *
 * Since the content is frequently copied to clipboard, a "copy to clipboard"
 * functionality is available.
 */
export function CodeText({
    text,
    copyToClipboard = true,
    fullSizeCopy = false,
    copyText = undefined,
}: CodeTextProps): ReactElement {
    const theme = useTheme();
    const textToCopy = copyText ?? text;
    return (
        <Box
            sx={theme => ({
                color: theme.colours.neutral[700],
                display: "inline-flex",
                flexDirection: fullSizeCopy ? "column" : undefined,
                alignItems: "flex-start",
                gap: "4px",
            })}>
            <code
                style={{
                    display: "inline-block",
                    padding: "4px 8px",
                    background: theme.colours.neutral[200],
                    borderRadius: "4px",
                    fontSize: 12,
                    lineHeight: textLevelLineHeight[9],
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                }}>
                {text}
            </code>
            {copyToClipboard ? (
                <CopyToClipboard
                    variant={fullSizeCopy ? "full" : "compact"}
                    copytext={textToCopy}
                />
            ) : null}
        </Box>
    );
}
