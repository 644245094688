import React, { Fragment, ReactElement, useEffect } from "react";

// import TabPanel from "@mui/lab/TabPanel";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { isArray, isString } from "lodash";

import { NumberInput } from "components/common/NumberInput";
import { Select } from "components/common/Select";
import { Switch } from "components/common/Switch";
import Txt from "components/common/Text";
import { TextInput } from "components/common/TextInput";
import { DeviceOperationParameter } from "services/hooks/useDeviceOperations";
import { toTitleCase } from "services/string-utils";

import { log as parentLog } from "./log";

export const log = parentLog.extend("ParameterInput");

export type ParameterInputProps = DeviceOperationParameter &
    SetOperationParameter;

export type StringParameterInputProps = Extract<
    ParameterInputProps,
    { __typename?: "StringParameter" }
>;

export type StringArrayParameterInputProps = Extract<
    ParameterInputProps,
    { __typename?: "StringArrayParameter" }
>;

export type BooleanParameterInputProps = Extract<
    ParameterInputProps,
    { __typename?: "BooleanParameter" }
>;

export type NumberParameterInputProps = Extract<
    ParameterInputProps,
    { __typename?: "NumberParameter" }
>;

type SetOperationParameter = {
    setOperationParameters: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, unknown>>>
    >;
    operationParameters: Record<string, Record<string, unknown>>;
    isMobile: boolean;
    operationId: string;
    previousValue?: unknown;
    displayPreviousValue?: boolean;
};

export function ParameterInput(props: ParameterInputProps): ReactElement {
    const { __typename } = props;
    if (__typename === "StringParameter") {
        return <StringParameterInput {...props} />;
    }
    if (__typename === "StringArrayParameter") {
        return <StringArrayParameterInput {...props} />;
    }
    if (__typename === "BooleanParameter") {
        return <BooleanParameterInput {...props} />;
    }
    if (__typename === "NumberParameter") {
        return <NumberParameterInput {...props} />;
    }
    return <></>;
}

function StringParameterInput(props: StringParameterInputProps): ReactElement {
    const {
        options,
        name,
        parameterId,
        defaultString,
        description,
        operationId,
        setOperationParameters,
        isMobile,
        previousValue,
        displayPreviousValue,
    } = props;
    const onChange = onChangeParameter<string>(
        setOperationParameters,
        operationId,
        parameterId,
    );
    const initialValue = previousValue
        ? String(previousValue)
        : defaultString
          ? defaultString
          : undefined;
    useEffect(() => {
        if (initialValue) {
            onChange(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (parameterId === null) {
        log.error({ props }, "no parameter id");
        return <Fragment />;
    }
    return (
        <Box sx={{ paddingTop: "8px" }}>
            <DisplayTitle
                name={name}
                description={description}
                previousValue={previousValue}
                displayPreviousValue={displayPreviousValue}
            />
            {options ? (
                <Select
                    defaultValue={initialValue} // This is the initial render value
                    options={options
                        .filter(o => !!o) // remove empty strings
                        .map(str => ({
                            value: str,
                            label: toTitleCase(str.toLowerCase()),
                        }))}
                    onChange={onChange}
                    minWidth={256}
                    maxWidth={isMobile ? false : 256}
                    disabled={false}
                    multi={false}
                />
            ) : (
                <TextInput fullWidth={isMobile} onChange={onChange} />
            )}
        </Box>
    );
}

function isValidStringArray(value: unknown): value is readonly string[] {
    return isArray(value) && value.every(v => isString(v));
}

function StringArrayParameterInput(
    props: StringArrayParameterInputProps,
): ReactElement {
    const {
        options,
        name,
        parameterId,
        defaultStringArray,
        description,
        operationId,
        setOperationParameters,
        isMobile,
        previousValue,
        displayPreviousValue,
    } = props;
    const onChange = onChangeParameter<readonly string[]>(
        setOperationParameters,
        operationId,
        parameterId,
    );
    const initialValue = isValidStringArray(previousValue)
        ? previousValue
        : (defaultStringArray ?? undefined);
    const typedOptions = isValidStringArray(options)
        ? options
              .filter(o => !!o) // remove empty strings
              .map(str => ({
                  value: str,
                  label: toTitleCase(str.toLowerCase()),
              }))
        : [];
    useEffect(() => {
        if (initialValue) {
            onChange(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (parameterId === null) {
        log.error({ props }, "no parameter id");
        return <Fragment />;
    }
    return (
        <Box sx={{ paddingTop: "8px" }}>
            <DisplayTitle
                name={name}
                description={description}
                previousValue={previousValue}
                displayPreviousValue={displayPreviousValue}
            />
            <Select
                defaultValue={initialValue} // This is the initial render value
                options={typedOptions}
                onChange={onChange}
                minWidth={256}
                maxWidth={isMobile ? false : 256}
                disabled={false}
                multi={true}
            />
        </Box>
    );
}

function BooleanParameterInput(
    props: BooleanParameterInputProps,
): ReactElement {
    const {
        name,
        parameterId,
        description,
        operationId,
        setOperationParameters,
        operationParameters,
        defaultBoolean,
        isMobile,
        previousValue,
        displayPreviousValue,
    } = props;

    const onChange = onChangeParameter<boolean>(
        setOperationParameters,
        operationId,
        parameterId,
    );
    useEffect(() => {
        if (previousValue) {
            onChange(Boolean(previousValue));
        } else if (defaultBoolean) {
            onChange(defaultBoolean);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (parameterId === null) {
        log.error({ props }, "no parameter id");
        return <Fragment />;
    }
    return (
        <Box sx={{ paddingTop: "8px" }}>
            <DisplayTitle
                name={name}
                description={description}
                previousValue={previousValue}
                displayPreviousValue={displayPreviousValue}
            />
            <Switch
                id={parameterId ?? "unknown"}
                fillWidth={isMobile}
                checked={
                    operationParameters[operationId]?.[parameterId]
                        ? Boolean(operationParameters[operationId][parameterId])
                        : undefined
                }
                onChange={onChange}
            />
        </Box>
    );
}

function NumberParameterInput(props: NumberParameterInputProps): ReactElement {
    const {
        name,
        description,
        parameterId,
        defaultNumber,
        min,
        max,
        step,
        units,
        operationId,
        setOperationParameters,
        operationParameters,
        isMobile,
        previousValue,
        displayPreviousValue,
    } = props;
    const onChange = onChangeParameter<number | null>(
        setOperationParameters,
        operationId,
        parameterId,
    );
    useEffect(() => {
        if (previousValue) {
            onChange(Number(previousValue));
        } else if (defaultNumber) {
            onChange(defaultNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (parameterId === null) {
        log.error({ props }, "no parameter id");
        return <Fragment />;
    }
    return (
        <Box sx={{ paddingTop: "8px", width: "100%" }}>
            <DisplayTitle
                name={name}
                description={description}
                previousValue={previousValue}
                displayPreviousValue={displayPreviousValue}
            />
            <NumberInput
                value={
                    operationParameters[operationId]?.[parameterId]
                        ? Number(operationParameters[operationId][parameterId])
                        : null
                }
                fullWidth={isMobile}
                onChange={onChange}
                min={min ?? undefined}
                max={max ?? undefined}
                step={step ?? undefined}
                units={units ?? undefined}
            />
        </Box>
    );
}

export function DisplayTitle(props: {
    name: string | null;
    description: string | null;
    previousValue?: unknown;
    displayPreviousValue?: boolean;
}): ReactElement {
    const theme = useTheme();
    const { displayPreviousValue = false } = props;
    return (
        <Fragment>
            <Txt
                font="secondary"
                level={7}
                emphasis
                align="left"
                style={{ color: theme.colours.neutral[700] }}>
                {props.name ?? "Unknown Parameter"}
            </Txt>
            <Txt
                font="secondary"
                level={8}
                align="left"
                style={{ color: theme.colours.neutral[700] }}>
                {props.description ?? "No description available"}
            </Txt>
            {displayPreviousValue && (
                <div style={{ margin: "2px 0" }}>
                    <Txt font="secondary" level={9} italic align="left">
                        {props.previousValue
                            ? `Previous value: ${props.previousValue}`
                            : "No previous value"}
                    </Txt>
                </div>
            )}
        </Fragment>
    );
}

function onChangeParameter<T>(
    setOperationParameters: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, unknown>>>
    >,
    operationId: string,
    parameterId: string | null,
) {
    return (value: T) => {
        setOperationParameters(previousValue =>
            parameterId === null
                ? previousValue
                : {
                      ...previousValue,
                      [operationId]: {
                          ...previousValue[operationId],
                          [parameterId]: value,
                      },
                  },
        );
    };
}
