import { Getter, Setter, atom } from "jotai";

import {
    MultiViewModalView,
    MultiViewModalViewButton,
    MultiViewModalViewSimple,
} from "./MultiViewModal";

/**
 * Create atom for the state of whether a multi view modal is open.
 *
 * This is required because the modal needs to be able to reset the view index
 * on _open_ rather than on close, or else there is a visual flicker while closing.
 */
export function createModalOpenAtom({
    onOpen,
}: {
    /**
     * Optional callback which triggers when the modal is opened
     */
    onOpen?: (get: Getter, set: Setter) => void;
}) {
    const modalOpenAtom = atom(false, (get, set, nextValue: boolean) => {
        set(modalOpenAtom, nextValue);
        if (nextValue === true) {
            onOpen?.(get, set);
        }
    });

    return modalOpenAtom;
}
/**
 * If true or nothing is returned, default behaviour will occur, which is to
 * progress to the next view for the right button, return to the previous
 * view for the left button, and close the modal for extreme buttons.
 * If false is returned, no state transition will occur.
 */
export type LinearMultiViewModalClickHandler = () => Promise<void | boolean>;

type LinearMultiViewModalViewInput = {
    /** Props for left-hand footer button */
    buttonLeft?: Omit<MultiViewModalViewButton, "onClick"> & {
        onClick?: LinearMultiViewModalClickHandler;
    };
    /** Props for right-hand footer button */
    buttonRight?: Omit<MultiViewModalViewButton, "onClick"> & {
        onClick?: LinearMultiViewModalClickHandler;
    };
    render: () => React.ReactElement;
    /** If true, omit this modal page */
    omit?: boolean;
};

export function getLinearMultiViewModalViewProps<T extends string>({
    idsAndViews,
    setModalOpen,
    setViewId,
}: {
    idsAndViews: [T, LinearMultiViewModalViewInput][];
    setModalOpen: (newValue: boolean) => void;
    setViewId: (newValue: T) => void;
}): Record<T, MultiViewModalViewSimple | null> {
    const filteredViews = idsAndViews.filter(([, { omit }]) => !omit);

    const amendedViews: [T, MultiViewModalView][] = filteredViews.map(
        ([viewId, { buttonLeft, buttonRight, render }], index) => {
            const isEntryView = index === 0;
            const isTerminalView = index === filteredViews.length - 1;

            const leftButtonAmended: MultiViewModalViewButton = {
                label: isEntryView ? "Cancel" : "Back",
                ...buttonLeft,
                onClick: async () => {
                    const success = await buttonLeft?.onClick?.();

                    if (success === false) {
                        return;
                    }

                    if (isEntryView) {
                        return setModalOpen(false);
                    }

                    setViewId(filteredViews[index - 1][0]);
                },
            };

            const rightButtonAmended: MultiViewModalViewButton = {
                label: "Continue",
                ...buttonRight,
                onClick: async () => {
                    const success = await buttonRight?.onClick?.();

                    if (success === false) {
                        return;
                    }

                    if (isTerminalView) {
                        return setModalOpen(false);
                    }

                    setViewId(filteredViews[index + 1][0]);
                },
            };

            return [
                viewId,
                {
                    buttonLeft: leftButtonAmended,
                    buttonRight: rightButtonAmended,
                    render,
                },
            ];
        },
    );

    // Needs type cast or type is inferred as { [key: string]: MultiViewModalView<T> }
    return Object.fromEntries(amendedViews) as Record<
        T,
        MultiViewModalViewSimple | null
    >;
}
