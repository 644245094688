import React, { useState } from "react";

// @dnd-kit/core doesn't use the HTML5 drag and drop API under the hood
// Were using it is because we want to support touch devices and the HTML5 drag and drop API doesn't support touch devices.
// One of the main downsides of not using the HTML API is that we don't support dragging and dropping between windows or desktop to window.
// More details: https://docs.dndkit.com/#architecture
import {
    DndContext,
    DragEndEvent,
    DragOverlay,
    DropAnimation,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    closestCenter,
    defaultDropAnimationSideEffects,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "@emotion/styled";

const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: "0.5",
            },
        },
    }),
};

export interface SortableListProps<Item extends { id: string }> {
    /**
     * The list of items to be sorted. The order of this list should update (in response to `setItems`) as items are dragged and dropped.
     */
    items: Item[];
    /**
     * `useState` style updater function to update the items in the list. This function will be called with the new sorted list of items.
     */
    setItems: (callback: (items: Item[]) => Item[]) => void;
    /**
     * Function to render each item in the list. This doesn't need to worry about styling for the specific drag and drop functionality, just the content of the item.
     */
    renderItem: (item: Item) => React.ReactNode;
    /**
     * Is the sortable list in a scrollable div? We need to know this to handle the drag functionality correctly.
     */
    scrollable?: boolean;
}

/**
 * A component that provides Drag and Drop functionality for a list of items.
 *
 * This component will handle all the drag and drop functionality and the animation/styling of the list
 * items that requires. You will need to provide the rest of the item styling using the `renderItem` prop.
 */
export function SortableList<Item extends { id: string }>({
    items,
    setItems,
    renderItem,
    scrollable,
}: SortableListProps<Item>) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        // Allows the drag and drop to be controlled by the keyboard using tab, enter and arrow keys
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [activeId, setActiveId] = useState<string | null>(null);

    const activeItem = items.find(item => item.id === activeId);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={scrollable ? [restrictToFirstScrollableAncestor] : []}
            onDragStart={({ active }) => {
                if (!active) {
                    return;
                }

                setActiveId(active.id as string);
            }}
            onDragCancel={() => setActiveId(null)}
            onDragEnd={handleDragEnd}>
            <>
                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {items.map(item => (
                            <SortableItem key={item.id} id={item.id}>
                                {renderItem(item)}
                            </SortableItem>
                        ))}
                    </div>
                </SortableContext>
                {/* The drag overlay does 2 things. */}
                {/* 1. It allows us to show an item being dragged under the cursor, and a duplicate where below in it's current list position (which is nice as it avoids gaps in the list) */}
                {/* 2. It helps the UX in scrollable lists. The Drag overlay is able to escape any overflows so the dragging element doesn't get hidden if you try to drag it out of the list. */}
                <DragOverlay dropAnimation={dropAnimationConfig}>
                    {activeItem ? (
                        <SortableItem
                            dragOverlay
                            key={activeItem.id}
                            id={activeItem.id}>
                            {renderItem(activeItem)}
                        </SortableItem>
                    ) : null}
                </DragOverlay>
            </>
        </DndContext>
    );

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        setActiveId(null);

        if (active && over && active.id !== over.id) {
            setItems(items => {
                const oldIndex = items.findIndex(({ id }) => id === active.id);
                const newIndex = items.findIndex(({ id }) => id === over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }
}

/**
 * A component to wrap elements of a draggable item to prevent the drag and drop functionality from being triggered when a user attempts to interact with them.
 *
 * Use case:
 * Sometimes within a draggable element you want to have an input or button that the user can interact with without clicks bubbling up to trigger the drag and drop functionality.
 * This component will cache all events that can trigger drag and drop and stop them from propagating.
 */
export function PreventFromTriggeringDragAndDrop({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            // This stops the drag and drop functionality from being triggered when a user attempts to click the input
            onPointerDown={e => e.stopPropagation()}
            // Prevents drag and drop focus when a user is typing the step name
            onKeyDown={e => e.stopPropagation()}>
            {children}
        </div>
    );
}

/**
 * Internal component to handle the sorting specific styling of sortable items.
 *
 * The styling in this component is intentionally kept minimal, as specific styling
 * needed for your use case should be handled in the `renderItem` function passed to
 * `SortableList`.
 */
function SortableItem({
    id,
    children,
    dragOverlay,
}: {
    id: string;
    children: React.ReactNode;
    /**
     * Is this item being rendered in the drag overlay? (i.e. is it the one currently being dragged?)
     */
    dragOverlay?: boolean;
}) {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
    });

    return (
        <div
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
                opacity: isDragging ? 0.4 : undefined,
                zIndex: dragOverlay ? 999 : 0, // currently dragging item should be over everything else
                // This is important to ensure drag and drop works on touch devices within scrollable lists
                // https://docs.dndkit.com/api-documentation/sensors/pointer#touch-action
                touchAction: "none",
            }}
            {...listeners}
            {...attributes}>
            {dragOverlay ? (
                <DragOverlayAnimation>{children}</DragOverlayAnimation>
            ) : (
                <div
                    style={{
                        // Note: We set the cursor it "grabbing" in the DragOverlayAnimation
                        cursor: "grab",
                    }}>
                    {children}
                </div>
            )}
        </div>
    );
}

const DragOverlayAnimation = styled.div`
    /**
    * Animate using keyframe because this element only gets added to the DOM when being dragged,
    * so we can't use a transition to animate changes to it's properties (because it doesn't exist
    * before).
    */
    @keyframes dragOverlayScaleUp {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.01);
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        }
    }

    animation: dragOverlayScaleUp 0.2s forwards;
    cursor: grabbing;
`;
