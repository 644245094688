import { useState } from "react";

import { useTheme } from "@emotion/react";
import { Collapse, Divider } from "@mui/material";

import { CalloutCard } from "components/common/Callout";
import Icon from "components/common/Icon";
import Txt from "components/common/Text";

export function CultureError({ errorMessage }: { errorMessage: string }) {
    const theme = useTheme();

    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <CalloutCard variant="error">
            <div
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                }}>
                <div style={{ display: "flex" }}>
                    <Icon
                        name="alert-cross"
                        level={8}
                        sx={{
                            marginRight: theme => theme.spacing(3),
                            color: theme.colours.accent.errorRed,
                        }}
                    />
                    <Txt
                        font="secondary"
                        level={8}
                        emphasis
                        style={{ flexGrow: 1 }}>
                        An error occurred with this culture
                    </Txt>
                </div>
                <Txt
                    font="secondary"
                    level={8}
                    style={{
                        marginTop: "8px",
                    }}>
                    Please contact a member of Mytos support.
                </Txt>
                <Collapse in={isExpanded}>
                    <div
                        style={{
                            marginTop: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                        }}>
                        <Divider />
                        <Txt font="secondary" level={9}>
                            Debug information: {errorMessage}
                        </Txt>
                    </div>
                </Collapse>
            </div>
        </CalloutCard>
    );
}
