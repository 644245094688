import { ReactElement } from "react";

import {
    MultiViewModal,
    MultiViewModalViewRecord,
} from "components/common/Modal/MultiViewModal";
import { guessTimezone, today } from "services/date";

import { CellLineMetadataView } from "./CellLineMetadata/CellLineMetadataView";
import { CellPreparationView } from "./CellPreparation/CellPreparationView";
import { useCellConcentration } from "./CellPreparation/useCellPreparationData";
import { AutomatedStartChecksModalContent } from "./CreateCultureModalFlow/AutomatedStartChecksModalContent";
import { CultureMetadataModalContent } from "./CreateCultureModalFlow/CultureMetadataModalContent";
import { DrySetupModalContent } from "./CreateCultureModalFlow/DrySetupModalContent";
import { DryTestsModalContent } from "./CreateCultureModalFlow/DryTestsModalContent";
import { SelectProtocolModalContent } from "./CreateCultureModalFlow/SelectProtocolModalContent";
import { useCreatePrimaryCulture } from "./CreateCultureModalFlow/useCreatePrimaryCulture";
import { useCreateWetTestCulture } from "./CreateCultureModalFlow/useCreateWetTestCulture";
import {
    CultureSetupPhase,
    CultureUploadType,
    setQueriedStateAdapter,
    SetupStep,
    useCultureSetupModalState,
    useCultureSetupState,
} from "./culture-setup-state";
import { log as parentLog } from "./log";
import { useCellLineMetadata } from "./protocol-upload-hooks";
import { ReviewBottlesView } from "./ReviewBottles/ReviewBottlesView";
import { WetTestsView } from "./WetTests/WetTestsView";

export const log = parentLog.extend("ClearCultureDialog");

export interface CultureSetupModalProps {
    deviceId: string;
}

export function CultureSetupModal(props: CultureSetupModalProps): ReactElement {
    const { deviceId } = props;

    const { cultureUploadType, modalOpen, setModalOpen } =
        useCultureSetupModalState();

    const isDevelopmentCultureUpload =
        cultureUploadType === CultureUploadType.Development;

    const {
        doStateTransition,
        currentStep,
        currentSetupPhase,
        setupState,
        setSetupState,
        resetSetupState,
    } = useCultureSetupState();

    const isWetTestProtocol = Boolean(setupState.wetTestProtocol);
    const primaryProtocolId = setupState.primaryProtocol?.id;
    const wetTestProtocolId = setupState.wetTestProtocol?.id;

    const createWetTestCulture = useCreateWetTestCulture({ deviceId });

    const { cellConcentration } = useCellConcentration();

    const { cellLine, passageNumber, vialID, cellFormat } =
        useCellLineMetadata();

    const cultureMetadata = {
        initialSeedCellConcentration: cellConcentration,
        cellLine,
        passageNumber,
        vialID,
        cellFormat,
    };

    const showCellPreparationPages =
        !isDevelopmentCultureUpload &&
        setupState.primaryProtocol?.canCollectInitialSeedData;

    const createPrimaryCulture = useCreatePrimaryCulture({
        deviceId,
        primaryProtocolId,
        wetTestCultureId: setupState.culture?.id,
        cultureMetadata,
        isReplacingWetTestCulture:
            isWetTestProtocol && !isDevelopmentCultureUpload,
        skipValidation: isDevelopmentCultureUpload,
    });

    /***************************************************************************
     * Select/Create protocol
     */

    const onClickTransition = (targetStep: SetupStep) => {
        return async () => doStateTransition(targetStep);
    };

    const views: MultiViewModalViewRecord<SetupStep> = {
        [SetupStep.Inactive]: null,
        [SetupStep.AutomatedChecks]: {
            renderModalContent: () => (
                <AutomatedStartChecksModalContent
                    deviceId={deviceId}
                    onBackClick={onClickTransition(SetupStep.Inactive)}
                    onSubmit={onClickTransition(SetupStep.SelectProtocol)}
                />
            ),
        },
        [SetupStep.SelectProtocol]: {
            renderModalContent: () => (
                <SelectProtocolModalContent
                    onBackClick={onClickTransition(SetupStep.Inactive)}
                    onProtocolUpload={protocolUploadDetails => {
                        setSetupState(state => ({
                            ...state,
                            ...protocolUploadDetails,
                        }));

                        if (isDevelopmentCultureUpload) {
                            doStateTransition(SetupStep.CultureWetSetup);
                            return;
                        }

                        doStateTransition(SetupStep.DrySetup);
                    }}
                />
            ),
        },
        [SetupStep.DrySetup]: {
            renderModalContent: () => (
                <DrySetupModalContent
                    deviceId={deviceId}
                    onBackClick={onClickTransition(SetupStep.SelectProtocol)}
                    onSubmit={onClickTransition(SetupStep.DryTests)}
                />
            ),
        },
        [SetupStep.DryTests]: {
            renderModalContent: () => (
                <DryTestsModalContent
                    deviceId={deviceId}
                    onBackClick={onClickTransition(SetupStep.DrySetup)}
                    onSubmit={async () => {
                        if (isWetTestProtocol && !isDevelopmentCultureUpload) {
                            const timezone = guessTimezone();
                            if (!wetTestProtocolId || !timezone) {
                                log.error("Invalid protocol ID or timezone");
                                return;
                            }

                            const cultureUploadOutput =
                                await createWetTestCulture({
                                    protocolId: wetTestProtocolId,
                                    startDate: today(),
                                    timezone,
                                    cultureMetadata,
                                    skipValidation: false,
                                });

                            if (cultureUploadOutput instanceof Error) {
                                return cultureUploadOutput;
                            }

                            if (cultureUploadOutput === null) {
                                return new Error(
                                    "Failed to create wet test culture",
                                );
                            }

                            setSetupState(s => ({
                                ...s,
                                ...setQueriedStateAdapter(cultureUploadOutput),
                            }));

                            doStateTransition(SetupStep.WetTestsSetup);
                            return;
                        }

                        doStateTransition(SetupStep.CultureWetSetup);
                    }}
                />
            ),
        },
        [SetupStep.WetTestsSetup]: {
            // Could make this clear the wet test culture instead
            buttonLeft: {
                onClick: async () => setModalOpen(false),
                label: "Close",
            },
            buttonRight: { onClick: onClickTransition(SetupStep.WetTests) },
            render: () =>
                wetTestProtocolId ? (
                    <ReviewBottlesView protocolId={wetTestProtocolId} />
                ) : (
                    <></>
                ),
        },
        [SetupStep.WetTests]: {
            buttonLeft:
                currentSetupPhase === CultureSetupPhase.WetTestStarted
                    ? {
                          onClick: async () => setModalOpen(false),
                          label: "Close",
                      }
                    : { onClick: onClickTransition(SetupStep.WetTestsSetup) },
            buttonRight: {
                // Disable if wet tests are not complete
                disabled:
                    currentSetupPhase !== CultureSetupPhase.WetTestComplete,
                onClick: onClickTransition(SetupStep.CultureWetSetup),
            },
            render: () => (
                <WetTestsView cultureId={setupState.culture?.id ?? ""} />
            ),
        },
        [SetupStep.CultureWetSetup]: {
            buttonLeft: {
                onClick: async () => {
                    if (
                        currentSetupPhase === CultureSetupPhase.NoCultureLoaded
                    ) {
                        return doStateTransition(SetupStep.DryTests);
                    }
                    setModalOpen(false);
                },
            },
            buttonRight: {
                onClick: async () => {
                    if (showCellPreparationPages) {
                        return doStateTransition(SetupStep.CellLineInfo);
                    }
                    doStateTransition(SetupStep.CultureMetadata);
                },
            },
            render: () =>
                primaryProtocolId ? (
                    <ReviewBottlesView protocolId={primaryProtocolId} />
                ) : (
                    <></>
                ),
        },
        [SetupStep.CellLineInfo]: {
            buttonLeft: {
                onClick: onClickTransition(SetupStep.CultureWetSetup),
            },
            buttonRight: {
                onClick: onClickTransition(SetupStep.CellPreparation),
            },
            render: () => <CellLineMetadataView />,
        },
        [SetupStep.CellPreparation]: {
            buttonLeft: {
                onClick: onClickTransition(SetupStep.CellLineInfo),
            },
            buttonRight: {
                onClick: onClickTransition(SetupStep.CultureMetadata),
            },
            render: () => (
                <CellPreparationView protocolId={primaryProtocolId} />
            ),
        },
        [SetupStep.CultureMetadata]: {
            renderModalContent: () =>
                primaryProtocolId ? (
                    <CultureMetadataModalContent
                        protocolName={
                            setupState.primaryProtocol?.name ?? undefined
                        }
                        onBackClick={() => {
                            if (showCellPreparationPages) {
                                return doStateTransition(
                                    SetupStep.CellPreparation,
                                );
                            }
                            doStateTransition(SetupStep.CultureWetSetup);
                        }}
                        onSubmit={async ({
                            cultureTimezone,
                            cultureStartDate,
                        }) => {
                            const result = await createPrimaryCulture({
                                cultureTimezone,
                                cultureStartDate,
                            });

                            if (result instanceof Error) {
                                return result;
                            }

                            resetSetupState();

                            doStateTransition(SetupStep.Inactive);
                        }}
                    />
                ) : null,
        },
    };

    return (
        <MultiViewModal
            title={"New Culture"}
            views={views}
            viewId={currentStep}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            maxWidth="sm"
        />
    );
}
