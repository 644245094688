import { ReactElement } from "react";

import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

import { StepState } from "__generated__/apollo/graphql";
import Icon from "components/common/Icon";

import { log as parentLog } from "./log";

export const log = parentLog.extend("StepItemStatus");

export type StepStateIconProps = {
    state: StepState | null;
};

export function StepStateIcon(props: StepStateIconProps): ReactElement {
    const theme = useTheme();

    // a default fallback if a state isn't defined below
    let element: ReactElement = <div>{props.state}</div>;

    if (props.state === StepState.Complete) {
        element = (
            <div
                css={css`
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colours.brand.green};
                    color: ${theme.colours.neutral.white};
                `}>
                <Icon name="check" size="sm" />
            </div>
        );
    }
    if (props.state === StepState.Failed) {
        element = (
            <div
                css={css`
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colours.neutral.white};
                    color: ${theme.colours.accent.errorRed};
                    border: 1px solid ${theme.colours.accent.errorRed};
                `}>
                <Icon name="cross" size="sm" />
            </div>
        );
    }
    if (props.state === StepState.Running) {
        element = (
            <div
                css={css`
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colours.brand.blueDark};
                    padding: 1px;
                `}>
                <RotatingLines
                    width="18"
                    strokeColor={theme.colours.neutral.white}
                    strokeWidth="5"
                    animationDuration="1"
                />
            </div>
        );
    }
    if (props.state === StepState.Paused) {
        element = (
            <div
                css={css`
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colours.neutral.white};
                    color: ${theme.colours.neutral[500]};
                `}>
                <Icon name="pause-circle" size="lg" />
            </div>
        );
    }
    if (
        props.state === StepState.Planned ||
        props.state === StepState.Waiting ||
        props.state === StepState.Unknown
    ) {
        element = (
            <div
                css={css`
                    border-radius: 20px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colours.neutral.white};
                `}>
                <div
                    css={css`
                        border-radius: 20px;
                        width: 10px;
                        height: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid ${theme.colours.neutral[300]};
                    `}></div>
            </div>
        );
    }
    if (
        props.state === StepState.Removed ||
        props.state === StepState.Ignored ||
        props.state === StepState.Cancelled
    ) {
        element = (
            <div
                css={css`
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colours.neutral.white};
                    color: ${theme.colours.neutral[500]};
                `}>
                <Icon name="disable" size="md" />
            </div>
        );
    }
    return (
        <div
            css={css`
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            `}>
            {element}
        </div>
    );
}
